import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import useCustom from './hooks';
import { Image } from 'primereact/image';
import { formatRupiah } from '@/utils/formatter';
import dayjs from 'dayjs';
import { Dialog } from 'primereact/dialog';
import IconRect from '@/assets/IconRect.svg';
import IconDot from '@/assets/IconTrackingDot.svg';
import { Button } from 'primereact/button';

const DetailOrderManagement = () => {
  const { data, method } = useCustom();

  if (data.isLoading) return null;

  const headerCreate = (
    <div>
      <div>Detail Status Pesanan - PO {`${data.poId}`}</div>
      <div>Nomor Pengiriman {data.dataOrderDetail?.awb_no || '-'}</div>
    </div>
  );

  const shippingName = data.dataOrderDetail?.delivery_metadata?.['provider_name'] === 'on_site_pick_up' ?
    data.dataOrderDetail?.delivery_metadata?.['name'] :
    data.dataOrderDetail?.delivery_metadata?.['provider_name']?.toUpperCase?.() + ' ' + data.dataOrderDetail?.delivery_metadata?.['name'];
  return (
    <BaseContainer>
      <div>
        <Dialog header={headerCreate} visible={data.displayTrackingOrder} style={{ width: '500px' }} onHide={method.handleCloseDialog}>
          {data.loadingAccount && <div className="m-auto"> Loading...</div>}
          {!data.loadingAccount && (
            <div className="flex flex-col my-10">
              {data.dataTrackorder && data.dataTrackorder.map((item, idx) => {
                const isLastItem = idx === data.dataTrackorder.length - 1;
                return (
                  <div key={idx} className="flex gap-3 mt-[-20px] px-5 items-start">
                    {isLastItem ? (
                      <div>
                        <img src={IconDot} />
                      </div>
                    ) : (
                      <div>
                        <img src={IconRect} />
                      </div>
                    )}
                    <div className="flex flex-col gap-3">
                      <div className="font-bold">
                        {`${item.account}`} {`${dayjs(item.created_at).format('dddd, DD MMM YYYY HH:mm:ss')}`}
                      </div>
                      <div>{item.status}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </Dialog>
      </div>

      <MainTitle title={`PO ID: ${data.poId}`} isWithButton labelButton="Back" nav="/order-management"/>

      <div className="flex items-start ga">
        <div className="w-1/2">
          <p className="font-bold text-lg mb-2">PO Details</p>
          <div className="grid grid-cols-3 gap-4">
            <div>
              <p className="font-bold text-sm">Shipping Amount</p>
              <p className="text-xs">{formatRupiah(data.dataOrderDetail?.shipping_fee || 0)}</p>
            </div>
            <div>
              <p className="font-bold text-sm">Nama Pembeli</p>
              <p className="text-xs">
                {data.dataOrderDetail?.buyer_detail?.first_name}{' '}
                {data.dataOrderDetail?.buyer_detail?.last_name}
              </p>
            </div>
            <div>
              <p className="font-bold text-sm">Metode Pembayaran</p>
              <p className="text-xs">{data.dataOrderDetail?.payment_metadata?.['payment_type_name']}</p>
            </div>
            <div>
              <p className="font-bold text-sm">Jasa Kirim</p>
              <img
                width={120}
                src={data.dataOrderDetail?.delivery_metadata?.['shipping_method']?.['icon']}
              />
              <p className="text-xs mb-4">
                {data.dataOrderDetail?.delivery_metadata?.['provider_name']} - {data.dataOrderDetail?.delivery_metadata?.['type']}
              </p>
            </div>
            <div>
              <p className="font-bold text-sm">Informasi Penerima</p>
              <p>{data.dataOrderDetail?.shipping_address_detail?.receiver_name} ({data.dataOrderDetail?.shipping_address_detail?.phone_number})</p>
              <p>{data.dataOrderDetail?.shipping_address_detail?.address}</p>
              <p>{data.dataOrderDetail?.shipping_address_detail?.districts?.['name']}</p>
              <p>{data.dataOrderDetail?.shipping_address_detail?.cities?.['name']}</p>
              <p>{data.dataOrderDetail?.shipping_address_detail?.provinces?.['name']}</p>
              <p>{data.dataOrderDetail?.shipping_address_detail?.postal_code}</p>
            </div>
            <div className="max-w-[121px]">
              <p className="font-bold text-sm">Tanggal Pesanan</p>
              <p className="text-sm text-[#6B7280]">{data.orderDate}</p>
            </div>
            <div>
              <p className="font-bold text-sm py-1">Pelacakan ID</p>
              <p className="text-xs py-1">{data.dataOrderDetail?.awb_no}</p>

              <Button className="!bg-orange-300 !rounded-lg !text-black !text-sm"
                onClick={method.handleTrackOrder} >Lacak Pesanan</Button>
            </div>
          </div>
        </div>

        <div className="w-1/2">
          <p className="font-bold text-lg mb-2">Status Pesanan</p>
          {data.trackingStatus ? (
            <ol className="items-center sm:flex overflow-x-scroll">
              {data.trackingStatus?.map((tracking, i) => {
                return (
                  <li key={i} className="relative mb-6 h-40 sm:mb-0">
                    <div className="flex items-center">
                      <div className="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full shrink-0">
                        <svg aria-hidden="true" className="w-3 h-3 text-blue-800 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" /></svg>
                      </div>
                      <div className="hidden sm:flex w-full bg-gray-200 h-0.5" />
                    </div>
                    <div className="mt-3  sm:pr-8">
                      <h3 className="text-base font-semibold text-gray-900">{tracking.status}</h3>
                      <time className="block mb-2 text-sm font-normal leading-none text-gray-500">
                        {dayjs(tracking.created_at).format('ddd, DD MMM YYYY HH:mm:ss')}
                      </time>
                    </div>
                  </li>
                );
              })}
            </ol>

          ) : null}
        </div>
      </div>

      <p className="font-bold text-lg mt-8 mb-2">PO Items</p>
      <div className="w-full flex bg-slate-200">
        <div className="flex p-4 flex-col w-full gap-2">
          <hr className="h-4 mb-4 bg-slate-300" />
          <div className="flex items-start px-6 w-full">
            <div className="flex flex-col gap-4 w-1/2">
              {data.dataOrderDetail?.order_list?.map((product) => {
                return (
                  <div key={product?.['product_metadata']?.['product']?.id} className="flex items-start justify-start gap-4 w-full h-full">
                    <Image
                      id="order-product-image"
                      zoomSrc={product?.['product_metadata']?.['product']?.['images']?.[0]}
                      src={product?.['product_metadata']?.['product']?.['images']?.[0]}
                      alt={product?.['product_metadata']?.['product']?.['name'] ?? 'product image'}
                      className="w-16 h-16 object-cover"
                      preview
                    />
                    <div className="flex flex-col gap-2">
                      <p className="font-bold text-base">
                        {product?.['product_metadata']?.['product']?.['name']}
                      </p>
                      <p className="text-sm">{product?.['product_metadata']?.['product']?.['sku']}</p>
                      <p className="text-sm">&quot;{product?.['notes'] || '-'}&quot;</p>
                      {product?.['product_metadata']?.['attribute_set_variants']?.length > 0 && (
                        <p className="text-sm">
                          Variant: (
                          {product?.['product_metadata']?.['attribute_set_variants']
                            ?.map((variant) => variant?.['value'])
                            ?.join(', ')
                          })
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-sm">{`${product?.['quantity']} pcs x ${formatRupiah(product?.['amount'] ?? 0)}`}</p>
                      <p className="text-sm">(Berat {(product?.['product_metadata']?.['products']?.['volume']?.['weight'] || 0)})</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-1/2 self-end bg-slate-300 p-3">
            <div className="flex items-center justify-between">
              <p className="font-bold text-sm">Subtotal ({data.dataOrderDetail?.order_list?.length} Barang)</p>
              <p className="text-xs">{formatRupiah(data.dataOrderDetail?.total_amount || 0)}</p>
            </div>
            <div className="flex items-center justify-between">
              <p className="font-bold text-sm">Biaya Pengiriman ({shippingName})</p>
              <p className="text-xs">{formatRupiah(data.dataOrderDetail?.shipping_fee || 0)}</p>
            </div>
            <div className="flex items-center justify-between">
              <p className="font-bold text-sm">Asuransi Pengiriman</p>
              <p className="text-xs">{formatRupiah(data.dataOrderDetail?.insurance_fee || 0)}</p>
            </div>
            <div className="flex items-center justify-between">
              <p className="font-bold text-sm">Diskon Pengiriman</p>
              <p className="text-xs">{formatRupiah((data.dataOrderDetail?.['shipping_discount'] || 0))}</p>
            </div>
            <div className="flex items-center justify-between">
              <p className="font-bold text-sm">Diskon Promo</p>
              <p className="text-xs">{formatRupiah((data.dataOrderDetail?.discount || 0))}</p>
            </div>
            <div className="flex items-center justify-between">
              <p className="font-bold text-sm">Total Transaksi</p>
              <p className="text-xs">{formatRupiah(((data.dataOrderDetail?.total_amount || 0) + (data.dataOrderDetail?.shipping_fee || 0) + (data.dataOrderDetail?.insurance_fee || 0)) - (data.dataOrderDetail?.discount || 0) - (data.dataOrderDetail?.['shipping_discount'] || 0) )}</p>
            </div>
          </div>
        </div>
      </div>
    </BaseContainer>
  );
};

export default DetailOrderManagement;
