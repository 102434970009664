import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { IPagination } from '@/types/pagination';
import { Database as DatabaseCatalogue } from '@/types/services/catalogue';
import { Database as DatabaseTrade } from '@/types/services/trade';
import { Database as DatabaseUser } from '@/types/services/user';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';


type Orders = DatabaseTrade['public']['Tables']['orders']['Row']
type Carts = DatabaseTrade['public']['Tables']['carts']['Row']
type ProductFavorites = DatabaseCatalogue['public']['Tables']['product_favorites']['Row']
type ShippingAddress = DatabaseUser['public']['Tables']['shipping_addresses']['Row']
type Accounts = DatabaseUser['public']['Tables']['accounts']['Row']

// TODO: handle type platform
interface IOrders extends Orders {
  platform: string;
}

interface IWishList extends ProductFavorites {
  platform: string;
}

interface IPayloadFilter{
  gender:Array<string>
  name:Array<string>
  email:Array<string>
}

type DataCustomer = Accounts & ShippingAddress;

const getDataOrderByBuyerId = async (buyerId: string) => {
  return await supabase
    .from(TABLE.ORDERS)
    .select('*, order_list(*)')
    .eq('buyer_account_id', buyerId) as PostgrestSingleResponse<IOrders[]>;
};

const getDataCartByBuyerId = async (buyerId: string) => {
  return await supabase
    .from(TABLE.CARTS)
    .select('*')
    .eq('buyer_account_id', buyerId) as PostgrestSingleResponse<Carts[]>;
};

const getProductVariantsById = async (ids: string[]) => {
  return await supabase
    .from(TABLE.PRODUCT_VARIANTS)
    .select('id, product_id')
    .in('id', ids);
};

const getProductById = async (ids: number[]) => {
  return await supabase
    .from(TABLE.PRODUCT)
    .select('id, name, sku, main_price')
    .in('id', ids);
};

const getCustomerById = async (id: string) => {
  return await supabase
    .from(TABLE.ACCOUNTS)
    .select('first_name, last_name')
    .eq('id', id)
    .single();
};

const getProductFavorites = async (buyerId: string) => {
  return await supabase
    .from(TABLE.PRODUCT_FAVORITES)
    .select('*')
    .eq('buyer_id', buyerId) as PostgrestSingleResponse<IWishList[]>;
};

const getShippingAddressByAccountId = async (accountId: string) => {
  return await supabase
    .from(TABLE.SHIPPING_ADDRESSES)
    .select('*')
    .eq('account_id', accountId) as PostgrestSingleResponse<ShippingAddress[]>;
};

export const getShippingAddressByName = async (name: string) => {
  return await supabase
    .from(TABLE.SHIPPING_ADDRESSES)
    .select('*, provinces!inner(name)')
    .eq('is_primary', true)
    .ilike('provinces.name', `%${name}%`) as PostgrestSingleResponse<ShippingAddress[]>;
};

const getCityById = async (cityIds: number[]) => {
  return await supabase
    .from(TABLE.CITIES)
    .select('id, name')
    .in('id', cityIds);
};

const getProvinceById = async (provinceIds: number[]) => {
  return await supabase
    .from(TABLE.PROVINCE)
    .select('id, name')
    .in('id', provinceIds);
};

const getDataCustomer = async (range: IPagination = { start: 0, end: 10 }, filter: IPayloadFilter, search?: string) => {
  const query = supabase
    .from(TABLE.ACCOUNTS)
    .select(
      `
      *, shipping_addresses (
        is_primary,
        provinces (name)
      )
      `, { count: 'exact' }
    ).eq('shipping_addresses.is_primary', true)
    .neq('first_name', '')
    .not('phone_number', 'is', null)
    .not('email', 'is', null)
    .ilike('type', '%customer%')
    .or('type.is.null')
    .range(range.start, range.end);

  if (search && !isNaN(+search)) {
    query.textSearch('first_name_email_phone_number', search);
  }

  if (filter.gender && filter.gender.length > 0) {
    query.in('gender', filter.gender);
  }

  if (filter.email && filter.email.length > 0) {
    query.in('email', filter.email);
  }

  if (filter.name && filter.name.length > 0) {
    const firstName = filter.name.map((it) => {
      const nameSplit = it.split(' ');
      return nameSplit[0];
    });

    const lastName = filter.name.map((it) => {
      const nameSplit = it.split(' ');
      if (nameSplit.length >= 3) {
      // last name when more than 3 words
        return [nameSplit.slice(1).join(' ')];
      }
      return nameSplit[1];
    });

    if (firstName) {
      query.in('first_name', firstName);
    }

    if (lastName[0] || lastName.length > 1) {
      query.in('last_name', lastName);
    }
  }

  return await query as PostgrestSingleResponse<DataCustomer[]>;
};

export const useGetDataCustomer = (range: IPagination = { start: 0, end: 10 }, filter: IPayloadFilter, search?: string) =>
  useQuery({
    queryKey: ['getCustomer', range, filter, search],
    queryFn: async () => await getDataCustomer(range, filter, search),
    enabled: !!(range.start && range.end),
    refetchOnWindowFocus: false
  });

export const useGetOrders = (buyerId: string) =>
  useQuery({
    queryKey: ['getOrders', buyerId],
    queryFn: async () => await getDataOrderByBuyerId(buyerId),
    enabled: !!buyerId,
    refetchOnWindowFocus: false
  });

export const useGetDataCarts = (buyerId: string) =>
  useQuery({
    queryKey: ['getCarts', buyerId],
    queryFn: async () => await getDataCartByBuyerId(buyerId),
    enabled: !!buyerId,
    refetchOnWindowFocus: false
  });

export const useGetProductVariantById = (ids: string[]) =>
  useQuery({
    queryKey: ['getProductVariants', ids],
    queryFn: async () => await getProductVariantsById(ids),
    enabled: !!(ids.length > 0),
    refetchOnWindowFocus: false
  });

export const useGetProductsById = (ids: number[]) =>
  useQuery({
    queryKey: ['getProducts', ids],
    queryFn: async () => await getProductById(ids),
    enabled: !!(ids.length > 0),
    refetchOnWindowFocus: false
  });

export const useGetCustomerById = (id: string) =>
  useQuery({
    queryKey: ['getCustomer', id],
    queryFn: async () => await getCustomerById(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });

export const useGetProductFavorites = (buyerId: string) =>
  useQuery({
    queryKey: ['getProductFavorites', buyerId],
    queryFn: async () => await getProductFavorites(buyerId),
    enabled: !!buyerId,
    refetchOnWindowFocus: false
  });

export const useGetShippingAddress = (accountId: string) =>
  useQuery({
    queryKey: ['getShippingAddress', accountId],
    queryFn: async () => await getShippingAddressByAccountId(accountId),
    enabled: !!accountId,
    refetchOnWindowFocus: false
  });

export const useGetCitiesById = (cityIds: number[]) =>
  useQuery({
    queryKey: ['getCities', cityIds],
    queryFn: async () => await getCityById(cityIds),
    enabled: !!(cityIds.length > 0),
    refetchOnWindowFocus: false
  });

export const useGetProvinceById = (provinceIds: number[]) =>
  useQuery({
    queryKey: ['getProvince', provinceIds],
    queryFn: async () => await getProvinceById(provinceIds),
    enabled: !!(provinceIds.length > 0),
    refetchOnWindowFocus: false
  });
