import { Button, FiltersIcon, SelectInput, Table, TextInput } from 'akeneo-design-system';
import React, { useContext } from 'react';
import { CategoryContext } from '../hooks';
import ModalFilterProduct from './ModalFilterProduct';
import ModalMoveAllCategory from './ModalMoveAllCategory';
import ModalMoveCategory from './ModalMoveCategory';

const TabProduct = () => {
  const category = useContext(CategoryContext);
  const {
    data: {
      productsData,
      pageProduct,
      directionTableProduct,
      optionDataPerPage,
      tableProductColumns,
      dataProductsPerPage,
      isModalFilterOpen,
      isModalMoveCategoryOpen,
      moveCategoryProduct,
      isModalMoveAllCategoryOpen,
      filterProducts,
      activeFilterProduct
    },
    methods: {
      onSortTableProduct,
      onInputPagination,
      onNextPagination,
      onPrevPagination,
      onChangeProductPerPage,
      onSelectActionProduct,
      onMoveCategory,
      onFilterProducts,
      onDownloadTemplate,
      onUploadMoveAll,
      onSearchProduct,
      onOpenModalFilter,
      onOpenModalMoveAllCategory,
      onCloseModalFilter,
      onCloseModalMoveCategory,
      onCloseModalMoveAllCategory
    }
  } = category();

  return (
    <div id="tab-product" className="p-8 w-full flex flex-col gap-8">
      <div className="w-full flex justify-end gap-6">
        <Button id="btn-move-all" level="secondary" className="btn-move-all" onClick={onOpenModalMoveAllCategory}>
          Pindah Sekaligus
        </Button>
        <Button id="btn-filter" level="tertiary" className="btn-filter" onClick={onOpenModalFilter}>
          <FiltersIcon color="#ff8500" size={24} />
          Filter
        </Button>
      </div>
      {!!activeFilterProduct && (
        <div id="filter-product">
          <div className="flex gap-4 items-center py-2">
            <div className="font-semibold text-black min-w-max">Filter Aktif:</div>
            <div className="flex flex-wrap gap-2 items-center" id="active-filter">
              {activeFilterProduct}
            </div>
          </div>
        </div>
      )}
      <div id="table-control" className="flex justify-between">
        <div className="w-2/6">
          <TextInput
            id="search-input"
            onChange={onSearchProduct}
            placeholder="Keyword Pencarian"
            value={filterProducts.search}
          />
        </div>
        <div className="flex gap-4">
          <div className="flex gap-2 items-center">
            <div className="w-28">
              <SelectInput
                id="per-page-select"
                openLabel=""
                emptyResultLabel="No result found"
                onChange={onChangeProductPerPage}
                placeholder="-"
                value={String(dataProductsPerPage)}
              >
                {optionDataPerPage.map((data) => (
                  <SelectInput.Option key={data} value={data as string} id={`per-page-select-item-${data}`}>
                    {data}
                  </SelectInput.Option>
                ))}
              </SelectInput>
            </div>
            <p className="text-black font-medium">per Halaman</p>
          </div>
          <div id="pagination-category-product" className="flex items-center gap-4">
            <div
              id="pagination-prev"
              className="h-10 w-16 flex items-center justify-center border border-black text-3xl font-medium cursor-pointer"
              onClick={onPrevPagination}
            >
              &lt;
            </div>
            <div className="h-10 w-16 flex items-center justify-center border border-black text-black">
              <input
                id="pagination-page"
                className="w-full text-center border-none bg-none focus:outline-none"
                type="number"
                value={pageProduct}
                onChange={onInputPagination}
              />
            </div>
            <div className="font-medium">dari 120</div>
            <div
              id="pagination-next"
              className="h-10 w-16 flex items-center justify-center border border-black text-3xl font-medium cursor-pointer"
              onClick={onNextPagination}
            >
              &gt;
            </div>
          </div>
        </div>
      </div>
      <div id="table-products">
        {productsData?.length ? (
          <Table className="table-products" id="table-products">
            <Table.Header>
              {tableProductColumns.map((column) => (
                <Table.HeaderCell
                  key={column.value}
                  isSortable={column.value === 'productId'}
                  onDirectionChange={column.value === 'productId' ? onSortTableProduct : undefined}
                  sortDirection={column.value === 'productId' ? directionTableProduct : undefined}
                >
                  <div
                    className={`inline-block text-center ${
                      column.value === 'productId' ? 'w-1/2 text-right' : 'w-full -mx-2.5'
                    }`}
                  >
                    {column.label}
                  </div>
                </Table.HeaderCell>
              ))}
              <Table.HeaderCell>
                <div className="w-full text-center">Action</div>
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {productsData.map((product) => (
                <Table.Row key={product?.['producId']}>
                  {tableProductColumns.map((column) => (
                    <Table.Cell key={column.value}>
                      <div className="w-full text-center">{product[column.value]}</div>
                    </Table.Cell>
                  ))}
                  <Table.Cell className="w-40">
                    <SelectInput
                      id="action-product"
                      openLabel=""
                      emptyResultLabel="No result found"
                      onChange={(e: string) => onSelectActionProduct(e, product)}
                      placeholder=""
                      value={null}
                    >
                      <SelectInput.Option title="" value="detail" id="action-product-detail">
                        Lihat Detail
                      </SelectInput.Option>
                      <SelectInput.Option title="" value="move" id="action-product-move">
                        Pindah Kategori
                      </SelectInput.Option>
                    </SelectInput>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ) : (
          <div className="w-full flex items-center justify-center text-xl" id="product-empty">
            <p>Tidak ada produk!</p>
          </div>
        )}
      </div>
      {isModalFilterOpen && <ModalFilterProduct onFilter={onFilterProducts} onCancel={onCloseModalFilter} />}
      {isModalMoveCategoryOpen && (
        <ModalMoveCategory product={moveCategoryProduct} onMove={onMoveCategory} onCancel={onCloseModalMoveCategory} />
      )}
      {isModalMoveAllCategoryOpen && (
        <ModalMoveAllCategory
          onUpload={onUploadMoveAll}
          onDownloadTemplate={onDownloadTemplate}
          onClose={onCloseModalMoveAllCategory}
        />
      )}
    </div>
  );
};

export default TabProduct;
