import React from 'react';

interface ICSpinnerProps {
  text?: string;
}

const Spinner = ({ text }: ICSpinnerProps) => {
  return (
    <div className="flex flex-col gap-2 items-center justify-center">
      <div
        className="border-primary spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
        role="status"
      />
      {text && <span>{text}</span>}
    </div>
  );
};

export default Spinner;
