import React, { FC } from 'react';
import { headerVariant, useCustom } from './hooks';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import IconShow from '@/assets/IconShow';
import IconImage from '@/assets/IconImage';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { IProduct } from '@/services/rest/product';
import { InputSwitch } from 'primereact/inputswitch';
import { usePermissionStore } from '@/store/usePermissions';
import Pagination from '@/components/base/Pagination';
import { Divider } from 'primereact/divider';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import FilterHistory from '@/components/base/FilterHistory';
import { Dialog } from 'primereact/dialog';
import { Link } from 'react-router-dom';

const ProductList: FC = () => {
  const { data, method } = useCustom();
  const { canView } = usePermissionStore();
  const rowExpansionTemplate = (data: IProduct) => {
    const statusTemplate = (data) => {
      return (
        <div>
          <InputSwitch checked={data.is_active} id="status" disabled
            onChange={(e)=>method.handleListVariantSwitch(e.value as boolean, data.id)}
          />
        </div>
      );
    };
    const variantTemplate = (data) => {
      return <div>{data.is_primary_variant ? 'Variant Utama' : 'Variant Tambahan'}</div>;
    };

    const mappedVariants = data.product_variants?.map((pv)=> {
      const attrib = pv.attribute_set_variants?.filter((i)=> i.product_variant_id === pv.id);
      return ({
        ...pv,
        attribute_set_variants: attrib,
        ui_id: pv.ref_id || pv.id
      });
    }).filter((pvs)=> pvs.attribute_set_variants != undefined && pvs.attribute_set_variants?.length > 0);
    const skuTemplate = (rowData)=>{
      return <div className="flex flex-col gap-2">
        <span className="font-bold">
          {rowData.attribute_set_variants?.map((v)=>v.value).join('-')}
        </span>
        SKU: {rowData.sku_id}
      </div>;
    };
    const saleableTemplate = (rowData) => {
      if (rowData.is_digital && rowData.attribute_set_variants.length > 0 && rowData.saleable_stock_product_digitals) {
        const mappedSaleable = rowData.saleable_stock_product_digitals?.filter((item) => item.product_variant_id === rowData.id);
        const salables = mappedSaleable[0]?.stock || 0;
        return (
          <span>{salables}</span>
        );
      }
      return <span>{rowData.saleable_stock}</span>;
    };
    const stockTemplate = (rowData) => {
      if (rowData.is_digital && rowData.attribute_set_variants.length > 0) {
        const mappedStocks = rowData?.stock_product_digitals?.filter((item)=> item.product_variant_id === rowData.id);
        return (
          <span>{mappedStocks?.length || 0}</span>
        );
      }
      return <span>{Number(rowData.saleable_stock) + Number(rowData.inflight_stock)}</span>;
    };
    const stockFlashsale = (rowData) => {
      if (rowData?.product_flashsale?.length > 0) {
        const mappedflash = rowData?.product_flashsale?.find((pv) => pv.periode.isEnable === true && pv.periode.isExpired === false);
        return (
          <span>{mappedflash?.saleable_stock || 0}</span>
        );
      }
    };
    return (
      <div className="p-3">
        <DataTable id="product-data-table" value={mappedVariants} scrollable scrollHeight="15rem">
          <Column field="ui_id" header="Variant ID" alignHeader="center" align="center" />
          <Column key={`sku${data.id}`} field="sku_id" header="SKU" alignHeader="center" align="center" body={skuTemplate} />
          <Column
            field="is_primary_variant"
            header="Variant utama"
            alignHeader="center"
            align="center"
            body={variantTemplate}
          />
          {headerVariant.map((item) => (
            <Column key={item.field} field={item.field} header={item.header} alignHeader="center" align="center" />
          ))}
          <Column
            key={`stock${data.id}`}
            header="Stock"
            alignHeader="center"
            align="center"
            body={stockTemplate}
          />
          <Column
            key={`stock${data.id}`}
            field="saleable_stock"
            header="Saleable"
            alignHeader="center"
            align="center"
            body={saleableTemplate}
          />
          <Column
            key={`stock${data.id}`}
            field="flashsale_stock"
            header="Flashsale Stock"
            alignHeader="center"
            align="center"
            body={stockFlashsale}
          />
          <Column field="is_active" header="Status"
            alignHeader="center" align="center"
            body={statusTemplate} />
        </DataTable>
      </div>
    );
  };

  const infoProduct = (prod: IProduct) => {
    const totalFavorite = prod.product_variants?.map((item) => item.product_favorites?.length).reduce((a, b)=> (a! + b!), 0);
    const totalViews = prod.product_variants?.map((item) => item.account_history_logs?.length).reduce((a, b)=> (a! + b!), 0);
    return (
      <div className="flex gap-3 items-center">
        <div className="w-1/5">
          {prod.images?.length > 0 ? (
            <Image
              id="product-image"
              src={prod.images[0]}
              zoomSrc={prod.images[0]}
              alt={`image${prod.id}`}
              className="mx-0.5 w-full"
              width="70px"
              preview
            />
          ) : (
            <div className="h-15 w-15">
              <IconImage />
            </div>
          )}
        </div>
        <div className="flex flex-col w-4/5">
          <div className="font-bold text-left">{prod.name}</div>
          <div className="text-sm text-left">{prod.sku}</div>
          <div className="flex gap-4">
            <div className="flex items-center gap-1">
              <i className="pi pi-heart-fill text-red-300 rounded-full p-0.5" />
              { totalFavorite ? Number(totalFavorite) : 0}
            </div>
            <div className="flex items-center gap-1">
              <i className="pi pi-eye text-green-600 rounded-full p-0.5" />
              {totalViews ? Number(totalViews): 0}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const allowExpansion = (rowData: IProduct) => {
    if (rowData.product_variants?.length) {
      const datas = rowData.product_variants.flatMap((item)=> item.attribute_set_variants?.filter((v)=> v.product_variant_id === item.id)).filter((item)=> item != undefined);
      return datas.length > 0;
    }
    return false;
  };

  const statusProduct = (rowData) => {
    return (
      <InputSwitch checked={Boolean(rowData.is_active)}
        id="product-list-status"
        onChange={(e)=>method.handleListSwitch(e.value as boolean, rowData.id)}
        disabled={rowData.status === 'IN_REVIEW' || rowData.status === 'NEED_REVISION' || rowData.status === 'NEED_REVISION_UPDATE'}
      />
    );
  };

  const actionButton = (rowData) => (
    <Link to={canView ? `${rowData.id}` : ''}>
      <Button
        id="product-detail-button"
        icon={IconShow}
        outlined
        text
        severity="secondary"
        disabled={!canView}
      />
    </Link>
  );

  const optionStatusProduct = [
    { label: 'APPROVED', value: 'APPROVED' },
    { label: 'IN_REVIEW', value: 'IN_REVIEW' },
    { label: 'NEED_REVISION', value: 'NEED_REVISION' },
    { label: 'IN_REVIEW_UPDATE', value: 'IN_REVIEW_UPDATE' },
    { label: 'NEED_REVISION_UPDATE', value: 'NEED_REVISION_UPDATE' }
  ];

  const filterContent = (
    <div className="flex flex-col gap-4 w-[900px]">
      <div className="w-full flex flex-col items-start gap-2">
        <h1 className="font-bold">ID</h1>
        <div className="flex w-full gap-8">
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Dari</label>
            <InputNumber
              onChange={(e) => method.handleFilter(e, 'id_from')}
              value={data.itemFilters.id_from}
              name="id_from"
            />
          </div>
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Sampai</label>
            <InputNumber
              onChange={(e) => method.handleFilter(e, 'id_to')}
              value={data.itemFilters.id_to}
              name="id_to"
            />
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-start gap-2">
        <h1 className="font-bold">Harga</h1>
        <div className="flex w-full gap-8">
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Dari</label>
            <InputNumber
              onChange={(e) => method.handleFilter(e, 'price_from')}
              value={data.itemFilters.price_from}
              name="price_from"
            />
          </div>
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Sampai</label>
            <InputNumber
              onChange={(e) => method.handleFilter(e, 'price_to')}
              value={data.itemFilters.price_to}
              name="price_to"
            />
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-start gap-2">
        <h1 className="font-bold">Harga Spesial</h1>
        <div className="flex w-full gap-8">
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Dari</label>
            <InputNumber
              onChange={(e) => method.handleFilter(e, 'special_price_from')}
              value={data.itemFilters.special_price_from}
              name="special_price_from"
            />
          </div>
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Sampai</label>
            <InputNumber
              onChange={(e) => method.handleFilter(e, 'special_price_to')}
              value={data.itemFilters.special_price_to}
              name="special_price_to"
            />
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-start gap-2">
        <h1 className="font-bold">Saleable Stock</h1>
        <div className="flex w-full gap-8">
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Dari</label>
            <InputNumber
              onChange={(e) => method.handleFilter(e, 'saleable_from')}
              value={data.itemFilters.saleable_from}
              name="saleable_from"
            />
          </div>
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Sampai</label>
            <InputNumber
              onChange={(e) => method.handleFilter(e, 'saleable_to')}
              value={data.itemFilters.saleable_to}
              name="saleable_to"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Product Name</label>
          <InputText
            onChange={(e) => method.handleFilter(e, 'name')}
            value={data.itemFilters.name}
            name="name"
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Merchant Name</label>
          <InputText
            onChange={(e) => method.handleFilter(e, 'merchant_name')}
            value={data.itemFilters.merchant_name}
            name="merchant_name"
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">SKU</label>
          <InputText
            onChange={(e) => method.handleFilter(e, 'sku')}
            value={data.itemFilters.sku}
            name="sku"
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Status Product</label>
          <Dropdown
            options={optionStatusProduct}
            onChange={(e) => method.handleFilter(e, 'status_product')}
            value={data.itemFilters.status_product}
            placeholder="Select Status Product"
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Status</label>
          <Dropdown
            options={[{ label: 'Active', value: true }, { label: 'Inactive', value: false }]}
            onChange={(e) => method.handleFilter(e, 'status')}
            value={data.itemFilters.status}
            placeholder="Select Status"
          />
        </div>
      </div>
    </div>
  );

  const footerContent = (
    <div className="flex justify-end py-4 gap-4">
      <Button label="Batal" severity="secondary" outlined onClick={() => method.setIsOpenDialog(false)} size="large" />
      <Button label="Terapkan" severity="info" onClick={method.handleClickSubmitFilter} size="large" />
    </div>
  );

  return (
    <>
      <BaseContainer>
        <LoadingOverlay loadingText={data.isExportLoading ? 'Exporting Data...' : undefined} show={data.isExportLoading || data.isLoading} />
        <MainTitle title="Products" nav="" />
        <div className="flex flex-col gap-2 my-4">
          <div className="flex justify-end">
            <Button
              icon="pi pi-sliders-h"
              onClick={() => method.setIsOpenDialog(true)}
              label="Filter"
              severity="secondary"
              text
              raised
            />
          </div>
          {data.filterHistory && data.filterHistory.length > 0 ?
            <FilterHistory
              filterHistory={data.filterHistory}
              handleClearFilter={method.handleClearFilter}
              handleDeleteFilterHistory={method.handleDeleteFilterHistory}
              show={data.filterHistory?.length > 0}
            /> :
            <Divider className="p-divider-solid" />
          }
          <div className="flex gap-4 justify-between items-center">
            <div id="search" className="w-[20%]">
              <InputText
                id="product-search-input"
                className="w-full"
                placeholder="Cari Disini"
                type="search"
                value={data.search}
                onInput={method.handleSearch}
              />
            </div>
            <div className="flex items-center justify-end gap-6 w-full">
              <h4 className="font-bold justify-self-end"> {data.totalRecords} data ditemukan</h4>
              <Pagination
                perPage={data.perPage}
                totalPages={data.totalPages}
                currentPage={data.paginator.currentPage}
                disabledDropdown={data.totalRecords === 0}
                onClickPrev={method.handleClickPrev}
                onClickNext={method.handleClickNext}
                onChangeDropdownPage={method.handleChangeDropdownPage}
                onChangeJumptoPage={method.handleChangeJumpTopage}
                onJumptoPage={method.handleJumpToPage}
              />
            </div>
          </div>
        </div>
        <div className="card">
          <DataTable
            id="product-data-table"
            value={data.dataProduct as []}
            rows={data.perPage}
            removableSort
            scrollable
            scrollHeight="36rem"
            expandedRows={data.expanded}
            onRowToggle={(e) => method.setExpanded(e.data)}
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="id"
            showGridlines
            tableStyle={{ minWidth: '12rem' }}
            loading={data.isFetchingProduct}
            onSort={method.handleChangeSort}
          >
            <Column alignHeader="center" align="center" expander={allowExpansion} style={{ width: '65px' }} />
            <Column
              alignHeader="center"
              align="center"
              field="ui_id"
              sortable
              header="ID"
              style={{ maxWidth: '5rem' }}
            />
            <Column
              alignHeader="center"
              align="center"
              sortable
              sortField="name"
              field="name"
              header="Product Info"
              body={infoProduct}
              style={{ maxWidth: '12rem' }}
            />
            <Column alignHeader="center" align="center" sortable field="merchants.name" header="Merchants" />
            <Column alignHeader="center" align="center" sortable field="main_price" header="Price" />
            <Column alignHeader="center" align="center" sortable field="special_price" header="Special Price" />
            <Column alignHeader="center" align="center" sortable field="total_stock" header="Stok" />
            <Column alignHeader="center" align="center" sortable field="stock_flashsale" header="Flash Sale Stok" />
            <Column alignHeader="center" align="center" sortable field="saleable_stock" header="Saleable" />
            <Column alignHeader="center" align="center" sortable field="status" header="Status Product" />
            <Column
              alignHeader="center"
              align="center"
              sortable
              field="is_active"
              header="Status"
              body={statusProduct}
            />
            <Column
              header="Action"
              alignHeader="center"
              align="center"
              style={{ width: '6rem' }}
              body={actionButton}
            />
          </DataTable>
        </div>
      </BaseContainer>
      <Dialog onHide={() => method.setIsOpenDialog(false)} visible={data.isOpenDialog} header="Semua Filter" footer={footerContent}>
        {filterContent}
      </Dialog>
    </>
  );
};

export default ProductList;
