import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ReactElement } from 'react';
import useTabGroupInformation from './hooks';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { useNavigate } from 'react-router-dom';
import { InputNumber } from 'primereact/inputnumber';
import { usePermissionStore } from '@/store/usePermissions';

interface TabGroupInformationInterface {
  form?: {
    id?: number | null;
    name?: string;
    description?: string;
    count?: number;
    created_at?: string;
    status?: string;
  } | null;
  isLoading: boolean;
}

const TabGroupInformation = ({ form, isLoading }: TabGroupInformationInterface): ReactElement => {
  const {
    method
  } = useTabGroupInformation();
  const navigate = useNavigate();
  const { canEdit } = usePermissionStore();
  return (
    <>
      <div className="border border-mainly-blue rounded-md min-h-full p-10">
        <div className="flex justify-end mt-2.5 mb-5 gap-2">
          <Button onClick={() => navigate('/user-management')} id="user-group-edit-nama" label="Back" />
          <Button onClick={()=>method.handleClickEdit(form?.id || null)} disabled={!canEdit} id="user-group-edit-nama" label="EDIT NAME & DESCRIPTION GROUP" />
        </div>
        <LoadingOverlay show={isLoading} />
        <div className="grid grid-cols-12 items-center gap-x-2 gap-y-2.5">
          <div className="col-span-2 flex justify-between">
            <span>Name *</span>
            <span>:</span>
          </div>
          <div className="col-span-10 w-full">
            <InputText id="user-group-name" value={form?.name || ''} className="w-full" disabled />
          </div>
          <div className="col-span-2 flex justify-between">
            <span>Description</span>
            <span>:</span>
          </div>
          <div className="col-span-10">
            <InputText id="user-group-description" value={form?.description || ''} className="w-full" disabled />
          </div>
          <div className="col-span-2 flex justify-between">
            <span>Number of Member</span>
            <span>:</span>
          </div>
          <div className="col-span-10">
            <InputNumber id="user-group-count" value={form?.count || 0} className="w-full" disabled />
          </div>
          <div className="col-span-2 flex justify-between">
            <span>Created Date</span>
            <span>:</span>
          </div>
          <div className="col-span-10">
            <InputText id="user-group-created-at" value={form?.created_at || ''} className="w-full" disabled />
          </div>
          <div className="col-span-2 flex justify-between">
            <span>Group Status *</span>
            <span>:</span>
          </div>
          <div className="col-span-10">
            <InputText id="user-group-status" value={form?.status ? 'Active' : 'Inactive'} className="w-full" disabled />
          </div>
        </div>
      </div>
    </>
  );
};

export default TabGroupInformation;
