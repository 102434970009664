import React, { FC } from 'react';


import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import IconCancel from '@/assets/IconCancel';
import { IMenuPermission } from '../hooks';
import { Button } from 'primereact/button';

type PropsModalPermission = {
  onCloseFilterModal: () => void;
  handleEdit: (id) => void;
  id: number | undefined
  roleName: string | undefined
  description: string
  dataFeaturePermissions: IMenuPermission[];
  isLoading: boolean
}

const DetailPermission: FC<PropsModalPermission> = ({ onCloseFilterModal, id, roleName, description, dataFeaturePermissions, isLoading, handleEdit }) => {
  const handleClose = () => {
    onCloseFilterModal();
  };

  const onClickEdit = () => {
    handleEdit(id);
  };
  return (
    <>
      <div id="user-popip-detail" className="bg-black bg-opacity-30 w-screen h-screen fixed top-0 left-0 z-50 flex justify-center items-center">
        <div className="bg-white relative p-6 z-50 w-[628px] rounded-lg h-[723px]">
          <div className="flex justify-between">
            <h1 className="font-bold text-xl">BOS Feature Permission</h1>
            <button type="button" id="user-close-button" onClick={() => handleClose()}><IconCancel /></button>
          </div>
          <div className="flex justify-between items-center mt-4">
            <p className="font-normal">{id}</p>
            <Button id="user-edit-button" onClick={() => onClickEdit()} text>Edit</Button>
          </div>
          <h1 className="font-bold text-xl">{roleName}</h1>
          <p className="mt-6 text-xs font-medium mb-2">Description</p>
          <div className="flex justify-content-center mb-10">
            <InputTextarea
              id="user-detail-description"
              autoResize
              value={description}
              className="w-full"
              onChange={(e) => {}}
              rows={5}
              cols={30} />
          </div>
          <div className="flex flex-col gap-8">
            <div id="detail permisions">
              <div className="flex justify-between">
                <h2 className="w-1/2 font-semibold">Feature</h2>
                <div className="flex justify-around w-1/2">
                  <span className="font-semibold">Read</span>
                  <span className="font-semibold">Create</span>
                  <span className="font-semibold">Edit</span>
                  <span className="font-semibold">Delete</span>
                </div>
              </div>
            </div>
            <LoadingOverlay show={isLoading} />
            <div className="overflow-y-scroll h-[280px]">
              {dataFeaturePermissions.map((item, index) => (
                <div key={index}>
                  <div className="flex justify-between mt-6">
                    <h2 className="w-1/2 font-medium">{item.name}</h2>
                    <div className="grid grid-cols-4 w-1/2">
                      <div className="flex justify-center">
                        <Checkbox
                          id="user-read-checkbox"
                          checked={item.read}
                          onChange={() => {}} />
                      </div>
                      <div className="flex justify-center">
                        <Checkbox
                          id="user-create-checkbox"
                          checked={item.create}
                          onChange={() => {}} />
                      </div>
                      <div className="flex justify-center">
                        <Checkbox
                          id="user-update-checkbox"
                          checked={item.update}
                          onChange={() => {}} />
                      </div>
                      <div className="flex justify-center">
                        <Checkbox
                          id="user-delete-checkbox"
                          checked={item.delete}
                          onChange={() => {}} />

                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-8 mt-8">
                    {item.children !== undefined && item.children.length > 0 ? item.children.map((item, index) => {
                      return (
                        <div key={index}>
                          <div className="flex justify-between">
                            <h2 className="w-1/2 pl-8">{item.name}</h2>
                            <div className="grid grid-cols-4 w-1/2">
                              <div className="flex justify-center">
                                <Checkbox
                                  id="user-read-checkbox"
                                  checked={item.read}
                                  onChange={() => {}} />
                              </div>
                              <div className="flex justify-center">
                                <Checkbox
                                  id="user-create-checkbox"
                                  checked={item.create}
                                  onChange={() => {}} />
                              </div>
                              <div className="flex justify-center">
                                <Checkbox
                                  id="user-update-checkbox"
                                  checked={item.update}
                                  onChange={() => {}} />
                              </div>
                              <div className="flex justify-center">
                                <Checkbox
                                  id="user-delete-checkbox"
                                  checked={item.delete}
                                  onChange={() => {}} />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }) : null}
                  </div>
                </div>
              ))}

            </div>

          </div>

        </div>

      </div>
    </>
  );
};

export default DetailPermission;


