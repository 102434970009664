import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { IFilterMasterSlot, useAllMasterSlots, useDeleteMasterSlot, useTotalMasterSlot } from '@/services/rest/masterSlot';
import { useAuthStore } from '@/store/useAuthStore';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useFormik } from 'formik';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { schemaMasterSlot } from './CreateSlots/validation';
import { IPagination } from '@/types/pagination';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { useHistoryStore } from '@/store/useHistoryStore';
import { IFilterHistoryItems } from '@/components/base/FilterHistory';
import { useDebounce } from 'primereact/hooks';

interface IDataSlots {
  id: string;
  name: string;
  created_by: string;
  updated_by: string;
  created_at: string;
  updated_at: string;
  type: string;
  time: Time | string;
}

export interface Time {
  ended_time: string | Date;
  started_time: string | Date;
}

interface optionValue {
  name: string;
  code: string;
}

interface IPaginationState {
  currentPage:number,
  range:IPagination
}


export const header = [
  { field: 'id', header: 'Slot ID' },
  { field: 'name', header: 'Slot Name' },
  { field: 'created_by', header: 'Created By' },
  { field: 'updated_by', header: 'Updated By' },
  { field: 'created_at', header: 'Created Date' },
  { field: 'updated_at', header: 'Updated Date' },
  { field: 'status', header: 'Status' }
];

const optionsStatus = [
  { name: 'ACTIVE', code: 'ACTIVE' },
  { name: 'INACTIVE', code: 'INACTIVE' }
];

const useMasterSlots = () => {
  const initialFilter: IFilterMasterSlot = {
    name: '',
    created_by: '',
    updated_by: '',
    created_at: [],
    updated_at: [],
    status: ''
  };

  const [rangeTimeSlots, setRangeTimeSlots] = useState<Time[]>([{ started_time: new Date(), ended_time: new Date() }]);
  const formattedTimeSlots = rangeTimeSlots.map((item) => {
    return {
      started_time: dayjs(item.started_time).format('HH:mm:ss'),
      ended_time: dayjs(item.ended_time).format('HH:mm:ss')
    };
  });
  const [selectedTypeFilter, setSelectedTypeFilter] = useState<Array<string>>([]);
  const [selectedDataCustomer, setSelectedDataCustomer] = useState<IDataSlots[]>([]);
  const [filterType, setFilterType] = useState<Array<string>>([]);
  const [filtersValues, setFiltersValues] = useState([]);
  const [filter, setFilter] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const [formCreateStartedTime, setCreateStartedTime] = useState<string | Date | Date[] | null | undefined>(null);
  const [formCreateEndedTime, setCreateEndedTime] = useState<string | Date | Date[] | null | undefined>(null);

  const [formEditStartedTime, setEditStartedTime] = useState<string | Date | null | undefined>(null);
  const [formEditEndedTime, setEditEndedTime] = useState<string | Date | null | undefined>(null);

  interface IFormikState {
    name: string;
    status: string;
    slot_time: string[];
  }

  const initialFormik:IFormikState = {
    name: '',
    status: '',
    slot_time: []
  };

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const formik = useFormik({
    initialValues: initialFormik,
    validationSchema: schemaMasterSlot,
    onSubmit: ()=>{
      if (checkTimeIntervals(rangeTimeSlots)) {
        showError('Slot times are intersecting');
      } else {
        validateCreateSlots();
      }
    }
  });

  dayjs.extend(isBetween);
  const checkTimeIntervals = (array) => {
    let isIntersect = false;
    for (let i = 0; i < array.length - 1; i++) {
      const currentInterval = array[i];
      const nextInterval = array[i + 1];

      const currentStartedTime = dayjs(currentInterval.started_time);
      const currentEndedTime = dayjs(currentInterval.ended_time);
      const nextStartedTime = dayjs(nextInterval.started_time);
      const nextEndedTime = dayjs(nextInterval.ended_time);

      if (currentStartedTime.isBetween(nextStartedTime, nextEndedTime) ||
      currentEndedTime.isBetween(nextStartedTime, nextEndedTime)) {
        isIntersect = true;
      }
    }
    for (let i = array.length - 1; i > 0; i--) {
      const currentInterval = array[i];
      const nextInterval = array[i - 1];

      const currentStartedTime = dayjs(currentInterval.started_time);
      const currentEndedTime = dayjs(currentInterval.ended_time);
      const nextStartedTime = dayjs(nextInterval.started_time);
      const nextEndedTime = dayjs(nextInterval.ended_time);

      if (currentStartedTime.isBetween(nextStartedTime, nextEndedTime) ||
      currentEndedTime.isBetween(nextStartedTime, nextEndedTime)) {
        isIntersect = true;
      }
    }
    return isIntersect;
  };


  interface Data {
    label: string | '';
    value: string | '';
  }

  const [filterCreatedBy, setFilterCreatedBy] = useState<Data[]>([]);
  const [filterName, setFilterName] = useState<Data[]>([]);

  const [selectedStatus, setSelectedStatus] = useState<optionValue>();
  const { user } = useAuthStore();
  const [perPage, setPerPage] = useState<number>(10);
  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const currentPage = parseInt(visitedPage.masterSlot.toString()) ?? 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : perPage - 1;
  const [pagination, setPagination] = useState<IPaginationState>({
    currentPage,
    range: {
      start,
      end
    }
  });
  const [jumpToPage, setJumpToPage] = useState<number>(1);
  const [itemFilters, setItemFilters] = useState<IFilterMasterSlot>(
    lastFilterPage.masterSlot != '' ? JSON.parse(String(lastFilterPage.masterSlot)) : initialFilter
  );
  const [filters, setFilters] = useState<IFilterMasterSlot>(
    lastFilterPage.masterSlot != '' ? JSON.parse(String(lastFilterPage.masterSlot)) : initialFilter
  );
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);
  const [search, debounceSearch, setSearch] = useDebounce('', 1000);


  const { data: dataAllMasterSlots,
    isLoading: isLoadingAllMasterSlots,
    refetch: refetchAllMasterSlots } = useAllMasterSlots(pagination.range, filters, debounceSearch);
  const { mutate: mutateDeleteMasterSlot, isSuccess: successDeleteMasterSlot, isError: errorDeleteMasterSlot } = useDeleteMasterSlot();
  const { data: dataTotalSlot } = useTotalMasterSlot(pagination.range, filters, debounceSearch);
  const params = useParams();
  const totalRecords = useMemo(()=>{
    return dataTotalSlot?.count ? dataTotalSlot?.count : 0;
  }, [dataTotalSlot]);

  const totalPages = useMemo(()=>{
    return Math.ceil(Number(totalRecords) / perPage);
  }, [totalRecords, perPage]);

  const options = [
    {
      label: 'Status',
      items: [
        { label: 'ACTIVE', value: 'ACTIVE' },
        { label: 'INACTIVE', value: 'INACTIVE' }
      ]
    }
  ];

  const showError = (error) => {
    toast.current?.show({ severity: 'error', summary: 'Error', detail: `${error}`, life: 3000 });
  };
  const showSuccess = useCallback((msg: string) => {
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: msg,
      life: 3000
    });
  }, []);

  const confirmDelete = useCallback((id: string) => {
    confirmDialog({
      message: 'Are you sure you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept() {
        selectionDeleteSlots(id);
      }
    });
  }, [selectedDataCustomer]);

  useEffect(() => {
    if (successDeleteMasterSlot) {
      showSuccess('Berhasil menghapus data');
      refetchAllMasterSlots();
    }
  }, [successDeleteMasterSlot]);

  useEffect(() => {
    if (errorDeleteMasterSlot) {
      showError('Gagal menghapus data');
    }
  }, [errorDeleteMasterSlot]);

  const [formCreateSlots, setFormCreateSlots] = useState({
    name: '',
    description: ''
  });

  const [formEditSlots, setFormEditSlots] = useState({
    name: '',
    description: '',
    exist_name: '',
    exist_description: ''
  });

  const resetFormCreateSlots = () => {
    setFormCreateSlots({
      name: '',
      description: ''
    });
    setCreateStartedTime('');
    setCreateEndedTime('');
    setSelectedStatus(undefined);
  };

  const onChangeFormSlots = (key: string, value: string | number | null) => {
    setFormCreateSlots((prevState) => {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  const handleTimeSlotChange = (index: number, key: keyof Time, value: string | Date) => {
    const updatedSlots = [...rangeTimeSlots];
    updatedSlots[index][key] = value as Date;
    setRangeTimeSlots(updatedSlots);
  };

  const addMoreSlot = useCallback(() => {
    const addTimeSlot = { started_time: new Date(), ended_time: new Date() };
    setRangeTimeSlots((prevSlots) => [...prevSlots, addTimeSlot]);
  }, [rangeTimeSlots, setRangeTimeSlots]);

  const deleteSlot = useCallback((index: number) => {
    const arrayOfNumbers = rangeTimeSlots;
    arrayOfNumbers.splice(index, 1);
    setRangeTimeSlots([...arrayOfNumbers]);
    formik.setFieldValue('slot_time', rangeTimeSlots);
  }, [rangeTimeSlots, setRangeTimeSlots]);

  useEffect(()=>{
    formik.setFieldValue('slot_time', rangeTimeSlots);
  }, [rangeTimeSlots, setRangeTimeSlots]);

  const dataSlots = useMemo(() => {
    return dataAllMasterSlots || [];
  }, [dataAllMasterSlots]);

  const renderDefaultValueStatue = useCallback((status: string) => {
    switch (status) {
    case 'ACTIVE':
      return { name: 'ACTIVE', code: 'ACTIVE' };
    case 'INACTIVE':
      return { name: 'INACTIVE', code: 'INACTIVE' };
    default:
      return {};
    }
  }, []);

  useEffect(() => {
    if (filterType.length === 0) {
      const option = dataSlots?.map((item) => ({
        label: item.type,
        value: item.type,
        labelCreatedBy: item.created_by,
        labelName: item.name
      }));
      if (option !== undefined) {
        // type option
        const uniqueOption = option.filter((option, index, self) => index === self.findIndex((o) => (o.label === option.label && option.value)));
        const filterSorted = uniqueOption.sort((a, b) => (a?.label || '').localeCompare(b?.label || ''));
        setFilterType(filterSorted as []);

        // createdBy option
        const createdByOption = option?.map((v) => {
          return {
            label: v?.labelCreatedBy || '',
            value: v?.labelCreatedBy || ''
          };
        });

        const uniqueCreatedByOption: Data[] = [];
        if (createdByOption) {
          createdByOption?.forEach((item) => {
            if (item?.label !== null && !uniqueCreatedByOption?.some((data) => data?.label === item?.label)) {
              uniqueCreatedByOption.push(item);
            }
          });
        }
        const createdByFilterSorted = uniqueCreatedByOption.sort((a, b) => (a?.label || '').localeCompare(b?.label || ''));
        setFilterCreatedBy(createdByFilterSorted);

        // name option
        const nameOption = option?.map((v) => {
          return {
            label: v?.labelName || '',
            value: `name-${v?.labelName}` || ''
          };
        });

        const uniqueNameOption: Data[] = [];
        if (nameOption) {
          nameOption?.forEach((item) => {
            if (item?.label !== null && !uniqueNameOption?.some((data) => data?.label === item?.label)) {
              uniqueNameOption.push(item);
            }
          });
        }
        const NameFilterSorted = uniqueNameOption.sort((a, b) => (a?.label || '').localeCompare(b?.label || ''));
        setFilterName(NameFilterSorted);
      }
    }
  }, [dataSlots]);

  const deleteFilterHistory = () => {
    setFiltersValues([]);
    setSelectedTypeFilter([]);
  };

  const handleFilter = useCallback((e, field: string) => {
    setItemFilters((prev) => ({
      ...prev,
      [field]: e.target ? e.target.value : e.value
    }));
  }, []);

  const onChangeFormEditSlots = (key: string, value: string | number | null) => {
    setFormEditSlots((prevState) => {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  const getDataById = useCallback(async () => {
    const { data, error } = await supabase.from(TABLE.MASTER_SLOTS).select('*').order('id').eq('id', params.id);
    if (data != undefined && !error) {
      onChangeFormEditSlots('name', data[0].name);
      onChangeFormEditSlots('description', data[0].description || '');
      const getTimeSlots = data[0].time.map((item) => {
        const [startedHour, startedMinutes] = item.started_time.split(':');
        const [endedHours, endedMinutes] = item.ended_time.split(':');
        const startedTime = dayjs().hour(startedHour).minute(startedMinutes);
        const endedTime = dayjs().hour(endedHours).minute(endedMinutes);
        return {
          started_time: startedTime.toDate(),
          ended_time: endedTime.toDate()
        };
      });
      setRangeTimeSlots(getTimeSlots);
      onChangeFormEditSlots('exist_name', data[0].name);
      onChangeFormEditSlots('exist_description', data[0].description || '');
      setSelectedStatus(renderDefaultValueStatue(data[0].status) as optionValue);
    }
  }, [
    onChangeFormEditSlots,
    formEditSlots.exist_name,
    formEditStartedTime,
    formEditEndedTime,
    rangeTimeSlots
  ]);

  const validateCreateSlots = async () => {
    if (!formCreateSlots.name || !rangeTimeSlots[0].started_time || !rangeTimeSlots[0].ended_time) {
      showError('Please complete the form!');
      return;
    }
    setIsLoading(true);
    const { data } = await supabase.from(TABLE.MASTER_SLOTS).select('name').limit(20);
    if (data != null) {
      const found = data.find((n) => n.name.toLowerCase() === formCreateSlots.name.toLowerCase());
      if (found !== undefined) {
        showError(`${formCreateSlots.name} already exist!`);
        setIsLoading(false);
        return;
      }
      let isErrorTimeShown = false;
      for (let i = 0; i < rangeTimeSlots.length; i++) {
        const time = rangeTimeSlots[i];
        if (time.started_time >= time.ended_time) {
          isErrorTimeShown = true;
        }
      }

      if (isErrorTimeShown == true) {
        setIsLoading(false);
        showError('End time must be greater than start time');
      } else {
        createSlots();
      }
    }
  };

  const createSlots = useCallback(async () => {
    setIsLoading(true);
    const tempName = formCreateSlots.name.trimRight();
    const { data: isNameExist } = await supabase
      .from(TABLE.MASTER_SLOTS)
      .select('name').limit(20)
      .eq('name', tempName);
    if (isNameExist?.length==0) {
      const { error } = await supabase.from(TABLE.MASTER_SLOTS).insert([
        {
          name: formCreateSlots.name,
          description: formCreateSlots.description,
          created_by: user?.email,
          time: formattedTimeSlots,
          account_id: user?.id,
          status: selectedStatus?.code
        }
      ]);

      if (!error) {
        setIsLoading(false);
        showSuccess(`${formCreateSlots.name} master slot created successfully`);
        resetFormCreateSlots();
        navigate('/master-slot');
      }
      if (error) {
        showError(error);
      }
    } else {
      showError(`Master slot ${tempName} already exists`);
    }
    setIsLoading(false);
  }, [formCreateSlots.name, resetFormCreateSlots, formCreateStartedTime, formCreateEndedTime]);

  // DELETE SELECTEDROW
  const selectionDeleteSlots = useCallback(async (id: string) => {
    setIsLoading(true);
    const params: string[] = [];
    if (selectedDataCustomer?.length > 0) {
      selectedDataCustomer?.forEach((item) => {
        params.push(item.id);
      });
    } else {
      params.push(id);
    }
    const { data } = await supabase.from(TABLE.FLASHSALE).select('id')
      .is('parent_id', null).is('deleted_at', null)
      .eq('periode->>isEnable', true).eq('periode->>isExpired', false)
      .in('master_slot_id', params);
    if (data != null && data.length > 0) {
      showError('Slot Masih Dipakai oleh Flash Sale');
      setIsLoading(false);
      return;
    }
    mutateDeleteMasterSlot(params);
    setIsLoading(false);
  }, [selectedDataCustomer]);

  const validateEditSlots = async () => {
    if (checkTimeIntervals(rangeTimeSlots)) {
      showError('Slot times are intersecting');
    } else {
      if (!formEditSlots.name || !rangeTimeSlots[0].started_time || !rangeTimeSlots[0].ended_time) {
        showError('Please complete the form!');
        return;
      }
      const { data } = await supabase.from(TABLE.MASTER_SLOTS).select('name').limit(20);
      if (data != null) {
        const found = data.find((n) => n.name.toLowerCase() === formEditSlots.name.toLowerCase());
        if (found !== undefined && formEditSlots.name !== formEditSlots.exist_name) {
          showError(`${formEditSlots.name} already exist!`);
          return;
        }
        let isErrorTimeShown = false;
        for (let i = 0; i < rangeTimeSlots.length; i++) {
          const time = rangeTimeSlots[i];
          if (time.started_time >= time.ended_time) {
            isErrorTimeShown = true;
          }
        }

        if (isErrorTimeShown == true) {
          showError('Ended time must be greater than started time');
        } else {
          editSlots();
        }
      }
    }
  };

  const editSlots = useCallback(async () => {
    setIsLoading(true);
    const { error } = await supabase
      .from(TABLE.MASTER_SLOTS)
      .update({
        name: formEditSlots.name,
        description: formEditSlots.description,
        time: formattedTimeSlots,
        updated_by: user?.email,
        updated_at: new Date(),
        status: selectedStatus?.code
      })
      .eq('id', params.id);

    if (error) {
      showError(error);
    } else {
      showSuccess(`${formEditSlots.name} updated successfully`);
      navigate('/master-slot');
    }
    setIsLoading(false);
  }, [formEditStartedTime, formEditEndedTime, formEditSlots.name, formattedTimeSlots]);

  const handleSetStatus = useCallback((value) => {
    setSelectedStatus(value);
  }, []);

  useEffect(() => {
    if (params.id) {
      getDataById();
    }
  }, [params.id]);

  // Pagination
  const handleClickPage = useCallback((page:number)=>()=>{
    setPagination((prev) => ({
      ...prev,
      currentPage: page,
      range: {
        start: page === 1 ? page - 1 : perPage * page - perPage,
        end: page * perPage - 1
      }
    }));
  }, [perPage]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent)=>{
    setPerPage(event.value);
    setPagination((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleClickNext = useCallback(()=>{
    pagination.currentPage <= totalPages &&
    setPagination((prev) => ({
      ...prev,
      currentPage: pagination.currentPage + 1,
      range: {
        start: pagination.range.start + perPage,
        end: pagination.range.end + perPage
      }
    }));
  }, [pagination, totalPages, perPage]);

  const handleClickPrev = useCallback(()=>{
    setPagination((prev) => ({
      ...prev,
      currentPage: prev.currentPage - 1,
      range: {
        start: prev.range.start - perPage,
        end: prev.range.end - perPage
      }
    }));
  }, [pagination, perPage]);

  const handleClickFirstPage = useCallback(()=>{
    setPagination((prev) => ({
      ...prev,
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    }));
  }, [pagination, perPage]);

  const handleClickLastPage = useCallback(()=>{
    setPagination((prev) => ({
      ...prev,
      currentPage: totalPages,
      range: {
        start: totalRecords - perPage + (totalPages * perPage - totalRecords),
        end: totalRecords
      }
    }));
  }, [pagination, totalRecords, perPage]);

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, totalRecords - 1);

      setPagination(() => ({
        currentPage: jumpToPage > totalPages ? totalPages : value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, totalRecords]);

  const handleClearFilter = useCallback(() => {
    setLastFilterPage({
      ...lastFilterPage,
      masterSlot: ''
    });
    setItemFilters(initialFilter);
    setFilters(initialFilter);
    setFilterHistory([]);
    setSearch('');
    setPagination({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [lastFilterPage, perPage]);

  const handleDeleteFilterHistory = useCallback(
    (key: string, value: string[]) => {
      const items = value[0].split(',');
      items.forEach((i) => {
        setFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));

        setItemFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));
      });

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setSearch('');
    },
    []
  );

  const handleSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>)=>{
    setSearch(currentTarget.value);
    const newKeyword = {
      name: 'Keyword',
      items: [{
        label: currentTarget.value,
        value: currentTarget.value,
        name: currentTarget.value
      }]
    };

    if (currentTarget.value != '') {
      setFilterHistory((prev: IFilterHistoryItems[]) => {
        const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
        if (existingIndex !== -1) {
          prev[existingIndex] = newKeyword;
        } else {
          prev.push(newKeyword);
        }
        return [...prev];
      });

      setPagination({
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      });
    } else {
      handleDeleteFilterHistory('Keyword', ['Keyword']);
    }
  }, [perPage]);

  const handleClickSubmitFilter = useCallback(() => {
    setFilters(itemFilters);
    setPagination({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [itemFilters, perPage]);

  const storeFilterHistory = useCallback((filter: IFilterMasterSlot) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter?.created_at && filter.created_at[0]) {
      let filterDate = `${dayjs(filter.created_at[0]).format('YYYY-MM-DD')}`;
      if (filter.created_at.length > 1 && filter.created_at[1] !== null) {
        filterDate = `${dayjs(filter.created_at[0]).format('YYYY-MM-DD')} - ${dayjs(filter.created_at[1]).format('YYYY-MM-DD')}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Created At', 'created_at', filterDate));
    }

    if (filter?.updated_at && filter.updated_at[0]) {
      let filterDate = `${dayjs(filter.updated_at[0]).format('YYYY-MM-DD')}`;
      if (filter.updated_at.length > 1 && filter.updated_at[1] !== null) {
        filterDate = `${dayjs(filter.updated_at[0]).format('YYYY-MM-DD')} - ${dayjs(filter.updated_at[1]).format('YYYY-MM-DD')}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Updated At', 'updated_at', filterDate));
    }

    if (filter.name !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Name', 'name', filter.name));
    }

    if (filter.created_by !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Created By', 'created_by', filter.created_by));
    }

    if (filter.updated_by !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Updated By', 'updated_by', filter.updated_by));
    }

    if (filter.status !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Status', 'status', filter.status));
    }

    setFilterHistory(filterHistoryItems);
  }, []);

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  useEffect(() => {
    // update store visitedPage with latest page
    setVisitedPage({
      ...visitedPage,
      masterSlot: pagination.currentPage
    });
  }, [pagination.currentPage]);

  // update store lastFilterPage with latest filter
  useEffect(() => {
    setLastFilterPage({
      ...lastFilterPage,
      masterSlot: JSON.stringify(filters)
    });
  }, [filters]);

  return {
    data: {
      dataSlots,
      filter,
      isLoadingAllMasterSlots,
      formCreateSlots,
      formEditStartedTime,
      formEditEndedTime,
      formCreateStartedTime,
      formCreateEndedTime,
      toast,
      rangeTimeSlots,
      formEditSlots,
      user,
      params,
      options,
      filtersValues,
      selectedDataCustomer,
      selectedTypeFilter,
      optionsStatus,
      selectedStatus,
      formik,
      filterCreatedBy,
      filterName,
      isLoading,
      pagination,
      perPage,
      totalPages,
      totalRecords,
      itemFilters,
      search,
      filterHistory
    },
    methods: {
      setFilter,
      onChangeFormSlots,
      validateCreateSlots,
      confirmDelete,
      addMoreSlot,
      onChangeFormEditSlots,
      setCreateStartedTime,
      setCreateEndedTime,
      setEditStartedTime,
      setEditEndedTime,
      navigate,
      handleTimeSlotChange,
      handleFilter,
      setSelectedDataCustomer,
      selectionDeleteSlots,
      validateEditSlots,
      deleteFilterHistory,
      getDataById,
      deleteSlot,
      handleSetStatus,
      isFormFieldValid,
      handleChangeDropdownPage,
      handleClickFirstPage,
      handleClickLastPage,
      handleClickNext,
      handleClickPage,
      handleClickPrev,
      handleChangeJumpTopage,
      handleJumpToPage,
      handleClearFilter,
      handleDeleteFilterHistory,
      handleSearch,
      handleClickSubmitFilter
    }
  };
};

export default useMasterSlots;
