import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { Database } from '@/types/services/catalogue';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';


type ProductSubscription = Database['public']['Tables']['product_subscriptions']['Row']
type Products = Database['public']['Tables']['products']['Row']
type ProductVariants = Database['public']['Tables']['product_variants']['Row']
type ProductTabulationVariants = Database['public']['Tables']['product_tabulation_variants']['Row']
type ProductTabulation = Database['public']['Tables']['product_tabulations']['Row']
type Banner = Database['public']['Tables']['banners']['Row']
type OfficialStore = Database['public']['Tables']['official_merchants']['Row']
export interface Highlight {
  id: number;
  product_variants: ProductVariant;
  product_variant_id: string;
  products: Product;
  weight?: string;
  sort_type?: string;
  started_time?: Date;
  ended_time?: Date;
  is_active: boolean;
}

export interface IBanner extends Banner {
  products: Products;
}

export interface IOfficialMerchant extends OfficialStore {
  product_variants: IProductVariant
  is_active: boolean;
}
export interface IProductVariant extends ProductVariants {
  products: Products
}
export interface IProductSubscription extends ProductSubscription {
  product_variants: IProductVariant;
  is_active: boolean;
}

export interface IProductTabulation extends ProductTabulationVariants {
  product_variants: IProductVariant;
  product_tabulations: ProductTabulation
  is_active: boolean;
}

export interface ProductVariant {
  sku_id: string;
}
export interface Product {
  id: number;
  name: string;
}

const getProductSubscription = async () => {
  return (await supabase
    .from(TABLE.PRODUCT_SUBSCRIPTION)
    .select('*, product_variants(sku_id,id), products(id, name))').range(0, 10)) as PostgrestSingleResponse<Highlight[]>;
};

const getProductSubscriptionById = async (id?: number) => {
  return (await supabase
    .from(TABLE.PRODUCT_SUBSCRIPTION)
    .select('*, product_variants(*, products(*))')
    .eq('id', id).single()) as PostgrestSingleResponse<IProductSubscription>;
};

export const updateProductSubscription = async (payload) => {
  return await supabase.from(TABLE.PRODUCT_SUBSCRIPTION).update(payload).eq('id', payload.id);
};

export const updateProductHotDeal = async (payload) => {
  return await supabase
    .from(TABLE.BANNER).update(payload).eq('id', payload.id);
};

export const updateOfficialStore = async (payload) => {
  return await supabase.from(TABLE.OFFICIAL_MERCHANT).update(payload).eq('id', payload.id);
};

export const updateProductTabulation = async (payload) => {
  return await supabase
    .from(TABLE.PRODUCT_TABULATION)
    .update(payload).eq('id', payload.id);
};

const getProductTabulationsById = async (id?: number) => {
  return (await supabase
    .from(TABLE.PRODUCT_TABULATION)
    .select('*, product_variants(id, sku_id, products(name, id)), product_tabulations(id,name)')
    .eq('id', id)
    .single()) as PostgrestSingleResponse<IProductTabulation>;
};

const getProductByProductVariants = async () => {
  return (await supabase
    .from(TABLE.PRODUCT_VARIANTS)
    .select('id, products(id,name)').range(0, 20)) as PostgrestSingleResponse<IProductVariant[]>;
};

const getProductHotDeal = async (id?: number) => {
  return (await supabase
    .from(TABLE.BANNER)
    .select('name, product_id, started_time, ended_time, merchant_id, id, status, products(id, name)')
    .eq('type', 'PRODUCT_HOT_DEAL')
    .eq('id', id).single()) as PostgrestSingleResponse<IBanner>;
};

const getOfficialStoreById = async (id?: number) => {
  return (await supabase
    .from(TABLE.OFFICIAL_MERCHANT)
    .select('*, product_variants!inner(id, sku_id, products!inner(id,name))')
    .eq('id', id).single()) as PostgrestSingleResponse<IOfficialMerchant>;
};

export const useGetProductSubscription = () =>
  useQuery({
    queryKey: ['product_subscription'],
    queryFn: getProductSubscription,
    refetchOnWindowFocus: false
  });

export const useGetProductSubscriptionById = (id?: number) =>
  useQuery({
    queryKey: ['subscriptionById', id],
    queryFn: () => getProductSubscriptionById(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });

export const useGetProductHotDealById = (id?: number) =>
  useQuery({
    queryKey: ['productHotDeal', id],
    queryFn: () => getProductHotDeal(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });

export const useGetOfficialMerchant = (id?: number) =>
  useQuery({
    queryKey: ['officialMerchant', id],
    queryFn: () => getOfficialStoreById(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });

export const useGetProductByProductVariant = () =>
  useQuery({
    queryKey: ['productVariant'],
    queryFn: getProductByProductVariants,
    refetchOnWindowFocus: false
  });

export const useGetProductTabulationsById = (id?: number) =>
  useQuery({
    queryKey: ['tabulationsById', id],
    queryFn: () => getProductTabulationsById(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });
