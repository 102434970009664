import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import { Button } from 'primereact/button';
import { useMemo } from 'react';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { usePromoTaggingDetail } from './hooks';
import { usePermissionStore } from '@/store/usePermissions';

const PromoTaggingDetail = () => {
  const {
    data: {
      isLoading,
      formEditPromoTagging,
      street,
      navigate,
      detailPromo,
      detailProduct,
      detailMerchant
    }
  } = usePromoTaggingDetail();
  const { canEdit } = usePermissionStore();
  const dataSelected = useMemo(() => {
    const dataStoreRecomendations = formEditPromoTagging.tag_metadata?.store?.map((item) => item.store);
    const stores = dataStoreRecomendations?.join(', ');
    const dataNewProduct = formEditPromoTagging.tag_metadata?.product?.map((item) => item.product);
    const products = dataNewProduct?.join(', ');
    if (!products) {
      return stores;
    }
    return products;
  }, [formEditPromoTagging]);

  return (
    <BaseContainer>

      <MainTitle isWithButton={false} title="Detail Promo Tagging" />
      <div className="border p-6">
        <div className="flex">
          <div id="title" className="w-1/4">
            <div className="border border-solid px-16 py-3 font-bold bg-gray-400">PROMO TAGGING INFORMATION</div>
            <div className="border border-solid px-16 py-3 font-normal text-center" />
          </div>
          <LoadingOverlay show={isLoading}/>
          <div className="border p-10 w-full space-y-3">
            <div className="grid grid-cols-3">
              <p className="grid items-center">
                  Promo Tagging Category
              </p>
              <div className="grid items-center border border-gray-300 rounded-lg bg-white px-4 py-3.5">
                {formEditPromoTagging.category?.name || '-'}
              </div>
            </div>
            {formEditPromoTagging.category?.code !== 'storeRecommendation' &&
            <>
              <div className="grid grid-cols-3">
                <p className="grid items-center">
                  Promo Tagging Name
                </p>
                <div className="grid items-center border border-gray-300 rounded-lg bg-white px-4 py-3.5">
                  {formEditPromoTagging.name || '-'}
                </div>
              </div>
              <div className="grid grid-cols-3">
                <div className="grid items-center">
                  Description
                </div>
                <div className="grid items-center border border-gray-300 rounded-lg bg-white px-4 py-3.5">
                  {formEditPromoTagging.description || '-'}
                </div>
              </div>
            </>
            }
            {formEditPromoTagging.category?.code === 'topKeywordList' &&
              <div className="grid grid-cols-3">
                <p className="grid items-center">
                  Show Description
                </p>
                <div id="promo-tagging-show-description-show-description" className="grid items-center border border-gray-300 rounded-lg bg-white px-4 py-3.5">
                  {formEditPromoTagging.showDescription?.name || '-'}
                </div>
              </div>
            }
            {(formEditPromoTagging.category?.code === 'topKeywordList' || formEditPromoTagging.category?.code === 'keywordRecommendation') &&
                <>
                  <div className="grid grid-cols-3">
                    <p className="grid items-center">
                      Direct To
                    </p>
                    <div
                      id="promo-tagging-show-description-show-description"
                      className="grid items-center border border-gray-300 rounded-lg bg-white px-4 py-3.5">
                      {formEditPromoTagging.tag_metadata?.directTo || '-'}
                    </div>
                  </div>
                  {formEditPromoTagging.tag_metadata?.directTo === 'STORE_DETAIL' && (
                    <div id="apply-to-street" className="grid grid-cols-3">
                      <div id="apply-street-label" className="grid items-center" />
                      <div className="grid items-center" id="dropdown_field">
                        <div className="card grid justify-content-center border border-gray-300 rounded-lg bg-white px-4 py-3.5">
                          {detailMerchant.name}
                        </div>
                      </div>
                    </div>
                  )}
                  {formEditPromoTagging.tag_metadata?.directTo === 'PDP' && (
                    <div id="apply-to-street" className="grid grid-cols-3">
                      <div id="apply-street-label" className="grid items-center" />
                      <div className="grid items-center" id="dropdown_field">
                        <div className="card grid justify-content-center border border-gray-300 rounded-lg bg-white px-4 py-3.5">
                          {detailProduct.name}
                        </div>
                      </div>
                    </div>
                  )}
                  {formEditPromoTagging.tag_metadata?.directTo === 'PROMO_DETAIL_PAGE' && (
                    <div id="apply-to-street" className="grid grid-cols-3">
                      <div id="apply-street-label" className="grid items-center" />
                      <div className="grid items-center" id="dropdown_field">
                        <div className="card grid justify-content-center border border-gray-300 rounded-lg bg-white px-4 py-3.5">
                          {detailPromo.name}
                        </div>
                      </div>
                    </div>
                  )}
                  {formEditPromoTagging.tag_metadata?.directTo === 'LANDING_PAGE' && (
                    <div id="apply-to-street" className="grid grid-cols-3">
                      <div id="apply-street-label" className="grid items-center" />
                      <div className="grid items-center" id="dropdown_field">
                        <div className="card grid justify-content-center border border-gray-300 rounded-lg bg-white px-4 py-3.5">
                          {formEditPromoTagging.urlLandingPage}
                        </div>
                      </div>
                    </div>
                  )}
                </>
            }
            {(formEditPromoTagging.category?.code === 'newProduct' || formEditPromoTagging.category?.code === 'storeRecommendation') &&
                <div className="grid grid-cols-3">
                  <div className="grid items-center">
                    {(formEditPromoTagging.category.code === 'newProduct' ? 'Select Product' : 'Select Store')}
                  </div>
                  <div className="grid items-center border border-gray-300 rounded-lg bg-white px-4 py-3.5">
                    {dataSelected}
                  </div>
                </div>}
            {(formEditPromoTagging.category?.code === 'newProduct' || formEditPromoTagging.category?.code === 'storeRecommendation') &&
              <div className="grid grid-cols-3">
                <p className="grid items-center">
                  Action
                </p>
                <div id="promo-tagging-category-action" className="border border-gray-300 rounded-lg bg-white px-4 py-3.5">
                  {formEditPromoTagging.action?.name || '-'}
                </div>
              </div>
            }
            <div className="grid grid-cols-3">
              <div className="grid items-center">
                  Valid From
              </div>
              <div className="grid items-center">
                <div
                  id="promo-tagging-valid-from"
                  className="grid items-center border border-gray-300 rounded-lg bg-white px-4 py-3.5">
                  {formEditPromoTagging.valid_from || '-'}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3">
              <div className="grid items-center">
                  Valid To
              </div>
              <div className="grid items-center">
                <div
                  id="promo-tagging-valid-to"
                  className="grid items-center border border-gray-300 rounded-lg bg-white px-4 py-3.5">
                  {formEditPromoTagging.valid_to || '-'}
                </div>
              </div>
            </div>
            {formEditPromoTagging.category?.code === 'topKeywordList' &&
                <div className="grid grid-cols-3">
                  <div className="grid items-center">
                    Apply to Street
                  </div>
                  <div id="promo-tagging-street-multiselect-input" className="grid items-center border border-gray-300 rounded-lg bg-white px-4 py-3.5">
                    {street[0]?.name}
                  </div>
                </div>}
            <div className="grid grid-cols-3">
              <div className="grid items-center">
                  Status
              </div>
              <div className="grid items-center border border-gray-300 rounded-lg bg-white px-4 py-3.5">
                {formEditPromoTagging.status?.name || '-'}
              </div>
            </div>
          </div>

        </div>
      </div>
      <div id="buttons-action" className="mt-5 w-full flex justify-end gap-4 px-10">
        <Button id="buttons-action-back" label="Back" severity="danger" type="button" outlined onClick={() => {
          navigate('/promo-tagging');
        }} />
        <Button id="buttons-action-edit" disabled={!canEdit} label="Edit" type="submit" severity="danger" onClick={() => {
          navigate(`/promo-tagging/edit/${formEditPromoTagging.id}`);
        }} />
      </div>
    </BaseContainer>
  );
};

export default PromoTaggingDetail;
