import { Image } from 'primereact/image';
import React, { FC } from 'react';

declare interface BannerProps {
  imgSrc: string;
  altText: string;
}

export const HeaderBanner: FC<BannerProps> = ({ imgSrc, altText }) => {
  return (
    <>
      <div id="banner-promo" className="h-fit">
        <Image src={imgSrc} alt={altText}/>
      </div>
    </>
  );
};
