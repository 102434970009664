import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { InputNumber, InputNumberChangeEvent } from 'primereact/inputnumber';
import { FC } from 'react';

export interface IPagination {
  perPage?: number;
  totalPages?: number;
  currentPage?: number;
  disabledDropdown?: boolean;
  onClickPrev?: () => void;
  onClickNext?: () => void;
  onJumptoPage?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChangeJumptoPage?: (e: InputNumberChangeEvent) => void;
  onChangeDropdownPage?: (e: DropdownChangeEvent) => void;
  isOnOrder?: boolean;
}

interface IDropdownOptions {
  label: number;
  value: number;
}

const defaultDropdownOptions: IDropdownOptions[] = [
  { label: 10, value: 10 },
  { label: 25, value: 25 },
  { label: 50, value: 50 }
];

const Pagination: FC<IPagination> = ({
  perPage,
  totalPages,
  currentPage = 1,
  disabledDropdown,
  onClickPrev,
  onClickNext,
  onJumptoPage,
  onChangeJumptoPage,
  onChangeDropdownPage,
  isOnOrder
}) => {
  const dropdownOptions: IDropdownOptions[] = isOnOrder ?
    [
      ...defaultDropdownOptions,
      { label: 80, value: 80 }
    ] :
    [
      ...defaultDropdownOptions,
      { label: 100, value: 100 },
      { label: 200, value: 200 }
    ];

  return (
    <div id="pagination" className="flex gap-2 items-center">
      <div className="flex gap-2 items-center">
        <Dropdown
          value={perPage}
          options={dropdownOptions}
          onChange={onChangeDropdownPage}
          disabled={disabledDropdown}
        />
        <h2 className="font-bold">per Halaman</h2>
      </div>
      <Button
        icon="pi pi-angle-left"
        disabled={Boolean(currentPage === 1)}
        outlined
        severity="secondary"
        onClick={onClickPrev}
      />

      <div className="flex gap-2 items-center">
        <InputNumber value={currentPage} onKeyDown={onJumptoPage} onChange={onChangeJumptoPage} size={2} min={1}/>
        dari
        <h2>{totalPages}</h2>
      </div>

      <Button
        icon="pi pi-angle-right"
        disabled={Boolean(currentPage === totalPages)}
        outlined
        severity="secondary"
        onClick={onClickNext}
      />
    </div>
  );
};

export default Pagination;
