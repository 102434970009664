import React, { FC, useCallback } from 'react';
import {
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  BlockButton,
  Button,
  Dropdown,
  TextInput
} from 'akeneo-design-system';

type ISpecificationPagination = {
  handleSelect: (value: number) => void;
  perPage: string;
  totalPage: number;
  currentPage: string;
  handleClickNext: () => void;
  handleClickPrev: () => void;
  handleClickDropdown: () => void;
  handleOnChange: () => void;
  isDropDownOpen: boolean;
};

export const SpecificationPagination: FC<ISpecificationPagination> = ({
  handleSelect,
  perPage,
  handleClickNext,
  currentPage,
  handleClickPrev,
  handleClickDropdown,
  handleOnChange,
  isDropDownOpen,
  totalPage
}) => {
  const onSelect = useCallback((value: number) => () => handleSelect(value), [handleSelect]);

  return (
    <div>
      <div className="flex gap-2 justify-end items-center">
        <div>
          <Dropdown>
            <Button className="!px-6 !bg-transparent !text-gray-700" onClick={handleClickDropdown}>
              {perPage} <ArrowDownIcon />
            </Button>
            {isDropDownOpen && (
              <Dropdown.Overlay verticalPosition="down" onClose={handleClickDropdown}>
                <Dropdown.Header>
                  <Dropdown.Title>{perPage}</Dropdown.Title>
                </Dropdown.Header>
                <Dropdown.ItemCollection>
                  <Dropdown.Item onClick={onSelect(100)}>100</Dropdown.Item>
                  <Dropdown.Item onClick={onSelect(50)}>50</Dropdown.Item>
                  <Dropdown.Item onClick={onSelect(10)}>10</Dropdown.Item>
                  <Dropdown.Item onClick={onSelect(5)}>5</Dropdown.Item>
                </Dropdown.ItemCollection>
              </Dropdown.Overlay>
            )}
          </Dropdown>
        </div>
        <div className="flex gap-5 items-center">
          <BlockButton
            className=" !border !border-black</div> !justify-center !items-center"
            icon={<ArrowLeftIcon color="#000" />}
            onClick={handleClickPrev}
          />
          <div className="flex items-center gap-2">
            <TextInput disabled className="!w-16 !h-12 !text-xl" value={currentPage} onChange={handleOnChange} />
            <span className="font-semibold whitespace-nowrap">dari {totalPage ?? '100'}</span>
          </div>
          <BlockButton
            className=" !border !border-black !justify-center !items-center"
            icon={<ArrowRightIcon color="#000" />}
            onClick={handleClickNext}
          />
        </div>
      </div>
    </div>
  );
};
