export interface IDropdown {
    name: string;
    code: string | boolean;
}

export interface IFlashSaleSupabase {
    id: number;
    product_id?: number;
    weight?: number;
    product_variant_id?: string;
    master_slot_id?: ISlotSupabase;
    periode: IPeriode;
    name: string;
    description?: string;
    saleable_stock?: number;
    inflight_stock?: number;
    price_by?: string;
    price_value?: number;
    total_stock?: number;
    created_by?: string;
    updated_by?: string;
    created_at?: string;
    updated_at?: string;
}

export interface ISlotSupabase {
    id: string;
    name: string;
    time: ITime[];
}

export interface ITimeRange {
  id: string;
  name: string;
  time: ITime[];
}

export interface ITime {
  index?: number;
  started_time: string;
  ended_time: string;
}

export interface IPeriode {
    date: string[];
    isRange: boolean;
    isExpired: boolean;
    isEnable: boolean;
}

export interface IFormFlashSale {
    id?: number;
    product_variant_id: string;
    master_slot_id: IDropdown;
    periode: IDropdown; // range or multiple
    valid_from?: string;
    valid_to?: string;
    multiple: string[];
    name: string;
    description: string;
    saleable_stock?: number;
    total_stock?: number;
    price_by?: string;
    price_value?: number;
    status: IDropdown; // enable > true | disable > false
    parent_id?: number;
    isEnable?: boolean;
}

export interface ITemplateCSV {
    'FS Price By': string;
    'FS Price Value': string;
    'Jumlah Stock Flash Sale': string;
    'Merchant Id': string;
    Periode: string;
    Slot: string;
    'Sort By': string;
    'Sort Value': string;
    'Variant Id': string;
}

export interface ITemplateXLS {
  'Merchant ID': string;
  'Product Variant ID': string;
  'Flash Sale Price By': string;
  'Flash Sale Price Value': string;
  'Jumlah Stock Flash Sale': number;
  'Sort By': string;
  'Sort Value': string;
  Periode: string;
  Slot: string;
}

export interface ISaleableStock {
    id: string;
    saleable_stock: number
}

export interface IProductVariant {
  id: string;
  product_id: number;
}

export const HeaderFlashSale = [
  'Merchant ID',
  'Product Variant ID',
  'Flash Sale Price By',
  'Flash Sale Price Value',
  'Jumlah Stock Flash Sale',
  'Sort By',
  'Sort Value',
  'Periode',
  'Slot'
];

export interface IListFlashSale {
    id: number;
    name: string;
    created_by: string;
    updated_by: string;
    created_at: string;
    updated_at: string;
    status: string;
}
