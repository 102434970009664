import React, { useEffect, useRef, useState } from 'react';
import { Link, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '@/components/shared/sidebar/Sidebar';
import { supabase } from '@/lib/supabase';
import {
  Menu
} from '@headlessui/react';
import {
  Bars3Icon
} from '@heroicons/react/24/outline';
import { useAuthStore } from '@/store/useAuthStore';
import { shallow } from 'zustand/shallow';
import ImageUserLogin from '@/assets/ImageUserLogin.svg';
import { Toast } from 'primereact/toast';

const ProtectedLayout = () => {
  const { user, setUser, isLoading } = useAuthStore(
    (state) => ({ user: state.user, setUser: state.setUser, isLoading: state.isLoading }),
    shallow
  );

  // Check if token expired
  const expiredToken = async () => {
    const { error } = await supabase.auth.getUser();

    if (error) {
      logout(() => {
        navigate('/login');
      });
    }
  };

  const toast = useRef<Toast>(null);

  const showError = (msg: string) => {
    toast.current?.show({ severity: 'error', summary: 'Error', detail: `${msg}`, life: 3000 });
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [profileImage, setProfileImage] = useState<string>(ImageUserLogin);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const logout = useAuthStore((state) => state.logout);
  const navigate = useNavigate();

  useEffect(() => {
    if (user === null) {
      setUser(showError);
    }

    if (user) {
      const getDataProfileImage = async () => {
        const { data } = await supabase
          .from('user.accounts')
          .select('email, photo')
          .eq('email', user?.email)
          .single();
        setProfileImage(data?.photo || ImageUserLogin);
      };

      getDataProfileImage();
    }
  }, [setUser, user]);

  useEffect(() => {
    expiredToken();
  });

  const location = useLocation();

  if (isLoading) return null;

  return user === null ? (
    <Navigate to="/login" state={{ from: location }} replace />
  ) : (
    <div id="protected_layout" className="">
      <Sidebar />
      <div id="main" className="flex flex-col bg-light-gray">
        <div className="lg:pl-64">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => {}}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 justify-end">
              <div className="flex items-center gap-x-4 lg:gap-x-6 ">
                <Menu as="div" className="relative">
                  <Menu.Button onClick={toggleDropdown} className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src={profileImage}
                      alt=""
                    />
                    <span className="hidden lg:flex lg:items-center">
                      <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                        {user?.email}
                      </span>
                      {isOpen && (<div className="dropdown-menu absolute -bottom-28 left-5 z-40 flex flex-col shadow rounded-lg px-4 border border-solid bg-white appearance-none w-[200px] pl-[20px]">
                        <Link to="profile">
                          <div className="hover:bg-gray-200 rounded-lg cursor-pointer flex gap-2 items-center p-[5px] text-sm font-normal font-satoshi text-grey-800 mt-3">
                            <span className="text-left">User Profile</span>
                          </div>
                        </Link>
                        <div className="hover:bg-gray-200 rounded-lg flex gap-2 items-center p-[5px] text-sm font-normal font-satoshi text-grey-800 mb-3">
                          <span onClick={() =>
                            logout(() => {
                              navigate('/login');
                            })
                          } className="text-left cursor-pointer">Logout</span>
                        </div>
                      </div>)}
                    </span>
                  </Menu.Button>
                </Menu>
              </div>
            </div>
          </div>
          <main className="m-5">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
};

export default ProtectedLayout;
