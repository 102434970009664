import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import { InputText } from 'primereact/inputtext';
import { TabPanel, TabView } from 'primereact/tabview';
import './style.css';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import useCustomerManagement from './CustomerManagementHooks';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import CustomerRegistered from './CustomerRegistered';
import { useState } from 'react';


const CustomerManagement = () => {
  const {
    data: { column, isLoading, search, visibleColumn },
    method: { setSearch, onColumnToggle, handleSearch, exportExcel, defaultView }
  } = useCustomerManagement();

  const [activeIndex, setActiveIndex] = useState(1);

  return (
    <>
      <LoadingOverlay show={isLoading}/>
      <BaseContainer>
        <MainTitle title="Customer"/>
        <div className="mt-5">
          <TabView
            id="customer-tab-view"
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <div className="hide">
              <TabPanel header="Customer Non-Registered">
                <div className="flex justify-between mb-6 mt-3 w-full">
                  <InputText
                    className="w-[300px]"
                    id="customer-search-input"
                    placeholder="Search"
                    type="search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    onKeyDown={handleSearch}
                  />
                  <div className="flex gap-x-3">
                    <div className="w-[250px]">
                      <MultiSelect
                        id="customer-filter-input"
                        value={[]}
                        onChange={() => {}}
                        optionLabel="label"
                        optionGroupLabel="label"
                        optionGroupChildren="items"
                        placeholder="Filter"
                        className="w-full md:w-20rem"
                        maxSelectedLabels={3}
                      />
                    </div>
                    <div className="w-[150px]">
                      <Button id="customer-default-button" className="w-full" label="Default View" severity="secondary" onClick={defaultView} outlined />
                    </div>
                    <div className="w-[150px]">
                      <MultiSelect
                        id="customer-filter-column"
                        value={visibleColumn}
                        onChange={onColumnToggle}
                        options={column}
                        optionLabel="header"
                        placeholder="Column"
                        className="w-full md:w-20rem"
                        maxSelectedLabels={3}
                      />
                    </div>
                    <div className="w-[150px]">
                      <Button id="customer-export-button" className="w-full" label="Export" severity="secondary" onClick={exportExcel} outlined />
                    </div>
                  </div>
                </div>
                <DataTable id="customer-table"/>
              </TabPanel>
            </div>
            <TabPanel header="Customer Registered">
              <CustomerRegistered/>
            </TabPanel>
          </TabView>
        </div>
      </BaseContainer>
    </>
  );
};

export default CustomerManagement;
