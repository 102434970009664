import { InputText } from 'primereact/inputtext';
import useCustomerManagement from './CustomerManagementHooks';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import IconShow from '@/assets/IconShow';
import { Link } from 'react-router-dom';
import FilterHistory from '@/components/base/FilterHistory';
import { usePermissionStore } from '@/store/usePermissions';
import Pagination from '@/components/base/Pagination';
import { Divider } from 'primereact/divider';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';

const CustomerRegistered = () => {
  const {
    data: {
      column,
      dataCustomer,
      visibleColumn,
      filterHistory,
      paginator,
      perPage,
      totalRecords,
      totalPages,
      itemFilters,
      isOpenDialog,
      search
    },
    method: {
      onColumnToggle,
      exportExcel,
      defaultView,
      handleClearFilter,
      handleDeleteFilterHistory,
      handleFilter,
      handleSearch,
      handleClickPrev,
      handleClickNext,
      handleChangeDropdownPage,
      handleChangeJumpTopage,
      handleJumpToPage,
      setIsOpenDialog,
      handleClickSubmitFilter
    }
  } = useCustomerManagement();
  const { canView } = usePermissionStore();

  const filterContent = (
    <div className="flex flex-col gap-4 w-[900px]">
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Name</label>
          <InputText
            onChange={(e) => handleFilter(e, 'name')}
            value={itemFilters.name}
            name="name"
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Email</label>
          <InputText
            keyfilter="email"
            onChange={(e) => handleFilter(e, 'email')}
            value={itemFilters.email}
            name="email"
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Phone</label>
          <InputText
            keyfilter="pnum"
            onChange={(e) => handleFilter(e, 'phone')}
            value={itemFilters.phone}
            name="phone"
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Province</label>
          <InputText
            onChange={(e) => handleFilter(e, 'province')}
            value={itemFilters.province}
            name="province"
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Customer Since</label>
          <Calendar
            onChange={(e) => handleFilter(e, 'created_at')}
            value={itemFilters.created_at}
            name="created_at"
            selectionMode="range"
            showIcon
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Gender</label>
          <Dropdown
            options={[{ label: 'Laki-Laki', value: 'laki-laki' }, { label: 'Perempuan', value: 'perempuan' }]}
            onChange={(e) => handleFilter(e, 'gender')}
            value={itemFilters.gender}
            placeholder="Select Gender"
          />
        </div>
      </div>
    </div>
  );

  const footerContent = (
    <div className="flex justify-end py-4 gap-4">
      <Button label="Batal" severity="secondary" outlined onClick={() => setIsOpenDialog(false)} size="large" />
      <Button label="Terapkan" severity="info" onClick={handleClickSubmitFilter} size="large" />
    </div>
  );

  return (
    <>
      <div className="flex justify-end mb-6 mt-3 w-full">
        <div className="flex gap-x-3">
          <Button
            icon="pi pi-sliders-h"
            onClick={() => setIsOpenDialog(true)}
            label="Filter"
            severity="secondary"
            text
            raised
          />
          <div className="w-[150px]">
            <Button
              id="customer-default-button"
              className="w-full"
              label="Default View"
              severity="secondary"
              onClick={defaultView}
              outlined
            />
          </div>
          <div className="w-[150px]">
            <MultiSelect
              id="customer-filter-column"
              value={visibleColumn}
              onChange={onColumnToggle}
              options={column}
              optionLabel="header"
              placeholder="Column"
              className="w-full md:w-20rem"
              maxSelectedLabels={3}
            />
          </div>
          <div className="w-[150px]">
            <Button
              id="customer-export-button"
              className="w-full"
              label="Export"
              severity="secondary"
              onClick={exportExcel}
              outlined
            />
          </div>
        </div>
      </div>
      { filterHistory.length > 0 ?
        <FilterHistory
          filterHistory={filterHistory}
          handleClearFilter={handleClearFilter}
          handleDeleteFilterHistory={handleDeleteFilterHistory}
          show={filterHistory.length > 0}
        /> :
        <Divider className="p-divider-solid " />
      }
      <div>
        <div className="flex justify-between items-center my-4">
          <div id="search" className="p-input-icon-right w-[20%]">
            <InputText
              className="w-full"
              id="customer-search-input"
              placeholder="Cari Disini"
              type="search"
              onChange={handleSearch}
              value={search}
              keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
            />
            <i className="pi pi-search" />
          </div>
          <div className="flex items-center justify-end gap-6 w-full">
            <h4 className="font-bold justify-self-end"> {totalRecords} data ditemukan</h4>
            <Pagination
              perPage={perPage}
              totalPages={totalPages}
              currentPage={paginator.currentPage}
              disabledDropdown={totalRecords === 0}
              onClickPrev={handleClickPrev}
              onClickNext={handleClickNext}
              onChangeDropdownPage={handleChangeDropdownPage}
              onChangeJumptoPage={handleChangeJumpTopage}
              onJumptoPage={handleJumpToPage}
            />
          </div>
        </div>
        <DataTable id="customer-table" value={dataCustomer as []} dataKey="id" scrollable>
          {visibleColumn.map((item, index) => (
            <Column key={index} header={item.header} sortable field={item.field} />
          ))}
          <Column
            header="Action"
            style={{ width: '6rem' }}
            body={(rowData) => (
              <Link to={canView ? `${rowData.id}` : ''}>
                <Button
                  id="customer-detail-button"
                  icon={IconShow}
                  outlined
                  rounded
                  severity="secondary"
                  disabled={!canView}
                />
              </Link>
            )} />
        </DataTable>
      </div>
      <Dialog onHide={() => setIsOpenDialog(false)} visible={isOpenDialog} header="Semua Filter" footer={footerContent}>
        {filterContent}
      </Dialog>
    </>
  );
};

export default CustomerRegistered;
