
import { useMerchatns, useProduct, usePromos, useStreet } from '@/services/rest/banner';
import { useGetPromoById, useGetPromoTaggingById, useMerchantById, useProductById } from '@/services/rest/promoTagging';
import { IBannerOptions } from '@/views/BannerManagement/CreateEditBanner/hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IMerchantSupabase, IMetaProductVariant, IMetaStore, IProductOptions, IProductSupabase, IProductVariant, IProductVariantSupabase, IStore, IStoreOptions } from '../type';
import { supabase } from '@/lib/supabase';
import { TABLE } from '@/constants';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import { schemaEditPromoTagging } from './validation';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { useDebounce } from 'primereact/hooks';
import { PostgrestMaybeSingleResponse } from '@supabase/supabase-js';
import { debounce, uniqBy } from 'lodash';

export const initialFormEdit = {
  id: '',
  category: '',
  action: '',
  validFrom: '',
  validTo: '',
  status: '',
  name: '',
  description: '',
  showDescription: '',
  directTo: '',
  applyToStreet: '',
  merchantId: { code: '', label: '' } as IPromoTagOptions,
  productId: { code: '', label: '' } as IPromoTagOptions,
  promoDetailId: { code: '', label: '' } as IPromoTagOptions,
  landingPageType: ''
};

export interface IPromoTagOptions {
  label: string;
  code: string;
}

export const optionDirectTo: IPromoTagOptions[] = [
  {
    label: 'Store Detail',
    code: 'STORE_DETAIL'
  },
  {
    label: 'PDP',
    code: 'PDP'
  },
  {
    label: 'Landing Page',
    code: 'LANDING_PAGE'
  },
  {
    label: 'Promo Detail Page',
    code: 'PROMO_DETAIL_PAGE'
  }
];

export const optionKeywordRecomendations: IPromoTagOptions[] = [
  {
    label: 'Landing Page',
    code: 'LANDING_PAGE'
  },
  {
    label: 'PLP',
    code: 'PLP'
  }
];

const optionLandingPage: IPromoTagOptions[] = [
  { label: 'Promo', code: '/promo' },
  { label: 'Flash Sale', code: '/flashsale' }
];

const usePromoTaggingEdit = () => {
  const params = useParams();
  const [formEdit, setFormEdit] = useState(initialFormEdit);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedStreet, setSelectedStreet] = useState<[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [validate, setValidate] = useState(false);
  const [search, debounceSearch, setSearch] = useDebounce('', 500);
  const toast = useRef<Toast>(null);
  const [selected, setSelected] = useState<IProductVariant[] | IStore[]>([]);
  // for temporary state selected
  const [tempSelected, setTempSelected] = useState<IProductVariant[] | IStore[]>([]);
  const { data: dataPromoTag, isLoading: isLoadingPromo } = useGetPromoTaggingById(Number(params.id));
  const [perPage, setPerPage] = useState<number>(10);
  const [dataProductVariants, setDataProductVariants] = useState<IProductVariant[]>([]);
  const [dataStores, setDataStores] = useState<IStore[]>([]);
  const [totalProduct, setTotalProduct] = useState<number>(50);
  const [totalStore, setTotalStore] = useState<number>(50);
  const [dataStoresDirectTo, setDataStoresDirectTo] = useState<IStoreOptions[]>([]);
  const [dataProductsDirectTo, setDataProductsDirectTo] = useState<IProductOptions[]>([]);
  const [dataPromoDirectTo, setDataPromoDirectTo] = useState<IPromoTagOptions[]>([]);

  const [paginator, setPaginator] = useState({
    currentPage: 1,
    range: {
      start: 0,
      end: perPage - 1
    }
  });

  const initialValueFormik = {
    name: '',
    valid_from: '',
    valid_to: '',
    street: '',
    status: '',
    directTo: '',
    productId: '',
    merchantId: '',
    promoDetailId: '',
    action: '',
    landingPageType: '',
    category: ''
  };

  const formik = useFormik({
    initialValues: initialValueFormik,
    validationSchema: schemaEditPromoTagging,
    onSubmit: ()=>{
      onSaveEdit();
    }
  });

  const findProductsDebounce = debounce(async (findName: string | null) => {
    if (!findName) return;

    const { data } = await supabase.from(TABLE.PRODUCT).select('id, name')
      .eq('status', 'APPROVED').eq('is_active', true).ilike('name', `%${findName}%`).limit(10);

    if (data && data.length > 0) {
      const dataProducts = data.map((it) => {
        return {
          code: it.id,
          label: it.name
        };
      });
      const concatData = uniqBy([...dataProductsDirectTo || [], ...dataProducts], 'code');
      setDataProductsDirectTo(concatData);
    }
  }, 300);

  const findProducts = async (findName: string | null) => {
    findProductsDebounce(findName);
  };

  const optionProducts = useMemo(() => {
    return dataProductsDirectTo;
  }, [dataProductsDirectTo]);

  const findStoresDebounce = debounce(async (findName: string | null) => {
    if (!findName) return;

    const { data } = await supabase.from(TABLE.MERCHANTS).select('id, name').ilike('name', `*${findName}*`).limit(10);

    if (data && data.length > 0) {
      const dataStores = data.map((it) => {
        return {
          code: it.id,
          label: it.name
        };
      });
      const concatData = uniqBy([...dataStoresDirectTo || [], ...dataStores], 'code');
      setDataStoresDirectTo(concatData);
    }
  }, 300);

  const findStores = async (findName: string | null) => {
    findStoresDebounce(findName);
  };

  const optionStores = useMemo(() => {
    return dataStoresDirectTo;
  }, [dataStoresDirectTo]);

  const findPromosDebounce = debounce(async (findName: string | null) => {
    if (!findName) return;

    const { data } = await supabase.from(TABLE.PROMOS).select('id, name')
      .eq('type', 'landing_page').eq('status', true).ilike('name', `*${findName}*`).limit(10);

    if (data && data.length > 0) {
      const dataPromos = data.map((it) => {
        return {
          code: it.id,
          label: it.name
        };
      });
      const concatData = uniqBy([...dataPromoDirectTo || [], ...dataPromos], 'code');
      setDataPromoDirectTo(concatData);
    }
  }, 300);

  const findPromos = async (findName: string | null) => {
    findPromosDebounce(findName);
  };

  const optionPromos = useMemo(()=> {
    return dataPromoDirectTo;
  }, [dataPromoDirectTo]);

  const { data: dataPromos } = usePromos(true);
  const optionListPromos: IPromoTagOptions[] = useMemo(() => {
    return (
      (Array.isArray(dataPromos?.data) &&
        dataPromos?.data.map((promo) => {
          return {
            label: promo.name,
            code: promo.id
          };
        })) ||
      []
    );
  }, [dataPromos]);

  const { data: dataProduct } = useProduct();
  const optionListProduct: IProductOptions[] = useMemo(() => {
    return (
      (Array.isArray(dataProduct?.data) &&
        dataProduct?.data.map((product) => {
          return {
            label: product.name,
            code: product.id
          };
        })) ||
      []
    );
  }, [dataProduct]);

  const { data: dataMerchants } = useMerchatns();
  const optionListMerchants: IStoreOptions[] = useMemo(() => {
    return (
      (Array.isArray(dataMerchants?.data) &&
        dataMerchants?.data.map((merchant) => {
          return {
            code: merchant.id,
            label: merchant.name
          };
        })) ||
      []
    );
  }, [dataMerchants]);

  // set default option list merchants
  useEffect(() => {
    setDataStoresDirectTo(optionListMerchants);
  }, [optionListMerchants]);

  // set default option list products
  useEffect(() => {
    setDataProductsDirectTo(optionListProduct);
  }, [optionListProduct]);

  // set default option list promo
  useEffect(()=> {
    setDataPromoDirectTo(optionListPromos);
  }, [optionListPromos]);

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const promoTagging = useMemo(() => {
    const data = dataPromoTag?.data;
    if (data?.type === 'topKeywordList') {
      const result = {
        id: data?.id,
        category: data?.type,
        name: data?.name,
        description: data?.description,
        showDescription: data?.tag_metadata?.showDescription,
        directTo: String(data?.tag_metadata?.directTo),
        validFrom: String(data?.valid_from),
        validTo: String(data?.valid_to),
        applyToStreet: String(data?.tag_metadata?.applyToStreet),
        status: String(data?.status),
        action: data?.tag_metadata?.action,
        merchantId: { code: data?.tag_metadata.merchant_id, label: '' } || { code: '', label: '' },
        productId: optionListProduct.find((lp) => lp.code === data?.tag_metadata?.product_id) || { code: '', label: '' },
        promoDetailId: { code: data?.tag_metadata.promos_id, label: '' } || { code: '', label: '' },
        landingPageType: data.url
      };
      setFormEdit(result);
    } else if (data?.type === 'plp' || data?.type === 'landingPage') {
      const result = {
        id: data?.id,
        category: 'keywordRecommendation',
        name: data?.name,
        description: data?.description,
        showDescription: data?.tag_metadata?.showDescription,
        directTo: String(data?.tag_metadata?.directTo),
        validFrom: String(data?.valid_from),
        validTo: String(data?.valid_to),
        applyToStreet: String(data?.tag_metadata?.applyToStreet),
        status: String(data?.status),
        action: data?.tag_metadata?.action,
        merchantId: { code: '', label: '' },
        productId: { code: '', label: '' },
        promoDetailId: { code: '', label: '' },
        landingPageType: data.url
      };
      setFormEdit(result);
    } else if (data?.type === 'newProduct') {
      const result = {
        id: data?.id,
        category: data?.type,
        name: data?.name,
        description: data?.description,
        showDescription: data?.tag_metadata?.showDescription,
        directTo: String(data?.tag_metadata?.directTo),
        validFrom: String(data?.valid_from),
        validTo: String(data?.valid_to),
        applyToStreet: String(data?.tag_metadata?.applyToStreet),
        status: String(data?.status),
        action: data?.tag_metadata?.action,
        selected: data?.tag_metadata?.product,
        merchantId: { code: '', label: '' },
        productId: { code: '', label: '' },
        promoDetailId: { code: '', label: '' },
        landingPageType: data.url
      };
      setSelected(result.selected);
      setFormEdit(result);
    } else if (data?.type === 'storeRecommendation') {
      const result = {
        id: data?.id,
        category: data?.type,
        name: data?.name,
        description: data?.description,
        showDescription: data?.tag_metadata?.showDescription,
        directTo: String(data?.tag_metadata?.directTo),
        validFrom: String(data?.valid_from),
        validTo: String(data?.valid_to),
        applyToStreet: String(data?.tag_metadata?.applyToStreet),
        status: String(data?.status),
        action: data?.tag_metadata?.action,
        selected: data?.tag_metadata?.store,
        merchantId: { code: '', label: '' },
        productId: { code: '', label: '' },
        promoDetailId: { code: '', label: '' },
        landingPageType: data.url
      };
      setSelected(result.selected);
      setFormEdit(result);
    }
  }, [dataPromoTag, optionListPromos, optionListProduct]);

  useEffect(() => {
    formik.setValues({
      action: formEdit.action,
      directTo: formEdit.directTo,
      merchantId: formEdit.merchantId.code || '',
      name: formEdit.name,
      productId: formEdit.productId.code || '',
      promoDetailId: formEdit.promoDetailId.code || '',
      status: formEdit.status,
      street: formEdit.applyToStreet,
      valid_to: formEdit.validTo,
      valid_from: formEdit.validFrom,
      landingPageType: formEdit.landingPageType || '',
      category: formEdit.category
    });
  }, [formEdit]);

  // get detail product
  const { data: productPromo } = useProductById(dataPromoTag?.data?.tag_metadata?.product_id);
  // get detail merchant
  const { data: dataMerchant } = useMerchantById(dataPromoTag?.data?.tag_metadata?.merchant_id);
  // get detail promo
  const { data: dataPromo } = useGetPromoById(dataPromoTag?.data?.tag_metadata?.promos_id);

  // detail product
  const detailProductPromo = useMemo(() => {
    if (productPromo?.data) {
      return productPromo?.data?.name;
    }
  }, [productPromo]);

  // detail merchant
  const detailMerchantPromo = useMemo(() => {
    if (dataMerchant?.data) {
      return dataMerchant?.data?.name;
    }
  }, [dataMerchant]);

  // detail promo
  const detailPromo = useMemo(() => {
    if (dataPromo?.data) {
      return dataPromo?.data?.name;
    }
  }, [dataPromo]);

  // detail landing page
  const detailLandingPage = useMemo(() => {
    if (dataPromoTag?.data) {
      const fillterLandingPage = optionLandingPage.find((it) => it.code == dataPromoTag.data.url);
      return fillterLandingPage?.label;
    }
  }, [dataPromoTag]);

  const { data: getStreets } = useStreet();
  const optionStreet: IBannerOptions[] = useMemo(() => {
    return (
      (Array.isArray(getStreets?.data) &&
        getStreets?.data.map((street) => {
          return {
            label: street.name,
            code: street.id
          };
        })) ||
      []
    );
  }, [getStreets]);

  const validateDate: boolean = useMemo(() => {
    return new Date(formEdit.validTo).getTime() < new Date(formEdit.validFrom).getTime();
  }, [formEdit]);

  const showSuccessToast = () => {
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: 'successfully update',
      life: 3000
    });
    const timeOut = setTimeout(() => {
      setIsLoading(false);
      navigate('/promo-tagging');
      return () => clearTimeout(timeOut);
    }, 2000);
  };

  const showFailedToast = (message?: string) => {
    toast.current?.show({
      severity: 'error',
      summary: 'Failed',
      detail: message || 'Failed update',
      life: 2000
    });
  };

  const handleClickCancel = useCallback(() => {
    setIsLoading(false);
    navigate('/promo-tagging');
  }, []);

  const onCancel = () => {
    setVisible(false);
    setTempSelected([]);
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  };

  const arrayProduct: IProductVariant[] = useMemo(() => [], []);
  const getProdVars = async (start: number, end: number, search?: string) => {
    const query = supabase.from(TABLE.PRODUCT_VARIANTS)
      .select('id, products(name),merchants(name)')
      .eq('is_primary_variant', true)
      .eq('is_active', true);
    let counter: number = 0;
    if (search && search.length >= 3) {
      const { data: prodIds, count: prodCount } = await supabase.from(TABLE.PRODUCT)
        .select('id', { count: 'exact' }).eq('status', 'APPROVED').eq('is_active', true)
        .ilike('name', `%${search}%`).range(start, end);
      if (prodIds) {
        const ids = prodIds.map((item)=> item.id).filter((item)=> item != null);
        query.in('product_id', ids);
        counter = Number(prodCount);
        const { data: prodVar } = await query as PostgrestMaybeSingleResponse<IProductVariantSupabase[]>;
        if (prodVar) {
          const prodVariants = prodVar.map((item) => {
            return {
              id: String(item.id),
              product: (item.products as IProductSupabase)?.name,
              store: (item.merchants as IMerchantSupabase)?.name
            };
          });
          const total = counter;
          setTotalProduct(total);
          setDataProductVariants(prodVariants);
        }
      }
    } else {
      query.eq('products.status', 'APPROVED').
        eq('products.is_active', true);
      let rangeStart = 0;
      let rangeEnd = 9;
      while (arrayProduct.length < perPage*5 + end) {
        const { data: prodVar } = await query.range(rangeStart, rangeEnd) as PostgrestMaybeSingleResponse<IProductVariantSupabase[]>;
        if (prodVar) {
          const prods = prodVar.map((item)=> {
            return {
              id: String(item.id),
              product: (item.products as IProductSupabase)?.name,
              store: (item.merchants as IMerchantSupabase)?.name
            };
          }).filter((item)=> item.product != null);
          arrayProduct.push(...prods);
        }
        rangeStart += perPage;
        rangeEnd += perPage;
      }
      const prodVariants = arrayProduct.slice(start, end+1);
      const total = arrayProduct.length;
      setTotalProduct(total);
      setDataProductVariants(prodVariants);
    }
  };
  const getStore = async (start: number, end: number, search?: string) => {
    const query = supabase.from(TABLE.MERCHANTS)
      .select('id, name', { count: 'exact' })
      .range(start, end);
    if (search && search != '') {
      query.ilike('name', `%${search}%`);
    }
    const { data: stores, count: merchantCount } = await query;
    if (stores && merchantCount) {
      const merchants = stores.map((item) => {
        return {
          id: item?.id,
          store: item?.name
        };
      });
      setTotalStore(merchantCount);
      setDataStores(merchants);
    } else {
      setTotalStore(0);
      setDataStores([]);
    }
  };

  const totalRecords = useMemo(() => {
    const total = formEdit.category === 'newProduct' ? totalProduct : totalStore;
    return total || 0;
  }, [totalProduct, totalStore]);

  const totalPages = useMemo(() => {
    return Math.ceil(totalRecords / perPage);
  }, [totalRecords, perPage]);

  const onSubmit = useCallback(() => {
    if (tempSelected.length < 1 || tempSelected.length > 6) {
      setValidate(true);
    } else {
      setSelected(tempSelected);
      setValidate(false);
      setVisible(false);
    }
  }, [tempSelected]);

  const onChangeFormEditPromotagging = (key: string, value: string | number | null) => {
    formik.setFieldValue(key, value?.toString());
    setFormEdit((prevState) => {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  const handleSearch = useCallback((value: string) => {
    setSearch(value);
  }, []);

  const handleOnChangeDirectTo = useCallback(() => {
    setFormEdit((prevState) => {
      return {
        ...prevState,
        productId: { code: '', label: '' },
        merchantId: { code: '', label: '' },
        promoDetailId: { code: '', label: '' }
      };
    });
  }, []);

  const onSaveEdit = useCallback(async ()=> {
    setIsLoading(true);
    if (formEdit.category === 'topKeywordList') {
      if (Array.isArray(selectedStreet)) {
        const applyToStreet = selectedStreet.join(', ');

        const metadata = {
          showDescription: Boolean(formEdit.showDescription),
          applyToStreet: applyToStreet || '',
          product_id: Number(formEdit.productId.code) || null,
          merchant_id: formEdit.merchantId.code || '',
          directTo: formEdit.directTo || '',
          promos_id: formEdit.promoDetailId.code || ''
        };

        const editPromoPayload = {
          type: 'topKeywordList',
          name: String(formEdit.name),
          description: String(formEdit.description),
          tag_metadata: metadata,
          valid_from: String(formEdit.validFrom),
          valid_to: String(formEdit.validTo),
          status: String(formEdit.status),
          url: formEdit.directTo === 'LANDING_PAGE' ? formEdit.landingPageType : null
        };

        const { error } = await supabase
          .from(TABLE.PROMO_TAGGING)
          .update({
            name: editPromoPayload.name,
            description: editPromoPayload.description,
            tag_metadata: editPromoPayload.tag_metadata,
            valid_from: editPromoPayload.valid_from,
            valid_to: editPromoPayload.valid_to,
            status: editPromoPayload.status,
            url: editPromoPayload.url
          })
          .eq('id', formEdit.id);

        if (error) {
          showFailedToast();
          setIsLoading(false);
        } else {
          setSelected([]);
          showSuccessToast();
        }
      }
    } else if (formEdit.category === 'newProduct') {
      const metaProductVariant: IMetaProductVariant[] = (selected as IProductVariant[]).map((i) => {
        return {
          id: i.id,
          product: i.product
        };
      });

      const metadata = {
        product: metaProductVariant || [],
        action: formEdit.action || ''
      };

      const editPromoPayload = {
        type: formEdit.category,
        name: formEdit.name,
        description: formEdit.description,
        status: formEdit.status,
        tag_metadata: metadata,
        valid_from: formEdit.validFrom,
        valid_to: formEdit.validTo,
        url: formEdit.directTo === 'LANDING_PAGE' ? formEdit.landingPageType : null
      };

      const { data } = await supabase.rpc('catalogue.delete_action', {
        'table_name': 'product_variant_promo_tags', 'column_name': 'promo_tag_id', 'val': [formEdit.id]
      });

      if (!data[0].success) {
        showFailedToast('failed delete in table product_variant_promo_tag');
        setIsLoading(false);
        return;
      }

      const { error } = await supabase
        .from(TABLE.PROMO_TAGGING)
        .update({
          name: editPromoPayload.name,
          description: editPromoPayload.description,
          tag_metadata: editPromoPayload.tag_metadata,
          valid_from: editPromoPayload.valid_from,
          valid_to: editPromoPayload.valid_to,
          status: editPromoPayload.status,
          url: editPromoPayload.url
        })
        .eq('id', formEdit.id);

      (selected as IProductVariant[]).forEach(async (i) => {
        const { error: errInsertProductVariantPromoTag } = await supabase
          .from(TABLE.PRODUCT_VARIANT_PROMO_TAGS)
          .insert([
            {
              product_variant_id: i.id,
              promo_tag_id: formEdit.id
            }
          ]);

        if (errInsertProductVariantPromoTag) {
          showFailedToast('failed insert in table product_variant_promo_tag');
          setIsLoading(false);
        }
      });

      if (error) {
        showFailedToast('Error Update Promo Tagging');
        setIsLoading(false);
      } else {
        setSelected([]);
        showSuccessToast();
      }
    } else if (formEdit.category === 'storeRecommendation') {
      const metaStore: IMetaStore[] = (selected as IStore[]).map((i) => {
        return {
          id: i.id,
          store: i.store
        };
      });

      const metadata = {
        action: String(formEdit.action) || '',
        store: metaStore || []
      };

      const editPromoPayload = {
        type: formEdit.category,
        name: formEdit.name,
        description: formEdit.description,
        status: formEdit.status,
        tag_metadata: metadata,
        valid_from: formEdit.validFrom,
        valid_to: formEdit.validTo
      };

      const { error } = await supabase
        .from(TABLE.PROMO_TAGGING)
        .update({
          name: editPromoPayload.name,
          description: editPromoPayload.description,
          tag_metadata: editPromoPayload.tag_metadata,
          valid_from: editPromoPayload.valid_from,
          valid_to: editPromoPayload.valid_to,
          status: editPromoPayload.status
        })
        .eq('id', formEdit.id);

      if (error) {
        showFailedToast();
        setIsLoading(false);
      } else {
        setSelected([]);
        showSuccessToast();
      }
    } else if (formEdit.category === 'keywordRecommendation') {
      let type: string = '';
      if (formEdit?.directTo === 'PLP') {
        type = 'plp';
      }
      if (formEdit?.directTo === 'LANDING_PAGE') {
        type = 'landingPage';
      }

      const metadata = {
        product_id: Number(formEdit.productId.code )|| null,
        merchant_id: formEdit.merchantId.code || null,
        directTo: formEdit.directTo,
        promos_id: formEdit.promoDetailId.code || null
      };

      const editPromoPayload = {
        type: type,
        name: String(formEdit.name),
        description: String(formEdit.description),
        tag_metadata: metadata,
        valid_from: String(formEdit.validFrom),
        valid_to: String(formEdit.validTo),
        status: String(formEdit.status),
        url: formEdit.directTo === 'LANDING_PAGE' ? formEdit.landingPageType : null
      };

      const { error } = await supabase
        .from(TABLE.PROMO_TAGGING)
        .update({
          name: editPromoPayload.name,
          description: editPromoPayload.description,
          tag_metadata: editPromoPayload.tag_metadata,
          valid_from: editPromoPayload.valid_from,
          valid_to: editPromoPayload.valid_to,
          status: editPromoPayload.status,
          url: editPromoPayload.url
        })
        .eq('id', formEdit.id);
      if (error) {
        showFailedToast();
        setIsLoading(false);
      } else {
        setSelected([]);
        showSuccessToast();
      }
    }
  }, [formEdit, selected, selectedStreet]);

  // Pagination Product
  const handleClickNext = useCallback(() => {
    paginator.currentPage <= totalPages &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: paginator.currentPage + 1,
        range: {
          start: paginator.range.start + perPage,
          end: paginator.range.end + perPage
        }
      }));
  }, [paginator, totalPages, perPage]);

  const handleClickPrev = useCallback(() => {
    paginator.range.start > 0 &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: prev.currentPage - 1,
        range: {
          start: prev.range.start - perPage,
          end: prev.range.end - perPage
        }
      }));
  }, [paginator, perPage]);

  const handleClickLastPage = useCallback(() => {
    paginator.currentPage <= totalPages &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: totalPages,
        range: {
          start: (totalRecords as number) - (totalPages * perPage - (totalRecords as number)),
          end: (totalRecords as number)
        }
      }));
  }, [perPage, totalRecords, totalPages, paginator]);

  const handleClickFirstPage = useCallback(() => {
    paginator.currentPage > 0 &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      }));
  }, [paginator, perPage]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent) => {
    setPerPage(event.value);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleClickPage = useCallback(
    (page: number) => () => {
      setPaginator((prev) => ({
        ...prev,
        currentPage: page,
        range: {
          start: page === 1 ? page - 1 : perPage * page - perPage,
          end: page * perPage - 1
        }
      }));
    },
    [perPage]
  );

  const handleOpenModal = useCallback(() => {
    setVisible(true);
    setTempSelected(selected);
  }, [selected]);

  useEffect(()=>{
    if (formEdit.category === 'newProduct' && visible) {
      getProdVars(paginator.range.start, paginator.range.end, debounceSearch);
    }
  }, [paginator, formEdit.category, perPage, visible, debounceSearch]);
  useEffect(()=> {
    if (formEdit.category === 'storeRecommendation' && visible) {
      getStore(paginator.range.start, paginator.range.end, debounceSearch);
    }
  }, [paginator, formEdit.category, perPage, visible, debounceSearch]);

  return {
    data: {
      toast,
      isLoadingPromo,
      dataStores,
      dataProductVariants,
      search,
      selectedStreet,
      optionStreet,
      validateDate,
      optionStores,
      optionProducts,
      optionPromos,
      isLoading,
      formEdit,
      optionDirectTo,
      optionKeywordRecomendations,
      promoTagging,
      validate,
      selected,
      visible,
      formik,
      paginator,
      perPage,
      totalPages,
      totalRecords,
      detailProductPromo,
      detailMerchantPromo,
      detailPromo,
      detailLandingPage,
      optionLandingPage,
      tempSelected
    },
    method: {
      onSaveEdit,
      handleClickCancel,
      setSelected,
      setVisible,
      handleSearch,
      onCancel,
      onSubmit,
      setSelectedStreet,
      handleOnChangeDirectTo,
      onChangeFormEditPromotagging,
      isFormFieldValid,
      handleChangeDropdownPage,
      handleClickFirstPage,
      handleClickLastPage,
      handleClickNext,
      handleClickPrev,
      handleClickPage,
      handleOpenModal,
      setTempSelected,
      findProducts,
      findPromos,
      findStores
    }
  };
};

export default usePromoTaggingEdit;
