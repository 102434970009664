import LoadingOverlay from '@/components/base/LoadingOverlay';
import ShopComponents from '../../component/TitleAndTable';
import useShop from '../ShopHooks';

const WishList = () => {
  const { data: { listWishList, isLoadingWishList, isInitialLoadingProductVariant, isInitialLoadingProduct } } = useShop();
  const column = [
    { field: 'wishlist', header: 'Wish List' },
    { field: 'productName', header: 'Product Name' },
    // TODO: handle not implement
    // { field: 'userDescription', header: 'User Description' },
    { field: 'quantity', header: 'Quantity' },
    { field: 'addLocale', header: 'Add Locale' },
    { field: 'addDate', header: 'Add Date' },
    { field: 'daysInWishList', header: 'Days in Wish List' }
  ];

  return (
    <>
      <LoadingOverlay show={isLoadingWishList || isInitialLoadingProduct || isInitialLoadingProductVariant} />
      <ShopComponents column={column} titleLabel="Wish List" valueTable={listWishList as []} />
    </>
  );
};

export default WishList;
