import * as yup from 'yup';
import { MESSAGE } from '@/constants';

const schemaCreateSpecification = yup.object({
  name: yup.string().required(MESSAGE.REQUIRED),
  value: yup.array().min(1, MESSAGE.REQUIRED).required(MESSAGE.REQUIRED),
  label: yup.string().required(MESSAGE.REQUIRED),
  type: yup.string().required(MESSAGE.REQUIRED)
});

export { schemaCreateSpecification };
