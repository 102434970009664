import { useGetProductFavorites, useGetProductsById, useGetProductVariantById } from '@/services/rest/customerManagement';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

const useShop = () => {
  const { id } = useParams<{ id: string }>();
  const [tabMenuActiveIndex, setTabMenuActiveIndex] = useState(0);
  const [sideMenuActiveIndex, setSideMenuActiveIndex] = useState(0);

  // get diff days
  const getDiffDays = useCallback((date: string) => {
    const currentDay = dayjs();
    const dateWishList = dayjs(date);

    const diffDays = currentDay.diff(dateWishList, 'day');
    return diffDays;
  }, []);

  // product wishlist
  const { data: dataProductFavorite, isLoading: isLoadingWishList } = useGetProductFavorites(id as string);

  // id product variant in product wishlist
  const idsProductVariantWishlist = useMemo(() => {
    return Array.isArray(dataProductFavorite?.data) ?
      dataProductFavorite?.data.map((it) => it.product_variant_id) :
      [];
  }, [dataProductFavorite?.data]);

  const { data: dataProductVariantWishlist, isInitialLoading: isInitialLoadingProductVariant } = useGetProductVariantById(idsProductVariantWishlist as string[]);

  const idsProductWishlist = useMemo(() => {
    return Array.isArray(dataProductVariantWishlist?.data) ?
      dataProductVariantWishlist?.data.map((it) => it.product_id) :
      [];
  }, [dataProductVariantWishlist?.data]);

  const { data: dataProductWishlist, isInitialLoading: isInitialLoadingProduct } = useGetProductsById(idsProductWishlist as number[]);

  const listWishList = useMemo(() => {
    return (Array.isArray(dataProductFavorite?.data) && Array.isArray(dataProductVariantWishlist?.data) && Array.isArray(dataProductWishlist?.data) ) ?
      dataProductFavorite?.data.map((it) => {
        const productVariant = dataProductVariantWishlist?.data?.find((i) => i.id == it.product_variant_id);
        const product = dataProductWishlist?.data?.find((i) => i.id == productVariant?.product_id);

        return {
          wishlist: 'Wish List',
          productName: product?.name || '-',
          // TODO: handle not implement
          // userDescription: '',
          quantity: 1,
          addLocale: it.platform || '-',
          addDate: dayjs(it.created_at).format('MMMM DD YYYY h:mm:ss A') || '-',
          daysInWishList: getDiffDays(it.created_at as string)
        };
      }) :
      [];
  }, [dataProductFavorite?.data, dataProductVariantWishlist?.data, dataProductWishlist?.data]);

  return {
    data: {
      listWishList,
      isLoadingWishList,
      tabMenuActiveIndex,
      sideMenuActiveIndex,
      isInitialLoadingProduct,
      isInitialLoadingProductVariant
    },
    method: {
      setTabMenuActiveIndex,
      setSideMenuActiveIndex
    }
  };
};

export default useShop;
