import { useGetOfficialMerchant, useGetProductHotDealById, useGetProductSubscriptionById, useGetProductTabulationsById } from '@/services/rest/productHighlight';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const useDetailProductHighlight = () => {
  const { search } = useLocation();
  const { id } = useParams();
  const query = new URLSearchParams(search).get('section');

  const selectedId = useMemo(() => {
    if (query === 'Product Pilihan') {
      return {
        productPilihanId: Number(id)
      };
    } else if (query === 'Product Tabulation') {
      return {
        productTabulationId: Number(id)
      };
    } else if (query === 'Product Hot Deal') {
      return {
        prodductHotDealId: Number(id)
      };
    } else if (query === 'Official Store') {
      return {
        officialStoreId: Number(id)
      };
    }
  }, [id, query]);
  const { data: productSubscribtion, isLoading: loadingProductSubscribtion } = useGetProductSubscriptionById(selectedId?.productPilihanId);
  const { data: productTabulation, isLoading: loadingProductTabulation } = useGetProductTabulationsById(selectedId?.productTabulationId);
  const { data: productHotDeal, isLoading: loadingProductHotDeal } = useGetProductHotDealById(selectedId?.prodductHotDealId);
  const { data: officialStore, isLoading: loadingOfficial } = useGetOfficialMerchant(selectedId?.officialStoreId);

  const productHighlight = useMemo(() => {
    if (query === 'Product Pilihan') {
      const data = productSubscribtion?.data;
      return {
        id: data?.id,
        applyToStreet: 'AladinMall',
        applyToSection: query,
        product: data?.product_variants.products.name,
        sortBy: data?.sort_type,
        sortValue: data?.weight,
        validFrom: data?.started_time,
        validTo: data?.ended_time,
        loading: loadingProductSubscribtion,
        status: data?.is_active ? 'Enable' : 'Disable'
      };
    }
    if (query === 'Product Tabulation') {
      const data = productTabulation?.data;
      return {
        id: data?.id,
        applyToStreet: 'AladinMall',
        applyToSection: query,
        applyToTab: data?.product_tabulations?.name,
        product: data?.product_variants?.products?.name,
        sortBy: data?.sort_type,
        sortValue: data?.weight,
        validFrom: data?.started_time,
        validTo: data?.ended_time,
        loading: loadingProductTabulation,
        status: data?.is_active ? 'Enable' : 'Disable'
      };
    }
    if (query === 'Product Hot Deal') {
      const data = productHotDeal?.data;
      return {
        id: data?.id,
        applyToStreet: 'AladinMall',
        applyToSection: query,
        product: data?.products.name,
        validFrom: data?.started_time,
        validTo: data?.ended_time,
        loading: loadingProductHotDeal,
        status: data?.status === 'ACTIVE' ? 'Enable' : 'Disable'
      };
    }
    if (query === 'Official Store') {
      const data = officialStore?.data;
      return {
        product: data?.product_variants?.products?.name,
        id: data?.id,
        applyToStreet: 'AladinMall',
        applyToSection: query,
        loading: loadingOfficial,
        sortBy: data?.sort_type,
        sortValue: data?.weight,
        validFrom: data?.started_time,
        validTo: data?.ended_time,
        status: data?.is_active ? 'Enable' : 'Disable'
      };
    }
  }, [
    loadingOfficial,
    productSubscribtion,
    loadingProductSubscribtion,
    loadingProductTabulation,
    loadingProductHotDeal,
    productHotDeal,
    productTabulation,
    query,
    officialStore,
    productSubscribtion?.data,
    productTabulation?.data,
    productHotDeal?.data,
    officialStore?.data
  ]);
  return {
    data: {
      productHighlight,
      query,
      id
    },
    method: {}
  };
};

export default useDetailProductHighlight;
