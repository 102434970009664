
import IconCancel from '@/assets/IconCancel';
import IconImage from '@/assets/IconImage';
import IconUpload from '@/assets/IconUpload';
import { TABLE, URL_CHECK_NOW } from '@/constants';
import { supabase } from '@/lib/supabase';
import {
  deletePromos,
  IFilterPromos,
  useAllPromos,
  useCategoryPromosFilterOption
} from '@/services/rest/promo';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { schemaCreatePromoPage } from './validation';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { debounce, isEmpty, uniqBy } from 'lodash';
import { FileUpload } from 'primereact/fileupload';
import { storageUpload } from '@/utils/storage';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { useHistoryStore } from '@/store/useHistoryStore';
import { IFilterHistoryItems } from '@/components/base/FilterHistory';
import { useDebounce } from 'primereact/hooks';

interface IImagesUrl {
  desktop: string;
  mobile: string;
  thumbnail: string;
}
export interface Promos {
  id: string;
  code: string;
  description: string;
  image: string;
  amount_type: string;
  amount: number;
  start_date: Date;
  end_date: Date;
  status: boolean;
  type: string;
  accepted_payment_provider_ids: AcceptedPaymentProviderID[];
  payment_bin_rule: string;
  quota: number;
  user_quota: number;
  is_quota_unlimited: boolean;
  is_user_quota_unlimited: boolean;
  min_discount_amount: number;
  max_discount_amount: number;
  min_payment_amount: number;
  max_payment_amount: number;
  name: string;
  created_at: Date;
  title: string;
  code_info: string;
  path_url: string;
  promo_category_id: number;
  periode_start: Date;
  periode_end: Date;
  images: IImagesUrl;
  multiple_code: MultipleCode[];
}
export interface AcceptedPaymentProviderID {
  id: number;
  code: string;
  label: string;
}

export interface CategoryPromo {
  id: number;
  name: string;
}

export interface MultipleCode {
  code: string;
  description: string
}

export interface ImagesMetadata {
  banner_desktop: string;
  banner_mobile: string;
  banner_thumbnail: string;
}

interface IOptions {
  label: string;
  items: Array<IItemsOption>;
}

interface IItemsOption {
  label: string;
  value: string | boolean;
  name: string;
}
interface IFormik{
  name: string;
  period_from: string;
  period_to: string;
  short_desc: string;
  url_check_now: string;
  promo_category_id: string;
  show_from: string;
  show_to: string;
  mobile: string;
  desktop: string;
  thumbnail: string;
  maxMobileSize: number;
  maxDesktopSize: number;
  maxThumbnailSize: number;
}
interface IFormPromoPage {
  name: string
  status: boolean;
  show_from: string;
  show_to: string;
  short_desc: string;
  desc: string;
  url_check_now: string;
  promo_category_id: number;
  period_from: string;
  period_to: string;
  desktop_name: string;
  images: {
    desktop: string;
    mobile: string;
    thumbnail: string;
  },
  mobile_name: string;
  images_mobile: string;
  thumbnail_name: string;
  images_thumbnail: string;
  multiple_code: MultipleCode[],
  changeImage: boolean
};

const useCustom = () => {
  const initialFilter: IFilterPromos = {
    name: '',
    show_date: [],
    category: '',
    status: ''
  };

  const initialFormik:IFormik = {
    name: '',
    period_from: '',
    period_to: '',
    short_desc: '',
    url_check_now: '',
    promo_category_id: '',
    show_from: '',
    show_to: '',
    mobile: '',
    desktop: '',
    thumbnail: '',
    maxMobileSize: 0,
    maxDesktopSize: 0,
    maxThumbnailSize: 0
  };

  const initialForm = {
    name: '',
    status: false,
    show_from: '',
    show_to: '',
    short_desc: '',
    desc: '',
    url_check_now: '',
    promo_category_id: 0,
    period_from: '',
    period_to: '',
    desktop_name: '',
    images: {
      desktop: '',
      mobile: '',
      thumbnail: ''
    },
    mobile_name: '',
    images_mobile: '',
    thumbnail_name: '',
    images_thumbnail: '',
    multiple_code: [{ code: '', description: '' }],
    changeImage: false
  };

  const [foundPromoNames, setFoundPromoNames] = useState<{ id: string, name: string }[]|null>(null);
  const [chekedUrl, setChekedUrl] = useState<boolean>(false);
  const params = useParams();
  const navigate = useNavigate();
  const fileUpload = useRef<FileUpload>(null);
  const fileMobileRef = useRef<FileUpload>(null);
  const fileDekstopRef = useRef<FileUpload>(null);
  const fileThumbnailRef = useRef<FileUpload>(null);
  const [formInput, setFormInput] = useState<IFormPromoPage>(initialForm);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCheckedPromoCode, setIsCheckedPromoCode] = useState<boolean>(false);
  const [imagesUrl, setImagesUrl] = useState<IImagesUrl>({
    desktop: '',
    mobile: '',
    thumbnail: ''
  });
  const [isShowSection, setIsShowSection] = useState({
    promoInformation: true,
    promoCode: true
  });
  const [perPage, setPerPage] = useState<number>(10);
  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const currentPage = parseInt(visitedPage.promoPage.toString()) ?? 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : perPage - 1;
  const [paginator, setPaginator] = useState({
    currentPage,
    range: {
      start,
      end
    }
  });
  const [jumpToPage, setJumpToPage] = useState<number>(1);
  const [itemFilters, setItemFilters] = useState(lastFilterPage.promoPage != '' ? JSON.parse(String(lastFilterPage.promoPage)) : initialFilter);
  const [filters, setFilters] = useState(lastFilterPage.promoPage != '' ? JSON.parse(String(lastFilterPage.promoPage)) : initialFilter);
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);
  const [search, debounceSearch, setSearch] = useDebounce('', 1500);

  const formik = useFormik({
    initialValues: initialFormik,
    validationSchema: schemaCreatePromoPage,
    onSubmit: ()=>{
      if (params.id) {
        handleEditPromoPage();
      } else {
        handleCreatePromoPage();
      }
    }
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const [categoryPromo, setCategoryPromo] = useState<CategoryPromo[]>([]);
  const chooseOptions = {
    icon: IconImage,
    iconOnly: true,
    className: 'custom-choose-btn p-button-rounded p-button-outlined'
  };
  const uploadOptions = {
    icon: IconUpload,
    iconOnly: true,
    className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'
  };
  const cancelOptions = {
    icon: IconCancel,
    iconOnly: true,
    className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'
  };
  const toast = useRef<Toast>(null);

  const { data: dataPromos, isLoading: isLoadingPromos, refetch: refecthPromo } = useAllPromos(filters, 'landing_page', debounceSearch, paginator.range);

  const totalRecords = useMemo(() => {
    return dataPromos?.count ? dataPromos.count : 0;
  }, [dataPromos]);

  const totalPages = useMemo(() => {
    return Math.ceil(totalRecords / perPage);
  }, [totalRecords, perPage]);

  const promos = useMemo(() => {
    if (!Array.isArray(dataPromos?.data)) return [];
    return dataPromos?.data;
  }, [dataPromos]);

  const { data: dataPromosOption } = useCategoryPromosFilterOption();

  const optionCategoryPromo = useMemo(() => {
    if (!Array.isArray(dataPromosOption?.data)) return [];
    return dataPromosOption?.data.map((c) => ({
      label: c.name,
      value: c.name
    }));
  }, [dataPromosOption]);

  const findPromoNamesDebounce = debounce(async (findName: string | null) => {
    if (!findName) {
      return;
    }

    const { data } = await supabase
      .from(TABLE.PROMOS)
      .select('id, name')
      .eq('type', 'landing_page')
      .ilike('name', `*${findName}*`)
      .limit(10);

    if (data) {
      const concatData = uniqBy([...foundPromoNames || [], ...data], 'id');
      setFoundPromoNames(concatData);
    }
  }, 250);

  const findPromoNames = async (findName: string | null) => {
    findPromoNamesDebounce(findName);
  };

  const options = useMemo(():IOptions[] => {
    if (!Array.isArray(dataPromosOption?.data)) return [];
    const filterCategory = [...new Set(dataPromosOption?.data.map((p) => p))];
    return [
      {
        label: 'Name',
        items: foundPromoNames?.map((pn) => ({
          label: pn.name,
          name: 'name',
          value: pn.id
        })) || []
      },
      {
        label: 'Category',
        items:
          filterCategory.map((c) => {
            return {
              label: String(c.name),
              name: 'category',
              value: String(c.id)
            };
          }) || []
      },
      {
        label: 'Status',
        items: [
          {
            label: 'Active',
            name: 'status',
            value: true
          },
          {
            label: 'Inactive',
            name: 'status',
            value: false
          }
        ]
      }
    ];
  }, [dataPromosOption, foundPromoNames]);

  const formInputValue = (key: string, value: boolean | string | number | Date | MultipleCode[]) => {
    formik.setFieldValue(key, value);
    setFormInput((prevState) => {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  const showSuccessToast = (msg?:string) => {
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: msg || 'New Promo successfully created',
      life: 2000
    });
    const timeOut = setTimeout(() => {
      resetForm();
      setIsLoading(false);
      navigate('/promo-page');
      return () => clearTimeout(timeOut);
    }, 2000);
  };

  const showFailedToast = (message?: string) => {
    toast.current?.show({
      severity: 'error',
      summary: 'Failed',
      detail: message || 'Failed Create Promo',
      life: 2000
    });
  };

  // upload image yang thumbnail dulu
  const handleFileUpload = useCallback((key: string, e) => {
    const today = dayjs();
    const formattedDate = today.format('YYYY-MM-DD-HH-mm-ss');

    const file = e.files[0];
    switch (key) {
    case 'images':
      formInputValue('desktop_name', formattedDate + file.name);
      formInputValue('changeImage', true);
      break;
    case 'images_mobile':
      formInputValue('mobile_name', formattedDate + file.name);
      formInputValue('changeImage', true);
      break;
    case 'images_thumbnail':
      formInputValue('thumbnail_name', formattedDate + file.name);
      formInputValue('changeImage', true);
      break;
    }
    formik.setValues((prevState) => {
      return {
        ...prevState,
        [key]: file
      };
    });
    setFormInput((prevState) => {
      return {
        ...prevState,
        [key]: file
      };
    });
  }, []);

  // clear file ref image
  const clearFileRefImage = useCallback((key: string) => {
    switch (key) {
    case 'desktop':
      fileDekstopRef.current?.clear();
      break;
    case 'mobile':
      fileMobileRef.current?.clear();
      break;
    case 'thumbnail':
      fileThumbnailRef.current?.clear();
      break;
    default:
    }
  }, [fileDekstopRef, fileMobileRef, fileThumbnailRef]);


  const handleUploadImage = useCallback( async (e, key: string) => {
    const file = e.files[0];

    if (file.size <= 150000) {
      const dataUpload = await storageUpload('web/', file);

      if (dataUpload) {
        setImagesUrl((prev) => ({
          ...prev,
          [key]: dataUpload.src
        }));

        // set validation form images
        formik.setFieldValue(key, dataUpload.src);
      }
    } else {
      clearFileRefImage(key);
    }
  }, [imagesUrl, fileUpload]);


  const handleChekedUrl = useCallback((e: CheckboxChangeEvent) => {
    if (e.checked) {
      formik.setFieldValue('url_check_now', URL_CHECK_NOW);
    } else {
      formik.setFieldValue('url_check_now', '');
    }
    setChekedUrl(Boolean(e.checked));
  }, []);

  const handleCollapseSection = useCallback((key:string)=>()=>{
    setIsShowSection((prev)=>({
      ...prev,
      [key]: !prev[key]
    }));
  }, []);

  const handleAddPromoPage = () => {
    const newPromo = [...formInput.multiple_code];
    newPromo.push({
      code: '',
      description: ''
    });
    formInputValue('multiple_code', newPromo);
  };

  const handleRemovePromo = (index) => {
    if (index === 0 && formInput.multiple_code.length === 1) {
      return formInputValue('multiple_code', [{ code: '', description: '' }]);
    }
    const values = [...formInput.multiple_code];
    values.splice(index, 1);
    formInputValue('multiple_code', values);
  };

  const handleInputChange = (index, event) => {
    const values = [...formInput.multiple_code];
    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;
    formInputValue('multiple_code', values);
  };

  const handleFilter = useCallback((e, field: string) => {
    setItemFilters((prev) => ({
      ...prev,
      [field]: e.target ? e.target.value : e.value
    }));
  }, []);

  const validateDate: Boolean = useMemo(() => {
    return new Date(formInput.period_to).getTime() < new Date(formInput.period_from).getTime();
  }, [formInput]);

  const validateShowDate: Boolean = useMemo(() => {
    return new Date(formInput.show_to).getTime() < new Date(formInput.show_from).getTime();
  }, [formInput]);

  const getCategoryPromo = useCallback(async () => {
    const { data, error } = await supabase.from(TABLE.CATEGORY_PROMO).select('*').limit(10).order('id');
    if (error) {
      showFailedToast();
      return;
    }
    setCategoryPromo(data as CategoryPromo[]);
  }, []);

  const validateMultipleCode = (multiple: MultipleCode) => {
    const isEmptyCode = isEmpty(multiple.code);
    const isEmptyDesc = isEmpty(multiple.description);
    return isEmptyCode && isEmptyDesc;
  };
  const disabledButton = (id?:string, isChecked?: boolean) => {
    const validMultiple = validateMultipleCode(formInput.multiple_code[formInput.multiple_code.length -1]);
    return Boolean(id) || isChecked || validMultiple;
  };

  const handleCreatePromoPage = useCallback( async () => {
    setIsLoading(true);
    const validateMultiple = formInput.multiple_code.length === 1 && validateMultipleCode(formInput.multiple_code[0]);
    const url = chekedUrl ? URL_CHECK_NOW : formInput.url_check_now.replace(/\s/g, '-');
    const payloadInsert = {
      name: formInput.name,
      description: formInput.desc,
      start_date: formInput.period_from,
      end_date: formInput.period_to,
      status: formInput.status,
      title: formInput.short_desc,
      path_url: url,
      type: 'landing_page',
      promo_category_id: formInput.promo_category_id,
      periode_start: formInput.show_from,
      periode_end: formInput.show_to,
      multiple_code: isCheckedPromoCode || validateMultiple ? [] : formInput.multiple_code,
      images: imagesUrl
    };

    const { error } = await supabase.from(TABLE.PROMOS).insert(payloadInsert);

    if (error) {
      showFailedToast('Failed Create Promo Page');
      setIsLoading(false);
      return;
    }

    showSuccessToast();
    refecthPromo();
    fileUpload.current?.clear();
  }, [imagesUrl, chekedUrl, formInput]);


  const handleEditPromoPage = useCallback( async () => {
    setIsLoading(true);
    const validateMultiple = formInput.multiple_code.length === 1 && validateMultipleCode(formInput.multiple_code[0]);
    const url = chekedUrl ? URL_CHECK_NOW : formInput.url_check_now.replace(/\s/g, '-');
    const payloadUpdate = {
      name: formInput.name,
      description: formInput.desc,
      start_date: formInput.period_from,
      end_date: formInput.period_to,
      status: formInput.status,
      title: formInput.short_desc,
      path_url: url,
      type: 'landing_page',
      promo_category_id: formInput.promo_category_id,
      periode_start: formInput.show_from,
      periode_end: formInput.show_to,
      multiple_code: isCheckedPromoCode || validateMultiple ? [] : formInput.multiple_code,
      images: imagesUrl
    };

    const { error } = await supabase.from(TABLE.PROMOS)
      .update(payloadUpdate)
      .eq('id', params?.id);

    if (error) {
      showFailedToast('Failed Edit Promo Page');
      setIsLoading(false);
      return;
    }
    showSuccessToast('Promo successfully edited');
    refecthPromo();
    fileUpload.current?.clear();
  }, [imagesUrl, formInput, isCheckedPromoCode, params]);


  const getDetailPage = useCallback(async () => {
    const { data } = await supabase.from(TABLE.PROMOS).select('*').eq('id', params.id||params.detailId).single();
    if (data) {
      // check when use promo code
      const isUsePromoCode = data.multiple_code.length === 0;
      setIsCheckedPromoCode(isUsePromoCode);

      const multipleCode: MultipleCode[] = [];
      if (!isUsePromoCode) {
        const codes = data.multiple_code?.map((item) => {
          return {
            code: item.code,
            description: item.description
          };
        });
        multipleCode.push(...codes);
      } else {
        multipleCode.push(...initialForm.multiple_code);
      }

      const isChekedHomePage = data.path_url === URL_CHECK_NOW;

      const page = data as Promos;
      formInputValue('name', page.name);
      formInputValue('status', page.status);
      formInputValue('show_from', new Date(page.periode_start));
      formInputValue('show_to', new Date(page.periode_end));
      formInputValue('short_desc', page.title);
      formInputValue('desc', page.description);
      formInputValue('url_check_now', page.path_url);
      formInputValue('promo_category_id', page.promo_category_id);
      formInputValue('period_from', new Date(page.start_date));
      formInputValue('period_to', new Date(page.end_date));
      formInputValue('images_thumbnail', page.image);
      formInputValue('multiple_code', multipleCode);
      setImagesUrl(page.images);
      setChekedUrl(isChekedHomePage);
      formik.setFieldValue('desktop', page.images.desktop);
      formik.setFieldValue('mobile', page.images.mobile);
      formik.setFieldValue('thumbnail', page.images.thumbnail);
    }
  }, []);

  const onDelete = async (id: string) => {
    const { error: errorDeletePromos, count: countDeletePromos } = await deletePromos(id);
    if (
      errorDeletePromos
    ) {
      showFailedToast();
    }
    if (
      countDeletePromos !== 1
    ) {
      showFailedToast('Forbidden');
    } else {
      showSuccessDelete();
    }
    refecthPromo();
  };

  const showSuccessDelete = () => {
    toast.current?.show({
      severity: 'success',
      summary: 'Confirmed',
      detail: 'Promo deleted succesfully',
      life: 3000
    });
  };
  const confirmDelete = useCallback((id: string) => {
    confirmDialog({
      message: 'Are you sure you want to delete this record?',
      header: 'Delete Confirmation',
      acceptClassName: 'p-button-danger',
      accept() {
        onDelete(id);
      }
    });
  }, []);

  const handleSwitch = async (event, ids) => {
    const query = supabase.from(TABLE.PROMOS).update({
      status: event
    }, { count: 'estimated' }).eq('id', ids).select();

    const { error, count } = await query;
    if (error) {
      showFailedToast('Failed Change Status');
      return;
    }
    if (count !== 1) {
      showFailedToast('Forbidden');
      return;
    }
    refecthPromo();
  };

  const resetForm = () => {
    setFormInput(initialForm);
  };

  const handleClearFilter = useCallback(() => {
    setLastFilterPage({
      ...lastFilterPage,
      promoPage: ''
    });
    setItemFilters(initialFilter);
    setFilters(initialFilter);
    setFilterHistory([]);
    setSearch('');
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [lastFilterPage, perPage]);

  const handleDeleteFilterHistory = useCallback(
    (key: string, value: string[]) => {
      const items = value[0].split(',');
      items.forEach((i) => {
        setFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));

        setItemFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));
      });

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setSearch('');
    },
    []
  );

  useEffect(() => {
    getCategoryPromo();
  }, []);

  useEffect(() => {
    if (params.id || params.detailId) {
      getDetailPage();
    }
  }, [params]);

  // Pagination Function
  const handleClickNext = useCallback(() => {
    paginator.currentPage <= totalPages &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: paginator.currentPage + 1,
        range: {
          start: paginator.range.start + perPage,
          end: paginator.range.end + perPage
        }
      }));
  }, [paginator, totalPages, perPage]);

  const handleClickPrev = useCallback(() => {
    paginator.range.start > 0 &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: prev.currentPage - 1,
        range: {
          start: prev.range.start - perPage,
          end: prev.range.end - perPage
        }
      }));
  }, [paginator, perPage]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent) => {
    setPerPage(event.value);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleClickPage = useCallback(
    (page: number) => () => {
      setPaginator((prev) => ({
        ...prev,
        currentPage: page,
        range: {
          start: page === 1 ? page - 1 : perPage * page - perPage,
          end: page * perPage - 1
        }
      }));
    },
    [perPage]
  );

  const handleCheckedPromoCode = useCallback((e: CheckboxChangeEvent) => {
    setIsCheckedPromoCode(e.checked as boolean);
  }, []);

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, totalRecords - 1);

      setPaginator(() => ({
        currentPage: jumpToPage > totalPages ? totalPages : value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, totalRecords]);

  const handleSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>)=>{
    setSearch(currentTarget.value);
    const newKeyword = {
      name: 'Keyword',
      items: [{
        label: currentTarget.value,
        value: currentTarget.value,
        name: currentTarget.value
      }]
    };

    if (currentTarget.value != '') {
      setFilterHistory((prev: IFilterHistoryItems[]) => {
        const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
        if (existingIndex !== -1) {
          prev[existingIndex] = newKeyword;
        } else {
          prev.push(newKeyword);
        }
        return [...prev];
      });

      setPaginator({
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      });
    } else {
      handleDeleteFilterHistory('Keyword', ['Keyword']);
    }
  }, [perPage]);

  const handleClickSubmitFilter = useCallback(() => {
    setFilters(itemFilters);
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [itemFilters, perPage]);

  const storeFilterHistory = useCallback((filter) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter?.show_date && filter.show_date[0]) {
      let filterDate = `${dayjs(filter.show_date[0]).format('YYYY-MM-DD')}`;
      if (filter.show_date.length > 1 && filter.show_date[1] !== null) {
        filterDate = `${dayjs(filter.show_date[0]).format('YYYY-MM-DD')} - ${dayjs(filter.show_date[1]).format('YYYY-MM-DD')}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Show Date', 'show_date', filterDate));
    }

    if (filter.name !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Name', 'name', filter.name));
    }

    if (filter.category !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Category', 'category', filter.category));
    }

    if (filter.status !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Status', 'status', filter.status.toUpperCase()));
    }

    setFilterHistory(filterHistoryItems);
  }, []);

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  useEffect(() => {
    // update store visitedPage with latest page
    setVisitedPage({
      ...visitedPage,
      promoPage: paginator.currentPage
    });
  }, [paginator.currentPage]);

  // update store lastFilterPage with latest filter
  useEffect(() => {
    setLastFilterPage({
      ...lastFilterPage,
      promoPage: JSON.stringify(filters)
    });
  }, [filters]);

  return {
    data: {
      validateShowDate,
      validateDate,
      chekedUrl,
      promos,
      toast,
      formInput,
      chooseOptions,
      uploadOptions,
      cancelOptions,
      params,
      categoryPromo,
      isLoadingPromos,
      options,
      isShowSection,
      filterHistory,
      formik,
      perPage,
      paginator,
      totalPages,
      totalRecords,
      isCheckedPromoCode,
      imagesUrl,
      isLoading,
      fileMobileRef,
      fileDekstopRef,
      fileThumbnailRef,
      itemFilters,
      search,
      optionCategoryPromo
    },
    method: {
      handleRemovePromo,
      handleInputChange,
      handleAddPromoPage,
      handleChekedUrl,
      formInputValue,
      handleFileUpload,
      resetForm,
      confirmDelete,
      handleSearch,
      handleSwitch,
      handleCollapseSection,
      handleClearFilter,
      handleDeleteFilterHistory,
      isFormFieldValid,
      handleChangeDropdownPage,
      handleClickNext,
      handleClickPage,
      handleClickPrev,
      handleCheckedPromoCode,
      findPromoNames,
      handleUploadImage,
      disabledButton,
      handleChangeJumpTopage,
      handleJumpToPage,
      handleFilter,
      handleClickSubmitFilter
    }
  };
};

export default useCustom;
