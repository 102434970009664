import * as yup from 'yup';
import { MESSAGE } from '@/constants';

const schemaCreatePromoPage = yup.object({
  name: yup.string().required(MESSAGE.REQUIRED),
  period_from: yup.string().required(MESSAGE.REQUIRED),
  period_to: yup.string().required(MESSAGE.REQUIRED),
  short_desc: yup.string().required(MESSAGE.REQUIRED),
  url_check_now: yup.string().required(MESSAGE.REQUIRED),
  promo_category_id: yup.string().required(MESSAGE.REQUIRED),
  show_from: yup.string().required(MESSAGE.REQUIRED),
  show_to: yup.string().required(MESSAGE.REQUIRED),
  mobile: yup.string().required(MESSAGE.REQUIRED),
  desktop: yup.string().required(MESSAGE.REQUIRED),
  thumbnail: yup.string().required(MESSAGE.REQUIRED),
  maxMobileSize: yup.number().max(150000, MESSAGE.IMAGE_BIGGER_SIZE),
  maxDesktopSize: yup.number().max(150000, MESSAGE.IMAGE_BIGGER_SIZE),
  maxThumbnailSize: yup.number().max(150000, MESSAGE.IMAGE_BIGGER_SIZE)
});

export { schemaCreatePromoPage };
