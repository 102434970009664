import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { accountTypeOptions } from '@/utils';
import { User } from '@supabase/supabase-js';
import { create } from 'zustand';

declare interface AuthStore {
  user: User | null;
  isLoading: boolean;
  setUser: (callback: (message: string) => void) => void;
  login: (email: string, password: string, callback: (message: string)=>void) => void;
  logout: (callback: () => void) => void;
}

export const useAuthStore = create<AuthStore>((set) => ({
  user: null,
  isLoading: true,
  setUser: async (callback) => {
    set({ isLoading: true });

    const { data, error } = await supabase.auth.getUser();

    // TODO - show Toast to handle error
    if (error) {
      callback(error.message);
      set({ user: null, isLoading: false });
      return;
    }

    set({ user: data.user, isLoading: false });
  },
  login: async (email, password, callback) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password
    });

    // TODO - show Toast to handle error
    if (error) {
      if (error.status != undefined && error.status >= 500) {
        callback('Terdapat kesalahan pada server');
        return;
      }
      callback(error.message);
      return;
    }

    localStorage.setItem('previous_access_token', data.session?.access_token ?? '');

    // check when user is super admin bos
    if (accountTypeOptions.find((it) => data.user?.user_metadata?.type === it.code) || data.user?.user_metadata?.application_id == '1') {
      const { data: dataTeam, error: errorTeam } = await supabase
        .from(TABLE.TEAM)
        .select('id, name, status, code')
        .eq('id', data.user?.user_metadata?.team_id)
        .limit(1)
        .single();

      if (errorTeam) {
        await supabase.auth.signOut();
        localStorage.clear();
        callback('Team anda tidak ditemukan');
        return;
      }

      if (!dataTeam.status) {
        await supabase.auth.signOut();
        localStorage.clear();
        callback(`Team anda ${dataTeam.name} sudah tidak aktif, maka anda tidak dapat masuk ke sistem`);
        return;
      }

      if (dataTeam.code === 'super_admin') {
        const { data: response, error: errorResponse } = await supabase.functions.invoke('admin-token', {
          body: { userId: data.user?.id }
        });

        // TODO - show Toast to handle error user not admin
        if (errorResponse) {
          await supabase.auth.signOut();
          localStorage.clear();
          callback('User Group Anda tidak memenuhi syarat code team yang diperbolehkan sistem');
          return;
        }

        const { data: dataAdmin, error: errorAdmin } = await supabase.auth.setSession({
          access_token: response?.accessToken,
          refresh_token: data.session?.refresh_token ?? ''
        });

        // TODO - show Toast to handle error
        if (errorAdmin) {
          await supabase.auth.signOut();
          localStorage.clear();
          callback(errorAdmin.message);
          return;
        }

        set({ user: dataAdmin.user, isLoading: false });
      }

      set({ user: data.user, isLoading: false });
    } else {
      set({ user: data.user, isLoading: false });
    }
  },
  logout: async (callback) => {
    // Before logout, revert access_token
    const prevAccessToken = localStorage.getItem('previous_access_token');
    const { data } = await supabase.auth.getSession();
    await supabase.auth.setSession({
      access_token: prevAccessToken ?? '',
      refresh_token: data.session?.refresh_token ?? ''
    });

    await supabase.auth.signOut();

    localStorage.clear();

    set({ user: null, isLoading: false });

    callback();
  }
}));
