import * as yup from 'yup';
import { MESSAGE } from '@/constants';

const schemaCreateHighlight = yup.object({
  street: yup.number().min(1, MESSAGE.REQUIRED),
  section: yup.number().min(1, MESSAGE.REQUIRED),
  tabulation: yup.number().when('section', (section, schema)=> {
    if (section.includes(2)) {
      return schema.min(1, MESSAGE.REQUIRED);
    }
    return schema;
  }),
  fileName: yup.string().required(MESSAGE.REQUIRED)
});

export { schemaCreateHighlight };
