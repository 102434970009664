import { ReactElement } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import IconDot from '@/assets/IconDot';
import { MultiSelect } from 'primereact/multiselect';
import IconSearch from '@/assets/IconSearch';
import useTabGroupMember from './hooks';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { Calendar } from 'primereact/calendar';
import { usePermissionStore } from '@/store/usePermissions';

const statusBodyTemplate = (data): ReactElement => {
  return (
    <div className="flex gap-x-3 items-center">
      {data.status ? (
        <>
          <IconDot fill="#39ac76" />
          <span>Active</span>
        </>
      ) : (
        <>
          <IconDot fill="#ef4444" />
          <span>Inactive</span>
        </>
      )}
    </div>
  );
};

const TabGroupInformation = (): ReactElement => {
  const {
    data: { dataMember, isLoading, payloadFilter, selectedFilter, search, header, options },
    mathod: { handleChangeCalendar, handleChangeInputSearch, handleClickDetailMember, handleChangeFilter }
  } = useTabGroupMember();
  const { canView } = usePermissionStore();
  return (
    <>
      <div className="flex justify-between">
        <InputText
          id="user-input-filter-search"
          value={search}
          onChange={handleChangeInputSearch}
          className="w-[300px]"
          placeholder="Search"
          type="search"
        />
        <LoadingOverlay show={isLoading} />
        <div className="flex gap-x-2">
          <div className="flex gap-x-2">
            <MultiSelect
              id="user-input-filter-dropdown"
              value={selectedFilter}
              onChange={handleChangeFilter}
              options={options}
              optionLabel="label"
              optionGroupLabel="label"
              optionGroupChildren="items"
              placeholder="Filter"
              className="w-full md:w-20rem"
              maxSelectedLabels={2}
            />
          </div>
          <Calendar
            value={payloadFilter.from}
            onChange={handleChangeCalendar}
            placeholder="Please select date"
            clearButtonClassName="pi-close"
            showButtonBar
            hideOnDateTimeSelect
          />
        </div>
      </div>
      <div className="mt-7">
        <DataTable
          value={dataMember as []}
          paginator
          rows={10}
          scrollable
          scrollHeight="36rem"
          rowsPerPageOptions={[10, 20, 50]}
          dataKey="id"
          tableStyle={{ minWidth: '12rem' }}
          globalFilter={search.length>0?search:null}
        >
          {header.map((item, index) =>
            item.field !== 'status' ? (
              <Column key={index} field={item.field} header={item.header} sortable />
            ) : (
              <Column key={index} header="Status" sortable body={statusBodyTemplate} />
            )
          )}
          <Column
            header="Action"
            style={{ width: 'rem' }}
            body={(rowData) => (
              <Button
                id="user-button-action"
                icon={IconSearch}
                outlined
                rounded
                severity="secondary"
                onClick={() => handleClickDetailMember(rowData.id)}
                disabled={!canView}
              />
            )}
          />
        </DataTable>
      </div>

    </>
  );
};

export default TabGroupInformation;
