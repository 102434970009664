import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFound from '@/views/Errors/NotFound';
import ProtectedLayout from '@/components/shared/ProtectedLayout';
import Login from '@/views/Login/Login';
import Home from '@/views/Home/Home';
import Banner from '@/views/BannerManagement';
import Specification from '@/views/Category/Specification/Specification';
import AttributeSet from '@/views/Category/AttributeSet/AttributeSet';
import Categories from '@/views/Category/Category';
import DetailCategory from './views/Category/DetailCategory';
import AddCategory from '@/views/CategoryManagement/AddCategory';
import EditCategory from '@/views/Category/EditCategory/EditCategory';
import CreateCategories from '@/views/Category/CreateCategory/CreateCategroy';
import CreateAtributeSet from '@/views/Category/AttributeSet/CreateAttributeSet/CreateAttributeSet';
import CreateVariant from '@/views/CategoryManagement/Variant/CreateVariant/CreateVariant';
import PageBuilder from '@/views/PageBuilder/PageBuilder';
import CreatePageBuilder from '@/views/PageBuilder/Create';
import EditPageBuilder from '@/views/PageBuilder/Edit';
import SpecificationCategory from '@/views/SpecificationCategory/spesification';
import CreateSpecification from '@/views/SpecificationCategory/CreateSpecification/Create';
import ImportHistory from '@/views/ImportHistory/ImportHistory';
import ProductList from '@/views/Product';
import DetailProduct from '@/views/Product/detail';
import ManageFlashsale from './views/FlashSaleManagement/ManageFlashsale';
import ProductHighlight from '@/views/ProductHighlight/ProductHighlight';
import UploadProduct from '@/views/ProductHighlight/UploadProduct/UploadProduct';
// import CustomerSupport from '@/views/CustomerSupport';
import PromoTagging from './views/PromoTagging/index';
import PromoTaggingCreate from '@/views/PromoTagging/Create/PromoTaggingCreate';
import PromoTaggingEdit from '@/views/PromoTagging/Edit/PromoTaggingEdit';
import CreateFlashSale from './views/FlashSaleManagement/Create/CreateFlashSale';
import EditFlashSale from './views/FlashSaleManagement/Edit/EditFlashSale';
import ListVariant from '@/views/CategoryManagement/Variant/ListVariant/ListVariant';
import MasterSlots from './views/MasterSlots';
import CreateSlots from './views/MasterSlots/CreateSlots/CreateSlots';
import EditSlots from './views/MasterSlots/EditSlots/EditSlots';
import DetailSlots from './views/MasterSlots/Show';
import UserManagement from './views/UserManagement/UserGroup/UserGroup';
import CustomerManagement from '@/views/CustomerManagement/CustomerManagement';
import OrderManagement from './views/OrderManagement/OrderManagement';
import DetailOrderManagement from './views/OrderManagement/Detail';
import Promo from './views/Ongkir';
import PromoOngkir from './views/Ongkir/create';
import AuditTrail from './views/AuditTrail/AuditTrail';
import CreateBanner from './views/BannerManagement/CreateEditBanner/CreateEditBanner';
import CreateVoucher from './views/Promo/Voucher/Create/CreateVoucher';
import ListVoucher from './views/Promo/Voucher/ListVoucher';
import EditVoucher from './views/Promo/Voucher/Edit/EditVoucher';
import DetailUserManagement from './views/UserManagement/UserGroup/Detail/DetailUserManagement';
import UserRoles from './views/UserManagement/Roles/UserRoles';
import DetailCustomer from './views/CustomerManagement/DetailCustomer/DetailCustomer';
import PromoPage from './views/PromoPage';
import CreatePromoPage from './views/PromoPage/create';
// import PromoPageDetail from './views/PromoPage/detail';
import Admin from './views/UserManagement/Admin';
import DetailAdmin from './views/UserManagement/Admin/component/detail';
import DetailOngkir from './views/Ongkir/detail';
import PromoTaggingDetail from './views/PromoTagging/Detail/PromoTaggingDetail';
import EditUserGroupManagement from './views/UserManagement/UserGroup/Edit/EditUserGroupManagement';
import DetailMember from './views/UserManagement/UserGroup/Detail/TabGroupMember/DetailMember/DetailMember';
import Profile from '@/views/Profile/Profile';
import DetailProductHighlight from './views/ProductHighlight/DetailProduct/DetailProductHighlight';
import EditProductHighlight from './views/ProductHighlight/EditProduct/EditProductHighlight';
import ApiManagement from './views/Api/management';

function App() {
  return (
    <Routes>
      <Route path="/" element={<ProtectedLayout />}>
        <Route index element={<Home />} />
        {/* Route yang sebelumnya ada namun belum terpakai */}
        <Route path="/category-management/add" element={<AddCategory />} />
        <Route path="/category-management/specification" element={<Specification />} />
        <Route path="/import-history/list" element={<ImportHistory />} />
        {/* <Route path="/customer-support" element={<CustomerSupport />} /> */}
        {/* Route yang masih terpakai */}
        <Route path="/variant" element={<ListVariant />} />
        <Route path="/variant/:detailId" element={<CreateVariant />} />
        <Route path="/variant/create" element={<CreateVariant />} />
        <Route path="/variant/edit/:id" element={<CreateVariant />} />
        <Route path="/attribute-set" element={<AttributeSet />} />
        <Route path="/attribute-set/create" element={<CreateAtributeSet />} />
        <Route path="/attribute-set/edit/:id" element={<CreateAtributeSet />} />
        <Route path="/attribute-set/:detailId" element={<CreateAtributeSet />} />
        <Route path="/customer-management" element={<CustomerManagement />} />
        <Route path="/customer-management/:id" element={<DetailCustomer />} />
        <Route path="/specification" element={<SpecificationCategory />} />
        <Route path="/specification/:detailId" element={<CreateSpecification />} />
        <Route path="/specification/create" element={<CreateSpecification />} />
        <Route path="/specification/edit/:id" element={<CreateSpecification />} />
        <Route path="/category" element={<Categories />} />
        <Route path="/category/:detailId" element={<DetailCategory />} />
        <Route path="/category/edit/:id" element={<EditCategory />} />
        <Route path="/category/create" element={<CreateCategories />} />
        <Route path="/frontstore-management/page-builder" element={<PageBuilder />} />
        <Route path="/frontstore-management/page-builder/create" element={<CreatePageBuilder />} />
        <Route path="/frontstore-management/page-builder/edit/:id" element={<EditPageBuilder />} />
        <Route path="/frontstore-management/banner" element={<Banner />} />
        <Route path="/frontstore-management/banner/create" element={<CreateBanner />} />
        <Route path="/frontstore-management/banner/edit/:editId" element={<CreateBanner />} />
        <Route path="/frontstore-management/banner/:id" element={<CreateBanner />} />
        <Route path="/product" element={<ProductList />} />
        <Route path="/product/:id" element={<DetailProduct />} />
        <Route path="/flash-sale" element={<ManageFlashsale />}/>
        <Route path="/flash-sale/add" element={<CreateFlashSale />}/>
        <Route path="/flash-sale/edit/:id" element={<EditFlashSale />}/>
        <Route path="/flash-sale/:detailId" element={<EditFlashSale />}/>
        <Route path="/master-slot" element={<MasterSlots />} />
        <Route path="/master-slot/:id" element={<DetailSlots />} />
        <Route path="/master-slot/create" element={<CreateSlots />} />
        <Route path="/master-slot/edit/:id" element={<EditSlots />} />
        <Route path="/promo-tagging" element={<PromoTagging />} />
        <Route path="/promo-tagging/:id" element={<PromoTaggingDetail />} />
        <Route path="/promo-tagging/create" element={<PromoTaggingCreate />} />
        <Route path="/promo-tagging/edit/:id" element={<PromoTaggingEdit />} />
        <Route path="/user-management" element={<UserManagement />} />
        <Route path="/user-management/:id" element={<DetailUserManagement />} />
        <Route path="/user-management/:id/edit-information" element={<EditUserGroupManagement />} />
        <Route path="/user-management/:id/member-id/:memberId" element={<DetailMember />} />
        <Route path="/order-management" element={<OrderManagement />} />
        <Route path="/order-management/:id" element={<DetailOrderManagement />} />
        <Route path="/ongkir" element={<Promo />} />
        <Route path="/ongkir/:id" element={<DetailOngkir />} />
        <Route path="/ongkir/edit/:id" element={<PromoOngkir />} />
        <Route path="/ongkir/create" element={<PromoOngkir />} />
        <Route path="/audit-trail" element={<AuditTrail />} />
        <Route path="/voucher" element={<ListVoucher />} />
        <Route path="/voucher/create" element={<CreateVoucher />} />
        <Route path="/voucher/edit/:id" element={<EditVoucher />} />
        <Route path="/voucher/:detailId" element={<EditVoucher />} />
        <Route path="/bos-acc-type" element={<UserRoles />} />
        <Route path="/promo-page" element={<PromoPage />} />
        <Route path="/promo-page/create" element={<CreatePromoPage />} />
        <Route path="/promo-page/:detailId" element={<CreatePromoPage />} />
        <Route path="/promo-page/edit/:id" element={<CreatePromoPage />} />
        <Route path="/user-admin" element={<Admin />} />
        <Route path="/user-admin/:id" element={<DetailAdmin />} />
        <Route path="/product-highlight" element={<ProductHighlight />} />
        <Route path="/product-highlight/create" element={<EditProductHighlight />} />
        <Route path="/product-highlight/create/bulk" element={<UploadProduct />} />
        <Route path="/product-highlight/:id" element={<DetailProductHighlight />} />
        <Route path="/product-highlight/edit/:id" element={<EditProductHighlight />} />
        <Route path="/product-highlight/edit/bulk" element={<UploadProduct />} />
        <Route path="/profile" element={<Profile />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/api/management" element={<ApiManagement />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
