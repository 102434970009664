import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCustom } from './hooks';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { TabPanel, TabView } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { ConfirmDialog } from 'primereact/confirmdialog';
import PopUpChangeAll from '../Components/PopUpChangeAll';
import ChangeSucces from '../Components/ChangeSucces';
import { DataTable } from 'primereact/datatable';
import Spinner from '@/components/base/Spinner';
import { Column } from 'primereact/column';
import IconCancel from '@/assets/IconCancel';
import IconUpload from '@/assets/IconUpload';
import LoadingOverlay from '@/components/base/LoadingOverlay';

const EditCategory = () => {
  const { data, methods } = useCustom();
  const navigate = useNavigate();
  const header = [
    { field: 'id', header: 'ID' },
    { field: 'name', header: 'Product Name' },
    { field: 'sku', header: 'SKU' },
    { field: 'stok', header: 'Stock' },
    { field: 'status', header: 'Status' },
    { field: 'main_price', header: 'Price' },
    { field: 'specialPrice', header: 'Special Price' }
  ];
  const PaginationTemplate = {
    layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
        { label: 'All', value: options.totalRecords }
      ];

      const autoDisabled: boolean = options.totalRecords === 0;

      return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} disabled={autoDisabled} />;
    }
  };

  const uploadOptions = {
    icon: IconUpload,
    iconOnly: true,
    className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'
  };
  const cancelOptions = {
    icon: IconCancel,
    iconOnly: true,
    className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'
  };

  const dataCountProduct = JSON.stringify(data.modalFilter) !== JSON.stringify(data.initialFilter) ? data?.coutAllData : data.dataEditCategory.count;

  return (
    <BaseContainer>
      <ConfirmDialog
        visible={data.visible}
        onHide={() => methods.setVisible(false)}
        message="Are you sure? specifications and variants will be reset"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={methods.handleAcceptDialog}
        reject={() => methods.setVisible(false)}
      />
      <Toast ref={data.toast} />
      <LoadingOverlay show={data.isLoading} />
      <TabView activeIndex={Number(data.params.get('index'))} onTabChange={(e) => methods.onTabChange(e)} >
        <TabPanel header="General">
          <MainTitle title="Edit Category" nav="" />
          <div id="edit_category">
            <div className="mb-3 flex gap-8">
              <label htmlFor="parent" className="w-1/6 mt-2">
              Parent Category*
              </label>
              <div className="w-1/2">
                <Dropdown
                  value={data.selectedDataParentID}
                  options={data.dataParentID}
                  onChange={(e) => methods.setSelectedDataParentID(e.value)}
                  optionLabel="name"
                  filter filterBy="name"
                  placeholder="Change Parent"
                  className="w-full"
                  disabled={data.checked}
                />
                <div className="flex items-center gap-2 py-2">
                  <Checkbox
                    checked={data.checked}
                    onChange={() => methods.setChecked(!data.checked)}
                  />
                  <label className="cursor-pointer" htmlFor="not-have-parent">
                    No Parent
                  </label>
                </div>
              </div>
            </div>
            <div id="edit_name" className="mb-3 flex gap-8 items-center">
              <label htmlFor="name" className="w-1/6">
                Category Name*
              </label>
              <InputText
                id="edit_name"
                className="w-1/2"
                placeholder="Label Specification"
                value={data.dataEditCategory.name}
                onChange={(e) => methods.onChangeValue('name', e.target.value)}
              />
            </div>
            <div id="status_input" className="mb-3 flex gap-8 items-center">
              <label htmlFor="isActive" className="w-1/6">
                Status
              </label>

              {/* is active */}
              <InputSwitch
                id="edit-status"
                className="w-1/2"
                checked={data.dataEditCategory.status}
                onChange={(e) => {
                  methods.onChangeValue('status', !data.dataEditCategory.status as boolean);
                }}
              />
            </div>
            <div id="edit_description" className="mb-3 flex gap-8 items-center">
              <label htmlFor="description" className="w-1/6">
                Category Description
              </label>
              <InputTextarea
                autoResize
                className="w-1/2"
                id="description"
                placeholder="Description"
                value={data.dataEditCategory.description}
                onChange={(e) => methods.onChangeValue('description', e.target.value)}
              />
            </div>
            <div id="detail_image" className="mb-3 flex gap-8 items-center">
              <label htmlFor="media_url" className="w-1/6">
                Category Icon
              </label>
              <div className="ml-11 w-full">
                <div className="flex">
                  {data.dataEditCategory?.images?.data?.image && (
                    <div>
                      <img src={data.dataEditCategory.images?.data?.image} alt="" width={200} />
                    </div>
                  )}
                  <FileUpload
                    ref={data.fileUploadRef}
                    id="media_url"
                    chooseLabel="Change Image"
                    className="w-1/2"
                    accept="image/*"
                    uploadOptions={uploadOptions}
                    cancelOptions={cancelOptions}
                    emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>}
                    onSelect={(e) => {
                      methods.handleFileUpload(e);
                      data.formik.setFieldValue('maxImageCategory', e.files[0].size);
                    }}
                  />
                </div>
                {/* validation size image */}
                {data.formik.errors['maxImageCategory'] && (
                  <small className="p-error mt-2">{data.formik.errors['maxImageCategory']}</small>
                )}
                <p className="mt-5">Recommended icon images no larger than 50KB, 1:1 ratio, file formats: JPG, GIF, SVG, PNG</p>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end py-2 px-6 ">
            {data.seoPage ? <MinusIcon onClick={() => methods.setSeoPage(!data.seoPage)} className="w-10 cursor-pointer" /> : <PlusIcon onClick={() => methods.setSeoPage(!data.seoPage)} className="w-10 cursor-pointer" />}
          </div>
          {data.seoPage && (
            <div className="mt-2 flex flex-col gap-y-6">
              <h3 className="font-bold">Search Engine Optimization</h3>
              <hr />
              <div className="flex gap-x-4">
                <div className="grid grid-cols-2 w-2/3 gap-y-5">
                  <div>
                    <label className="font-bold" htmlFor="">URL Key*</label>
                  </div>
                  <InputText disabled={data.isFollowCategoryName} value={data.isFollowCategoryName ? data.dataEditCategory.name.toLowerCase().replace(/\s/g, '-') : data.dataEditCategory.slug} onChange={(e)=> methods.onChangeValue('slug', e.target.value)}/>
                  <div>
                    <label className="font-bold" htmlFor="">Meta Title*</label>
                  </div>
                  <InputText value={data.dataEditCategory.metaTitle}
                    onChange={(e) => methods.onChangeValue('metaTitle', e.target.value)} name="meta-title" />
                  <div>
                    <label className="font-bold" htmlFor="">Meta Keywords</label>
                  </div>
                  <InputTextarea value={data.dataEditCategory.metaKeyword || undefined}
                    onChange={(e) => methods.onChangeValue('metaKeyword', e.target.value)} />
                  <div>
                    <label className="font-bold" htmlFor="">Meta Description</label>
                  </div>
                  <InputTextarea value={data.dataEditCategory.metaDescription || undefined}
                    onChange={(e) => methods.onChangeValue('metaDescription', e.target.value)} />
                </div>
                <div>
                  <Checkbox checked={data.isFollowCategoryName}
                    onChange={() => methods.setIsFollowCategoryName(!data.isFollowCategoryName)} /> <span>Follow Category Name</span>
                </div>
              </div>
            </div>
          )}
        </TabPanel>
        {/* Tab Product */}
        <TabPanel header={`Product(${data.isLoadingProduct ? 0 : dataCountProduct})`}>
          <div>
            <MainTitle title="Product" nav="" />
            {data.isLoadingProduct ? <Spinner /> : (
              <div>
                <div id="search" className=" mb-4 flex justify-end w-full">
                  <div className="flex gap-3">
                    <Button label="Bulk Change"
                      onClick={() => methods.setPopUpChangeAll(true)} />
                    <Button label="Filter"
                      onClick={() => methods.setFilterModal(true)} />
                  </div>
                </div>
                {methods.checkFilter() && (
                  <div id="detailFilter" className="border-y-2 border-black my-4 py-2">
                    <div className="flex justify-between">
                      <div className="flex items-center">
                        <p className="font-semibold">Filter Aktif: &nbsp;</p>
                        {data.modalFilter.productName &&(
                          <>
                            <span className="font-semibold">Name: {data.modalFilter.productName}</span>
                            <Button icon="pi pi-times" text severity="danger" onClick={() => methods.onDeleteFilter('productName')} />
                          </>
                        )}
                        {data.modalFilter.idFrom && data.modalFilter.idTo &&(
                          <>
                            <span className="font-semibold">ID: {data.modalFilter.idFrom} - {data.modalFilter.idTo}</span>
                            <Button icon="pi pi-times" text severity="danger" onClick={() => {
                              methods.onDeleteFilter('idFrom');
                              methods.onDeleteFilter('idTo');
                            }} />
                          </>
                        )}
                        {data.modalFilter.priceFrom && data.modalFilter.priceTo &&(
                          <>
                            <span className="font-semibold">Price: {data.modalFilter.priceFrom} - {data.modalFilter.priceTo}</span>
                            <Button icon="pi pi-times" text severity="danger" onClick={() => {
                              methods.onDeleteFilter('priceFrom');
                              methods.onDeleteFilter('priceTo');
                            }} />
                          </>
                        )}
                        {data.modalFilter.specialPriceFrom && data.modalFilter.specialPriceTo &&(
                          <>
                            <span className="font-semibold">Special Price: {data.modalFilter.specialPriceFrom} - {data.modalFilter.specialPriceTo}</span>
                            <Button icon="pi pi-times" text severity="danger" onClick={() => {
                              methods.onDeleteFilter('specialPriceFrom');
                              methods.onDeleteFilter('specialPriceTo');
                            }} />
                          </>
                        )}
                        {data.modalFilter.sku &&(
                          <>
                            <span className="font-semibold">SKU: {data.modalFilter.sku}</span>
                            <Button icon="pi pi-times" text severity="danger" onClick={() => methods.onDeleteFilter('sku')} />
                          </>
                        )}
                        {data.modalFilter.status !== null && (data.modalFilter.statusProduct || !data.modalFilter.statusProduct) &&(
                          <>
                            <span className="font-semibold">Status: {data.modalFilter.status.toString().toUpperCase()}</span>
                            <Button icon="pi pi-times" text severity="danger" onClick={() => methods.onDeleteFilter('status')} />
                          </>
                        )}
                        {data.modalFilter.statusProduct && (
                          <>
                            <span className="font-semibold">Status Product: {data.modalFilter.statusProduct}</span>
                            <Button icon="pi pi-times" text severity="danger" onClick={() => methods.onDeleteFilter('statusProduct')} />
                          </>
                        )}
                      </div>
                      <div>
                        <Button icon="pi pi-times" text severity="danger" onClick={() => methods.onDeleteAllFilter()} ><p className="text-black ml-2">Clear Filter</p></Button>
                      </div>
                    </div>
                  </div>
                )}
                <div className="w-1/4 flex justify-start mb-6">
                  <InputText
                    className="w-full"
                    placeholder="Search"
                    type="search"
                    onChange={(e) => methods.setFilters(e.target.value)}
                  />
                </div>
                <DataTable
                  removableSort
                  value={data.dataAllProduct}
                  paginator
                  rows={10}
                  paginatorTemplate={PaginationTemplate}
                  globalFilter={data.filters}
                >
                  {header.map((item, index) => {
                    return <Column sortable key={index} field={item.field} header={item.header} />;
                  })}
                  <Column
                    header="Action"
                    body={(rowData) => (
                      <div>
                        <Dropdown
                          placeholder="Detail"
                          onChange={(e) => methods.onActionProduct(rowData, e.target.value)}
                          options={[
                            { label: 'Detail', value: 'onClickDetail' },
                            { label: 'Change Category', value: 'onClickChangeCategory' }
                          ]}
                          optionLabel="label"
                        />
                      </div>
                    )}
                  />
                </DataTable>
                {data.popUpDetail && (
                  <div className="bg-black bg-opacity-30 w-full h-screen fixed top-0 left-0 z-50 flex items-center justify-center">
                    <div className="bg-white p-28 relative">
                      <p>Detail Product</p>
                      <button type="button" className="absolute right-3 top-3" onClick={() => methods.setPopUpDetail(false)}>
                        close
                      </button>
                    </div>
                  </div>
                )}
                {/* pop up change category */}
                {data.popUpChangeCategory && (
                  <div className="bg-black bg-opacity-30 w-full h-screen fixed top-0 left-0 z-50 flex items-center justify-center">
                    <div className="bg-white p-8 relative w-3/5 rounded-lg z-51">
                      <h4 className="mb-10 font-semibold">Change Category</h4>
                      <div className="grid grid-cols-2 gap-10">
                        <p>
                          {data.dataChangeCategory?.name} - <span>{data.dataChangeCategory?.sku}</span>
                        </p>
                        <p>{data.merchantName}</p>
                        <h4 className="font-semibold">Current Category</h4>
                        <p>{data.currentCategory?.name}</p>
                        <h4 className="font-semibold">New Category</h4>
                        <div>
                          <Dropdown
                            value={data.dataChangeCategory.category_id}
                            options={data.dataParentID}
                            onChange={(e) => {
                              methods.onChangeParent(e.value);
                            }}
                            filter
                            onFilter={(e) => methods.findCategory(e.filter)}
                            optionLabel="name"
                            optionValue="id"
                            placeholder="Change New Category"
                            className="w-full"
                          />
                        </div>
                      </div>
                      <div className="my-5">
                        <Checkbox checked={false} inputId="notificate" />
                        <label htmlFor="notificate">Notify Merchant for this Category Change</label>
                      </div>
                      <div>
                        <SubmitButton
                          onCancel={() => methods.setPopUpChangeCategory(false)}
                          onSubmit={() => {
                            methods.onSubmitChangeCategoryProduct();
                          }}
                          labelBack="Cancel"
                          labelSubmit="Submit"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {data.popUpChangeSucces && (
                  <div className="bg-black bg-opacity-30 w-full h-screen fixed top-0 left-0 z-50 flex items-center justify-center">
                    <ChangeSucces message={data.message} onCloseButton={() => methods.setPopUpChangeSucces(false)} />
                  </div>
                )}
                {
                  data.popUpChangeFailed && (
                    <div className="bg-black bg-opacity-30 w-full h-screen fixed top-0 left-0 z-50 flex items-center justify-center">
                      <ChangeSucces message={`Oops, The product was not successfully moved.
                Please try again, if the problem persists,
                please contact your administrator.`} onCloseButton={() => methods.setPopUpChangeFailed(false)} />
                    </div>
                  )
                }
                {
                  data.filterModal && (
                    <div className="bg-black bg-opacity-30 w-screen h-screen fixed top-0 left-0 z-50 flex justify-center items-center">
                      <div className="flex flex-col gap-y-3 bg-white w-4/5 relative p-10 z-50">
                        <h1 className="font-semibold">All Filter</h1>
                        <h1 className="font-semibold" >ID</h1>
                        <div className="flex items-center gap-4">
                          <label htmlFor="">From</label>
                          <InputText type="number" value={String(data.modalFilter.idFrom) || ''} name="idFrom" onChange={(e) => methods.onChangeFilterValue(e)} />
                          <label htmlFor="">To</label>
                          <InputText type="number" value={String(data.modalFilter.idTo) || ''} name="idTo" onChange={(e) => methods.onChangeFilterValue(e)} />
                        </div>
                        <h1 className="font-semibold">Price</h1>
                        <div className="flex items-center gap-4">
                          <label htmlFor="">From</label>
                          <InputText type="number" value={String(data.modalFilter.priceFrom) || ''} name="priceFrom" onChange={(e) => methods.onChangeFilterValue(e)} />
                          <label htmlFor="">To</label>
                          <InputText type="number" value={String(data.modalFilter.priceTo) || ''} name="priceTo" onChange={(e) => methods.onChangeFilterValue(e)} />
                        </div>
                        <h1 className="font-semibold">Special Price</h1>
                        <div className="flex items-center gap-4">
                          <label htmlFor="">From</label>
                          <InputText type="number" value={String(data.modalFilter.specialPriceFrom) || ''} name="specialPriceFrom" onChange={(e) => methods.onChangeFilterValue(e)} />
                          <label htmlFor="">To</label>
                          <InputText type="number" value={String(data.modalFilter.specialPriceTo) || ''} name="specialPriceTo" onChange={(e) => methods.onChangeFilterValue(e)} />
                        </div>
                        <div className="grid grid-cols-3">
                          <div>
                            <p className="font-semibold">Product Name</p>
                            <InputText value={data.modalFilter.productName} name="productName" onChange={(e) => methods.onChangeFilterValue(e)} />
                          </div>
                          <div>
                            <p className="font-semibold">SKU</p>
                            <InputText value={data.modalFilter.sku} name="sku" onChange={(e) => methods.onChangeFilterValue(e)} />
                          </div>
                        </div>
                        <div className="grid grid-cols-3">
                          <div>
                            <p className="font-semibold">Status Product</p>
                            <Dropdown
                              value={data.dataStatusProduct}
                              name="status"
                              options={[
                                { label: 'APPROVED' },
                                { label: 'IN_REVIEW' },
                                { label: 'NEED_REVISION' },
                                { label: 'IN_REVIEW_UPDATE' },
                                { label: 'NEED_REVISION_UPDATE' }
                              ]} optionLabel="label" onChange={(e) => methods.onChangeStatusProductFilter(e)} />
                          </div>
                          <div>
                            <p className="font-semibold">Status</p>
                            <Dropdown
                              value={data.dataStatus}
                              name="status"
                              options={[
                                { label: 'ACTIVE', code: true },
                                { label: 'IN_ACTIVE', code: false }
                              ]} optionLabel="label" onChange={(e) => methods.onChangeStatusFilter(e)} />
                          </div>
                        </div>
                        <SubmitButton
                          onCancel={() => {
                            methods.setFilterModal(false);
                          }}
                          onSubmit={() => {
                            methods.onSubmitFilterValue();
                          }}
                          labelBack="Batal"
                          labelSubmit="Terapkan"
                        />
                      </div>
                    </div >
                  )
                }
                {
                  data.popUpChangeAll && (
                    <PopUpChangeAll disableOption={!data.selectedChangeAllParent} onChangeParent={methods.onChangeAllParent} options={data.dataParentID || []} value={data.selectedChangeAllParent ? data.selectedChangeAllParent : null} onClosePopUpFilter={() => methods.setPopUpChangeAll(false)} />
                  )
                }
              </div>
            )}
          </div>
        </TabPanel>

        <TabPanel header="Attribute Set">
          <div>
            <div id="container-attribute-set" className="flex gap-2 mb-4 mt-4 justify-center items-center">
              <span className="w-[220px]">Add Attribute Set From: </span>
              <Dropdown
                id="dropdown-terapkan"
                optionLabel="name" filter emptyFilterMessage="Atribute Set Not Found"
                placeholder="Select Attribute Set"
                className="w-3/6"
                options={data.attributeSets}
                onChange={(e: DropdownChangeEvent) => methods.handleSelectedAttributeSets(e)}
                value={data.selectedAttributeSets}
              />
              <div className="flex justify-center items-center w-1/6">
                <Button
                  onClick={methods.handleClickApply}
                  id="button-terapkan"
                  className="w-full"
                  label="Apply"
                  style={{ textAlign: 'center' }}
                />
              </div>
            </div>
            <hr />
            <div >
              <div id="container-specification" className="mb-4 mt-4">
                <span>1. Specification</span>
                <div className="flex flex-col gap-y-2 ml-2 justify-start">
                  <span>Specifications from the Parent Category will be included in this category and cannot be changed</span>
                  {Array.isArray(data.parentCategorySpec) && (data.parentCategorySpec.map((item, index) => {
                    return (
                      <div className="flex gap-5" key={index}>
                        <Dropdown
                          id={`dropdown-parent-spec-${index}`}
                          optionLabel="name" filter emptyFilterMessage="Specification Not Found"
                          optionValue="id" placeholder="Select specification"
                          className="w-1/4" disabled
                          value={item?.id}
                          options={data.parentCategorySpec}
                        />
                      </div>
                    );
                  }))}
                  {Array.isArray(data.rangeSpecification) && (data.rangeSpecification.map((item, index) => {
                    return (
                      <div className="flex gap-5" key={index}>
                        <Dropdown
                          id={`dropdown-specification-${index}`}
                          optionLabel="name" filter emptyFilterMessage="Specification Not Found"
                          optionValue="id" placeholder="Select specification" filterBy="name"
                          optionDisabled={(op) => data.categorySpecIds.includes(op.id)}
                          className="w-1/4" options={data.foundSpec.length > 0 ? data.foundSpec : data.specifications}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>)=> methods.findSpecificaiton(e.target.value)}
                          onChange={(e: DropdownChangeEvent) => methods.selectSpecification(index, e.value)}
                          value={item?.id}
                        />
                        <Button icon="pi pi-trash" id={`button-hapus-specification-${index}`}
                          onClick={() => methods.deleteSpecification(index)} />
                      </div>
                    );
                  }))}
                  <div className="flex justify-start">
                    <Button id="button-tambah-specification"
                      onClick={methods.addSpecification} label="+ Add Specification" link />
                  </div>
                </div>
              </div>
              <hr />
              <div id="container-varian" className="mb-4 mt-4">
                <span>2. Varian</span>
                <div className="flex flex-col gap-y-2 ml-2 justify-start">
                  <span>Variants from the Parent Category will be included in this category and cannot be changed</span>
                  {Array.isArray(data.parentCategoryVars) && (data.parentCategoryVars.map((item, index) => {
                    return (
                      <div className="flex gap-5" key={index}>
                        <Dropdown
                          id={`dropdown-variant-${index}`}
                          optionLabel="name" filter emptyFilterMessage="Varian Not Found"
                          optionValue="id" placeholder="Select varian"
                          className="w-1/4" disabled
                          options={data.parentCategoryVars}
                          value={item?.id}
                        />
                      </div>
                    );
                  }))}
                  {Array.isArray(data.rangeVariant) && (data.rangeVariant.map((item, index) => {
                    return (
                      <div className="flex gap-5" key={index}>
                        <Dropdown
                          id={`dropdown-variant-${index}`}
                          optionLabel="name" filter emptyFilterMessage="Varian Not Found"
                          optionValue="id" placeholder="Select varian" filterBy="name"
                          optionDisabled={(op) => data.categoryVariantIds.includes(op.id)}
                          className="w-1/4"
                          options={data.foundVariant.length > 0 ? data.foundVariant : data.variants}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>)=> methods.findVariant(e.target.value)}
                          onChange={(e: DropdownChangeEvent) => methods.selectVariant(index, e.value)}
                          value={item?.id}
                        />
                        <Button icon="pi pi-trash" id={`button-hapus-variant-${index}`}
                          onClick={() => methods.deleteVariant(index)} />
                      </div>
                    );
                  }))}
                  <div className="flex justify-start">
                    <Button id="button-tambah-variant"
                      onClick={methods.addVariant} label="+ Add Varian" link />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
      <div id="buttons-action" className="w-full flex justify-end gap-6">
        <SubmitButton
          onCancel={() => {
            navigate('/category');
          }}
          onSubmit={()=> data.formik.handleSubmit()}
          disabledSubmit={data.isLoading}
          labelBack="Cancel"
          labelSubmit="Submit"
        />
      </div>
    </BaseContainer>
  );
};

export default EditCategory;
