import { useCustom } from './hooks';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import Table from '@/components/base/DataTable/DataTable';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { IListFlashSale } from './type';
import dayjs from 'dayjs';
import { usePermissionStore } from '@/store/usePermissions';
import { IPagination } from '@/components/base/Pagination';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';

const ManageFlashsale = () => {
  const {
    data: {
      flashsale,
      isLoading,
      toast,
      dataMemoFlashSale,
      totalPages,
      totalRecords,
      paginator,
      perPage,
      itemFilters,
      filterHistory,
      search
    },
    methods: {
      onDeleteFlashSale,
      handleFilter,
      setFlashsale,
      handleChangeDropdownPage,
      handleClickNext,
      handleClickPrev,
      handleJumpToPage,
      handleChangeJumpTopage,
      handleClearFilter,
      handleDeleteFilterHistory,
      handleClickSubmitFilter,
      handleSearch
    }
  } = useCustom();

  const { canCreate, canView, canDelete, canEdit } = usePermissionStore();

  const createdDateTemplate = (flashSale: IListFlashSale) => {
    return <div id={`flashSale-created-date-${flashSale.id}`}>{dayjs(flashSale.created_at).format('DD MMM YYYY')}</div>;
  };
  const updatedDateTemplate = (flashSale: IListFlashSale) => {
    return <div id={`flashSale-updated-date-${flashSale.id}`}>{dayjs(flashSale.updated_at).format('DD MMM YYYY')}</div>;
  };

  const headerTableFlashSale = [
    { field: 'id', header: 'Flash Sale ID' },
    { field: 'name', header: 'Flash Sale Name' },
    { field: 'created_by', header: 'Created By' },
    { field: 'updated_by', header: 'Updated By' },
    { field: 'created_at', header: 'Created Date', body: createdDateTemplate },
    { field: 'updated_at', header: 'Updated Date', body: updatedDateTemplate },
    { field: 'status', header: 'Status' }
  ];

  const paginationOptions: IPagination = {
    perPage: perPage,
    totalPages: totalPages,
    currentPage: paginator.currentPage,
    disabledDropdown: totalRecords === 0,
    onChangeDropdownPage: handleChangeDropdownPage,
    onClickPrev: handleClickPrev,
    onClickNext: handleClickNext,
    onJumptoPage: handleJumpToPage,
    onChangeJumptoPage: handleChangeJumpTopage
  };

  const filterContent = (
    <div className="flex flex-col gap-4 w-[900px]">
      <div className="w-full flex flex-col items-start gap-2">
        <h1 className="font-bold">ID</h1>
        <div className="flex w-full gap-8">
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Dari</label>
            <InputNumber
              onChange={(e) => handleFilter(e, 'id_from')}
              value={itemFilters.id_from}
              name="id_from"
            />
          </div>
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Sampai</label>
            <InputNumber
              onChange={(e) => handleFilter(e, 'id_to')}
              value={itemFilters.id_to}
              name="id_to"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Flashsale Name</label>
          <InputText
            onChange={(e) => handleFilter(e, 'name')}
            value={itemFilters.name}
            name="name"
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Created By</label>
          <InputText
            onChange={(e) => handleFilter(e, 'created_by')}
            value={itemFilters.created_by}
            name="created_by"
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Updated By</label>
          <InputText
            onChange={(e) => handleFilter(e, 'updated_by')}
            value={itemFilters.updated_by}
            name="updated_by"
            keyfilter="email"
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-status" className="font-bold">Created Date</label>
          <Calendar
            onChange={(e) => handleFilter(e, 'created_at')}
            value={itemFilters.created_at}
            name="created_at"
            className="w-full"
            selectionMode="range"
            showIcon
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-status" className="font-bold">Updated Date</label>
          <Calendar
            onChange={(e) => handleFilter(e, 'updated_at')}
            value={itemFilters.updated_at}
            name="updated_at"
            className="w-full"
            selectionMode="range"
            showIcon
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Status</label>
          <Dropdown
            options={[{ label: 'Enabled', value: 'Enabled' }, { label: 'Disabled', value: 'Disabled' }]}
            onChange={(e) => handleFilter(e, 'status')}
            value={itemFilters.status}
            placeholder="Select Status"
          />
        </div>
      </div>
    </div>
  );

  return (
    <BaseContainer>
      <MainTitle title="Manage Flash Sale" nav="add" labelButton="Create New Flash Sale" isWithButton={canCreate} />
      <ConfirmDialog />
      <Toast ref={toast} />
      <Table
        isWithCheckBox
        isLoadingTable={isLoading}
        onSelectionChange={
          (e) => {
            const value = e.value as [];
            setFlashsale(value);
          }}
        selection={flashsale as []}
        value={dataMemoFlashSale as []}
        filter={search}
        headerTable={headerTableFlashSale}
        onSearch={handleSearch}
        onDeleted={onDeleteFlashSale}
        isDeleteAction={canDelete}
        isEditAction={canEdit}
        isDetailAction={canView}
        paginationOptions={paginationOptions}
        filterContent={filterContent}
        filterHistory={filterHistory}
        handleClearFilter={handleClearFilter}
        handleDeleteFilterHistory={handleDeleteFilterHistory}
        onSubmitFilter={handleClickSubmitFilter}
        rows={totalRecords}
      />
    </BaseContainer>
  );
};

export default ManageFlashsale;
