import Toggle from '@/components/base/Toggle';
import {
  ArrowDownIcon,
  BlockButton,
  Collapse,
  Dropdown,
  GroupsIllustration,
  MediaFileInput,
  Search,
  TextAreaInput,
  TextInput
} from 'akeneo-design-system';
import React, { useContext } from 'react';
import { CategoryContext } from '../hooks';

const TabGeneral = () => {
  const category = useContext(CategoryContext);

  const {
    data: {
      formCategory,
      collapseSEO,
      noParentCategory,
      itemsParentCategory,
      isOpenParentCategory,
      searchParentCategory,
      isFollowCategoryName,
      isIconInvalid,
      thumbnailIconUrl,
      isFormGeneralSubmitted,
      errorParentCategory
    },
    methods: {
      setIsActive,
      onChangeName,
      setIsCollapseSEO,
      openParentCategory,
      handleNextPageParent,
      setIsNoParentCategory,
      setSearchParentCategory,
      closeParentCategory,
      onChangeDescription,
      followCategoryName,
      onInputMetaForm,
      onChangeParentCategory,
      setIconCategory,
      uploaderImage
    }
  } = category();

  return (
    <div id="tab-general" className="p-8 flex flex-col gap-8">
      <div className="flex gap-8 w-3/5">
        <p className="font-medium w-44" id="lbl-parent-category">
          Parent Kategori*
        </p>
        <div className="flex-1">
          <Dropdown className="parent-category-dropdown w-full" id="parent-category-dropdown">
            <BlockButton
              id="button-dropdown-parent-category"
              className={errorParentCategory}
              icon={<ArrowDownIcon />}
              onClick={openParentCategory}
              disabled={noParentCategory}
            >
              {formCategory.parent.text}
            </BlockButton>
            {isOpenParentCategory && (
              <Dropdown.Overlay
                id="parent-category-modal"
                verticalPosition="down"
                onClose={closeParentCategory}
                fullWidth
                className="w-full"
              >
                <Dropdown.Header>
                  <Search
                    id="parent-category-search"
                    onSearchChange={setSearchParentCategory}
                    placeholder="Search"
                    searchValue={searchParentCategory}
                    title="Search"
                  />
                </Dropdown.Header>
                <Dropdown.ItemCollection
                  onNextPage={handleNextPageParent}
                  noResultIllustration={React.createElement(GroupsIllustration)}
                  noResultTitle="Parent Kategori tidak ditemukan"
                >
                  {itemsParentCategory.map((item: unknown) => (
                    <Dropdown.Item
                      id={`parent-category-item-${item?.['id']}`}
                      key={`parent-category-item-${item?.['id']}`}
                      onClick={() => onChangeParentCategory(item)}
                    >
                      {item?.['text']}
                    </Dropdown.Item>
                  ))}
                </Dropdown.ItemCollection>
              </Dropdown.Overlay>
            )}
          </Dropdown>
          <div className="flex items-center gap-2 py-2">
            <input type="checkbox" name="notHaveParent" id="not-have-parent" onChange={setIsNoParentCategory} />
            <label className="cursor-pointer" htmlFor="not-have-parent">
              Tidak Ada Parent
            </label>
          </div>
        </div>
      </div>
      <div className="flex gap-8 w-3/5 items-center">
        <p className="font-medium w-44" id="category-name-label">
          Nama Kategori *
        </p>
        <div className="flex-1">
          <TextInput
            invalid={isFormGeneralSubmitted && !formCategory.name}
            id="category-name-input"
            onChange={onChangeName}
            placeholder=""
            value={formCategory.name}
          />
        </div>
      </div>
      <div className="flex gap-8 w-3/5 items-center" id="category-active">
        <p className="font-medium w-44" id="category-active-label">
          is Active
        </p>
        <Toggle onToggle={(e) => setIsActive(e as React.ChangeEvent<HTMLInputElement>)} />
      </div>
      <div className="flex gap-8 w-3/5">
        <p className="font-medium w-44" id="category-description-label">
          Deskripsi Kategori
        </p>
        <div className="flex-1">
          <TextAreaInput
            id="category-description-input"
            onChange={onChangeDescription}
            placeholder=""
            value={formCategory.description}
          />
        </div>
      </div>
      <div className="flex gap-8 w-3/5">
        <p className="font-medium w-44">Icon Kategori</p>
        <div className="flex-1">
          <MediaFileInput
            invalid={isIconInvalid}
            id="category-icon-uploader"
            value={formCategory.icon}
            onChange={setIconCategory}
            thumbnailUrl={thumbnailIconUrl}
            uploader={uploaderImage}
            clearTitle="Clear"
            placeholder="Drag and drop to upload or click here"
            uploadErrorLabel="Gambar tidak valid!"
            uploadingLabel="Uploading..."
          />
          <p>Gambar ikon yang disarankan tidak lebih dari 50KB, ratio 1:1, format file : JPG, GIF, SVG, PNG</p>
        </div>
      </div>

      <div className="collapse-seo" id="collapse-seo">
        <Collapse
          collapseButtonLabel="Collapse"
          label="Search Engine Optimization"
          onCollapse={() => setIsCollapseSEO(!collapseSEO)}
          isOpen={collapseSEO}
        >
          <div className="flex flex-col gap-8 mt-8">
            <div className="w-full flex gap-8 items-center">
              <div className="flex gap-8 w-3/5 items-center">
                <p className="font-medium w-44">URL Key</p>
                <div className="flex-1">
                  <TextInput
                    id="seo-url-key"
                    onChange={(value: string) => onInputMetaForm('urlKey', value)}
                    placeholder=""
                    value={formCategory.meta.urlKey}
                    readOnly={isFollowCategoryName}
                  />
                </div>
              </div>
              <div className="flex items-center gap-2 py-2">
                <input
                  type="checkbox"
                  name="followName"
                  id="follow-category-name"
                  className="cursor-pointer"
                  onChange={followCategoryName}
                />
                <label className="cursor-pointer" htmlFor="follow-category-name">
                  mengikuti nama kategori
                </label>
              </div>
            </div>
            <div className="flex gap-8 w-3/5 items-center">
              <p className="font-medium w-44">Meta Title *</p>
              <div className="flex-1">
                <TextInput
                  invalid={isFormGeneralSubmitted && !formCategory.meta.title}
                  id="seo-title"
                  onChange={(value: string) => onInputMetaForm('title', value)}
                  placeholder=""
                  value={formCategory.meta.title}
                />
              </div>
            </div>
            <div className="flex gap-8 w-3/5">
              <p className="font-medium w-44">Meta Keywords</p>
              <div className="flex-1">
                <TextAreaInput
                  id="seo-keywords"
                  onChange={(value: string) => onInputMetaForm('keywords', value)}
                  placeholder=""
                  value={formCategory.meta.keywords}
                />
              </div>
            </div>
            <div className="flex gap-8 w-3/5">
              <p className="font-medium w-44">Meta Description</p>
              <div className="flex-1">
                <TextAreaInput
                  id="seo-description"
                  onChange={(value: string) => onInputMetaForm('description', value)}
                  placeholder=""
                  value={formCategory.meta.description}
                />
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default TabGeneral;
