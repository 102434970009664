import { IDropdown, IDropdownPrimeReact } from './type';

export const optionCategory: IDropdown[] = [
  {
    label: 'Top Keyword List',
    value: 'topKeywordList'
  },
  {
    label: 'Keyword Recommendation',
    value: 'keywordRecommendation'
  },
  {
    label: 'New Product',
    value: 'newProduct'
  },
  {
    label: 'Store Recommendation',
    value: 'storeRecommendation'
  }
];

export const optionCategoryTags: IDropdownPrimeReact[] = [
  { name: 'Top Keyword List', code: 'topKeywordList' },
  { name: 'Keyword Recommendation', code: 'keywordRecommendation' },
  { name: 'New Product', code: 'newProduct' },
  { name: 'Store Recommendation', code: 'storeRecommendation' }
];

export const optionShowDescription: IDropdownPrimeReact[] = [
  { name: 'Yes', code: true },
  { name: 'No', code: false }
];

export const optionAction: IDropdownPrimeReact[] = [
  { name: 'New Tab', code: 'newTab' },
  { name: 'Current Tab', code: 'currentTab' }
];

export const optionStatus: IDropdownPrimeReact[] = [
  { name: 'Enable', code: 'ACTIVE' },
  { name: 'Disable', code: 'INACTIVE' }
];

export const optionPromoTaggingType: IDropdownPrimeReact[] = [
  { name: 'PLP', code: 'plp' },
  { name: 'Landing Page', code: 'landingPage' }
];

export const headerProduct = [
  { field: 'id', header: 'ID' },
  { field: 'product', header: 'Product' },
  { field: 'store', header: 'Store' }
];

export const headerStore = [
  { field: 'id', header: 'ID' },
  { field: 'store', header: 'Store' }
];

export const formCreatePromoTagging = {
  id: 'topKeywordList',
  labelList: [
    'Promo Tagging Name',
    'Description',
    'Show Description',
    'Action',
    'URL',
    'Valid From',
    'Valid To',
    'Apply to Street',
    'Status'
  ],
  componentList: [
    {
      name: 'input',
      behavior: {
        value: ''
      }
    },
    {
      name: 'input',
      behavior: {
        value: ''
      }
    },
    {
      name: 'dropdown',
      behavior: {
        value: '',
        isOpen: false,
        option: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      }
    },
    {
      name: 'dropdown',
      behavior: {
        value: '',
        isOpen: false,
        option: [
          {
            label: 'New Tab',
            value: 'newTab'
          },
          {
            label: 'Current Tab',
            value: 'currentTab'
          }
        ]
      }
    },
    {
      name: 'input',
      behavior: {
        value: ''
      }
    },
    {
      name: 'datepicker',
      behavior: {
        value: ''
      }
    },
    {
      name: 'datepicker',
      behavior: {
        value: ''
      }
    },
    {
      name: 'dropdown',
      behavior: {
        value: '',
        isOpen: false,
        option: [
          {
            label: 'Sport',
            value: 'sport'
          },
          {
            label: 'Aladin',
            value: 'aladin'
          }
        ]
      }
    },
    {
      name: 'dropdown',
      behavior: {
        value: '',
        isOpen: false,
        option: [
          {
            label: 'enable',
            value: 'ACTIVE'
          },
          {
            label: 'disable',
            value: 'INACTIVE'
          }
        ]
      }
    }
  ]
};

export const formPromoTagging = [
  {
    id: 'topKeywordList',
    labelList: [
      'Promo Tagging Name',
      'Description',
      'Show Description',
      'Action',
      'URL',
      'Valid From',
      'Valid To',
      'Apply to Street',
      'Status'
    ],
    componentList: [
      {
        name: 'input',
        behavior: {
          value: ''
        }
      },
      {
        name: 'input',
        behavior: {
          value: ''
        }
      },
      {
        name: 'dropdown',
        behavior: {
          value: '',
          isOpen: false,
          option: [
            {
              label: 'Yes',
              value: true
            },
            {
              label: 'No',
              value: false
            }
          ]
        }
      },
      {
        name: 'dropdown',
        behavior: {
          value: '',
          isOpen: false,
          option: [
            {
              label: 'New Tab',
              value: 'newTab'
            },
            {
              label: 'Current Tab',
              value: 'currentTab'
            }
          ]
        }
      },
      {
        name: 'input',
        behavior: {
          value: ''
        }
      },
      {
        name: 'datepicker',
        behavior: {
          value: ''
        }
      },
      {
        name: 'datepicker',
        behavior: {
          value: ''
        }
      },
      {
        name: 'dropdown',
        behavior: {
          value: '',
          isOpen: false,
          option: [
            {
              label: 'Sport',
              value: 'sport'
            },
            {
              label: 'Aladin',
              value: 'aladin'
            }
          ]
        }
      },
      {
        name: 'dropdown',
        behavior: {
          value: '',
          isOpen: false,
          option: [
            {
              label: 'enable',
              value: 'ACTIVE'
            },
            {
              label: 'disable',
              value: 'INACTIVE'
            }
          ]
        }
      }
    ]
  },
  {
    id: 'keywordRecommendation',
    labelList: [
      'Promo Tagging Type',
      'Promo Tagging Name',
      'Description',
      'Show Description',
      'Action',
      'URL',
      'Valid From',
      'Valid To',
      'Status'
    ],
    componentList: [
      {
        name: 'dropdown',
        behavior: {
          value: '',
          isOpen: false,
          option: [
            {
              label: 'PLP',
              value: 'plp'
            },
            {
              label: 'Landing Page',
              value: 'landingPage'
            }
          ]
        }
      },
      {
        name: 'input',
        behavior: {
          value: ''
        }
      },
      {
        name: 'input',
        behavior: {
          value: ''
        }
      },
      {
        name: 'dropdown',
        behavior: {
          value: '',
          isOpen: false,
          option: [
            {
              label: 'Yes',
              value: true
            },
            {
              label: 'No',
              value: false
            }
          ]
        }
      },
      {
        name: 'dropdown',
        behavior: {
          value: '',
          isOpen: false,
          option: [
            {
              label: 'New Tab',
              value: 'newTab'
            },
            {
              label: 'Current Tab',
              value: 'currentTab'
            }
          ]
        }
      },
      {
        name: 'input',
        behavior: {
          value: ''
        }
      },
      {
        name: 'datepicker',
        behavior: {
          value: ''
        }
      },
      {
        name: 'datepicker',
        behavior: {
          value: ''
        }
      },
      {
        name: 'dropdown',
        behavior: {
          value: '',
          isOpen: false,
          option: [
            {
              label: 'enable',
              value: 'ACTIVE'
            },
            {
              label: 'disable',
              value: 'INACTIVE'
            }
          ]
        }
      }
    ]
  },
  {
    id: 'newProduct',
    labelList: ['Promo Tagging Name', 'Description', 'Select Product', 'Action', 'Valid Form', 'Valid To', 'Status'],
    componentList: [
      {
        name: 'input',
        behavior: {
          value: ''
        }
      },
      {
        name: 'input',
        behavior: {
          value: ''
        }
      },
      {
        name: 'button',
        behavior: {
          value: ''
        }
      },
      {
        name: 'dropdown',
        behavior: {
          value: '',
          isOpen: false,
          option: [
            {
              label: 'New Tab',
              value: 'newTab'
            },
            {
              label: 'Current Tab',
              value: 'currentTab'
            }
          ]
        }
      },
      {
        name: 'datepicker',
        behavior: {
          value: ''
        }
      },
      {
        name: 'datepicker',
        behavior: {
          value: ''
        }
      },
      {
        name: 'dropdown',
        behavior: {
          value: '',
          isOpen: false,
          option: [
            {
              label: 'enable',
              value: 'ACTIVE'
            },
            {
              label: 'disable',
              value: 'INACTIVE'
            }
          ]
        }
      }
    ]
  },
  {
    id: 'storeRecommendation',
    labelList: ['Promo Tagging Name', 'Description', 'Select Store', 'Action', 'Valid Form', 'Valid To', 'Status'],
    componentList: [
      {
        name: 'input',
        behavior: {
          value: ''
        }
      },
      {
        name: 'input',
        behavior: {
          value: ''
        }
      },
      {
        name: 'button',
        behavior: {
          value: ''
        }
      },
      {
        name: 'dropdown',
        behavior: {
          value: '',
          isOpen: false,
          option: [
            {
              label: 'New Tab',
              value: 'newTab'
            },
            {
              label: 'Current Tab',
              value: 'currentTab'
            }
          ]
        }
      },
      {
        name: 'datepicker',
        behavior: {
          value: ''
        }
      },
      {
        name: 'datepicker',
        behavior: {
          value: ''
        }
      },
      {
        name: 'dropdown',
        behavior: {
          value: '',
          isOpen: false,
          option: [
            {
              label: 'enable',
              value: 'ACTIVE'
            },
            {
              label: 'disable',
              value: 'INACTIVE'
            }
          ]
        }
      }
    ]
  }
];


export const FilterStatus = [
  { code: 'true', label: 'TRUE' },
  { code: 'false', label: 'FALSE' }
];


