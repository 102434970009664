import { Image } from 'primereact/image';
import React, { FC, useEffect, useRef, useState } from 'react';

interface ISquareCarousel {
  images: string[];
  size:number
}

export const SquareCarousel: FC<ISquareCarousel> = ({ images, size }) => {
  const [indexSlide, setIndexSlide] = useState<number>(0);
  const productScroll = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (productScroll.current) {
        if (indexSlide !== images?.length - 1) {
          productScroll.current.scrollLeft += size;
          setIndexSlide((prevIndex) => prevIndex + 1);
        } else {
          productScroll.current.scrollLeft = 0;
          setIndexSlide(0);
        }
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [images, indexSlide, size]);

  return (
    <div className="relative" style={{ width: size, height: size }}>
      <div ref={productScroll} className="w-full flex overflow-scroll scroll-smooth no-scroll-bar">
        {images?.map((item, index) => {
          return (
            <div className="rounded-md overflow-hidden flex-shrink-0" style={{ width: size, height: size }} key={index}>
              <Image src={item} alt={item} />
            </div>
          );
        })}
      </div>
      <div className="flex gap-[5px] w-full justify-center absolute bottom-4">
        {images?.map((_, index) => {
          return (
            <div
              className={`h-[7px] w-[7px] rounded-full ${
                indexSlide == index ? 'bg-primary' : 'bg-light-gray'
              } `}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
};
