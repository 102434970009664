import useCustom from './hooks';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import './style.css';
import Table from '@/components/base/DataTable/DataTable';
import { Button } from 'primereact/button';
import ModalBulkUpload from '@/components/base/ModalUpload';
import { usePermissionStore } from '@/store/usePermissions';
import { IPagination } from '@/components/base/Pagination';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Link } from 'react-router-dom';

const ListVariant = () => {
  const {
    data: {
      variant,
      search,
      toast,
      isLoading,
      isOpenDropdown,
      importedVariant,
      fileUploadRef,
      isShowModal,
      filterHistory,
      uploadType,
      totalPages,
      totalRecords,
      perPage,
      paginator,
      itemFilters
    },
    method: {
      confirmDelete,
      handleFilter,
      // handleChangeSearch,
      handleClickDropdownAddVariant,
      handleClickDownload,
      handleChangeFileUpload,
      handleClickUpload,
      handleToggleModal,
      handleClearFilter,
      handleDeleteFilterHistory,
      handleChangeDropdownPage,
      handleClickNext,
      handleClickPrev,
      handleJumpToPage,
      handleChangeJumpTopage,
      handleClickSubmitFilter,
      handleSearch
    }
  } = useCustom();

  const { canCreate, canEdit, canDelete, canView } = usePermissionStore();

  const templateDescription = (rowData) => {
    return <div className="elipsis-20ch text-center mx-auto">{rowData.description}</div>;
  };

  const header = [
    { field: 'id', header: 'ID' },
    { field: 'name', header: 'Variant Name' },
    { field: 'label', header: 'Variant Label' },
    { field: 'description', header: 'Description', body: templateDescription },
    { field: 'type', header: 'Variant Type' },
    { field: 'is_active', header: 'Status' }
  ];

  const instructionTemplate = [
    {
      title: 'Download & ubah File Excel',
      subtitle:
        'Template hanya bisa diisi dengan MS. Excel 2007 ke atas atau Libre Office. Jangan menambah kolom atau baris apa pun di file Excel.'
    },
    {
      title: 'Upload File Excel',
      subtitle: 'Pilih atau letakkan file Excel (.xlsx) kamu di sini, maks. 300 Varian per File.'
    }
  ];

  const paginationOptions: IPagination = {
    perPage: perPage,
    totalPages: totalPages,
    currentPage: paginator.currentPage,
    disabledDropdown: totalRecords === 0,
    onChangeDropdownPage: handleChangeDropdownPage,
    onClickPrev: handleClickPrev,
    onClickNext: handleClickNext,
    onJumptoPage: handleJumpToPage,
    onChangeJumptoPage: handleChangeJumpTopage
  };

  const filterContent = (
    <div className="flex flex-col gap-4 w-[900px]">
      <div className="w-full flex flex-col items-start gap-2">
        <h1 className="font-bold">ID</h1>
        <div className="flex w-full gap-8">
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Dari</label>
            <InputNumber
              onChange={(e) => handleFilter(e, 'id_from')}
              value={itemFilters.id_from}
              name="id_from"
            />
          </div>
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Sampai</label>
            <InputNumber
              onChange={(e) => handleFilter(e, 'id_to')}
              value={itemFilters.id_to}
              name="id_to"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Variant Name</label>
          <InputText
            onChange={(e) => handleFilter(e, 'name')}
            value={itemFilters.name}
            name="name"
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Variant Label</label>
          <InputText
            onChange={(e) => handleFilter(e, 'label')}
            value={itemFilters.label}
            name="label"
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Description</label>
          <InputText
            onChange={(e) => handleFilter(e, 'description')}
            value={itemFilters.description}
            name="description"
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Variant Type</label>
          <InputText
            onChange={(e) => handleFilter(e, 'type')}
            value={itemFilters.type}
            name="type"
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Status</label>
          <Dropdown
            options={[{ label: 'Active', value: 'active' }, { label: 'Inactive', value: 'inactive' }]}
            onChange={(e) => handleFilter(e, 'status')}
            value={itemFilters.status}
            placeholder="Select Status"
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <ConfirmDialog />
      <Toast ref={toast} />
      <BaseContainer>
        <MainTitle title="Variant">
          <div className="relative">
            <div className="rounded-md overflow-hidden h-fit">
              <Link to={canCreate ? 'create' : ''}>
                <Button disabled={!canCreate} severity="danger" className="!rounded-none">
                  <i className="pi pi-plus text-white" />
                </Button>
              </Link>
              <Button disabled={!canCreate} severity="danger" className="!rounded-none" onClick={handleClickDropdownAddVariant}>
                <i className="pi pi-angle-down text-white" />
              </Button>
            </div>
            {isOpenDropdown && (
              <div className="absolute text-black right-0 z-10 border rounded-md bg-white">
                <Link to={canCreate ? 'create' : ''}>
                  <div className="p-4 hover:bg-slate-50 whitespace-nowrap w-full text-start">Tambah Variant</div>
                </Link>
                <button
                  type="button"
                  className="p-4 hover:bg-slate-50 whitespace-nowrap w-full text-start"
                  onClick={handleToggleModal('create')}
                >
                  Tambah Sekaligus
                </button>
                <button
                  type="button"
                  onClick={handleToggleModal('edit')}
                  className="p-4 hover:bg-slate-50 whitespace-nowrap w-full text-start"
                >
                  Ubah Sekaligus
                </button>
              </div>
            )}
          </div>
        </MainTitle>
        <ModalBulkUpload
          handleClickDownload={handleClickDownload}
          handleChangeFileUpload={handleChangeFileUpload}
          handleClickUpload={handleClickUpload}
          disabledInsert={!Boolean(importedVariant.length > 0)}
          fileUploadRef={fileUploadRef}
          show={isShowModal}
          handleCloseModal={handleToggleModal('create')}
          modalTitle={uploadType === 'create' ? 'Tambah Sekaligus Varian' : 'Ubah Sekaligus Varian'}
          placeholder="Drag and drop to upload file or click button choose"
          instruction={instructionTemplate}
        />
        <Table
          filter={search}
          headerTable={header as []}
          rows={totalRecords}
          paginationOptions={paginationOptions}
          isLoadingTable={isLoading}
          onDeleted={confirmDelete}
          onSearch={handleSearch}
          value={variant as []}
          isDeleteAction={canDelete}
          isEditAction={canEdit}
          isDetailAction={canView}
          filterHistory={filterHistory}
          handleClearFilter={handleClearFilter}
          handleDeleteFilterHistory={handleDeleteFilterHistory}
          filterContent={filterContent}
          onSubmitFilter={handleClickSubmitFilter}
        />
      </BaseContainer>
    </>
  );
};

export default ListVariant;
