import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import dayjs from 'dayjs';
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { useGetTeamsByAccoundId, useLastTeamId } from '@/services/rest/userManagement';
import { uniq } from 'lodash';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { useHistoryStore } from '@/store/useHistoryStore';
import { IFilterHistoryItems } from '@/components/base/FilterHistory';
import { useDebounce } from 'primereact/hooks';
type IDataPermission = {
  id?: number
  name?: string
  description?: string
}

export interface IMenuPermission {
  id: number
  name: string
  parent_id: number | null
  children: IChildren[]
  read: boolean
  create: boolean
  update: boolean
  delete: boolean
  changed?: boolean
}

interface IChildren {
  menu_id: number;
  id: number
  name: string
  parent_id: number
  code: string
  read: boolean
  create: boolean
  update: boolean
  delete: boolean
  changed?: boolean
}

export const initialForm = {
  id: 0,
  name: '',
  desciption: ''
};

export interface IFilter {
  roleName: Array<string>;
  status: Array<string>;
}

const useUserRoles = () => {
  const initialFilter = {
    id_from: null,
    id_to: null,
    created_at: [],
    role_name: '',
    status: ''
  };

  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const [dataUserRoles, setDataUserRoles] = useState<[]>([]);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [filterNames, setFilterNames] = useState<[]>([]);
  const [filters, setFilters] = useState(lastFilterPage.accountType != '' ? JSON.parse(String(lastFilterPage.accountType)) : initialFilter);
  const [searchValue, setSearchValue] = useState<string>('');
  const [displayDetailRole, setDisplayDetailRole] = useState<boolean>(false);
  const [displayEdit, setDisplayEdit] = useState<boolean>(false);
  const [displayCreate, setDisplayCreate] = useState<boolean>(false);
  const [displayCreatePermission, setDisplayCreatePermission] = useState<boolean>(false);
  const [displaySettingPermission, setDisplaySettingPermission] = useState<boolean>(false);
  const [dataPermissionsSetting, setDataPermissionsSetting] = useState<IDataPermission | null>();
  const [dataMenusPermission, setDataMenusPermission] = useState<IMenuPermission[]>([]);
  const [defaultMenusPermission, setDefaultMenusPermission] = useState<IMenuPermission[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newCreateData, setNewCreateData] = useState<IDataPermission>(initialForm);
  const [perPage, setPerPage] = useState<number>(10);
  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const currentPage = parseInt(visitedPage.accountType.toString()) ?? 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : perPage - 1;
  const [paginator, setPaginator] = useState({ currentPage, range: { start, end } });
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [jumpToPage, setJumpToPage] = useState<number>(1);
  const [itemFilters, setItemFilters] = useState(lastFilterPage.accountType != '' ? JSON.parse(String(lastFilterPage.accountType)) : initialFilter);
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);
  const [search, debounceSearch, setSearch] = useDebounce('', 1500);

  const { data: dataTeamMenus } = useGetTeamsByAccoundId(1);

  const roleName = useMemo(() => {
    return Array.isArray(dataTeamMenus?.data) ?
      dataTeamMenus?.data.map((item) => {
        return {
          label: item.name,
          value: item.name,
          name: 'roleName'
        };
      }) :
      [];
  }, [dataTeamMenus?.data]);

  const options = [
    {
      label: 'Role Name',
      items: roleName as []
    },
    {
      label: 'Status',
      items: [
        { label: 'Active', value: true, name: 'status' },
        { label: 'Inactive', value: false, name: 'status' }
      ]
    }
  ];
  const toast = useRef<Toast>(null);

  const showToastFailed = (msg: string) => {
    toast.current?.show({ severity: 'error', detail: msg });
  };

  const showToastSucces = (msg: string) => {
    toast.current?.show({ severity: 'success', detail: msg });
  };

  const { data: lastId, refetch: refetchTeamId } = useLastTeamId();
  const newId = useMemo(()=>{
    return lastId?.data ? lastId?.data[0]?.id + 1 : 0;
  }, [lastId]);

  const getUserRoles = useCallback(
    async () => {
      setIsLoading(true);
      const query = supabase
        .from(TABLE.TEAM)
        .select('*', { count: 'exact' }).eq('application_id', 1)
        .order('created_at', { ascending: false })
        .ilike('name', `%${searchValue}`)
        .range(paginator.range.start, paginator.range.end);

      // ID
      if (filters.id_from != null) {
        query.gte('id', filters.id_from);
      }

      if (filters.id_to != null) {
        query.lte('id', filters.id_to);
      }

      // Status
      if (filters.status != '') {
        query.eq('status', (filters.status.toLowerCase() === 'active'));
      }

      // Name
      if (filters.role_name != '' || debounceSearch != '') {
        query.ilike('name', `%${(filters.role_name || debounceSearch)}%`);
      }

      // Created At
      if (filters.created_at && filters.created_at?.length != 0) {
        const fromDate = dayjs(String(filters.created_at[0])).format('YYYY-MM-DD HH:mm:ss');
        query.gte('created_at', fromDate);

        if (filters.created_at.length > 1) {
          const toDate = dayjs(String(filters.created_at[1])).format('YYYY-MM-DD HH:mm:ss');
          query.lte('created_at', toDate);
        }
      }

      const { data, error, count } = await query;

      if (error ) {
        showToastFailed('Error Get Data');
        return;
      }

      if ( data ) {
        const mapper = data.map((item) => {
          const newDate = dayjs(item.created_at).format('DD MMM YYYY');
          item.created_at = newDate;
          return item;
        });
        setDataUserRoles(mapper as []);
        setTotalRecords(Number(count || 0));
      }

      if (filterNames.length === 0) {
        const options = data?.map((item) => {
          return (
            {
              label: item.name,
              value: item.code
            }
          );
        });
        setFilterNames(options as []);
      }
      setIsLoading(false);
    }, [filters, paginator, debounceSearch]
  );

  const handleFilter = useCallback((e, field: string) => {
    setItemFilters((prev) => ({
      ...prev,
      [field]: e.target ? e.target.value : e.value
    }));
  }, []);

  useEffect(() => {
    getUserRoles();
  }, [filters, paginator, debounceSearch]);

  useEffect(() => {
    setNewCreateData((prevState) => ({
      ...prevState,
      id: newId
    }));
  }, [displayCreate, newId]);

  useEffect(() => {
    getUserRoles();
    getMenuPermission();
  }, []);

  const onClickDetailPermission = useCallback(async (e) => {
    setIsLoading(true);
    setDisplayDetailRole(true);


    let applicationId = e.application_id;
    if (applicationId === null) {
      applicationId = 1;
    } else {
      applicationId = e.application_id;
    }

    await getMenuPermission(applicationId);


    //get value base team id
    const api = supabase
      .from(TABLE.TEAM_MENUS)
      .select('*, menus(*)').order('menu_id')
      .eq('team_id', e.id);

    const { data, error } = await api;

    if (error) {
      showToastFailed('Error Get Detail Role And Permission');
      setIsLoading(false);
      return;
    }
    const dataFilter = data.filter((it)=> {
      return !it.menus?.code.includes('create') && !it.menus?.code.includes('detail') && !it.menus?.code.includes('edit') && !it.menus?.code.includes('delete') && !it.menus?.code.includes('customermanagement');
    });
    if (dataFilter) {
      const noParent = dataFilter.filter((item) => item.menus.parent_id === null);


      const curentMenu = noParent.map((menu) => {
        const newMenu = {
          ...menu
        };

        const arrayChildren = dataFilter.filter((item) => item.menus.parent_id === newMenu.menu_id);


        if (arrayChildren.length > 0) {
          const newArray = arrayChildren.map((item) => {
            const newChild = {
              ...item,
              name: item.menus.name
            };
            return newChild;
          });
          newMenu.children = newArray as [];
          return newMenu;
        }
        return newMenu;
      });
      //modify detail

      const updated = defaultMenusPermission;


      curentMenu.forEach((item) => {
        const newUpdateIndex = updated.findIndex((menu) => menu.id === item.menu_id );

        if (newUpdateIndex >= 0) {
          if (!item.create && !item.delete && !item.read && !item.update) {

          } else {
            updated[newUpdateIndex] = {
              name: item.menus.name,
              create: item.create,
              delete: item.delete,
              read: item.read,
              update: item.update,
              children: item.children,
              id: item.menu_id,
              parent_id: null

            };
          }
        }
      });
      setDataMenusPermission(updated as []);
      setIsLoading(false);
    }


    if (e) {
      setDataPermissionsSetting((prevState) => (
        {
          ...prevState,
          id: e.id,
          name: e.name,
          description: e.description
        }
      ));
    }
    setIsLoading(false);
  }, [defaultMenusPermission, dataMenusPermission]);


  //ATUR PERMISSION

  const onClickSettingPermission = async (e) => {
    setIsLoading(true);
    setDisplaySettingPermission(true);
    await getMenuPermission();

    const api = supabase
      .from(TABLE.TEAM_MENUS)
      .select('*, menus(*)').order('menu_id')
      .eq('team_id', e.id);

    const { data, error } = await api;
    if (error) {
      showToastFailed('Error Get List Permission');
      setIsLoading(false);
      return;
    }
    const dataFilter = data.filter((it)=> {
      return !it.menus?.code.includes('create') && !it.menus?.code.includes('detail') && !it.menus?.code.includes('edit') && !it.menus?.code.includes('delete') && !it.menus?.code.includes('customermanagement');
    });
    if (dataFilter) {
      const noParent = dataFilter.filter((item) => item.menus.parent_id === null);
      const currentMenu = noParent.map((menu) => {
        const newMenu = {
          ...menu
        };
        const arrayChildre = dataFilter.filter((item) => item.menus.parent_id === newMenu.menu_id);

        if (arrayChildre.length > 0) {
          const newArray = arrayChildre.map((item) => {
            const newChild = {
              ...item,
              name: item.menus.name
            };
            return newChild;
          });
          newMenu.children = newArray;
          return newMenu;
        }
        return newMenu;
      });
      setDataMenusPermission(defaultMenusPermission as []);

      //mapping semua ke current

      const updated = defaultMenusPermission;

      currentMenu.forEach((item) => {
        const newUpdateIndex = updated.findIndex((menu) => menu.id === item.menu_id );

        if ( newUpdateIndex >= 0 ) {
          if (!item.create && !item.update && item.delete && item.read) {

          } else {
            updated[newUpdateIndex] = {
              name: item.menus.name,
              create: item.create,
              delete: item.delete,
              read: item.read,
              update: item.update,
              children: item.children,
              id: item.menu_id,
              parent_id: null

            };
          }
        }
      });
      setDataMenusPermission(updated as []);

      setIsLoading(false);
    }

    if (e) {
      setDataPermissionsSetting((prevState) => (
        {
          ...prevState,
          id: e.id,
          name: e.name,
          description: e.description
        }
      ));
    }
  };

  const onClickEdit = (e) => {
    setDisplayDetailRole(false);
    setDisplayEdit(true);
  };

  const onChangeValueEdit = useCallback(
    (e) => {
      setDataPermissionsSetting((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    }, [dataPermissionsSetting]
  );

  const handleSubmitEdit = async () => {
    setIsLoading(true);

    if ( !dataPermissionsSetting?.id || !dataPermissionsSetting?.name || !dataPermissionsSetting.description) {
      showToastFailed('Error Missing Input Field');
      setIsLoading(false);
      return;
    }

    const api = supabase
      .from(TABLE.TEAM)
      .update({
        id: dataPermissionsSetting?.id,
        name: dataPermissionsSetting?.name,
        description: dataPermissionsSetting?.description
      }, { count: 'estimated' } )
      .eq('id', dataPermissionsSetting?.id);

    const { count, error } = await api;

    if (count !== 1) {
      // detected RLS error
      showToastFailed('Forbidden');
      setIsLoading(false);
    } else {
      setDisplayEdit(false);
      showToastSucces('Succes Update Data');
      setIsLoading(false);
    }

    if ( error ) {
      showToastFailed('Error Update Role');
      setIsLoading(false);
    }
    getUserRoles();
  };

  const handleSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>)=>{
    setSearch(currentTarget.value);
    const newKeyword = {
      name: 'Keyword',
      items: [{
        label: currentTarget.value,
        value: currentTarget.value,
        name: currentTarget.value
      }]
    };

    if (currentTarget.value != '') {
      setFilterHistory((prev: IFilterHistoryItems[]) => {
        const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
        if (existingIndex !== -1) {
          prev[existingIndex] = newKeyword;
        } else {
          prev.push(newKeyword);
        }
        return [...prev];
      });

      setPaginator({
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      });
    } else {
      handleDeleteFilterHistory('Keyword', ['Keyword']);
    }
  }, [perPage]);

  const onUpdateCheckboxSettingEdit = useCallback(
    (name :string) => {
      const updateName = name.split('.')[0];
      const id = name.split('.')[1];


      const noParentUpdate = dataMenusPermission.map((item) => {
        if ( item.id === parseInt(id)) {
          item[updateName] = !item[updateName];
          item.changed = true;
          return item;
        }
        return item;
      });


      const newResult = noParentUpdate.map((item) => {
        if (item.children) {
          if (item.children.length > 0) {
            item.children.map((child) => {
              if (child.id === parseInt(id)) {
                child[updateName] = !child[updateName];
                child.changed = true;
                item.changed = true;
                return child;
              }
              return child;
            });
          }
        }
        return item;
      });
      setDataMenusPermission(newResult as []);
    }, [dataMenusPermission]
  );

  interface IInsert {
    menu_id: number
    team_id?: number
    create: boolean
    read: boolean
    update: boolean
    delete: boolean
  }

  const onSubmitUpdateCheckboxEdit = async () => {
    setIsLoading(true);

    const noParent = dataMenusPermission.filter((item) => item.changed === true).map((item) => {
      return {
        menu_id: item.id,
        team_id: dataPermissionsSetting?.id,
        create: item.create,
        read: item.read,
        update: item.update,
        delete: item.delete
      };
    });

    const newResult: IInsert[] = [...noParent];

    const subMenu = dataMenusPermission.filter((item) => item.changed === true).filter((item) => item.children && item.children.length > 0);
    subMenu.forEach((item) => {
      const filteredSubMenu = item.children.filter((item) => item.changed === true);
      filteredSubMenu.forEach((child) => {
        const newChild: IInsert = {
          menu_id: child.menu_id || child.id,
          team_id: dataPermissionsSetting?.id,
          create: child.create,
          read: child.read,
          update: child.update,
          delete: child.delete
        };
        newResult.push(newChild);
      });
    });

    // bulk update
    const teamId = uniq(newResult.map((i)=> i.team_id));
    const { error: errorDelete } = await supabase.from(TABLE.TEAM_MENUS).delete().eq('team_id', teamId);
    if (errorDelete) {
      showToastFailed('Can not Update Account Permission');
    }
    const { error } = await supabase
      .from(TABLE.TEAM_MENUS)
      .insert(newResult);

    if (error) {
      setIsLoading(false);
      showToastFailed('can not update checkbox');
      return;
    }

    setIsLoading(false);
    setDisplaySettingPermission(false);
    showToastSucces('Update Role and Permission Succesfully ');
  };

  //CREATE
  const onChangeValueCreate = useCallback(
    (e) => {
      setNewCreateData((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    }, [newCreateData]
  );

  const handleSubmitRole = async () => {
    setIsLoading(true);
    // validation

    const { data } = await supabase
      .from(TABLE.TEAM)
      .select('id')
      .eq('id', newCreateData?.id);

    if ( data ) {
      if ( data.length > 0) {
        showToastFailed('Error Duplicate ID');
        setIsLoading(false);
        return false;
      }
    }


    if ( !newCreateData?.id || !newCreateData.name) {
      setIsLoading(false);
      showToastFailed('Missing input name or id');
      return;
    }

    //duplicate name
    const getFull = supabase
      .from(TABLE.TEAM)
      .select('id, name')
      .or(`id.eq.${newCreateData.id},name.eq.${newCreateData.name}`);


    const { data: dataName } = await getFull;

    if (dataName) {
      if (dataName.length > 0) {
        showToastFailed('Error Duplicate Name');
        setIsLoading(false);
        return;
      }
    }

    await getMenuPermission();
    setDisplayCreate(false);
    setDisplayCreatePermission(true);
    setIsLoading(false);
  };

  const onSubmitCreatePermission = async () => {
    setIsLoading(true);
    const noParent = dataMenusPermission.map((item) => {
      const newItem: IInsert = {
        menu_id: item.id,
        team_id: newCreateData?.id,
        create: item.create,
        read: item.read,
        update: item.update,
        delete: item.delete
      };
      return newItem;
    });

    const result = [...noParent];

    const subMenu = dataMenusPermission.filter((item) => item.children && item.children.length > 0);

    subMenu.forEach((item) => {
      const filterSubmenu = item.children;
      filterSubmenu.forEach((item) => {
        const newChildItem = {
          menu_id: item.id,
          team_id: newCreateData?.id,
          create: item.create,
          read: item.read,
          update: item.update,
          delete: item.delete
        };
        result.push(newChildItem);
      });
    });


    //insert to team

    const query = supabase
      .from(TABLE.TEAM)
      .insert({
        id: newCreateData?.id,
        name: newCreateData?.name,
        description: newCreateData?.description,
        application_id: 1
      });

    const { error: errorRole } = await query;

    if (errorRole) {
      showToastFailed('Error Create Role and Permission');
      setIsLoading(false);
      return;
    }

    //inset to table team menus

    const api = supabase
      .from(TABLE.TEAM_MENUS)
      .insert(result);

    const { error } = await api;

    if ( error ) {
      showToastFailed('Error Create Role and Permission');
      setIsLoading(false);
      return;
    }
    setNewCreateData({ name: '', description: '' });
    getUserRoles();
    setDisplayCreatePermission(false);
    setIsLoading(false);
    showToastSucces('Succes Create New Role and Permission');
  };

  const getMenuPermission = async (applicationId: number = 1) => {
    setIsLoading(true);
    const query = supabase
      .from(TABLE.MENUS)
      .select('*')
      .not('name', 'ilike', '%detail%')
      .not('name', 'ilike', '%create%')
      .not('name', 'ilike', '%edit%')
      .not('name', 'ilike', '%delete%')
      .eq('application_id', applicationId);

    const { data, error } = await query;
    if (error) {
      showToastFailed('Error Get List Permission');
      setIsLoading(false);
      return;
    }
    //filter for {menu name}.create, {menu name}.edit, {menu name}.delete, and {menu name}.read
    //main menu still include
    const dataFilter = data.filter((it)=> {
      return !it.code.includes('create') && !it.code.includes('detail') && !it.code.includes('edit') && !it.code.includes('delete') && !it.code.includes('customermanagement');
    });
    //mapping parent

    if ( dataFilter ) {
      const noParent = dataFilter.filter((item) => item.parent_id === null).map((item) => {
        const newItem = { ...item };
        newItem.create = false;
        newItem.read = false;
        newItem.update = false;
        newItem.delete = false;


        return newItem;
      });

      //mapping children

      const resultMenu = noParent.map((menu) => {
        const newMenu = {
          ...menu
        };

        const arrayChildren = dataFilter.filter((item) => menu.id === item.parent_id).map((item) => {
          const newItem = { ...item };
          newItem.create = false;
          newItem.read = false;
          newItem.update = false;
          newItem.delete = false;


          return newItem;
        });

        if (arrayChildren.length > 0) {
          newMenu.children = arrayChildren as [];
          return newMenu;
        }
        return newMenu;
      });
      setDataMenusPermission(resultMenu as IMenuPermission[]);
      setDefaultMenusPermission(resultMenu as IMenuPermission[]);
    }
    setIsLoading(false);
  };

  const onUpdateCheckboxPermission = useCallback(
    (name :string) => {
      const updateName = name.split('.')[0];
      const id = name.split('.')[1];

      const noParentUpdate = dataMenusPermission.map((item) => {
        if ( item.id === parseInt(id)) {
          item[updateName] = !item[updateName];
          return item;
        }
        return item;
      });

      const newResult = noParentUpdate.map((item) => {
        if (item.children) {
          if (item.children.length > 0) {
            item.children.map((child) => {
              if (child.id === parseInt(id)) {
                child[updateName] = !child[updateName];
                return child;
              }
              return child;
            });
          }
        }
        return item;
      });

      setDataMenusPermission(newResult);
    }, [dataMenusPermission]
  );

  const handleClearFilter = useCallback(() => {
    setLastFilterPage({
      ...lastFilterPage,
      accountType: ''
    });
    setFilters(initialFilter);
    setItemFilters(initialFilter);
    setFilterHistory([]);
    setGlobalFilter('');
  }, []);

  const handleDeleteFilterHistory = useCallback(
    (_: string, value: string[]) => {
      const items = value[0].split(',');
      items.forEach((i) => {
        setFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));

        setItemFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));
      });

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setGlobalFilter('');
    },
    []
  );

  const totalPages = useMemo(() => {
    return Math.ceil(totalRecords / perPage);
  }, [totalRecords, perPage]);


  const handleClickNext = useCallback(() => {
    paginator.currentPage <= totalPages &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: paginator.currentPage + 1,
        range: {
          start: paginator.range.start + perPage,
          end: paginator.range.end + perPage
        }
      }));
  }, [paginator, totalPages, perPage]);

  const handleClickPrev = useCallback(() => {
    paginator.range.start > 0 &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: prev.currentPage - 1,
        range: {
          start: prev.range.start - perPage,
          end: prev.range.end - perPage
        }
      }));
  }, [paginator, perPage]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent) => {
    setPerPage(event.value);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, totalRecords - 1);

      setPaginator(() => ({
        currentPage: jumpToPage > totalPages ? totalPages : value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, totalRecords]);

  const handleClickSubmitFilter = useCallback(() => {
    setFilters(itemFilters);
    setPaginator({
      currentPage: 1,
      range: { start: 0, end: perPage - 1 }
    });
  }, [itemFilters, perPage]);

  const storeFilterHistory = useCallback((filter) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter.id_from != null) {
      let filterID = filter.id_from.toString();
      if (filter.id_to != null) {
        filterID = `${filter.id_from}-${filter.id_to}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('ID', 'id_from,id_to', filterID));
    }

    if (filter?.created_at && filter.created_at[0]) {
      let filterDate = `${dayjs(filter.created_at[0]).format('YYYY-MM-DD')}`;
      if (filter.created_at.length > 1 && filter.created_at[1] !== null) {
        filterDate = `${dayjs(filter.created_at[0]).format('YYYY-MM-DD')} - ${dayjs(filter.created_at[1]).format('YYYY-MM-DD')}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Created At', 'created_at', filterDate));
    }

    if (filter.role_name !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Role Name', 'role_name', filter.role_name));
    }

    if (filter.status !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Status', 'status', filter.status));
    }

    setFilterHistory(filterHistoryItems);
  }, []);

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  useEffect(() => {
    // update store visitedPage with latest page
    setVisitedPage({
      ...visitedPage,
      accountType: paginator.currentPage
    });
  }, [paginator.currentPage]);

  // update store lastFilterPage with latest filter
  useEffect(() => {
    setLastFilterPage({
      ...lastFilterPage,
      accountType: JSON.stringify(filters)
    });
  }, [filters]);

  interface IInsert {
    menu_id: number
    team_id?: number
    create: boolean
    read: boolean
    update: boolean
    delete: boolean
  }


  return {
    data: {
      toast,
      dataUserRoles,
      options,
      filterNames,
      filters,
      status,
      displayDetailRole,
      dataPermissionsSetting,
      isLoading,
      displayEdit,
      searchValue,
      displayCreate,
      newCreateData,
      displaySettingPermission,
      displayCreatePermission,
      dataMenusPermission,
      globalFilter,
      filterHistory,
      totalPages,
      totalRecords,
      perPage,
      paginator,
      itemFilters,
      search
    },
    methods: {
      handleFilter,
      setDisplayDetailRole,
      onClickDetailPermission,
      setNewCreateData,
      onClickEdit,
      setDisplayEdit,
      onChangeValueEdit,
      handleSubmitEdit,
      setSearchValue,
      handleSearch,
      setDisplayCreate,
      onChangeValueCreate,
      handleSubmitRole,
      setDisplaySettingPermission,
      onClickSettingPermission,
      onUpdateCheckboxSettingEdit,
      onSubmitUpdateCheckboxEdit,
      setDisplayCreatePermission,
      onUpdateCheckboxPermission,
      onSubmitCreatePermission,
      setGlobalFilter,
      handleClearFilter,
      refetchTeamId,
      handleDeleteFilterHistory,
      handleClickNext,
      handleClickPrev,
      handleChangeDropdownPage,
      handleChangeJumpTopage,
      handleJumpToPage,
      handleClickSubmitFilter
    }
  };
};
export default useUserRoles;
