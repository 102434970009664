import React from 'react';
import useCustom from './hooks';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Editor } from 'primereact/editor';
import { FileUpload } from 'primereact/fileupload';
import { Image } from 'primereact/image';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { URL_CHECK_NOW } from '@/constants';
import { usePermissionStore } from '@/store/usePermissions';

const CreatePromoPage = () => {
  const { data, method } = useCustom();
  const navigate = useNavigate();
  const getFormErrorMessage = (name) => {
    return method.isFormFieldValid(name) && <small className="p-error">{data.formik.errors[name]}</small>;
  };
  const { canEdit } = usePermissionStore();
  return (
    <BaseContainer>
      <Toast ref={data.toast} />
      <MainTitle
        title={
          (data.params.id && 'Edit Promo Page') || (data.params.detailId && 'Detail Promo Page') || 'Create Promo Page'
        }
      />
      {/* loading screen */}
      <LoadingOverlay show={data.isLoading} />
      <div id="form group">
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="input-promo-name" className="w-1/6">
            Promo Page Name*
          </label>
          <div className="card w-1/2">
            <InputText
              required
              id="input-promo-name"
              className="w-full"
              placeholder="Postage Promo Name"
              value={data.formInput.name}
              onChange={(e) => method.formInputValue('name', e.target.value)}
              disabled={Boolean(data.params.detailId)}
            />
            {getFormErrorMessage('name')}
          </div>
        </div>
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="status" className="w-1/6">
            Status
          </label>
          <div className="card flex justify-content-center w-1/2">
            <InputSwitch
              checked={data.formInput.status}
              onChange={(e) => method.formInputValue('status', e.value as boolean)}
              id="status"
              disabled={Boolean(data.params.detailId)}
            />
          </div>
        </div>
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="show_from" className="w-1/6">
            Promo Show From*
          </label>
          <div className="card flex flex-col w-1/2">
            <Calendar
              value={data.formInput.show_from}
              showTime
              hourFormat="24"
              dateFormat="dd/mm/yy"
              id="show_from"
              hideOnDateTimeSelect
              onChange={(e: CalendarChangeEvent) => method.formInputValue('show_from', e.value as string)}
              showIcon
              disabled={Boolean(data.params.detailId)}
              minDate={new Date()}
              maxDate={new Date(data.formInput.show_to)}
            />
            {getFormErrorMessage('show_from')}
          </div>
        </div>
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="show_to" className="w-1/6">
            Promo Show To*
          </label>
          <div className="card flex flex-col w-1/2">
            <Calendar
              value={data.formInput.show_to}
              inputStyle={{
                border: data.validateShowDate ? '1px solid red' : ''
              }}
              showTime
              hourFormat="24"
              dateFormat="dd/mm/yy"
              id="show_to"
              hideOnDateTimeSelect
              onChange={(e: CalendarChangeEvent) => method.formInputValue('show_to', e.value as string)}
              showIcon
              disabled={Boolean(data.params.detailId) || !Boolean(data.formInput.show_from)}
              minDate={data.formInput.show_from == '' ? new Date() : new Date(data.formInput.show_from)}
            />
            {getFormErrorMessage('show_to')}
            {data.validateShowDate && (
              <div className="text-red-500 text-sm">End time must be greater than start time</div>
            )}
          </div>
        </div>
        <div className="flex flex-col font-bold py-2">
          <div className="py-4 flex justify-between items-center">
            <label htmlFor="label">Promo Information</label>
            <button type="button" onClick={method.handleCollapseSection('promoInformation')}>
              <i className={`pi ${data.isShowSection.promoInformation ? 'pi-minus' : 'pi-plus'}`} />
            </button>
          </div>
          <hr className="w-full font-bold text-solid bg-black h-1" />
        </div>
        {data.isShowSection.promoInformation && (
          <>
            <div className="py-4 flex gap-8">
              <label htmlFor="input_short_description" className="w-1/6">
                Short Description*
              </label>
              <div className="card flex flex-col w-1/2">
                <InputTextarea
                  className="w-full"
                  value={data.formInput.short_desc}
                  id="input_short_description"
                  onChange={(e) => method.formInputValue('short_desc', e.target.value)}
                  rows={3}
                  disabled={Boolean(data.params.detailId)}
                />
                {getFormErrorMessage('short_desc')}
              </div>
            </div>
            <div className="py-4 flex gap-8">
              <label htmlFor="input_description" className="w-1/6">
                Description
              </label>
              <div className="card flex justify-content-center w-1/2">
                <Editor
                  value={data.formInput.desc}
                  onTextChange={(e) => method.formInputValue('desc', e.htmlValue as string)}
                  style={{ height: '200px' }}
                  className="w-full"
                  showHeader={false}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, 4, false] }],
                      ['bold', 'italic', 'underline', 'strike'],
                      ['blockquote', 'code-block'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      [{ script: 'sub' }, { script: 'super' }],
                      [{ indent: '-1' }, { indent: '+1' }],
                      [{ direction: 'rtl' }],
                      [{ align: [] }],
                      ['clean']
                    ]
                  }}
                  readOnly={Boolean(data.params.detailId)}
                />
              </div>
            </div>
            <div className="py-3 flex gap-8 items-center">
              <label htmlFor="input-promo-name" className="w-1/6">
                URL Check Now*
              </label>
              <div className="card w-1/2">
                <InputText
                  required
                  id="input-promo-name"
                  className="w-full"
                  placeholder="Postage Promo Name"
                  value={data.chekedUrl ? URL_CHECK_NOW : data.formInput.url_check_now}
                  onChange={(e) => method.formInputValue('url_check_now', e.target.value)}
                  disabled={Boolean(data.params.detailId || data.chekedUrl)}
                />
                {getFormErrorMessage('url_check_now')}
              </div>
              <div className="flex gap-3">
                <Checkbox checked={data.chekedUrl} onChange={method.handleChekedUrl} />
                <p>Back to Homepage</p>
              </div>
            </div>
            <div className="py-3 flex gap-8 items-center">
              <label htmlFor="voucher" className="w-1/6">
                Promo Category*
              </label>
              <div className="card flex flex-col w-1/2">
                <Dropdown
                  value={data.formInput.promo_category_id}
                  onChange={(e) => method.formInputValue('promo_category_id', e.value)}
                  options={data.categoryPromo}
                  placeholder="Select Promo Category"
                  optionLabel="name"
                  optionValue="id"
                  className="w-full md:w-20rem"
                  id="voucher_type_selection"
                  disabled={Boolean(data.params.detailId)}
                />
                {getFormErrorMessage('promo_category_id')}
              </div>
            </div>
            <div className="py-3 flex gap-8 items-center">
              <label htmlFor="show_from" className="w-1/6">
                Promo Period From*
              </label>
              <div className="card flex flex-col w-1/2">
                <Calendar
                  value={data.formInput.period_from}
                  showTime
                  hourFormat="24"
                  dateFormat="dd/mm/yy"
                  id="show_from"
                  onChange={(e: CalendarChangeEvent) => method.formInputValue('period_from', e.value as string)}
                  showIcon
                  disabled={Boolean(data.params.detailId)}
                  hideOnDateTimeSelect
                  minDate={new Date()}
                  maxDate={new Date(data.formInput.period_to)}
                />
                {getFormErrorMessage('period_from')}
              </div>
            </div>
            <div className="py-3 flex gap-8 items-center">
              <label htmlFor="period_to" className="w-1/6">
                Promo Period To*
              </label>
              <div className="card flex flex-col w-1/2">
                <Calendar
                  inputStyle={{
                    border: data.validateDate ? '1px solid red' : ''
                  }}
                  value={data.formInput.period_to}
                  showTime
                  hourFormat="24"
                  dateFormat="dd/mm/yy"
                  id="period_to"
                  onChange={(e: CalendarChangeEvent) => method.formInputValue('period_to', e.value as string)}
                  showIcon
                  disabled={Boolean(data.params.detailId)}
                  minDate={new Date(data.formInput.period_from)}
                  hideOnDateTimeSelect
                />
                {getFormErrorMessage('period_to')}
                {data.validateDate && (
                  <span className="text-red-500 text-sm">End time must be greater than start time</span>
                )}
              </div>
            </div>
            <div id="image_input" className="mb-3 flex gap-8 items-center">
              <label htmlFor="media_url" className="w-1/6">
                Banner Desktop*
              </label>
              {Boolean(data.params.id || data.params.detailId) && (
                <Image src={data.imagesUrl.desktop} alt="Image" width="250" className="w-1/6" preview />
              )}
              <div className="w-1/2">
                <FileUpload
                  ref={data.fileDekstopRef}
                  id="media_url"
                  accept="image/*"
                  chooseOptions={data.chooseOptions}
                  uploadOptions={data.uploadOptions}
                  cancelOptions={data.cancelOptions}
                  emptyTemplate={<p className="m-0">Upload file here, click second button to upload.</p>}
                  onSelect={(e) => {
                    method.handleUploadImage(e, 'desktop');
                    data.formik.setFieldValue('maxDesktopSize', e.files[0]?.size);
                  }}
                  disabled={Boolean(data.params.detailId)}
                />
                {/* validation size image */}
                {data.formik.errors['maxDesktopSize'] && (
                  <small className="p-error block">{data.formik.errors['maxDesktopSize']}</small>
                )}

                {/* validation field image */}
                {getFormErrorMessage('desktop')}
              </div>
              <span>
                *Gambar yang disarankan <br />
                tidak lebih dari 150KB <br />
                format file : JPG, GIF, PNG
              </span>
            </div>
            <div id="image_input" className="mb-3 flex gap-8 items-center">
              <label htmlFor="media_url" className="w-1/6">
                Banner Mobile*
              </label>
              {Boolean(data.params.id || data.params.detailId) && (
                <Image src={data.imagesUrl.mobile} alt="Image" width="250" className="w-1/6" preview />
              )}
              <div className="w-1/2">
                <FileUpload
                  ref={data.fileMobileRef}
                  id="media_url"
                  accept="image/*"
                  chooseOptions={data.chooseOptions}
                  uploadOptions={data.uploadOptions}
                  cancelOptions={data.cancelOptions}
                  emptyTemplate={<p className="m-0">Upload file here, click second button to upload.</p>}
                  onSelect={(e) => {
                    data.formik.setFieldValue('maxMobileSize', e.files[0]?.size);
                    method.handleUploadImage(e, 'mobile');
                  }}
                  disabled={Boolean(data.params.detailId)}
                />
                {/* validation size image */}
                {data.formik.errors['maxMobileSize'] && (
                  <small className="p-error block">{data.formik.errors['maxMobileSize']}</small>
                )}

                {/* validation field image */}
                {getFormErrorMessage('mobile')}
              </div>
              <span>
                *Gambar yang disarankan <br />
                tidak lebih dari 150KB <br />
                format file : JPG, GIF, PNG
              </span>
            </div>
            <div id="image_input" className="mb-3 flex gap-8 items-center">
              <label htmlFor="media_url" className="w-1/6">
                Banner Thumbnail*
              </label>
              {Boolean(data.params.id || data.params.detailId) && (
                <Image src={data.imagesUrl.thumbnail} alt="Image" width="250" className="w-1/6" preview />
              )}
              <div className="w-1/2">
                <FileUpload
                  ref={data.fileThumbnailRef}
                  id="media_url"
                  accept="image/*"
                  chooseOptions={data.chooseOptions}
                  uploadOptions={data.uploadOptions}
                  cancelOptions={data.cancelOptions}
                  emptyTemplate={<p className="m-0">Upload file here, click second button to upload.</p>}
                  onSelect={(e) => {
                    data.formik.setFieldValue('maxThumbnailSize', e.files[0]?.size);
                    method.handleUploadImage(e, 'thumbnail');
                  }}
                  disabled={Boolean(data.params.detailId)}
                />
                {/* validation size image */}
                {data.formik.errors['maxThumbnailSize'] && (
                  <small className="p-error block">{data.formik.errors['maxThumbnailSize']}</small>
                )}

                {/* validation field image */}
                {getFormErrorMessage('thumbnail')}
              </div>
              <span>
                *Gambar yang disarankan <br />
                tidak lebih dari 150KB <br />
                format file : JPG, GIF, PNG
              </span>
            </div>
          </>
        )}
        <div className="flex flex-col font-bold py-2">
          <div className="py-4 flex justify-between items-center">
            <label htmlFor="label">Promo Code</label>
            <button type="button" onClick={method.handleCollapseSection('promoCode')}>
              <i className={`pi ${data.isShowSection.promoCode ? 'pi-minus' : 'pi-plus'}`} />
            </button>
          </div>
          <hr className="w-full font-bold text-solid bg-black h-1" />
        </div>
        {data.isShowSection.promoCode && (
          <>
            <div className="mt-2 flex w-3/4">
              <p className="font-bold w-1/4">Using a promo code ?</p>
              <div className="ml-9 w-1/4 flex ">
                <Checkbox
                  disabled={Boolean(data.params.detailId)}
                  checked={data.isCheckedPromoCode}
                  onChange={(e: CheckboxChangeEvent) => method.handleCheckedPromoCode(e)}
                />
                <label className="px-3">without promo code</label>
              </div>
            </div>
            {Array.isArray(data?.formInput.multiple_code) &&
              data?.formInput.multiple_code.map((item, idx) => {
                return (
                  <div key={idx} className="w-full flex items-center">
                    <div className="flex flex-col py-3 w-3/4">
                      <div className="flex py-2 gap-x-8 items-center">
                        <label htmlFor="code" className="w-1/4">
                          Promo Code
                        </label>
                        <InputText
                          id="code"
                          name="code"
                          className="w-2/3"
                          value={item.code}
                          onChange={(e) => method.handleInputChange(idx, e)}
                          disabled={Boolean(data.params.detailId) || data.isCheckedPromoCode}
                        />
                      </div>
                      <div className="flex gap-8 items-center">
                        <label htmlFor="description" className="w-1/4">
                          Short Description Promo Code
                        </label>
                        <InputTextarea
                          id="description"
                          name="description"
                          className="w-2/3"
                          value={item.description}
                          onChange={(e) => method.handleInputChange(idx, e)}
                          disabled={Boolean(data.params.detailId) || data.isCheckedPromoCode}
                        />
                      </div>
                    </div>
                    <div className="w-fit p-4">
                      <Button icon="pi pi-trash" text severity="danger" disabled={idx === 0 && (item.code == '' || item.description == '') || data.isCheckedPromoCode}
                        onClick={()=>method.handleRemovePromo(idx)}
                      />
                    </div>
                  </div>
                );
              })}
            <Button label="Add Promo Code" icon="pi pi-plus"
              severity="secondary" text
              onClick={method.handleAddPromoPage}
              disabled={method.disabledButton(data.params.detailId, data.isCheckedPromoCode)}
            />
          </>
        )}
      </div>
      <div id="buttons-action" className="w-full flex justify-end gap-6">
        {/* <Toast position='center' ref={data.toast} /> */}
        <SubmitButton
          disabledSubmit={Boolean(data.validateDate) || !canEdit}
          onCancel={() => {
            method.resetForm();
            navigate('/promo-page');
          }}
          onSubmit={data.params.detailId? ()=>navigate(`/promo-page/edit/${data.params.detailId}`) : ()=>data.formik.handleSubmit()}
          labelBack={data.params.detailId?'Back':'Cancel'}
          labelSubmit={data.params.detailId?'Edit':'Submit'}
        />
      </div>
    </BaseContainer>
  );
};

export default CreatePromoPage;
