import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { Chips, ChipsChangeEvent } from 'primereact/chips';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { Toast } from 'primereact/toast';
import useEditVoucher from './hooks';
import { useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import ModalBulkUpload from '@/components/base/ModalUpload';
import { usePermissionStore } from '@/store/usePermissions';
import ListVoucherDetailVoucher from './ListVoucher';

const EditVoucher = () => {
  const location = useLocation();
  const {
    data: {
      formValue,
      optionNominal,
      isLoading,
      optionVoucherType,
      toast,
      selectionDiscountOption,
      variantOptions,
      excludedDiscountOption,
      merchantOptions,
      promoTagsOptions,
      params,
      collapse,
      formik,
      addMoreException,
      isSubmitting,
      fileUploadRef,
      codeUpload,
      isShow,
      optionsPayments
    },
    method: {
      onChangeFormValue,
      handleOnChangeDropdown,
      navigate,
      handleOnChangeCheckbox,
      onCollapse,
      handleSelectFile,
      isFormFieldValid,
      handleAddException,
      handleDeleteRuleExcepiton,
      handleInputChange,
      findMerchants,
      findProducts,
      findPromoTags,
      handleClickDownload,
      onEditVoucher,
      setIsShow
    }
  } = useEditVoucher();
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };
  const instructionTemplate = [
    {
      title: 'Download & ubah File Excel',
      subtitle:
        'Template hanya bisa diisi dengan MS. Excel 2007 ke atas atau Libre Office. Jangan menambah kolom atau baris apa pun di file Excel.'
    },
    {
      title: 'Upload File Excel',
      subtitle: 'Pilih atau letakkan file Excel (.xlsx) kamu di sini, maks. 300 Code per File.'
    }
  ];
  const { canEdit } = usePermissionStore();
  return (
    <>
      <Toast ref={toast} />
      <LoadingOverlay show={isLoading || isSubmitting} />
      <BaseContainer>
        <MainTitle title={location.pathname.includes('edit') ? 'Edit Promo Voucher' : 'Detail Promo Voucher'} />
        <div id="form-edit-voucher" className="gap-8 items-center">
          <div className="flex flex-row items-center py-3">
            <label htmlFor="input-promo-name" className="flex font-medium w-1/6">
              Promo Voucher Name*
            </label>
            <div className="card w-1/3">
              <InputText
                required
                id="voucher-name-input"
                className="w-full"
                placeholder="Input Promo Name"
                value={formValue.name}
                onChange={(e) => {
                  onChangeFormValue('name', e.target.value);
                }}
                disabled={!!params.detailId}
              />
              {getFormErrorMessage('name')}
            </div>
          </div>
          <div className="flex flex-row items-center py-3">
            <label htmlFor="select-platform" className="flex items-center font-medium w-1/6">
              Platform*
            </label>
            <div className="card justify-content-center w-1/3">
              <Checkbox
                disabled={Boolean(params.detailId)}
                checked={formValue.isAllPlatform as boolean}
                onChange={(e: CheckboxChangeEvent) => onChangeFormValue('isAllPlatform', e.checked as boolean)}
              />
              <label className="ml-5">All Platform</label>
            </div>
          </div>
          <div className="flex flex-row items-center py-3">
            <label htmlFor="select-group" className="flex items-center font-medium w-1/6">
              Customer Group*
            </label>
            <div className="card justify-content-center w-1/3">
              <Checkbox
                disabled={Boolean(params.detailId)}
                checked={formValue.isRegisteredCustomer as boolean}
                onChange={(e: CheckboxChangeEvent) => onChangeFormValue('isRegisteredCustomer', e.checked as boolean)}
              />
              <label className="ml-5">Registered Customer</label>
            </div>
          </div>
          <div className="flex flex-row items-center py-3">
            <label htmlFor="select-group" className="flex items-center font-medium w-1/6">
              Cooperation With*
            </label>
            <div className="card flex flex-col justify-content-center w-1/3">
              <Dropdown
                value={formValue.cooperation}
                onChange={handleOnChangeDropdown('cooperation')}
                options={[
                  { name: 'AladinMall', code: 'AM' },
                  { name: 'Official Store', code: 'OS' }
                ]}
                optionLabel="name"
                optionValue="code"
                id="voucher-cooperation-input"
                placeholder="Select Cooperation With"
                className="w-full md:w-20rem"
                disabled={!!params.detailId}
              />
              {getFormErrorMessage('cooperation')}
            </div>
            {formValue.cooperation === 'OS' && (
              <div className="flex justify-content-center ml-4 w-1/5">
                <MultiSelect
                  disabled={Boolean(params.detailId)}
                  value={formValue.merchants}
                  onChange={(e) => onChangeFormValue('merchants', e.value)}
                  onFilter={(e)=> {
                    findMerchants(e.filter);
                  }}
                  options={merchantOptions as []}
                  optionLabel="name"
                  optionValue="id"
                  id="voucher-merchant-input"
                  placeholder="Cari untuk memilih"
                  filterPlaceholder="Cari untuk memilih"
                  className="w-80 md:w-20rem"
                  filter
                  filterBy="id,name"
                  resetFilterOnHide
                />
              </div>
            )}
          </div>
          {formValue.cooperation === 'OS' && (
            <div id="amount" className="flex items-center w-full">
              <label htmlFor="select-group" className="flex font-medium w-1/6">
                Borne by Seller
              </label>
              <div className="w-1/3">
                <InputNumber
                  disabled={Boolean(params.detailId)}
                  value={formValue.borned_by_seller}
                  id="voucher-seller-borned-input"
                  onChange={(e) => {
                    const maxDiscValue = Number(e.value) > 100 ? 100 : e.value;
                    onChangeFormValue('borned_by_seller', maxDiscValue as number);
                  }}
                  className="w-full"
                />
              </div>
              <span className="p-inputgroup-addon">%</span>
            </div>
          )}
          <div className="flex items-center py-3">
            <label htmlFor="start-date-label" className="font-medium w-1/6">
              Valid From*
            </label>
            <div className="card flex flex-col justify-content-center w-1/3">
              <Calendar
                id="voucher-started-date-input"
                value={formValue.start_date}
                onChange={(e: CalendarChangeEvent) => {
                  onChangeFormValue('start_date', e.value as string);
                }}
                showIcon
                showTime
                hideOnDateTimeSelect
                hourFormat="24"
                dateFormat="dd/mm/yy"
                disabled={!!params.detailId}
                maxDate={new Date(formValue.end_date)}
                minDate={new Date()}
              />
              {getFormErrorMessage('start_date')}
            </div>
          </div>
          <div className="flex items-center py-3">
            <label htmlFor="end-date-label" className="font-medium w-1/6">
              Valid To*
            </label>
            <div className="card flex flex-col justify-content-center w-1/3">
              <Calendar
                id="voucher-ended-date-input"
                value={formValue.end_date}
                onChange={(e: CalendarChangeEvent) => {
                  onChangeFormValue('end_date', e.value as string);
                }}
                showIcon
                showTime
                hideOnDateTimeSelect
                hourFormat="24"
                dateFormat="dd/mm/yy"
                disabled={params.detailId ? true : false && !Boolean(formValue.start_date)}
                minDate={new Date(formValue.start_date)}
              />
              {getFormErrorMessage('end_date')}
            </div>
          </div>
          <div className="flex items-center py-3">
            <label htmlFor="status" className="flex font-medium w-1/6">
              Status*
            </label>
            <div className="card flex justify-content-center w-1/3">
              <InputSwitch
                id="voucher-status-input"
                checked={formValue.status}
                onChange={(e) => {
                  onChangeFormValue('status', e.value as boolean);
                }}
                disabled={!!params.detailId}
              />
            </div>
          </div>
          <div className="flex items-center py-3">
            <label htmlFor="label" className="flex font-medium w-1/6">
              Promo Description*
            </label>
            <div className="card flex flex-col justify-content-center w-1/3">
              <InputTextarea
                id="voucher-description-input"
                className="w-full"
                value={formValue.description}
                onChange={(e) => {
                  onChangeFormValue('description', e.target.value);
                }}
                rows={5}
                cols={30}
                disabled={!!params.detailId}
              />
              {getFormErrorMessage('description')}
            </div>
          </div>
          <div className="flex items-center py-3">
            <label htmlFor="label" className="flex font-medium w-1/6">
              Voucher*
            </label>
            <div className="card flex flex-col justify-content-center w-1/3">
              <Dropdown
                id="voucher-type-input"
                value={formValue.voucher_type}
                onChange={(e) => {
                  onChangeFormValue('voucher_type', e.value);
                }}
                options={optionVoucherType}
                optionLabel="name"
                optionValue="code"
                placeholder="Select a Voucher"
                className="w-full md:w-14rem"
                disabled={!!params.detailId}
              />
              {getFormErrorMessage('voucher_type')}
            </div>
          </div>
          {formValue.voucher_type == 'specific' && (
            <div className="flex items-center py-3">
              <label htmlFor="input-promo-name" className="flex font-medium w-1/6">
                Voucher Code*
              </label>
              <div className="card w-1/3">
                <InputText
                  required
                  id="voucher-code-input"
                  className="w-full"
                  placeholder="Input Voucher Code"
                  value={formValue.code}
                  onChange={(e) => {
                    onChangeFormValue('code', e.target.value);
                  }}
                  disabled={Boolean(params.detailId) || formValue.use_generator}
                />
                {getFormErrorMessage('code')}
                <div className="flex mt-4">
                  <Checkbox
                    id="voucher-is-user-quota-unlimited-input"
                    onChange={handleOnChangeCheckbox('use_generator')}
                    checked={formValue.use_generator as boolean}
                    disabled={Boolean(params.detailId)}
                  />
                  <label htmlFor="voucher-use-generator" className="ml-2">
                    Use Code Generator
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-between mt-8 items-center">
          <label htmlFor="label" className="flex font-bold">
            Usage Quota
          </label>
          <Button
            type="button"
            icon={collapse.quota ? 'pi pi-minus' : 'pi pi-plus'}
            rounded
            text
            onClick={() => onCollapse('quota', !collapse.quota)}
            severity="secondary"
          />
        </div>
        <hr className="w-full font-bold text-solid bg-black h-1" />
        {collapse.quota && (
          <div id="voucher-usage-quota-form" className="gap-8 pt-3 items-center">
            <div className="flex items-center py-3">
              <label htmlFor="input-kuota-promo" className="flex font-medium w-1/6">
                Promo Quota*
              </label>
              <div className="card w-1/3">
                <InputNumber
                  className="w-full"
                  id="voucher-quota-voucher-input"
                  value={formValue.use_generator ? (formValue.quota = 1) : (formValue.quota as number)}
                  onValueChange={(e) => onChangeFormValue('quota', e.target.value as number)}
                  useGrouping={false}
                  disabled={Boolean(params.detailId) || formValue.use_generator}
                />
                {getFormErrorMessage('quota')}
              </div>
            </div>
            <div className="flex items-center py-3">
              <label htmlFor="input-promo-name" className="flex font-medium w-1/6">
                Usage per User*
              </label>
              <div className="card w-1/3">
                <InputNumber
                  id="voucher-user-quota-input"
                  value={formValue.user_quota}
                  onValueChange={(e) => onChangeFormValue('user_quota', e.target.value as number)}
                  useGrouping={false}
                  className="w-full"
                  disabled={Boolean(params.detailId) || formValue.is_user_quota_unlimited || formValue.use_generator}
                />
                {getFormErrorMessage('user_quota')}
              </div>
              <div className="flex ml-4">
                <Checkbox
                  id="voucher-is-user-quota-unlimited-input"
                  onChange={handleOnChangeCheckbox('is_user_quota_unlimited')}
                  checked={formValue.is_user_quota_unlimited}
                  disabled={!!params.detailId || formValue.use_generator}
                />
                <label htmlFor="ingredient1" className="ml-2">
                  No Usage Limit
                </label>
              </div>
            </div>
          </div>
        )}
        <div className="flex justify-between mt-8 items-center">
          <label htmlFor="label" className="flex items-center font-bold">
            Discount and Condition
          </label>
          <Button
            type="button"
            icon={collapse.condition ? 'pi pi-minus' : 'pi pi-plus'}
            onClick={() => onCollapse('condition', !collapse.condition)}
            rounded
            text
            severity="secondary"
          />
        </div>
        <hr className="w-full font-bold text-solid bg-black h-1" />
        {collapse.condition && (
          <>
            <div id="voucher-form-condition-and-discount" className="gap-8 pt-3 items-center">
              <div className="flex items-center py-3">
                <label htmlFor="input-kuota-promo" className="flex font-medium w-1/6">
                  Minimum Subtotal Order*
                </label>
                <div className="card w-1/3">
                  <InputNumber
                    className="w-full"
                    id="voucher-min-payment-amount-input"
                    value={
                      formValue.no_minimal ?
                        (formValue.min_payment_amount = 0) :
                        (formValue.min_payment_amount as number)
                    }
                    onValueChange={(e) => onChangeFormValue('min_payment_amount', e.target.value as number)}
                    useGrouping={false}
                    disabled={Boolean(params.detailId) || formValue.no_minimal}
                  />
                  {getFormErrorMessage('min_payment_amount')}
                </div>
                <div className="flex ml-4">
                  <Checkbox
                    id="voucher-no-minimal-input"
                    onChange={handleOnChangeCheckbox('no_minimal')}
                    checked={formValue.no_minimal as boolean}
                    disabled={!!params.detailId}
                  />
                  <label htmlFor="voucher-no-minimal-subtotal" className="ml-2">
                    No Minimum Subtotal Order
                  </label>
                </div>
              </div>
              <div className="flex items-center py-3">
                <label htmlFor="discount_type" className="flex font-medium w-1/6">
                  Nominal Diskon*
                </label>
                <div className="w-5/6">
                  <div className="py-3 flex gap-4">
                    <Dropdown
                      id="voucher-amount-type-input"
                      value={formValue.amount_type}
                      onChange={(e) => {
                        onChangeFormValue('amount_type', e.value);
                      }}
                      options={optionNominal}
                      optionLabel="name"
                      optionValue="code"
                      placeholder="Select Nominal"
                      className="w-1/4 md:w-14rem"
                      disabled={!!params.detailId}
                    />
                    {getFormErrorMessage('amount_type')}
                    {(formValue.amount_type === 'fixed') && (
                      <div className="relative">
                        <div id="discount_amount" className="p-inputgroup">
                          <label htmlFor="discount_amount" className="self-center mr-3">
                            Amount
                          </label>
                          <InputNumber
                            id="voucher-discount-amount-input"
                            className="w-full"
                            inputId="discount-amount"
                            value={formValue.discount_amount as number}
                            onValueChange={(e) => onChangeFormValue('discount_amount', e.target.value as number)}
                            useGrouping={false}
                            disabled={!!params.detailId}
                          />
                        </div>
                        {getFormErrorMessage('discount_amount')}
                      </div>
                    )}
                    {formValue.amount_type === 'percentage' && (
                      <div className="flex flex-row gap-2">
                        <div id="discount_percentage" className="p-inputgroup">
                          <label htmlFor="discount_percentage" className="self-center mr-3">
                            Senilai
                          </label>
                          <div className="flex flex-col gap-1">
                            <div className="flex">
                              <InputNumber
                                id="voucher-discount-percentage-input"
                                className="w-full"
                                inputId="discount-percentage"
                                value={formValue.discount_percentage as number}
                                onValueChange={(e) => {
                                  const maxValueDisc = Number(e.target.value) >= 100 ? 100 : Number(e.target.value);
                                  onChangeFormValue('discount_percentage', maxValueDisc as number);
                                }}
                                max={100}
                                useGrouping={false}
                                disabled={!!params.detailId}
                              />
                              <span className="p-inputgroup-addon">%</span>
                            </div>
                            {getFormErrorMessage('discount_percentage')}
                          </div>
                        </div>
                        <div className="relative">
                          <div className="flex items-center gap-2">
                            <label htmlFor="max_amount">Amount</label>
                            <InputNumber
                              className="w-full"
                              id="voucher-max-discount-amount-input"
                              value={formValue.max_discount_amount as number}
                              onValueChange={(e) => onChangeFormValue('max_discount_amount', e.target.value as number)}
                              useGrouping={false}
                              disabled={!!params.detailId}
                            />
                            {getFormErrorMessage('max_discount_amount')}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex items-center py-3">
                <label htmlFor="label" className="flex font-medium w-1/6">
                  Payment Method*
                </label>
                <div className="card w-1/3">
                  <Dropdown
                    id="voucher-payment-method-input"
                    value={formValue.payment_method}
                    onChange={handleOnChangeDropdown('payment_method')}
                    options={optionsPayments}
                    optionLabel="label"
                    placeholder="Select a Payment Method"
                    className="w-full md:w-14rem"
                    disabled={!!params.detailId}
                  />
                  {getFormErrorMessage('payment_method')}
                </div>
              </div>
              {formValue.payment_method.code === 'credit_card' && (
                <div className="flex items-center py-3">
                  <label htmlFor="label" className="flex font-medium w-1/6">
                    BIN Code*
                  </label>
                  <div className="card p-fluid w-1/3">
                    <Chips
                      id="voucher-bin-code-input"
                      value={formValue.bin_code}
                      onChange={(e: ChipsChangeEvent) => onChangeFormValue('bin_code', e.value as string[])}
                      separator=","
                      disabled={!!params.detailId}
                    />
                    {getFormErrorMessage('bin_code')}
                  </div>
                </div>
              )}
              <div className="flex items-center py-3">
                <label htmlFor="label" className="flex font-medium w-1/6">
                  Discount Calculated From
                </label>
                <div className="card w-1/3">
                  <Dropdown
                    id="voucher-discount-by-selection-input"
                    value={formValue.discount_by}
                    onChange={handleOnChangeDropdown('discount_by')}
                    options={selectionDiscountOption}
                    optionLabel="name"
                    optionValue="code"
                    placeholder=""
                    className="w-full md:w-14rem"
                    disabled={!!params.detailId}
                  />
                </div>
              </div>
            </div>
            {formValue.discount_by !== 'ALL' && formValue.discount_by !== '' && (
              <div className="mt-10 space-y-5">
                <label htmlFor="label" className="flex items-center font-medium w-1/3">
                  Match
                </label>
                <div className="flex gap-8 w-1/2">
                  <div className="card flex justify-content-center w-[250px]">
                    <Dropdown
                      placeholder={
                        (formValue.discount_by === 'PRODUCT' && 'Produk Pilihan') ||
                        (formValue.discount_by === 'STORE' && 'Merchant Pilihan') ||
                        (formValue.discount_by === 'PROMO' && 'Promo Tag Pilihan') ||
                        ''
                      }
                      id="voucher-applied-by-selection-input"
                      disabled
                      className="w-full md:w-14rem"
                    />
                  </div>
                  <label htmlFor="label" className="flex items-center font-medium">
                    Value
                  </label>
                  <div className="card p-fluid w-full">
                    <MultiSelect
                      value={formValue.applied_to}
                      options={
                        (formValue.discount_by === 'PRODUCT' && variantOptions) ||
                        (formValue.discount_by === 'STORE' && merchantOptions) ||
                        (formValue.discount_by === 'PROMO' && promoTagsOptions) ||
                        []
                      }
                      onFilter={(e) => {
                        formValue.discount_by === 'PRODUCT' && findProducts(e.filter);
                        formValue.discount_by === 'STORE' && findMerchants(e.filter);
                        formValue.discount_by === 'PROMO' && findPromoTags(e.filter);
                      }}
                      filterPlaceholder="Cari untuk memilih"
                      display="chip"
                      optionLabel="name"
                      optionValue="id"
                      maxSelectedLabels={3}
                      onChange={handleOnChangeDropdown('applied_to')}
                      id="voucher-applied-selection-input"
                      filter
                      filterBy="id,name"
                      disabled={!!params.detailId}
                      resetFilterOnHide
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="mt-10 space-y-5">
              <label htmlFor="label" className="flex items-center font-medium w-1/3">
                Add Rule For Exceptions
              </label>
              {addMoreException.map((item, index) => {
                return (
                  <div key={index} className="flex gap-8 w-1/2">
                    <div className="card flex justify-content-center w-[250px]">
                      <Dropdown
                        disabled={Boolean(params.detailId)}
                        id="voucher-exclude-by-selection-input"
                        value={item.exclude_type}
                        onChange={(e: MultiSelectChangeEvent) => {
                          handleInputChange(index, e);
                        }}
                        options={excludedDiscountOption}
                        optionDisabled={(op) => (addMoreException.filter((o) => op.code === o.exclude_type).length > 0)}
                        optionLabel="name"
                        optionValue="code"
                        placeholder=""
                        name="exclude_type"
                        className="w-52 md:w-14rem"
                      />
                    </div>
                    <label htmlFor="label" className="flex items-center font-medium">
                      Value
                    </label>
                    <div className="card p-fluid w-80">
                      <MultiSelect
                        disabled={Boolean(params.detailId)}
                        options={
                          (item.exclude_type === 'PRODUCT' && variantOptions) ||
                          (item.exclude_type === 'STORE' && merchantOptions) ||
                          (item.exclude_type === 'PROMO' && promoTagsOptions) ||
                          []
                        }
                        onFilter={(e) => {
                          item.exclude_type === 'PRODUCT' && findProducts(e.filter);
                          item.exclude_type === 'STORE' && findMerchants(e.filter);
                          item.exclude_type === 'PROMO' && findPromoTags(e.filter);
                        }}
                        filterPlaceholder="Cari untuk memilih"
                        optionLabel="name"
                        optionValue="id"
                        display="chip"
                        value={item.exclude}
                        maxSelectedLabels={3}
                        id="voucher-exclude-selection-input"
                        filter
                        filterBy="id,name"
                        name="exclude"
                        onChange={(e) => {
                          handleInputChange(index, e, item.exclude_type);
                        }}
                        resetFilterOnHide
                      />
                    </div>
                    <Button
                      className="flex-none"
                      type="button"
                      icon="pi pi-trash"
                      rounded
                      text
                      onClick={() => handleDeleteRuleExcepiton(index)}
                      disabled={addMoreException.length === 1 || Boolean(params.detailId)}
                    />
                  </div>
                );
              })}
            </div>
            <div className="py-4">
              <Button
                type="button"
                id="voucher-add-exclude-button"
                disabled={addMoreException.length === 3 || Boolean(params.detailId)}
                label="+ Add Exception"
                onClick={() => {
                  handleAddException();
                }}
                text
              />
            </div>
          </>
        )}
        <div className="flex justify-between mt-8 items-center">
          <label htmlFor="label" className="flex items-center font-bold">
            Voucher Code
          </label>
          <Button
            type="button"
            icon={collapse.codes ? 'pi pi-minus' : 'pi pi-plus'}
            onClick={() => onCollapse('codes', !collapse.codes)}
            rounded
            text
            severity="secondary"
          />
        </div>
        <hr className="w-full font-bold text-solid bg-black h-1" />
        {collapse.codes && (
          <>
            <ModalBulkUpload
              handleClickDownload={handleClickDownload}
              modalTitle="Tambah Sekaligus Kode Voucher"
              placeholder="Drag and drop to upload file or click button choose"
              fileUploadRef={fileUploadRef}
              disabledInsert={!Boolean(codeUpload.length > 0)}
              instruction={instructionTemplate}
              handleChangeFileUpload={handleSelectFile}
              isLoadingUpload={isSubmitting}
              handleClickUpload={onEditVoucher}
              show={isShow}
              handleCloseModal={()=>setIsShow(false)}
            />
            <div className="py-4">
              <Button
                type="button" id="voucher-upload-code"
                label="Import Code"
                onClick={()=>setIsShow(true)}
                disabled={!location.pathname.includes('edit') || !formValue.use_generator || (formValue.use_generator && !!formValue.code)}
              />
            </div>
            {
              formValue.use_generator && (
                <div className="py-4">
                  <ListVoucherDetailVoucher />
                </div>
              )
            }
          </>
        )}
        <Toast ref={toast} />
        <SubmitButton
          labelSubmit={params.detailId ? 'Edit' : 'Submit'}
          labelBack="Cancel"
          disabledSubmit={isLoading || isSubmitting || !canEdit}
          onCancel={() => {
            navigate('/voucher');
          }}
          onSubmit={() => formik.handleSubmit()}
        />
      </BaseContainer>
    </>
  );
};

export default EditVoucher;
