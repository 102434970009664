import { FC, ReactNode, useEffect, useMemo, useState } from 'react';

export type DropdownValue = string | number | boolean

export interface IDropdownOptions {
  label: string | ReactNode;
  value: DropdownValue
}

export interface IDropdown {
  value: DropdownValue
  options: IDropdownOptions[]
  onChange: (v: DropdownValue) => void
  field: string
}

const Dropdown: FC<IDropdown> = ({ value, options, onChange, field }) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const displayedValue = useMemo(() => (
    options.find((item) => (item.value === value))?.label ?? ''
  ), [value]);

  const onClickDropdown = () => {
    setIsOpenDropdown((c) => (!c));
  };

  const onClickDropdownOption = (value: DropdownValue) => {
    onChange(value);
    setIsOpenDropdown(false);
  };

  useEffect(() => {
    window.onclick = (e) => {
      if (
        isOpenDropdown && !(
          e.target.matches('.dropdown-custom') ||
          e.target.matches('.dropdown-custom-trigger') ||
          e.target.matches('.dropdown-custom-options-container') ||
          e.target.matches('.dropdown-custom-option') ||
          e.target.matches('.dropdown-custom-arrow')
        )
      ) {
        setIsOpenDropdown(false);
      }
    };
  });

  return (
    <div className="dropdown-custom relative text-[#495057] select-none">
      <div
        className={`dropdown-custom-trigger ${field === 'Slot' ? 'h-[80px] min-w-[210px]' : 'h-[46px] min-w-[180px]'} bg-white flex justify-between items-center text-left p-3 rounded-md border border-[#ced4da] hover:border-[#6366F1] focus:border-[#6366F1] cursor-pointer`}
        onClick={onClickDropdown}
      >
        {displayedValue}
        <i className="pi pi-fw pi-chevron-down dropdown-custom-arrow" />
      </div>
      {isOpenDropdown && (
        <ul
          className={`z-[99] dropdown-custom-options-container absolute ${field === 'Slot' ? 'top-[84px]' : 'top-[50px]'} w-full py-3 bg-white rounded-md shadow-[0_2px_12px_0_rgba(0,0,0,0.1)] max-h-72 overflow-scroll`}
        >
          {options.map((item, index) => {
            return (
              <li
                key={`item-${index + 1}`}
                className="dropdown-custom-option py-3 px-5 hover:bg-[#e9ecef] cursor-pointer"
                onClick={() => onClickDropdownOption(item.value)}
              >
                {item.label}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
