import React, { Fragment, ReactElement } from 'react';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import { Button } from 'primereact/button';
import useUserRoles from './hooks';
import IconDot from '@/assets/IconDot';
import EditRoleSetting from './Components/EditRoleSetting';
import DetailPermission from './Components/DetailPermission';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import CreateRoleSetting from './Components/CreateRoleSetting';
import SettingRolePermissions from './Components/SettingRolePermissions';
import CreateFeaturePermission from './Components/CreateFeaturePermission';
import { Toast } from 'primereact/toast';
import Table from '@/components/base/DataTable/DataTable';
import { usePermissionStore } from '@/store/usePermissions';
import { IPagination } from '@/components/base/Pagination';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

const UserRoles = () => {
  const {
    data: {
      toast,
      dataUserRoles,
      displayDetailRole,
      dataPermissionsSetting,
      dataMenusPermission,
      isLoading,
      displayEdit,
      displayCreate,
      newCreateData,
      displaySettingPermission,
      displayCreatePermission,
      filterHistory,
      paginator,
      perPage,
      totalPages,
      totalRecords,
      itemFilters,
      search
    },
    methods: {
      handleFilter,
      setNewCreateData,
      setDisplayDetailRole,
      onClickDetailPermission,
      onClickSettingPermission,
      onClickEdit,
      setDisplayEdit,
      onChangeValueEdit,
      handleSubmitEdit,
      setDisplayCreate,
      onChangeValueCreate,
      handleSubmitRole,
      setDisplaySettingPermission,
      onUpdateCheckboxSettingEdit,
      onSubmitUpdateCheckboxEdit,
      setDisplayCreatePermission,
      onUpdateCheckboxPermission,
      onSubmitCreatePermission,
      handleClearFilter,
      refetchTeamId,
      handleDeleteFilterHistory,
      handleChangeDropdownPage,
      handleClickNext,
      handleClickPrev,
      handleJumpToPage,
      handleChangeJumpTopage,
      handleClickSubmitFilter,
      handleSearch
    }
  } = useUserRoles();

  const statusBodyTemplate = (data): ReactElement => {
    return (
      <div className="flex gap-x-3 justify-center items-center">
        {data.status ? (
          <>
            <IconDot fill="#39ac76" />
            <span>Active</span>
          </>
        ) : (
          <>
            <IconDot fill="#ef4444" />
            <span>Inactive</span>
          </>
        )}
      </div>
    );
  };

  const elementAction = (rowData) => {
    return (
      <div className="w-full flex justify-center gap-3">
        <Button label="Detail" disabled={!canView} outlined onClick={() => onClickDetailPermission(rowData)} />
        <Button label="Set Permission" disabled={!canEdit} outlined onClick={() => onClickSettingPermission(rowData)} />
      </div>
    );
  };

  const header = [
    { field: 'id', header: 'ID' },
    { field: 'name', header: 'Role Setting Name' },
    { field: 'created_at', header: 'Created Date' },
    { field: 'status', header: 'Status', body: statusBodyTemplate },
    { field: '', header: 'Action', body: elementAction, sortable: false }
  ];
  const { canCreate, canView, canEdit } = usePermissionStore();

  const paginationOptions: IPagination = {
    perPage: perPage,
    totalPages: totalPages,
    currentPage: paginator.currentPage,
    disabledDropdown: totalRecords === 0,
    onChangeDropdownPage: handleChangeDropdownPage,
    onClickPrev: handleClickPrev,
    onClickNext: handleClickNext,
    onJumptoPage: handleJumpToPage,
    onChangeJumptoPage: handleChangeJumpTopage
  };

  const filterContent = (
    <div className="flex flex-col gap-4 w-[900px]">
      <div className="w-full flex flex-col items-start gap-2">
        <h1 className="font-bold">ID</h1>
        <div className="flex w-full gap-8">
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Dari</label>
            <InputNumber
              onChange={(e) => handleFilter(e, 'id_from')}
              value={itemFilters.id_from}
              name="id_from"
            />
          </div>
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Sampai</label>
            <InputNumber
              onChange={(e) => handleFilter(e, 'id_to')}
              value={itemFilters.id_to}
              name="id_to"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Role Setting Name</label>
          <InputText
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
            onChange={(e) => handleFilter(e, 'role_name')}
            value={itemFilters.role_name}
            name="role_name"
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-status" className="font-bold">Created Date</label>
          <Calendar
            onChange={(e) => handleFilter(e, 'created_at')}
            value={itemFilters.created_at}
            name="created_at"
            className="w-full"
            selectionMode="range"
            showIcon
          />
        </div>
      </div>
      <div className="flex w-full items-center gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-status" className="font-bold">Status</label>
          <Dropdown
            onChange={(e) => handleFilter(e, 'status')}
            value={itemFilters.status}
            name="status"
            placeholder="Pilih Status"
            options={[{ label: 'Active', value: 'Active' }, { label: 'Inactive', value: 'Inactive' }]}
            className="w-full"
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <BaseContainer>
        <Toast ref={toast} />
        <LoadingOverlay show={isLoading} />
        <div className="flex justify-between">
          <MainTitle title="BOS Account Type Setting" />
          <Button id="user-create-role" label="Add Role Setting" disabled={!canCreate} onClick={() => setDisplayCreate(true)} outlined />
        </div>
        <Table
          value={dataUserRoles}
          filter={search}
          headerTable={header}
          onDeleted={() => {}}
          onSearch={handleSearch}
          isDetailAction={false}
          isDeleteAction={false}
          isEditAction={false}
          isCustomAction
          isLoadingTable={isLoading}
          paginationOptions={paginationOptions}
          filterContent={filterContent}
          filterHistory={filterHistory}
          handleClearFilter={handleClearFilter}
          handleDeleteFilterHistory={handleDeleteFilterHistory}
          onSubmitFilter={handleClickSubmitFilter}
          rows={totalRecords}
        />
        {displayDetailRole ? (
          <DetailPermission
            handleEdit={canEdit ? onClickEdit : ()=>{}}
            isLoading={isLoading}
            dataFeaturePermissions={dataMenusPermission}
            description={dataPermissionsSetting?.description ? dataPermissionsSetting?.description : ''}
            roleName={dataPermissionsSetting?.name}
            id={dataPermissionsSetting?.id}
            onCloseFilterModal={() => setDisplayDetailRole(!displayDetailRole)}
          />
        ) : null}
        {displayEdit ? (
          <EditRoleSetting
            roleName={dataPermissionsSetting?.name}
            id={dataPermissionsSetting?.id}
            description={dataPermissionsSetting?.description}
            onCloseEditRole={() => setDisplayEdit(false)}
            onChangeData={onChangeValueEdit}
            handleSubmit={() => handleSubmitEdit()}
            loading={isLoading}
          />
        ) : null}
        {displayCreate ? (
          <CreateRoleSetting
            roleName={newCreateData?.name}
            id={newCreateData?.id}
            description={newCreateData?.description}
            onChangeData={onChangeValueCreate}
            onCloseCreateRole={() => {
              setNewCreateData((prevState) => {
                return {
                  ...prevState,
                  name: '',
                  description: ''
                };
              });
              refetchTeamId();
              setDisplayCreate(false);
            }}
            handleSubmit={() => handleSubmitRole()}
            loading={isLoading}
          />
        ) : null}
        {displaySettingPermission ? (
          <SettingRolePermissions
            isLoading={isLoading}
            dataFeaturePermissions={dataMenusPermission}
            roleName={dataPermissionsSetting?.name}
            id={dataPermissionsSetting?.id}
            onCloseFilterModal={() => setDisplaySettingPermission(false)}
            onUpdateCheckbox={onUpdateCheckboxSettingEdit}
            onSubmitUpdateCheckbox={onSubmitUpdateCheckboxEdit}
          />
        ) : null}
        {/* create new role and new menu permission */}
        {displayCreatePermission ? (
          <CreateFeaturePermission
            isLoading={isLoading}
            dataFeaturePermissions={dataMenusPermission}
            roleName={newCreateData?.name}
            id={newCreateData?.id}
            onCloseFilterModal={() => setDisplayCreatePermission(false)}
            onUpdateCheckbox={onUpdateCheckboxPermission}
            onSubmitUpdateCheckbox={onSubmitCreatePermission}
          />
        ) : null}
      </BaseContainer>
    </>
  );
};

export default UserRoles;
