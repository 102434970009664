import { useEffect, useState } from 'react';

export function useDebounce<T>(value: T, delay = 500): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const toTitleCase = (string: string) => {
  const result = string.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export const toGram = (weight: number) => {
  return weight * 1000;
};

export const getKeyByValue = (object: Object, value: string | number): string | undefined => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const getKeysByValue = (object: Object, value: string | number): string[] => {
  return Object.keys(object).filter((key) => object[key] === value);
};

export const getValueStatus = (status: string) => {
  return status.toLowerCase() === 'enable';
};

export const accountTypeOptions = [
  { code: 'BOS_LEAD', label: 'Lead' },
  { code: 'BOS_ADMIN', label: 'Admin' },
  { code: 'BOS_USER', label: 'User' },
  { code: 'BOS', label: 'BOS' }
];

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};
