import React from 'react';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import { useCustom } from './hooks';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Tree } from 'primereact/tree';
import { Button } from 'primereact/button';
import IconShow from '@/assets/IconShow';
import IconPencil from '@/assets/IconPencil';
import IconTrash from '@/assets/IconTrash';
import IconCheck from '@/assets/IconChecklist';
import IconUncheck from '@/assets/IconUnchek';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { TabPanel, TabView } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import ModalUploadCategory from '@/components/base/ModalUpload';
import { usePermissionStore } from '@/store/usePermissions';
import Pagination from '@/components/base/Pagination';
import { Divider } from 'primereact/divider';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import FilterHistory from '@/components/base/FilterHistory';

const Categories = () => {
  const { data, method } = useCustom();
  const { canCreate, canEdit, canDelete, canView } = usePermissionStore();

  const dropdownOptions = [
    { label: <Link to="create"><div className="px-5 py-3">Tambah Kategori</div></Link>, value: 'Tambah Kategori' },
    { label: <div className="px-5 py-3">Tambah Sekaligus</div>, value: 'Tambah Sekaligus' }
  ];

  const instructionTemplate = [
    {
      title: 'Download & ubah File Excel',
      subtitle:
        'Template hanya bisa diisi dengan MS. Excel 2007 ke atas atau Libre Office. Jangan menambah kolom atau baris apa pun di file Excel.'
    },
    {
      title: 'Upload File Excel',
      subtitle: 'Pilih atau letakkan file Excel (.xlsx) kamu di sini, maks. 100 Kategori Baru per File.'
    }
  ];

  const actionTree = (node, index) => {
    return (
      <div className="flex justify-between w-full items-center">
        <h1><span>(ID: {node.id}) </span>{node.name} ({node.count})</h1>
        <div className="flex gap-2 items-center">
          <Button disabled={!canEdit} icon={node.status === 'ACTIVE' ? IconCheck : IconUncheck} severity={node.status === 'ACTIVE' ? 'success' : 'danger'} />
          <Dropdown disabled={!canCreate} placeholder="+" onChange={(e) => method.onCreateCategory(e.value)} options={dropdownOptions} optionLabel="label" className="w-16" />
          <Link to={canView ? `${node.id}` : ''}>
            <Button disabled={!canView} icon={IconShow} />
          </Link>
          <Link to={canEdit ? `edit/${node.id}` : ''}>
            <Button disabled={!canEdit} icon={IconPencil} severity="secondary" />
          </Link>
          <Button disabled={!canDelete} icon={IconTrash} severity="danger" onClick={() => method.onConfirmDelete(node.id)} />
        </div>
      </div>
    );
  };

  const filterContent = (
    <div className="flex flex-col gap-4 w-[900px]">
      <div className="w-full flex flex-col items-start gap-2">
        <h1 className="font-bold">ID</h1>
        <div className="flex w-full gap-8">
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Dari</label>
            <InputNumber
              onChange={(e) => method.handleFilter(e, 'id_from')}
              value={data.itemFilters.id_from}
              name="id_from"
            />
          </div>
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Sampai</label>
            <InputNumber
              onChange={(e) => method.handleFilter(e, 'id_to')}
              value={data.itemFilters.id_to}
              name="id_to"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Category Name</label>
          <InputText
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
            onChange={(e) => method.handleFilter(e, 'name')}
            value={data.itemFilters.name}
            name="name"
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Status</label>
          <Dropdown
            options={[{ label: 'ACTIVE', value: 'ACTIVE' }, { label: 'INACTIVE', value: 'INACTIVE' }]}
            onChange={(e) => method.handleFilter(e, 'status')}
            value={data.itemFilters.status}
            placeholder="Select Status"
          />
        </div>
      </div>
    </div>
  );

  const footerContent = (
    <div className="flex justify-end py-4 gap-4">
      <Button label="Batal" severity="secondary" outlined onClick={() => method.setIsOpenDialog(false)} size="large" />
      <Button label="Terapkan" severity="info" onClick={method.handleClickSubmitFilter} size="large" />
    </div>
  );

  return (
    <BaseContainer>
      <MainTitle title="Category" nav="/category/create" isWithButton={canCreate} labelButton="Create Category" />
      <TabView>
        <TabPanel header="Kategori AladinMall">
          <Toast ref={data.toast} />
          <div>
            <div className="flex justify-end items-center gap-4">
              <Button
                icon="pi pi-sliders-h"
                onClick={() => method.setIsOpenDialog(true)}
                label="Filter"
                severity="secondary"
                text
                raised
              />
              <Button onClick={method.handleReset} label="Reset" severity="secondary" outlined />
              <Button onClick={method.updatePositions} label="Ubah Urutan Kategori" severity="secondary" outlined />
            </div>
            {data.filterHistory && data.filterHistory.length > 0 ? (
              <FilterHistory
                filterHistory={data.filterHistory}
                handleClearFilter={method.handleClearFilter}
                handleDeleteFilterHistory={method.handleDeleteFilterHistory}
                show={data.filterHistory?.length > 0}
              />
            ) : (
              <Divider className="p-divider-solid " />
            )}
            <div className="flex justify-between my-4">
              <div id="search" className="p-input-icon-right w-[20%]">
                <InputText
                  className="w-full"
                  placeholder="Cari Disini"
                  type="search"
                  onChange={method.handleSearch}
                  keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
                  value={data.search}
                />
                <i className="pi pi-search" />
              </div>
              <div className="flex items-center justify-end gap-6 w-full">
                <h4 className="font-bold justify-self-end"> {data.totalRecords} data ditemukan</h4>
                <Pagination
                  perPage={data.rows}
                  totalPages={data.totalPages}
                  currentPage={data.paginator.currentPage}
                  disabledDropdown={data.totalRecords === 0}
                  onClickPrev={method.handleClickPrev}
                  onClickNext={method.handleClickNext}
                  onChangeDropdownPage={method.handleChangeDropdownPage}
                />
              </div>
            </div>
            <div className="flex flex-row">
              <p
                className="font-semibold cursor-pointer"
                onClick={() => {
                  method.expandAll();
                }}
              >
                Tampilkan Semua
              </p>
              <p className="font-semibold ml-2 mr-2">/</p>
              <p
                className="font-semibold cursor-pointer"
                onClick={() => {
                  method.collapseAll();
                }}
              >
                Tutup Semua
              </p>
            </div>
            <div className="card">
              <Tree
                onToggle={(e) => method.setExpandedKeys(e.value)}
                expandedKeys={data.expandedKeys}
                value={data.dataTreeCategory}
                loading={data.isLoading}
                selectionMode="single"
                dragdropScope="true"
                onDragDrop={method.handleDragDrop}
                nodeTemplate={actionTree}
              />
            </div>
          </div>
        </TabPanel>
      </TabView>
      <ConfirmDialog />
      <ModalUploadCategory
        handleClickDownload={method.handleClickDownload}
        handleChangeFileUpload={method.handleChangeFileUpload}
        handleClickUpload={method.handleClickUpload}
        disabledInsert={false}
        fileUploadRef={data.fileUploadRef}
        show={data.isShowModal}
        handleCloseModal={method.handleToggleModal('create')}
        modalTitle={data.uploadType === 'create' ? 'Tambah Sekaligus Kategori' : 'Ubah Sekaligus Kategori'}
        placeholder="Drag and drop to upload file or click button choose"
        instruction={instructionTemplate}
      />
      <Dialog
        onHide={() => method.setIsOpenDialog(false)}
        visible={data.isOpenDialog}
        header="Semua Filter"
        footer={footerContent}
      >
        {filterContent}
      </Dialog>
    </BaseContainer>
  );
};

export default Categories;
