import React, { FC } from 'react';
import { IBannerOptions } from '../../hooks';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { HeaderBanner } from '@/components/base/Banner/HeaderBanner';
import { Carousel } from '@/components/base/Banner/Carousel';
import { PromoAdsBanner } from '@/components/base/Banner/PromoAdsBanner';
import { SquareCarousel } from '@/components/base/Banner/SquareCarousel';
import { Image } from 'primereact/image';
import { BrandBanner } from '@/components/base/Banner/BrandBanner';
import {
  bannerBgVideo,
  bannerCategory,
  bannerFlashsale,
  bannerHotDeals,
  bannerMain,
  bannerPopup,
  bannerPromoAds,
  bannerPromoHeader,
  bannerPromoPage,
  bannerSquareCarousel,
  bannerStreet,
  brandBanner,
  brandBannerLeft,
  brandBannerRight
} from '@/assets/banner';
import { MenuCategory } from '@/components/base/Banner/MenuCategory';

interface IDropdownBannerTypeProps {
  options: IBannerOptions[];
  onChage: (value: IBannerOptions) => () => void;
  value: IBannerOptions;
  handleHoverOptions: (label: string | null, value: string | null) => () => void;
  previewType: IBannerOptions;
  className?: string;
  show?: boolean;
  onClickToggleDropdown: () => void;
  isDisable?: boolean
}
export const DropdownBannerType: FC<IDropdownBannerTypeProps> = ({
  onChage,
  handleHoverOptions,
  options,
  value,
  className,
  previewType,
  onClickToggleDropdown,
  show,
  isDisable
}) => {
  const renderPreviewOptions = () => {
    switch (previewType.code) {
    case 'HEADER_BANNER':
      return <HeaderBanner altText="promoHeader" imgSrc={bannerPromoHeader} />;
    case 'MAIN_BANNER':
      return <Carousel images={Array.from({ length: 4 }).map(() => bannerMain)} />;
    case 'ADS_BANNER':
      return <PromoAdsBanner images={Array.from({ length: 4 }).map(() => bannerPromoAds)} />;
    case 'OPTIONAL_PRODUCT_BANNER':
      return <SquareCarousel images={Array.from({ length: 4 }).map(() => bannerSquareCarousel)} size={200} />;
    case 'BRAND_BANNER':
      return (
        <BrandBanner
          centerImage={Array.from({ length: 4 }).map(() => brandBanner)}
          leftImage={brandBannerLeft}
          rightImage={brandBannerRight}
        />
      );
    case 'HOT_DEAL_BANNER':
      return <Image width="200" src={bannerHotDeals} />;
    case 'PRODUCT_CATEGORY_BANNER':
      return <MenuCategory images={bannerCategory} />;
    case 'PROMO_BANNER':
      return <Image src={bannerPromoPage} />;
    case 'FLASH_SALE_BANNER':
      return <Image src={bannerFlashsale} />;
    case 'OFFICIAL_STORE_BANNER':
      return <SquareCarousel images={Array.from({ length: 4 }).map(() => bannerSquareCarousel)} size={200} />;
    case 'STREET':
      return <Image src={bannerStreet} />;
    case 'SECTION_BACKGROUND':
      return <Image src={bannerBgVideo} />;
    case 'POPUP_BANNER':
      return <Image src={bannerPopup} />;
    }
  };

  return (
    <div className={`relative ${className}`}>
      <button
        disabled={isDisable}
        type="button"
        className="w-full bg-white p-3 flex items-center justify-between border border-gray-300 rounded-md text-gray-500"
        onClick={onClickToggleDropdown}
      >
        {value.label || 'Select Type'} <ChevronDownIcon width={15} />
      </button>
      {show && (
        <div className="absolute left-0 top-full z-10 flex rounded-md overflow-hidden border">
          <div className="bg-white max-h-[360px] overflow-y-scroll no-scrollbar overflow-hidden">
            {options?.map((option, index) => {
              return (
                <option
                  onClick={onChage(option)}
                  className="p-4 hover:bg-slate-200 cursor-pointer"
                  key={index}
                  value={String(option.code)}
                  onMouseEnter={handleHoverOptions(option.label, option.code)}
                >
                  {option.label}
                </option>
              );
            })}
          </div>
          <div className="flex-1 w-[400px] bg-gray-50 p-4 flex border-l justify-center items-center overflow-hidden">
            {renderPreviewOptions()}
          </div>
        </div>
      )}
    </div>
  );
};
