import React from 'react';

export default function ApiManagement() {
  const date = new Date(import.meta.env.VITE_GIT_COMMIT_DATE);
  return (
    <div style={{ color: 'black' }}>
      <div>
        <p>Date: {date.toLocaleString()}</p>
        <p>Last Commit Message: {import.meta.env.VITE_GIT_LAST_COMMIT_MESSAGE}</p>
        <p>Branch Name: {import.meta.env.VITE_GIT_BRANCH_NAME}</p>
        <p>Commit Long Hash: {import.meta.env.VITE_GIT_COMMIT_HASH}</p>
      </div>
    </div>
  );
};
