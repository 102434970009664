import React from 'react';

interface IconDotInterface {
  fill?: string;
  width?: string;
  height?: string;
}

const IconDot = ({ fill = '#0A0A0A', height = '12', width = '12' }: IconDotInterface) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6" cy="6" r="6" fill={fill} />
    </svg>
  );
};

export default IconDot;
