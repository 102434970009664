import * as yup from 'yup';
import { MESSAGE } from '@/constants';

const schemaFlashSale = yup.object({
  name: yup.string().required(MESSAGE.REQUIRED),
  description: yup.string().required(MESSAGE.REQUIRED),
  status: yup.string().required(MESSAGE.REQUIRED),
  periode: yup.string().required(MESSAGE.REQUIRED),
  valid_from: yup.string().required(MESSAGE.REQUIRED),
  valid_to: yup.string().required(MESSAGE.REQUIRED),
  slot_type: yup.string().required(MESSAGE.REQUIRED)
});

export { schemaFlashSale };
