import { CheckRoundIcon, DangerPlainIcon, Modal, TabBar } from 'akeneo-design-system';
import React, { useCallback } from 'react';
import useAddCategory, { CategoryContext } from './hooks';
import TabAttributeSet from './components/TabAttributeSet';
import TabGeneral from './components/TabGeneral';
import TabProduct from './components/TabProduct';
import './style.css';
import dompurify from 'dompurify';

const AddCategory = () => {
  const { data, methods } = useAddCategory();
  const { stepAddCategory, modalInfoCategory, isFormGeneralValid } = data;
  const { onSubmitFormCategory, closeModalInfoCategory, goToTabGeneral, goToTabProduct, goToTabAttributeSet } = methods;
  const renderTabContent = () => {
    switch (stepAddCategory) {
    case 1:
      return <TabProduct />;
    case 2:
      return <TabAttributeSet />;
    default:
      return <TabGeneral />;
    }
  };

  const categoryContextProvider = useCallback(
    () => ({
      data,
      methods
    }),
    [data, methods]
  );

  return (
    <div id="add-category">
      <div id="buttons-action" className="w-full flex justify-end gap-6">
        <button type="button" id="button-action-cancel" className="px-10 py-3 bg-[#F1F2F4] rounded-md text-dark-electric-blue">
          Batal
        </button>
        <button
          type="button"
          id="button-action-save"
          onClick={onSubmitFormCategory}
          className="px-10 py-3 bg-azure rounded-md text-white"
        >
          Simpan
        </button>
      </div>
      <div id="tabs-category">
        <TabBar moreButtonTitle="More" className="tabs-container">
          <TabBar.Tab id="tab-general" isActive={stepAddCategory === 0} onClick={goToTabGeneral}>
            General
          </TabBar.Tab>
          {isFormGeneralValid && (
            <TabBar.Tab id="tab-product" isActive={stepAddCategory === 1} onClick={goToTabProduct}>
              Product (0)
            </TabBar.Tab>
          )}
          {isFormGeneralValid && (
            <TabBar.Tab id="tab-attribute-set" isActive={stepAddCategory === 2} onClick={goToTabAttributeSet}>
              Attribute Set
            </TabBar.Tab>
          )}
        </TabBar>
      </div>
      <CategoryContext.Provider value={categoryContextProvider}>{renderTabContent()}</CategoryContext.Provider>
      {modalInfoCategory.isOpen && (
        <Modal
          id="modal-info-category"
          className="modal-info-category"
          onClose={closeModalInfoCategory}
          closeTitle="close"
        >
          <div className="py-4 px-4 flex flex-col gap-8 w-[550px] font-sans text-base">
            <div className="w-full text-left">
              <p className="text-black font-medium text-xl" id="title-modal-info">
                {modalInfoCategory.title}
              </p>
            </div>
            <div className="flex gap-4 text-black ">
              <div className="w-max" id="illustration-modal-info">
                {modalInfoCategory.illustration === 'success' ? (
                  <CheckRoundIcon color="#009045" size={80} />
                ) : (
                  <DangerPlainIcon color="#DC424D" size={80} />
                )}
              </div>
              <p
                id="description-modal-info"
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(modalInfoCategory.description)
                }}
              />
            </div>
            <div className="w-full flex items-center justify-center">
              <button
                type="button"
                id="button-close-modal-info"
                className="py-2 px-20 bg-azure rounded-md text-white text-xl font-medium"
                onClick={closeModalInfoCategory}
              >
                <p className="font-sans">OK</p>
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AddCategory;
