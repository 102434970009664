import { Specifications } from '@/models/Specification';
import { KeyboardEvent, SyntheticEvent, useCallback, useMemo, useState } from 'react';


export const useCustom = () => {
  const [dataSpecification, setDataSpecification] = useState<Specifications>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>();
  const [inputValue, setInputValue] = useState<string>('');
  const [listFilter, setListFilter] = useState<string[]>([]);
  const [perPage, setPerPage] = useState<number>(5);
  const [isOpenDropDown, setIsOpenDropdown] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isOpenListAdd, setIsOpenListAdd] = useState<boolean>();
  const [orderBy, setOrderBy] = useState<'asc' | 'desc'>('desc');

  const [isLoading] = useState<boolean>(false);
  const [totalPage] = useState<number>(10);

  const dropdownName = useMemo(() => {
    return [...new Set(dataSpecification.map((item) => item.name))];
  }, [dataSpecification]);
  const dropdownDescription = useMemo(() => {
    return [...new Set(dataSpecification.map((item) => item.description))];
  }, [dataSpecification]);
  const dropdownStatus = useMemo(() => {
    return [...new Set(dataSpecification.map((item) => item.status))];
  }, [dataSpecification]);
  const dropdownLabel = useMemo(() => {
    return [...new Set(dataSpecification.map((item) => item.label))];
  }, [dataSpecification]);
  const dropdownType = useMemo(() => {
    return [...new Set(dataSpecification.map((item) => item.type))];
  }, [dataSpecification]);

  const handleEnterSearch = useCallback(({ key }: KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter' || key === 'Tab') {
      //Todo: set search value when integration
    }
  }, []);

  const handleClickHeader = useCallback(() => {
    orderBy === 'desc' ? setOrderBy('asc') : setOrderBy('desc');
  }, [orderBy, setOrderBy]); //Todo: handle table header sorting when inegration

  const handleClickPrev = useCallback(() => {
    currentPage > 1 && setCurrentPage(currentPage - 1);
  }, [setCurrentPage, currentPage]);

  const handleClickNext = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [setCurrentPage, currentPage]);

  const handleClickDropdown = useCallback(
    ({ currentTarget }: SyntheticEvent<Element, Event>) => {
      const filter = listFilter;
      if (!listFilter.includes(currentTarget.id)) {
        filter.push(currentTarget.id);
        setListFilter([...filter]);
      } else {
        const index = listFilter.findIndex((i) => i === currentTarget.id);
        filter.splice(index, 1);
        setListFilter([...filter]);
      }
    },
    [setListFilter, listFilter]
  );

  const handleClickOpenListAdd = useCallback(() => {
    setIsOpenListAdd(!isOpenListAdd);
  }, [isOpenListAdd, setIsOpenListAdd]);

  const handleClickOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const handleOnChange = useCallback(
    (value: string) => {
      setInputValue(value);
    },
    [setInputValue, inputValue]
  );

  const handleSelect = (value: number) => {
    setPerPage(value);
  };

  const handleClosePopUp = useCallback(() => {
    setIsError(!isError);
  }, [isError, setIsError]);

  const handleClickDelete = useCallback(
    (id: number) => {
      const data = dataSpecification;
      const index = dataSpecification.findIndex((item) => item.id === id);
      data.splice(index, 1);
      setDataSpecification([...dataSpecification]);
    },
    [setDataSpecification, dataSpecification]
  );
  const handleClickPaginationDropdown = useCallback(() => {
    setIsOpenDropdown(!isOpenDropDown);
  }, [setIsOpenDropdown, isOpenDropDown]);
  const handleOnChangePagination = useCallback(() => {}, []);

  return {
    data: {
      isOpen,
      isOpenListAdd,
      dropdownName,
      dropdownDescription,
      dropdownStatus,
      listFilter,
      currentPage,
      dataSpecification,
      inputValue,
      isLoading,
      isError,
      perPage,
      isOpenDropDown,
      orderBy,
      totalPage,
      dropdownType,
      dropdownLabel
    },
    methods: {
      handleClickOpen,
      handleClickOpenListAdd,
      handleClickDropdown,
      handleEnterSearch,
      handleOnChange,
      handleSelect,
      handleClickPrev,
      handleClickNext,
      handleClickHeader,
      handleClickDelete,
      handleClosePopUp,
      handleOnChangePagination,
      handleClickPaginationDropdown
    }
  };
};
