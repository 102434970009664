import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { useAllAttributeSetById, useAllAttributeSets, useGetSpecificationByid, useGetVariantsByid } from '@/services/rest/attributeSet';
import { useCategorySpec, useSpecification } from '@/services/rest/specification';
import { useAllVariants, useCategoryVariants } from '@/services/rest/variant';
import { PostgrestMaybeSingleResponse } from '@supabase/supabase-js';
import { concat, debounce, uniqBy } from 'lodash';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { schemaEditCategory } from './validation';
import { FileUpload } from 'primereact/fileupload';
import { storageUpload } from '@/utils/storage';

interface IDataEditCategory {
  description: string;
  id: number;
  isSelectable?: boolean | null;
  name: string;
  position: number | null;
  parent_id: number | null;
  status: boolean;
  metaTitle: string;
  metaKeyword?: string | null;
  metaDescription?: string | null;
  slug?: string;
  mediaUrl?: string | null;
  images: {
    data: {
      image: string;
    };
  };
  count: number;
}

interface IParentID {
  id: number;
  name: string;
}
interface IProducts {
  id: string;
  name: string;
  sku: string;
  status: string;
  main_price: number;
  merchantId: string;
  category_id: number;
}
interface EIProduct extends IProducts {
  specialPrice: number;
}
interface ICategoryVar {
  variants: IVariantID;
}
interface ICategorySpec {
  specifications: ISpecificationID;
}
interface ISpecificationID {
  id: number;
  name?: string;
}
interface IVariantID {
  id: number;
  name?: string;
}
interface IAttributeSetsID {
  id: number;
  name: string;
}
interface Filter {
  idFrom: null,
  idTo: null,
  priceFrom: null,
  priceTo: null,
  specialPriceTo: null,
  specialPriceFrom: null,
  productName: string,
  sku: string,
  status: boolean | null,
  statusProduct: string
}
const initailFormik = {
  maxImageCategory: 0
};

export const useCustom = () => {
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const param = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [seoPage, setSeoPage] = useState<boolean>(true);
  const [checked, setChecked] = useState<boolean>(false);
  const [urlKey, setUrlKey] = useState<string>('');
  const [isFollowCategoryName, setIsFollowCategoryName] = useState<boolean>(true);
  const [metaTitle, setMetaTitle] = useState<string>('');
  const [metaKeywords, setMetaKeywords] = useState<string>('');
  const [metaDescription, setMetaDescription] = useState<string>('');
  const [rangeSpecification, setRangeSpecification] = useState<ISpecificationID[]>([]);
  const [rangeVariant, setRangeVariant] = useState<IVariantID[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedChangeAllParent, setSelectedChangeAllParent] = useState<IParentID | null>();
  const [selectedAttributeSets, setSelectedAttributeSets] = useState<IAttributeSetsID | null>();
  const [attributSetId, setAttributSetId] = useState<string>('');
  const [currentCategory, setCurrentCategory] = useState<IParentID>();
  const [popUpChangeCategory, setPopUpChangeCategory] = useState<boolean>(false);
  const [popUpChangeSucces, setPopUpChangeSucces] = useState<boolean>(false);
  const [selectedParent, setSelectedParent] = useState<IParentID | null>();
  const [popUpDetail, setPopUpDetail] = useState<boolean>(false);
  const [filterModal, setFilterModal] = useState<boolean>(false);
  const [filters, setFilters] = useState<string>('');
  const [popUpChangeAll, setPopUpChangeAll] = useState<boolean>();
  const [popUpChangeFailed, setPopUpChangeFailed] = useState<boolean>(false);
  const { data: attributSetbyId } = useAllAttributeSetById(attributSetId);
  const { data: variantsById } = useGetVariantsByid(attributSetbyId?.data?.variant_ids);
  const { data: attributSpecById } = useGetSpecificationByid(attributSetbyId?.data?.specification_ids);
  const [foundVariant, setFoundVariant] = useState<{ id: number | string, name?: string }[]>([]);
  const [foundSpec, setFoundSpec] = useState<{ id: number | string, name?: string }[]>([]);
  const [dataStatus, setDataStatus] = useState<{ label: string, code: boolean | null }>({ label: '', code: null });
  const [dataStatusProduct, setDataStatusProduct] = useState({ label: '' });
  const [message, setMessage] = useState<string>('');
  const [merchantName, setMerchantName] = useState<string>('');
  const [dataParentID, setDataParentID] = useState<IParentID[]>();
  const [subParentIds, setSubParentIds] = useState<Array<number>>([]);
  const [childIds, setChildIds] = useState<Array<number>>([]);
  const [isLoadingProductParent, setIsLoadingProductParent] = useState<boolean>(false);
  const [isLoadingProductSubParent, setIsLoadingProductSubParent] = useState<boolean>(false);
  const [isLoadingProductChild, setIsLoadingProductChild] = useState<boolean>(false);
  const [selectedDataParentID, setSelectedDataParentID] = useState<IParentID>();
  const fileUploadRef = useRef<FileUpload>(null);
  const [params, setParams] = useSearchParams();
  const initialFilter = {
    idFrom: null,
    idTo: null,
    priceFrom: null,
    priceTo: null,
    specialPriceTo: null,
    specialPriceFrom: null,
    productName: '',
    sku: '',
    status: null,
    statusProduct: ''
  };
  const [modalFilter, setModalFilter] = useState<Filter>(initialFilter);
  const [dataEditCategory, setDataEditCategory] = useState<IDataEditCategory>({
    id: 0,
    parent_id: 0,
    name: '',
    description: '',
    position: 0,
    status: false,
    images: { data: { image: '' } },
    metaTitle: '',
    count: 0
  });
  const [dataChangeCategory, setDataChangeCategory] = useState<IProducts>({
    id: '',
    name: '',
    sku: '',
    status: '',
    main_price: 0,
    merchantId: '',
    category_id: 0
  });

  // data product parent
  const [dataProduct, setDataProduct] = useState<EIProduct[]>([]);

  // data product subparent
  const [dataProductSubParent, setDataProductSubParent] = useState<EIProduct[]>([]);

  // data product children
  const [dataProductChildren, setDataProductChildren] = useState<EIProduct[]>([]);

  // data count product
  const [countData, setCountData] = useState<number>(0);
  const [countDataSubParent, setCountDataSubParent] = useState(0);

  const dataAllProduct = useMemo(() => {
    let concatArr = concat(dataProduct, dataProductSubParent, dataProductChildren);
    if (modalFilter.specialPriceFrom || modalFilter.specialPriceTo) {
      const dataAll = concatArr.filter((item)=> item.specialPrice != undefined);
      concatArr = dataAll;
    }
    return concatArr;
  }, [dataProduct, dataProductSubParent, dataProductChildren, modalFilter]);

  const coutAllData = useMemo(() => {
    let setCount = countData;
    if (subParentIds.length > 0 || childIds.length > 0) {
      setCount = countDataSubParent;
    }

    return setCount;
  }, [countData, countDataSubParent, subParentIds, childIds]);

  const isLoadingProduct = isLoadingProductParent || isLoadingProductSubParent || isLoadingProductChild;

  const showToastFailed = (msg) => {
    setIsLoading(false);
    toast.current?.show({ severity: 'error', summary: 'Error', detail: msg });
  };

  const formik = useFormik({
    initialValues: initailFormik,
    validationSchema: schemaEditCategory,
    onSubmit: () => {
      onSubmitEditValue();
    }
  });

  const getDataEditById = async () => {
    setIsLoading(true);
    const { data, error } = await supabase.from(TABLE.CATEGORIES).select('*').eq('id', param?.id).single();
    const { data: allCategory } = await supabase.rpc('catalogue.get_all_categories');

    const dataCategory = allCategory?.flatMap((i)=> {
      const children = i.child?.flatMap((c)=> [c, ...c?.child]);
      return [i, ...children];
    }).find((cat)=> cat.id === data.id);

    if (error) {
      return;
    }

    if (data) {
      // set to data category
      setDataEditCategory({
        id: data.id,
        name: data.name || '',
        description: data.description || '',
        images: { data: { image: data.images?.data?.image } } || { data: { image: '' } },
        parent_id: data.parent_id || 0,
        position: data.position || 0,
        status: data.status === 'ACTIVE',
        metaTitle: data.meta_title,
        metaKeyword: data.meta_keyword,
        metaDescription: data.meta_description,
        slug: data.slug || undefined,
        count: Number(dataCategory?.count) || 0
      });
      setIsFollowCategoryName(data.slug === null || data.slug === '');
      setChecked(!Boolean(data.parent_id));
    }
    setIsLoading(false);
  };

  const { data: dataAttributeSets } = useAllAttributeSets();
  const attributeSets = useMemo(() => {
    if (!Array.isArray(dataAttributeSets?.data)) return [];
    return dataAttributeSets?.data.map((item) => {
      return {
        id: item?.id || '',
        name: item?.name || ''
      };
    });
  }, [dataAttributeSets]);
  const dataSpecs = useMemo(() => {
    const data = attributSpecById?.data?.map((item) => {
      return {
        id: item.id,
        name: String(item.name)
      };
    });
    return data;
  }, [attributSpecById]);

  const dataVariants = useMemo(() => {
    const data = variantsById?.data?.map((item) => {
      return {
        id: item.id,
        name: String(item.name)
      };
    });
    return data;
  }, [variantsById]);

  const validateDataAttributSet: Boolean = useMemo(() => {
    return Boolean(dataVariants && dataVariants?.length > 0) || Boolean(dataSpecs && dataSpecs?.length > 0);
  }, [dataVariants]);

  const { data: dataSpecification } = useSpecification();
  const specifications = useMemo(() => {
    if (!Array.isArray(dataSpecification?.data)) return [];
    return dataSpecification?.data.map((item) => {
      return {
        id: item?.id || '',
        name: item?.name || ''
      };
    });
  }, [dataSpecification]);

  const { data: dataVariant } = useAllVariants();
  const variants = useMemo(() => {
    if (!Array.isArray(dataVariant?.data)) return [];
    return dataVariant?.data.map((item) => {
      return {
        id: item?.id || '',
        name: item?.name || ''
      };
    });
  }, [dataVariant]);

  // Handle search variant and specifications
  const findVariantDebounce = debounce(async (findName: string | null) => {
    if (!findName) {
      return;
    }

    const { data } = await supabase.from('catalogue.variants').select('id, name').ilike('name', `*${findName}*`).limit(10);

    if (data) {
      if (variants) {
        setFoundVariant((prev)=>{
          const newData = [...prev, ...data];
          const distinctData = [...new Set(newData.map((item)=> JSON.stringify(item)))];
          const parseDistinct = distinctData.map((item)=> JSON.parse(item));
          return parseDistinct;
        });
      } else {
        setFoundVariant(data);
      }
    }
  }, 300);

  const findVariant = async (findName: string | null) => {
    findVariantDebounce(findName);
  };

  const findSpecDebounce = debounce(async (findName: string | null) => {
    if (!findName) {
      return;
    }

    const { data } = await supabase.from('catalogue.specifications').select('id, name').is('deleted_at', null).ilike('name', `*${findName}*`).limit(10);

    if (data) {
      if (specifications) {
        setFoundSpec((prev)=>{
          const newData = [...prev, ...data];
          const distinctData = [...new Set(newData.map((item)=> JSON.stringify(item)))];
          const parseDistinct = distinctData.map((item)=> JSON.parse(item));
          return parseDistinct;
        });
      } else {
        setFoundSpec(data);
      }
    }
  }, 300);

  const findSpecificaiton = async (findName: string | null) => {
    findSpecDebounce(findName);
  };
  // Handle variant and specifications from category
  const findVariantByIdDebounce = debounce(async (findId: string | null) => {
    if (!findId) {
      return;
    }

    if (!foundVariant || foundVariant?.length <= 0) {
      const { data } = await supabase.from('catalogue.category_variants').select('variants(id, name)').eq('category_id', findId) as PostgrestMaybeSingleResponse<ICategoryVar[]>;

      if (data) {
        const variant = data.map((item)=> {
          return {
            id: item.variants.id,
            name: item.variants.name
          };
        });
        setFoundVariant(variant);
      };
    }
  }, 300);

  const findSpecByIdDebounce = debounce(async (findId: string | null) => {
    if (!findId) {
      return;
    }

    if (!foundSpec || foundSpec?.length <= 0) {
      const { data } = await supabase.from('catalogue.category_specifications').select('specifications(id, name)').eq('category_id', findId) as PostgrestMaybeSingleResponse<ICategorySpec[]>;

      if (data) {
        const spec = data.map((item)=> {
          return {
            id: item.specifications.id,
            name: item.specifications.name
          };
        });
        setFoundSpec(spec);
      };
    }
  }, 300);

  useEffect(()=> {
    if (param.id) {
      findVariantByIdDebounce(param.id);
      findSpecByIdDebounce(param.id);
    }
  }, [param.id]);
  const onChangeFilterValue = (e) => {
    if (e.target.value < 0) {
      return;
    }
    setModalFilter((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const onChangeStatusFilter = (e) => {
    setModalFilter((prevState) => ({ ...prevState, status: e.value.code }));
    setDataStatus(e.value);
  };

  const onChangeStatusProductFilter = (e) => {
    setModalFilter((prevState) => ({ ...prevState, statusProduct: e.value.label }));
    setDataStatusProduct(e.value);
  };

  const onDeleteFilter = (key: string) => {
    setModalFilter((prevState)=> ({ ...prevState, [key]: initialFilter[key] }));
    onSubmitFilterValue();
  };

  const onDeleteAllFilter = () => {
    setModalFilter(initialFilter);
    onSubmitFilterValue();
  };

  const handleFileUpload = useCallback(async (e) => {
    const file = e.files[0];
    if (file.size <= 50000) {
      if (file.type.includes('image/')) {
        const responsUploadPhoto = await storageUpload('web/', file);

        if (responsUploadPhoto) {
          setImageUrl(responsUploadPhoto.src);
        }
      } else {
        showToastFailed('Selected File is not an Image type');
      }
    } else {
      fileUploadRef.current?.clear();
    }
  }, [imageUrl, fileUploadRef]);

  const onChangeValue =
    (key: string, value: string | boolean) => {
      setDataEditCategory((prev) => {
        return {
          ...prev,
          [key]: value
        };
      });
    };

  const handleSelectedAttributeSets = useCallback((e: DropdownChangeEvent) => {
    setSelectedAttributeSets(e.value);
  }, []);

  const handleClickApply = useCallback(() => {
    setVisible(true);
  }, []);

  // Get data Variant and Specifications when add attribute set
  const handleAcceptDialog = useCallback(() => {
    setRangeSpecification([]);
    setRangeVariant([]);
    if (dataVariants) {
      setFoundVariant(dataVariants);
    }
    const variants: IVariantID[] =
      dataVariants?.map((item) => {
        return {
          id: Number(item?.id)
        };
      }) ?? [];
    setRangeVariant(variants);
    if (dataSpecs) {
      setFoundSpec(dataSpecs);
    }
    const specs: ISpecificationID[] =
      dataSpecs?.map((item) => {
        return {
          id: Number(item?.id)
        };
      }) ?? [];
    setRangeSpecification(specs);
  }, [dataVariants, dataSpecs, rangeSpecification, rangeVariant]);

  const addSpecification = useCallback(() => {
    const tempSpecification = { id: 0 };
    setRangeSpecification((prev) => [...prev, tempSpecification]);
  }, [rangeSpecification, setRangeSpecification]);

  const deleteSpecification = useCallback(
    (index: number) => {
      const arrayOfNumbers = rangeSpecification;
      arrayOfNumbers.splice(index, 1);
      setRangeSpecification([...arrayOfNumbers]);
    },
    [rangeSpecification, setRangeSpecification]
  );

  const addVariant = useCallback(() => {
    const tempVariant = { id: 0 };
    setRangeVariant((prev) => [...prev, tempVariant]);
  }, [rangeVariant, setRangeVariant]);

  const deleteVariant = useCallback(
    (index: number) => {
      const arrayOfNumbers = rangeVariant;
      arrayOfNumbers.splice(index, 1);
      setRangeVariant([...arrayOfNumbers]);
    },
    [rangeVariant, setRangeVariant]
  );

  const selectVariant = useCallback(
    (index: number, e: number) => {
      const temp = [...rangeVariant];
      const dataFilter = temp[index];
      dataFilter.id = e;
      temp.splice(index, 1, dataFilter);
      setRangeVariant(temp);
    },
    [rangeVariant, setRangeVariant]
  );

  const selectSpecification = useCallback(
    (index: number, e: number) => {
      const temp = [...rangeSpecification];
      const dataFilter = temp[index];
      dataFilter.id = e;
      temp.splice(index, 1, dataFilter);
      setRangeSpecification(temp);
    },
    [rangeSpecification, setRangeSpecification]
  );

  const getSubParentCategoryId = useCallback( async (parentId: number) => {
    const { data } = await supabase.from(TABLE.CATEGORIES).select('id').eq('parent_id', parentId);

    const subParentIds = data?.map((it) => it.id);

    setSubParentIds(subParentIds as number[]);
  }, []);

  const getChildCategoryId = useCallback( async (subParentIds: number[]) => {
    const { data } = await supabase.from(TABLE.CATEGORIES).select('id').in('parent_id', subParentIds);

    const childIds = data?.map((it) => it.id);

    setChildIds(childIds as number[]);
  }, []);

  const getProducts = async () => {
    setIsLoadingProductParent(true);
    let api = supabase
      .from(TABLE.PRODUCT)
      .select('*, product_variants(special_price, price, saleable_stock)', { count: 'exact' })
      .order('id')
      .eq('product_variants.is_primary_variant', true)
      .eq('category_id', dataEditCategory.id);

    if (modalFilter.productName) {
      api = api.ilike('name', `%${modalFilter.productName}%`);
    }

    if (modalFilter.sku) {
      api = api.ilike('sku', `%${modalFilter.sku}%`);
    }

    if (typeof modalFilter.status == 'boolean') {
      api = api.eq('is_active', modalFilter.status);
    }

    if (modalFilter.statusProduct) {
      api.eq('status', modalFilter.statusProduct);
    }

    if (modalFilter.idFrom || modalFilter.idTo) {
      api = api.gte('id', modalFilter.idFrom).lte('id', modalFilter.idTo);
    }

    if (modalFilter.priceFrom || modalFilter.priceTo) {
      api = api.gte('main_price', modalFilter.priceFrom).lte('main_price', modalFilter.priceTo);
    }

    if (modalFilter.specialPriceFrom || modalFilter.specialPriceTo) {
      api = api
        .gte('product_variants.special_price', modalFilter.specialPriceFrom)
        .lte('product_variants.special_price', modalFilter.specialPriceTo);
    }

    const { data, error, count: dataCount } = await api;
    if (error) {
      setIsLoadingProductParent(false);
      return;
    }

    const newData = data.map((item) => {
      return {
        id: item?.id,
        name: item?.name,
        sku: item?.sku,
        stok: item?.product_variants[0]?.saleable_stock,
        status: item?.status,
        main_price: item?.product_variants[0]?.price,
        merchantId: item.merchant_id,
        category_id: item.category_id,
        specialPrice: item.product_variants.map((item) => item.special_price)[0]
      };
    });

    setCountData((prev) => typeof dataCount == 'number' ? dataCount : prev);
    setDataProduct(newData as EIProduct[]);
    setIsLoadingProductParent(false);
  };

  const getProductSubParent = async (categoryIds: number[]) => {
    setIsLoadingProductSubParent(true);

    let api = supabase
      .from(TABLE.PRODUCT)
      .select('*, product_variants(special_price, price, saleable_stock)', { count: 'exact' })
      .eq('product_variants.is_primary_variant', true)
      .order('id')
      .in('category_id', categoryIds);

    if (modalFilter.productName) {
      api = api.ilike('name', `%${modalFilter.productName}%`);
    }

    if (modalFilter.sku) {
      api = api.ilike('sku', `%${modalFilter.sku}%`);
    }

    if (typeof modalFilter.status == 'boolean') {
      api = api.eq('is_active', modalFilter.status);
    }

    if (modalFilter.statusProduct) {
      api.eq('status', modalFilter.statusProduct);
    }

    if (modalFilter.idFrom || modalFilter.idTo) {
      api = api.gte('id', modalFilter.idFrom).lte('id', modalFilter.idTo);
    }

    if (modalFilter.priceFrom || modalFilter.priceTo) {
      api = api.gte('main_price', modalFilter.priceFrom).lte('main_price', modalFilter.priceTo);
    }

    if (modalFilter.specialPriceFrom || modalFilter.specialPriceTo) {
      api = api
        .gte('product_variants.special_price', modalFilter.specialPriceFrom)
        .lte('product_variants.special_price', modalFilter.specialPriceTo);
    }

    const { data, error, count: dataCount } = await api;
    if (error) {
      setIsLoadingProductSubParent(false);
      return;
    }

    const newData = data.map((item) => {
      return {
        id: item?.id,
        name: item?.name,
        sku: item?.sku,
        stok: item?.product_variants[0]?.saleable_stock,
        status: item?.status,
        main_price: item?.product_variants[0]?.price,
        merchantId: item.merchant_id,
        category_id: item.category_id,
        specialPrice: item.product_variants.map((item) => item.special_price)[0]
      };
    });

    const uniqProduct = uniqBy(newData, 'id');

    setCountDataSubParent((prev) => typeof dataCount == 'number' ? dataCount : prev);
    setDataProductSubParent(uniqProduct as EIProduct[]);
    setIsLoadingProductSubParent(false);
  };

  const getProductChild = async (categoryIds: number[]) => {
    setIsLoadingProductChild(true);

    let api = supabase
      .from(TABLE.PRODUCT)
      .select('*, product_variants(special_price, price, saleable_stock)', { count: 'exact' })
      .eq('product_variants.is_primary_variant', true)
      .order('id')
      .in('category_id', categoryIds);

    if (modalFilter.productName) {
      api = api.ilike('name', `%${modalFilter.productName}%`);
    }

    if (modalFilter.sku) {
      api = api.ilike('sku', `%${modalFilter.sku}%`);
    }

    if (typeof modalFilter.status == 'boolean') {
      api = api.eq('is_active', modalFilter.status);
    }

    if (modalFilter.statusProduct) {
      api.eq('status', modalFilter.statusProduct);
    }

    if (modalFilter.idFrom || modalFilter.idTo) {
      api = api.gte('id', modalFilter.idFrom).lte('id', modalFilter.idTo);
    }

    if (modalFilter.priceFrom || modalFilter.priceTo) {
      api = api.gte('main_price', modalFilter.priceFrom).lte('main_price', modalFilter.priceTo);
    }

    if (modalFilter.specialPriceFrom || modalFilter.specialPriceTo) {
      api = api
        .gte('product_variants.special_price', modalFilter.specialPriceFrom)
        .lte('product_variants.special_price', modalFilter.specialPriceTo);
    }

    const { data, error, count: dataCount } = await api;

    if (error) {
      setIsLoadingProductChild(false);
      return;
    }

    const newData = data.map((item) => {
      return {
        id: item?.id,
        name: item?.name,
        sku: item?.sku,
        stok: item?.product_variants[0]?.saleable_stock,
        status: item?.status,
        main_price: item?.product_variants[0]?.price,
        merchantId: item.merchant_id,
        category_id: item.category_id,
        specialPrice: item.product_variants.map((item) => item.special_price)[0]
      };
    });

    const uniqProduct = uniqBy(newData, 'id');

    setCountDataSubParent((prev) => typeof dataCount == 'number' ? dataCount : prev);
    setDataProductChildren(uniqProduct as EIProduct[]);
    setIsLoadingProductChild(false);
  };

  const onActionProduct = useCallback(
    async (value, funcName: string) => {
      if (funcName === 'onClickDetail') {
        setPopUpDetail(false);
        navigate(`/product/${value.id}`);
      } else if (funcName === 'onClickChangeCategory') {
        setPopUpChangeCategory(true);
        setDataChangeCategory(value);
        const { data } = await supabase.from(TABLE.CATEGORIES).select('id, name, parent_id').eq('id', value.category_id).single();
        if (data) {
          const parents: { name: string, id: number } = { id: 0, name: '' };
          if (data.parent_id != null) {
            const { data: dataParents } = await supabase.from(TABLE.CATEGORIES).select('id, name').eq('id', data.parent_id).single();
            parents.id = dataParents?.id;
            parents.name = dataParents?.name;
          }
          data.name = data.parent_id !== null ? `${parents.name} > ${data.name}` : data.name;
          const current = data;
          setCurrentCategory(current);
          getMerchantName(value.merchantId);
        }
      }
    },
    [popUpDetail, popUpChangeCategory, dataChangeCategory, currentCategory, dataParentID, merchantName, dataProduct]
  );
  const getMerchantName = useCallback(
    async (merchantId: string) => {
      const { data } = await supabase.from(TABLE.MERCHANTS).select('name').eq('id', merchantId);

      data?.map((item) => setMerchantName(item.name));
    },
    [merchantName]
  );
  const onChangeAllParent = (e) => {
    setSelectedChangeAllParent(e.value);
  };
  const { data: dataSpec } = useCategorySpec(Number(param.id));
  const categorySpecification = ()=> {
    if (dataSpec?.data) {
      const categorySpec = dataSpec.data.map((item)=>{
        return {
          id: item.specifications?.id as number,
          name: item.specifications?.name as string
        };
      });

      if (parentCategorySpec && parentCategorySpec?.length > 0) {
        const parentSpecIds = parentCategorySpec?.map((i) => i.id);
        const filterSpec = categorySpec.filter((it) => !parentSpecIds.includes(it.id));
        setRangeSpecification(filterSpec);
        return;
      }

      setRangeSpecification(categorySpec);
    }
  };
  const { data: dataParentVars } = useCategoryVariants(Number(dataEditCategory.parent_id as number));
  const parentCategoryVars = useMemo(() => {
    if (!Array.isArray(dataParentVars?.data)) return [];
    const parentCategoryVars = dataParentVars?.data.map((item)=>{
      return {
        id: item.variants?.id as number,
        name: item.variants?.name as string
      };
    });
    return parentCategoryVars;
  }, [dataParentVars, dataEditCategory]);

  const categoryVariantIds = useMemo(() => {
    const concatArr = concat(parentCategoryVars, rangeVariant);
    const uniqArrIds = uniqBy(concatArr, 'id').map((i) => i?.id);

    return uniqArrIds;
  }, [parentCategoryVars, rangeVariant]);

  const { data: dataVars } = useCategoryVariants(Number(param.id));
  const categoryVariant = ()=> {
    if (dataVars?.data) {
      const categoryVars = dataVars.data.map((item)=>{
        return {
          id: item.variants?.id as number,
          name: item.variants?.name as string
        };
      });

      if (parentCategoryVars && parentCategoryVars.length > 0) {
        const parentVarsIds = parentCategoryVars?.map((i) => i.id);
        const filterVariants = categoryVars.filter((it) => !parentVarsIds.includes(it.id));
        setRangeVariant(filterVariants);
        return;
      }

      setRangeVariant(categoryVars);
    }
  };
  const { data: parentSpect } = useCategorySpec(dataEditCategory.parent_id as number);
  const parentCategorySpec = useMemo(()=> {
    if (!Array.isArray(parentSpect?.data)) return [];
    const parentSpecification = parentSpect?.data.map((items)=>{
      return {
        id: items.specifications?.id as number,
        name: items.specifications?.name as string
      };
    });
    return parentSpecification;
  }, [parentSpect, dataEditCategory]);

  const categorySpecIds = useMemo(() => {
    const concatArr = concat(parentCategorySpec, rangeSpecification);

    const uniqArrIds = uniqBy(concatArr, 'id').map((i) => i?.id);
    return uniqArrIds;
  }, [parentCategorySpec, rangeSpecification]);

  const onChangeParent = useCallback(
    (e) => {
      setDataChangeCategory((prev) => {
        return {
          ...prev,
          category_id: e
        };
      });
    },
    [dataChangeCategory, currentCategory]
  );
  const checkFilter = () => {
    const idFrom = modalFilter.idFrom !== initialFilter.idFrom;
    const idTo = modalFilter.idTo !== initialFilter.idTo;
    const productName = modalFilter.productName !== initialFilter.productName;
    const sku = modalFilter.sku !== initialFilter.sku;
    const priceFrom = modalFilter.priceFrom !== initialFilter.priceFrom;
    const priceTo = modalFilter.priceTo !== initialFilter.priceTo;
    const specialPriceFrom = modalFilter.specialPriceFrom !== initialFilter.specialPriceFrom;
    const specialPriceTo = modalFilter.specialPriceTo !== initialFilter.specialPriceTo;
    const status = modalFilter.status !== initialFilter.status;

    return idFrom || idTo || productName || sku || priceFrom || priceTo || specialPriceFrom || specialPriceTo || status;
  };

  const onSubmitFilterValue = async () => {
    setIsLoading(true);
    await getProducts();
    await getSubParentCategoryId(dataEditCategory.id);
    await getProductSubParent(subParentIds);
    setFilterModal(false);
    setIsLoading(false);
  };
  const onSubmitChangeCategoryProduct = useCallback(async () => {
    const { error } = await supabase
      .from(TABLE.PRODUCT)
      .update({ category_id: currentCategory?.id })
      .eq('id', dataChangeCategory.id);
    if (error) {
      setPopUpChangeFailed(true);
    } else {
      getProducts();
      setPopUpChangeCategory(false);
      const info = `Produk ${dataChangeCategory.name} dengan sku ${dataChangeCategory.sku} dari ${merchantName} berhasil dipindah ke kategori ${currentCategory?.name}`;

      setMessage(info);
      setPopUpChangeSucces(true);
    }
  }, [dataChangeCategory, currentCategory, dataProduct]);

  const getAllParentId = async (id?: number ) => {
    setIsLoading(true);
    const { data } = await supabase.from(TABLE.CATEGORIES).select('*').eq('id', id).order('id', { ascending: true });
    if ( data ) {
      //get parent
      const { data: dataParent } = await supabase.from(TABLE.CATEGORIES).select('*').order('id', { ascending: true });
      const dropdownOptions: IParentID[] = dataParent?.map(( category ) => {
        if (category.parent_id !== null ) {
          const parentCategory = dataParent?.find( (cat ) => cat.id === category.parent_id );
          const item : IParentID = {
            id: category.id,
            name: `${parentCategory?.name} > ${category.name}`
          };
          return item;
        }
        const itemNoParent : IParentID = {
          id: category.id,
          name: `${category.name}`
        };
        return itemNoParent;
      }) || [];
      setDataParentID(dropdownOptions as IParentID[]);
      const currentData = dropdownOptions.find((item) => item.id == dataEditCategory.parent_id);
      setSelectedDataParentID(currentData as IParentID);
    }
    setIsLoading(false);
  };

  const findCategoryDebounce = debounce(async (findName: string ) => {
    if (!findName) {
      return;
    }

    const { data } = await supabase.from(TABLE.CATEGORIES).select('id, name, parent_id').ilike('name', `*${findName}*`).limit(10);

    if (data) {
      const parentIds = data.filter((item) => item.parent_id !== null).map((item) => item.parent_id);
      const { data: dataParent } = await supabase.from(TABLE.CATEGORIES).select('id, name, parent_id').in('id', parentIds).limit(10);
      if (dataParent) {
        const datasOptions = data?.map((category) => {
          if (category.parent_id !== null) {
            const parentCategory = dataParent?.find((cat) => cat.id === category.parent_id);
            if (parentCategory?.name !== undefined) {
              const item = {
                id: category?.id,
                name: `${parentCategory?.name} > ${category?.name}`
              };
              return item;
            }
          }

          const itemNoParent = {
            id: category?.id,
            name: category?.name
          };
          return itemNoParent;
        });
        setDataParentID(datasOptions);
      }
    }
  }, 250);

  const findCategory = (findName: string) => {
    findCategoryDebounce(findName);
  };

  const showToastCreateCategorySuccesfully = (msg: string) => {
    toast.current?.show({ severity: 'info', summary: 'Info', detail: msg });
  };

  // payload range variant
  const payloadRangeVariants = useMemo(() => {
    return Array.isArray(rangeVariant) ?
      rangeVariant.map((it) => {
        return {
          variant_id: it.id,
          category_id: param.id
        };
      }) :
      [];
  }, [rangeVariant, param]);

  // payload parent category variant
  const payloadParentVariants = useMemo(() => {
    return Array.isArray(parentCategoryVars) ?
      parentCategoryVars.map((it) => {
        return {
          variant_id: it.id,
          category_id: param.id
        };
      }) :
      [];
  }, [parentCategoryVars, param]);

  // payload range specification
  const payloadRangeSpecification = useMemo(() => {
    return Array.isArray(rangeSpecification) ?
      rangeSpecification.map((it) => {
        return {
          specification_id: it.id,
          category_id: param.id
        };
      }) :
      [];
  }, [rangeSpecification, param]);

  // payload parent specification
  const payloadParentSpecification = useMemo(() => {
    return Array.isArray(parentCategorySpec) ?
      parentCategorySpec.map((it) => {
        return {
          specification_id: it.id,
          category_id: param.id
        };
      }) :
      [];
  }, [parentCategorySpec, param]);

  const onSubmitEditValue = useCallback(async () => {
    setIsLoading(true);

    if (!isFollowCategoryName && (!dataEditCategory.slug || dataEditCategory.slug === '')) {
      showToastFailed('URL Key is required if not following category name');
      setIsLoading(false);
      return;
    }

    const changes = (selectedDataParentID && selectedDataParentID.id)? selectedDataParentID.id : 0;

    const { data: parents } = await supabase.from(TABLE.CATEGORIES)
      .select('parent_id, id').eq('id', dataEditCategory.parent_id).single();

    const { data: nextParents } = await supabase.from(TABLE.CATEGORIES)
      .select('parent_id, id').eq('id', changes).single();

    const ids = (parents?.id != null && nextParents?.id != null) || parents?.id == null;

    const parentId = (parents?.parent_id != null && nextParents?.parent_id != null) || parents?.parent_id == null;
    if (!ids && !parentId && selectedDataParentID) {
      showToastFailed('Error Change Parent Category');
      return;
    }
    if (dataProduct.length > 0 && selectedDataParentID && (selectedDataParentID.id != dataEditCategory.parent_id)) {
      showToastFailed('Error Category Have Product');
      return;
    }
    //validation
    if ( !dataEditCategory.name) {
      showToastFailed('Error Missing Input Field');
      return;
    }

    //validation duplicate name
    const { data } = await supabase
      .from(TABLE.CATEGORIES)
      .select('name')
      .neq('id', param.id);

    if ( data ) {
      const validateID = data.find((item) => item.name.toLowerCase() === dataEditCategory.name.toLocaleLowerCase());

      if ( validateID !== undefined) {
        showToastFailed('Error Duplicate Name');
        return false;
      }
    }
    const { data: dataSpect } = await supabase.rpc('catalogue.delete_action', {
      'table_name': 'category_specifications', 'column_name': 'category_id', 'val': [dataEditCategory.id]
    });
    const { data: dataVars } = await supabase.rpc('catalogue.delete_action', {
      'table_name': 'category_variants', 'column_name': 'category_id', 'val': [dataEditCategory.id]
    });
    const dataEditUrl = dataEditCategory?.images.data.image != undefined ? dataEditCategory?.images.data.image : '';
    const urlImage = imageUrl ? imageUrl : dataEditUrl;
    const { error } = await supabase
      .from(TABLE.CATEGORIES)
      .update({
        name: dataEditCategory.name,
        parent_id: selectedDataParentID?.id,
        description: dataEditCategory.description,
        status: dataEditCategory.status ? 'ACTIVE' : 'INACTIVE',
        images: {
          data: {
            image: urlImage
          }
        },
        slug: isFollowCategoryName ? null : dataEditCategory.slug,
        meta_title: dataEditCategory.metaTitle,
        meta_keyword: dataEditCategory.metaKeyword,
        meta_description: dataEditCategory.metaDescription
      })
      .eq('id', param.id);


    if ( error || !dataSpect[0].success || !dataVars[0].success ) {
      showToastFailed(error?.message || 'Failed Edit Category');
      return;
    }

    // insert range variant
    const { error: errorRangeCategoryVariant } = await supabase.from(TABLE.CATEGORY_VARIANTS)
      .insert(payloadRangeVariants);

    if (errorRangeCategoryVariant) {
      showToastFailed('Error Insert to Category Variant');
      return;
    }

    // insert parent variants
    const { error: errorParentCategoryVariant } = await supabase.from(TABLE.CATEGORY_VARIANTS)
      .insert(payloadParentVariants);

    if (errorParentCategoryVariant) {
      showToastFailed('Error Insert to Category Variant');
      return;
    }

    // insert range specification
    const { error: errorRangeSpecification } = await supabase.from(TABLE.CATEGORY_SPECIFICATION)
      .insert(payloadRangeSpecification);

    if (errorRangeSpecification) {
      showToastFailed('Error Insert to Category Specification');
      return;
    }

    // inser parent specification
    const { error: errorParentSpecification } = await supabase.from(TABLE.CATEGORY_SPECIFICATION)
      .insert(payloadParentSpecification);

    if (errorParentSpecification) {
      showToastFailed('Error Insert to Category Specification');
      return;
    }

    showToastCreateCategorySuccesfully('Edit Category Succesfully');
    setTimeout(() => {
      fileUploadRef.current?.clear();
      setIsLoading(false);
      navigate('/category');
    }, 3000);
  }, [
    isFollowCategoryName,
    rangeSpecification,
    rangeVariant,
    dataEditCategory,
    param.id,
    dataProduct,
    selectedDataParentID,
    imageUrl,
    fileUploadRef,
    categorySpecIds
  ]);

  const onTabChange = (e) => {
    setParams({ index: e.index });
  };

  useEffect(() => {
    if (popUpChangeCategory) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [popUpChangeCategory]);

  useEffect(() => {
    getDataEditById();
  }, []);

  useEffect(()=>{
    if (dataEditCategory.parent_id) {
      getAllParentId(Number(dataEditCategory.id));
    }
  }, [dataEditCategory.parent_id]);

  useEffect(() => {
    if (checked === true) {
      setSelectedParent(null);
    }
  }, [checked, selectedParent]);

  useEffect(() => {
    getProducts();
    categorySpecification();
    categoryVariant();
  }, [dataEditCategory.id, dataSpec, dataVars, parentCategorySpec]);

  useEffect(() => {
    if (selectedAttributeSets?.id) setAttributSetId(String(selectedAttributeSets?.id));
  }, [selectedAttributeSets]);


  useEffect(() => {
    if (dataEditCategory.id) {
      getSubParentCategoryId(dataEditCategory.id);
    }
  }, [dataEditCategory.id]);

  useEffect(() => {
    if (subParentIds.length > 0) {
      getChildCategoryId(subParentIds);
    }
  }, [subParentIds]);


  useEffect(() => {
    if (subParentIds.length > 0) {
      getProductSubParent(subParentIds);
    }
  }, [subParentIds]);


  useEffect(() => {
    if (childIds.length > 0) {
      getProductChild(childIds);
    }
  }, [childIds]);

  useEffect(() => {
    filterModal ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto';
  }, [filterModal]);

  return {
    data: {
      isLoading,
      dataEditCategory,
      dataParentID,
      selectedDataParentID,
      toast,
      seoPage,
      visible,
      urlKey,
      metaDescription,
      metaKeywords,
      metaTitle,
      specifications,
      variants,
      filters,
      dataStatus,
      dataProduct,
      merchantName,
      attributeSets,
      rangeSpecification,
      popUpChangeAll,
      popUpChangeCategory,
      popUpChangeFailed,
      popUpChangeSucces,
      currentCategory,
      rangeVariant,
      dataChangeCategory,
      filterModal,
      modalFilter,
      message,
      checked,
      popUpDetail,
      selectedParent,
      isFollowCategoryName,
      selectedAttributeSets,
      validateDataAttributSet,
      selectedChangeAllParent,
      parentCategoryVars,
      parentCategorySpec,
      foundVariant,
      foundSpec,
      dataAllProduct,
      isLoadingProductParent,
      isLoadingProductSubParent,
      isLoadingProductChild,
      isLoadingProduct,
      formik,
      fileUploadRef,
      params,
      categorySpecIds,
      categoryVariantIds,
      countData,
      dataStatusProduct,
      coutAllData,
      initialFilter
    },
    methods: {
      onChangeValue,
      handleFileUpload,
      onSubmitEditValue,
      setSelectedDataParentID,
      setSeoPage,
      setVisible,
      setUrlKey,
      setChecked,
      setMetaDescription,
      setMetaKeywords,
      setMetaTitle,
      setIsFollowCategoryName,
      addSpecification,
      addVariant,
      deleteSpecification,
      deleteVariant,
      handleSelectedAttributeSets,
      handleAcceptDialog,
      handleClickApply,
      setPopUpDetail,
      selectSpecification,
      selectVariant,
      setFilters,
      onChangeFilterValue,
      onChangeStatusFilter,
      onSubmitFilterValue,
      setPopUpChangeAll,
      setPopUpChangeCategory,
      setPopUpChangeFailed,
      setPopUpChangeSucces,
      setFilterModal,
      onActionProduct,
      onChangeAllParent,
      onChangeParent,
      onSubmitChangeCategoryProduct,
      findVariant,
      findSpecificaiton,
      findCategory,
      onTabChange,
      onDeleteAllFilter,
      onDeleteFilter,
      checkFilter,
      onChangeStatusProductFilter
    }
  };
};
