import React, { FC } from 'react';
import { RadioButton } from 'primereact/radiobutton';

type IAsuransi = {
  insuranceFlag: boolean;
};

const Asuransi: FC<IAsuransi> = ({ insuranceFlag }) => {
  return (
    <><div id="asuransi" className="font-bold text-xl mt-6 mb-2">Asuransi Pengiriman</div>
      <div id="form_asuransi" className="py-3 px-4">
        <div id="form_asuransi_item" className="flex gap-2">
          <div id="keterangan_asuransi" className="mr-3">
            <div id="keterangan_value" className="text-xs">Menanggung pengembalian dana produk & ongkir untuk penjual maupun pembeli jika terjadi kerusakan / kehilangan saat penggiriman</div>
          </div>
          <div id="radio_form" className="flex gap-2">
            <div id="radio_item" className="flex align-items-center">
              <RadioButton disabled inputId="product-required-description-input" name="asuransi" value="wajib" checked={!!insuranceFlag} />
              <label htmlFor="keterangan_wajib" className="ml-2 mb-2">
                <div id="keterangan_wajib" className="flex font-bold">Wajib</div>
                <p className="text-xs">Pembeli wajib mengaktifkan asuransi pengiriman</p>
              </label>
            </div>
            <div id="radio_item" className="flex align-items-center">
              <RadioButton disabled inputId="product-optional-description-input" name="asuransi" value="optional" checked={!insuranceFlag} />
              <label htmlFor="keterangan_optional" className="ml-2 mb-2">
                <div id="keterangan_optional" className="flex font-bold">Optional</div>
                <p className="text-xs">Pembeli tidak wajib mengaktifkan asuransi pengiriman</p>
              </label>
            </div>
          </div>
        </div>
      </div></>
  );
};

export default Asuransi;
