import useVariant from './hooks';
import './style.css';
import { FC } from 'react';
import { InputText } from 'primereact/inputtext';
import { Chips } from 'primereact/chips';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputSwitch } from 'primereact/inputswitch';
import { usePermissionStore } from '@/store/usePermissions';

const CreateVariant: FC = () => {
  const {
    data: { formCreateVariant, toast, formik, params },
    methods: {
      isFormFieldValid,
      onChangeFormVariantValue,
      onChangeChips,
      resetFormCreateVariant,
      onChangeToggle,
      navigate
    }
  } = useVariant();
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };
  const { canEdit } = usePermissionStore();
  return (
    <>
      <BaseContainer>
        <MainTitle
          title={(params.detailId && 'Detail Variant') || (params.id && 'Edit Variant') || 'Create Variant'}
          isWithButton={false}
          labelButton=""
          nav=""
        />
        <div id="form-create-variant" className="mt-10 flex flex-col gap-8">
          <div className="flex gap-8">
            <p className="font-medium w-44 mt-2" id="variant-name-label">
              Variant Name*
            </p>
            <div className="flex flex-col w-1/3">
              <InputText
                required
                id="variants-name-input"
                className="flex-1"
                placeholder="Input variants Name"
                value={formCreateVariant.name}
                onChange={onChangeFormVariantValue('name')}
                disabled={Boolean(params.detailId)}
              />
              <div className="pt-1 pl-1">{getFormErrorMessage('name')}</div>
            </div>
          </div>
          <div className="flex gap-8">
            <p className="font-medium w-44 mt-2" id="variant-name-label">
              Variant Label*
            </p>
            <div className="flex flex-col w-1/3">
              <InputText
                required
                id="variant-name-input"
                className="flex-1"
                placeholder="Input Variant Label"
                value={formCreateVariant.label}
                onChange={onChangeFormVariantValue('label')}
                disabled={Boolean(params.detailId)}
              />
              <div className="pt-1 pl-1">{getFormErrorMessage('label')}</div>
            </div>
          </div>
          <div className="flex gap-8 items-center">
            <p className="font-medium w-44" id="variant-value-label">
              Description
            </p>
            <div className="p-fluid w-1/3">
              <InputTextarea
                value={formCreateVariant.description}
                autoResize
                placeholder="Input Description"
                onChange={onChangeFormVariantValue('description')}
                disabled={Boolean(params.detailId)}
              />
            </div>
          </div>
          <div className="flex gap-8">
            <p className="font-medium w-44 mt-2" id="variant-name-label">
              Variant Type*
            </p>
            <div className="flex flex-col w-1/3">
              <InputText
                required
                id="variant-name-input"
                className="flex-1"
                placeholder="Input Variant Type"
                value={formCreateVariant.type}
                onChange={onChangeFormVariantValue('type')}
                disabled={Boolean(params.detailId)}
              />
              <div className="pt-1 pl-1">{getFormErrorMessage('type')}</div>
            </div>
          </div>
          <div className="flex gap-8">
            <p className="font-medium w-44 mt-2" id="variant-name-label">
              Status *
            </p>
            <InputSwitch
              checked={formCreateVariant.is_active}
              onChange={onChangeToggle}
              disabled={Boolean(params.detailId)}
            />
          </div>
          <div className="flex gap-8 items-center">
            <p className="font-medium w-44" id="variant-value-label">
              Variant Value*
            </p>
            <div className="flex flex-col w-1/3">
              <div className="p-fluid flex-1">
                <Chips
                  separator=","
                  id="variant-value-input"
                  placeholder="Input Values Here"
                  value={formCreateVariant.value}
                  onChange={onChangeChips}
                  disabled={Boolean(params.detailId)}
                />
              </div>
              <div className="pt-1 pl-1">{getFormErrorMessage('value')}</div>
            </div>
          </div>
          <div className="flex gap-8 items-center">
            <p className="font-medium w-44" id="variant-value-label">
              Input Instruction
            </p>
            <div className="flex flex-col w-1/3">
              <div className="p-fluid flex-1">
                <InputTextarea
                  value={formCreateVariant.instruction}
                  autoResize
                  placeholder="Input Charge Instruction"
                  onChange={onChangeFormVariantValue('instruction')}
                  disabled={Boolean(params.detailId)}
                />
              </div>
            </div>
          </div>
        </div>
        <Toast ref={toast} />
        <SubmitButton
          onSubmit={params.detailId ? ()=>navigate(`/variant/edit/${params.detailId}`) : formik.handleSubmit}
          onCancel={() => {
            resetFormCreateVariant();
            navigate('/variant');
          }}
          labelBack="Cancel"
          labelSubmit={params.detailId?'Edit':'Submit'}
          disabledSubmit={params.detailId? !canEdit : false}
        />
      </BaseContainer>
    </>
  );
};

export default CreateVariant;
