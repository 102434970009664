import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import {
  Banner,
  getAllBrandBanner,
  IBrandBanner,
  IIdentity,
  insertBanner,
  insertBannerAppliedStreet,
  insertBannerBrandId,
  insertBrandBanner,
  IPayloadBrandBanner,
  upsertBanner,
  upsertBannerAppliedStreet,
  upsertBrandBanner,
  useBannerById,
  useBrandBannerGroup,
  useCategory,
  usePromos,
  useStreet
} from '@/services/rest/banner';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { CalendarChangeEvent } from 'primereact/calendar';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { FileUpload, FileUploadFilesEvent } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { schemaUploadBanner } from './validation';
import { debounce, uniqBy } from 'lodash';
import { storageUpload } from '@/utils/storage';

export interface Street {
  id: number;
  name: string;
}
export interface IFormValue {
  name: string;
  description: string;
  type: IBannerOptions;
  previewType: IBannerOptions;
  directTo: string;
  street?: IBannerOptions;
  desktop?: string;
  mobile?: string;
  thumbnail?: string;
  status: IBannerOptions;
  leftImage?: Array<IImage>;
  rightImage?: Array<IImage>;
  centerImage?: Array<IImage>;
  validFrom: string;
  validTo: string;
  categoryId: Array<IBannerOptions> | IBannerOptions;
  merchantId: IBannerOptions;
  productId: IBannerOptions;
  promoDetailId: IBannerOptions;
  landingPage: string;
  urlPath: string;
  sizeLeft?: IBannerOptions;
  sizeCenter?: IBannerOptions;
  sizeRight?: IBannerOptions;
  brandBannerLeftId?:number
  brandBannerRightId?:number
  brandBannerId?:number
  sectionType: Array<ISectionOptions>;
  streetStatus: IBannerOptions;
  leftImageBrand: IFormBrandBanner[];
  centerImageBrand: IFormBrandBanner[];
  rightImageBrand: IFormBrandBanner[];
}

export interface IBannerOptions {
  label: string | null;
  code: string | null;
}

export interface ISectionOptions {
  label: string | null;
  code: string | null;
}

export interface IImage {
  id:number,
  image:string
}

interface IFormikUploadSchema{
  name:string
  type:string
  desktop?:string
  mobile?:string
  thumbnail?:string
  centerImageBrand?:Array<Object>
  leftImageBrand?:Array<Object>
  rightImageBrand?:Array<Object>
  status:string
}

interface IFormBrandBanner {
  id: number | null;
  bannerId: number | null;
  image: string;
  directTo: string;
  productId: IBannerOptions;
  promosId: IBannerOptions;
  merchantId: IBannerOptions;
  urlFull: string;
  type: string;
  tagId: IBannerOptions;
  validFrom: string;
  validTo: string;
  sequence: number;
  status: IBannerOptions;
}

const initBrandBanner = (type: string)=> {
  const temp: IFormBrandBanner[] = [];
  Array.from({ length: 4 }).forEach((_v, index)=> {
    temp.push({
      id: null,
      bannerId: null,
      image: '',
      directTo: '',
      productId: { code: '', label: '' },
      promosId: { code: '', label: '' },
      merchantId: { code: '', label: '' },
      urlFull: '',
      sequence: index +1,
      tagId: { code: '', label: '' },
      type: type,
      validFrom: '',
      validTo: '',
      status: { code: '', label: '' }
    });
  });

  return temp;
};

const useCustom = () => {
  const initBannerFormValue: IFormValue = {
    description: '',
    directTo: '',
    name: '',
    street: {
      code: '',
      label: ''
    },
    type: { code: '', label: '' },
    previewType: { code: '', label: '' },
    desktop: '',
    mobile: '',
    thumbnail: '',
    status: { code: '', label: '' },
    centerImage: [],
    leftImage: [],
    rightImage: [],
    validFrom: '',
    validTo: '',
    categoryId: [],
    merchantId: { code: '', label: '' },
    productId: { code: '', label: '' },
    promoDetailId: { code: '', label: '' },
    landingPage: '',
    urlPath: '',
    sizeLeft: { code: 'MINI', label: 'Mini' },
    sizeCenter: { code: 'LARGE', label: 'Large' },
    sizeRight: { code: 'MINI', label: 'Mini' },
    sectionType: [],
    streetStatus: { code: '', label: '' },
    leftImageBrand: initBrandBanner('left'),
    centerImageBrand: initBrandBanner('center'),
    rightImageBrand: initBrandBanner('right')
  };

  const initUploadBannerSchema: IFormikUploadSchema = {
    name: '',
    type: '',
    desktop: '',
    mobile: '',
    thumbnail: '',
    centerImageBrand: [],
    leftImageBrand: [],
    rightImageBrand: [],
    status: ''
  };

  const optionsListSection: ISectionOptions[] = [
    { label: 'Video', code: 'VIDEO_BANNER' },
    { label: 'Flash Sale', code: 'SECTION_FLASH_SALE_BACKGROUND' }
  ];

  const navigate: NavigateFunction = useNavigate();
  const query = useParams();
  const refToast = useRef<Toast>(null);
  const fileInput = useRef<FileUpload>(null);
  const [formValue, setFormValue] = useState<IFormValue>(initBannerFormValue);
  const [tabMenu, setTabMenu] = useState<number>(0);
  const [streetCount, setStreetCount] = useState<number>(1);
  const [imageType, setImageType] = useState<string>('');
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [foundMerchants, setFoundMerchants] = useState<{ id: string, name: string }[]|null>(null);
  const [foundProducts, setFoundProducts] = useState<{ id: string, name: string }[]|null>(null);
  const [foundTagging, setFoundTagging] = useState<{ id: string, name: string }[]|null>(null);
  const [refetch, setRefetch] = useState(false);
  const [identityBrandBanner, setIdentityBrandBanner] = useState<IIdentity>({
    categoryId: '',
    merchantId: '',
    productId: '',
    promosId: '',
    urlPath: '',
    createdAt: ''
  });

  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);

  const { data: dataCategory } = useCategory();
  const { data: dataPromos } = usePromos();
  const { data: dataStreet } = useStreet();

  const { data: selectedBannerById, isFetching: isLoadingBannerDetail } = useBannerById(query.id || query.editId);
  const { data: selectedBrandBannerGroup } = useBrandBannerGroup(Number(query.id) || Number(query.editId), identityBrandBanner);

  const selectedBanner = useMemo(() => {
    return selectedBannerById?.data && selectedBannerById.data;
  }, [selectedBannerById]);

  const selectedBrandBanner = useMemo(() => {
    return selectedBrandBannerGroup?.data && selectedBrandBannerGroup.data;
  }, [selectedBrandBannerGroup]);

  const currentBrand = formValue[`${imageType}Brand`];

  const setMerchantOptions = (data: { id: string, name: string }[] | null) => {
    if (data) {
      const concatData = uniqBy([...foundMerchants || [], ...data], 'id');
      setFoundMerchants(concatData);
      if (formValue.merchantId.label == null) {
        const merchant = data.find((p) => p.id == formValue.merchantId.code);
        setFormValue((prev) => ({
          ...prev,
          merchantId: {
            code: merchant ? merchant.id : formValue.merchantId.code,
            label: merchant ? merchant.name : formValue.merchantId.label
          }
        }));
      }
    }
  };

  const setProductOptions = (data: { id: string, name: string }[] | null) => {
    if (data) {
      const concatData = uniqBy([...foundProducts || [], ...data], 'id');
      setFoundProducts(concatData);
      if (formValue.productId.label == null) {
        const product = data.find((p) => p.id == formValue.productId.code);
        setFormValue((prev) => ({
          ...prev,
          productId: {
            code: product ? product.id : formValue.productId.code,
            label: product ? product.name : formValue.productId.label
          }
        }));
      }
    }
  };

  const setTaggingOptions = (data: { id: string, name: string }[] | null) => {
    if (data) {
      const concatData = uniqBy([...foundTagging || [], ...data], 'id');
      setFoundTagging(concatData);
    }
  };

  const getDefaultOptionListTagging = useCallback( async (ids?: number[] | string[]) => {
    const today = dayjs().utc().format('YYYY-MM-DD');

    const query = supabase.from(TABLE.PROMO_TAGGING).select('id, name')
      .lte('valid_from:date', today)
      .gte('valid_to:date', today)
      .limit(10).eq('status', 'ACTIVE');

    if (ids) {
      query.in('id', ids);
    }
    const { data } = await query;

    setTaggingOptions(data);
  }, []);

  const getDefaultOptionListMerchant = useCallback( async (ids?: number[] | string[]) => {
    const query = supabase.from(TABLE.MERCHANTS).select('id, name').limit(10);
    if (formValue.merchantId.code && formValue.merchantId.code !== '') {
      query.eq('id', formValue.merchantId.code);
    }
    if (ids) {
      query.in('id', ids);
    }
    const { data } = await query;

    setMerchantOptions(data);
  }, [formValue.merchantId.code, formValue.merchantId.label, foundMerchants]);

  const findMerchantByIdDebounce = debounce(async (findId: string | null) => {
    if (!findId) {
      return;
    }

    if (!foundMerchants || foundMerchants?.length <= 0) {
      const { data } = await supabase.from(TABLE.MERCHANTS).select('id, name').eq('id', findId);

      setMerchantOptions(data);
    }
  }, 300);

  const getDefaultOptionListProduct = useCallback( async (ids?: number[] | string[]) => {
    const query = supabase.from(TABLE.PRODUCT).select('id, name').limit(10);
    if (formValue.productId.code && formValue.productId.code !== '') {
      query.eq('id', formValue.productId.code);
    }
    if (ids) {
      query.in('id', ids);
    }
    const { data } = await query;

    setProductOptions(data);
  }, [formValue.productId.code, formValue.productId.label, foundProducts]);

  const findProductByIdDebounce = debounce(async (findId: string | null) => {
    if (!findId) {
      return;
    }
    const { data } = await supabase.from(TABLE.PRODUCT).select('id, name').eq('id', findId);

    setProductOptions(data);
  }, 300);

  useEffect(() => {
    getDefaultOptionListProduct();
    getDefaultOptionListMerchant();
    getDefaultOptionListTagging();
  }, [formValue.productId.code, formValue.merchantId.code]);

  useEffect(() => {
    if (formValue.merchantId.code) {
      findMerchantByIdDebounce(formValue.merchantId.code);
    }

    if (formValue.productId.code) {
      findProductByIdDebounce(formValue.productId.code);
    }
  }, [formValue.merchantId.code, formValue.productId.code]);

  const findMerchantsDebounce = debounce(async (findName: string | null) => {
    if (!findName) {
      return;
    }

    const { data } = await supabase.from('catalogue.merchants').select('id, name').ilike('name', `*${findName}*`).limit(10);

    if (data && data.length > 0) {
      const concatData = uniqBy([...foundMerchants || [], ...data], 'id');
      setFoundMerchants(concatData);
    }
  }, 300);

  const findMerchants = async (findName: string | null) => {
    findMerchantsDebounce(findName);
  };

  const findProductsDebounce = debounce(async (findName: string | null) => {
    if (!findName) {
      return;
    }

    const { data } = await supabase.from('catalogue.products').select('id, name').ilike('name', `*${findName}*`).limit(10);

    if (data && data.length > 0) {
      const concatData = uniqBy([...foundProducts || [], ...data], 'id');
      setFoundProducts(concatData);
    }
  }, 300);

  const findProducts = async (findName: string | null) => {
    findProductsDebounce(findName);
  };

  const findTaggingDebounce = debounce(async (findName: string | null) => {
    if (!findName) {
      return;
    }
    const today = dayjs().utc().format('YYYY-MM-DD');

    const { data } = await supabase.from(TABLE.PROMO_TAGGING).select('id, name')
      .lte('valid_from:date', today)
      .gte('valid_to:date', today)
      .ilike('name', `*${findName}*`).limit(10).eq('status', 'ACTIVE');

    if (data && data.length > 0) {
      const concatData = uniqBy([...foundTagging || [], ...data], 'id');
      setFoundTagging(concatData);
    }
  }, 300);

  const findTagging = async (findName: string | null) => {
    findTaggingDebounce(findName);
  };

  const formikUpload = useFormik({
    initialValues: initUploadBannerSchema,
    validationSchema: schemaUploadBanner,
    onSubmit: ()=>{
      handleClickSave();
    }
  });

  const isFormFieldValid = (name) => !!(formikUpload.touched[name] && formikUpload.errors[name]);

  const validateDate: Boolean = useMemo(() => {
    return new Date(formValue.validTo).getTime() < new Date(formValue.validFrom).getTime();
  }, [formValue]);

  const optionsListCategories: IBannerOptions[] = useMemo(() => {
    const categoryLevel2 =
      Array.isArray(dataCategory?.data) &&
      dataCategory?.data.map((data) => data.child).reduce((prev, current) => prev.concat(current));

    const categoryLevel3 =
      Array.isArray(dataCategory?.data) &&
      dataCategory?.data
        .map((data) => data.child)
        .reduce((prev, current) => prev.concat(current))
        .map((data) => data.child)
        .reduce((prev, current) => prev.concat(current));

    return (
      (Array.isArray(categoryLevel2) &&
        Array.isArray(categoryLevel3) &&
        [...dataCategory?.data, ...categoryLevel2, ...categoryLevel3].map((category) => {
          return {
            label: category.name,
            code: category.id
          };
        })) ||
      []
    );
  }, [dataCategory]);

  const optionTabMenu = [
    {
      label: !['VIDEO_BANNER', 'SECTION_BACKGROUND', 'PROMO_BANNER', 'SECTION_FLASH_SALE_BACKGROUND'].includes(String(formValue.type.code)) ? 'Upload Banner' : 'Upload Background',
      command: () => {
        setTabMenu(0);
      },
      className: tabMenu === 0 || tabMenu === 3 ? 'bg-white font-bold text-black border rounded' : 'rounded'
    },
    Boolean(formValue.type.code !== 'PROMO_BANNER') ?
      {
        label: !['VIDEO_BANNER', 'SECTION_BACKGROUND', 'PROMO_BANNER', 'SECTION_FLASH_SALE_BACKGROUND'].includes(String(formValue.type.code))? 'Detail Banner' : 'Background Detail',
        command: () => {
          formikUpload.handleSubmit();
        },
        className: tabMenu === 1 || tabMenu === 2 ? 'bg-white font-bold text-black border rounded' : 'rounded'
      } :
      {
        className: 'hidden'
      }
  ];

  const optionDirectTo: IBannerOptions[] = [
    {
      label: 'Store Detail',
      code: 'STORE_DETAIL'
    },
    {
      label: 'PDP',
      code: 'PDP'
    },
    {
      label: 'Landing Page',
      code: 'LANDING_PAGE'
    },
    {
      label: 'Promo Detail Page',
      code: 'PROMO_DETAIL_PAGE'
    },
    {
      label: 'Promo Tagging',
      code: 'PROMO_TAGGING'
    }
  ];

  const optionSize: IBannerOptions[] = [
    {
      code: 'LARGE',
      label: 'Large'
    },
    {
      code: 'MINI',
      label: 'Mini'
    }
  ];

  const optionBannerType: IBannerOptions[] = [
    { label: 'Promo Header Banner', code: 'HEADER_BANNER' },
    { label: 'Main Banner', code: 'MAIN_BANNER' },
    { label: 'Promo Ads Banner 1', code: 'ADS_BANNER' },
    { label: 'Produk Pilihan', code: 'OPTIONAL_PRODUCT_BANNER' },
    { label: 'Brand Banner', code: 'BRAND_BANNER' },
    { label: 'Hot Deals', code: 'HOT_DEAL_BANNER' },
    { label: 'Banner Produk Kategori', code: 'PRODUCT_CATEGORY_BANNER' },
    { label: 'Promo Page', code: 'PROMO_BANNER' },
    { label: 'Street', code: 'STREET' },
    { label: 'Flash Banner', code: 'FLASH_SALE_BANNER' },
    { label: 'Official Store Banner', code: 'OFFICIAL_STORE_BANNER' },
    { label: 'Section Background', code: 'SECTION_BACKGROUND' },
    { label: 'Popup Banner', code: 'POPUP_BANNER' }
  ];

  const optionBannerStatus: IBannerOptions[] = [
    { label: 'Enabled', code: 'ACTIVE' },
    { label: 'Disabled', code: 'INACTIVE' }
  ];

  const optionLandingPage: IBannerOptions[] = [
    { label: 'Promo', code: '/promo' },
    { label: 'Flash Sale', code: '/flashsale' }
  ];

  const optionListPromos: IBannerOptions[] = useMemo(() => {
    return (
      (Array.isArray(dataPromos?.data) &&
        dataPromos?.data.map((promo) => {
          return {
            label: promo.name,
            code: promo.id
          };
        })) ||
      []
    );
  }, [dataPromos]);

  const optionListProduct: IBannerOptions[] = useMemo(() => {
    return (
      (Array.isArray(foundProducts) &&
        foundProducts?.map((product) => {
          return {
            label: product.name,
            code: product.id
          };
        })) ||
      []
    );
  }, [foundProducts]);

  const optionStreet: IBannerOptions[] = useMemo(() => {
    return (
      (Array.isArray(dataStreet?.data) &&
        dataStreet?.data.map((street) => {
          return {
            label: street.name,
            code: street.id
          };
        })) ||
      []
    );
  }, [dataStreet]);

  const optionsTagging: IBannerOptions[] = useMemo(()=> {
    return (
      (Array.isArray(foundTagging) &&
        foundTagging.map((tag) => {
          return {
            label: tag.name,
            code: tag.id
          };
        })) ||
      []
    );
  }, [foundTagging]);

  const optionListMerchants = useMemo(() => {
    return (
      (Array.isArray(foundMerchants) &&
        foundMerchants?.map((merchant) => {
          return {
            code: merchant.id,
            label: merchant.name
          };
        })) ||
      []
    );
  }, [foundMerchants]);

  const showSuccessToast = () => {
    setIsLoading(false);
    refToast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: 'New banner successfully created',
      life: 2000
    });
    const timeOut = setTimeout(() => {
      navigate('/frontstore-management/banner');
      return () => clearTimeout(timeOut);
    }, 1000);
  };

  const showFailedToast = (message?: string) => {
    setIsLoading(false);
    refToast.current?.show({
      severity: 'error',
      summary: 'Failed',
      detail: message || 'Failed upload image',
      life: 2000
    });
  };

  const handleClickAddStreet = useCallback(() => {
    setStreetCount(streetCount + 1);
  }, [streetCount]);

  const handleClickSubStreet = useCallback(() => {
    setStreetCount(streetCount - 1);
  }, [streetCount]);

  const handleCLickDetailBanner = useCallback(() => {
    setTabMenu(2);
  }, []);

  const handleClickSaveMultipleImage = useCallback(() => {
    setTabMenu(0);
  }, []);

  const handleAddMultipleImage = useCallback(
    (type: string) => () => {
      setTabMenu(3);
      setImageType(type);
    },
    []
  );

  const handleOnChangeInput = useCallback(
    (key: string, isBrand?: boolean, index?: number) =>
      ({ currentTarget }: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement, Event>) => {
        if (isBrand) {
          const tempBrand: IFormBrandBanner[] = [...formValue[key]];
          tempBrand[index as number].urlFull = currentTarget.value;
          setFormValue((prev)=> ({
            ...prev,
            [key]: tempBrand
          }));
        } else {
          formikUpload.setFieldValue(key, currentTarget.value);
          setFormValue((prev) => ({
            ...prev,
            [key]: currentTarget.value
          }));
        }
      },
    [formValue]
  );

  const handleOnChangeDate = useCallback(
    (key: string, isBrand?: boolean, index?: number) => (e: CalendarChangeEvent) => {
      if (isBrand) {
        const currentKey = `${imageType}Brand`;
        const tempBrand: IFormBrandBanner[] = [...formValue[currentKey]];
        tempBrand[index as number][key] = e.value;
        setFormValue((prev)=> ({
          ...prev,
          [currentKey]: tempBrand
        }));
      } else {
        setFormValue((prev) => ({
          ...prev,
          [key]: e.value
        }));
      }
    },
    [imageType]
  );

  const handleOnChangeDropdown = useCallback(
    (key: string) => (e: DropdownChangeEvent) => {
      formikUpload.setFieldValue(key, e.value.code);
      setFormValue((prev) => ({
        ...prev,
        [key]: e.value
      }));
      if (key === 'type') {
        setTabMenu(0);
        setFormValue({
          ...initBannerFormValue,
          type: e.value
        });
        formikUpload.setValues({
          ...initUploadBannerSchema,
          type: e.value.code
        });
      }
      if (key === 'directTo') {
        setFormValue((prev) => ({
          ...prev,
          productId: { code: '', label: '' },
          merchantId: { code: '', label: '' },
          promoDetailId: { code: '', label: '' }
        }));
      }
    },
    []
  );

  const handleDropDownBrandBanner = useCallback((key: string, index: number) => (e: DropdownChangeEvent) => {
    const isDirectTo = ['leftImageBrand', 'centerImageBrand', 'rightImageBrand'].includes(key);

    if (isDirectTo) {
      const tempBrand: IFormBrandBanner[] = [...formValue[key]];
      tempBrand[index].directTo = e.value;
      setFormValue((prev)=> ({
        ...prev,
        [key]: tempBrand
      }));
    } else {
      const currentKey = `${imageType}Brand`;
      const tempBrand: IFormBrandBanner[] = [...formValue[currentKey]];
      tempBrand[index][key] = e.value;
      setFormValue((prev)=> ({
        ...prev,
        [currentKey]: tempBrand
      }));
    }
  }, [imageType]);

  const handleUploadImage = useCallback(
    (key: string, id?:number) => async (event: FileUploadFilesEvent) => {
      setIsLoadingUpload(true);
      const dataImage = event.files[0];
      // const fileName = dataImage.name;
      if (dataImage.size > 1024 * 1024) {
        setIsLoadingUpload(false);
        showFailedToast('File Size is Bigger than 1MB');
        event.files.pop();
        return;
      }
      if (dataImage.type.includes('image/')) {
        // upload image to bucket
        const responsUploadPhoto = await storageUpload('web/', dataImage);
        if (responsUploadPhoto === null) {
          showFailedToast();
          setIsLoadingUpload(false);
          return;
        }

        if (id !== null && id !== undefined && formValue.type.code === 'BRAND_BANNER') {
          const tempBrand: IFormBrandBanner[] = [...formValue[key]];
          tempBrand[id as number].image = responsUploadPhoto.src;
          tempBrand[id as number].sequence = id +1;
          setFormValue((prev)=> ({
            ...prev,
            [key]: tempBrand
          }));
          formikUpload.setValues((prev)=> ({
            ...prev,
            [key]: tempBrand
          }));
          setIsLoadingUpload(false);
          return;
        }

        const tempValue = Array.isArray(formValue[key]) && formValue[key].filter((item)=>item.id !== id);

        if (Array.isArray(formValue[key]) && formValue[key].find((item)=>item.id===id)) {
          setFormValue((prev) => ({
            ...prev,
            [key]: tempValue
          } ));
          formikUpload.setValues((prev) => ({
            ...prev,
            [key]: tempValue
          } ));
        }

        setFormValue((prev) => ({
          ...prev,
          [key]: ['leftImage', 'rightImage', 'centerImage'].includes(key) ?
            [...prev[key], { id: id, image: responsUploadPhoto.src }] :
            responsUploadPhoto.src
        }));

        formikUpload.setValues((prev)=>({
          ...prev,
          [key]: ['leftImage', 'rightImage', 'centerImage'].includes(key) ?
            [...prev[key], { id: id, image: responsUploadPhoto.src }] :
            responsUploadPhoto.src
        }));

        fileInput.current?.clear();
        setIsLoadingUpload(false);
      } else {
        return showFailedToast('File Selected is not an Image, Please reload for Select New File');
      }
    },
    [formValue]
  );

  const handleEditBanner = async (id?: number) => {
    const tempId = query.editId ? Number(query.editId) : id;
    const payloadBanner: Banner = {
      id: tempId,
      created_at: null,
      description: formValue.description,
      ended_time: dayjs(formValue.validTo).format('YYYY-MM-DD'),
      image: [
        {
          id: 1,
          image: formValue.desktop || '',
          image_mobile: formValue.mobile || '',
          thumbnail: formValue.thumbnail
        }
      ],
      category_id: !Array.isArray(formValue.categoryId) ? formValue.categoryId.code : null,
      name: formValue.name,
      started_time: dayjs(formValue.validFrom).format('YYYY-MM-DD'),
      status: formValue.status.code,
      type: formValue.type.code,
      updated_at: null,
      merchant_id: formValue.merchantId.code || null,
      product_id: formValue.productId.code || null,
      url_path: setUrlPath(tempId),
      url_full: formValue.directTo === 'LANDING_PAGE' ? formValue.landingPage : null,
      promos_id: formValue.promoDetailId.code || null
    };

    const { data: banner, error: errorBanner } = await upsertBanner([payloadBanner]);

    if (!banner || errorBanner) {
      showFailedToast(errorBanner.message);
    }
  };

  const handleEditBrandBanner = async (idLeft?: number, idCenter?: number, idRight?: number) => {
    const tempIdLeft = formValue.brandBannerLeftId ? Number(formValue.brandBannerLeftId) : idLeft;
    const tempIdCenter = formValue.brandBannerId ? Number(formValue.brandBannerId) : idCenter;
    const tempIdRight = formValue.brandBannerId ? Number(formValue.brandBannerRightId) : idRight;

    const payloadBrandBannerLeft: Banner = {
      id: tempIdLeft,
      name: formValue.name,
      image: formValue.leftImage||[],
      description: formValue.description,
      status: formValue.status.code,
      type: 'BRAND_BANNER_LEFT',
      started_time: dayjs(formValue.validFrom).format('YYYY-MM-DD'),
      ended_time: dayjs(formValue.validTo).format('YYYY-MM-DD'),
      product_id: formValue.productId.code || null,
      merchant_id: formValue.merchantId.code || null,
      url_path: setUrlPath(tempIdLeft),
      url_full: formValue.directTo === 'LANDING_PAGE' ? formValue.landingPage : '',
      promos_id: formValue.promoDetailId.code || null
    };
    const payloadBrandBanner: Banner = {
      id: tempIdCenter,
      name: formValue.name,
      image: formValue.centerImage || [],
      description: formValue.description,
      status: formValue.status.code,
      type: 'BRAND_BANNER',
      started_time: dayjs(formValue.validFrom).format('YYYY-MM-DD'),
      ended_time: dayjs(formValue.validTo).format('YYYY-MM-DD'),
      product_id: formValue.productId.code || null,
      merchant_id: formValue.merchantId.code || null,
      url_path: setUrlPath(tempIdCenter),
      url_full: formValue.directTo === 'LANDING_PAGE' ? formValue.landingPage : '',
      promos_id: formValue.promoDetailId.code || null
    };
    const payloadBrandBannerRight: Banner = {
      id: tempIdRight,
      name: formValue.name,
      image: formValue.rightImage || [],
      description: formValue.description,
      status: formValue.status.code,
      type: 'BRAND_BANNER_RIGHT',
      started_time: dayjs(formValue.validFrom).format('YYYY-MM-DD'),
      ended_time: dayjs(formValue.validTo).format('YYYY-MM-DD'),
      product_id: formValue.productId.code || null,
      merchant_id: formValue.merchantId.code || null,
      url_path: setUrlPath(tempIdRight),
      url_full: formValue.directTo === 'LANDING_PAGE' ? formValue.landingPage : null,
      promos_id: formValue.promoDetailId.code || null
    };

    const { data, error: errorUpsert } = await upsertBanner([payloadBrandBannerLeft, payloadBrandBanner, payloadBrandBannerRight]);
    if (!data && errorUpsert) {
      showFailedToast(errorUpsert.message);
    }
  };

  const setUrlPath = (id?: number) => {
    // ketika typecodenya VIDEO_BANNER | SECTION_FLASH_SALE_BACKGROUND && directo landing_page, set urlpath dengan input landingpage
    if (formValue.directTo === 'LANDING_PAGE' && (formValue.type.code === 'VIDEO_BANNER' || formValue.type.code === 'SECTION_FLASH_SALE_BACKGROUND')) {
      return formValue.landingPage;
    }
    // ketika edit set urlpath dengan /page/bannername-bannerid
    if (id) return formValue.directTo === 'LANDING_PAGE' ? `/page/${formValue.name.replace(/\s/g, '-')}-${id}` : null;
    // ketika tidak ada id (create / insert) maka akan null
    return null;
  };

  const handleSubmitForm = useCallback(
    async (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();
      const brandBanner = ['BRAND_BANNER_LEFT', 'BRAND_BANNER_RIGHT', 'BRAND_BANNER'];
      setIsLoading(true);
      if (query.id || query.editId) {
        if (formValue.type.code && brandBanner.includes(formValue.type.code)) {
          const payloadBrandBannerLeft: Banner = {
            id: formValue.brandBannerLeftId,
            name: formValue.name,
            image: [
              {
                id: 1,
                image: formValue.leftImageBrand[0].image
              }
            ] || [],
            description: formValue.description,
            status: formValue.status.code,
            type: 'BRAND_BANNER_LEFT',
            started_time: null,
            ended_time: null,
            product_id: formValue.productId.code || null,
            merchant_id: formValue.merchantId.code || null,
            url_path: setUrlPath(formValue.brandBannerLeftId),
            url_full: formValue.directTo === 'LANDING_PAGE' ? formValue.landingPage : '',
            promos_id: formValue.promoDetailId.code || null
          };
          const payloadBrandBanner: Banner = {
            id: formValue.brandBannerId,
            name: formValue.name,
            image: [
              {
                id: 1,
                image: formValue.centerImageBrand[0].image
              }
            ] || [],
            description: formValue.description,
            status: formValue.status.code,
            type: 'BRAND_BANNER',
            started_time: null,
            ended_time: null,
            product_id: formValue.productId.code || null,
            merchant_id: formValue.merchantId.code || null,
            url_path: setUrlPath(formValue.brandBannerId),
            url_full: formValue.directTo === 'LANDING_PAGE' ? formValue.landingPage : '',
            promos_id: formValue.promoDetailId.code || null
          };
          const payloadBrandBannerRight: Banner = {
            id: formValue.brandBannerRightId,
            name: formValue.name,
            image: [
              {
                id: 1,
                image: formValue.rightImageBrand[0].image
              }
            ] || [],
            description: formValue.description,
            status: formValue.status.code,
            type: 'BRAND_BANNER_RIGHT',
            started_time: null,
            ended_time: null,
            product_id: formValue.productId.code || null,
            merchant_id: formValue.merchantId.code || null,
            url_path: setUrlPath(formValue.brandBannerRightId),
            url_full: formValue.directTo === 'LANDING_PAGE' ? formValue.landingPage : null,
            promos_id: formValue.promoDetailId.code || null
          };

          const { data, error: errorUpsert } = await upsertBanner([payloadBrandBannerLeft, payloadBrandBanner, payloadBrandBannerRight]);
          if (errorUpsert) {
            showFailedToast(errorUpsert.message);
            return;
          }

          const leftPayloadBanner: IBrandBanner[] = formValue.leftImageBrand.map((banner)=> {
            return {
              id: banner.id,
              banner_id: data?.find((b)=> b.type === 'BRAND_BANNER_LEFT')?.id as number || null,
              image: banner.image,
              direct_to: banner.directTo,
              product_id: banner.directTo === 'PDP' ? banner.productId.code : null,
              promos_id: banner.directTo === 'PROMO_DETAIL_PAGE' ? banner.promosId?.code : null,
              merchant_id: banner.directTo === 'STORE_DETAIL' ? banner.merchantId?.code : null,
              url_full: banner.directTo === 'LANDING_PAGE' ? banner.urlFull : null,
              type: banner.type,
              tag_id: banner.directTo === 'PROMO_TAGGING' ? banner.tagId?.code : null,
              started_time: banner.validFrom,
              ended_time: banner.validTo,
              status: banner.status.code,
              sequence: banner.sequence
            } as IBrandBanner;
          });

          const centerPayloadBanner: IBrandBanner[] = formValue.centerImageBrand.map((banner)=> {
            return {
              id: banner.id,
              banner_id: data?.find((b)=> b.type === 'BRAND_BANNER')?.id as number || null,
              image: banner.image,
              direct_to: banner.directTo,
              product_id: banner.directTo === 'PDP' ? banner.productId.code : null,
              promos_id: banner.directTo === 'PROMO_DETAIL_PAGE' ? banner.promosId?.code : null,
              merchant_id: banner.directTo === 'STORE_DETAIL' ? banner.merchantId?.code : null,
              url_full: banner.directTo === 'LANDING_PAGE' ? banner.urlFull : null,
              type: banner.type,
              tag_id: banner.directTo === 'PROMO_TAGGING' ? banner.tagId?.code : null,
              started_time: banner.validFrom,
              ended_time: banner.validTo,
              status: banner.status.code,
              sequence: banner.sequence
            } as IBrandBanner;
          });

          const rightPayloadBanner: IBrandBanner[] = formValue.rightImageBrand.map((banner)=> {
            return {
              id: banner.id,
              banner_id: data?.find((b)=> b.type === 'BRAND_BANNER_RIGHT')?.id as number || null,
              image: banner.image,
              direct_to: banner.directTo,
              product_id: banner.directTo === 'PDP' ? banner.productId.code : null,
              promos_id: banner.directTo === 'PROMO_DETAIL_PAGE' ? banner.promosId?.code : null,
              merchant_id: banner.directTo === 'STORE_DETAIL' ? banner.merchantId?.code : null,
              url_full: banner.directTo === 'LANDING_PAGE' ? banner.urlFull : null,
              type: banner.type,
              tag_id: banner.directTo === 'PROMO_TAGGING' ? banner.tagId?.code : null,
              started_time: banner.validFrom,
              ended_time: banner.validTo,
              status: banner.status.code,
              sequence: banner.sequence
            } as IBrandBanner;
          });

          const finalPayloadBrand = [...leftPayloadBanner, ...centerPayloadBanner, ...rightPayloadBanner];
          await upsertBrandBanner(finalPayloadBrand);

          const payloadBannerAppliedStreet = data.map((b) => {
            return {
              id: String(b.banner_applied_streets && b.banner_applied_streets[0].id),
              banner_id: String(b.id),
              street_id: String(formValue.street?.code)
            };
          });

          const { error: errorAppliedStreet } = await upsertBannerAppliedStreet(payloadBannerAppliedStreet);
          if (errorAppliedStreet) {
            showFailedToast(errorAppliedStreet.message);
          }
          showSuccessToast();
          return;
        }

        if (formValue.type.code === 'SECTION_BACKGROUND') {
          const payloadBanner: Banner = {
            id: Number(query.editId),
            created_at: null,
            description: formValue.description,
            ended_time: dayjs(formValue.validTo).format('YYYY-MM-DD'),
            image: [
              {
                id: 1,
                image: formValue.desktop || '',
                image_mobile: formValue.mobile || '',
                thumbnail: formValue.thumbnail
              }
            ],
            name: formValue.name,
            started_time: dayjs(formValue.validFrom).format('YYYY-MM-DD'),
            status: formValue.status.code,
            updated_at: null,
            merchant_id: formValue.merchantId.code || null,
            product_id: formValue.productId.code || null,
            url_path: setUrlPath(Number(query.editId)),
            url_full: formValue.directTo === 'LANDING_PAGE' ? formValue.landingPage : null,
            promos_id: formValue.promoDetailId.code || null
          };

          const payloadBannerBackground = formValue.sectionType.map((item) => {
            return {
              ...payloadBanner,
              type: item.code
            };
          });

          const { data: bannerBackground, error: errorBannerBackground } = await upsertBanner(payloadBannerBackground);

          if (!bannerBackground || errorBannerBackground) {
            showFailedToast(errorBannerBackground.message);
            return;
          }

          const payloadAppliedStreet = bannerBackground.map((b) => {
            if (Array.isArray(b.banner_applied_streets) && b.banner_applied_streets.length > 0) {
              return {
                id: String(b.banner_applied_streets && b.banner_applied_streets[0].id),
                banner_id: String(b.id),
                street_id: String(formValue.street?.code)
              };
            }

            return {
              banner_id: String(b.id),
              street_id: String(formValue.street?.code)
            };
          });

          const { error: errorAppliedStreet } = await upsertBannerAppliedStreet(payloadAppliedStreet);
          if (errorAppliedStreet) {
            showFailedToast(errorAppliedStreet.message);
            return;
          }
          showSuccessToast();
          return;
        }

        const payloadBanner: Banner = {
          id: Number(query.editId),
          created_at: null,
          description: formValue.description,
          ended_time: dayjs(formValue.validTo).format('YYYY-MM-DD'),
          image: [
            {
              id: 1,
              image: formValue.desktop || '',
              image_mobile: formValue.mobile || '',
              thumbnail: formValue.thumbnail
            }
          ],
          category_id: !Array.isArray(formValue.categoryId) ? formValue.categoryId.code : null,
          name: formValue.name,
          started_time: dayjs(formValue.validFrom).format('YYYY-MM-DD'),
          status: formValue.status.code,
          type: formValue.type.code,
          updated_at: null,
          merchant_id: formValue.merchantId.code || null,
          product_id: formValue.productId.code || null,
          url_path: setUrlPath(Number(query.editId)),
          url_full: formValue.directTo === 'LANDING_PAGE' ? formValue.landingPage : null,
          promos_id: formValue.promoDetailId.code || null
        };

        const { data: banner, error: errorBanner } = await upsertBanner([payloadBanner]);

        if (!banner || errorBanner) {
          showFailedToast(errorBanner.message);
          return;
        }
        const payloadBannerAppliedStreet = banner.map((b) => {
          if (Array.isArray(b.banner_applied_streets) && b.banner_applied_streets?.length > 0) {
            return {
              id: String(b?.banner_applied_streets && b.banner_applied_streets[0].id),
              banner_id: String(b.id),
              street_id: String(formValue.street?.code)
            };
          }
          return {
            banner_id: String(b.id),
            street_id: String(formValue.street?.code)
          };
        });

        const { error: errorAppliedStreet } = await upsertBannerAppliedStreet(payloadBannerAppliedStreet);
        if (errorAppliedStreet) {
          showFailedToast(errorAppliedStreet.message);
          return;
        }
      } else {
        //insert banner brand
        if (formValue.type.code === 'BRAND_BANNER') {
          const payloadLeftBanner: Banner = {
            name: formValue.name,
            image: [
              {
                id: 1,
                image: formValue.leftImageBrand[0].image
              }
            ] || [],
            description: formValue.description,
            status: formValue.status.code,
            type: 'BRAND_BANNER_LEFT',
            started_time: null,
            ended_time: null,
            product_id: formValue.productId.code || null,
            merchant_id: formValue.merchantId.code || null,
            url_path: setUrlPath(),
            url_full: formValue.directTo === 'LANDING_PAGE' ? formValue.landingPage : null,
            promos_id: formValue.promoDetailId.code || null,
            size: formValue.sizeLeft?.code || null
          };
          const payloadRightBanner: Banner = {
            name: formValue.name,
            image: [
              {
                id: 1,
                image: formValue.rightImageBrand[0].image
              }
            ] || [],
            description: formValue.description,
            status: formValue.status.code,
            type: 'BRAND_BANNER_RIGHT',
            started_time: null,
            ended_time: null,
            product_id: formValue.productId.code || null,
            merchant_id: formValue.merchantId.code || null,
            url_path: setUrlPath(),
            url_full: formValue.directTo === 'LANDING_PAGE' ? formValue.landingPage : null,
            promos_id: formValue.promoDetailId.code || null,
            size: formValue.sizeRight?.code || null
          };
          const payloadCenterBanner: Banner = {
            name: formValue.name,
            image: [
              {
                id: 1,
                image: formValue.centerImageBrand[0].image
              }
            ] || [],
            description: formValue.description,
            status: formValue.status.code,
            type: 'BRAND_BANNER',
            started_time: null,
            ended_time: null,
            product_id: formValue.productId.code || null,
            merchant_id: formValue.merchantId.code || null,
            url_path: setUrlPath(),
            url_full: formValue.directTo === 'LANDING_PAGE' ? formValue.landingPage : null,
            promos_id: formValue.promoDetailId.code || null,
            size: formValue.sizeCenter?.code || null
          };

          const payloadBulk = [payloadCenterBanner, payloadLeftBanner, payloadRightBanner];

          const { data: brandBanner, error: errorInsertBrandBanner } = await insertBanner(payloadBulk);

          if (formValue.directTo === 'LANDING_PAGE' && brandBanner) handleEditBrandBanner(brandBanner[0].id, brandBanner[1].id, brandBanner[2].id);

          const leftPayloadBanner: IPayloadBrandBanner[] = formValue.leftImageBrand.map((banner)=> {
            return {
              banner_id: brandBanner?.find((b)=> b.type === 'BRAND_BANNER_LEFT')?.id as number || null,
              image: banner.image,
              direct_to: banner.directTo,
              product_id: banner.directTo === 'PDP' ? banner.productId.code : null,
              promos_id: banner.directTo === 'PROMO_DETAIL_PAGE' ? banner.promosId?.code : null,
              merchant_id: banner.directTo === 'STORE_DETAIL' ? banner.merchantId?.code : null,
              url_full: banner.directTo === 'LANDING_PAGE' ? banner.urlFull : null,
              type: banner.type,
              tag_id: banner.directTo === 'PROMO_TAGGING' ? banner.tagId?.code : null,
              started_time: banner.validFrom,
              ended_time: banner.validTo,
              status: banner.status.code,
              sequence: banner.sequence
            } as IPayloadBrandBanner;
          });

          const centerPayloadBanner: IPayloadBrandBanner[] = formValue.centerImageBrand.map((banner)=> {
            return {
              banner_id: brandBanner?.find((b)=> b.type === 'BRAND_BANNER')?.id as number || null,
              image: banner.image,
              direct_to: banner.directTo,
              product_id: banner.directTo === 'PDP' ? banner.productId.code : null,
              promos_id: banner.directTo === 'PROMO_DETAIL_PAGE' ? banner.promosId?.code : null,
              merchant_id: banner.directTo === 'STORE_DETAIL' ? banner.merchantId?.code : null,
              url_full: banner.directTo === 'LANDING_PAGE' ? banner.urlFull : null,
              type: banner.type,
              tag_id: banner.directTo === 'PROMO_TAGGING' ? banner.tagId?.code : null,
              started_time: banner.validFrom,
              ended_time: banner.validTo,
              status: banner.status.code,
              sequence: banner.sequence
            } as IPayloadBrandBanner;
          });

          const rightPayloadBanner: IPayloadBrandBanner[] = formValue.rightImageBrand.map((banner)=> {
            return {
              banner_id: brandBanner?.find((b)=> b.type === 'BRAND_BANNER_RIGHT')?.id as number || null,
              image: banner.image,
              direct_to: banner.directTo,
              product_id: banner.directTo === 'PDP' ? banner.productId.code : null,
              promos_id: banner.directTo === 'PROMO_DETAIL_PAGE' ? banner.promosId?.code : null,
              merchant_id: banner.directTo === 'STORE_DETAIL' ? banner.merchantId?.code : null,
              url_full: banner.directTo === 'LANDING_PAGE' ? banner.urlFull : null,
              type: banner.type,
              tag_id: banner.directTo === 'PROMO_TAGGING' ? banner.tagId?.code : null,
              started_time: banner.validFrom,
              ended_time: banner.validTo,
              status: banner.status.code,
              sequence: banner.sequence
            } as IPayloadBrandBanner;
          });

          const finalPayloadBrand = [...leftPayloadBanner, ...centerPayloadBanner, ...rightPayloadBanner];
          await insertBrandBanner(finalPayloadBrand);
          if (errorInsertBrandBanner || !brandBanner) {
            showFailedToast(errorInsertBrandBanner.message);
            return;
          }

          const dataIds = brandBanner.map((brandBanner) => {
            return {
              banner_id: String(brandBanner.id),
              started_time: null,
              ended_time: null,
              merchant_id: formValue.merchantId.code || null
            };
          });

          const { error: errorInsertPopularBrand } = await insertBannerBrandId(dataIds);
          if (errorInsertPopularBrand) {
            showFailedToast(errorInsertPopularBrand.message);
            return;
          }

          const payloadBannerApplied = brandBanner.map((banner) => {
            return {
              banner_id: String(banner.id),
              street_id: String(formValue.street?.code)
            };
          });

          const { error: errorInsertAppliedStreet } = await insertBannerAppliedStreet(payloadBannerApplied);
          if (errorInsertAppliedStreet) {
            showFailedToast(errorInsertAppliedStreet.message);
            return;
          }

          showSuccessToast();
          setFormValue(initBannerFormValue);
          fileInput?.current?.clear();
          return;
        }

        //insert banner category
        if (formValue.type.code === 'PRODUCT_CATEGORY_BANNER') {
          const payloadBannerCategory: Banner[] =
            (Array.isArray(formValue.categoryId) &&
              formValue.categoryId.map((category) => {
                return {
                  name: formValue.name,
                  image: [
                    {
                      id: 1,
                      image: formValue.desktop || '',
                      image_mobile: formValue.mobile || ''
                    }
                  ],
                  created_at: null,
                  description: formValue.description,
                  status: formValue.status.code,
                  type: formValue.type.code,
                  updated_at: null,
                  started_time: dayjs(formValue.validFrom).format('YYYY-MM-DD'),
                  ended_time: dayjs(formValue.validTo).format('YYYY-MM-DD'),
                  category_id: category.code,
                  url_path: formValue.directTo === 'LANDING_PAGE' ? formValue.urlPath : null,
                  url_full: formValue.directTo === 'LANDING_PAGE' ? formValue.landingPage : null
                };
              })) ||
            [];

          const { data: bannerCategories, error: errorBannerCategory } = await insertBanner(payloadBannerCategory);

          if (!bannerCategories || errorBannerCategory) {
            showFailedToast(errorBannerCategory.message);
            return;
          }

          const payloadBannerApplied = bannerCategories.map((banner) => {
            return {
              banner_id: String(banner.id),
              street_id: String(formValue.street?.code)
            };
          });

          const { error: errorInsertAppliedStreet } = await insertBannerAppliedStreet(payloadBannerApplied);
          if (errorInsertAppliedStreet) {
            showFailedToast(errorInsertAppliedStreet.message);
            return;
          }

          showSuccessToast();
          setFormValue(initBannerFormValue);
          fileInput?.current?.clear();
          return;
        }

        if (formValue.type.code === 'SECTION_BACKGROUND') {
          const payloadBanner: Banner = {
            created_at: null,
            description: formValue.description,
            ended_time: dayjs(formValue.validTo).format('YYYY-MM-DD'),
            image: [
              {
                id: 1,
                image: formValue.desktop || '',
                image_mobile: formValue.mobile || '',
                thumbnail: formValue.thumbnail
              }
            ],
            name: formValue.name,
            started_time: dayjs(formValue.validFrom).format('YYYY-MM-DD'),
            status: formValue.status.code,
            updated_at: null,
            merchant_id: formValue.merchantId.code || null,
            product_id: formValue.productId.code || null,
            url_path: setUrlPath(),
            url_full: formValue.directTo === 'LANDING_PAGE' ? formValue.landingPage : null,
            promos_id: formValue.promoDetailId.code || null
          };

          const payloadBannerBackground = formValue.sectionType.map((item) => {
            return {
              ...payloadBanner,
              type: item.code
            };
          });
          const { data: bannerBackground, error: errorBannerBackground } = await insertBanner(payloadBannerBackground);

          if (bannerBackground) handleEditBanner(bannerBackground[0].id);

          if (!bannerBackground || errorBannerBackground) {
            showFailedToast(errorBannerBackground.message);
            return;
          }

          const payloadBannerApplied = bannerBackground.map((banner) => {
            return {
              banner_id: String(banner.id),
              street_id: String(formValue.street?.code)
            };
          });

          const { error: errorInsertAppliedStreet } = await insertBannerAppliedStreet(payloadBannerApplied);
          if (errorInsertAppliedStreet) {
            showFailedToast(errorInsertAppliedStreet.message);
            return;
          }

          showSuccessToast();
          setFormValue(initBannerFormValue);
          fileInput.current?.clear();
          return;
        }

        //insert formvalue to banner
        const payloadBanner: Banner = {
          created_at: null,
          description: formValue.description,
          ended_time: formValue.validTo ? dayjs(formValue.validTo).format('YYYY-MM-DD') : null,
          image: [
            {
              id: 1,
              image: formValue.desktop || '',
              image_mobile: formValue.mobile || '',
              thumbnail: formValue.thumbnail
            }
          ],
          name: formValue.name,
          started_time: formValue.validFrom ? dayjs(formValue.validFrom).format('YYYY-MM-DD') : null,
          status: formValue.status.code,
          type: formValue.type.code,
          updated_at: null,
          merchant_id: formValue.merchantId.code || null,
          product_id: formValue.productId.code || null,
          url_path: setUrlPath(),
          url_full: formValue.directTo === 'LANDING_PAGE' ? formValue.landingPage : null,
          promos_id: formValue.promoDetailId.code || null
        };
        const { data: banner, error: errorBanner } = await insertBanner([payloadBanner]);
        // jika directo landing_page dan insert berhasil
        // dan typecode bukan video_banner dan section flashsale background
        // maka akan melakukan update url_path
        if (
          formValue.type.code !== 'VIDEO_BANNER' &&
          formValue.type.code !== 'SECTION_FLASH_SALE_BACKGROUND' &&
          formValue.directTo === 'LANDING_PAGE' &&
          banner
        ) handleEditBanner(banner[0].id);

        if (!banner || errorBanner) {
          showFailedToast(errorBanner.message);
          return;
        }

        if (formValue.type.code !== 'PROMO_BANNER') {
          //insert banner id to banner_applied_street
          const payloadBannerApplied = banner.map((banner) => {
            return {
              banner_id: String(banner.id),
              street_id: String(formValue.street?.code)
            };
          });
          const { error: errorInsertAppliedStreet } = await insertBannerAppliedStreet(payloadBannerApplied);
          if (errorInsertAppliedStreet) {
            showFailedToast(errorInsertAppliedStreet.message);
            return;
          }
        }
      }
      showSuccessToast();
      setFormValue(initBannerFormValue);
      fileInput?.current?.clear();
    },
    [formValue, fileInput, query.editId, query.id]
  );

  const handleClickCancel = useCallback(() => {
    navigate('/frontstore-management/banner');
  }, []);

  const handleClickSave = useCallback(()=>{
    setTabMenu(1);
  }, []);


  const getLabelType = (type: string) => {
    switch (type) {
    case 'BRAND_BANNER':
    case 'BRAND_BANNER_RIGHT':
    case 'BRAND_BANNER_LEFT':
      return 'Brand Banner';
    case 'VIDEO_BANNER':
    case 'SECTION_FLASH_SALE_BACKGROUND':
      return 'Section Background';
    default:
      return '';
    }
  };


  const defaultBanner = ['BRAND_BANNER', 'BRAND_BANNER_RIGHT', 'BRAND_BANNER_LEFT', 'VIDEO_BANNER', 'SECTION_FLASH_SALE_BACKGROUND'];

  useEffect(() => {
    if (query.id || query.editId) {
      setFormValue((prev) => ({
        ...prev,
        description: selectedBanner?.description || '',
        name: selectedBanner?.name || '',
        street: {
          code: (selectedBanner?.banner_applied_streets && selectedBanner?.banner_applied_streets[0]?.street_id) || '',
          label:
            (selectedBanner?.banner_applied_streets && selectedBanner?.banner_applied_streets[0]?.streets?.name) || ''
        },
        status: {
          code: String(selectedBanner?.status) || '',
          label: String(selectedBanner?.status === 'ACTIVE' ? 'Enabled' : 'Disabled') || ''
        },
        type: {
          code: ['VIDEO_BANNER', 'SECTION_FLASH_SALE_BACKGROUND'].includes(String(selectedBanner?.type)) ? 'SECTION_BACKGROUND' : String(selectedBanner?.type),
          label: defaultBanner.includes(String(selectedBanner?.type)) ? getLabelType(selectedBanner?.type as string) :
            String(optionBannerType.find((type) => type.code === selectedBanner?.type)?.label)
        },
        validFrom: selectedBanner?.started_time || '',
        validTo: selectedBanner?.ended_time || '',
        directTo:
          (selectedBanner?.product_id && 'PDP') ||
          (selectedBanner?.merchant_id && 'STORE_DETAIL') ||
          (selectedBanner?.promos_id && 'PROMO_DETAIL_PAGE') ||
          (selectedBanner?.url_full && 'LANDING_PAGE') ||
          '',
        productId: {
          code: selectedBanner?.product_id || null,
          label: optionListProduct.find((p) => p.code === selectedBanner?.product_id)?.label || null
        },
        merchantId: {
          code: selectedBanner?.merchant_id || null,
          label: optionListMerchants.find((m) => m.code === selectedBanner?.merchant_id)?.label || null
        },
        promoDetailId: {
          code: selectedBanner?.promos_id || null,
          label: optionListPromos.find((p) => p.code === selectedBanner?.promos_id)?.label || null
        },
        categoryId: {
          code: selectedBanner?.category_id || null,
          label: optionsListCategories.find((category) => category.code === selectedBanner?.category_id)?.label || null
        },
        centerImage: selectedBanner?.image?.map((img) => {
          return {
            id: img.id,
            image: img.image
          };
        }),
        landingPage: selectedBanner?.url_full || initBannerFormValue.landingPage,
        urlPath: selectedBanner?.url_path || initBannerFormValue.urlPath,
        desktop: selectedBanner?.image[0]?.image,
        mobile: selectedBanner?.image[0]?.image_mobile,
        thumbnail: selectedBanner?.image[0]?.thumbnail,
        sectionType: ['VIDEO_BANNER', 'SECTION_FLASH_SALE_BACKGROUND'].includes(String(selectedBanner?.type)) ?
          optionsListSection.filter((i) => i.code == String(selectedBanner?.type)) : []
      }));

      if (selectedBrandBannerGroup) {
        setIdentityBrandBanner({
          categoryId: selectedBanner?.category_id || '',
          merchantId: selectedBanner?.merchant_id || '',
          productId: selectedBanner?.product_id || '',
          promosId: selectedBanner?.promos_id || '',
          urlFull: selectedBanner?.url_full || '',
          urlPath: selectedBanner?.url_path || '',
          createdAt: selectedBanner?.created_at || ''
        });
        const bannerLeftSize = selectedBrandBanner?.find((banner)=>banner.type === 'BRAND_BANNER_LEFT')?.size || 'MINI';
        const bannerCenterSize = selectedBrandBanner?.find((banner)=>banner.type === 'BRAND_BANNER')?.size || 'LARGE';
        const bannerRightSize = selectedBrandBanner?.find((banner)=>banner.type === 'BRAND_BANNER_RIGHT')?.size || 'MINI';
        const brandBannerLeftId = selectedBrandBanner?.find((banner)=>banner.type === 'BRAND_BANNER_LEFT')?.id || 0;
        const brandBannerId = selectedBrandBanner?.find((banner)=>banner.type === 'BRAND_BANNER')?.id || 0;
        const brandBannerRightId = selectedBrandBanner?.find((banner)=>banner.type === 'BRAND_BANNER_RIGHT')?.id || 0;

        getAllBrandBanner([brandBannerLeftId, brandBannerId, brandBannerRightId]).then(async ({ data })=> {
          if (data !== null && formValue.type.code === 'BRAND_BANNER') {
            const merchantIds = data.map((d)=> d.merchant_id).filter((m)=> m !== null);
            const productIds = data.map((d)=> d.product_id).filter((m)=> m !== null);
            const tagIds = data.map((d)=> d.tag_id).filter((m)=> m !== null);

            await getDefaultOptionListMerchant(merchantIds as string[]);
            await getDefaultOptionListProduct(productIds as string[]);
            await getDefaultOptionListTagging(tagIds as string[]);

            const mappedBrandData = data.map((d) => {
              return {
                id: d.id,
                bannerId: d.banner_id,
                image: d.image,
                directTo: d.direct_to,
                status: {
                  code: String(d?.status) || '',
                  label: String(d?.status === 'ACTIVE' ? 'Enabled' : 'Disabled') || ''
                },
                productId: {
                  code: d?.product_id || null,
                  label: optionListProduct.find((p) => p.code === d?.product_id)?.label || null
                },
                promosId: {
                  code: d?.promos_id || null,
                  label: optionListPromos.find((p) => p.code === d?.promos_id)?.label || null
                },
                merchantId: {
                  code: d?.merchant_id || null,
                  label: optionListMerchants.find((m) => m.code === d?.merchant_id)?.label || null
                },
                urlFull: d?.url_full,
                tagId: {
                  code: d?.tag_id || null,
                  label: optionsTagging.find((m) => m.code === d?.tag_id)?.label || null
                },
                sequence: d.sequence,
                type: d.type,
                validFrom: d.started_time,
                validTo: d.ended_time
              };
            }) as unknown as IFormBrandBanner[];

            setFormValue((prev)=>({
              ...prev,
              leftImage: selectedBrandBanner?.find((banner)=>banner.type === 'BRAND_BANNER_LEFT')?.image,
              centerImage: selectedBrandBanner?.find((banner)=>banner.type === 'BRAND_BANNER')?.image,
              rightImage: selectedBrandBanner?.find((banner)=>banner.type === 'BRAND_BANNER_RIGHT')?.image,
              sizeLeft: {
                code: bannerLeftSize,
                label: optionSize.find((size)=>size.code===bannerLeftSize)?.label || 'Mini'
              },
              sizeCenter: {
                code: bannerCenterSize,
                label: optionSize.find((size)=>size.code===bannerCenterSize)?.label || 'Large'
              },
              sizeRight: {
                code: bannerRightSize,
                label: optionSize.find((size)=>size.code===bannerRightSize)?.label || 'Mini'
              },
              brandBannerId,
              brandBannerLeftId,
              brandBannerRightId,
              leftImageBrand: mappedBrandData.filter((d) => d.type === 'left').sort((a, b)=> a.sequence - b.sequence) as IFormBrandBanner[],
              centerImageBrand: mappedBrandData.filter((d) => d.type === 'center').sort((a, b)=> a.sequence - b.sequence) as IFormBrandBanner[],
              rightImageBrand: mappedBrandData.filter((d) => d.type === 'right').sort((a, b)=> a.sequence - b.sequence) as IFormBrandBanner[]
            }));
          }
        });
      }

      formikUpload.setValues({
        desktop: selectedBanner?.image[0]?.image || '',
        mobile: selectedBanner?.image[0]?.image_mobile || '',
        thumbnail: selectedBanner?.image[0]?.thumbnail || '',
        type: selectedBanner?.type || '',
        name: selectedBanner?.name || '',
        leftImageBrand: selectedBrandBanner?.find((banner)=>banner.type === 'BRAND_BANNER_LEFT')?.image,
        centerImageBrand: selectedBrandBanner?.find((banner)=>banner.type === 'BRAND_BANNER')?.image,
        rightImageBrand: selectedBrandBanner?.find((banner)=>banner.type === 'BRAND_BANNER_RIGHT')?.image,
        status: String(selectedBanner?.status === 'ACTIVE' ? 'Enabled' : 'Disabled') || ''
      });
    }
  }, [query, selectedBanner, optionsListCategories, selectedBrandBannerGroup, refetch]);

  function isObjectFilled(obj) {
    if (!obj.validFrom || !obj.validTo || !obj.directTo) {
      return false;
    }

    if (
      (!obj.productId.code && !obj.productId.label) &&
      (!obj.promosId.code && !obj.promosId.label) &&
      (!obj.merchantId.code && !obj.merchantId.label) &&
      (!obj.tagId.code && !obj.tagId.label) &&
      !obj.urlFull && !obj.landingPage
    ) {
      return false;
    }
    return true;
  }

  useEffect(()=> {
    setRefetch(!refetch);
  }, [formValue.brandBannerId]);

  const leftImageBrandValidate = useMemo(()=> {
    const validateTemps = formValue.leftImageBrand?.map((form)=> isObjectFilled(form));
    if (validateTemps.includes(false)) {
      return false;
    }
    return true;
  }, [formValue.leftImageBrand]);

  const centerImageBrandValidate = useMemo(()=> {
    const validateTemps = formValue.centerImageBrand?.map((form)=> isObjectFilled(form));
    if (validateTemps.includes(false)) {
      return false;
    }
    return true;
  }, [formValue.centerImageBrand]);

  const rightImageBrandValidate = useMemo(()=> {
    const validateTemps = formValue.rightImageBrand?.map((form)=> isObjectFilled(form));
    if (validateTemps.includes(false)) {
      return false;
    }
    return true;
  }, [formValue.rightImageBrand]);

  const disableButtonSubmit = useMemo(() => {
    if (formValue.type.code === 'BRAND_BANNER' &&
    leftImageBrandValidate &&
    centerImageBrandValidate &&
    rightImageBrandValidate &&
    formValue.street?.code !== '' &&
    formValue.name !== ''
    ) {
      return false;
    }

    if (formValue.directTo != '' && (formValue.productId.code || formValue.promoDetailId.code || formValue.merchantId.code || formValue.landingPage) && formValue.type.code !== 'BRAND_BANNER') {
      return false;
    }

    if (formValue.type.code == 'PROMO_BANNER') {
      return false;
    }
    return Boolean(query.id || tabMenu !== 2);
  }, [formValue, query, tabMenu, leftImageBrandValidate, centerImageBrandValidate, rightImageBrandValidate]);

  const disableSaveImage = useMemo(() => {
    if (formValue.type.code === 'BRAND_BANNER' && imageType === 'leftImage' &&
      leftImageBrandValidate
    ) {
      return false;
    }
    if (formValue.type.code === 'BRAND_BANNER' && imageType === 'centerImage' &&
      centerImageBrandValidate
    ) {
      return false;
    }
    if (formValue.type.code === 'BRAND_BANNER' && imageType === 'rightImage' &&
      rightImageBrandValidate
    ) {
      return false;
    }
    return true;
  }, [formValue, query, tabMenu, leftImageBrandValidate, centerImageBrandValidate, rightImageBrandValidate]);

  const renderImageType = useCallback((type) => {
    switch (type) {
    case 'leftImage':
      return 'Left Image';
    case 'centerImage':
      return 'Center Image';
    case 'rightImage':
      return 'Right Image';
    default:
      return '';
    }
  }, []);

  const handleHoverOptions = useCallback((label:string|null, value:string|null)=>()=>{
    setFormValue((prev)=>({
      ...prev,
      previewType: { code: value, label: label }
    }));
  }, []);

  const handleClickToggleDropdown = useCallback(()=>{
    setIsOpenDropdown(!isOpenDropdown);
  }, [isOpenDropdown]);

  const handleOnChangeBannerTypeDropdown = useCallback((value:IBannerOptions)=>()=>{
    setFormValue((prev)=>({
      ...prev,
      type: value
    }));
    formikUpload.setFieldValue('type', value.code);
    setIsOpenDropdown(false);
  }, []);

  return {
    data: {
      optionStreet,
      optionBannerType,
      optionBannerStatus,
      optionDirectTo,
      optionSize,
      optionsListCategories,
      optionListMerchants,
      optionListProduct,
      optionListPromos,
      formValue,
      refToast,
      query,
      fileInput,
      tabMenu,
      optionTabMenu,
      streetCount,
      imageType,
      validateDate,
      selectedBanner,
      isLoadingBannerDetail,
      isLoadingUpload,
      formikUpload,
      optionLandingPage,
      disableButtonSubmit,
      isLoading,
      isOpenDropdown,
      optionsListSection,
      currentBrand,
      optionsTagging,
      disableSaveImage
    },
    method: {
      handleOnChangeInput,
      handleOnChangeDropdown,
      handleSubmitForm,
      handleClickCancel,
      handleUploadImage,
      handleClickAddStreet,
      handleCLickDetailBanner,
      handleClickSubStreet,
      handleAddMultipleImage,
      handleClickSaveMultipleImage,
      handleOnChangeDate,
      handleClickSave,
      isFormFieldValid,
      setTabMenu,
      findMerchants,
      findProducts,
      renderImageType,
      handleHoverOptions,
      handleClickToggleDropdown,
      handleOnChangeBannerTypeDropdown,
      handleDropDownBrandBanner,
      findTagging
    }
  };
};

export default useCustom;
