import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import usePromoTaggingEdit from './hooks';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import { Dropdown } from 'primereact/dropdown';
import { headerProduct, headerStore, optionAction, optionShowDescription, optionStatus } from '../helpers';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import dayjs from 'dayjs';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { IProductVariant, IStore } from '../type';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';

const PromoTaggingCreate = () => {
  const { data, method } = usePromoTaggingEdit();

  const footerContent = (
    <div>
      <Button label="Cancel" icon="pi pi-times" onClick={() => method.onCancel()} className="p-button-text" />
      <Button label="Confirm" icon="pi pi-check" onClick={method.onSubmit} autoFocus />
      {data.validate ?
        (<p className="w-full mt-3 text-end text-sm text-red-600">
          {data.selected.length} {data.formEdit.category === 'newProduct' ? 'product variant' : 'store '} Selected (max. 6)
        </p>
        ) :
        (<p className="w-full mt-3 text-end text-sm text-gray-600">
          {data.selected.length} {data.formEdit.category === 'newProduct' ? 'product variant' : 'store '} Selected (max. 6)
        </p>
        )}
    </div>
  );
  const prevPage = () => {
    return (
      <Button rounded icon="pi pi-angle-left" disabled={Boolean(data.paginator.currentPage===1)} outlined severity="secondary" onClick={method.handleClickPrev} className="!border-none"/>
    );
  };
  const nextPage = () => {
    return (
      <Button rounded icon="pi pi-angle-right" disabled={Boolean(data.paginator.currentPage===data.totalPages)} outlined severity="secondary" onClick={method.handleClickNext} className="!border-none"/>
    );
  };
  const firstPage = () => {
    return (
      <Button rounded icon="pi pi-angle-double-left" outlined severity="secondary" onClick={method.handleClickFirstPage} className="!border-none"/>
    );
  };
  const lastPage = () => {
    return (
      <Button rounded icon="pi pi-angle-double-right" outlined severity="secondary" onClick={method.handleClickLastPage} className="!border-none"/>
    );
  };
  const pageLink = ()=>{
    const lastPage = data.totalPages - 4;
    if (data.totalPages > 1 && data.totalPages <=5) {
      return Array.from({ length: data.totalPages }).map((_, index)=>{
        const pages = index+1;
        return <Button onClick={method.handleClickPage(pages)} className={`!border-none !font-thin ${pages === data.paginator.currentPage && '!text-blue-700 !bg-blue-100'}`} rounded outlined severity="secondary" key={pages} label={String(pages)}/>;
      });
    }
    if (data.totalPages>5) {
      return Array.from({ length: 5 }).map((_, index)=>{
        const pages = (data.paginator.currentPage >= data.totalPages-3 && lastPage + index) || (data.paginator.currentPage <= 3 ? index + 1 : index + data.paginator.currentPage - 2);
        return <Button onClick={method.handleClickPage(pages)} className={`!border-none !font-thin ${pages === data.paginator.currentPage && '!text-blue-700 !bg-blue-100'}`} rounded outlined severity="secondary" key={pages} label={String(pages)}/>;
      });
    }
    return <Button onClick={method.handleClickPage(1)} className="!border-none !font-thin !text-blue-700 !bg-blue-100" rounded outlined severity="secondary" label="1"/>;
  };
  const dropdownOptions = (options) => {
    const dropdownOptions = [
      { label: 10, value: 10 },
      { label: 25, value: 25 },
      { label: 50, value: 50 },
      { label: 100, value: 100 }
    ];
    const autoDisabled: boolean = options.totalRecords === 0;

    return <Dropdown value={data.perPage} options={dropdownOptions} onChange={method.handleChangeDropdownPage} disabled={autoDisabled} />;
  };
  const paginationTemplate = {
    'layout': 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
    'PrevPageLink': prevPage,
    'NextPageLink': nextPage,
    'FirstPageLink': firstPage,
    'LastPageLink': lastPage,
    'PageLinks': pageLink,
    'RowsPerPageDropdown': dropdownOptions
  };
  const getFormErrorMessage = (name) => {
    return method.isFormFieldValid(name) && <small className="p-error">{data.formik.errors[name]}</small>;
  };

  return (
    <BaseContainer>
      <Toast ref={data.toast} />
      <LoadingOverlay show={data.isLoading} />
      <Dialog id="promo-tagging-dialog" header={data.formEdit.category === 'newProduct' ? 'List Produk' : 'List Store'} visible={data.visible} style={{ width: '50vw' }} onHide={() => method.setVisible(false)} footer={footerContent}>
        <div id="search" className="mb-4">
          <InputText className="w-full" placeholder="Search With 3 Letters or More" type="search" onChange={(e) => method.handleSearch(e.target.value)} />
        </div>
        <DataTable
          selectionPageOnly paginator rows={data.perPage}
          paginatorTemplate={paginationTemplate}
          id="promo-tagging-data-table"
          value={data.formEdit.category === 'newProduct' ? data.dataProductVariants : data.dataStores}
          selectionMode="multiple"
          selection={data.tempSelected}
          onSelectionChange={(e) => {
            const value = data.formEdit.category === 'newProduct' ? e.value as IProductVariant[] : e.value as IStore[];
            method.setTempSelected(value);
          }}
          dataKey="id"
          tableStyle={{ minWidth: '50rem' }}>
          <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
          {data.formEdit.category === 'newProduct' ? headerProduct.map((item) =>
            <Column key={item.field} field={item.field} header={item.header} />
          ) : headerStore.map((item) =>
            <Column key={item.field} field={item.field} header={item.header} />
          )}
        </DataTable>
      </Dialog>
      <MainTitle isWithButton={false} title="Edit Promo Tagging" />
      <div className="border p-6">
        <div className="flex">
          <div id="title" className="w-1/4">
            <div className="border border-solid px-16 py-3 font-bold bg-gray-400">PROMO TAGGING INFORMATION</div>
            <div className="border border-solid px-16 py-3 font-normal text-center" />
          </div>
          <LoadingOverlay show={data.isLoading} />
          <div className="border p-10 w-full space-y-3">
            <div className="grid grid-cols-3">
              <p className="grid items-center">
                Promo Tagging Category*
              </p>
              <div className="grid items-center">
                <InputText
                  disabled
                  value={data.formEdit.category}
                />
              </div>
            </div>
            {data.formEdit.category !== 'storeRecommendation' &&
              <>
                <div className="grid grid-cols-3">
                  <p className="grid items-center">
                    Promo Tagging Name*
                  </p>
                  <div id="promo-tagging-category-tagging-name" className="grid items-center">
                    <InputText
                      id="promo-tagging-name-input"
                      name="name"
                      value={data.formEdit?.name}
                      onChange={(e) => method.onChangeFormEditPromotagging('name', e.target.value)}
                    />
                    {getFormErrorMessage('name')}
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  <p className="grid items-center">
                    Description
                  </p>
                  <div id="promo-tagging-category-description" className="grid items-center">
                    <InputText
                      id="promo-tagging-description-input"
                      value={data.formEdit?.description}
                      onChange={(e) => method.onChangeFormEditPromotagging('description', e.target.value)}
                    />
                  </div>
                </div>
              </>
            }

            {(data.formEdit.category === 'topKeywordList') &&
              <div className="grid grid-cols-3">
                <p className="grid items-center">
                  Show Description
                </p>
                <div className="grid items-center">
                  <Dropdown
                    id="promo-tagging-show-description-show-description"
                    value={data.formEdit.showDescription}
                    onChange={(e) => method.onChangeFormEditPromotagging('showDescription', e.value)}
                    options={optionShowDescription}
                    optionValue="code"
                    optionLabel="name"
                    placeholder="Show Description"
                    className="w-full md:w-14rem" />
                </div>
              </div>
            }

            {(data.formEdit.category === 'newProduct' || data.formEdit.category === 'storeRecommendation') &&
              <>
                <div className="grid grid-cols-3">
                  <div className="grid items-center">
                    {data.formEdit.category === 'newProduct' ? 'Select Product' : 'Select Store'}
                  </div>
                  <div className="grid items-center">
                    <Button id="promo-tagging-add-button"
                      label={data.formEdit.category === 'newProduct' ? '+ Add Product' : '+ Add Store'}
                      onClick={() => method.handleOpenModal()}
                    />
                    {(<p className="w-full mt-3 text-end text-sm text-blue-600">
                      {data.selected.length} {data.formEdit.category === 'newProduct' ? 'product variant' : 'store '} Selected (max. 6)
                    </p>)}
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  <p className="grid items-center">
                    Action*
                  </p>
                  <div id="promo-tagging-category-action" className="grid items-center">
                    <Dropdown
                      id="promo-tagging-action-dropdown-input-action"
                      value={data.formEdit.action}
                      onChange={(e)=>method.onChangeFormEditPromotagging('action', e.value)}
                      options={optionAction}
                      optionLabel="name"
                      optionValue="code"
                      placeholder="Select Action" className="w-full md:w-14rem" />
                  </div>
                  {getFormErrorMessage('action')}
                </div>
              </>
            }
            {(data.formEdit.category === 'topKeywordList' || data.formEdit.category === 'keywordRecommendation') && (
              <>
                <div id="apply-to-street" className="grid grid-cols-3">
                  <p id="apply-street-label" className="grid items-center">
                    Direct To*
                  </p>
                  <div className="grid items-center" id="dropdown_field">
                    <div className="card flex flex-col">
                      <Dropdown
                        id="promo-tagging-category-apply-street"
                        value={data.formEdit.directTo}
                        optionLabel="label"
                        className="w-full"
                        optionValue="code"
                        placeholder="Select"
                        options={data.formEdit.category === 'keywordRecommendation' ? data.optionKeywordRecomendations : data.optionDirectTo}
                        onChange={(e) => method.onChangeFormEditPromotagging('directTo', e.value)}
                      />
                      {getFormErrorMessage('directTo')}
                    </div>
                  </div>
                </div>
                {data.formEdit.directTo === 'STORE_DETAIL' && (
                  <div id="apply-to-street" className="grid grid-cols-3">
                    <div id="apply-street-label" className="grid items-center" />
                    <div className="grid items-center" id="dropdown_field">
                      <div className="card flex flex-col">
                        <Dropdown
                          id="promo-tagging-category-merchant-list"
                          value={data.formEdit.merchantId}
                          className="w-full h-full"
                          placeholder={data.detailMerchantPromo as string}
                          filter
                          filterBy="label,code"
                          options={data.optionStores as []}
                          onChange={(e) => method.onChangeFormEditPromotagging('merchantId', e.value)}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => method.findStores(e.target.value)}
                          resetFilterOnHide
                        />
                        {getFormErrorMessage('merchantId')}
                      </div>
                    </div>
                  </div>
                )}

                {(data.formEdit.directTo === 'PDP') && (
                  <div className="grid grid-cols-3">
                    <div className="grid items-center" />
                    <div className="grid items-center" id="dropdown_field">
                      <div className="card flex flex-col">
                        <Dropdown
                          id="promo-tagging-category-products-list"
                          value={data.formEdit.productId}
                          className="w-full"
                          placeholder={data.detailProductPromo as string}
                          filter
                          filterBy="label,code"
                          options={data.optionProducts as []}
                          onChange={(e) => method.onChangeFormEditPromotagging('productId', e.value)}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => method.findProducts(e.target.value)}
                          resetFilterOnHide
                        />
                        {getFormErrorMessage('productId')}
                      </div>
                    </div>
                  </div>
                )
                }
                {data.formEdit.directTo === 'PROMO_DETAIL_PAGE' && (
                  <div className="grid grid-cols-3">
                    <div className="grid items-center" />
                    <div className="grid items-center" id="dropdown_field">
                      <div className="card flex flex-col">
                        <Dropdown
                          id="promo-tagging-category-promos-list"
                          value={data.formEdit.promoDetailId}
                          className="w-full"
                          placeholder={data.detailPromo as string}
                          filter
                          filterBy="label,code"
                          options={data.optionPromos as []}
                          onChange={(e) => method.onChangeFormEditPromotagging('promoDetailId', e.value)}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => method.findPromos(e.target.value)}
                          resetFilterOnHide
                        />
                        {getFormErrorMessage('promoDetailId')}

                      </div>
                    </div>
                  </div>
                )}
                {data.formEdit.directTo === 'LANDING_PAGE' && (
                  <div className="grid grid-cols-3">
                    <div className="grid items-center" />
                    <div className="grid items-center" id="dropdown_field">
                      <div className="card flex flex-col">
                        <InputText
                          id="promo-tagging-category-promos-list"
                          value={data.formEdit.landingPageType}
                          onChange={(e) => method.onChangeFormEditPromotagging('landingPageType', e.target.value)}
                          placeholder="https://aladinmall.id/......"
                          className="w-full h-full"
                        />
                        {getFormErrorMessage('landingPageType')}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="grid grid-cols-3">
              <p className="grid items-center">
                Valid From*
              </p>
              <div className="grid items-center">
                <Calendar
                  showIcon
                  id="promo-tagging-valid-from-valid-from-input"
                  placeholder={data.formEdit.validFrom}
                  value={data.formEdit.validFrom}
                  onChange={(e: CalendarChangeEvent) => method.onChangeFormEditPromotagging('validFrom',
                    dayjs(e.target.value as string).format('YYYY-MM-DD'))}
                  dateFormat="yy-mm-dd" hideOnDateTimeSelect
                  minDate={new Date()}
                  maxDate={new Date(data.formEdit.validTo)}
                />
                {getFormErrorMessage('validFrom')}
              </div>
            </div>
            <div className="grid grid-cols-3">
              <p className="grid items-center">
                Valid To*
              </p>
              <div className="grid items-center gap-2">
                <Calendar id="promo-tagging-valid-to-valid-to-input"
                  showIcon
                  inputStyle={{
                    border: data.validateDate ? '1px solid red' : ''
                  }}
                  placeholder={data.formEdit.validTo}
                  value={data.formEdit.validTo}
                  onChange={(e: CalendarChangeEvent) => method.onChangeFormEditPromotagging('validTo',
                    dayjs(e.target.value as string).format('YYYY-MM-DD'))}
                  dateFormat="yy-mm-dd" hideOnDateTimeSelect
                  disabled={!Boolean(data.formEdit.validFrom)}
                  minDate={data.formEdit.validFrom == '' ? new Date() : new Date(data.formEdit.validFrom)}
                />
                {getFormErrorMessage('validTo')}
                {data.validateDate && (
                  <span className="text-red-500 text-sm">End time must be greater than start time</span>
                )}
              </div>
            </div>

            {data.formEdit.category === 'topKeywordList' &&
              <div className="grid grid-cols-3">
                <p className="grid items-center">
                  Apply to Street*
                </p>
                <div className="grid items-center">
                  <MultiSelect
                    id="promo-tagging-street-multiselect-input"
                    value={data.selectedStreet}
                    onChange={(e) => {
                      method.setSelectedStreet(e.value);
                      data.formik.setFieldValue('street', e.value.toString());
                    }}
                    options={data.optionStreet}
                    optionLabel="label"
                    optionValue="label"
                    placeholder="Select Street"
                    maxSelectedLabels={3} className="w-full md:w-20rem" />
                  {getFormErrorMessage('street')}
                </div>
              </div>
            }
            <div className="grid grid-cols-3">
              <p className="grid items-center">
                Status*
              </p>
              <div className="grid items-center">
                <Dropdown
                  id="promo-tagging-status-dropdown-input"
                  value={data.formEdit.status}
                  onChange={(e) => method.onChangeFormEditPromotagging('status', e.value)}
                  optionValue="code"
                  options={optionStatus} optionLabel="name"
                  placeholder="Select Status" className="w-full md:w-14rem" />
                {getFormErrorMessage('status')}
              </div>
            </div>
          </div>

        </div>
      </div>
      <SubmitButton
        disabledSubmit={Boolean(data.validateDate) || data.isLoading}
        onSubmit={data.formik.handleSubmit}
        onCancel={method.handleClickCancel}
        labelBack="Cancel"
        labelSubmit="Submit"
      />
    </BaseContainer>
  );
};

export default PromoTaggingCreate;
