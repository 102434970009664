import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import Categories from '@/views/Category/Category';
import { ChipsChangeEvent } from 'primereact/chips';
import {
  deleteSpecificationPreset,
  insertSpecification,
  insertSpecificationPreset,
  Specification,
  upsertSpecification,
  upsertSpecificationPreset,
  useDetailSpecification
} from '@/services/rest/specification';
import { InputSwitchChangeEvent } from 'primereact/inputswitch';
import { useFormik } from 'formik';
import { schemaCreateSpecification } from './validation';

export interface Values {
  id: number;
  label: string;
}
export interface FormSpecification {
  name: string;
  value: string[];
  label: string;
  description: string;
  type: string;
  is_active: boolean;
  instruction: string;
}
export interface Categories {
  id: number;
  name: string;
}

const useCustom = () => {
  const params = useParams();
  const initialFormSpecification: FormSpecification = {
    name: '',
    value: [],
    instruction: '',
    description: '',
    is_active: false,
    label: '',
    type: ''
  };

  const initialFormikState = {
    name: '',
    value: [],
    label: '',
    type: ''
  };

  const [SpecificationValues, setSpecificationValues] = useState<Array<string>>([]);
  const [formSpecification, setFormSpecification] = useState<FormSpecification>(initialFormSpecification);
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState<Categories | null>(null);

  const showErrorFieldName = () => {
    toast.current?.show({ severity: 'error', summary: 'Error', detail: "Specification name can't be empty!", life: 3000 });
  };
  const showErrorDuplicateName = () => {
    toast.current?.show({
      severity: 'error',
      summary: 'Error',
      detail: `${formSpecification.name} already exist!`,
      life: 3000
    });
  };

  const formik = useFormik({
    initialValues: initialFormikState,
    validationSchema: schemaCreateSpecification,
    onSubmit: () => {
      validateNameSpecification();
    }
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const resetForm = useCallback(() => {
    setFormSpecification(initialFormSpecification);
    setSpecificationValues([]);
    setSelectedCategory(null);
  }, []);

  const { data: dataDetailSpecification } = useDetailSpecification(params.id || params.detailId);

  const onChangeInput =
    (key: string) =>
      ({ currentTarget }: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement, Event>) => {
        formik.setFieldValue(key, currentTarget.value);
        setFormSpecification((prevState) => {
          return {
            ...prevState,
            [key]: currentTarget.value
          };
        });
      };

  const onChangeToggle = (event: InputSwitchChangeEvent) => {
    formik.setFieldValue('is_active', event.value);
    setFormSpecification((prev) => ({
      ...prev,
      is_active: Boolean(event.value)
    }));
  };

  const onChangeChips = (e: ChipsChangeEvent) => {
    formik.setFieldValue('value', e.value);
    setFormSpecification((prev) => ({
      ...prev,
      value: e.value as []
    }));
  };

  const validateNameSpecification = async () => {
    if (!formSpecification.name) {
      showErrorFieldName();
      return;
    }
    const query = supabase.from(TABLE.SPESIFICATION).select('name').limit(10).is('deleted_at', null).ilike('name', `%${formSpecification.name}%`);
    if (params.id) {
      query.neq('id', params.id);
    }
    const { data } = await query;
    if (data != null) {
      if (data.length > 0) {
        showErrorDuplicateName();
      } else {
        onSubmitForm();
      }
    }
  };

  // TODO - Add toast for failed or success
  const showSuccess = () => {
    if (toast.current != null) {
      toast.current.show({
        severity: 'success',
        summary: 'Created',
        detail: 'Specification saved successfully',
        life: 2500
      });
      setTimeout(() => {
        navigate('/specification');
      }, 3000);
    }
  };
  const showFailed = () => {
    if (toast.current != null) {
      toast.current?.show({
        severity: 'error',
        summary: 'Failed',
        detail: 'Specification failed to save',
        life: 3000
      });
    }
  };

  const onSubmitForm = useCallback(async () => {
    // TODO - Add check if formSpecificaiton is empty
    if (params.id != null) {
      const payloadUpsert: Specification = {
        id: params.id,
        description: formSpecification.description,
        is_active: formSpecification.is_active,
        label: formSpecification.label,
        name: formSpecification.name,
        type: formSpecification.type,
        instruction: formSpecification.instruction
      };
      const payloadUpsertPreset = dataDetailSpecification?.data?.specification_presets?.map((item, index) => {
        return {
          id: item.id,
          specification_id: params.id,
          name: formSpecification.value[index]
        };
      });
      const newInsertPayload = formSpecification.value.splice(payloadUpsertPreset?.length as number).map((item)=> {
        return {
          specification_id: params.id,
          name: item
        };
      });
      const deletePayload = payloadUpsertPreset?.filter((item)=> !formSpecification.value.includes(item.name) && item.name === undefined).map((i)=> i.id);
      const { error: errorUpsert } = await upsertSpecification(payloadUpsert);
      const { error: errorUpPreset } = await upsertSpecificationPreset(payloadUpsertPreset as []);

      if (newInsertPayload.length !== 0) {
        const { error: errorInsertNew } = await insertSpecificationPreset(newInsertPayload as []);
        if (errorInsertNew) showFailed();
      }

      if (deletePayload?.length !== 0) {
        const { error: errorDelete } = await deleteSpecificationPreset(deletePayload as []);
        if (errorDelete) showFailed();
      }

      if (errorUpsert || errorUpPreset) {
        showFailed();
      } else {
        showSuccess();
        resetForm();
      }
    } else {
      const payloadInsert: Specification = {
        description: formSpecification.description,
        is_active: formSpecification.is_active,
        label: formSpecification.label,
        name: formSpecification.name,
        type: formSpecification.type,
        instruction: formSpecification.instruction
      };
      const { data: specId, error: errorInsert } = await insertSpecification(payloadInsert);
      if (specId) {
        const payloadPreset = formSpecification.value.map((item)=> {
          return {
            name: item,
            specification_id: specId.id
          };
        });
        const { error: errPreset } = await insertSpecificationPreset(payloadPreset as []);
        if (!errPreset) {
          showSuccess();
          resetForm();
        }
      }
      if (errorInsert) {
        showFailed();
      }
    }
  }, [formSpecification]);

  //TODO : CLEAR STATE
  const handleCancelSubmit = useCallback(() => {
    setFormSpecification(initialFormSpecification);
    navigate('/specification');
  }, [initialFormSpecification]);

  const handleCLickEdit = useCallback(() => {
    navigate(`/specification/edit/${params.detailId}`);
  }, []);

  useEffect(() => {
    if (dataDetailSpecification && (params.id || params.detailId)) {
      setFormSpecification({
        description: dataDetailSpecification.data?.description || '',
        instruction: dataDetailSpecification.data?.instruction || '',
        is_active: dataDetailSpecification.data?.is_active || false,
        label: dataDetailSpecification.data?.label || '',
        name: dataDetailSpecification.data?.name || '',
        type: dataDetailSpecification.data?.type || '',
        value: dataDetailSpecification.data?.specification_presets?.map((value) => value.name) || []
      });
      formik.setValues({
        label: dataDetailSpecification.data?.label || '',
        name: dataDetailSpecification.data?.name || '',
        type: dataDetailSpecification.data?.type || '',
        value: (dataDetailSpecification.data?.specification_presets?.map((value) => value.name) as []) || []
      });
    }
  }, [dataDetailSpecification, params]);

  return {
    data: {
      params,
      selectedCategory,
      formSpecification,
      SpecificationValues,
      toast,
      formik
    },
    method: {
      isFormFieldValid,
      onSubmitForm,
      onChangeInput,
      onChangeChips,
      onChangeToggle,
      setSpecificationValues,
      validateNameSpecification,
      resetForm,
      setSelectedCategory,
      handleCancelSubmit,
      handleCLickEdit
    }
  };
};

export default useCustom;
