import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { deleteAttributeSet, IFilterAttribute, useAllAttributeSets } from '@/services/rest/attributeSet';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { useHistoryStore } from '@/store/useHistoryStore';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { IFilterHistoryItems } from '@/components/base/FilterHistory';
import { useDebounce } from 'primereact/hooks';

const useCustom = () => {
  const initialFilter: IFilterAttribute = {
    id_from: null,
    id_to: null,
    name: '',
    description: '',
    status: ''
  };
  const [inputValue, setInputValue] = useState<string>('');
  const [perPage, setPerPage] = useState<number>(10);
  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const currentPage = parseInt(visitedPage.attributeSet.toString()) ?? 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : perPage - 1;
  const [paginator, setPaginator] = useState({
    currentPage,
    range: { start, end }
  });
  const [jumpToPage, setJumpToPage] = useState<number>(1);
  const [itemFilters, setItemFilters] = useState(lastFilterPage.attributeSet != '' ? JSON.parse(String(lastFilterPage.attributeSet)) : initialFilter);
  const [filters, setFilters] = useState(lastFilterPage.attributeSet != '' ? JSON.parse(String(lastFilterPage.attributeSet)) : initialFilter);
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);
  const [search, debounceSearch, setSearch] = useDebounce('', 1500);

  const {
    data: dataAttributeSetResponse,
    isFetching,
    refetch: refetchAttributeSet
  } = useAllAttributeSets(filters, debounceSearch, paginator.range);
  const toast = useRef<Toast>(null);

  const dataAttributeSet = useMemo(() => {
    if (!Array.isArray(dataAttributeSetResponse?.data)) return [];
    return dataAttributeSetResponse?.data;
  }, [dataAttributeSetResponse]);

  const showErrorToast = (msg: string) => {
    toast.current?.show({
      severity: 'error',
      summary: 'Failed',
      detail: msg,
      life: 2000
    });
  };

  const showSucessToast = (msg: string) => {
    toast.current?.show({
      severity: 'success',
      summary: 'Confirmed',
      detail: msg,
      life: 2000
    });
  };

  const handleOnChangeSearch = useCallback(({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    setInputValue(currentTarget.value);
  }, []);

  const deleteDataAttributeSet = async (id: string) => {
    const { error } = await deleteAttributeSet(id);
    if (error) {
      showErrorToast('Delete Attribute Set Failed');
      return;
    }
    showSucessToast('Delete Attribute Set Successfull');
    refetchAttributeSet();
  };

  const handleDelete = useCallback(async (id: string) => {
    confirmDialog({
      message: 'Are you sure you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept() {
        deleteDataAttributeSet(id);
      }
    });
  }, []);

  const handleClearFilter = useCallback(() => {
    setLastFilterPage({
      ...lastFilterPage,
      attributeSet: ''
    });
    setItemFilters(initialFilter);
    setFilters(initialFilter);
    setFilterHistory([]);
    setSearch('');
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [lastFilterPage, perPage]);

  const handleDeleteFilterHistory = useCallback(
    (key: string, value: string[]) => {
      const items = value[0].split(',');
      items.forEach((i) => {
        setFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));

        setItemFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));
      });

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setSearch('');
    },
    []
  );

  const totalRecords = useMemo(() => {
    return dataAttributeSetResponse?.count ? dataAttributeSetResponse.count : 0;
  }, [dataAttributeSetResponse]);

  const totalPages = useMemo(() => {
    return Math.ceil(totalRecords / perPage);
  }, [totalRecords, perPage]);

  const handleClickNext = useCallback(() => {
    paginator.currentPage <= totalPages &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: paginator.currentPage + 1,
        range: {
          start: paginator.range.start + perPage,
          end: paginator.range.end + perPage
        }
      }));
  }, [paginator, totalPages, perPage]);

  const handleClickPrev = useCallback(() => {
    paginator.range.start > 0 &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: prev.currentPage - 1,
        range: {
          start: prev.range.start - perPage,
          end: prev.range.end - perPage
        }
      }));
  }, [paginator, perPage]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent) => {
    setPerPage(event.value);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, totalRecords - 1);

      setPaginator(() => ({
        currentPage: jumpToPage > totalPages ? totalPages : value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, totalRecords]);

  const handleSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>)=>{
    setSearch(currentTarget.value);
    const newKeyword = {
      name: 'Keyword',
      items: [{
        label: currentTarget.value,
        value: currentTarget.value,
        name: currentTarget.value
      }]
    };

    if (currentTarget.value != '') {
      setFilterHistory((prev: IFilterHistoryItems[]) => {
        const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
        if (existingIndex !== -1) {
          prev[existingIndex] = newKeyword;
        } else {
          prev.push(newKeyword);
        }
        return [...prev];
      });

      setPaginator({
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      });
    } else {
      handleDeleteFilterHistory('Keyword', ['Keyword']);
    }
  }, [perPage]);

  const handleFilter = useCallback((e, field: string) => {
    setItemFilters((prev) => ({
      ...prev,
      [field]: e.target ? e.target.value : e.value
    }));
  }, []);

  const handleClickSubmitFilter = useCallback(() => {
    setFilters(itemFilters);
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [itemFilters, perPage]);

  const storeFilterHistory = useCallback((filter: IFilterAttribute) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter.id_from != null) {
      let filterID = filter.id_from.toString();
      if (filter.id_to != null) {
        filterID = `${filter.id_from}-${filter.id_to}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('ID', 'id_from,id_to', filterID));
    }

    if (filter.name !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Name', 'name', filter.name));
    }

    if (filter.description !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Description', 'description', filter.description));
    }

    if (filter.status !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Status', 'status', filter.status));
    }

    setFilterHistory(filterHistoryItems);
  }, []);

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  useEffect(() => {
    // update store visitedPage with latest page
    setVisitedPage({
      ...visitedPage,
      attributeSet: paginator.currentPage
    });
  }, [paginator.currentPage]);

  // update store lastFilterPage with latest filter
  useEffect(() => {
    setLastFilterPage({
      ...lastFilterPage,
      attributeSet: JSON.stringify(filters)
    });
  }, [filters]);

  return {
    data: {
      dataAttributeSet,
      inputValue,
      isFetching,
      toast,
      filterHistory,
      paginator,
      perPage,
      totalPages,
      totalRecords,
      itemFilters,
      search
    },
    methods: {
      handleOnChangeSearch,
      handleDelete,
      handleClickNext,
      handleClickPrev,
      handleChangeDropdownPage,
      handleChangeJumpTopage,
      handleJumpToPage,
      handleClearFilter,
      handleDeleteFilterHistory,
      handleSearch,
      handleFilter,
      handleClickSubmitFilter
    }
  };
};

export default useCustom;
