import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Button } from 'primereact/button';
import { CascadeSelect } from 'primereact/cascadeselect';
import { Checkbox } from 'primereact/checkbox';
import { ColorPicker } from 'primereact/colorpicker';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import useCustom from './hooks';
import { usePermissionStore } from '@/store/usePermissions';

const EditStreet = () => {
  const { data, methods } = useCustom();
  const { canEdit } = usePermissionStore();
  return (
    <BaseContainer>
      {/* loading screen */}
      <LoadingOverlay show={data.isLoadingChildLayout || data.isLoadingParentlayout ||
        data.isLoadingUpdateChild || data.isLoadingUpdateParent || data.isLoadingDeleteSection} />

      <Toast ref={data.toast} position="center" />
      <div className="flex gap-8">
        <div className="w-1/4 flex flex-col gap-4">
          <div id="main-title" className="font-bold lg:text-2xl w-full h-16 flex items-center">
            Edit Page Builder
          </div>
          <div className="w-full flex flex-col gap-2">
            {/* <Checkbox></Checkbox> */}
            {data.streetItems.map((item, index) => {
              return (
                <div key={index} className="flex gap-x-1 items-center">
                  <div
                    draggable
                    onDragStart={() => methods.handleDragStart(index)}
                    onDragEnter={() => methods.handleDragEnter(index)}
                    onDragEnd={methods.handleSort}
                    className="relative w-full border hover:border-transparent rounded-md"
                  >
                    {data.activeIndex === index && !item.isDisabled && (
                      <Button
                        icon={<XMarkIcon />}
                        rounded
                        severity="danger"
                        aria-label="Cancel"
                        style={{ position: 'absolute', top: -10, right: -10, width: 25, height: 25, zIndex: 99 }}
                        onClick={methods.deleteSection(index, item.id)}
                        disabled={!canEdit}
                      />
                    )}

                    <Button
                      label={item.title}
                      onClick={() => {
                        methods.handleClickEditSection(item);
                        methods.handleClick(index);
                      }}
                      severity="secondary"
                      text
                      size="large"
                      style={{ width: '100%' }}
                      disabled={!canEdit}
                    />
                  </div>
                  <label htmlFor="" className="flex ml-1 flex-col justify-center">
                    Mobile
                    <Checkbox checked={!!item.isMobile} disabled={!canEdit} value onChange={() => methods.handleCheckPlatform(item.id, !item.isMobile)} className="self-center" />
                  </label>
                </div>
              );
            })}
            <Button
              label="+Add Section"
              onClick={methods.addSection}
              severity="secondary"
              text disabled={data.selectedSection?.type === 'product-tabulation' ?
                (!Boolean(data.sectionText) || !Boolean(data.productTabulationItems[data.productTabulationItems.length-1].title)) || !canEdit :
                (!Boolean(data.sectionText)) || !canEdit}
              style={{ marginTop: 24 }}
            />
          </div>
        </div>

        <div className="w-3/4 flex flex-col gap-4">
          <div className="flex items-center justify-between w-full h-16">
            <Button label="Back" onClick={methods.handleBack} severity="secondary" />

            <div className="flex items-center gap-2">
              <Button label="Preview" onClick={(e) => {}} severity="info" />
              <Button label="Save Street" onClick={methods.handleSubmitPageBuilder} severity="danger" disabled={!canEdit} />
            </div>
          </div>

          <div className="border rounded-lg">
            <div className="p-4 flex items-center gap-4">
              <CascadeSelect
                value={data.selectedSection}
                onChange={(e) => {
                  methods.handleResetSectionText();
                  methods.handleResetAttributeStyle();
                  methods.setSelectedSection(e.value);
                }} options={data.sections}
                optionLabel="name" optionGroupLabel="name" optionGroupChildren={['subSection']}
                className="w-1/4" placeholder="Select a Section"
                disabled={!canEdit}
              />
              <Button label="Reset" size="small" onClick={methods.handleResetSelectSection} severity="warning" disabled={!canEdit} />
            </div>

            <div className="border-t h-[65vh] flex overflow-y-auto">

              {/* TODO - dynamic based on selected Section */}
              {
                data.selectedSection?.type === 'street' && (
                  <div className="w-1/2 py-8 px-4 flex flex-col gap-8">
                    <div className="flex justify-center items-center gap-4">
                      <span className="flex flex-col gap-2 w-1/2">
                        <label htmlFor="tile-type" className="text-sm">Title Type</label>
                        <Dropdown disabled={!canEdit} inputId="tile-type" value={data.selectedTileType} onChange={(e) => methods.setSelectedTileType(e.value)} options={data.tileType} optionLabel="name" className="w-full md:w-14rem" />
                      </span>
                      {
                        data.selectedTileType?.code === 'ICON' ? (
                          <div className="flex flex-col mt-6 justify-end w-1/2">
                            <div className="flex w-1/2">
                              <label htmlFor="title-type" className="text-lg cursor-pointer flex items-center font-semibold h-full">Upload</label>
                              <input
                                type="file"
                                accept="Image/png, Image/jpg, Image/jpeg"
                                id="title-type"
                                onChange={(e) => methods.onChangeImage(e)}
                                hidden
                                disabled={!canEdit}
                              />
                            </div>
                            <div>
                              {data.iconName && (
                                <p className="text-sm">{data.iconName}</p>
                              )}
                            </div>
                          </div>
                        ) : (
                          <span className="flex flex-col gap-2 w-1/2">
                            <label htmlFor="tile-type" className="text-sm text-transparent">Text</label>
                            <InputText disabled={!canEdit} value={data.title} onChange={(e) => methods.handleSetStreetName(e.target.value)} />
                          </span>
                        )
                      }
                    </div>
                    <span className="flex flex-col gap-2 w-2/3">
                      <label htmlFor="color-palette" className="text-sm">Color Palette</label>
                      <ColorPicker disabled={!canEdit} inputId="color-palette" value={data.color || ''} onChange={(e) => methods.setColor(e.value)} inline />
                    </span>
                  </div>
                )
              }

              {
                data.selectedSection?.type === 'productTabulation' && (
                  <div className="w-1/2 py-8 px-4 flex flex-col gap-8">
                    <span className="flex flex-col gap-2 w-1/2">
                      <label htmlFor="section-name" className="text-sm">Section Name</label>
                      <InputText disabled={!canEdit} id="section-name" value={data.sectionText} onChange={(e) => methods.setSectionText(e.target.value)} />
                    </span>
                    <div className="flex items-start w-full">
                      <div className="text-sm w-1/2 h-[50px] flex items-center">Tab Name</div>
                      <div className="flex flex-col gap-2 w-1/2">
                        {data.productTabulationItems.length > 0 && (
                          <div className="flex flex-col gap-2 mb-6">
                            {data.productTabulationItems.map((item, index) => (
                              <div
                                key={index}
                                className="relative w-full"
                                onClick={methods.selectProductTabulation(item.id)}
                              >
                                {item.isSelected && (
                                  <Button
                                    icon={<XMarkIcon />}
                                    rounded
                                    severity="danger"
                                    aria-label="Cancel"
                                    style={{ position: 'absolute', top: -15, right: -15, width: 25, height: 25, zIndex: 99 }}
                                    onClick={methods.deleteProductTabulation(index)}
                                    disabled={!canEdit}
                                  />
                                )}

                                <InputText
                                  value={item.title}
                                  className="w-full"
                                  onChange={(e) => methods.changeProductTabulation(item.id, e.target.value)}
                                  disabled={!canEdit}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                        <Button
                          label="+Add More"
                          onClick={methods.addProductTabulation}
                          severity="secondary"
                          text disabled={!Boolean(data.productTabulationItems[data.productTabulationItems.length-1]?.title) || !canEdit}
                          style={{ marginBottom: 24 }}
                        />
                      </div>
                    </div>
                  </div>
                )
              }

              {
                (data.selectedSection?.type !== 'productTabulation' && data.selectedSection?.type !== 'street' && data.selectedSection ) && (
                  <div className="w-1/2 py-8 px-4 flex flex-col gap-8">
                    <span className="flex flex-col gap-2 w-1/2">
                      <label htmlFor="section-name" className="text-sm">Section Name</label>
                      <InputText disabled={!canEdit} id="section-name" value={data.sectionText} onChange={(e) => methods.setSectionText(e.target.value)} />
                    </span>
                  </div>
                )
              }

              {data.selectedSection && (
                <div className="px-4 flex flex-col">
                  <div>Attribute</div>
                  <div className="flex items-start w-full">
                    <div className="text-sm w-1/2 h-[50px] flex items-center">Margin Top</div>
                    <div className="flex flex-col gap-2 w-1/2">
                      <div className="flex flex-col gap-2 mb-6">
                        <div className="relative w-full">
                          <InputText
                            value={String(data.attributeStyle.marginTop)}
                            className="w-full"
                            onInput={methods.handleSetAttributeStyle('marginTop', Number(data.activeIndex))}
                            disabled={!canEdit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-start w-full">
                    <div className="text-sm w-1/2 h-[50px] flex items-center">Margin Bottom</div>
                    <div className="flex flex-col gap-2 w-1/2">
                      <div className="flex flex-col gap-2 mb-6">
                        <div className="relative w-full">
                          <InputText
                            value={String(data.attributeStyle.marginBottom)}
                            className="w-full"
                            onInput={methods.handleSetAttributeStyle('marginBottom', Number(data.activeIndex))}
                            disabled={!canEdit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-start w-full">
                    <div className="text-sm w-1/2 h-[50px] flex items-center">Display</div>
                    <div className="flex flex-col gap-2 w-1/2">
                      <div className="flex flex-col gap-2 mb-6">
                        <div className="relative w-full">
                          <InputText
                            value={data.attributeStyle.display}
                            className="w-full"
                            onInput={methods.handleSetAttributeStyle('display', Number(data.activeIndex))}
                            disabled={!canEdit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="w-1/2 border-l">
                {data.selectedSection?.type === 'street' && (
                  <Image
                    src={data?.imageUrl}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseContainer>
  );
};

export default EditStreet;
