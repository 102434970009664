import useCustom, { History, optionStatus, optionTypes } from './hooks';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import Table from '@/components/base/DataTable/DataTable';
import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { exportTable } from '@/utils/exportTable';
import dayjs from 'dayjs';
import { Toast } from 'primereact/toast';
import { usePermissionStore } from '@/store/usePermissions';
import { IPagination } from '@/components/base/Pagination';
import { Calendar } from 'primereact/calendar';

const ImportHistory = () => {
  const { data, methods } = useCustom();
  const { canView } = usePermissionStore();

  const statusTemplate = (history: History) => {
    switch (history.status) {
    case true:
      return <div className="flex gap-2 items-center tex-sm">
        <i className="pi pi-check-circle" style={{ fontSize: 25, color: 'green' }} />
        <p>Done And Success</p>
      </div>;
    case false:
      return <div className="flex gap-1 tex-sm">
        <i className="pi pi-times-circle" style={{ fontSize: 25, color: 'red' }} />
        <p>Done With Error</p>
      </div>;
    default:
      return <DocumentMagnifyingGlassIcon />;
    }
  };

  const progressTemplate = (rowData) => {
    const exportData = Array.isArray(rowData.errors) && rowData.errors?.map((i) => {
      return {
        'Pesan Error': i
      };
    });
    switch (rowData.status) {
    case true:
      return <p>Success</p>;
    case false:
      return <button type="button" disabled={!canView} className="text-[14px] text-blue-700" onClick={() => exportTable(exportData as [], 'Error Report.xlsx')}>Download Report Error</button>;
    default:
      return <DocumentMagnifyingGlassIcon />;
    }
  };

  const dateTemplate = (rowData) => {
    if (rowData.created_at) {
      const date = dayjs(rowData.created_at).format('DD MMM YYYY');
      return <div className="">{date}</div>;
    }
    return <div className="" />;
  };

  const header = [
    { field: 'id', header: 'Proccess ID' },
    { field: 'account_id', header: 'User' },
    { field: 'created_at', header: 'Upload Date', body: dateTemplate },
    { field: 'file_path', header: 'File Name' },
    { field: 'type', header: 'Type' },
    { field: 'row_number', header: 'Data Processed' },
    { field: 'status', header: 'Status', body: statusTemplate },
    { field: 'progress', header: 'Progress', body: progressTemplate }
  ];

  // pagination
  const paginationOptions: IPagination = {
    perPage: data.perPage,
    totalPages: data.totalPages,
    currentPage: data.pagination.currentPage,
    disabledDropdown: data.totalRecords === 0,
    onChangeDropdownPage: methods.handleChangeDropdownPage,
    onClickNext: methods.handleClickNext,
    onClickPrev: methods.handleClickPrev,
    onJumptoPage: methods.handleJumpToPage,
    onChangeJumptoPage: methods.handleChangeJumpTopage
  };

  const filterContent = (
    <div className="flex flex-col gap-4 w-[900px]">
      <div className="w-full flex flex-col items-start gap-2">
        <h1 className="font-bold">ID</h1>
        <div className="flex w-full gap-8">
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Dari</label>
            <InputNumber
              onChange={(e) => {
                methods.handleFilter(e, 'id_from');
              }}
              value={data.itemFilters.id_from}
              name="id_from"
            />
          </div>
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Sampai</label>
            <InputNumber
              onChange={(e) => {
                methods.handleFilter(e, 'id_to');
              }}
              value={data.itemFilters.id_to}
              name="id_to"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full items-center gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">User</label>
          <InputText
            onChange={(e) => {
              methods.handleFilter(e, 'username');
            }}
            value={data.itemFilters.username}
            name="username"
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-file-name" className="font-bold">File Name</label>
          <InputText
            onChange={(e) => {
              methods.handleFilter(e, 'filename');
            }}
            value={data.itemFilters.filename}
            name="filename"
          />
        </div>
      </div>
      <div className="flex w-full items-center gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Upload Date</label>
          <Calendar
            onChange={(e) => methods.handleFilter(e, 'upload_date')}
            value={data.itemFilters.upload_date}
            name="upload_date"
            selectionMode="range"
            showIcon
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-status" className="font-bold">Status</label>
          <Dropdown
            onChange={(e) => {
              methods.handleFilter(e, 'status');
            }}
            value={data.itemFilters.status}
            name="status"
            placeholder="Select Status"
            options={optionStatus}
            optionLabel="label"
            optionValue="code"
            className="w-full"
          />
        </div>
      </div>
      <div className="flex w-full items-center gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-type" className="font-bold">Type</label>
          <Dropdown
            onChange={(e) => {
              methods.handleFilter(e, 'type');
            }}
            value={data.itemFilters.type}
            name="type"
            options={optionTypes}
            placeholder="Select Type"
            optionLabel="label"
            optionValue="code"
            className="w-full"
          />
        </div>
      </div>
    </div>
  );

  return (
    <BaseContainer>
      <Toast ref={data.toast}/>
      <MainTitle title="Import History" />
      <Table
        filter={data.search}
        value={data.listHistory as []}
        onSearch={methods.handleSearch}
        headerTable={header}
        rows={data.totalRecords}
        isCustomAction
        isLoadingTable={data.loading || data.loading}
        paginationOptions={paginationOptions}
        filterContent={filterContent}
        filterHistory={data.filterHistory}
        handleClearFilter={methods.handleClearFilter}
        handleDeleteFilterHistory={methods.handleDeleteFilterHistory}
        onSubmitFilter={methods.handleClickSubmitFilter}
      />
    </BaseContainer>
  );
};

export default ImportHistory;
