import { IPagination } from '@/types/pagination';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { IFilterHistory, useGetHistoryAccount, useGetUserRole } from '@/services/rest/importHistory';
import { useAuthStore } from '@/store/useAuthStore';
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { useHistoryStore } from '@/store/useHistoryStore';
import { IFilterHistoryItems } from '@/components/base/FilterHistory';
import { useDebounce } from 'primereact/hooks';
import dayjs from 'dayjs';

export interface History {
  id: number;
  file_path: string;
  created_at: Date;
  type: string;
  account_id: string;
  status?: boolean;
  process?: string;
}

const initialFilter: IFilterHistory ={
  id_to: null,
  id_from: null,
  username: '',
  filename: '',
  status: null,
  type: '',
  upload_date: []
};

export const optionTypes = [
  { code: 'bulk_upload_product_pilihan', label: 'Product Pilihan' },
  { code: 'bulk_upload_product_tabulation', label: 'Product Tabulation' },
  { code: 'bulk_upload_product_hot_deal', label: 'Product Hot Deal' },
  { code: 'bulk_upload_official_store', label: 'Official Store' },
  { code: 'bulk_upload_category', label: 'Upload Category' },
  { code: 'bulk_update_category', label: 'Update Category' },
  { code: 'bulk_upload_variant', label: 'Upload Variant' },
  { code: 'bulk_update_variant', label: 'Update Variant' },
  { code: 'bulk_upload_specification', label: 'Upload Specification' },
  { code: 'bulk_update_specification', label: 'Update Specification' },
  { code: 'bulk_upload_voucher_code', label: 'Upload Voucher Code' },
  { code: 'bulk_create_flash_sale', label: 'Flash Sale' }
];

export const optionStatus = [
  { code: true, label: 'Done and Succeed' },
  { code: false, label: 'Done with Error' }
];

interface IPaginationState {
  currentPage:number,
  range:IPagination
}

const useCustom = () => {
  const [limitImportHistory, setLimitImportHistory] = useState<number>(100);
  const [pageImportHistory, setPageImportHistory] = useState<number>(1);
  const [dataSearch, setDataSearch] = useState<string>('');
  const [popUpFilter, setPopUpFilter] = useState(false);
  const [idFromFilter, setIdFromFilter] = useState<number>(0);
  const [idToFilter, setIdToFilter] = useState<number>(0);
  const [usernameFilter, setUsernameFilter] = useState<string>('');
  const [fileNameFilter, setFileNameFilter] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [typeFilter, setTypeFilter] = useState<string>('');
  const [perPage, setPerPage] = useState<number>(10);
  const toast = useRef<Toast>(null);
  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const currentPage = parseInt(visitedPage.importHistory.toString()) ?? 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : perPage - 1;
  const [pagination, setPagination] = useState<IPaginationState>({
    currentPage,
    range: {
      start,
      end
    }
  });
  const [jumpToPage, setJumpToPage] = useState<number>(1);
  const [itemFilters, setItemFilters] = useState<IFilterHistory>(lastFilterPage.importHistory != '' ? JSON.parse(String(lastFilterPage.importHistory)) : initialFilter);
  const [filters, setFilters] = useState<IFilterHistory>(lastFilterPage.importHistory != '' ? JSON.parse(String(lastFilterPage.importHistory)) : initialFilter);
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);
  const [search, debounceSearch, setSearch] = useDebounce('', 1500);

  const user = useAuthStore((state) => state.user);

  const { data: userRole } = useGetUserRole(String(user?.id));

  const dataUser = useMemo(() => {
    const temp = userRole?.data?.members;
    const userType = temp && temp[0].teams.code;
    return userType;
  }, [userRole]);

  const { data: dataHistory, isLoading: loading } = useGetHistoryAccount(
    pagination.range,
    String(dataUser),
    String(user?.id),
    filters,
    debounceSearch
  );

  const handleSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>)=>{
    setSearch(currentTarget.value);
    const newKeyword = {
      name: 'Keyword',
      items: [{
        label: currentTarget.value,
        value: currentTarget.value,
        name: currentTarget.value
      }]
    };

    if (currentTarget.value != '') {
      setFilterHistory((prev: IFilterHistoryItems[]) => {
        const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
        if (existingIndex !== -1) {
          prev[existingIndex] = newKeyword;
        } else {
          prev.push(newKeyword);
        }
        return [...prev];
      });

      setPagination({
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      });
    } else {
      handleDeleteFilterHistory('Keyword', ['Keyword']);
    }
  }, [perPage]);

  const totalRecords = useMemo(()=>{
    return dataHistory?.count ? dataHistory?.count : 0;
  }, [dataHistory]);

  const totalPages = useMemo(()=>{
    return Math.ceil(Number(totalRecords) / perPage);
  }, [totalRecords, perPage]);

  const listHistory = useMemo(() => {
    const data = dataHistory?.data;
    const result =
      data?.map((data) => {
        const userData = { id: '', email: '' };
        if (data.accounts != undefined && data.accounts.length > 0 ) {
          const users = data.accounts.filter((item)=> item.id === data.account_id);
          userData.email = users[0]?.email;
          userData.id = users[0]?.id;
        }
        return {
          id: data.id,
          account_id: userData.email,
          created_at: data.created_at,
          errors: data.errors,
          file_path: data.file_path,
          row_number: data.row_number,
          status: data.status,
          type: data.type
        };
      }) || [];

    return result as [];
  }, [dataHistory, user]);

  const changeFilterValue = (key: string, value: number | string) => {
    return setFilters((prevState)=> {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  const onChangeSearch = useCallback(
    (value: string) => {
      setDataSearch(value);
    },
    []
  );

  const onChangeIdFromFilter = useCallback(
    (value: number) => {
      setIdFromFilter(value);
    },
    []
  );
  const onChangeIdToFilter = useCallback(
    (value: number) => {
      setIdToFilter(value);
    },
    []
  );

  const onChangeUsernameFilter = useCallback(
    (value: string) => {
      setUsernameFilter(value);
    },
    []
  );

  const onChangeFileNameFilter = useCallback(
    (value: string) => {
      setFileNameFilter(value);
    },
    []
  );

  const onChangeStatusFilter = useCallback(
    (value) => {
      setStatusFilter(value);
    },
    []
  );

  const onChangeTypeFilter = useCallback(
    (value) => {
      setTypeFilter(value);
    },
    []
  );

  const changeStatusFilter = () => {
    setPopUpFilter(!popUpFilter);
  };

  const onChangeLimitImportHistory = useCallback(
    (value: string) => {
      setLimitImportHistory(parseInt(value));
    },
    [setLimitImportHistory]
  );
  const onChangePageImportHisotry = useCallback(
    (value: string) => {
      setPageImportHistory(parseInt(value));
    },
    [setPageImportHistory]
  );

  const onClickButtonPage = useCallback(
    (direction: string) => () => {
      direction === 'next' ? setPageImportHistory(pageImportHistory + 1) : setPageImportHistory(pageImportHistory - 1);
    },
    [setPageImportHistory, pageImportHistory]
  );

  const handleClickPage = useCallback((page:number)=>()=>{
    setPagination((prev) => ({
      ...prev,
      currentPage: page,
      range: {
        start: page === 1 ? page - 1 : perPage * page - perPage,
        end: page * perPage - 1
      }
    }));
  }, [perPage]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent)=>{
    setPerPage(event.value);
    setPagination((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleClickNext = useCallback(()=>{
    pagination.currentPage <= totalPages &&
    setPagination((prev) => ({
      ...prev,
      currentPage: pagination.currentPage + 1,
      range: {
        start: pagination.range.start + perPage,
        end: pagination.range.end + perPage
      }
    }));
  }, [pagination, totalPages, perPage]);

  const handleClickPrev = useCallback(()=>{
    setPagination((prev) => ({
      ...prev,
      currentPage: prev.currentPage - 1,
      range: {
        start: prev.range.start - perPage,
        end: prev.range.end - perPage
      }
    }));
  }, [pagination, perPage]);

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, totalRecords - 1);

      setPagination(() => ({
        currentPage: jumpToPage > totalPages ? totalPages : value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, totalRecords]);

  const handleFilter = useCallback((e, field: string) => {
    setItemFilters((prev) => ({
      ...prev,
      [field]: e.target ? e.target.value : e.value
    }));
  }, []);

  const handleClearFilter = useCallback(() => {
    setLastFilterPage({
      ...lastFilterPage,
      importHistory: ''
    });
    setFilters(initialFilter);
    setItemFilters(initialFilter);
    setFilterHistory([]);
    setSearch('');
    setPagination({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [perPage]);

  const handleDeleteFilterHistory = useCallback(
    (key: string, value: string[]) => {
      const items = value[0].split(',');
      items.forEach((i) => {
        setFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));

        setItemFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));
      });

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setSearch('');
    },
    []
  );

  const handleClickSubmitFilter = useCallback(() => {
    setFilters(itemFilters);
    setPagination({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [itemFilters]);

  const storeFilterHistory = useCallback((filter: IFilterHistory) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter.id_from != null) {
      let filterID = filter.id_from.toString();
      if (filter.id_to != null) {
        filterID = `${filter.id_from}-${filter.id_to}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('ID', 'id_from,id_to', filterID));
    }

    if (filter?.upload_date && filter.upload_date[0]) {
      let filterDate = `${dayjs(filter.upload_date[0]).format('YYYY-MM-DD')}`;
      if (filter.upload_date.length > 1 && filter.upload_date[1] !== null) {
        filterDate = `${dayjs(filter.upload_date[0]).format('YYYY-MM-DD')} - ${dayjs(filter.upload_date[1]).format('YYYY-MM-DD')}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Upload Date', 'upload_date', filterDate));
    }

    if (filter.username !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Username', 'username', filter.username));
    }

    if (filter.filename !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Filename', 'filename', filter.filename));
    }

    if (filter.type !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Type', 'type', optionTypes.filter((item) => item.code === filter.type)[0].label));
    }

    if (filter.status != null) {
      filterHistoryItems.push(createFilterHistoryItem('Status', 'status', (filter.status ? 'Done And Succeed' : 'Done With Error')));
    }

    setFilterHistory(filterHistoryItems);
  }, []);

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  useEffect(() => {
    // update store visitedPage with latest page
    setVisitedPage({
      ...visitedPage,
      importHistory: pagination.currentPage
    });
  }, [pagination.currentPage]);

  // update store lastFilterPage with latest filter
  useEffect(() => {
    setLastFilterPage({
      ...lastFilterPage,
      importHistory: JSON.stringify(filters)
    });
  }, [filters]);

  return {
    data: {
      loading,
      listHistory,
      limitImportHistory,
      pageImportHistory,
      dataSearch,
      popUpFilter,
      idFromFilter,
      idToFilter,
      usernameFilter,
      fileNameFilter,
      statusFilter,
      typeFilter,
      filters,
      pagination,
      totalRecords,
      totalPages,
      perPage,
      search,
      toast,
      itemFilters,
      filterHistory
    },
    methods: {
      onChangeLimitImportHistory,
      onChangePageImportHisotry,
      onClickButtonPage,
      onChangeSearch,
      changeStatusFilter,
      onChangeIdFromFilter,
      onChangeIdToFilter,
      onChangeUsernameFilter,
      onChangeFileNameFilter,
      onChangeStatusFilter,
      onChangeTypeFilter,
      changeFilterValue,
      handleClickPage,
      handleChangeDropdownPage,
      handleClickNext,
      handleClickPrev,
      handleSearch,
      handleChangeJumpTopage,
      handleJumpToPage,
      handleFilter,
      handleClearFilter,
      handleDeleteFilterHistory,
      handleClickSubmitFilter
    }
  };
};

export default useCustom;
