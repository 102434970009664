import { MenuItem } from 'primereact/menuitem';
import { Menu } from 'primereact/menu';
import './style.css';
import useCustomerInfo from './CustomerInfoHooks';
import CustomerView from './CustomerView/CustomerView';
import AccountInformation from './AccountInformation/AccountInformation';
import SocialAccounts from './SocialAccounts/SocialAccounts';
import Addresses from './Addresses/Addresses';

const CustomerInformation = () => {
  const {
    data: {
      tabMenuActiveIndex,
      sideMenuActiveIndex
    },
    method: {
      setTabMenuActiveIndex,
      setSideMenuActiveIndex
    }
  } = useCustomerInfo();

  const sideMenu: MenuItem[] = [
    { label: 'Customer View', command: () => {
      setSideMenuActiveIndex(0);
    }, className: sideMenuActiveIndex === 0 ? 'bg-white font-bold text-black' : '' },
    { label: 'Account Information', command: () => {
      setSideMenuActiveIndex(1);
    }, className: sideMenuActiveIndex === 1 ? 'bg-white font-bold text-black' : '' },
    { label: 'Addresses', command: () => {
      setSideMenuActiveIndex(2);
    }, className: sideMenuActiveIndex === 2 ? 'bg-white font-bold text-black' : '' },
    { label: 'Social Accounts', command: () => {
      setSideMenuActiveIndex(3);
    }, className: sideMenuActiveIndex === 3 ? 'bg-white font-bold text-black' : '' }
  ];

  const items: MenuItem[] = [
    { label: 'Personal Information' },
    { label: 'Reward Points Balance' }
  ];

  return (
    <div className="flex w-full">
      <div className="flex items-start w-[215px]">
        <Menu id="customer-side-menu-information" className="custom-menu" model={sideMenu} tabIndex={sideMenuActiveIndex}/>
      </div>
      <div className="w-full bg-white border px-6">
        {sideMenuActiveIndex === 0 && <CustomerView items={items} setTabMenuActiveIndex={setTabMenuActiveIndex} tabMenuActiveIndex={tabMenuActiveIndex}/>}
        {sideMenuActiveIndex === 1 && <AccountInformation/>}
        {sideMenuActiveIndex === 2 && <Addresses/>}
        {sideMenuActiveIndex === 3 && <SocialAccounts/>}
      </div>
    </div>
  );
};

export default CustomerInformation;
