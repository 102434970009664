import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import useDetailCustomer from '../../DetailHooks';
import { useState } from 'react';
import { FilterMatchMode } from 'primereact/api';

const Addresses = () => {
  const {
    data: {
      fieldAccountInfo,
      selectedFilter,
      optionsFilter,
      listAddress
    },
    method: {
      setSelectedFilter
    }
  } = useDetailCustomer();

  const initailFilter = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    firstName: { value: null, matchMode: FilterMatchMode.IN },
    lastName: { value: null, matchMode: FilterMatchMode.IN },
    city: { value: null, matchMode: FilterMatchMode.IN },
    country: { value: null, matchMode: FilterMatchMode.IN },
    state: { value: null, matchMode: FilterMatchMode.IN },
    postalCode: { value: null, matchMode: FilterMatchMode.IN },
    streetAddress: { value: null, matchMode: FilterMatchMode.IN },
    phoneNumber: { value: null, matchMode: FilterMatchMode.IN }
  };

  const column = [
    { field: 'firstName', header: 'First Name' },
    { field: 'lastName', header: 'Last Name' },
    { field: 'streetAddress', header: 'Street Address' },
    { field: 'city', header: 'City' },
    { field: 'country', header: 'Country' },
    { field: 'state', header: 'State' },
    { field: 'postalCode', header: 'Zip/Post Code' },
    { field: 'phoneNumber', header: 'Phone' }
  ];
  const [search, setSearch] = useState('');

  const [filter, setFilter] = useState(initailFilter);


  const onSearchFilter = (e) => {
    const value = e.target.value;
    const _filter = { ...filter };
    if (value) {
      // @ts-ignore
      _filter['global'].value = value;
    } else {
      // @ts-ignore
      _filter['global'].value = null;
    }

    setFilter(_filter);
    setSearch(value);
  };

  const onFilterChange = (e: MultiSelectChangeEvent) => {
    const name = e.selectedOption?.name;
    const value = e.value;
    const _filter = { ...filter };

    // set filter
    _filter[name].value = value;

    // set to display in multiple filter
    setSelectedFilter(value);
  };

  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(listAddress as []);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });

      saveAsExcelFile(excelBuffer, `${fieldAccountInfo.first_name || fieldAccountInfo.last_name}_Addresses`);
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  };

  return (
    <BaseContainer>
      <label className="font-bold">
          Adresses
      </label>
      <hr className="text-black mt-2" />
      <div id="customer-default-shipping-address" className="my-4">
        <div className="flex w-full bg-gray-400 h-[50px] items-center font-bold px-4">
          Default Shipping Address
        </div>
        <div className="flex w-full h-[43px] bg-gray-200 items-center font-normal px-4">
          {fieldAccountInfo.address}
        </div>
      </div>
      <div id="customer-default-billing-address" className="my-4">
        <div className="flex w-full bg-gray-400 h-[50px] items-center font-bold px-4">
          Default Billing Address
        </div>
        <div className="flex w-full h-[43px] bg-gray-200 items-center font-normal px-4">
          {fieldAccountInfo.address}
        </div>
      </div>
      <div className="flex justify-between mb-6 mt-8 w-full">
        <InputText
          className="w-[300px]"
          id="customer-search-input"
          placeholder="Search"
          type="search"
          value={search}
          onChange={onSearchFilter}
        />
        <div className="flex gap-x-3">
          <div className="w-[250px]">
            <MultiSelect
              id="customer-filter-input"
              value={selectedFilter}
              onChange={onFilterChange}
              options={optionsFilter as []}
              optionLabel="label"
              optionGroupLabel="label"
              optionGroupChildren="items"
              placeholder="Filter"
              className="w-full md:w-20rem"
              maxSelectedLabels={3}
            />
          </div>
          <div className="w-[150px]">
            <Button id="customer-default-button" className="w-full" label="Default View" severity="secondary" onClick={()=> {}} outlined />
          </div>
          <div className="w-[150px]">
            <Button id="customer-export-button" className="w-full" label="Export" severity="secondary" onClick={exportExcel} data-pr-tooltip="XLS" outlined />
          </div>
        </div>
      </div>
      <DataTable paginator rowsPerPageOptions={[10, 20, 50, 100]}
        filterDisplay="row"
        filters={filter}
        onFilter={(e) => e.filters}
        globalFilterFields={column.map((item) => item.field)}
        emptyMessage="We couldn't find any records"
        rows={10}
        id="customer-list-table"
        dataKey="id"
        value={listAddress as []}
      >
        {column.map((item, index ) => <Column filterField={item.field} key={index} header={item.header} field={item.field} />)}
      </DataTable>
    </BaseContainer>
  );
};

export default Addresses;
