import React, { FC, Fragment } from 'react';
import { SquareCarousel } from '../SquareCarousel';
import { Image } from 'primereact/image';
interface IBrandBanner{
    leftImage:string[]
    centerImage:string[]
    rightImage:string[];
}

export const BrandBanner:FC<IBrandBanner> = ({ centerImage, leftImage, rightImage }) => {
  return (
    <div className="flex justify-center gap-2">
      {/* left */}
      <div className="grid grid-cols-2 flex-1">
        {leftImage.map((item, idx) => {
          return (
            <Fragment key={idx}>
              <Image src={item} width="50px"/>
            </Fragment>
          );
        })}
      </div>

      {/* middle */}
      <SquareCarousel
        images={centerImage}
        size={100}
      />

      {/* right */}
      <div className="grid grid-cols-2 flex-1">
        {rightImage.map((item, idx) => {
          return (
            <Fragment key={idx}>
              <Image src={item} width="50px"/>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
