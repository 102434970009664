import { supabase } from '@/lib/supabase';
import { isActive, Route } from '@/routes';
import { Menu } from '@/services/rest/user';
import { parseJwt } from '@/utils';
import { create } from 'zustand';

declare interface PermissionStore {
  canCreate: boolean;
  canEdit: boolean;
  canDelete: boolean;
  canView: boolean;
  menu: Menu[];
  access: { [k: string]: string | number | boolean }[];
  setPermissions: (routeCollection: Route[], path: string, menu: Menu[], access: { [k: string]: string | number | boolean }[]) => void,
  setData: (key: string, value: boolean | Menu[] | { [k: string]: string | number | boolean }[]) => void
}

export const usePermissionStore = create<PermissionStore>((set) => ({
  canCreate: false,
  canEdit: false,
  canDelete: false,
  canView: false,
  menu: [],
  access: [],
  setData(key, value) {
    set({
      [key]: value
    });
  },
  async setPermissions(routeCollection: Route[], path: string, menu: Menu[] = [], access: { [k: string]: string | number | boolean }[] = []) {
    const u = await supabase.auth.getSession();
    const token = u.data.session?.access_token || '';
    const isSuperAdmin = parseJwt(token)?.role == 'service_role';
    if (isSuperAdmin) {
      set({
        canCreate: true,
        canEdit: true,
        canDelete: true,
        canView: true
      });
      return;
    }

    routeCollection.forEach((rc) => {
      const activeRoute = isActive(rc, path);
      if (activeRoute.isActive) {
        const selectedMenu = menu.find((v) => v.code == activeRoute.route.code);
        access
          .filter((v) => v.menu_id == selectedMenu?.id)
          .forEach((p) => {
            set({
              canCreate: p.create == true,
              canEdit: p.update == true,
              canDelete: p.delete == true,
              canView: p.read == true
            });
          });
      }
    });
  }
}));
