import React, { FC } from 'react';

type ILoadingOverlay = {
  show?: boolean;
  loadingText?: string;
};

const LoadingOverlay: FC<ILoadingOverlay> = ({ show = false, loadingText = 'Loading ...' }) => {
  if (!show) return null;

  return (
    <div className="flex gap-4 justify-center flex-col items-center !fixed z-[99] w-full h-full bg-black/20 top-0 right-0">
      <div
        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      />
      <span className="text-gray-800 font-semibold text-lg">{loadingText}</span>
    </div>
  );
};

export default LoadingOverlay;
