export const optionSection = [
  {
    code: 'Product Pilihan',
    label: 'Product Pilihan'
  },
  {
    code: 'Product Tabulation',
    label: 'Product Tabulation'
  },
  {
    code: 'Product Hot Deal',
    label: 'Product Hot Deal'
  },
  {
    code: 'Official Store',
    label: 'Official Store'
  }
];

export const optionSortBy = [
  {
    code: 'sequence',
    label: 'Sequence'
  },
  {
    code: 'lowest_price',
    label: 'Lowest Price'
  },
  {
    code: 'random',
    label: 'Random'
  }
];

export const optionStatus = [
  {
    code: true,
    label: 'Enable'
  },
  {
    code: false,
    label: 'Disable'
  }
];

export interface IFormEdit {
  id?: number;
  applyToStreet?: string;
  name?: string;
  applyToSection?: string;
  product?: string | number;
  product_variants?: string;
  product_id?: number;
  sortBy?: string;
  sortValue?: string;
  validFrom?: string | Date;
  validTo?: string | Date;
  loading?: boolean;
  merchant_id?: string;
  status?: boolean;
  tabulationId?: number;
  tabulationName?: string;
  applyToTab?: number;
}
