import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FileUpload, FileUploadFilesEvent } from 'primereact/fileupload';
import React, { FC, Ref } from 'react';

interface IModalBulkUpload {
  handleClickDownload: () => void;
  handleChangeFileUpload: (event: FileUploadFilesEvent) => void;
  handleClickUpload: () => void;
  disabledInsert: boolean;
  fileUploadRef: Ref<FileUpload>;
  show: boolean;
  handleCloseModal: () => void;
  modalTitle: string;
  placeholder?: string;
  instruction: Array<IInstruction>;
  isLoadingUpload?:boolean;
}
interface IInstruction {
  title: string;
  subtitle: string;
}
const ModalBulkUpload: FC<IModalBulkUpload> = ({
  handleClickDownload,
  handleChangeFileUpload,
  handleClickUpload,
  disabledInsert,
  fileUploadRef,
  handleCloseModal,
  show,
  modalTitle,
  placeholder,
  instruction,
  isLoadingUpload
}) => {
  return (
    <Dialog
      header={modalTitle}
      visible={show}
      onHide={handleCloseModal}
      breakpoints={{ '960px': '75vw', '641px': '100vw' }}
      className="max-w-[70vw]"
      draggable={false}
    >
      <div className="p-6">
        <ol>
          {instruction.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {index === 0 ? (
                  <>
                    <li className="font-bold list-decimal mb-2">{item.title}</li>
                    <div className="flex justify-between gap-4">
                      <div className="w-2/3">{item.subtitle}</div>
                      <Button label="Download Template" className="h-fit" onClick={handleClickDownload} />
                    </div>
                  </>
                ) : (
                  <div className="w-2/3">
                    <li className="font-bold list-decimal my-2">{item.title}</li>
                    <span>{item.subtitle}</span>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </ol>
        <div className="flex items-center justify-between gap-4 mt-4">
          <FileUpload
            ref={fileUploadRef}
            customUpload
            auto
            uploadHandler={handleChangeFileUpload}
            className="w-2/3"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            headerClassName="!none"
            contentClassName="bg-red"
            emptyTemplate={placeholder}
          />
          <Button icon={isLoadingUpload ? 'pi pi-spin pi-spinner' :''} label={isLoadingUpload ? '' : 'Upload'} className="h-fit min-w-[180px]" onClick={handleClickUpload} disabled={isLoadingUpload || disabledInsert} />
        </div>
      </div>
    </Dialog>
  );
};

export default ModalBulkUpload;
