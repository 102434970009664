import { FileInfo, useBooleanState, useDebounce } from 'akeneo-design-system';
import { createContext, DependencyList, useCallback, useEffect, useMemo, useState } from 'react';

declare interface SpesificationInterface {
  isParent: boolean;
  value: string | null;
}
declare interface FilterProductInterface {
  productId: {
    from: number | null;
    to: number | null;
  };
  price: {
    from: number | null;
    to: number | null;
  };
  specialPrice: {
    from: number | null;
    to: number | null;
  };
  productName: string;
  sku: string;
  status: string | null;
  search: string;
}

export declare interface CategoryProductInterface {
  productId: number;
  productName: string;
  sku: string;
  stock: number;
  status: string;
  price: string;
  specialPrice: string;
  category: string;
}

const useAddCategory = () => {
  const [stepAddCategory, setStepAddCategory] = useState(0);
  const [isCompleteFirstStep, setCompleteFirstStep] = useState(true);
  const [isOpenParentCategory, openParentCategory, closeParentCategory] = useBooleanState(false);
  const [searchParentCategory, setSearchParentCategory] = useState('');
  const [noParentCategory, setNoParentCategory] = useState(false);
  const [collapseSEO, setIsCollapseSEO] = useState(false);
  const [isFollowCategoryName, setIsFollowCategoryName] = useState(false);
  const [isFormGeneralValid, setIsFormGeneralValid] = useState(false);
  const [isFormGeneralSubmitted, setIsFormGeneralSubmitted] = useState(false);
  const debouncedSearchParentCategory = useDebounce(searchParentCategory);
  const [formCategory, setFormCategory] = useState({
    parent: {
      id: '',
      text: ''
    },
    name: '',
    isActive: false,
    description: '',
    icon: null,
    meta: {
      urlKey: '',
      title: '',
      keywords: '',
      description: ''
    }
  });
  const [modalInfoCategory, setModalInfoCategory] = useState({
    isOpen: false,
    title: 'Data belum lengkap',
    illustration: '',
    description: ''
  });

  const [directionTableProduct, setDirectionTableProduct] = useState<'descending' | 'none' | 'ascending' | undefined>(
    'descending'
  );
  const [dataProductsPerPage, setDataProductsPerPage] = useState(100);
  const [pageProduct, setPageProduct] = useState(1);
  const [productsData, setProductsData] = useState<CategoryProductInterface[]>([
    {
      productId: 1234563456,
      productName: 'Tshirt',
      sku: 'TS-1234',
      stock: 100,
      status: 'Aktif',
      price: 'Rp 35.000',
      specialPrice: '-',
      category: 'atasan pria'
    },
    {
      productId: 1246576843,
      productName: 'Celana Panjang',
      sku: 'CP-1234',
      stock: 999,
      status: 'Aktif',
      price: 'Rp 99.000',
      specialPrice: '-',
      category: 'bawahan pria'
    },
    {
      productId: 1423647585,
      productName: 'Celana Pendek',
      sku: 'CP-5678',
      stock: 0,
      status: 'Aktif',
      price: 'Rp 50.000',
      specialPrice: 'Rp 45.000',
      category: 'bawahan pria'
    },
    {
      productId: 6564523122,
      productName: 'Kemeja',
      sku: 'KM-1234',
      stock: 0,
      status: 'TIdak Aktif',
      price: 'Rp 65.000',
      specialPrice: 'Rp 50.000',
      category: 'atasan pria'
    }
  ]);
  const optionDataPerPage = [20, 30, 50, 100, 200, 'Custom'];

  const tableProductColumns = [
    {
      label: 'ID',
      value: 'productId'
    },
    {
      label: 'Nama Produk',
      value: 'productName'
    },
    {
      label: 'SKU',
      value: 'sku'
    },
    {
      label: 'Status',
      value: 'status'
    },
    {
      label: 'Harga',
      value: 'price'
    },
    {
      label: 'Harga Spesial',
      value: 'specialPrice'
    }
  ];

  const [isModalFilterOpen, setIsModalFilterOpen] = useState(false);
  const [isModalMoveCategoryOpen, setIsModalMoveCategoryOpen] = useState(false);
  const [moveCategoryProduct, setMoveCategoryProduct] = useState<CategoryProductInterface>({
    productId: 0,
    productName: '',
    sku: '',
    stock: 0,
    status: '',
    price: '',
    specialPrice: '',
    category: ''
  });
  const [moveCategoryForm, setMoveCategoryForm] = useState({
    notifyMerchant: false,
    newCategory: ''
  });
  const [moveAllCategoryForm, setMoveAllCategoryForm] = useState<{ recentCategory: string, file: FileInfo | null }>({
    recentCategory: '',
    file: null
  });
  const [isModalMoveAllCategoryOpen, setIsModalMoveAllCategoryOpen] = useState(false);
  const [filterProducts, setFilterProducts] = useState<FilterProductInterface>({
    productId: {
      from: null,
      to: null
    },
    price: {
      from: null,
      to: null
    },
    specialPrice: {
      from: null,
      to: null
    },
    productName: '',
    sku: '',
    status: null,
    search: ''
  });
  const [spesifications, setSpesification] = useState<SpesificationInterface[]>([
    {
      isParent: true,
      value: '(ID: 123) Merk'
    },
    {
      isParent: true,
      value: '(ID: 124) Style'
    },
    {
      isParent: false,
      value: null
    }
  ]);
  const [variants, setVariants] = useState<SpesificationInterface[]>([
    {
      isParent: true,
      value: '(ID: 123) Merk'
    },
    {
      isParent: true,
      value: '(ID: 124) Style'
    },
    {
      isParent: false,
      value: null
    }
  ]);
  const [attributeSetFrom, setAttributeSetFrom] = useState<string | null>(null);

  const [activeFilterProduct, setActiveFilterProduct] = useState('');

  useEffect(() => {
    const isFormValid = validateFormGeneral();
    setIsFormGeneralValid(isFormValid && isFormGeneralSubmitted);
    if (isFormValid) {
      setIsFormGeneralSubmitted(false);
    }
  }, [formCategory.name, formCategory.parent, formCategory.meta.title]);

  const errorParentCategory = useMemo(
    () => (isFormGeneralSubmitted && !formCategory.parent.id && !noParentCategory ? 'border-red' : ''),
    [isFormGeneralSubmitted, formCategory.parent.id, noParentCategory]
  );

  const onChangeFilterIdFrom = useCallback(
    (newValue: string) => {
      onSetFilterProduct('productId', {
        ...filterProducts.productId,
        from: +newValue
      });
    },
    [filterProducts]
  );

  const onChangeFilterIdTo = useCallback(
    (newValue: string) => {
      onSetFilterProduct('productId', {
        ...filterProducts.productId,
        to: +newValue
      });
    },
    [filterProducts]
  );

  const onChangeFilterPriceFrom = useCallback(
    (newValue: string) => {
      onSetFilterProduct('price', {
        ...filterProducts.price,
        from: +newValue
      });
    },
    [filterProducts]
  );

  const onChangeFilterPriceTo = useCallback(
    (newValue: string) => {
      onSetFilterProduct('price', {
        ...filterProducts.price,
        to: +newValue
      });
    },
    [filterProducts]
  );

  const onChangeFilterSpecialPriceFrom = useCallback(
    (newValue: string) => {
      onSetFilterProduct('specialPrice', {
        ...filterProducts.specialPrice,
        from: +newValue
      });
    },
    [filterProducts]
  );

  const onChangeFilterSpecialPriceTo = useCallback(
    (newValue: string) => {
      onSetFilterProduct('specialPrice', {
        ...filterProducts.specialPrice,
        to: +newValue
      });
    },
    [filterProducts]
  );

  const onChangeFilterName = useCallback(
    (newValue: string) => {
      onSetFilterProduct('productName', newValue);
    },
    [filterProducts]
  );

  const onChangeFilterSku = useCallback(
    (newValue: string) => {
      onSetFilterProduct('sku', newValue);
    },
    [filterProducts]
  );

  const onChangeFilterStatus = useCallback(
    (newValue: string) => {
      onSetFilterProduct('status', newValue);
    },
    [filterProducts]
  );

  const onSearchProduct = useCallback(
    (search: string) => {
      onSetFilterProduct('search', search);
    },
    [filterProducts]
  );

  const goToTabGeneral = useCallback(() => {
    setStepAddCategory(0);
  }, [stepAddCategory]);

  const goToTabProduct = useCallback(() => {
    setStepAddCategory(1);
  }, [stepAddCategory]);

  const goToTabAttributeSet = useCallback(() => {
    setStepAddCategory(2);
  }, [stepAddCategory]);

  const onOpenModalFilter = useCallback(() => {
    setIsModalFilterOpen(true);
  }, [isModalFilterOpen]);

  const onCloseModalFilter = useCallback(() => {
    setIsModalFilterOpen(false);
  }, [isModalFilterOpen]);

  const onOpenModalMoveCategory = useCallback(() => {
    setIsModalMoveCategoryOpen(true);
  }, [isModalMoveCategoryOpen]);

  const onCloseModalMoveCategory = useCallback(() => {
    setIsModalMoveCategoryOpen(false);
  }, [isModalMoveCategoryOpen]);

  const onOpenModalMoveAllCategory = useCallback(() => {
    setIsModalMoveAllCategoryOpen(true);
  }, [isModalMoveAllCategoryOpen]);

  const onCloseModalMoveAllCategory = useCallback(() => {
    setIsModalMoveAllCategoryOpen(false);
  }, [isModalMoveAllCategoryOpen]);

  const onSelectNewCategory = useCallback(
    (newCategory: string) => {
      setMoveCategoryForm({ ...moveCategoryForm, newCategory });
    },
    [moveCategoryForm]
  );

  const onChangeNotifyMove = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setMoveCategoryForm({
        ...moveCategoryForm,
        notifyMerchant: e.target.checked
      });
    },
    [moveCategoryForm]
  );

  const usePaginatedResults = (fetcher: (page: number) => Promise<unknown[]>, dependencies: DependencyList, shouldFetch = true) => {
    const [results, setResults] = useState<unknown[]>([]);
    const [page, setPage] = useState<number>(0);
    const [isFetching, startFetching, stopFetching] = useBooleanState();
    const [isLastPage, onLastPage, notOnLastPage] = useBooleanState();

    useEffect(() => {
      if (null === results) return;

      setPage(0);
      notOnLastPage();
      // We need this to re-trigger the fetching of results in case of search on first load
      setResults([...results]);
    }, dependencies);

    useEffect(() => {
      if (isFetching || isLastPage || !shouldFetch) return;

      const fetchResults = async () => {
        const newResults = await fetcher(page);

        if (newResults?.['length'] === 0) onLastPage();

        setResults((currentResults) => {
          if (0 === page || null === currentResults) return newResults;

          return [...currentResults, ...newResults];
        });
        stopFetching();
      };

      startFetching();
      void fetchResults();
    }, [page, shouldFetch]);

    useEffect(() => {
      if (shouldFetch) return;

      setPage(0);
      setResults([]);
      notOnLastPage();
    }, [shouldFetch]);

    const fetchNextPage = () => {
      if (isFetching || isLastPage) return;

      setPage((page) => page + 1);
    };

    return [results ?? [], fetchNextPage] as const;
  };
  // parent category search dropdown
  const [itemsParentCategory, handleNextPageParent] = usePaginatedResults(
    (page) => getParentCategory(page, debouncedSearchParentCategory),
    [debouncedSearchParentCategory],
    isOpenParentCategory
  );
  const getParentCategory = (page: number, search: string): Promise<unknown[]> => {
    const items = [
      {
        id: `name_${page}`,
        text: `Name (page ${page})`
      },
      {
        id: `collection_${page}`,
        text: 'Collection'
      },
      {
        id: `description_${page}`,
        text: 'Description'
      },
      {
        id: `brand_${page}`,
        text: 'Brand'
      },
      {
        id: `response_time_${page}`,
        text: 'Response time (ms)'
      },
      {
        id: `variation_name_${page}`,
        text: 'Variant Name'
      }
    ];
    if (page > 3) return Promise.resolve([]);
    const filteredItems = search !== '' ? items.filter((item) => -1 !== item.text.indexOf(search)) : items;
    if (search !== '' && page > 0) return Promise.resolve([]);
    return new Promise((resolve) => {
      setTimeout(() => resolve(filteredItems), 200);
    });
  };

  const onChangeParentCategory = (parent) => {
    setFormCategory({ ...formCategory, parent: parent });
    closeParentCategory();
  };

  const setIsNoParentCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormCategory({ ...formCategory, parent: { id: '', text: '' } });
    setNoParentCategory(e.target.checked);
  };

  const onChangeName = useCallback(
    (newName: string) => {
      setFormCategory({ ...formCategory, name: newName });
    },
    [formCategory]
  );

  const setIsActive = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormCategory({ ...formCategory, isActive: e.target.checked });
    },
    [formCategory]
  );

  const onChangeDescription = useCallback(
    (newDesc: string) => {
      setFormCategory({ ...formCategory, description: newDesc });
    },
    [formCategory]
  );

  const followCategoryName = useCallback(() => {
    setIsFollowCategoryName(!isFollowCategoryName);
    formCategory.meta.urlKey = formCategory.name;
    setFormCategory({
      ...formCategory,
      meta: { ...formCategory.meta, urlKey: formCategory.name }
    });
  }, [formCategory, isFollowCategoryName]);

  const onInputMetaForm = (type: string, value: string) => {
    setFormCategory({
      ...formCategory,
      meta: { ...formCategory.meta, [type]: value }
    });
  };

  const onUploadImage = (defaultPath: string | null = null) => {
    let thumbnailUrl;

    const uploaderImage = (file: File, onProgress: (ratio: number) => void): Promise<unknown> =>
      new Promise((resolve, reject) => {
        const validType = ['image/png', 'image/jpg', 'image/svg+xml', 'image/gif'];
        const normalizedFile = URL.createObjectURL(file);
        thumbnailUrl = normalizedFile;
        if (file.size > 50000) {
          setIconInvalid(true);
          reject(new Error('image too large'));
        } else if (!validType.includes(file.type)) {
          setIconInvalid(true);
          reject(new Error('invalid image'));
        } else {
          setIconInvalid(false);
          resolve({
            filePath: `/file/${file.name}`,
            originalFilename: file.name
          });
        }
      });

    return [thumbnailUrl, uploaderImage];
  };
  const [thumbnailIconUrl, uploaderImage] = onUploadImage();
  const [isIconInvalid, setIconInvalid] = useState(false);
  const setIconCategory = (value) => {
    setFormCategory({ ...formCategory, icon: value });
  };

  const onUploadProduct = (defaultPath: string | null = null) => {
    let thumbnailUrl;
    const uploader = (file: File, onProgress: (ratio: number) => void): Promise<unknown> =>
      new Promise((resolve) => {
        const normalizedFile = URL.createObjectURL(file);
        thumbnailUrl = normalizedFile;
        resolve({
          filePath: `/file/${file.name}`,
          originalFilename: file.name
        });
      });

    return [thumbnailUrl, uploader];
  };

  const [thumbnailProductUrl, uploaderProduct] = onUploadProduct();

  const onSortTableProduct = useCallback(
    (direction) => {
      setDirectionTableProduct(direction);
      const sortedProducts = productsData.sort((a, b) =>
        direction === 'descending' ? a.productId - b.productId : b.productId - a.productId
      );
      setProductsData(sortedProducts);
    },
    [directionTableProduct]
  );

  const onChangePaginationProduct = (page: number) => {
    if (page < 1) {
      return;
    }
    setPageProduct(page);
  };

  const onInputPagination = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangePaginationProduct(+e.target.value);
    },
    [pageProduct]
  );

  const onPrevPagination = useCallback(() => {
    onChangePaginationProduct(pageProduct - 1);
  }, [pageProduct]);

  const onNextPagination = useCallback(() => {
    onChangePaginationProduct(pageProduct + 1);
  }, [pageProduct]);

  const onChangeProductPerPage = (perPage: number | string) => {
    if (!optionDataPerPage.includes(perPage)) {
      setInterval(() => setDataProductsPerPage(+perPage), 300);
    }
    setDataProductsPerPage(+perPage);
  };

  const validateFormGeneral = () =>
    !!formCategory.name && (!!formCategory.parent.id || noParentCategory) && !!formCategory.meta.title;

  const closeModalInfoCategory = () => {
    setModalInfoCategory({
      ...modalInfoCategory,
      isOpen: false
    });
  };

  const onSelectActionProduct = (type: string, product) => {
    if (type === 'move') {
      setMoveCategoryProduct(product);
      setIsModalMoveCategoryOpen(true);
    }
  };

  const onMoveCategory = useCallback(() => {
    // todo hit endpoint move category

    const result = true;

    if (result) {
      setIsModalMoveCategoryOpen(false);
      setModalInfoCategory({
        isOpen: true,
        description: `Produk ${moveCategoryProduct.productName} dengan SKU ${moveCategoryProduct.sku} dari Nama Merchant berhasil dipindah ke Kategori <br/> <b>${moveCategoryForm.newCategory}</b>`,
        illustration: 'success',
        title: 'Pindah Kategori Berhasil'
      });
    } else {
      setModalInfoCategory({
        isOpen: true,
        description:
          'Oops, produk tidak berhasil dipindahkan. <br/> Mohon coba kembali, jika masalah masih berlanjut, silakan hubungi Administrator Anda',
        illustration: 'failed',
        title: 'Pindah Kategori Gagal'
      });
    }
  }, [modalInfoCategory, isModalMoveCategoryOpen]);

  const onDownloadTemplate = (category) => {
    const result = 'this must be response from be';
    // todo hit endpoint download template
    if (result) {
      return new Promise((resolve, reject) => {
        fetch(result)
          .then(async (response) => {
            if (response.ok) {
              resolve(response.blob());
            } else {
              const body = await response.json();
              response['data'] = body;
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ response: response });
            }
          })
          .catch((error) => {
            reject(error);
          });
      })
        .then((blob) => {
          if (blob instanceof Blob) {
            const newUrl = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = newUrl;
            anchor.download = 'TemplateMoveCategory.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(newUrl);
          }
        })
        .catch((error) => {
          setModalInfoCategory({
            isOpen: true,
            description:
              'Oops, gagal mendownload template. Mohon coba kembali, jika masalah masih berlanjut, silakan hubungi Administrator Anda',
            illustration: 'failed',
            title: 'Download Template Gagal'
          });
          throw error;
        });
    }
    setModalInfoCategory({
      isOpen: true,
      description:
          'Oops, gagal mendownload template. Mohon coba kembali, jika masalah masih berlanjut, silakan hubungi Administrator Anda',
      illustration: 'failed',
      title: 'Download Template Gagal'
    });

    return true;
  };

  const onUploadMoveAll = () => {
    // todo hit endpoint upload move all categories
    const result = true;

    if (result) {
      setIsModalMoveAllCategoryOpen(false);
      setModalInfoCategory({
        isOpen: true,
        description: 'Anda telah berhasil mengupload file untuk memindahkan sekaligus kategori',
        illustration: 'success',
        title: 'Upload File Berhasil'
      });
    } else {
      setModalInfoCategory({
        isOpen: true,
        description:
          'Oops, upload file gagal. <br/> Mohon coba kembali, jika masalah masih berlanjut, silakan hubungi Administrator Anda',
        illustration: 'failed',
        title: 'Upload File Gagal'
      });
    }
  };

  const formatPriceIDR = (value) => {
    return (
      value?.toLocaleString('id-ID', {
        style: 'currency',
        currency: 'IDR'
      }) || ''
    );
  };

  const onFilterProducts = useCallback(() => {
    const result = true;
    // todo hit endpoint filter products
    if (result) {
      const filterId =
        filterProducts.productId.from || filterProducts.productId.to ?
          `ID: ${filterProducts.productId.from} - ${filterProducts.productId.to}; ` :
          '';
      const filterPrice =
        filterProducts.price.from || filterProducts.price.to ?
          `Harga: ${formatPriceIDR(filterProducts.price.from)} - ${formatPriceIDR(filterProducts.price.to)}; ` :
          '';
      const filterSpecialPrice =
        filterProducts.specialPrice.from || filterProducts.specialPrice.to ?
          `Harga Spesial: ${formatPriceIDR(filterProducts.specialPrice.from)} - ${formatPriceIDR(
            filterProducts.specialPrice.to
          )}; ` :
          '';
      const filterName = filterProducts.productName ? `Nama Produk: ${filterProducts.productName}; ` : '';
      const filterSku = filterProducts.sku ? `SKU: ${filterProducts.sku}; ` : '';
      const filterStatus = filterProducts.status ? `Status: ${filterProducts.status}; ` : '';
      const activeFilter = filterId + filterPrice + filterSpecialPrice + filterName + filterSku + filterStatus;
      setActiveFilterProduct(activeFilter);
      setIsModalFilterOpen(false);
    }
    return result;
  }, [filterProducts, isModalFilterOpen]);

  const addSpesification = useCallback(() => {
    setSpesification([
      ...spesifications,
      {
        isParent: false,
        value: null
      }
    ]);
  }, [spesifications]);
  const onChangeSpesification = (value: string, index: number) => {
    const newData = spesifications.map((spesification: SpesificationInterface, i: number) => {
      if (index === i) spesification.value = value;
      return spesification;
    });
    setSpesification(newData);
  };
  const removeSpesification = (index: number) => {
    const removedSpesification = spesifications.filter((_: SpesificationInterface, i: number) => i !== index);
    setSpesification(removedSpesification);
  };

  const addVariants = useCallback(() => {
    setVariants([
      ...variants,
      {
        isParent: false,
        value: null
      }
    ]);
  }, [variants]);
  const onChangeVariant = (value: string, index: number) => {
    const newData = variants.map((variant: SpesificationInterface, i: number) => {
      if (index === i) variant.value = value;
      return variant;
    });
    setVariants(newData);
  };
  const removeVariants = (index: number) => {
    const removedVariants = variants.filter((_: SpesificationInterface, i: number) => i !== index);
    setVariants(removedVariants);
  };

  const onSetFilterProduct = (key: string, value) => {
    setFilterProducts({ ...filterProducts, [key]: value });
  };

  const onSubmitGeneralForm = () => {
    const isFormValid = validateFormGeneral();
    const isDuplicateName = false;
    setIsFormGeneralSubmitted(true);
    setIsFormGeneralValid(isFormValid);
    if (!isFormValid) {
      setIsCollapseSEO(true);
      setModalInfoCategory({
        isOpen: true,
        description: 'Harap isi semua field yang wajib terlebih dahulu untuk melanjutkan',
        illustration: '',
        title: 'Anda belum selesai mengisi!'
      });
    } else if (isDuplicateName) {
      setModalInfoCategory({
        isOpen: true,
        description: 'Nama Kategori sudah ada, harap mengisi nama kategori yang belum ada',
        illustration: '',
        title: 'Nama Kategori sudah ada!'
      });
      onChangeName('');
    } else {
      setStepAddCategory(1);
    }
  };

  const onSubmitFormCategory = () => {
    switch (stepAddCategory) {
    case 1:
      setStepAddCategory(2);
      return;
    case 2:
      return;
    default:
      onSubmitGeneralForm();
    }
  };

  return {
    data: {
      collapseSEO,
      formCategory,
      stepAddCategory,
      noParentCategory,
      itemsParentCategory,
      isCompleteFirstStep,
      isOpenParentCategory,
      searchParentCategory,
      isFollowCategoryName,
      thumbnailIconUrl,
      isIconInvalid,
      productsData,
      directionTableProduct,
      pageProduct,
      dataProductsPerPage,
      optionDataPerPage,
      tableProductColumns,
      isFormGeneralValid,
      modalInfoCategory,
      isFormGeneralSubmitted,
      isModalFilterOpen,
      isModalMoveCategoryOpen,
      moveCategoryProduct,
      isModalMoveAllCategoryOpen,
      moveCategoryForm,
      filterProducts,
      thumbnailProductUrl,
      moveAllCategoryForm,
      variants,
      spesifications,
      attributeSetFrom,
      activeFilterProduct,
      errorParentCategory
    },
    methods: {
      setIsActive,
      onChangeName,
      setIsCollapseSEO,
      openParentCategory,
      closeParentCategory,
      handleNextPageParent,
      setCompleteFirstStep,
      setIsNoParentCategory,
      setSearchParentCategory,
      onSubmitFormCategory,
      onChangeDescription,
      followCategoryName,
      onInputMetaForm,
      onChangeParentCategory,
      setIconCategory,
      uploaderImage,
      onSortTableProduct,
      onInputPagination,
      onNextPagination,
      onPrevPagination,
      onChangeProductPerPage,
      closeModalInfoCategory,
      setIsModalFilterOpen,
      setIsModalMoveCategoryOpen,
      onSelectActionProduct,
      onMoveCategory,
      onDownloadTemplate,
      onUploadMoveAll,
      onFilterProducts,
      setIsModalMoveAllCategoryOpen,
      setMoveCategoryForm,
      onSetFilterProduct,
      uploaderProduct,
      setMoveAllCategoryForm,
      addSpesification,
      onChangeSpesification,
      removeSpesification,
      addVariants,
      onChangeVariant,
      removeVariants,
      setAttributeSetFrom,
      onChangeFilterIdFrom,
      onChangeFilterIdTo,
      onChangeFilterPriceFrom,
      onChangeFilterPriceTo,
      onChangeFilterSpecialPriceFrom,
      onChangeFilterSpecialPriceTo,
      onChangeFilterName,
      onChangeFilterSku,
      onChangeFilterStatus,
      onSearchProduct,
      goToTabGeneral,
      goToTabProduct,
      goToTabAttributeSet,
      onOpenModalFilter,
      onOpenModalMoveCategory,
      onOpenModalMoveAllCategory,
      onCloseModalFilter,
      onCloseModalMoveCategory,
      onCloseModalMoveAllCategory,
      onSelectNewCategory,
      onChangeNotifyMove
    }
  };
};
export const CategoryContext = createContext(useAddCategory);

export default useAddCategory;
