import { IFilterHistoryItems } from '@/components/base/FilterHistory';
import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { IFilterVouchers, useAllVouchers } from '@/services/rest/promo';
import { Promos, upsertPromoAppliedMerchants, upsertPromoAppliedProduct, upsertPromoAppliedPromo } from '@/services/rest/voucher';
import { useHistoryStore } from '@/store/useHistoryStore';
import { IPagination } from '@/types/pagination';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { confirmDialog } from 'primereact/confirmdialog';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { useDebounce } from 'primereact/hooks';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

export interface DataVoucher {
  id: string;
  code: string;
  description: null | string;
  image: string;
  amount_type: string;
  amount: number;
  start_date: Date;
  end_date: Date;
  status: boolean;
  type: string;
  accepted_payment_provider_ids: AcceptedPaymentProviderID[];
  payment_bin_rule: null;
  quota: number;
  user_quota: number;
  is_quota_unlimited: boolean;
  is_user_quota_unlimited: boolean;
  min_discount_amount: number;
  max_discount_amount: number;
  min_payment_amount: number;
  max_payment_amount: number;
  name: string;
  created_at: Date;
  is_payment_cc: boolean;
  target_platform: string[]
}

export interface AcceptedPaymentProviderID {
  id: number;
  code: string;
  label: string;
}

export interface ProductVariant {
  id: string;
  products: [{ name: string }]
}

export interface Merchants {
  id: string;
  name: string;
}

export interface PromoTags {
  id: number;
  name: string;
}

type Option = {
  label:string
  items: Array<OptionItems>
}

type OptionItems = {
  label:string | null;
  name:string;
  value:string | boolean;
}
interface IPaginationState {
  currentPage:number,
  range:IPagination
}

const useVoucher = () => {
  const initialFilter: IFilterVouchers = {
    name: '',
    valid_date: [],
    status: '',
    code: ''
  };

  const toast = useRef<Toast>(null);
  const [search, debounceSearch, setSearch] = useDebounce('', 1500);
  const [foundVoucher, setFoundVoucher] = useState<{ id: string, name: string }[]|null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const currentPage = parseInt(visitedPage.voucher.toString()) ?? 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : perPage - 1;
  const [pagination, setPagination] = useState<IPaginationState>({
    currentPage,
    range: {
      start,
      end
    }
  });
  const [jumpToPage, setJumpToPage] = useState<number>(1);
  const [itemFilters, setItemFilters] = useState<IFilterVouchers>(
    lastFilterPage.voucher != '' ? JSON.parse(String(lastFilterPage.voucher)) : initialFilter
  );
  const [filters, setFilters] = useState<IFilterVouchers>(
    lastFilterPage.voucher != '' ? JSON.parse(String(lastFilterPage.voucher)) : initialFilter
  );
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);

  //toast
  const showSuccess = (msg) => {
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: `${msg}`,
      life: 2000
    });
  };

  const showError = (msg: string) => {
    toast.current?.show({
      severity: 'error',
      summary: 'Error',
      detail: `${msg}`,
      life: 2000
    });
  };

  const { data: dataVouchers, isLoading, refetch: refetchVoucher } = useAllVouchers(filters, debounceSearch, pagination.currentPage, perPage);

  const dataVoucher = useMemo(()=>{
    if (!Array.isArray(dataVouchers?.data?.data)) return [];
    return dataVouchers?.data?.data;
  }, [dataVouchers]);

  const totalRecords = useMemo(()=>{
    return dataVouchers?.data?.total_data ? dataVouchers?.data?.total_data : 0;
  }, [dataVouchers]);

  const totalPages = useMemo(()=>{
    return Math.ceil(Number(totalRecords) / perPage);
  }, [totalRecords, perPage]);

  const getStartingRange = useCallback((perPage: number, page: number) => {
    if (page === 1) {
      return page - 1;
    }

    return perPage * page - perPage;
  }, [perPage]);

  const getEndRange = useCallback((perPage: number, page: number) => {
    if (page === 1) {
      return page * perPage - 1;
    }

    return page * perPage - 1;
  }, [perPage]);

  const findVoucherDebounce = debounce(async (findName: string | null) => {
    if (!findName) {
      return;
    }

    const { data } = await supabase.from('trade.promos').select('id,name,type,status,category_promos(id,name)')
      .eq('type', 'product').ilike('name', `*${findName}*`).limit(10);
    setFoundVoucher(data);
  }, 250);

  const filterInputHandler = (fileName : string) => {
    findVoucherDebounce(fileName);
  };

  const options = useMemo(():Option[]=>{
    const voucher = [...new Set(foundVoucher?.map((p) => p))];
    return [
      {
        label: 'Name',
        items: voucher
          .filter((m) => m.name !== undefined && m.name !== null)
          .map((p)=>({
            label: p.name || '',
            value: p.name || '',
            name: 'name'
          })) || []
      },
      {
        label: 'Status',
        items: [
          { label: 'Active', name: 'status', value: true },
          { label: 'Inactive', name: 'status', value: false }
        ]
      }
    ];
  }, [foundVoucher]);

  const handleSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>)=>{
    setSearch(currentTarget.value);
    const newKeyword = {
      name: 'Keyword',
      items: [{
        label: currentTarget.value,
        value: currentTarget.value,
        name: currentTarget.value
      }]
    };

    if (currentTarget.value != '') {
      setFilterHistory((prev: IFilterHistoryItems[]) => {
        const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
        if (existingIndex !== -1) {
          prev[existingIndex] = newKeyword;
        } else {
          prev.push(newKeyword);
        }
        return [...prev];
      });

      setPagination({
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      });
    } else {
      handleDeleteFilterHistory('Keyword', ['Keyword']);
    }
  }, [perPage]);

  const deletePromoAppliedPromo =async (id: string) => {
    const { data } = await supabase.rpc('trade.delete_action', {
      'table_name': TABLE.PROMO_APPLIED_PROMO_TAGS.split('.').splice(1, 1)[0], 'column_name': 'promo_id', 'val': [id]
    });
    if (!data[0].success) {
      return showError('Gagal Delete Voucher');
    }
  };
  const deletePromoAppliedProduct =async (id: string) => {
    const { data } = await supabase.rpc('trade.delete_action', {
      'table_name': TABLE.PROMO_APPLIED_PRODUCT.split('.').splice(1, 1)[0], 'column_name': 'promo_id', 'val': [id]
    });
    if (!data[0].success) {
      return showError('Gagal Delete Voucher');
    }
  };
  const deletePromoAppliedMerchant =async (id: string) => {
    const { data } = await supabase.rpc('trade.delete_action', {
      'table_name': TABLE.PROMO_APPLIED_MERCHANTS.split('.').splice(1, 1)[0], 'column_name': 'promo_id', 'val': [id]
    });
    if (!data[0].success) {
      return showError('Gagal Delete Voucher');
    }
  };
  const deleteDataVoucher =async (id: string) => {
    const { data } = await supabase.rpc('trade.delete_action', {
      'table_name': TABLE.PROMOS.split('.').splice(1, 1)[0], 'column_name': 'id', 'val': [id]
    });
    if (data[0].success) {
      showSuccess('Deleted Successfully');
      refetchVoucher();
    } else {
      return showError('Gagal Delete Voucher');
    }
  };

  const deleteDataPromoAmountCounts = async (id: string) => {
    const { data } = await supabase.rpc('trade.delete_action', {
      'table_name': TABLE.PROMO_AMOUNT_COUNTS.split('.').splice(1, 1)[0], 'column_name': 'promo_id', 'val': [id]
    });
    if (data[0].success) {
      refetchVoucher();
    } else {
      return showError('Gagal Delete Voucher');
    }
  };

  const deletePromoExludeProductsVariants = async (id: string) => {
    const { data } = await supabase.rpc('trade.delete_action', {
      'table_name': TABLE.PROMO_EXCLUDE_PRODUCT.split('.').splice(1, 1)[0], 'column_name': 'promo_id', 'val': [id]
    });
    if (data[0].success) {
      refetchVoucher();
    } else {
      return showError('Gagal Delete Voucher');
    }
  };

  const deletePromoExcludeMerchant = async (id: string) => {
    const { data } = await supabase.rpc('trade.delete_action', {
      'table_name': TABLE.PROMO_EXCLUDE_MERCHANT.split('.').splice(1, 1)[0], 'column_name': 'promo_id', 'val': [id]
    });
    if (data[0].success) {
      refetchVoucher();
    } else {
      return showError('Gagal Delete Voucher');
    }
  };

  const deletedPromoExcludePromoTags = async (id: string) => {
    const { data } = await supabase.rpc('trade.delete_action', {
      'table_name': TABLE.PROMO_EXCLUDE_PROMO_TAGS.split('.').splice(1, 1)[0], 'column_name': 'promo_id', 'val': [id]
    });
    if (data[0].success) {
      refetchVoucher();
    } else {
      return showError('Gagal Delete Voucher');
    }
  };

  const deletePromoRulesByPromoId = async (id: string) => {
    const { data } = await supabase.rpc('trade.delete_action', {
      'table_name': TABLE.PROMO_RULES.split('.').splice(1, 1)[0], 'column_name': 'promo_id', 'val': [id]
    });
    if (data[0].success) {
      refetchVoucher();
    } else {
      return showError('Gagal Delete Voucher');
    }
  };

  const handleFilter = useCallback((e, field: string) => {
    setItemFilters((prev) => ({
      ...prev,
      [field]: e.target ? e.target.value : e.value
    }));
  }, []);

  const onDuplicate = useCallback(async (promo: Object)=>{
    // to get detail promo
    const { data: dataVoucher } = await supabase
      .from('trade.promos')
      .select(
        '*'
      )
      .eq('id', promo['id'])
      .single();

    // to get promo rule
    const { data: dataPromoRules } = await supabase
      .from('trade.promos')
      .select(
        'promo_applied_merchant_variants(merchant_ids),promo_applied_product_variants(product_variant_ids),promo_applied_product_variant_promo_tags(product_variant_promo_tag_ids),promo_excluded_product_variants(product_variant_ids), promo_excluded_merchant_variants(merchant_ids), promo_excluded_product_variant_promo_tags(product_variant_promo_tag_ids)'
      )
      .eq('id', promo['id'])
      .single() as PostgrestSingleResponse<Promos>;

    const voucher = dataVoucher as {};
    // delete id and code to insert promo
    delete voucher['id'];
    delete voucher['code'];

    const { data, error } = await supabase.from(TABLE.PROMOS).insert(voucher).select().single();
    if (data) {
      const { error: errorRule } = await supabase.functions.invoke('promo-engine', ({
        body: {
          'action': 'add',
          'promo': {
            'id': data.id,
            'code': data.code,
            'description': data.description,
            'image': data.image,
            'amount_type': data.amount_type,
            'amount': data.amount,
            'start_date': data.start_date,
            'end_date': data.end_date,
            'status': data.status,
            'type': 'product',
            'accepted_payment_provider_ids': data.accepted_payment_provider_ids,
            'payment_bin_rule': data.payment_bin_rule,
            'is_payment_cc': data.is_payment_cc,
            'quota': data.quota,
            'user_quota': data.user_quota,
            'is_quota_unlimited': data.is_quota_unlimited,
            'is_user_quota_unlimited': data.is_user_quota_unlimited,
            'min_discount_amount': data.min_discount_amount,
            'max_discount_amount': data.max_discount_amount,
            'min_payment_amount': data.min_payment_amount,
            'max_payment_amount': data.max_payment_amount,
            'target_platform': data.target_platform,
            'is_public': data.is_public,
            'promo_applied_merchant_variants': [
              {
                'merchant_ids': dataPromoRules?.promo_applied_merchant_variants.map((i) => i.merchant_ids)[0] || []
              }
            ],
            'promo_applied_product_variants': [
              {
                'product_variant_ids': dataPromoRules?.promo_applied_product_variants.map((i) => i.product_variant_ids)[0] || []
              }
            ],
            'promo_applied_shipping_providers': [
              {
                'shipping_provider_ids': [
                ]
              }
            ],
            'promo_applied_product_variant_promo_tags': [
              {
                'product_variant_promo_tag_ids': dataPromoRules?.promo_applied_product_variant_promo_tags.map((i) => String(i.product_variant_promo_tag_ids )) || []
              }
            ],
            'promo_excluded_product_variants': [
              {
                'product_variant_ids': dataPromoRules?.promo_excluded_product_variants.map((i) => i.product_variant_ids) || []
              }
            ],
            'promo_excluded_merchant_variants': [
              {
                'merchant_ids': dataPromoRules?.promo_excluded_merchant_variants.map((i) => i.merchant_ids) || []
              }
            ],
            'promo_excluded_product_variant_promo_tags': [
              {
                'product_variant_promo_tag_ids': dataPromoRules?.promo_excluded_product_variant_promo_tags.map((i) => String(i.product_variant_promo_tag_ids)) || []
              }
            ]
          }
        }
      }));
      const discountBy = (dataPromoRules && dataPromoRules?.promo_applied_merchant_variants.length > 0 && 'STORE') ||
      (dataPromoRules && dataPromoRules?.promo_applied_product_variant_promo_tags.length > 0 && 'PROMO') ||
      (dataPromoRules && dataPromoRules?.promo_applied_product_variants.length > 0 && 'PRODUCT');
      if (errorRule) showError(errorRule);

      // insert promo applied
      if (discountBy === 'PROMO') {
        const { error: errPromo } = await upsertPromoAppliedPromo({
          promo_id: data.id,
          product_variant_promo_tag_ids: dataPromoRules?.promo_applied_product_variant_promo_tags[0]?.product_variant_promo_tag_ids || []
        });
        if (errPromo) showError(errPromo.details);
      }
      if (discountBy === 'PRODUCT') {
        const { error: errPromo } = await upsertPromoAppliedProduct({
          promo_id: data.id,
          product_variant_ids: dataPromoRules?.promo_applied_product_variants[0]?.product_variant_ids || []
        });
        if (errPromo) showError(errPromo.details);
      }
      if (discountBy === 'STORE') {
        const { error: errPromo } = await upsertPromoAppliedMerchants({
          promo_id: data.id,
          merchant_ids: dataPromoRules?.promo_applied_merchant_variants[0]?.merchant_ids || []
        });
        if (errPromo) showError(errPromo.details);
      }

      // insert promo exlude
      if (dataPromoRules && dataPromoRules.promo_excluded_merchant_variants.length > 0) {
        const payload = dataPromoRules.promo_excluded_merchant_variants.map((i) => {
          return {
            promo_id: data.id,
            merchant_ids: i.merchant_ids
          };
        });
        const { error: errExclude } = await supabase
          .from('trade.promo_excluded_merchant_variants')
          .insert(payload);
        if (errExclude) {
          showError('Failed insert to promo excluded product variants');
          return;
        }
      }

      if (dataPromoRules && dataPromoRules.promo_excluded_product_variants.length > 0) {
        const payload = dataPromoRules.promo_excluded_product_variants.map((i) => {
          return {
            promo_id: data.id,
            product_variant_ids: i.product_variant_ids
          };
        });
        const { error: errExclude } = await supabase
          .from('trade.promo_excluded_product_variants')
          .insert(payload);

        if (errExclude) {
          showError('Failed insert to promo excluded product variants');
          return;
        }
      }

      if (dataPromoRules && dataPromoRules.promo_excluded_product_variant_promo_tags.length > 0) {
        const payload = dataPromoRules.promo_excluded_product_variant_promo_tags.map((i) => {
          return {
            promo_id: data.id,
            product_variant_promo_tag_ids: i.product_variant_promo_tag_ids
          };
        });
        const { error: errExclude } = await supabase
          .from('trade.promo_excluded_product_variant_promo_tags')
          .insert(payload);
        if (errExclude) {
          showError('Failed insert to promo excluded product variants');
          return;
        }
      }
    }

    if (error) {
      showError(error.details);
    } else {
      refetchVoucher();
      showSuccess('New voucher created successfully');
    }
  }, []);

  const confirmDelete = (id: string) => {
    confirmDialog({
      message: 'Are you sure you want to delete this record?',
      header: 'Delete Confirmation',
      acceptClassName: 'p-button-danger',
      accept() {
        deletePromoRulesByPromoId(id);
        deletePromoExludeProductsVariants(id);
        deletePromoExcludeMerchant(id);
        deletedPromoExcludePromoTags(id);
        deleteDataPromoAmountCounts(id);
        deletePromoAppliedProduct(id);
        deletePromoAppliedPromo(id);
        deletePromoAppliedMerchant(id);
        deleteDataVoucher(id);
      }
    });
  };

  const handleClearFilter = useCallback(() => {
    setLastFilterPage({
      ...lastFilterPage,
      voucher: ''
    });
    setFilters(initialFilter);
    setItemFilters(initialFilter);
    setFilterHistory([]);
    setSearch('');
    setPagination({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [lastFilterPage, perPage]);

  const handleDeleteFilterHistory = useCallback(
    (key: string, value: string[]) => {
      const items = value[0].split(',');
      items.forEach((i) => {
        setFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));

        setItemFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));
      });

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setSearch('');
    },
    []
  );

  // Pagination
  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent)=>{
    setPerPage(event.value);
    setPagination((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleClickNext = useCallback(()=>{
    pagination.currentPage <= totalPages &&
    setPagination((prev) => ({
      ...prev,
      currentPage: pagination.currentPage + 1,
      range: {
        start: getStartingRange(perPage, pagination.currentPage + 1),
        end: getEndRange(perPage, pagination.currentPage + 1)
      }
    }));
  }, [pagination, totalPages, perPage]);

  const handleClickPrev = useCallback(()=>{
    setPagination((prev) => ({
      ...prev,
      currentPage: prev.currentPage - 1,
      range: {
        start: prev.range.start - perPage,
        end: prev.range.end - perPage
      }
    }));
  }, [pagination, perPage]);

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, totalRecords - 1);

      setPagination(() => ({
        currentPage: jumpToPage > totalPages ? totalPages : value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, totalRecords]);

  const handleClickSubmitFilter = useCallback(() => {
    setFilters(itemFilters);
    setPagination({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [itemFilters, perPage]);

  const storeFilterHistory = useCallback((filter: IFilterVouchers) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter?.valid_date && filter.valid_date[0]) {
      let filterDate = `${dayjs(filter.valid_date[0]).format('YYYY-MM-DD')}`;
      if (filter.valid_date.length > 1 && filter.valid_date[1] !== null) {
        filterDate = `${dayjs(filter.valid_date[0]).format('YYYY-MM-DD')} - ${dayjs(filter.valid_date[1]).format('YYYY-MM-DD')}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Valid Date', 'valid_date', filterDate));
    }

    if (filter.name !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Name', 'name', filter.name));
    }

    if (filter.code !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Code', 'code', filter.code));
    }

    if (filter.status !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Status', 'status', filter.status.toUpperCase()));
    }

    setFilterHistory(filterHistoryItems);
  }, []);

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  // update store lastFilterPage with latest filter
  useEffect(() => {
    setLastFilterPage({
      ...lastFilterPage,
      voucher: JSON.stringify(filters)
    });
  }, [filters]);

  useEffect(() => {
    // update store visitedPage with latest page
    setVisitedPage({
      ...visitedPage,
      voucher: pagination.currentPage
    });
  }, [pagination.currentPage]);

  return {
    data: {
      isLoading,
      dataVoucher,
      search,
      toast,
      options,
      filterHistory,
      pagination,
      totalPages,
      perPage,
      totalRecords,
      itemFilters
    },
    method: {
      confirmDelete,
      onDuplicate,
      handleDeleteFilterHistory,
      handleClearFilter,
      filterInputHandler,
      handleChangeDropdownPage,
      handleClickNext,
      handleClickPrev,
      handleSearch,
      handleChangeJumpTopage,
      handleJumpToPage,
      handleClickSubmitFilter,
      handleFilter
    }
  };
};

export default useVoucher;
