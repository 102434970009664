import React from 'react';
import useCustom from './hooks';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import Table from '@/components/base/DataTable/DataTable';
import { Button } from 'primereact/button';
import ModalBulkUpload from '@/components/base/ModalUpload';
import { usePermissionStore } from '@/store/usePermissions';
import { IPagination } from '@/components/base/Pagination';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Link } from 'react-router-dom';

const SpecificationCategory = () => {
  const { data, method } = useCustom();
  const { canCreate, canView, canDelete, canEdit } = usePermissionStore();
  const templateDescription = (rowData) => {
    return <div className="mx-auto elipsis-20ch">{rowData.description}</div>;
  };
  const header = [
    { field: 'id', header: 'ID' },
    { field: 'name', header: 'Specification Name' },
    { field: 'label', header: 'Specification Label' },
    { field: 'description', header: 'Description', body: templateDescription },
    { field: 'type', header: 'Specification Type' },
    { field: 'is_active', header: 'Status' }
  ];
  const instructionTemplate = [
    {
      title: 'Download & ubah File Excel',
      subtitle:
        'Template hanya bisa diisi dengan MS. Excel 2007 ke atas atau Libre Office. Jangan menambah kolom atau baris apa pun di file Excel.'
    },
    {
      title: 'Upload File Excel',
      subtitle: 'Pilih atau letakkan file Excel (.xlsx) kamu di sini, maks. 300 Spesifikasi Baru per File'
    }
  ];

  const paginationOptions: IPagination = {
    perPage: data.perPage,
    totalPages: data.totalPages,
    currentPage: data.paginator.currentPage,
    disabledDropdown: data.totalRecords === 0,
    onChangeDropdownPage: method.handleChangeDropdownPage,
    onClickNext: method.handleClickNext,
    onClickPrev: method.handleClickPrev,
    onJumptoPage: method.handleJumpToPage,
    onChangeJumptoPage: method.handleChangeJumpTopage
  };

  const filterContent = (
    <div className="flex flex-col gap-4 w-[900px]">
      <div className="w-full flex flex-col items-start gap-2">
        <h1 className="font-bold">ID</h1>
        <div className="flex w-full gap-8">
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Dari</label>
            <InputNumber
              onChange={(e) => method.handleFilter(e, 'id_from')}
              value={data.itemFilters.id_from}
              name="id_from"
            />
          </div>
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Sampai</label>
            <InputNumber
              onChange={(e) => method.handleFilter(e, 'id_to')}
              value={data.itemFilters.id_to}
              name="id_to"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Specification Name</label>
          <InputText
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
            onChange={(e) => method.handleFilter(e, 'name')}
            value={data.itemFilters.name}
            name="name"
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Specification Label</label>
          <InputText
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
            onChange={(e) => method.handleFilter(e, 'label')}
            value={data.itemFilters.label}
            name="label"
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Description</label>
          <InputText
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
            onChange={(e) => method.handleFilter(e, 'description')}
            value={data.itemFilters.description}
            name="description"
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Specification Type</label>
          <InputText
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
            onChange={(e) => method.handleFilter(e, 'type')}
            value={data.itemFilters.type}
            name="type"
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Status</label>
          <Dropdown
            options={[{ label: 'Active', value: 'active' }, { label: 'Inactive', value: 'inactive' }]}
            onChange={(e) => method.handleFilter(e, 'status')}
            value={data.itemFilters.status}
            placeholder="Select Status"
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <ConfirmDialog />
      <Toast ref={data.toast} />
      <BaseContainer>
        <MainTitle title="Specification">
          <div className="relative">
            <div className="rounded-md overflow-hidden h-fit">
              <Link to={canCreate ? 'create' : ''}>
                <Button disabled={!canCreate} severity="danger" className="!rounded-none" >
                  <i className="pi pi-plus text-white" />
                </Button>
              </Link>
              <Button disabled={!canCreate} severity="danger" className="!rounded-none" onClick={method.handleClickDropdownAddSpecification}>
                <i className="pi pi-angle-down text-white" />
              </Button>
            </div>
            {data.isOpenDropdown && (
              <div className="absolute text-black right-0 z-10 border rounded-md bg-white">
                <Link to={canCreate ? 'create' : ''}>
                  <div className="p-4 hover:bg-slate-50 whitespace-nowrap w-full text-start">Add Specification</div>
                </Link>
                <button
                  type="button"
                  className="p-4 hover:bg-slate-50 whitespace-nowrap w-full text-start"
                  onClick={method.handleToggleModal('create')}
                >
                  Bulk Upload
                </button>
                <button
                  type="button"
                  onClick={method.handleToggleModal('edit')}
                  className="p-4 hover:bg-slate-50 whitespace-nowrap w-full text-start"
                >
                  Bulk Update
                </button>
              </div>
            )}
          </div>
        </MainTitle>
        <ModalBulkUpload
          handleClickDownload={method.handleClickDownload}
          handleChangeFileUpload={method.handleChangeFileUpload}
          handleClickUpload={method.handleClickUpload}
          disabledInsert={!Boolean(data.importedSpecification.length > 0)}
          fileUploadRef={data.fileUploadRef}
          show={data.isShowModal}
          handleCloseModal={method.handleToggleModal('create')}
          modalTitle={data.uploadType === 'create' ? 'Tambah Sekaligus Spesifikasi' : 'Ubah Sekaligus Spesifikasi'}
          placeholder="Drag and drop to upload file or click button choose"
          instruction={instructionTemplate}
          isLoadingUpload={data.isLoadingUpload}
        />
        <Table
          rows={data.totalRecords}
          paginationOptions={paginationOptions}
          filter={data.search}
          onSearch={method.handleSearch}
          headerTable={header}
          onDeleted={method.onDelete}
          value={data.specifications as []}
          isDeleteAction={canDelete}
          isDetailAction={canView}
          isEditAction={canEdit}
          handleClearFilter={method.handleClearFilter}
          handleDeleteFilterHistory={method.handleDeleteFilterHistory}
          filterContent={filterContent}
          filterHistory={data.filterHistory}
          onSubmitFilter={method.handleClickSubmitFilter}
        />
      </BaseContainer>
    </>
  );
};

export default SpecificationCategory;
