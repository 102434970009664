import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import Table from '@/components/base/DataTable/DataTable';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import { header, useCustom } from './hooks';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { Button } from 'primereact/button';
import { exportTable } from '@/utils/exportTable';
import { usePermissionStore } from '@/store/usePermissions';
import { IPagination } from '@/components/base/Pagination';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';

const PromoTagging = () => {
  const {
    data: {
      isLoading,
      getPromoTaggings,
      toast,
      filterHistory,
      totalPages,
      perPage,
      paginator,
      totalRecords,
      search,
      itemFilters
    },
    methods: {
      onDeletePromoTagging,
      handleClearFilter,
      handleDeleteFilterHistory,
      handleChangeDropdownPage,
      handleClickNext,
      handleClickPrev,
      handleJumpToPage,
      handleChangeJumpTopage,
      handleSearch,
      handleClickSubmitFilter,
      handleFilter
    }
  } = useCustom();
  const { canCreate, canView, canDelete, canEdit } = usePermissionStore();

  const optionType = [
    {
      label: 'New Product',
      value: 'newProduct'
    },
    {
      label: 'PLP',
      value: 'plp'
    },
    {
      label: 'Landing Page',
      value: 'landingPage'
    },
    {
      label: 'Store Recommendation',
      value: 'storeRecommendation'
    },
    {
      label: 'Top Keyword List',
      value: 'topKeywordList'
    }
  ];

  // pagination
  const paginationOptions: IPagination = {
    perPage: perPage,
    totalPages: totalPages,
    currentPage: paginator.currentPage,
    disabledDropdown: totalRecords === 0,
    onChangeDropdownPage: handleChangeDropdownPage,
    onClickPrev: handleClickPrev,
    onClickNext: handleClickNext,
    onJumptoPage: handleJumpToPage,
    onChangeJumptoPage: handleChangeJumpTopage
  };

  const filterContent = (
    <div className="flex flex-col gap-4 w-[900px]">
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Promo Tagging Name</label>
          <InputText
            onChange={(e) => handleFilter(e, 'name')}
            value={itemFilters.name}
            name="name"
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Category Tagging</label>
          <Dropdown
            options={optionType}
            onChange={(e) => handleFilter(e, 'type')}
            value={itemFilters.type}
            placeholder="Select Category"
          />
        </div>
      </div>
      {/* <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Product</label>
          <InputText
            onChange={(e) => handleFilter(e, 'product')}
            value={itemFilters.product}
            name="product"
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Store</label>
          <InputText
            onChange={(e) => handleFilter(e, 'store')}
            value={itemFilters.store}
            name="store"
          />
        </div>
      </div> */}
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Valid Date</label>
          <Calendar
            onChange={(e) => handleFilter(e, 'valid_date')}
            value={itemFilters.valid_date}
            selectionMode="range"
            showIcon
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Status</label>
          <Dropdown
            options={[{ label: 'ACTIVE', value: 'ACTIVE' }, { label: 'INACTIVE', value: 'INACTIVE' }]}
            onChange={(e) => handleFilter(e, 'status')}
            value={itemFilters.status}
            placeholder="Select Status"
          />
        </div>
      </div>
    </div>
  );

  return (
    <BaseContainer>
      <MainTitle title="Promo Tagging" nav="/promo-tagging/create" labelButton="Create Promo Tagging" isWithButton={canCreate} />
      <div className="flex justify-end">
        <Button
          onClick={() => exportTable(getPromoTaggings as [], 'PromoTagging.xlsx')}
          severity="success"
          label="Export"
          icon="pi pi-download"
          disabled={!canView}
        />
      </div>
      <ConfirmDialog id="promo-tagging-confirm-dialog" />
      <Toast ref={toast} />
      <LoadingOverlay show={isLoading} />
      <Table
        filter={search}
        headerTable={header}
        onDeleted={onDeletePromoTagging}
        onSearch={handleSearch}
        value={getPromoTaggings as []}
        isDeleteAction={canDelete}
        isDetailAction={canView}
        isEditAction={canEdit}
        rows={totalRecords}
        paginationOptions={paginationOptions}
        filterHistory={filterHistory}
        handleClearFilter={handleClearFilter}
        handleDeleteFilterHistory={handleDeleteFilterHistory}
        onSubmitFilter={handleClickSubmitFilter}
        filterContent={filterContent}
      />
    </BaseContainer>
  );
};

export default PromoTagging;
