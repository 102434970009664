import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { IPagination } from '@/types/pagination';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';
import { IVariants } from '../product';

export type Variants = {
  category_id?: number;
  created_at?: string | null;
  id?: number | null |string;
  name: string | null;
  updated_at?: string | null;
  value?: Value[];
  label: string;
  description?: string;
  type: string;
  is_active: boolean;
  instruction?:string;
  variant_presets?:Array<VariantPreset>
  variants?: Variants;
};
type VariantPreset = {
  name:string
}
type Value = {
  id: number;
  label: string;
};

export interface IFilterVariant {
  id_from: number | null;
  id_to: number | null;
  name: string;
  label: string;
  description: string;
  type: string;
  status: string;
}

export type PayloadVariantPreset = {
  name:string;
  variant_id:string;
}

const getAllVariants = async (filter?: IFilterVariant, search?: string, range: IPagination = { start: 0, end: 10 }) => {
  const query = supabase
    .from(TABLE.VARIANTS)
    .select('*', { count: 'exact' })
    .order('created_at', { ascending: false })
    .range(range.start, range.end);

  if (filter) {
    // ID
    if (filter.id_from != null) {
      query.gte('id', filter.id_from);
    }

    if (filter.id_to != null) {
      query.lt('id', filter.id_to);
    }

    // Name
    if (filter.name != '') {
      query.ilike('name', `%${filter.name}%`);
    }

    // Label
    if (filter.label != '') {
      query.ilike('label', `%${filter.label}%`);
    }

    // Description
    if (filter.description != '') {
      query.ilike('description', `%${filter.description}%`);
    }

    // Type
    if (filter.type != '') {
      query.ilike('type', `%${filter.type}%`);
    }

    // Status
    if (filter.status == 'active') {
      query.is('is_active', !null);
    } else {
      query.is('is_active', null);
    }
  }

  if (search != '') {
    query.or(`label.ilike.%${search}%, type.ilike.%${search}%), name.ilike.%${search}%, description.ilike.%${search}%`);
  }

  return (await query) as PostgrestSingleResponse<Variants[]>;
};

const getTotalVariant = async () => {
  return (await supabase
    .from(TABLE.VARIANTS)
    .select('*', { count: 'exact', head: true }).limit(50)
  ) as PostgrestSingleResponse<IVariants[]>;
};

const getVariantByID =async (id?:string) => {
  return await supabase.from(TABLE.VARIANTS).select('*,variant_presets(*)').eq('id', id).single() as PostgrestSingleResponse<Variants>;
};

export const deleteCategoryVariants = async (id: string) => {
  return await supabase.from(TABLE.CATEGORY_VARIANTS).delete().eq('variant_id', id);
};

export const deleteVariants = async (id: string) => {
  return await supabase.from(TABLE.VARIANTS).delete().eq('id', id);
};

const getVariantOptionFilters = async () => {
  return (await supabase
    .from(TABLE.VARIANTS)
    .select('name,label,id,type,is_active')
    .range(0, 10)
  ) as PostgrestSingleResponse<Variants[]>;
};

const getVariantByCategoryId =async (id?: number) => {
  return (await supabase
    .from(TABLE.CATEGORY_VARIANTS)
    .select('variants(id, name)')
    .eq('category_id', id)
  ) as PostgrestSingleResponse<Variants[]>;
};

export const upsertVariants = async (payload: Variants) => {
  return await supabase.from(TABLE.VARIANTS).upsert([payload]).select('id').single();
};

export const upsertBulkVariants = async (payload: Variants[]) => {
  return await supabase.from(TABLE.VARIANTS).upsert(payload).select('id');
};

export const insertVariantPreset =async (payload:PayloadVariantPreset[]) => {
  return await supabase.from(TABLE.VARIANT_PRESETS).insert(payload);
};

export const deletePresetVariants =async (id:string) => {
  return await supabase.from(TABLE.VARIANT_PRESETS).delete().eq('variant_id', id);
};

export const deleteMultiplePresetVariants =async (id:string[]) => {
  return await supabase.from(TABLE.VARIANT_PRESETS).delete().in('variant_id', id);
};

export const useCategoryVariants = (id: number) =>
  useQuery({
    queryKey: ['category-variant', id],
    queryFn: () => getVariantByCategoryId(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });
export const useVariantOptionFilters = () =>
  useQuery({
    queryKey: ['variant-filters'],
    queryFn: getVariantOptionFilters,
    refetchOnWindowFocus: false
  });

export const useAllVariants = (filter?: IFilterVariant, search?: string, range?: IPagination) =>
  useQuery({
    queryKey: ['variants', filter, search, range],
    queryFn: () => getAllVariants(filter, search, range),
    refetchOnWindowFocus: false
  });

export const useTotalVariant = () =>
  useQuery({
    queryKey: ['total-variant'],
    queryFn: getTotalVariant,
    refetchOnWindowFocus: false
  });

export const useVariantsById = (id?:string)=>
  useQuery({
    queryKey: ['variants-detail', id],
    queryFn: () => getVariantByID(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });
