import * as yup from 'yup';
import { MESSAGE } from '@/constants';

const customRegex = /^(?:[a-z]+)(?:_?[a-z]+)+$/;

const schemaUserGroup = yup.object({
  name: yup.string().required(MESSAGE.REQUIRED),
  code: yup.string()
    .matches(/^\S*$/, 'Whitespace is not allowed')
    .matches(customRegex, 'Only Lowercase allowed, One optional hyphen, and No trailing hyphen.')
    .required(MESSAGE.REQUIRED),
  status: yup.boolean().required(MESSAGE.REQUIRED)
});

export { schemaUserGroup };
