import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { useMutation, useQuery } from '@tanstack/react-query';

export type IProfile = {
  id: number;
  email: string | null;
  username: string | null;
  phone_number: string | null;
  first_name: string | null;
  last_name: string | null;
};

export interface IPayload {
  first_name: string;
  last_name: string;
  phone_number: string;
  photo: string;
}

export const getDataProfileByEmail = (email: string) => async () => {
  return (await supabase
    .from(TABLE.ACCOUNTS)
    .select('*, members(teams(name, id))')
    .eq('email', email)
    .single());
};

export const useProfileByEmail = (email: string) =>
  useQuery({
    queryKey: ['profile', email],
    queryFn: getDataProfileByEmail(email),
    enabled: !!email
  });

export const updateProfile = async (payload: IPayload) => {
  return await supabase
    .from('user.accounts')
    .upsert([
      {
        first_name: payload.first_name,
        last_name: payload.last_name,
        phone_number: payload.phone_number,
        photo: payload.photo
      }
    ]);
};

export const useUpdateProfile = (refetchProfile?: () => void) =>
  useMutation({
    mutationKey: ['updateProfile'],
    mutationFn: (payload: IPayload) => updateProfile(payload),
    onSuccess: refetchProfile
  });
