import { FC, ReactNode } from 'react';

type IBaseBanner = {
  children?: ReactNode;
};

const BaseContainer: FC<IBaseBanner> = ({ children }) => {
  return <div className="p-6">{children}</div>;
};

export default BaseContainer;
