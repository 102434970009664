import dayjs from 'dayjs';
import * as XLSX from 'xlsx';

import { supabase } from '@/lib/supabase';
import { FLASH_SALE_PRICE_BY, FLASH_SALE_SORT_BY, FLASH_SALE_SORT_VALUE, TABLE } from '@/constants';
import { ChangeEvent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { toUpper, uniq } from 'lodash';
import {
  HeaderFlashSale,
  IDropdown,
  IFormFlashSale,
  IPeriode,
  IProductVariant,
  ISaleableStock,
  ISlotSupabase,
  ITemplateXLS,
  ITime,
  ITimeRange
} from '../type';
import { menuFlashSaleInformation } from '../helpers';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '@/store/useAuthStore';
import { useFormik } from 'formik';
import { schemaFlashSale } from './validation';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { FileUpload, FileUploadFilesEvent } from 'primereact/fileupload';
import { IHistory, insertImportHistory } from '@/services/rest/importHistory';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { getProductMerchantDetail } from '@/services/rest/product';

interface IFormikState {
  name: string;
  description: string;
  status: string;
  periode: string;
  valid_from: string;
  valid_to: string;
  slot_type: string;
}

export interface ITableDataProducts extends ITemplateXLS {
  'Product Name': string;
  'Merchant': string;
  'Stock': number;
  'Status'?: boolean;
  'Product ID'?: string;
  id?: string | number;
  index?: number;
}

export const useCreateFlashSale = () => {
  // Sidebar Flashsale { FLASH SALE DETAIL || FLASH SALE PRODUCT }
  const [dataMenuFlashSale, setDataMenuFlashSale] = useState<string>('FLASH SALE DETAIL');
  const [activeMenu, setActiveMenu] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [slot, setSlot] = useState<ISlotSupabase[]>();
  const [timeRange, setTimeRange] = useState<ITimeRange[]>();
  const [optionSlot, setOptionSlot] = useState<IDropdown[]>();
  const [time, setTime] = useState<ITime[]>();
  const [isOpenDropdownSlot, setIsOpenDropdownSlot] = useState<boolean>(false);
  const [slotName, setSlotName] = useState<string>('');
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string | null>(null);
  const [, setFileUpdateName] = useState<string | null>(null);
  const [tableData, setTableData] = useState<ITableDataProducts[]>([]);
  const [history, setHistory] = useState<string[]>([]);
  const [formCreateFlashSale, setFormCreateFlashSale] = useState<IFormFlashSale>({
    product_variant_id: '',
    master_slot_id: {} as IDropdown,
    periode: {} as IDropdown,
    multiple: [],
    valid_from: '',
    valid_to: '',
    name: '',
    description: '',
    saleable_stock: 0,
    total_stock: 0,
    price_by: '',
    price_value: 0,
    status: {} as IDropdown
  });

  const initialFormik: IFormikState = {
    name: '',
    description: '',
    status: '',
    periode: '',
    valid_from: '',
    valid_to: '',
    slot_type: ''
  };

  const formik = useFormik({
    initialValues: initialFormik,
    validationSchema: schemaFlashSale,
    onSubmit: () => {
      const validFrom = dayjs(formCreateFlashSale.valid_from);
      const validTo = dayjs(formCreateFlashSale.valid_to);
      const isSameMultiple = checkMultipleDate(formCreateFlashSale.multiple);
      if (validFrom.isAfter(validTo)) {
        showToastCreateFlashSaleFailed('Valid from date is not allowed to grater than valid to date!');
      } else if (checkEmptyArray(formCreateFlashSale.multiple)) {
        showToastCreateFlashSaleFailed('Multiple date is empty');
      } else if (isSameMultiple == true) {
        showToastCreateFlashSaleFailed('Multiple date is not allowed with same date');
      } else {
        onSaveFlashSale();
      }
    }
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const { user } = useAuthStore((state) => ({ user: state.user }));

  const showToastCreateFlashSaleSuccesfully = (msg: string) => {
    toast.current?.show({ severity: 'success', summary: 'Success', detail: msg, life: 2000 });
    const timeout = setTimeout(() => {
      setIsSubmit(false);
      setIsLoading(false);
      navigate('/flash-sale');
    }, 2000);
    return () => clearTimeout(timeout);
  };

  const showToastCreateFlashSaleFailed = (msg: string, duration: number = 10000) => {
    toast.current?.show({ severity: 'error', summary: 'Gagal', detail: msg, life: duration });
    setIsLoading(false);
    setIsSubmit(false);
  };

  const onChangeFormCreateFlashSale = (key: string, value: string | number | null | IDropdown) => {
    setFormCreateFlashSale((prevState) => {
      return {
        ...prevState,
        [key]: value
      };
    });

    if (key == 'master_slot_id') {
      const times = slot?.find((item) => item.id === (value as IDropdown).code)?.time;
      setTime(times as ITime[]);
    }
  };

  const changeInputText = useCallback(
    (key: string) =>
      ({ currentTarget }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        //set value to formik state
        formik.setFieldValue(key, currentTarget.value);
        //set value to form state

        setFormCreateFlashSale((prev) => ({
          ...prev,
          [key]: currentTarget.value
        }));
      },
    []
  );

  const handleChangeDropdown = useCallback(
    (index: number, key: string) => (event: DropdownChangeEvent) => {
      formCreateFlashSale[key].map(() => (formCreateFlashSale[key][index] = event.value));
      //set value dropdown to formik state
      formik.setFieldValue(key, [...formCreateFlashSale[key]]);
      //set value dropdown to state
      setFormCreateFlashSale((prev) => ({
        ...prev,
        [key]: [...formCreateFlashSale[key]]
      }));
    },
    [formCreateFlashSale]
  );

  // Get data slot from supabase
  const getSlot = async () => {
    const { data, error } = await supabase.from(TABLE.SLOT).
      select('id, name, time').
      eq('status', 'ACTIVE').is('deleted_at', null).
      order('id', { ascending: false }).limit(10);

    if (data != null && !error) {
      setSlot(data as ISlotSupabase[]);
      const temp = data?.map((m) => {
        const time: ITime[] = m.time.map((t, idx) => {
          return {
            index: idx + 1,
            started_time: t.started_time,
            ended_time: t.ended_time
          };
        });
        return {
          id: String(m.id),
          name: String(m.name),
          time: time
        };
      });
      setTimeRange(temp);
      const result = data.map((item) => {
        return {
          name: item.name,
          code: item.id
        };
      });
      setOptionSlot(result as IDropdown[]);
    }
  };

  // HANDLE MENU SIDEBAR FLASHSALE
  const handleMenu = useCallback(
    (menu: string) => {
      setDataMenuFlashSale(menu);
    },
    [setDataMenuFlashSale, dataMenuFlashSale]
  );

  // HANDLE ACTIVE MENU
  const handleActiveMenu = (id: number, label: string) => () => {
    handleMenu(toUpper(label));
    setActiveMenu(id);
  };

  // CHANGE COLOR ACTIVE MENU
  const handleActiveColorMenu = useCallback(
    (idx: number): string => {
      return idx === activeMenu ? 'bg-gray-100' : 'bg-gray-300';
    },
    [activeMenu]
  );

  const checkMultipleDate = (arr) => {
    const seen = {};
    for (let i = 0; i < arr.length; i++) {
      if (seen[arr[i]]) {
        return true;
      }
      seen[arr[i]] = true;
    }
    return false;
  };

  function checkEmptyArray(arr) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] == '') {
        return true;
      }
    }
    return false;
  }

  // click + add more
  const addMultipleDate = useCallback(() => {
    if (checkMultipleDate(formCreateFlashSale.multiple)) {
      showToastCreateFlashSaleFailed('Multiple date is not allowed with same date');
    } else {
      formCreateFlashSale.multiple.push('');
      setFormCreateFlashSale((prevState) => {
        return {
          ...prevState,
          multiple: [...formCreateFlashSale.multiple]
        };
      });
    }
  }, [formCreateFlashSale.multiple, setFormCreateFlashSale]);

  // onchange on calendar
  const addToMultipleDate = useCallback(
    (index, value) => {
      if (formCreateFlashSale.multiple.includes(dayjs(value).format('YYYY-MM-DD'))) {
        showToastCreateFlashSaleFailed('Multiple date is not allowed with same date');
      } else {
        formCreateFlashSale.multiple.map(
          () => (formCreateFlashSale.multiple[index] = dayjs(value).format('YYYY-MM-DD'))
        );
        setFormCreateFlashSale((prevState) => {
          return {
            ...prevState,
            multiple: [...formCreateFlashSale.multiple]
          };
        });
      }
    },
    [formCreateFlashSale, setFormCreateFlashSale]
  );

  // delete one of calendar field
  const deleteMultipleDate = useCallback(
    (index: number) => () => {
      formCreateFlashSale.multiple.splice(index, 1);
      setFormCreateFlashSale((prevState) => {
        return {
          ...prevState,
          multiple: [...formCreateFlashSale.multiple]
        };
      });
    },
    [formCreateFlashSale.multiple, setFormCreateFlashSale]
  );

  useEffect(() => {
    getSlot();
  }, []);

  // HANDLE UPLOAD CSV
  const showUploaded = () => {
    if (toast.current !== null) {
      toast.current.show({
        severity: 'success',
        summary: 'Uploaded',
        detail: 'File Uploaded Successfully',
        life: 2500
      });
    }
  };

  const showFailed = () => {
    if (toast.current !== null) {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: 'Data Empty, Please check your file before upload',
        life: 2500
      });
    }
  };

  const showPartialSuccess = () => {
    if (toast.current !== null) {
      toast.current.show({
        severity: 'warn',
        summary: 'Uploaded',
        detail: 'File Uploaded Successfully, But Some Data Is Fail To Proceed',
        life: 4000
      });
    }
  };

  const [files, setFiles] = useState<string>('');
  const [filesUpdate, setFilesUpdate] = useState<string>('');
  const fileUploaded = useRef<FileUpload>(null);
  const fileUpdateUploaded = useRef<FileUpload>(null);
  const [productUpload, setProductUpload] = useState<ITemplateXLS[]>([]);

  const handleSelectFile = useCallback(async (e: FileUploadFilesEvent) => {
    setIsSubmit(true);
    if (e.files) {
      const text = await e.files[0].arrayBuffer();
      const wb = XLSX.read(text, { type: 'binary', cellDates: true });
      const sn = wb.SheetNames[0];
      const ws = wb.Sheets[sn];
      const json = XLSX.utils.sheet_to_json<ITemplateXLS>(ws, { raw: false, dateNF: 'YYYY-MM-DD' });
      const options = { header: 1 };
      const sheetData2 = XLSX.utils.sheet_to_json(ws, options);
      const header = sheetData2.shift();

      // Validasi header
      const isHeaderValid = JSON.stringify(header) === JSON.stringify(HeaderFlashSale);
      setFileName(e.files[0].name);

      if (json.length === 0) {
        showFailed();
        fileUploaded.current?.clear();
      } else if (!isHeaderValid) {
        toast?.current?.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'File Not Same With Template, Check your file corectly',
          life: 2500
        });
        setFiles(e.files[0].name);
        fileUploaded.current?.clear();
      } else {
        setFiles(e.files[0].name);
        fileUploaded.current?.clear();
        mapTableData(json.slice(1));
      }
    }
    setIsSubmit(false);
  }, [
    formCreateFlashSale.valid_from,
    formCreateFlashSale.valid_to,
    formCreateFlashSale.multiple,
    formCreateFlashSale.periode.code
  ]);

  const handleSelectFileUpdate = useCallback(async (e: FileUploadFilesEvent) => {
    setIsSubmit(true);
    if (e.files) {
      const text = await e.files[0].arrayBuffer();
      const wb = XLSX.read(text, { type: 'binary', cellDates: true });
      const sn = wb.SheetNames[0];
      const ws = wb.Sheets[sn];
      const json = XLSX.utils.sheet_to_json<ITemplateXLS>(ws, { raw: false, dateNF: 'YYYY-MM-DD' });
      const options = { header: 1 };
      const sheetData2 = XLSX.utils.sheet_to_json(ws, options);
      const header = sheetData2.shift();
      // Validasi header
      const isHeaderValid = JSON.stringify(header) === JSON.stringify(HeaderFlashSale);
      setFileUpdateName(e.files[0].name);
      if (json.length === 0) {
        showFailed();
        fileUpdateUploaded.current?.clear();
      } else if (!isHeaderValid) {
        toast?.current?.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'File Not Same With Template, Check your file corectly',
          life: 2500
        });
        setFilesUpdate(e.files[0].name);
        fileUpdateUploaded.current?.clear();
      } else {
        showUploaded();
        setFilesUpdate(e.files[0].name);
        fileUpdateUploaded.current?.clear();
        updateTableData(json.slice(1));
      }
    }
    setIsSubmit(false);
  }, [
    tableData,
    formCreateFlashSale.valid_from,
    formCreateFlashSale.valid_to,
    formCreateFlashSale.multiple,
    formCreateFlashSale.periode.code
  ]);

  const getMappedDataAndValidateData = useCallback(async (data: ITemplateXLS[]) => {
    const listProductMerchantDetail = await Promise.all(data.map(async (item) => {
      const detail = await getProductMerchantDetail(item?.['Product Variant ID']);
      if (!!detail?.error && detail?.error.message.includes('uuid')) {
        return { error: true, message: 'invalid product variant' };
      }
      if (!!detail?.error) return { error: true, message: 'failed to get data detail' };
      return detail.data;
    }));

    const fsValidFrom = String(formCreateFlashSale.valid_from);
    const fsValidTo = String(formCreateFlashSale.valid_to);

    const mappedData: ITableDataProducts[] = [];

    setHistory([]);

    data.forEach((item, index) => {
      const isError = !!listProductMerchantDetail[index]?.['error'];
      const errorMessage = isError ? listProductMerchantDetail[index]?.['message'] : '';
      const saleableStock = listProductMerchantDetail[index]?.['saleable_stock'];
      const isValidPrice = listProductMerchantDetail[index]?.['price'] !== null;
      const periode = String(dayjs(item['Periode']).format('YYYY-MM-DD'));

      const isFsStockMoreThanSaleableStock = item['Jumlah Stock Flash Sale'] > saleableStock;
      const isOnPeriode = formCreateFlashSale.periode.code === 'multiple' ?
        formCreateFlashSale.multiple.includes(periode) :
        periode >= fsValidFrom && periode <= fsValidTo;
      const isNotDuplicatePeriodeAndSlot = mappedData.findIndex((fsProduct) => (
        fsProduct['Product Variant ID'] === item['Product Variant ID'] &&
        fsProduct['Periode'] === item['Periode'] &&
        fsProduct['Slot'] === item['Slot']
      )) === -1;
      const isNotDuplicateSequence = mappedData.findIndex((fsProduct) => (
        fsProduct['Periode'] === item['Periode'] &&
        fsProduct['Slot'] === item['Slot'] &&
        item['Sort By'] === 'sequence' &&
        item['Sort Value'] === fsProduct['Sort Value']
      )) === -1;

      if (
        !isError &&
        !isFsStockMoreThanSaleableStock &&
        isValidPrice &&
        isOnPeriode &&
        isNotDuplicatePeriodeAndSlot &&
        isNotDuplicateSequence
      ) {
        const variants = listProductMerchantDetail[index]?.['attribute_set_variants']
          ?.map((variant: unknown) => variant?.['value'])
          ?.join(', ');
        const productName: string = listProductMerchantDetail[index]?.['products']?.['name'];
        const merchant = listProductMerchantDetail[index]?.['products']?.['merchants']?.['name'];

        const productFsData = {
          ...item,
          'Product Name': `${productName}${!variants ? '' : ` Varian: (${variants})`}`,
          'Merchant': merchant,
          'Stock': saleableStock,
          'Status': true,
          index
        };
        mappedData.push(productFsData);
      } else {
        const errorMessages: string[] = [];
        if (isError) errorMessages.push(errorMessage);
        if (isFsStockMoreThanSaleableStock) {
          errorMessages.push('stock flash sale exceeds the stock of the product');
        }
        if (!isValidPrice) {
          errorMessages.push('product price is invalid');
        }
        if (!isOnPeriode) {
          errorMessages.push('period is not aligned with parent\'s period');
        }
        if (!isNotDuplicatePeriodeAndSlot) {
          errorMessages.push('slot is already occupied by other product');
        }
        if (!isNotDuplicateSequence) {
          errorMessages.push('sequence is already occupied by other product');
        }
        setHistory((current) => [
          ...current,
          `Baris-${index + 3} ${errorMessages.join(', ')}`
        ]);
      }
    });

    return mappedData;
  }, [
    formCreateFlashSale.valid_from,
    formCreateFlashSale.valid_to,
    formCreateFlashSale.multiple,
    formCreateFlashSale.periode.code
  ]);

  const mapTableData = useCallback(async (data: ITemplateXLS[]) => {
    const mappedData = await getMappedDataAndValidateData(data);

    const isSomeDataFailToProceed = data.length !== mappedData.length;
    if (isSomeDataFailToProceed) showPartialSuccess();
    else showUploaded();

    setTableData(mappedData);
  }, [
    formCreateFlashSale.valid_from,
    formCreateFlashSale.valid_to,
    formCreateFlashSale.multiple,
    formCreateFlashSale.periode.code
  ]);

  const updateTableData = useCallback(async (data: ITemplateXLS[]) => {
    const newTableData = tableData.slice();
    const mappedUpdatedData = await getMappedDataAndValidateData(data);

    const isSomeDataFailToProceed = data.length !== mappedUpdatedData.length;
    if (isSomeDataFailToProceed) showPartialSuccess();
    else showUploaded();

    for (const item of mappedUpdatedData) {
      const index = newTableData.findIndex(
        (elmt) => elmt['Product Variant ID'] === item['Product Variant ID']
      );
      const isDuplicatePeriodeAndSlot = newTableData.filter((fsProduct) => (
        fsProduct['Product Variant ID'] === item['Product Variant ID'] &&
        fsProduct['Periode'] === item['Periode'] &&
        fsProduct['Slot'] === item['Slot']
      )).length > 1;
      const isDuplicateSequence = newTableData.filter((fsProduct) => (
        fsProduct['Product Variant ID'] !== item['Product Variant ID'] &&
        fsProduct['Periode'] === item['Periode'] &&
        fsProduct['Slot'] === item['Slot'] &&
        item['Sort By'] === 'sequence' &&
        item['Sort Value'] === fsProduct['Sort Value']
      )).length > 0;

      if (index !== -1 && !isDuplicatePeriodeAndSlot && !isDuplicateSequence) {
        newTableData[index]['Flash Sale Price By'] = item['Flash Sale Price By'];
        newTableData[index]['Flash Sale Price Value'] = item['Flash Sale Price Value'];
        newTableData[index]['Jumlah Stock Flash Sale'] = item['Jumlah Stock Flash Sale'];
        newTableData[index]['Sort By'] = item['Sort By'];
        newTableData[index]['Sort Value'] = item['Sort Value'];
        newTableData[index]['Periode'] = item['Periode'];
        newTableData[index]['Slot'] = item['Slot'];
      }
    }

    setTableData(newTableData);
  }, [
    tableData,
    filesUpdate,
    formCreateFlashSale.valid_from,
    formCreateFlashSale.valid_to,
    formCreateFlashSale.multiple,
    formCreateFlashSale.periode.code
  ]);

  const onUpload = () => {
    toast.current?.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
  };

  const dataProduct = productUpload.slice();

  const onSaveFlashSale = useCallback(async () => {
    if (dataProduct.length <= 0 ) {
      showFailed();
      fileUploaded.current?.clear();
      return;
    }
    setIsLoading(true);
    setIsSubmit(true);

    const timeArray: string[] = [];
    for (const [, item] of dataProduct.entries()) {
      const tempTimeSlot = timeRange
        ?.filter((f) => f.name === slotName)
        .flatMap((m) => m.time.filter((t) => t.index === Number(item.Slot)));

      if (tempTimeSlot?.[0]?.started_time) {
        timeArray.push(tempTimeSlot[0]?.started_time);
      }

      if (tempTimeSlot?.[0]?.ended_time) {
        timeArray.push(tempTimeSlot[0]?.ended_time);
      }
    }

    const { data: flashsales } = await supabase.rpc('catalogue.get_occupied_flash_sales', {
      filter_date: formCreateFlashSale.periode.code === 'multiple' ? formCreateFlashSale.multiple : [String(formCreateFlashSale.valid_from), String(formCreateFlashSale.valid_to)],
      filter_time: uniq(timeArray)
    });

    if (flashsales && flashsales.length > 0) {
      const { data: parentFlashsales } = await supabase.from(TABLE.FLASHSALE).select('id, name').in('id', uniq(flashsales.map((fs) => fs.parent_id)));
      showToastCreateFlashSaleFailed('Tanggal dan waktu flashsale sudah digunakan flashsale lain', 50000);
      showToastCreateFlashSaleFailed(`Telah digunakan oleh:\n\n ${parentFlashsales?.map((pfs) => `${pfs.name}(${pfs.id})\n\n`)}`, 50000);
      return;
    }

    // if periode type is range
    if (formCreateFlashSale.periode.code === 'range') {
      const metadata: IPeriode = {
        date: [String(formCreateFlashSale.valid_from), String(formCreateFlashSale.valid_to)],
        isRange: true,
        isExpired: false,
        isEnable: Boolean(formCreateFlashSale.status.code)
      };

      const { data: headerFS, error: errHeaderFS } = await supabase
        .from(TABLE.FLASHSALE)
        .insert([
          {
            name: formCreateFlashSale.name,
            description: formCreateFlashSale.description,
            master_slot_id: formCreateFlashSale.master_slot_id.code,
            periode: metadata,
            created_by: user?.email
          }
        ])
        .select('id')
        .single();

      if (errHeaderFS) {
        showToastCreateFlashSaleFailed('Flash Sale Name is Exist!');
        return;
      }

      if (headerFS !== null) {
        for (const [idx, item] of dataProduct.entries()) {
          // check product variant is exist
          const { data: validProductVariant } = (await supabase
            .from(TABLE.PRODUCT_VARIANTS)
            .select('id, product_id')
            .eq('id', item['Product Variant ID'])
            .single()) as PostgrestSingleResponse<IProductVariant>;

          const { data: saleableStock } = (await supabase
            .from(TABLE.PRODUCT_VARIANTS)
            .select('id, saleable_stock')
            .eq('id', item['Product Variant ID'])
            .single()) as PostgrestSingleResponse<ISaleableStock>;

          const currentSaleableStock = Number(saleableStock?.saleable_stock ?? 0);

          const metadataProduct: IPeriode = {
            date: [String(dayjs(item.Periode).format('YYYY-MM-DD'))],
            isRange: false,
            isExpired: false,
            isEnable: Boolean(formCreateFlashSale.status.code)
          };

          const tempTimeSlot = timeRange
            ?.filter((f) => f.name === slotName)
            .flatMap((m) => m.time.filter((t) => t.index === Number(item.Slot)));

          const timeSlot = Array.isArray(tempTimeSlot) && tempTimeSlot[0]?.started_time && tempTimeSlot[0]?.ended_time ? {
            start_time: tempTimeSlot[0]?.started_time,
            ended_time: tempTimeSlot[0]?.ended_time
          } : null;

          const result = {
            product_variant_id: item['Product Variant ID'],
            product_id: validProductVariant?.product_id,
            master_slot_id: formCreateFlashSale.master_slot_id.code,
            periode: metadataProduct,
            saleable_stock: Number(item['Jumlah Stock Flash Sale']),
            price_by: FLASH_SALE_PRICE_BY[item['Flash Sale Price By']],
            price_value: Number(item['Flash Sale Price Value']),
            total_stock: Number(item['Jumlah Stock Flash Sale']),
            parent_id: headerFS.id,
            sort_value: isNaN(Number(item['Sort Value'])) ? FLASH_SALE_SORT_VALUE[item['Sort Value']] : item['Sort Value'],
            sort_by: FLASH_SALE_SORT_BY[item['Sort By']],
            created_by: user?.email,
            time_slot: timeSlot
          };

          const { error: errDetailFS } = await supabase.from(TABLE.FLASHSALE).insert(result);
          await supabase
            .from(TABLE.PRODUCT_VARIANTS)
            .update({ saleable_stock: currentSaleableStock - result.saleable_stock })
            .eq('id', result.product_variant_id);


          if (errDetailFS) {
            setHistory((current) => [
              ...current,
              `Baris-${idx + 3} ${errDetailFS?.message}`
            ]);
          }
        }
      }
    }

    // if periode type is multiple
    if (formCreateFlashSale.periode.code === 'multiple') {
      const metadata: IPeriode = {
        date: formCreateFlashSale.multiple,
        isRange: false,
        isExpired: false,
        isEnable: Boolean(formCreateFlashSale.status.code)
      };

      const { data: headerFS, error: errHeaderFS } = await supabase
        .from(TABLE.FLASHSALE)
        .insert([
          {
            name: formCreateFlashSale.name,
            description: formCreateFlashSale.description,
            master_slot_id: formCreateFlashSale.master_slot_id.code,
            periode: metadata,
            created_by: user?.email
          }
        ])
        .select('id')
        .single();

      if (errHeaderFS) {
        showToastCreateFlashSaleFailed('Flash sale name is exist!');
        return;
      }

      if (headerFS != null) {
        for (const [idx, item] of dataProduct.entries()) {
          // check product variant is exist
          const { data: validProductVariant } = (await supabase
            .from(TABLE.PRODUCT_VARIANTS)
            .select('id, product_id')
            .eq('id', item['Product Variant ID'])
            .single()) as PostgrestSingleResponse<IProductVariant>;

          const { data: saleableStock } = (await supabase
            .from(TABLE.PRODUCT_VARIANTS)
            .select('id, saleable_stock')
            .eq('id', item['Product Variant ID'])
            .single()) as PostgrestSingleResponse<ISaleableStock>;

          const currentSaleableStock = Number(saleableStock?.saleable_stock);

          const metadataProduct: IPeriode = {
            date: [String(dayjs(item.Periode).format('YYYY-MM-DD'))],
            isRange: false,
            isExpired: false,
            isEnable: Boolean(formCreateFlashSale.status.code)
          };
          const tempTimeSlot = timeRange
            ?.filter((f) => f.name === slotName)
            .flatMap((m) => m.time.filter((t) => t.index === Number(item.Slot)));
          const timeSlot =
                  Array.isArray(tempTimeSlot) && tempTimeSlot[0]?.started_time && tempTimeSlot[0]?.ended_time ?
                    {
                      start_time: tempTimeSlot[0]?.started_time,
                      ended_time: tempTimeSlot[0]?.ended_time
                    } :
                    null;
          const result = {
            product_variant_id: item['Product Variant ID'],
            product_id: validProductVariant?.product_id,
            master_slot_id: formCreateFlashSale.master_slot_id.code,
            periode: metadataProduct,
            saleable_stock: Number(item['Jumlah Stock Flash Sale']),
            price_by: FLASH_SALE_PRICE_BY[item['Flash Sale Price By']],
            price_value: Number(item['Flash Sale Price Value']),
            total_stock: Number(item['Jumlah Stock Flash Sale']),
            parent_id: headerFS.id,
            sort_value: isNaN(Number(item['Sort Value'])) ? FLASH_SALE_SORT_VALUE[item['Sort Value']] : item['Sort Value'],
            sort_by: FLASH_SALE_SORT_BY[item['Sort By']],
            created_by: user?.email,
            time_slot: timeSlot
          };

          const { error: errDetailFS } = await supabase.from(TABLE.FLASHSALE).insert(result);
          await supabase
            .from(TABLE.PRODUCT_VARIANTS)
            .update({ saleable_stock: currentSaleableStock - result.saleable_stock })
            .eq('id', result.product_variant_id);

          if (errDetailFS) {
            setHistory((current) => [
              ...current,
              `Baris-${idx + 3} ${errDetailFS?.message}`
            ]);
          }
        }
      }
    }

    const payloadImportHistorySuccess = dataProduct.length - history.length > 0 && {
      account_id: user?.id || '',
      file_path: fileName || '',
      row_number: dataProduct.length - history.length,
      status: true,
      type: 'bulk_create_flash_sale'
    };

    const payloadImportHistoryError = history.length > 0 && {
      account_id: user?.id || '',
      file_path: fileName || '',
      errors: history,
      row_number: history.length,
      status: false,
      type: 'bulk_create_flash_sale'
    };
    const payloadImportHistory = [payloadImportHistorySuccess, payloadImportHistoryError].filter((item) => typeof item != 'boolean');

    await insertImportHistory(payloadImportHistory as IHistory[]);
    showToastCreateFlashSaleSuccesfully('Create Flash Sale Succesfully');
  }, [formCreateFlashSale, timeRange, dataProduct, user, fileName, history, formik]);

  const handleClickDropdownSlot = useCallback(() => {
    setIsOpenDropdownSlot(!isOpenDropdownSlot);
  }, [isOpenDropdownSlot]);

  const handleMouseEnter = useCallback(
    (name: string) => (event: MouseEvent<HTMLOptionElement>) => {
      setSlotName(name);
      const times = slot?.find((item) => item.id === event.currentTarget.value)?.time;
      setTime(times as ITime[]);
    },
    [slot]
  );

  const handleClickDropdownType = useCallback(
    (name: string, code: string) => () => {
      formik.setFieldValue('slot_type', code);
      setFormCreateFlashSale((prev) => ({
        ...prev,
        master_slot_id: { code: code, name: name }
      }));
      setIsOpenDropdownSlot(false);
    },
    []
  );

  const handleDownloadTemplate = useCallback(() => {
    const link = document.createElement('a');
    link.href = `${window.location.href}/createFlashSaleTemplate.xlsx`;
    link.target = '_blank';
    link.download = '';
    link.click();
  }, []);

  return {
    data: {
      fileUploaded,
      fileUpdateUploaded,
      files,
      filesUpdate,
      tableData,
      formCreateFlashSale,
      menuFlashSaleInformation,
      dataMenuFlashSale,
      slot,
      optionSlot,
      isLoading,
      time,
      toast,
      isOpenDropdownSlot,
      slotName,
      formik,
      isSubmit
    },
    methods: {
      showFailed,
      onUpload,
      handleActiveMenu,
      handleActiveColorMenu,
      onChangeFormCreateFlashSale,
      addMultipleDate,
      addToMultipleDate,
      deleteMultipleDate,
      handleSelectFile,
      handleSelectFileUpdate,
      onSaveFlashSale,
      handleClickDropdownSlot,
      handleMouseEnter,
      handleClickDropdownType,
      handleDownloadTemplate,
      isFormFieldValid,
      changeInputText,
      handleChangeDropdown,
      setProductUpload
    }
  };
};
