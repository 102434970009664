import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import { InputText } from 'primereact/inputtext';
import useDetailCustomer from '../../DetailHooks';
import LoadingOverlay from '@/components/base/LoadingOverlay';

const AccountInformation = () => {
  const { data: { fieldAccountInfo, isLoading } } = useDetailCustomer();

  return (
    <>
      <LoadingOverlay show={isLoading}/>
      <BaseContainer>
        <label className="font-bold">
        Account Information
        </label>
        <hr className="text-black mt-2" />
        <div className="flex p-2 w-full mt-2 gap-x-11">
          <div className="flex items-center w-[200px]">
            <label htmlFor="label-field">First Name</label>
          </div>
          <div className="w-full">
            <InputText id="customer-first-name-input" className="w-full" value={fieldAccountInfo.first_name} disabled/>
          </div>
        </div>
        <div className="flex p-2 w-full mt-2 gap-x-11">
          <div className="flex items-center w-[200px]">
            <label htmlFor="label-display">Middle Name/Initial</label>
          </div>
          <div className="w-full">
            <InputText id="customer-middle-name-input" className="w-full" disabled/>
          </div>
        </div>
        <div className="flex p-2 w-full mt-2 gap-x-11">
          <div className="flex items-center w-[200px]">
            <label htmlFor="label-display">Last Name</label>
          </div>
          <div className="w-full">
            <InputText id="customer-last-name-input" className="w-full" value={fieldAccountInfo.last_name} disabled/>
          </div>
        </div>
        <div className="flex p-2 w-full mt-2 gap-x-11">
          <div className="flex items-center w-[200px]">
            <label htmlFor="label-display">Name Prefix</label>
          </div>
          <div className="w-full">
            <InputText id="customer-name-prefix-input" className="w-full" disabled/>
          </div>
        </div>
        <div className="flex p-2 w-full mt-2 gap-x-11">
          <div className="flex items-center w-[200px]">
            <label htmlFor="label-display">Name Suffix</label>
          </div>
          <div className="w-full">
            <InputText id="customer-name-suffix-input" className="w-full" disabled/>
          </div>
        </div>
        <div className="flex p-2 w-full mt-2 gap-x-11">
          <div className="flex items-center w-[200px]">
            <label htmlFor="label-display">Email</label>
          </div>
          <div className="w-full">
            <InputText id="customer-email-input" className="w-full" value={fieldAccountInfo.email} disabled/>
          </div>
        </div>
        <div className="flex p-2 w-full mt-2 gap-x-11">
          <div className="flex items-center w-[200px]">
            <label htmlFor="label-display">Telephone</label>
          </div>
          <div className="w-full">
            <InputText id="customer-telephone-input" className="w-full" value={fieldAccountInfo.phone_number} disabled/>
          </div>
        </div>
        <div className="flex p-2 w-full mt-2 gap-x-11">
          <div className="flex items-center w-[200px]">
            <label htmlFor="label-display">Assosiate to Website</label>
          </div>
          <div className="w-full">
            <InputText id="customer-assosiate-to-website-input" className="w-full" disabled/>
          </div>
        </div>
        <div className="flex p-2 w-full mt-2 gap-x-11">
          <div className="flex items-center w-[200px]">
            <label htmlFor="label-display">Group</label>
          </div>
          <div className="w-full">
            <InputText id="customer-group-input" className="w-full" value={fieldAccountInfo.members as string} disabled/>
          </div>
        </div>
        <div className="flex p-2 w-full mt-2 gap-x-11">
          <div className="flex items-center w-[200px]">
            <label htmlFor="label-display">Date of Birth</label>
          </div>
          <div className="w-full">
            <InputText id="customer-birth-date-input" className="w-full" value={fieldAccountInfo.birth_date as string} disabled/>
          </div>
        </div>
        <div className="flex p-2 w-full mt-2 gap-x-11">
          <div className="flex items-center w-[200px]">
            <label htmlFor="label-display">Tax/VAT Number</label>
          </div>
          <div className="w-full" >
            <InputText id="customer-VAT-number-input" className="w-full" disabled/>
          </div>
        </div>
        <div className="flex p-2 w-full mt-2 gap-x-11">
          <div className="flex items-center w-[200px]">
            <label htmlFor="label-display">Gender</label>
          </div>
          <div className="w-full">
            <InputText id="customer-gender-input" className="w-full" disabled value={fieldAccountInfo.gender}/>
          </div>
        </div>
        <div className="flex p-2 w-full mt-2 gap-x-11">
          <div className="flex items-center w-[200px]">
            <label htmlFor="label-display">Send Welcome Email From</label>
          </div>
          <div className="w-full">
            <InputText id="customer-send-welcome-email-from-input" className="w-full" disabled/>
          </div>
        </div>
        <div className="flex p-2 w-full mt-2 gap-x-11">
          <div className="flex items-center w-[200px]">
            <label htmlFor="label-display">Vartex Customer Code</label>
          </div>
          <div className="w-full">
            <InputText id="customer-vartex-customer-code-input" className="w-full" disabled/>
          </div>
        </div>
        <div className="flex p-2 w-full mt-2 gap-x-11">
          <div className="flex items-center w-[200px]">
            <label htmlFor="label-display">Whatsapp Number</label>
          </div>
          <div className="w-full">
            <InputText id="customer-whatsapp-number-input" className="w-full" disabled value={fieldAccountInfo.phone_number}/>
          </div>
        </div>
      </BaseContainer>
    </>
  );
};

export default AccountInformation;
