import { useState } from 'react';

const useStoreCredit = () => {
  const [tabMenuActiveIndex, setTabMenuActiveIndex] = useState(0);

  return {
    data: {
      tabMenuActiveIndex
    },
    method: {
      setTabMenuActiveIndex
    }
  };
};

export default useStoreCredit;

