// import { PutObjectCommand, type PutObjectCommandInput, type PutObjectCommandOutput, S3Client } from '@aws-sdk/client-s3';

// export interface IStore {
//   label: string
//   src: string
// }

// const VITE_S3_REGION = 'ap-southeast-1';
// const VITE_S3_ACCESS_KEY = import.meta.env.VITE_APP_S3_ACCESS_KEY;
// const VITE_S3_SECRET_KEY = import.meta.env.VITE_APP_S3_SECRET_KEY;
// const VITE_S3_BUCKET = import.meta.env.VITE_APP_S3_BUCKET;
// const VITE_APP_STORAGE_URL = import.meta.env.VITE_APP_STORAGE_URL;

// const client = new S3Client({
//   region: VITE_S3_REGION,
//   apiVersion: '2006-03-01',
//   credentials: {
//     accessKeyId: VITE_S3_ACCESS_KEY,
//     secretAccessKey: VITE_S3_SECRET_KEY
//   }
// });

// export const storageUpload = async (path: string, file: File): Promise<IStore | null> => {
//   try {
//     const fileName = `${file.name}${Date.now()}`;
//     const filePath = `stub/${path}`;
//     const params: PutObjectCommandInput = {
//       Key: filePath + fileName,
//       Bucket: VITE_S3_BUCKET,
//       ContentType: file.type,
//       Body: file,
//       CacheControl: '3600',
//       BucketKeyEnabled: true
//     };

//     const command = new PutObjectCommand(params);

//     const response: PutObjectCommandOutput = await client.send(command);

//     if (response.$metadata.httpStatusCode == 200) {
//       return {
//         label: file.name,
//         src: `${VITE_APP_STORAGE_URL}/${filePath}${encodeURIComponent(fileName)}`
//       };
//     }

//     return null;
//   } catch (error) {
//     return null;
//   };
// };

import { supabase } from '@/lib/supabase';

export interface IStore {
  label: string
  src: string
}

const VITE_APP_STORAGE_URL = import.meta.env.VITE_APP_STORAGE_URL;

const sanitizeFilename = (filename: string) => {
  return filename.replace(/[^a-z0-9.]/gi, '_').toLowerCase();
};

export const storageUpload = async (path: string, file: File): Promise<IStore | null> => {
  try {
    const fileName = `${sanitizeFilename(file.name)}${Date.now()}`;
    const filePath = `stub/${path}`;
    const source = path.replace('/', '');

    const { error } = await supabase.storage.from(source)
      .upload(fileName, file, {
        upsert: false,
        cacheControl: '3600',
        contentType: file.type
      });

    if (error) {
      return null;
    }

    return {
      label: file.name,
      src: `${VITE_APP_STORAGE_URL}/${filePath}${fileName}`
    };
  } catch (error) {
    return null;
  }
};
