import { useGetCountMembers, useMemberFilterOptions } from '@/services/rest/userManagement';
import dayjs from 'dayjs';
import { CalendarChangeEvent } from 'primereact/calendar';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { Nullable } from 'primereact/ts-helpers';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

type Filter = {
  type: Array<string>;
  status: Array<boolean>
  from: Nullable<string | Date | Date[]>
  to: Nullable<string | Date | Date[]>
};

const useTabGroupMember = () => {
  const initialFilter:Filter = {
    type: [],
    from: null,
    to: null,
    status: []
  };

  const params = useParams();
  const navigate = useNavigate();
  const [isLoading] = useState<boolean>(false);
  const [payloadFilter, setPayloadFilter] = useState<Filter>(initialFilter);
  const [selectedFilter, setSelectedFilter] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const { data, refetch: refetchMember } = useGetCountMembers(Number(params.id), payloadFilter);
  const { data: dataFilter } = useMemberFilterOptions(Number(params.id));

  const dataMember = useMemo(() => {
    if (!Array.isArray(data?.data)) return [];
    return data?.data.map((item) => {
      return {
        id: item.id,
        name: `${item.accounts?.first_name || ''} ${item.accounts?.last_name || ''}`,
        type: item.accounts?.type,
        created_at: dayjs(item.accounts?.created_at).format('DD MM YYYY'),
        status: item.accounts?.status
      };
    });
  }, [data]);

  const options = useMemo(() => {
    if (!Array.isArray(dataFilter?.data)) return [];
    const filterType = [...new Set(dataFilter?.data)];
    return [
      {
        label: 'Type',
        items: filterType.filter((type)=>type!==null).map((type) => ({
          label: type,
          value: type,
          name: 'type'
        }))
      },
      {
        label: 'Status',
        items: [
          { label: 'Active', value: true, name: 'status' },
          { label: 'Inactive', value: false, name: 'status' }
        ]
      }
    ];
  }, [dataFilter]);

  const header = [
    { field: 'id', header: 'Member ID' },
    { field: 'name', header: 'Member Name' },
    { field: 'type', header: 'Acc Type' },
    { field: 'created_at', header: 'Created Date' },
    { field: 'status', header: 'Status' }
  ];

  const handleChangeFilter = useCallback(
    (event: MultiSelectChangeEvent) => {
      //set filter value
      setSelectedFilter(event.value);

      //get total options length
      const length = options
        .map((o) => Array.isArray(o.items) && o.items.length)
        .reduce((prev, current) => Number(prev) + Number(current));

      //if select all
      if (event.value.length === length) {
        setPayloadFilter((prev)=>({
          ...prev,
          type: options?.find((o) => o.label === 'Type')?.items.map((item) => String(item.value)) || [],
          status:
            options?.find((o) => o.label === 'Status')?.items.map((item) => Boolean(item.value)) || []
        }));
        return;
      }

      if (!event.selectedOption.name && !event.selectedOption.value) {
        setPayloadFilter(initialFilter);
        return;
      }

      if (payloadFilter[event.selectedOption.name]?.includes(String(event.selectedOption.value))) {
        const temp = payloadFilter[event.selectedOption.name].filter(
          (item: string) => item !== String(event.selectedOption.value)
        );
        setPayloadFilter((prev) => ({
          ...prev,
          [event.selectedOption.name]: temp
        }));
      } else {
        setPayloadFilter((prev) => ({
          ...prev,
          [event.selectedOption.name]: [...prev[event.selectedOption.name], String(event.selectedOption.value)]
        }));
      }

      refetchMember();
    },
    [payloadFilter, options]
  );


  const handleClickDetailMember = useCallback((idMember:number) => {
    navigate(`/user-management/${params.id}/member-id/${idMember}`);
  }, [params]);

  const handleChangeCalendar = useCallback((e: CalendarChangeEvent) => {
    setPayloadFilter((prev) => ({
      ...prev,
      from: e.value,
      to: dayjs(String(e.value)).add(24, 'hours').toDate()
    }));
  }, []);

  const handleChangeInputSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>) => {
    setSearch(currentTarget.value);
  }, []);

  return {
    data: {
      data,
      isLoading,
      search,
      header,
      options,
      dataMember,
      selectedFilter,
      payloadFilter
    },
    mathod: {
      handleClickDetailMember,
      handleChangeCalendar,
      setSearch,
      handleChangeInputSearch,
      handleChangeFilter
    }
  };
};

export default useTabGroupMember;
