import './index.css';
import useCustom from './hooks';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import DataTable from '@/components/base/DataTable/DataTable';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { usePermissionStore } from '@/store/usePermissions';
import { IPagination } from '@/components/base/Pagination';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

const AttributeSet = () => {
  const { data, methods } = useCustom();
  const { canCreate, canView, canDelete, canEdit } = usePermissionStore();

  const templateDescription = (rowData) => {
    return <div className="mx-auto elipsis-20ch">{rowData.description}</div>;
  };

  const headers = [
    { field: 'id', header: 'ID' },
    { field: 'name', header: 'Attribute Set Name' },
    { field: 'description', header: 'Description', body: templateDescription },
    { field: 'status', header: 'Status' }
  ];

  const paginationOptions: IPagination = {
    perPage: data.perPage,
    totalPages: data.totalPages,
    currentPage: data.paginator.currentPage,
    disabledDropdown: data.totalRecords === 0,
    onChangeDropdownPage: methods.handleChangeDropdownPage,
    onClickNext: methods.handleClickNext,
    onClickPrev: methods.handleClickPrev,
    onChangeJumptoPage: methods.handleChangeJumpTopage,
    onJumptoPage: methods.handleJumpToPage
  };

  const filterContent = (
    <div className="flex flex-col gap-4 w-[900px]">
      <div className="w-full flex flex-col items-start gap-2">
        <h1 className="font-bold">ID</h1>
        <div className="flex w-full gap-8">
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Dari</label>
            <InputNumber
              onChange={(e) => methods.handleFilter(e, 'id_from')}
              value={data.itemFilters.id_from}
              name="id_from"
            />
          </div>
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Sampai</label>
            <InputNumber
              onChange={(e) => methods.handleFilter(e, 'id_to')}
              value={data.itemFilters.id_to}
              name="id_to"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Attribute Set Name</label>
          <InputText
            onChange={(e) => methods.handleFilter(e, 'name')}
            value={data.itemFilters.name}
            name="name"
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Description</label>
          <InputText
            onChange={(e) => methods.handleFilter(e, 'description')}
            value={data.itemFilters.description}
            name="description"
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Status</label>
          <Dropdown
            options={[{ label: 'Active', value: 'ACTIVE' }, { label: 'Inactive', value: 'INACTIVE' }]}
            onChange={(e) => methods.handleFilter(e, 'status')}
            value={data.itemFilters.status}
            placeholder="Select Status"
          />
        </div>
      </div>
    </div>
  );

  return (
    <BaseContainer>
      <MainTitle nav="create" title="Attribute Set" isWithButton={canCreate} labelButton="Add Set" />
      <Toast ref={data.toast} />
      <ConfirmDialog />
      <DataTable
        rows={data.totalRecords}
        filter={data.search}
        paginationOptions={paginationOptions}
        headerTable={headers}
        onDeleted={methods.handleDelete}
        onSearch={methods.handleSearch}
        value={data.dataAttributeSet as []}
        isLoadingTable={data.isFetching}
        isEditAction={canEdit}
        isDeleteAction={canDelete}
        isDetailAction={canView}
        filterHistory={data.filterHistory}
        handleClearFilter={methods.handleClearFilter}
        handleDeleteFilterHistory={methods.handleDeleteFilterHistory}
        onSubmitFilter={methods.handleClickSubmitFilter}
        filterContent={filterContent}
      />
    </BaseContainer>
  );
};

export default AttributeSet;
