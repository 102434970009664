import LoadingOverlay from '@/components/base/LoadingOverlay';
import ShopComponents from '../../component/TitleAndTable';
import useDetailCustomer from '../../DetailHooks';

const Order = () => {
  const { data: { listOrders, isLoadingOrders } } = useDetailCustomer();

  const column = [
    { field: 'id', header: 'Order' },
    { field: 'purchased', header: 'Purchased' },
    { field: 'billToName', header: 'Bill-to-name' },
    { field: 'shipToName', header: 'Ship-to-name' },
    { field: 'orderTotal', header: 'Order Total' },
    { field: 'purchasePoint', header: 'Purchase Point' }
  ];

  return (
    <>
      <LoadingOverlay show={isLoadingOrders} />
      <ShopComponents column={column} titleLabel="Order" valueTable={listOrders as []} />
    </>
  );
};

export default Order;
