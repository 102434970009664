import React from 'react';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import useCustom from './hooks';
import { Image } from 'primereact/image';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { useLocation } from 'react-router-dom';
import { DropdownBannerType, FormBanner, FormBannerDetail } from './components';
import { usePermissionStore } from '@/store/usePermissions';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';

const CreateBanner = () => {
  const location = useLocation();
  const { data, method } = useCustom();
  const { canEdit } = usePermissionStore();
  const getFormErrorMessage = (name) => {
    return method.isFormFieldValid(name) && <small className="p-error">{data.formikUpload.errors[name]}</small>;
  };
  const disableSaveSubmit = () => {
    return !(location.pathname.includes('create') || location.pathname.includes('edit'));
  };
  return (
    <BaseContainer>
      <MainTitle title="Banner Management" />
      <LoadingOverlay show={data.isLoading || data.isLoadingBannerDetail} />
      <Toast ref={data.refToast} />
      <div className="px-6 flex w-full mt-6">
        <form id="upload_form" className="bg-white w-full flex border" onSubmit={method.handleSubmitForm}>
          <div id="form_containeer" className="w-full p-6">
            <div className="card flex">
              <div className="flex flex-col flex-grow">
                <DropdownBannerType
                  isDisable={!!data.query.id}
                  handleHoverOptions={method.handleHoverOptions}
                  onChage={method.handleOnChangeBannerTypeDropdown}
                  options={data.optionBannerType}
                  value={data.formValue.type}
                  className="w-1/4 md:w-14rem"
                  previewType={data.formValue.previewType}
                  onClickToggleDropdown={method.handleClickToggleDropdown}
                  show={data.isOpenDropdown}
                />
                {getFormErrorMessage('type')}
              </div>
              <div className="flex gap-2">
                {data.tabMenu === 2 && (
                  <Button
                    label="Back"
                    className="h-fit min-w-[120px]"
                    severity="danger"
                    onClick={() => {
                      method.setTabMenu(1);
                    }}
                    type="button"
                  />
                )}
                <Button
                  label="Save"
                  className="h-fit min-w-[120px]"
                  disabled={data.tabMenu === 3 || disableSaveSubmit()}
                  onClick={() => data.formikUpload.handleSubmit()}
                  type="button"
                />
              </div>
            </div>
            <div className="flex items-start gap-4 py-10">
              <Menu
                id="side-menu-customer-information"
                className="bg-white !border-none"
                model={data.optionTabMenu}
                tabIndex={data.tabMenu}
              />
              <FormBanner
                disabledForm={Boolean(data.query.id)}
                dropDownOptionSize={data.optionSize}
                fileInputRef={data.fileInput}
                formValue={data.formValue}
                getFormErrorMessage={getFormErrorMessage}
                handleAddMultipleImage={method.handleAddMultipleImage}
                handleOnChangeDropdown={method.handleOnChangeDropdown}
                handleOnChangeInput={method.handleOnChangeInput}
                handleUploadImage={method.handleUploadImage}
                isLoadingUpload={data.isLoadingUpload}
                show={Boolean(data.formValue.type.code && data.tabMenu === 0)}
                dropDownOptionStatus={data.optionBannerStatus}
                validFrom={new Date(data.formValue.validFrom)}
                validTo={new Date(data.formValue.validTo)}
                validateDate={data.validateDate}
                handleOnChangeDate={method.handleOnChangeDate}
                handleChangeDropdown={method.handleOnChangeDropdown}
                onSearchTargetSelection={(e: React.ChangeEvent<HTMLInputElement>) => {
                  data.formValue.directTo === 'STORE_DETAIL' && method.findMerchants(e.target.value);
                  data.formValue.directTo === 'PDP' && method.findProducts(e.target.value);
                }}
                optionDirectTo={
                  (data.formValue.directTo === 'PDP' && data.optionListProduct) ||
                  (data.formValue.directTo === 'LANDING_PAGE' && data.optionLandingPage) ||
                  (data.formValue.directTo === 'STORE_DETAIL' && data.optionListMerchants) ||
                  (data.formValue.directTo === 'PROMO_DETAIL_PAGE' && data.optionListPromos) ||
                  []
                }
                directTo={data.formValue.directTo}
                directToList={data.optionDirectTo}
                selectedDirectTo={
                  (data.formValue.directTo === 'PDP' && data.formValue.productId) ||
                  (data.formValue.directTo === 'STORE_DETAIL' && data.formValue.merchantId) ||
                  // (data.formValue.directTo === 'LANDING_PAGE' && data.formValue.landingPage) ||
                  (data.formValue.directTo === 'PROMO_DETAIL_PAGE' && data.formValue.promoDetailId) ||
                  null
                }
              />

              <FormBannerDetail
                count={data.streetCount}
                handleCLickAddStreet={method.handleClickAddStreet}
                handleCLickDetailBanner={method.handleCLickDetailBanner}
                handleCLickSubStreet={method.handleClickSubStreet}
                handleChangeDropdown={method.handleOnChangeDropdown}
                optionStreet={data.optionStreet}
                selectedStreet={data.formValue.street}
                bannerId={data.query.id}
                bannerType={data.formValue.type.code}
                directTo={data.formValue.directTo}
                directToList={data.optionDirectTo}
                handleOnChangeDate={method.handleOnChangeDate}
                onSearchTargetSelection={(e: React.ChangeEvent<HTMLInputElement>) => {
                  data.formValue.directTo === 'STORE_DETAIL' && method.findMerchants(e.target.value);
                  data.formValue.directTo === 'PDP' && method.findProducts(e.target.value);
                }}
                optionDirectTo={
                  (data.formValue.directTo === 'PDP' && data.optionListProduct) ||
                  (data.formValue.directTo === 'LANDING_PAGE' && data.optionLandingPage) ||
                  (data.formValue.directTo === 'STORE_DETAIL' && data.optionListMerchants) ||
                  (data.formValue.directTo === 'PROMO_DETAIL_PAGE' && data.optionListPromos) ||
                  []
                }
                optionsListCategories={data.optionsListCategories}
                selectedCategory={data.formValue.categoryId}
                selectedDirectTo={
                  (data.formValue.directTo === 'PDP' && data.formValue.productId) ||
                  (data.formValue.directTo === 'STORE_DETAIL' && data.formValue.merchantId) ||
                  // (data.formValue.directTo === 'LANDING_PAGE' && data.formValue.landingPage) ||
                  (data.formValue.directTo === 'PROMO_DETAIL_PAGE' && data.formValue.promoDetailId) ||
                  null
                }
                validFrom={new Date(data.formValue.validFrom)}
                validTo={new Date(data.formValue.validTo)}
                validateDate={data.validateDate}
                tab={data.tabMenu}
                formValue={data.formValue}
                handleOnChangeInput={method.handleOnChangeInput}
                optionsListSection={data.optionsListSection}
                selectedSection={data.formValue.sectionType}
                getFormErrorMessage={getFormErrorMessage}
              />

              {data.formValue.type.code !== '' && data.tabMenu === 3 && (
                <div className="w-full">
                  <div className="flex justify-between items-center">
                    <div className="font-bold px-5">Upload &gt; {method.renderImageType(data.imageType)} &gt; {data.imageType === 'centerImage' ? 'Large Banner' : 'Mini Banner'}</div>
                    <Button type="button" disabled={data.disableSaveImage} onClick={method.handleClickSaveMultipleImage} label="Save Images" />
                  </div>
                  <div className="flex flex-col w-full py-10 gap-20">
                    {Array.from({ length: 4 }).map((_, index) => {
                      return (
                        <div key={index} className="rounded-lg w-full flex flex-col gap-2">
                          <div className="flex items-center gap-4">
                            <span className="w-[130px] whitespace-nowrap">Upload Banner - {index+1}</span>
                            <div>
                              <FileUpload
                                auto
                                mode="basic"
                                customUpload
                                uploadHandler={method.handleUploadImage(`${data.imageType}Brand`, index)}
                                disabled={Boolean(data.query.id || data.isLoadingUpload)}
                                key={index}
                                accept="image/*"
                              />
                              {data.currentBrand[index]?.image?.length > 0 && (
                                <Image
                                  src={data.currentBrand[index]?.image}
                                  width="280px"
                                />
                              )}
                            </div>
                          </div>
                          {data.formValue.type.code === 'BRAND_BANNER' && (
                            <>
                              <div id="apply_to_street" className="flex gap-4 items-center">
                                <div id="apply_street_label" className="w-[130px]">
                                Direct to
                                </div>
                                <div className="!w-1/2" id="dropdown_field">
                                  <div className="card flex justify-content-center">
                                    <Dropdown
                                      value={data.currentBrand[index]?.directTo}
                                      optionLabel="label"
                                      className="w-full"
                                      optionValue="code"
                                      placeholder="Select"
                                      options={data.optionDirectTo}
                                      onChange={method.handleDropDownBrandBanner(`${data.imageType}Brand`, index)}
                                      disabled={Boolean(data.query.id)}
                                    />
                                  </div>
                                </div>
                              </div>
                              {data.currentBrand[index]?.directTo !== '' && (
                                <div id="apply_to_street" className="flex gap-4 items-center">
                                  <div id="apply_street_label" className="w-[130px]" />
                                  <div className="!w-1/2" id="dropdown_field">
                                    <div className="card flex justify-content-center flex-col">
                                      {
                                        data.currentBrand[index]?.directTo === 'LANDING_PAGE' ?
                                          <>
                                            <InputText
                                              value={data.currentBrand[index]?.urlFull}
                                              onChange={method.handleOnChangeInput(`${data.imageType}Brand`, true, index)}
                                              placeholder="https://aladinmall.id/......"
                                              className="w-full md:w-14rem"
                                              disabled={Boolean(data.query.id)}
                                              required
                                            />
                                            {getFormErrorMessage('urlFull')}
                                          </> :
                                          <Dropdown
                                            value={(data.currentBrand[index]?.directTo === 'PDP' && data.currentBrand[index]?.productId) ||
                                            (data.currentBrand[index]?.directTo === 'STORE_DETAIL' && data.currentBrand[index]?.merchantId) ||
                                            (data.currentBrand[index]?.directTo === 'PROMO_DETAIL_PAGE' && data.currentBrand[index]?.promosId) ||
                                            (data.currentBrand[index]?.directTo === 'PROMO_TAGGING' && data.currentBrand[index]?.tagId) ||
                                            null}
                                            className="w-full"
                                            placeholder="Select"
                                            filter
                                            filterBy="label"
                                            options={(data.currentBrand[index]?.directTo === 'PDP' && data.optionListProduct) ||
                                            (data.currentBrand[index]?.directTo === 'LANDING_PAGE' && data.optionLandingPage) ||
                                            (data.currentBrand[index]?.directTo === 'STORE_DETAIL' && data.optionListMerchants) ||
                                            (data.currentBrand[index]?.directTo === 'PROMO_DETAIL_PAGE' && data.optionListPromos) ||
                                            (data.currentBrand[index]?.directTo === 'PROMO_TAGGING' && data.optionsTagging) ||
                                            []}
                                            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                              data.currentBrand[index]?.directTo === 'STORE_DETAIL' && method.findMerchants(e.target.value);
                                              data.currentBrand[index]?.directTo === 'PDP' && method.findProducts(e.target.value);
                                              data.currentBrand[index]?.directTo === 'PROMO_TAGGING' && method.findTagging(e.target.value);
                                            }}
                                            onChange={method.handleDropDownBrandBanner(
                                              (data.currentBrand[index]?.directTo === 'PDP' && 'productId') ||
                                                (data.currentBrand[index]?.directTo === 'STORE_DETAIL' && 'merchantId') ||
                                                (data.currentBrand[index]?.directTo === 'PROMO_DETAIL_PAGE' && 'promosId') ||
                                                (data.currentBrand[index]?.directTo === 'PROMO_TAGGING' && 'tagId') ||
                                                '', index
                                            )}
                                            disabled={Boolean(data.query.id)}
                                          />
                                      }
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div id="apply_to_street" className="flex gap-4 items-center">
                                <div id="apply_street_label" className="w-[130px]">
                                  Valid from
                                </div>
                                <div className="!w-1/2" id="dropdown_field">
                                  <div className="card flex justify-content-center">
                                    <Calendar
                                      value={new Date(data.currentBrand[index]?.validFrom)}
                                      onChange={method.handleOnChangeDate('validFrom', true, index)}
                                      showIcon
                                      hideOnDateTimeSelect
                                      maxDate={new Date(data.currentBrand[index]?.validTo)}
                                      minDate={new Date()}
                                      className="w-full"
                                      required
                                      inputStyle={{
                                        border: data.validateDate ? '1px solid red' : ''
                                      }}
                                      disabled={Boolean(data.query.id)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div id="apply_to_street" className="flex gap-4 items-center">
                                <div id="apply_street_label" className="w-[130px]">
                                  Valid to
                                </div>
                                <div className="!w-1/2" id="dropdown_field">
                                  <div className="card flex justify-content-center">
                                    <Calendar
                                      value={new Date(data.currentBrand[index]?.validTo)}
                                      onChange={method.handleOnChangeDate('validTo', true, index)}
                                      showIcon
                                      hideOnDateTimeSelect
                                      minDate={new Date(data.currentBrand[index]?.validFrom)}
                                      className="w-full"
                                      required
                                      inputStyle={{
                                        border: data.validateDate ? '1px solid red' : ''
                                      }}
                                      disabled={Boolean(data.query.id) || !Boolean(new Date(data.currentBrand[index]?.validFrom).getDate())}
                                    />
                                  </div>
                                  {data.validateDate && <span className="text-red-500 text-xs">End time must greater than start time</span>}
                                </div>
                              </div>
                              <div id="status" className="flex gap-4 items-center">
                                <div id="status_label" className="w-[130px]">Status</div>
                                <div className="!w-1/2" id="dropdown_field">
                                  <div className="card flex justify-content-center">
                                    <Dropdown
                                      value={data.currentBrand[index]?.status}
                                      className="w-full"
                                      placeholder="Select"
                                      options={data.optionBannerStatus}
                                      onChange={method.handleDropDownBrandBanner('status', index)}
                                      disabled={Boolean(data.query.id || data.isLoadingUpload)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <SubmitButton
              onCancel={method.handleClickCancel}
              onSubmit={() => {}}
              labelBack="Cancel"
              labelSubmit="Submit"
              disabledSubmit={disableSaveSubmit() || data.disableButtonSubmit || !canEdit}
            />
          </div>
        </form>
      </div>
    </BaseContainer>
  );
};

export default CreateBanner;
