export const TABLE = {
  FLASHSALE: 'catalogue.flash_sales',
  CATEGORIES: 'catalogue.categories',
  PRODUCT: 'catalogue.products',
  PRODUCT_VARIANTS: 'catalogue.product_variants',
  ATTRIBUTE_SET_SPECIFICATION: 'catalogue.attribute_set_specifications',
  ATTRIBUTE_SET_VARIANT: 'catalogue.attribute_set_variants',
  MERCHANTS: 'catalogue.merchants',
  VARIANTS: 'catalogue.variants',
  WHOLESALER: 'product_wholesaler_price',
  ATTRIBUTE_SETS: 'catalogue.attribute_sets',
  SPESIFICATION: 'catalogue.specifications',
  MASTER_SLOTS: 'catalogue.master_slots',
  SLOT: 'catalogue.master_slots',
  PROMO_TAGGING: 'catalogue.promo_tags',
  PRODUCT_VARIANT_PROMO_TAGS: 'catalogue.product_variant_promo_tags',
  TEAM: 'user.teams',
  TEAM_MENUS: 'user.team_menus',
  MENUS: 'user.menus',
  ACCOUNTS: 'user.accounts',
  SHIPPING_PROVIDER: 'trade.shipping_providers',
  SHIPPING_METHODS: 'trade.shipping_methods',
  PROMOS: 'trade.promos',
  PROMO_RULES: 'trade.promo_rules',
  PRODUCT_REVISIONS: 'catalogue.product_revisions',
  BANNER: 'catalogue.banners',
  STREET: 'catalogue.streets',
  SHIPPING_APPLIED: 'trade.promo_applied_shipping_providers',
  MEMBER: 'user.members',
  HITSORY: 'catalogue.import_histories',
  TABULATION: 'catalogue.product_tabulations',
  PRODUCT_TABULATION: 'catalogue.product_tabulation_variants',
  PRODUCT_SUBSCRIPTION: 'catalogue.product_subscriptions',
  CATEGORY_SPECIFICATION: 'catalogue.category_specifications',
  CATEGORY_VARIANTS: 'catalogue.category_variants',
  CATEGORY_PROMO: 'trade.category_promos',
  ORDERS: 'trade.orders',
  ORDER_LIST: 'trade.order_list',
  ORDER_TRANSACTIONS: 'trade.order_transactions',
  PROMO_APPLIED_PROMO_TAGS: 'trade.promo_applied_product_variant_promo_tags',
  PROMO_APPLIED_PRODUCT: 'trade.promo_applied_product_variants',
  PROMO_APPLIED_MERCHANTS: 'trade.promo_applied_merchant_variants',
  PROMO_AMOUNT_COUNTS: 'trade.promo_amount_counts',
  PROMO_EXCLUDE_PRODUCT: 'trade.promo_excluded_product_variants',
  PROMO_EXCLUDE_MERCHANT: 'trade.promo_excluded_merchant_variants',
  PROMO_EXCLUDE_PROMO_TAGS: 'trade.promo_excluded_product_variant_promo_tags',
  OFFICIAL_MERCHANT: 'catalogue.official_merchants',
  POPULAR_BRAND: 'catalogue.popular_brands',
  BANNER_APPLIED_STREET: 'catalogue.banner_applied_streets',
  CARTS: 'trade.carts',
  PRODUCT_FAVORITES: 'catalogue.product_favorites',
  SHIPPING_ADDRESSES: 'user.shipping_addresses',
  CITIES: 'user.cities',
  PROVINCE: 'user.provinces',
  LAYOUTS: 'user.layouts',
  ACCOUNT_HISTORY_LOGS: 'catalogue.account_history_logs',
  PRODUCT_VARIANT_VOUCHERS: 'catalogue.product_variant_vouchers',
  SPECIFICATION_PRESET: 'catalogue.specification_presets',
  ACCOUNT_TRANSACTIONS: 'trade.account_transactions',
  APPLICATION: 'user.applications',
  VARIANT_PRESETS: 'catalogue.variant_presets',
  PRODUCT_PENDING_UPDATE: 'catalogue.product_pending_updates',
  BRAND_BANNER: 'catalogue.brand_banners'
};

export const MESSAGE = {
  REQUIRED: 'Field is Required',
  EMAIL: 'Invalid email input. Please enter a valid email address',
  PASSWORD_NOT_MATCH: 'Make sure the password you enter matches the new password you entered.',
  END_DATE: 'End date cannot be earlier than start date',
  MIN_SELECTED: 'Please select at least 1 item',
  BIN_CODE_REQUIRED: 'At least one bin code is required for credit card payment.',
  IMAGE_BIGGER_SIZE: 'File must be under 150 kb',
  NUMBER_ONLY: 'Only numbers are allowed',
  IMAGE_BIGGER_SIZE_50_KB: 'File must be under 50 kb'
};

export const PRODUCT_HIGHTLIGHT_SORT_TYPE = {
  'SEQUENCE': 'sequence',
  'Sequence': 'sequence',
  'sequence': 'sequence',
  'random': 'random',
  'Random': 'random',
  'RANDOM': 'random',
  'lowest price': 'lowest_price',
  'Lowest Price': 'lowest_price',
  'lowest_price': 'lowest_price',
  'Lowest_Price': 'lowest_price',
  'lowestprice': 'lowest_price',
  'LOWESTPRICE': 'lowest_price',
  'LowestPrice': 'lowest_price'
};

export const FLASH_SALE_PRICE_BY = {
  'AMOUNT': 'Amount',
  'Amount': 'Amount',
  'amount': 'Amount',
  'PERCENTAGE': 'Percentage',
  'Percentage': 'Percentage',
  'percentage': 'Percentage'
};

export const FLASH_SALE_SORT_BY = {
  'SEQUENCE': 'sequence',
  'Sequence': 'sequence',
  'sequence': 'sequence',
  'random': 'random',
  'Random': 'random',
  'RANDOM': 'random',
  'lowest price': 'lowest_price',
  'Lowest Price': 'lowest_price',
  'lowest_price': 'lowest_price',
  'Lowest_Price': 'lowest_price',
  'lowestprice': 'lowest_price',
  'LOWESTPRICE': 'lowest_price',
  'LowestPrice': 'lowest_price'
};

export const FLASH_SALE_SORT_VALUE = {
  'random': 'random',
  'Random': 'random',
  'RANDOM': 'random',
  'lowest price': 'lowest_price',
  'Lowest Price': 'lowest_price',
  'lowest_price': 'lowest_price',
  'Lowest_Price': 'lowest_price',
  'lowestprice': 'lowest_price',
  'LOWESTPRICE': 'lowest_price',
  'LowestPrice': 'lowest_price'
};

export const STATUS_TRANSACTION = {
  WAITING_PAYMENT: 'Menunggu Pembayaran',
  PAYMENT_EXPIRED: 'Pembayaran Expired',
  PAYMENT_PAID: 'Pembayaran Terverifikasi',
  WAITING_APPROVAL: 'Menunggu Konfirmasi Seller',
  IN_PROGRESS: 'Dalam Proses',
  READY: 'Menunggu Kurir',
  ON_THE_WAY: 'Dalam Perngiriman',
  DELIVERED: 'Tiba Ditujuan',
  RETURN: 'Return',
  COMPLETED: 'Pesanan Selesai',
  CANCEL: 'Pesanan Dibatalkan',
  CANCEL_BY_BUYER: 'Dibatalkan Pembeli',
  CANCEL_BY_SELLER: 'Dibatalkan Penjual',
  CANCEL_BY_SYSTEM: 'Dibatalkan Sistem'
};

export const STATUS = {
  APPROVED: 'APPROVED',
  IN_REVIEW: 'IN_REVIEW',
  NEED_REVISION: 'NEED_REVISION',
  IN_REVIEW_UPDATE: 'IN_REVIEW_UPDATE',
  NEED_REVISION_UPDATE: 'NEED_REVISION_UPDATE'
};

export const URL_CHECK_NOW = import.meta.env.VITE_APP_BASE_FRONTSTORE_URL ? `${import.meta.env.VITE_APP_BASE_FRONTSTORE_URL}/` : 'https://ui-frontstore-stg.aladinmall.id/';

const GRAPHQL_BASE_URL = import.meta.env.VITE_APP_GRAPHQL_BASE_URL;

export const GRAPHQL_COMMON_URL = new URL('common', GRAPHQL_BASE_URL).toString();
