import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { IPagination } from '@/types/pagination';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

export interface IHistory {
  id?: number;
  account_id: string;
  file_path: string;
  created_at?: string;
  type: string;
  errors?: Array<string>;
  row_number: number;
  status: boolean;
  accounts: IUserEmail[]
}

export interface ITeam {
  id: number,
  name: string,
  code: string
  status: boolean
}

export interface IMembers {
  teams: ITeam
}
export interface IUserRole {
  id: string
  email: string
  members: IMembers
}

export interface IFilterHistory {
  id_to: number | null;
  id_from: number | null;
  username: string;
  filename: string;
  status: boolean | null;
  type: string;
  upload_date: Date[];
}

export interface IUserEmail {
  id: string,
  email: string
}

type UserRoles = {
  id: string;
  email: string;
  members: ITeam
}

const getHistory = async (range: IPagination, type?: string, id?: string, filter?: IFilterHistory, search?: string) => {
  const query = supabase
    .from(TABLE.HITSORY)
    .select('*', { count: 'exact' })
    .order('id', { ascending: false })
    .is('merchant_id', null)
    .range(range.start, range.end);

  const filterID = /^[0-9]+$/.test(search as string);

  if (type && type !== 'super_admin') {
    query.eq('account_id', id);
  }

  if (filter) {
    // ID
    if (filter.id_from != null) {
      query.gte('id', filter.id_from);
    }

    if (filter.id_to != null) {
      query.lte('id', filter.id_to);
    }

    // Status
    if (filter.status != null) {
      query.eq('status', filter.status);
    }

    // Filename
    if (!!filter?.filename) {
      query.ilike('file_path', `%${filter.filename}%`);
    }

    // Type
    if (filter.type != '') {
      query.eq('type', filter.type);
    }

    // Upload Date
    if (filter.upload_date?.length != 0) {
      const fromDate = dayjs(String(filter.upload_date[0])).format('YYYY-MM-DD');
      query.gte('created_at:date', fromDate);

      if (filter.upload_date.length > 1 && filter.upload_date[1] != null) {
        const toDate = dayjs(String(filter.upload_date[1])).format('YYYY-MM-DD');
        query.lte('created_at:date', toDate);
      }
    }
  }

  // ID only search
  if (filterID) {
    query.eq('id', search);
  }

  if (!!search && !filterID && search != '') {
    const { data } = await supabase.from(TABLE.ACCOUNTS).select('id, email').ilike('email', `%${search}%`).limit(50);
    if (data) {
      const accId = data.map((item)=> item.id);
      const accQuery = supabase
        .from(TABLE.HITSORY)
        .select('*', { count: 'exact' })
        .order('id', { ascending: false })
        .is('merchant_id', null)
        .range(range.start, range.end); ;
      const result = await accQuery.in('account_id', accId) as PostgrestSingleResponse<IHistory[]>;
      if (result.data && result.data.length > 0) {
        result.data = await getAccount(result.data);
        return result;
      }
      query.or(`file_path.ilike.%${search}%, type.ilike.%${search}%`);
    } else {
      query.or(`file_path.ilike.%${search}%, type.ilike.%${search}%`);
    }
  }

  if (!!filter?.username) {
    const { data } = await supabase.from(TABLE.ACCOUNTS).select('id, email').ilike('email', `%${filter.username}%`).limit(50);
    if (data) {
      const accId = data.map((item)=> item.id);
      query.in('account_id', accId);
    }
  }

  const result = await query as PostgrestSingleResponse<IHistory[]>;
  if (result.data && result.data.length > 0) {
    result.data = await getAccount(result.data);
  };
  return result;
};

const getAccount = async (history: IHistory[]) => {
  const accIds = [...new Set(history.map((item)=> item.account_id).filter((item)=> item != null).flat())];
  const query = supabase.from(TABLE.ACCOUNTS).select('email, id').in('id', accIds);
  const { data: users } = await query as PostgrestSingleResponse<IUserEmail[]>;
  if (users && users.length > 0) {
    history = history.map((item)=> {
      return {
        ...item,
        accounts: users
      };
    });
  }
  return history;
};

const getUserRole = async (id: string) => {
  return await supabase
    .from(TABLE.ACCOUNTS)
    .select('id,email,members(teams(id,name,code,status))')
    .eq('id', id)
    .single() as PostgrestSingleResponse<UserRoles>;
};

export const insertImportHistory =async (payload:IHistory[]) => {
  return await supabase.from(TABLE.HITSORY).insert(payload);
};

export const useGetHistoryAccount = (range: IPagination, type?: string, id?: string, filter?: IFilterHistory, search?: string) =>
  useQuery({
    queryKey: ['history', range, type, id, filter, search],
    queryFn: () => getHistory(range, type, id, filter, search),
    refetchOnWindowFocus: false,
    enabled: !!type || !!id || !!filter || !!search
  });

export const useGetUserRole = (id: string) =>
  useQuery({
    queryKey: ['role'],
    queryFn: () => getUserRole(id)
  });
