import { MenuItem } from 'primereact/menuitem';
import useShop from './ShopHooks';
import { Menu } from 'primereact/menu';
import './style.css';
import Order from './Order/Order';
import ShoppingCart from './Shopping Cart/ShoppingCart';
import WishList from './WishList/WishList';
import StoreCredit from './StoreCredit/StoreCredit';

const Shop = () => {
  const {
    data: {
      sideMenuActiveIndex
    },
    method: {
      setSideMenuActiveIndex
    }
  } = useShop();
  const sideMenu: MenuItem[] = [
    { label: 'Order', command: () => {
      setSideMenuActiveIndex(0);
    }, className: sideMenuActiveIndex === 0 ? 'bg-white font-bold text-black' : '' },
    // TODO: hide for now
    // { label: 'Returns', command: () => {
    //   setSideMenuActiveIndex(1);
    // }, className: sideMenuActiveIndex === 1 ? 'bg-white font-bold text-black' : '' },
    { label: 'Shopping Cart', command: () => {
      setSideMenuActiveIndex(2);
    }, className: sideMenuActiveIndex === 2 ? 'bg-white font-bold text-black' : '' },
    { label: 'Wish List', command: () => {
      setSideMenuActiveIndex(3);
    }, className: sideMenuActiveIndex === 3 ? 'bg-white font-bold text-black' : '' },
    { label: 'Store Credit', command: () => {
      setSideMenuActiveIndex(4);
    }, className: sideMenuActiveIndex === 4 ? 'bg-white font-bold text-black' : '' }
  ];
  return (
    <>
      <div className="flex w-full">
        <div className="flex items-start">
          <Menu id="customer-shop-menu" className="custom-menu-shop" model={sideMenu} tabIndex={sideMenuActiveIndex}/>
        </div>
        <div className="w-full bg-white border px-6 pb-5">
          {sideMenuActiveIndex === 0 && <Order/>}
          {/* TODO: Hide for now */}
          {/* {sideMenuActiveIndex === 1 && <Returns/>} */}
          {sideMenuActiveIndex === 2 && <ShoppingCart/>}
          {sideMenuActiveIndex === 3 && <WishList/>}
          {sideMenuActiveIndex === 4 && <StoreCredit/>}
        </div>
      </div>
    </>
  );
};

export default Shop;
