import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import {
  useGetCitiesById,
  useGetCustomerById,
  useGetDataCarts,
  useGetOrders,
  useGetProductsById,
  useGetProductVariantById,
  useGetProvinceById,
  useGetShippingAddress
} from '@/services/rest/customerManagement';
import dayjs from 'dayjs';
import { formatRupiah } from '@/utils/formatter';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { uniqBy } from 'lodash';


export interface Customer {
  id: string
  type: string
  first_name: string
  last_name: string
  birth_date: string
  gender: string
  phone_number: string
  photo: string
  safe_mode: boolean
  email: string
  created_at: string
  status: boolean
  username: string
  shipping_addresses: ShippingAddress[]
  members: string
}

export interface ShippingAddress {
  address: string
  postal_code: number
  cities: Cities
}

export interface Cities {
  name: string
  provinces: Provinces
}

export interface Provinces {
  name: string
}

interface IFilterValue {
  label: string;
  value: string;
}

interface IFilter {
  first_name: IFilterValue
  last_name: IFilterValue
  city: IFilterValue
  state: IFilterValue
  postal_code: IFilterValue
}


const useDetailCustomer = () => {
  const [tabMenuActiveIndex, setTabMenuActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const [filter, setFilter] = useState<IFilter[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<IFilter | null>(null);
  const [dataDetailCustomer, setDataDetailCustomer] = useState<Customer[]>([]);
  const [fieldAccountInfo, setFieldAccountInfo] = useState(
    {
      first_name: '',
      last_name: '',
      email: '',
      birth_date: '',
      gender: '',
      phone_number: '',
      created_at: '',
      address: '',
      status: true,
      members: '',
      last_sign_in: ''
    }
  );

  const onChangeFieldAccountInfo = (key: string, value: string | boolean) => {
    setFieldAccountInfo((prevState)=> {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  const getDataCustomerById = useCallback( async () => {
    setIsLoading(true);
    const { data: customer } = await supabase.from(TABLE.ACCOUNTS).select('*, shipping_addresses(address, postal_code, cities(name, provinces(name))), members(teams(name))').eq('id', params.id);
    if (customer != null) {
      onChangeFieldAccountInfo('first_name', customer[0].first_name);
      onChangeFieldAccountInfo('last_name', customer[0].last_name);
      onChangeFieldAccountInfo('email', customer[0].email);
      onChangeFieldAccountInfo('gender', customer[0].gender);
      onChangeFieldAccountInfo('phone_number', customer[0].phone_number);
      onChangeFieldAccountInfo('created_at', dayjs(customer[0].created_at).format('MMM DD, YYYY, HH:MM:ss'));
      onChangeFieldAccountInfo('birth_date', customer[0].birth_date && dayjs(customer[0].birth_date).format('DD MMM YYYY') as string || '');
      onChangeFieldAccountInfo('status', customer[0].status);
      if (customer[0].shipping_addresses.length > 0) {
        onChangeFieldAccountInfo('address', customer[0].shipping_addresses[0].address);
      } else {
        onChangeFieldAccountInfo('address', '');
      }
      if (customer[0].members.length > 1 ) {
        const memberMapped = customer[0].members?.map((i) => i?.teams?.name?.trim())?.join?.(', ');
        onChangeFieldAccountInfo('members', memberMapped);
      } else if (customer[0].members.length === 1) {
        onChangeFieldAccountInfo('members', customer[0].members[0].teams.name);
      }
      setIsLoading(false);

      const addressMapper = customer.map((item) => {
        const [, street] = item.shipping_addresses[0].address.split(',');

        return {
          first_name: item.first_name,
          last_name: item.last_name,
          street_address: item.shipping_addresses[0].address && street || '',
          city: item.shipping_addresses[0].cities.name,
          country: 'Indonesia',
          postal_code: item.shipping_addresses[0].postal_code,
          state: item.shipping_addresses[0].cities.provinces.name,
          phone: item.phone_number
        };
      });
      if (filter.length === 0) {
        const filteringOption = customer.map((item) => ({
          first_name: {
            label: item.first_name,
            value: item.first_name
          },
          last_name: {
            label: item.last_name,
            value: item.last_name
          },
          city: {
            label: item.shipping_addresses[0].cities.name,
            value: item.shipping_addresses[0].cities.name
          },
          state: {
            label: item.shipping_addresses[0].cities.provinces.name,
            value: item.shipping_addresses[0].cities.provinces.name
          },
          postal_code: {
            label: item.shipping_addresses[0].postal_code,
            value: item.shipping_addresses[0].postal_code
          }
        }));
        setFilter(filteringOption as []);
      }
      setDataDetailCustomer(addressMapper as []);
    }
    setIsLoading(false);
  }, []);

  const getLastSignIn = async () => {
    const { data } = await supabase.auth.admin.getUserById(params.id as string);
    onChangeFieldAccountInfo('last_sign_in', dayjs(data.user?.last_sign_in_at).format('MMM DD, YYYY, HH:MM:ss') as string);
  };


  useEffect(() => {
    if (params.id !== null) {
      getDataCustomerById();
      getLastSignIn();
    }
  }, []);


  const { data: dataCustomer } = useGetCustomerById(params.id as string);

  const customerName = useMemo(() => {
    return `${dataCustomer?.data?.first_name || ''} ${dataCustomer?.data?.last_name || ''}`;
  }, [dataCustomer]);

  // order
  const { data: dataOrders, isLoading: isLoadingOrders } = useGetOrders(params.id as string);

  const listOrders = useMemo(() => {
    return Array.isArray(dataOrders?.data) ?
      dataOrders?.data.map((it) => {
        const receivedName = JSON.parse(JSON.stringify(it.delivery_metadata)) || '';
        const totalOrder = Number(it.shipping_fee) + Number(it.total_amount);
        return {
          id: it.id || '-',
          purchased: dayjs(it.created_at).format('MMMM DD YYYY h:mm:ss A') || '-',
          billToName: receivedName ? `${receivedName?.pickup_request?.recipient?.firstName || ''} ${receivedName?.pickup_request?.recipient?.lastName || ''}` : customerName,
          shipToName: receivedName ? `${receivedName?.pickup_request?.recipient?.firstName || ''} ${receivedName?.pickup_request?.recipient?.lastName || ''}` : customerName,
          orderTotal: formatRupiah(totalOrder),
          // TODO: handle not implement
          purchasePoint: it.platform || '-'
        };
      }) :
      [];
  }, [dataOrders, customerName]);

  // shopping cart
  const { data: dataCart, isLoading: isLoadingCarts } = useGetDataCarts(params.id as string);

  const idsProductVariant = useMemo(() => {
    return Array.isArray(dataCart?.data) ?
      dataCart?.data.map((it) => it.product_variant_id) :
      [];
  }, []);

  const { data: dataProductVariants, isInitialLoading: isInitialLoadingProductVariant } = useGetProductVariantById(idsProductVariant as string[]);

  const idsProduct = useMemo(() => {
    return Array.isArray(dataProductVariants?.data) ?
      dataProductVariants?.data.map((it) => it.product_id) :
      [];
  }, []);

  const { data: dataProducts, isInitialLoading: isInitialLoadingProduct } = useGetProductsById(idsProduct as number[]);

  const listCarts = useMemo(() => {
    return (Array.isArray(dataCart?.data) && Array.isArray(dataProductVariants?.data) && Array.isArray(dataProducts?.data)) ?
      dataCart?.data.map((it) => {
        const productVariant = dataProductVariants?.data?.find((i) => i.id == it.product_variant_id);
        const product = dataProducts?.data?.find((i) => i.id == productVariant?.product_id);
        return {
          id: it.id || '-',
          product: product?.name || '-',
          sku: product?.sku || '-',
          quantity: it.quantity || '-',
          price: formatRupiah(product?.main_price as number) || 0,
          total: formatRupiah(it.amount as number) || 0
        };
      }) :
      [];
  }, [dataCart?.data, dataProductVariants?.data, dataProducts?.data]);


  // get shipping address
  const { data: dataShippingAddress } = useGetShippingAddress(params.id as string);

  // city id
  const cityIds = useMemo(() => {
    return Array.isArray(dataShippingAddress?.data) ?
      dataShippingAddress?.data.map((it) => it.city_id) :
      [];
  }, [dataShippingAddress?.data]);

  // province id
  const provinceIds = useMemo(() => {
    return Array.isArray(dataShippingAddress?.data) ?
      dataShippingAddress?.data.map((it) => it.province_id) :
      [];
  }, [dataShippingAddress?.data]);

  const { data: dataProvinces } = useGetProvinceById(provinceIds as number[]);

  const { data: dataCities } = useGetCitiesById(cityIds as number[]);

  const listAddress = useMemo(() => {
    return (Array.isArray(dataShippingAddress?.data) && Array.isArray(dataProvinces?.data) && Array.isArray(dataCities?.data)) ?
      dataShippingAddress?.data.map((it) => {
        const city = dataCities?.data?.find((i) => i.id == it.city_id);
        const province = dataProvinces?.data?.find((i) => i.id == it.province_id);
        return {
          firstName: dataCustomer?.data?.first_name || '',
          lastName: dataCustomer?.data?.last_name || '',
          streetAddress: it?.address?.split(',')[0] || '-',
          city: city?.name || '',
          country: 'Indonesia',
          state: province?.name || '',
          postalCode: it.postal_code || '',
          phoneNumber: it.phone_number || ''
        };
      }) :
      [];
  }, [dataShippingAddress?.data, dataProvinces?.data, dataCities?.data]);

  const optionsFilter = useMemo(() => {
    const uniqStreet = uniqBy(listAddress, 'streetAddress');
    const uniqCity = uniqBy(listAddress, 'city');
    const uniqState = uniqBy(listAddress, 'state');
    const uniqZipCode = uniqBy(listAddress, 'postalCode');
    const uniqPhone = uniqBy(listAddress, 'phoneNumber');

    return [
      {
        label: 'First Name',
        items: filter.map((i) => ({
          label: i.first_name.label,
          value: i.first_name.value,
          name: 'firstName'
        })) || []
      },
      {
        label: 'Last Name',
        items: filter.map((i) => ({
          label: i.last_name.label,
          value: i.last_name.value,
          name: 'lastName'
        })) || []
      },
      {
        label: 'Street Address',
        items: uniqStreet.map((i) => ({
          label: i.streetAddress,
          value: i.streetAddress,
          name: 'streetAddress'
        })) || []
      },
      {
        label: 'City',
        items: uniqCity.map((i) => ({
          label: i.city,
          value: i.city,
          name: 'city'
        })) || []
      },
      {
        label: 'Country',
        items: [
          {
            label: 'Indonesia',
            value: 'Indonesia',
            name: 'country'
          }
        ]
      },
      {
        label: 'State',
        items: uniqState.map((i) => ({
          label: i.state,
          value: i.state,
          name: 'state'
        })) || []
      },
      {
        label: 'Zip/Post Code',
        items: uniqZipCode.map((i) => ({
          label: i.postalCode,
          value: i.postalCode,
          name: 'postalCode'
        })) || []
      },
      {
        label: 'Phone Number',
        items: uniqPhone.map((i) => ({
          label: i.phoneNumber,
          value: i.phoneNumber,
          name: 'phoneNumber'
        })) || []
      }
    ];
  }, [filter, listAddress]);

  return {
    data: {
      listCarts,
      isLoading,
      listOrders,
      listAddress,
      optionsFilter,
      selectedFilter,
      isLoadingCarts,
      isLoadingOrders,
      fieldAccountInfo,
      tabMenuActiveIndex,
      dataDetailCustomer,
      isInitialLoadingProduct,
      isInitialLoadingProductVariant
    },
    method: {
      setTabMenuActiveIndex,
      setSelectedFilter
    }
  };
};

export default useDetailCustomer;
