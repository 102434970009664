import { useAuthStore } from '@/store/useAuthStore';
import React from 'react';

const Home = () => {
  const user = useAuthStore(
    (state) => state.user
  );

  return <div>Welcome, <b>{user?.email}</b></div>;
};

export default Home;
