import { useGetMenus, useGetMenusByApplicationId, useGetTeamById } from '@/services/rest/userManagement';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IEditTeam, IMenusPermissions } from '../../helper';
import dayjs from 'dayjs';
import { union } from 'lodash';
import { supabase } from '@/lib/supabase';
import { TABLE } from '@/constants';
import { Toast } from 'primereact/toast';


interface initialFormEdit {
  teamMenusId: number;
  menusId: number;
  teamId: number;
  create: boolean;
  delete: boolean;
  update: boolean;
  read: boolean;
}

const useTabAccessSetting = () => {
  const { id } = useParams();
  const [isLoading, setLoading] = useState<boolean>();
  const [displaySettingPermission, setDisplaySettingPermission] = useState<boolean>(false);
  const { data: dataTeam } = useGetTeamById(Number(id));
  const { data: dataMenus } = useGetMenus(Number(id));
  const { data: dataMenusDefault } = useGetMenusByApplicationId(1);
  const [formEdit, setFormEdit] = useState<initialFormEdit[]>([]);
  const [menusData, setMenusData] = useState<IMenusPermissions[]>([]);
  const toast = useRef<Toast>(null);

  const team: IEditTeam = useMemo(() => {
    const data = dataTeam?.data;
    const status = data?.status ? 'Aktif' : 'false';
    return {
      code: data?.code || '',
      created_at: dayjs(data?.created_at).format('DD MMM YYYY'),
      description: data?.description || '',
      id: data?.id || null,
      name: data?.name || '',
      status: status
    };
  }, [dataTeam]);

  const featureSelected = useCallback((e) => {
    const selectedFeature = [...formEdit];
    if (e.checked) {
      selectedFeature.push(e.value);
    } else {
      selectedFeature.splice(selectedFeature.indexOf(e.value), 1);
    }

    setFormEdit(selectedFeature);
  }, [formEdit]);

  const menusParentDefault: IMenusPermissions[] = useMemo(()=> {
    if (dataMenusDefault?.data) {
      const data = dataMenusDefault?.data;
      const noParent = data.filter((item) => item.parent_id === null).map((item) => {
        const newItem = {
          id: item.id,
          parent_id: null,
          menus: item.name,
          create: false,
          read: false,
          update: false,
          delete: false
        };

        return newItem;
      });
      //mapping children

      const resultMenu = noParent.map((menu) => {
        const newMenu = {
          parent: menu,
          child: []
        };

        const arrayChildren = data.filter((item) => menu.id === item?.parent_id).map((item) => {
          const newItem = {
            id: item.id,
            menus: item.name,
            parent_id: item.parent_id,
            create: false,
            read: false,
            update: false,
            delete: false
          };


          return newItem;
        });

        if (arrayChildren.length > 0) {
          newMenu.child = arrayChildren as [];
          return newMenu;
        }
        return newMenu;
      });
      return resultMenu || [];
    }
    return [];
  }, [dataMenusDefault]);

  const menusParent:IMenusPermissions[] = useMemo(()=> {
    const data = dataMenus?.data?.map((item) => {
      return {
        teamMenusId: item.id,
        create: item.create,
        delete: item.delete,
        update: item.update,
        read: item.read,
        menus: item.menus.name || '',
        id: item.menus.id,
        parent_id: item.menus.parent_id
      };
    });
    const newData = data?.filter((f) => f.parent_id === null).map((item) => {
      return {
        teamMenuId: item.teamMenusId,
        parent: item,
        child: data?.filter((a) => a.parent_id === item.id)
      };
    });
    return newData || [];
  }, [dataMenus, menusParentDefault]);

  const defaultRenderMenus = useMemo(() => {
    if (menusParent.length > 0) {
      return menusParent;
    }
    return menusParentDefault;
  }, [menusParent, menusParentDefault]);

  const handleClickCustomAccess = useCallback(() => {
    setDisplaySettingPermission(true);
  }, []);

  const handleClickClosePopUp = useCallback(() => {
    setDisplaySettingPermission(false);
  }, []);

  const showError = () => {
    toast.current?.show({
      severity: 'error',
      summary: 'Error',
      detail: 'Failed Update Permission',
      life: 2000
    });
  };

  const showSuccess = () => {
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Success Update Permission',
      life: 2000
    });
  };

  const handleClickSubmitPopUp = useCallback(async () => {
    setLoading(true);

    const parentMenu = menusData.map((it) => it.parent);
    const childMenu = menusData.map((it) => it.child);

    const combineChild = childMenu.reduce((arr, e) => arr.concat(e));
    const menuArr = union(parentMenu, combineChild);

    const payload = menuArr.map((item) => {
      if (item.teamMenusId) {
        return {
          id: item.teamMenusId,
          team_id: Number(id),
          create: item.create,
          read: item.read,
          update: item.update,
          delete: item.delete,
          menu_id: item.id
        };
      }
      return {
        team_id: Number(id),
        create: item.create,
        read: item.read,
        update: item.update,
        delete: item.delete,
        menu_id: item.id
      };
    });

    const { error } = await supabase.from(TABLE.TEAM_MENUS)
      .upsert(payload, { onConflict: 'id' })
      .select();

    if (error) {
      showError();
      return;
    }
    setLoading(false);
    showSuccess();
    setDisplaySettingPermission(false);
  }, [menusData]);

  const handleChangeCheckboxParent = useCallback((checked: boolean, index: number, access: string) => {
    const newArr = [...menusData];

    newArr[index].parent[access] = checked;
    setMenusData(newArr);
  }, [menusData]);

  const handleChangeCheckboxChild = useCallback((checked: boolean, index: number, access: string, indexChild: number) => {
    const newArr = [...menusData];

    newArr[index].child[indexChild][access] = checked;
    setMenusData(newArr);
  }, [menusData]);


  useEffect(() => {
    if (menusParent.length > 0) {
      setMenusData(menusParent);
    } else {
      setMenusData(menusParentDefault);
    }
  }, [menusParent, menusParentDefault]);

  return {
    data: {
      team,
      id,
      displaySettingPermission,
      menusParent,
      isLoading,
      menusData,
      toast,
      defaultRenderMenus
    },
    method: {
      featureSelected,
      handleClickCustomAccess,
      handleClickClosePopUp,
      handleClickSubmitPopUp,
      handleChangeCheckboxParent,
      handleChangeCheckboxChild
    }
  };
};

export default useTabAccessSetting;
