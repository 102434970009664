import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import { Avatar } from 'primereact/avatar';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import useDetailMemberGroup from './hooks';

const DetailMember = () => {
  const { data } = useDetailMemberGroup();

  return (
    <div>
      <BaseContainer>
        <MainTitle title="Detail Member" nav={`/user-management/${data.params?.id}`} labelButton="Back" isWithButton />
        <div className="card flex justify-content-center" />
        <div className="p-6 mt-4 bg-white rounded-xl">
          <div className="flex px-2 justify-between items-center">
            <div className="w-1/4 flex gap-4 items-center">
              <div className="flex">
                <Avatar size="large" shape="circle" />
              </div>
              <div className="flex flex-col">
                <div className="font-semibold">{'Alwin'}</div>
                <div className="text-sm">{'Admin Provider'}</div>
              </div>
            </div>
            {/* <Button label='Edit Profile' severity='danger' outlined className='h-10 w-1/5' /> */}
          </div>
          <Divider />
          <div className="font-semibold px-2 pb-3">Member Data</div>
          <div className="w-full px-2 flex gap-3">
            <div className="flex flex-col w-1/2">
              <div className="text-slate-400">
                ID Member
              </div>
              <InputText
                name="admin-name-input" disabled
                value={String(data?.detailMember?.id) || '-'}
                className="w-full h-10" />
            </div>
            <div className="flex flex-col w-1/2">
              <div className="text-slate-400">
                Name
              </div>
              <InputText
                name="admin-email-input" disabled
                value={data?.detailMember?.name || '-'}
                className="w-full h-10"
              />
            </div>
          </div>
          <div className="w-full px-2 flex gap-3">
            <div className="flex flex-col w-1/2">
              <div className="text-slate-400">
                ACC TYPE
              </div>
              <InputText
                name="admin-name-input" disabled
                value={data?.detailMember?.type || '-'}
                className="w-full h-10" />
            </div>
            <div className="flex flex-col w-1/2">
              <div className="text-slate-400">
                 Created
              </div>
              <InputText
                name="admin-email-input" disabled
                value={data?.detailMember?.created || '-'}
                className="w-full h-10"
              />
            </div>
          </div>
          <div className="w-full px-2 flex gap-3">
            <div className="flex flex-col w-1/2">
              <div className="text-slate-400">
                Status
              </div>
              <InputText
                name="admin-name-input" disabled
                value={data?.detailMember?.status || '-'}
                className="w-full h-10" />
            </div>
            <div className="flex flex-col w-1/2">
              <div className="text-slate-400">
                Email
              </div>
              <InputText
                name="admin-name-input" disabled
                value={data?.detailMember?.email || '-'}
                className="w-full h-10" />
            </div>
          </div>
          <Divider />
          {/* <div className="font-semibold px-2 pb-3">Password</div>
          <Button label='Reset Password' className='h-10 w-1/5' outlined /> */}
        </div>
      </BaseContainer>
    </div>
  );
};

export default DetailMember;
