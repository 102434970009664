import { Button } from 'primereact/button';
import { FC } from 'react';

type ISubmitButton = {
  onCancel: () => void;
  onSubmit: () => void;
  labelSubmit?: string;
  labelBack: string;
  disabledSubmit?:boolean
  loadingSubmit?: boolean
  disabledEdit?: boolean
};

const SubmitButton: FC<ISubmitButton> = ({ onSubmit, onCancel, labelBack, labelSubmit, disabledSubmit, loadingSubmit, disabledEdit }) => {
  return (
    <div id="buttons-action" className="mt-5 w-full flex justify-end gap-4 px-10">
      <Button label={labelBack} severity="danger" type="button" outlined onClick={onCancel} disabled={disabledEdit} />
      <Button loading={loadingSubmit} label={labelSubmit} type="submit" severity="danger" onClick={onSubmit} disabled={disabledSubmit}/>
    </div>
  );
};

export default SubmitButton;
