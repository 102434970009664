export const menuFlashSaleInformation = {
  headerMenu: 'FLASH SALE INFORMATION',
  listMenu: [
    { id: 1, label: 'Flash Sale Detail' },
    { id: 2, label: 'Flash Sale Product' }
  ]
};

export const optionPeriode = [
  // TODO: Unhide multiple date options when issues are fixed
  // { name: 'Multiple Date', code: 'multiple' },
  { name: 'Range', code: 'range' }
];

export const optionStatus = [
  { name: 'Enable', code: true },
  { name: 'Disabled', code: false }
];
