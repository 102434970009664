import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { IPagination } from '@/types/pagination';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

export type Specification = {
  id?: number | string;
  category_id?: number;
  created_at?: string | null;
  name: string | null;
  updated_at?: string | null;
  specification_presets?: Values[];
  label: string;
  description?: string;
  type: string;
  is_active: boolean;
  instruction?:string
  specifications?: Specification
};

type Values = {
  id: number;
  specification_id: number;
  name: string;
};

type SpecPreset = {
  specification_id: number;
  name: string;
}

export type FilterSpec = {
  id_from: number | null;
  id_to: number | null;
  name: string;
  label: string;
  description: string;
  type: string;
  status: string;
};

const getAllSpecification = async (filter?: FilterSpec, range?: IPagination, search?: string) => {
  const query = supabase
    .from(TABLE.SPESIFICATION)
    .select('*', { count: 'exact' })
    .order('created_at', { ascending: false })
    .is('deleted_at', null);

  if (range) {
    query.range(range.start, range.end);
  }

  if (filter) {
    // ID
    if (filter.id_from != null) {
      query.gte('id', filter.id_from);
    }

    if (filter.id_to != null) {
      query.lte('id', filter.id_to);
    }

    // Name
    if (filter.name != '') {
      query.ilike('name', `%${filter.name}%`);
    }

    // Label
    if (filter.label != '') {
      query.ilike('label', `%${filter.label}%`);
    }

    // Description
    if (filter.description != '') {
      query.ilike('description', `%${filter.description}%`);
    }

    // Type
    if (filter.type != '') {
      query.ilike('type', `%${filter.type}%`);
    }

    // Status
    if (filter.status != '') {
      query.eq('is_active', (filter.status == 'active'));
    }
  }

  // General Search
  if (search && search != '') {
    query.or(`name.ilike.%${search}%, description.ilike.%${search}%, label.ilike.%${search}%, type.ilike.%${search}%)`);
  }

  return await query as PostgrestSingleResponse<Specification[]>;
};

const getSpecificationFilterOptions = async () => {
  return (await supabase
    .from(TABLE.SPESIFICATION)
    .select('name,label,id,type,is_active')
    .is('deleted_at', null)
    .range(0, 10)
  ) as PostgrestSingleResponse<Specification[]>;
};

const getSpecByCategoryId =async (id?: number) => {
  return (await supabase
    .from(TABLE.CATEGORY_SPECIFICATION)
    .select('specifications(id, name)')
    .eq('category_id', id)
  ) as PostgrestSingleResponse<Specification[]>;
};

const getSpecificationById = async (id?: string) => {
  return (await supabase
    .from(TABLE.SPESIFICATION)
    .select('name,label,id,description,type,is_active,instruction,specification_presets(name, id)')
    .is('deleted_at', null)
    .eq('id', id)
    .single()) as PostgrestSingleResponse<Specification>;
};

export const deleteSpecificationById = async (id: string) => {
  return await supabase.from(TABLE.SPESIFICATION).update({
    deleted_at: dayjs().format('YYYY-MM-DD HH:mm:ssZ')
  }).eq('id', id);
};

export const deleteCategorySpecification = async (id: string) => {
  return await supabase.from(TABLE.CATEGORY_SPECIFICATION).delete().eq('specification_id', id);
};

export const deleteSpecificationPreset = async (id: Array<number>) => {
  return await supabase.from(TABLE.SPECIFICATION_PRESET).delete().in('id', id);
};

export const deleteSpecificationPresetBySpecId =async (id:number) => {
  return await supabase.from(TABLE.SPECIFICATION_PRESET).delete().eq('specification_id', id);
};

export const insertSpecification = async (payload: Specification) => {
  return await supabase.from(TABLE.SPESIFICATION).insert(payload).select('id').single();
};

export const insertSpecificationPreset = async (payload: SpecPreset[]) => {
  return await supabase.from(TABLE.SPECIFICATION_PRESET).insert(payload);
};

export const upsertSpecification = async (payload: Specification) => {
  return await supabase.from(TABLE.SPESIFICATION).upsert([payload]).select('id').single();
};

export const upsertSpecificationPreset = async (payload: SpecPreset[]) => {
  return await supabase.from(TABLE.SPECIFICATION_PRESET).upsert(payload);
};

export const deleteMultiplePresetSpecification =async (id:string[]) => {
  return await supabase.from(TABLE.SPECIFICATION_PRESET).delete().in('specification_id', id);
};

export const useCategorySpec = (id?: number) =>
  useQuery({
    queryKey: ['category-spec', id],
    queryFn: ()=>getSpecByCategoryId(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });

export const useSpecification = (filter?: FilterSpec, range?: IPagination, search?: string) =>
  useQuery({
    queryKey: ['specification', filter, range, search],
    queryFn: () => getAllSpecification(filter, range, search),
    refetchOnWindowFocus: false
  });

export const useDetailSpecification = (id?:string) =>
  useQuery({
    queryKey: ['detail-specification'],
    queryFn: ()=>getSpecificationById(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });

export const useSpecificationFilterOptions = () =>
  useQuery({
    queryKey: ['total-specification'],
    queryFn: getSpecificationFilterOptions,
    refetchOnWindowFocus: false
  });
