import React, { ReactElement } from 'react';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import { InputText } from 'primereact/inputtext';
import useUserManagement from './hooks';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import IconSearch from '@/assets/IconSearch';
import { Link } from 'react-router-dom';
import IconDot from '@/assets/IconDot';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { usePermissionStore } from '@/store/usePermissions';
import Pagination from '@/components/base/Pagination';
import { Divider } from 'primereact/divider';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import FilterHistory from '@/components/base/FilterHistory';

const statusBodyTemplate = (data): ReactElement => {
  return (
    <div className="flex gap-x-3 items-center">
      {data.status == 'Active' ? (
        <>
          <IconDot fill="#39ac76" />
          <span>Active</span>
        </>
      ) : (
        <>
          <IconDot fill="#ef4444" />
          <span>Inactive</span>
        </>
      )}
    </div>
  );
};

const UserManagement = (): ReactElement => {
  const {
    data: {
      search,
      dataTeams,
      header,
      isLoading,
      itemFilters,
      visible,
      formCreate,
      toast,
      statusOption,
      formik,
      paginator,
      perPage,
      totalPages,
      totalRecords,
      isOpenDialog,
      filterHistory
    },
    method: {
      handleFilter,
      handleClickCreate,
      handleCancelCreate,
      handleOnChangeInput,
      handleDropdown,
      isFormFieldValid,
      handleChangeDropdownPage,
      handleClickNext,
      handleClickPrev,
      handleJumpToPage,
      handleChangeJumpTopage,
      handleIsOpenDialog,
      handleClickSubmitFilter,
      handleClearFilterHistory,
      handleSearch,
      handleDeleteFilterHistory
    }
  } = useUserManagement();
  const { canCreate, canView } = usePermissionStore();

  const headerCreate = (
    <div id="admin-create-header" className="font-bold">Create User Group</div>
  );

  const footerCreate = (
    <div id="admin-create-footer" className="-mt-6 -mr-10">
      <SubmitButton
        onCancel={handleCancelCreate}
        onSubmit={formik.handleSubmit}
        labelBack="Cancel"
        labelSubmit="Submit"
      />
    </div>
  );

  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  const filterContent = (
    <div className="flex flex-col gap-4 w-[900px]">
      <div className="w-full flex flex-col items-start gap-2">
        <h1 className="font-bold">ID</h1>
        <div className="flex w-full gap-6">
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Dari</label>
            <InputNumber
              onChange={(e) => handleFilter(e, 'id_from')}
              value={itemFilters.id_from}
              name="id_from"
              min={0}
            />
          </div>
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Sampai</label>
            <InputNumber
              onChange={(e) => handleFilter(e, 'id_to')}
              value={itemFilters.id_to}
              name="id_to"
              min={0}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-start gap-2">
        <h1 className="font-bold">Total User</h1>
        <div className="flex w-full gap-6">
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Dari</label>
            <InputNumber
              onChange={(e) => handleFilter(e, 'total_user_from')}
              value={itemFilters.total_user_from}
              name="total_user_from"
              min={0}
            />
          </div>
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Sampai</label>
            <InputNumber
              onChange={(e) => handleFilter(e, 'total_user_to')}
              value={itemFilters.total_user_to}
              name="total_user_to"
              min={0}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full gap-6">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-status" className="font-bold">Created At</label>
          <Calendar
            onChange={(e) => handleFilter(e, 'created_at')}
            value={itemFilters.created_at}
            name="created_at"
            className="w-full"
            selectionMode="range"
            showIcon
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Group Name</label>
          <InputText
            onChange={(e) => handleFilter(e, 'group_name')}
            value={itemFilters.group_name}
            name="group_name"
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
          />
        </div>
      </div>
      <div className="flex flex-col gap-2 w-1/2">
        <label htmlFor="filter-status" className="font-bold">Status</label>
        <Dropdown
          onChange={(e) => handleFilter(e, 'status')}
          value={itemFilters.status}
          name="status"
          placeholder="Pilih Status"
          options={[{ label: 'Active', value: 'Active' }, { label: 'Inactive', value: 'Inactive' }]}
          optionLabel="label"
          className="w-full"
        />
      </div>
    </div>
  );

  const footerContent = (
    <div className="flex justify-end py-4 gap-4">
      <Button label="Batal" severity="secondary" outlined onClick={handleIsOpenDialog} size="large" />
      <Button label="Terapkan" severity="info" onClick={handleClickSubmitFilter} size="large" />
    </div>
  );

  return (
    <>
      <Toast ref={toast} />
      <BaseContainer>
        <div className="flex justify-between gap-3">
          <h2 className="font-bold lg:text-2xl">BOS User Group</h2>
          <Button disabled={!canCreate} onClick={handleClickCreate} severity="danger">
            Add User Group
          </Button>
        </div>
        <ConfirmDialog />
        <div id="admin-list" className="card flex justify-content-center">
          <Dialog
            header={headerCreate}
            footer={footerCreate}
            draggable={false}
            visible={visible}
            style={{ width: '50vw' }}
            onHide={handleCancelCreate}
          >
            <div className="py-2">
              <label className="pb-2 text-sm font-medium">
                Name <span className="text-red-600">*</span>
              </label>
              <InputText
                id="inputtext"
                onChange={handleOnChangeInput('name')}
                name="admin-name-input"
                required
                className="w-full"
              />
            </div>
            <div className="pt-1 pl-1">{getFormErrorMessage('name')}</div>
            <div className="py-2">
              <label className="pb-2 text-sm font-medium">
                Code <span className="text-red-600">*</span>
              </label>
              <InputText
                onChange={handleOnChangeInput('code')}
                name="admin-name-input"
                required
                value={formCreate.code}
                className="w-full"
              />
            </div>
            <div className="pt-1 pl-1">{getFormErrorMessage('code')}</div>
            <div className="py-2">
              <label className="pb-2 text-sm font-medium">Description</label>
              <InputText
                onChange={handleOnChangeInput('description')}
                name="admin-name-input"
                required
                value={formCreate.description}
                className="w-full"
              />
            </div>
            <div className="py-2">
              <label className="my-2 text-sm font-medium">
                Status <span className="text-red-600">*</span>
              </label>
              <Dropdown
                required
                className="w-full"
                optionLabel="label"
                optionValue="code"
                value={formCreate.status}
                options={statusOption}
                onChange={handleDropdown('status')}
                placeholder="Select status"
              />
            </div>
            <div className="pt-1 pl-1">{getFormErrorMessage('status')}</div>
          </Dialog>
        </div>
        <div className="flex flex-col gap-2 my-4">
          <div className="flex justify-end">
            <Button
              icon="pi pi-sliders-h"
              onClick={handleIsOpenDialog}
              label="Filter"
              severity="secondary"
              text
              raised
            />
          </div>
          {filterHistory.length > 0 ?
            <FilterHistory
              filterHistory={filterHistory}
              handleClearFilter={handleClearFilterHistory}
              handleDeleteFilterHistory={handleDeleteFilterHistory}
              show={filterHistory.length > 0}
            /> : <Divider className="p-divider-solid" />}

          <div className="flex justify-between items-center w-full">
            <div id="search" className="p-input-icon-right w-[20%]">
              <InputText
                id="user-input-search"
                className="w-full"
                placeholder="Cari Disini"
                type="search"
                onChange={handleSearch}
                value={search}
                keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
              />
              <i className="pi pi-search" />
            </div>

            <div className="flex items-center justify-end gap-6 w-full">
              <h4 className="font-bold justify-self-end"> {totalRecords} data ditemukan</h4>
              <Pagination
                perPage={perPage}
                totalPages={totalPages}
                currentPage={paginator.currentPage}
                disabledDropdown={totalRecords === 0}
                onClickPrev={handleClickPrev}
                onClickNext={handleClickNext}
                onJumptoPage={handleJumpToPage}
                onChangeJumptoPage={handleChangeJumpTopage}
                onChangeDropdownPage={handleChangeDropdownPage}
              />
            </div>
          </div>
        </div>

        <div className="card border">
          {isLoading ? (
            <LoadingOverlay show />
          ) : (
            <DataTable
              globalFilter={search}
              value={dataTeams as []}
              scrollable
              scrollHeight="36rem"
              dataKey="id"
              tableStyle={{ minWidth: '12rem' }}
            >
              {header.map((item, index) =>
                item.field !== 'status' ? (
                  <Column
                    key={index}
                    field={item.field}
                    header={item.header}
                    alignHeader="center"
                    align="center"
                    sortable
                  />
                ) : (
                  <Column
                    key={index}
                    field={item.field}
                    header={item.header}
                    alignHeader="center"
                    align="center"
                    sortable
                    body={statusBodyTemplate}
                  />
                )
              )}
              <Column
                header="Action"
                style={{ width: 'rem' }}
                body={(rowData) => (
                  <Link to={canView ? `${rowData.id}` : ''}>
                    <Button
                      id="user-button-action"
                      icon={IconSearch}
                      outlined
                      rounded
                      severity="secondary"
                      disabled={!canView}
                    />
                  </Link>
                )}
              />
            </DataTable>
          )}
        </div>
      </BaseContainer>
      <Dialog onHide={handleIsOpenDialog} visible={isOpenDialog} header="Semua Filter" footer={footerContent}>
        {filterContent}
      </Dialog>
    </>
  );
};

export default UserManagement;
