import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { FC, ReactNode, RefObject, SyntheticEvent } from 'react';
import { IBannerOptions, IFormValue } from '../../hooks';
import { Image } from 'primereact/image';
import { FileUpload, FileUploadFilesEvent } from 'primereact/fileupload';
import IconPencil from '@/assets/IconPencil';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';

interface IFormBanner {
  show: boolean;
  formValue: IFormValue;
  handleOnChangeInput: (key: string) => (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement, Event>) => void;
  getFormErrorMessage: (name: string) => ReactNode;
  handleOnChangeDropdown: (key: string) => (event: DropdownChangeEvent) => void;
  handleAddMultipleImage: (key: string) => () => void;
  disabledForm?: boolean;
  fileInputRef: RefObject<FileUpload>;
  handleUploadImage: (key: string) => (event: FileUploadFilesEvent) => void;
  isLoadingUpload: boolean;
  dropDownOptionSize: Array<IBannerOptions>;
  dropDownOptionStatus: Array<IBannerOptions>;
  validFrom: Date;
  validTo: Date;
  validateDate: Boolean;
  handleOnChangeDate: (key: string) => (e: CalendarChangeEvent) => void;
  directTo: string | null;
  directToList: Array<IBannerOptions>;
  optionDirectTo: Array<IBannerOptions> | [];
  selectedDirectTo: IBannerOptions | null;
  onSearchTargetSelection: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeDropdown: (key: string) => (e: DropdownChangeEvent) => void;
}

export const FormBanner: FC<IFormBanner> = ({
  show,
  formValue,
  disabledForm,
  fileInputRef,
  isLoadingUpload,
  handleOnChangeInput,
  getFormErrorMessage,
  handleUploadImage,
  handleAddMultipleImage,
  handleOnChangeDropdown,
  dropDownOptionSize,
  dropDownOptionStatus,
  validFrom,
  validTo,
  validateDate,
  handleOnChangeDate,
  directTo,
  optionDirectTo,
  selectedDirectTo,
  directToList,
  onSearchTargetSelection,
  handleChangeDropdown
}) => {
  const brandBanner = ['BRAND_BANNER', 'BRAND_BANNER_RIGHT', 'BRAND_BANNER_LEFT'];
  const bannerWithThumbnail = [
    'PRODUCT_CATEGORY_BANNER',
    'PROMO_BANNER',
    'SECTION_FLASH_SALE_BACKGROUND',
    'VIDEO_BANNER',
    'SECTION_BACKGROUND',
    'POPUP_BANNER'
  ];

  if (show && formValue.type.code) {
    return (
      <div className="flex flex-col flex-1 gap-4">
        <div id="apply_to_street" className="flex">
          <div id="apply_street_label" className="w-1/5">
            Banner Name <span className="text-red-600">*</span>
          </div>
          <div className="!w-1/2" id="dropdown_field">
            <div className="card flex flex-col">
              <InputText
                value={formValue.name}
                onChange={handleOnChangeInput('name')}
                placeholder="Banner Name"
                className="w-full md:w-14rem"
                disabled={Boolean(disabledForm)}
                required
              />
              {getFormErrorMessage('name')}
            </div>
          </div>
        </div>
        <div id="apply_to_street" className="flex">
          <div id="apply_street_label" className="w-1/5">
            Description
          </div>
          <div className="!w-1/2" id="dropdown_field">
            <div className="card flex justify-content-center">
              <InputTextarea
                value={formValue.description}
                onChange={handleOnChangeInput('description')}
                placeholder="Description"
                className="w-full md:w-14rem"
                disabled={Boolean(disabledForm)}
              />
            </div>
          </div>
        </div>

        {/* if banner type is not brand banner */}
        {!brandBanner.includes(formValue.type.code) && (
          <>
            <div id="apply_to_street" className="flex">
              <div id="apply_street_label" className="w-1/5">
                Desktop Banner <span className="text-red-600">*</span>
              </div>
              <div className="!w-1/2" id="dropdown_field">
                <div className="card flex justify-between w-full gap-2">
                  {formValue.desktop && (
                    <div className="w-full">
                      <Image src={formValue.desktop} className="object-cover" />
                    </div>
                  )}
                  <FileUpload
                    ref={fileInputRef}
                    auto
                    mode="basic"
                    className="items-end w-full"
                    customUpload
                    uploadHandler={handleUploadImage('desktop')}
                    accept="image/*"
                    disabled={Boolean(disabledForm || isLoadingUpload)}
                    chooseLabel={isLoadingUpload ? 'Uploading' : 'Choose File'}
                  />
                </div>
                {getFormErrorMessage('desktop')}
              </div>
            </div>
            <div id="apply_to_street" className="flex">
              <div id="apply_street_label" className="w-1/5">
                Mobile Banner <span className="text-red-600">*</span>
              </div>
              <div className="!w-1/2" id="dropdown_field">
                <div className="card flex justify-between w-full gap-2">
                  {formValue.mobile && (
                    <div className="w-full">
                      <Image src={formValue.mobile} className="object-cover" />
                    </div>
                  )}
                  <FileUpload
                    ref={fileInputRef}
                    auto
                    mode="basic"
                    className="items-end w-full"
                    customUpload
                    uploadHandler={handleUploadImage('mobile')}
                    disabled={Boolean(disabledForm || isLoadingUpload)}
                    chooseLabel={isLoadingUpload ? 'Uploading' : 'Choose File'}
                    accept="image/*"
                  />
                </div>
                {getFormErrorMessage('mobile')}
              </div>
            </div>

            {/* Show thumbnail banner field*/}
            {!bannerWithThumbnail.includes(formValue.type.code) && (
              <div id="apply_to_street" className="flex">
                <div id="apply_street_label" className="w-1/5">
                  Thumbnail <span className="text-red-600">*</span>
                </div>
                <div className="!w-1/2" id="dropdown_field">
                  <div className="card flex justify-between w-full gap-2">
                    {formValue.thumbnail && (
                      <div className="w-full">
                        <Image src={formValue.thumbnail} className="object-cover" />
                      </div>
                    )}
                    <FileUpload
                      ref={fileInputRef}
                      auto
                      mode="basic"
                      className="items-end w-full"
                      customUpload
                      uploadHandler={handleUploadImage('thumbnail')}
                      disabled={Boolean(disabledForm || isLoadingUpload)}
                      chooseLabel={isLoadingUpload ? 'Uploading' : 'Choose File'}
                      accept="image/*"
                    />
                  </div>
                  {getFormErrorMessage('thumbnail')}
                </div>
              </div>
            )}

            {
              formValue.type.code === 'PROMO_BANNER' && (
                <div className="w-full flex flex-col gap-4">
                  <div id="apply_to_street" className="flex items-center">
                    <div id="apply_street_label" className="w-1/5">
                      Direct to
                    </div>
                    <div className="!w-1/2" id="dropdown_field">
                      <div className="card flex justify-content-center">
                        <Dropdown
                          value={directTo}
                          optionLabel="label"
                          className="w-full"
                          optionValue="code"
                          placeholder="Select"
                          options={directToList}
                          onChange={handleChangeDropdown('directTo')}
                          disabled={Boolean(disabledForm)}
                        />
                      </div>
                    </div>
                  </div>
                  {
                    formValue.directTo !== '' && (
                      <div id="apply_to_street" className="flex items-center">
                        <div id="apply_street_label" className="w-1/5" />
                        <div className="!w-1/2" id="dropdown_field">
                          <div className="card flex justify-content-center flex-col">
                            {
                              directTo === 'LANDING_PAGE' ?
                                <>
                                  <InputText
                                    value={formValue.landingPage}
                                    onChange={handleOnChangeInput('landingPage')}
                                    placeholder="https://aladinmall.id/......"
                                    className="w-full md:w-14rem"
                                    disabled={Boolean(disabledForm)}
                                    required
                                  />
                                  {getFormErrorMessage('landingPage')}
                                </> :
                                <Dropdown
                                  value={selectedDirectTo}
                                  className="w-full"
                                  placeholder="Select"
                                  filter
                                  filterBy="label"
                                  options={optionDirectTo}
                                  onInput={onSearchTargetSelection}
                                  onChange={handleChangeDropdown(
                                    (directTo === 'PDP' && 'productId') ||
                                      // (directTo === 'LANDING_PAGE' && 'landingPage') ||
                                      (directTo === 'STORE_DETAIL' && 'merchantId') ||
                                      (directTo === 'PROMO_DETAIL_PAGE' && 'promoDetailId') ||
                                      ''
                                  )}
                                  disabled={Boolean(disabledForm)}
                                />
                            }
                          </div>
                        </div>
                      </div>
                    )
                  }
                  <div id="apply_to_street" className="flex items-center">
                    <div id="apply_street_label" className="w-1/5">
                      Valid from
                    </div>
                    <div className="!w-1/2" id="dropdown_field">
                      <div className="card flex justify-content-center">
                        <Calendar
                          value={validFrom}
                          onChange={handleOnChangeDate('validFrom')}
                          showIcon
                          hideOnDateTimeSelect
                          maxDate={new Date(validTo)}
                          minDate={new Date()}
                          className="w-full"
                          inputStyle={{
                            border: validateDate ? '1px solid red' : ''
                          }}
                          disabled={Boolean(disabledForm)}
                        />
                      </div>
                    </div>
                  </div>
                  <div id="apply_to_street" className="flex items-center">
                    <div id="apply_street_label" className="w-1/5">
                      Valid to
                    </div>
                    <div className="!w-1/2" id="dropdown_field">
                      <div className="card flex justify-content-center">
                        <Calendar
                          value={validTo}
                          onChange={handleOnChangeDate('validTo')}
                          showIcon
                          hideOnDateTimeSelect
                          minDate={new Date(validFrom)}
                          className="w-full"
                          inputStyle={{
                            border: validateDate ? '1px solid red' : ''
                          }}
                          disabled={Boolean(disabledForm) || !Boolean(validFrom.getDate())}
                        />
                      </div>
                      {validateDate && <span className="text-red-500 text-xs">End time must greater than start time</span>}
                    </div>
                  </div>
                </div>
              )
            }

            <div id="status" className="flex items-center">
              <div id="status_label" className="w-1/5">Status</div>
              <div className="!w-1/2" id="dropdown_field">
                <div className="card flex justify-content-center">
                  <Dropdown
                    value={formValue.status}
                    className="w-full"
                    placeholder="Select"
                    options={dropDownOptionStatus}
                    onChange={handleOnChangeDropdown('status')}
                    disabled={Boolean(disabledForm || isLoadingUpload)}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {/* Brand banner form */}
        {brandBanner.includes(formValue.type.code) && (
          <>
            <div>Banner type :</div>
            <div className="flex items-center">
              <div className="w-1/5 px-4">Left section</div>
              <div className="w-1/2 flex gap-2">
                <Dropdown
                  className="flex-1"
                  value={formValue.sizeLeft}
                  onChange={handleOnChangeDropdown('sizeLeft')}
                  options={dropDownOptionSize}
                  disabled
                />
                <Button
                  icon={IconPencil}
                  type="button"
                  onClick={handleAddMultipleImage('leftImage')}
                />
              </div>
            </div>
            <div className="flex">
              <div className="w-1/5" />
              {getFormErrorMessage('leftImageBrand')}
            </div>
            <div className="flex items-center">
              <div className="w-1/5 px-4">Center section</div>
              <div className="w-1/2 flex gap-2">
                <Dropdown
                  className="flex-1"
                  value={formValue.sizeCenter}
                  onChange={handleOnChangeDropdown('sizeCenter')}
                  disabled
                  options={dropDownOptionSize}
                />
                <Button
                  icon={IconPencil}
                  type="button"
                  onClick={handleAddMultipleImage('centerImage')}
                />
              </div>
            </div>
            <div className="flex">
              <div className="w-1/5" />
              {getFormErrorMessage('centerImageBrand')}
            </div>
            <div className="flex items-center">
              <div className="w-1/5 px-4">Right section</div>
              <div className="w-1/2 flex gap-2">
                <Dropdown
                  className="flex-1"
                  value={formValue.sizeRight}
                  onChange={handleOnChangeDropdown('sizeRight')}
                  disabled
                  options={dropDownOptionSize}
                />
                <Button
                  icon={IconPencil}
                  type="button"
                  onClick={handleAddMultipleImage('rightImage')}
                />
              </div>
            </div>
            <div className="flex">
              <div className="w-1/5" />
              {getFormErrorMessage('rightImageBrand')}
            </div>
            <div id="status" className="flex items-center">
              <div id="status_label" className="w-1/5">Status</div>
              <div className="!w-1/2" id="dropdown_field">
                <div className="card flex justify-content-center">
                  <Dropdown
                    value={formValue.status}
                    className="w-full"
                    placeholder="Select"
                    options={dropDownOptionStatus}
                    onChange={handleOnChangeDropdown('status')}
                    disabled={Boolean(disabledForm || isLoadingUpload)}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
  return <></>;
};
