import { Image } from 'primereact/image';
import React, { FC, useEffect, useMemo, useState } from 'react';

interface ICarousel {
  images: string[];
}
interface IImageCarousel {
  image: string;
  width: number;
  page: number;
  index: number;
}
const ImageCarousel: FC<IImageCarousel> = ({ image, width, page, index }) => {
  const hImage = useMemo(() => {
    return page === index ? '78px' : '68px';
  }, [page, index]);

  const transformValue = useMemo(() => {
    return width * (page);
  }, [page, width]);

  return (
    <div className="flex-shrink-0 transition-all" style={{ transform: `translateX(-${transformValue}px)`, width, height: hImage }}>
      <Image src={image} alt={image} className="rounded-xl bg-cover" draggable={false} style={{ height: hImage }} />
    </div>
  );
};
export const Carousel: FC<ICarousel> = ({ images }) => {
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (page !== images.length - 1) {
        setPage(page + 1);
      } else {
        setPage(0);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [page]);

  useEffect;
  return (
    <div className="w-full">
      <div className="w-full flex items-center gap-2.5 p-4 overflow-hidden">
        {images?.map((item, index) => {
          return <ImageCarousel image={item} index={index} page={page} width={300} key={index} />;
        })}
      </div>
      <div className="flex gap-0.5 ml-8">
        {images.map((_, index)=>{
          return <div className={`h-1 rounded-full ${page===index?'bg-primary w-2':'bg-slate-300 w-1'}`} key={index}/>;
        })}
      </div>
    </div>
  );
};
