import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IDropdownPrimeReact, IKeywordRecommendation, IMetaProductVariant, IMetaStore, INewProduct, IProductVariant, IProductVariantSupabase, IStore, IStoreRecommendation, ITagMetadataKeywordRecommendation, ITagMetadataNewProduct, ITagMetadataStoreRecomendation, ITagMetadataTopKeywordList, ITopKeywordList } from '../type';
import { useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { optionAction, optionCategoryTags, optionPromoTaggingType, optionShowDescription, optionStatus } from '../helpers';
import { IFormEditPromoTagging } from '../type';
import { supabase } from '@/lib/supabase';
import { TABLE } from '@/constants';
import { useGetPromoById, useGetStores, useMerchantById, useProductById, useProductVariants } from '@/services/rest/promoTagging';
import { useStreet } from '@/services/rest/banner';
import { IBannerOptions } from '@/views/BannerManagement/CreateEditBanner/hooks';

export const usePromoTaggingDetail = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [validate, setValidate] = useState(false);
  const [productVariant, setProductVariant] = useState<IProductVariant[]>([]);
  const [store, setStore] = useState<IStore[]>([]);
  const [search, setSearch] = useState('');
  const [searchEnter, setSearchEnter] = useState('');
  const [street, setStreet] = useState<IDropdownPrimeReact[]>([]);
  const [selectedStreet, setSelectedStreet] = useState<[]>([]);
  const [formEditPromoTagging, setFormEditPromoTagging] = useState<IFormEditPromoTagging>({
    id: '',
    category: {} as IDropdownPrimeReact,
    type: {} as IDropdownPrimeReact,
    name: '',
    description: '',
    showDescription: {} as IDropdownPrimeReact,
    tag_metadata: {} as ITagMetadataNewProduct | {} as ITagMetadataStoreRecomendation,
    action: {} as IDropdownPrimeReact,
    url: '',
    valid_from: '',
    valid_to: '',
    urlLandingPage: '',
    street: [] as IDropdownPrimeReact[],
    status: {} as IDropdownPrimeReact
  });
  const [selected, setSelected] = useState<IProductVariant[] | IStore[]>([]);
  const toast = useRef<Toast>(null);
  const params = useParams();
  const navigate = useNavigate();
  const showToastEditPromoTaggingSuccesfully = (msg: string) => {
    toast.current?.show({ severity: 'info', summary: 'Info', detail: msg });
  };
  const { data: dataProduct, isLoading: isLoadingProduct } = useProductById(formEditPromoTagging.tag_metadata?.product_id);
  const { data: dataMerchant, isLoading: isLoadingMerchant } = useMerchantById(formEditPromoTagging.tag_metadata?.merchant_id);
  const { data: dataPromo } = useGetPromoById(formEditPromoTagging.tag_metadata?.promos_id as string);

  const detailProduct = useMemo(() => {
    const data = dataProduct?.data;
    return {
      id: Number(data?.id),
      name: data?.name
    };
  }, [dataProduct, formEditPromoTagging]);

  const detailMerchant = useMemo(() => {
    const data = dataMerchant?.data;
    return {
      id: data?.id,
      name: data?.name
    };
  }, [dataMerchant, formEditPromoTagging]);

  const detailPromo = useMemo(() => {
    const data = dataPromo?.data;
    return {
      id: Number(data?.id),
      name: data?.name
    };
  }, [dataPromo]);

  const showToastEditPromoTaggingFailed = (msg: string) => {
    toast.current?.show({ severity: 'info', summary: 'Info', detail: msg });
  };

  const getPromoTaggingById = async (selectedList: IProductVariant[] | IStore[]) => {
    setIsLoading(true);
    const { data, error } = await supabase.from(TABLE.PROMO_TAGGING).select('id, name, type, description, tag_metadata, valid_from, valid_to, status, url').eq('id', params.id);
    if (data != null && !error) {
      if ( data[0].type === 'plp' || data[0].type === 'landingPage') {
        const result: IFormEditPromoTagging = {
          id: data[0].id,
          category: { name: 'Keyword Recommendation', code: 'keywordRecommendation' },
          type: optionPromoTaggingType.find((item) => item.code === data[0].type),
          name: data[0].name,
          description: data[0].description,
          showDescription: optionShowDescription.find((item) => item.code === data[0].tag_metadata.showDescription),
          action: optionAction.find((item) => item.code === data[0].tag_metadata.action),
          url: data[0].tag_metadata.url,
          tag_metadata: data[0].tag_metadata as ITagMetadataKeywordRecommendation,
          valid_from: data[0].valid_from,
          valid_to: data[0].valid_to,
          status: optionStatus.find((item) => item.code === data[0].status),
          urlLandingPage: data[0].url || ''
        };
        setFormEditPromoTagging(result);
      } else if (data[0].type === 'topKeywordList') {
        const result: IFormEditPromoTagging = {
          id: data[0].id,
          category: optionCategoryTags.find((item) => item.code === data[0].type),
          name: data[0].name,
          description: data[0].description,
          showDescription: optionShowDescription.find((item) => item.code === data[0].tag_metadata.showDescription),
          action: optionAction.find((item) => item.code === data[0].tag_metadata.action),
          url: data[0].tag_metadata.url,
          tag_metadata: data[0].tag_metadata as ITagMetadataTopKeywordList,
          valid_from: data[0].valid_from,
          valid_to: data[0].valid_to,
          street: street.filter((item) => item.code === data[0].tag_metadata.applyToStreet),
          status: optionStatus.find((item) => item.code === data[0].status),
          urlLandingPage: data[0].url || ''
        };
        setFormEditPromoTagging(result);
        getStreet();
      } else if (data[0].type === 'newProduct') {
        const result: IFormEditPromoTagging = {
          id: data[0].id,
          category: optionCategoryTags.find((item) => item.code === data[0].type),
          name: data[0].name,
          description: data[0].description,
          tag_metadata: data[0].tag_metadata as ITagMetadataNewProduct,
          action: optionAction.find((item) => item.code === data[0].tag_metadata.action),
          valid_from: data[0].valid_from,
          valid_to: data[0].valid_to,
          status: optionStatus.find((item) => item.code === data[0].status),
          urlLandingPage: data[0].url || ''
        };
        setFormEditPromoTagging(result);
        const metadata = data[0].tag_metadata as ITagMetadataNewProduct;
        if (Array.isArray(metadata.product)) {
          const productVariantId = metadata.product.map((item) => item.id);
          const filteredProductVariant = selectedList.filter((item) => productVariantId.includes(item.id));
          setSelected(filteredProductVariant);
        }
      } else if (data[0].type === 'storeRecommendation') {
        const result: IFormEditPromoTagging = {
          id: data[0].id,
          name: data[0].name,
          category: optionCategoryTags.find((item) => item.code === data[0].type),
          description: data[0].description,
          tag_metadata: data[0].tag_metadata as ITagMetadataStoreRecomendation,
          action: optionAction.find((item) => item.code === data[0].tag_metadata.action),
          valid_from: data[0].valid_from,
          valid_to: data[0].valid_to,
          status: optionStatus.find((item) => item.code === data[0].status),
          urlLandingPage: data[0].url || ''
        };
        setFormEditPromoTagging(result);
        const metadata = data[0].tag_metadata as ITagMetadataStoreRecomendation;
        if (Array.isArray(metadata.store)) {
          const storeId = metadata.store.map((item) => item.id);
          const filteredStore = selectedList.filter((item) => storeId.includes(item.id));
          setSelected(filteredStore);
        }
      }
    }
    setIsLoading(false);
  };

  const getProductVariant = async () => {
    const { data, error } = await supabase.from(TABLE.PRODUCT_VARIANTS).select('id, products ( name ), merchants ( name )').limit(10);

    if (data && !error) {
      const result: IProductVariant[] = (data as unknown as IProductVariantSupabase[]).map((item) => {
        return {
          id: String(item.id),
          product: item.products?.name,
          store: item.merchants?.name
        };
      });
      setProductVariant(result);
      getPromoTaggingById(result);
    }
  };

  const getStore = async () => {
    const { data, error } = await supabase.from(TABLE.MERCHANTS).select('id, name').limit(10);

    if (data != null && !error) {
      const result: IStore[] = data.map((item) => {
        return {
          id: item.id,
          store: item.name
        };
      });
      setStore(result);
      getPromoTaggingById(result);
    }
  };

  const { data: dataProducts } = useProductVariants();
  const dataProductVariants = useMemo(() => {
    const data = dataProducts?.data?.map((item) => {
      return {
        id: String(item.id),
        product: item.products?.name,
        store: item.merchants?.name
      };
    });
    return data;
  }, [dataProducts]);

  const { data: stores } = useGetStores();
  const dataStores = useMemo(() => {
    const data = stores?.data?.map((item) => {
      return {
        id: item?.id,
        store: item?.name
      };
    });
    return data;
  }, [stores]);

  const getStreet = useCallback(async () => {
    const { data, error } = (await supabase.from(TABLE.STREET).select('id, name').limit(10));

    if (data != null && !error) {
      const result: IDropdownPrimeReact[] = data.map((item) => {
        return {
          code: item.id,
          name: item.name
        };
      });
      setStreet(result);
    }
  }, []);

  const onCancel = () => {
    if (formEditPromoTagging.category?.code === 'newProduct') {
      const metadata = formEditPromoTagging.tag_metadata as ITagMetadataNewProduct;
      if (Array.isArray(metadata.product)) {
        const productVariantId = metadata.product.map((item) => item.id);
        const filteredProductVariant = productVariant.filter((item) => productVariantId.includes(item.id));
        setSelected(filteredProductVariant);
      }
    } else if (formEditPromoTagging.category?.code === 'storeRecommendation') {
      const metadata = formEditPromoTagging.tag_metadata as ITagMetadataStoreRecomendation;
      if (Array.isArray(metadata.store)) {
        const storeId = metadata.store.map((item) => item.id);
        const filteredStore = store.filter((item) => storeId.includes(item.id));
        setSelected(filteredStore);
      }
    }
    setVisible(false);
  };

  const onSubmit = useCallback(() => {
    if (selected.length < 1 || selected.length > 6) {
      setValidate(true);
    } else {
      setValidate(false);
      setVisible(false);
    }
  }, [selected]);

  const onChangeFormEditPromotagging = (key: string, value: string | number | null) => {
    setFormEditPromoTagging((prevState) => {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  const handleOnEnter = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        setSearchEnter(search);
      }
    },
    [search]
  );

  const handleSearch = useCallback((value: string) => {
    setSearch(value);
  }, []);

  const { data: getStreets } = useStreet();
  const optionStreet: IBannerOptions[] = useMemo(() => {
    return (
      (Array.isArray(getStreets?.data) &&
        getStreets?.data.map((street) => {
          return {
            label: street.name,
            code: street.id
          };
        })) ||
      []
    );
  }, [getStreets]);

  const onSavePromoTaggingById = async () => {
    setIsLoading(true);
    if (formEditPromoTagging.category?.code === 'newProduct') {
      const metaProductVariant: IMetaProductVariant[] = (selected as IProductVariant[]).map((i) => {
        return {
          id: i.id,
          product: i.product
        };
      });

      const metadata: ITagMetadataNewProduct = {
        product: metaProductVariant,
        action: String(formEditPromoTagging.action?.code)
      };

      const PromoTagging: INewProduct = {
        type: String(formEditPromoTagging.category.code),
        name: String(formEditPromoTagging.name),
        description: String(formEditPromoTagging.description),
        tag_metadata: metadata,
        valid_from: String(formEditPromoTagging.valid_from),
        valid_to: String(formEditPromoTagging.valid_to),
        status: String(formEditPromoTagging.status?.code)
      };

      await supabase.from(TABLE.PRODUCT_VARIANT_PROMO_TAGS).delete().eq('promo_tag_id', formEditPromoTagging.id);

      const { error } = await supabase.from(TABLE.PROMO_TAGGING)
        .update({
          name: PromoTagging.name,
          description: PromoTagging.description,
          tag_metadata: PromoTagging.tag_metadata,
          valid_from: PromoTagging.valid_from,
          status: PromoTagging.status
        })
        .eq('id', formEditPromoTagging.id);

      (selected as IProductVariant[]).forEach(async (i) => {
        await supabase.from(TABLE.PRODUCT_VARIANT_PROMO_TAGS).insert([{
          product_variant_id: i.id,
          promo_tag_id: formEditPromoTagging.id
        }]);
      });

      if (error) {
        showToastEditPromoTaggingFailed('Error Update Promo Tagging');
      }
      showToastEditPromoTaggingSuccesfully('Update Promo Tagging Succesfully');
      navigate('/promo-tagging');
      setIsLoading(false);
    } else if (formEditPromoTagging.category?.code === 'storeRecommendation') {
      const metaStore: IMetaStore[] = (selected as IStore[]).map((i) => {
        return {
          id: i.id,
          store: i.store
        };
      });

      const metadata: ITagMetadataStoreRecomendation = {
        action: String(formEditPromoTagging.action?.code),
        store: metaStore
      };

      const PromoTagging: IStoreRecommendation = {
        type: String(formEditPromoTagging.category.code),
        name: String(formEditPromoTagging.name),
        description: String(formEditPromoTagging.description),
        tag_metadata: metadata,
        valid_from: String(formEditPromoTagging.valid_from),
        valid_to: String(formEditPromoTagging.valid_to),
        status: String(formEditPromoTagging.status?.code)
      };

      const { error } = await supabase.from(TABLE.PROMO_TAGGING)
        .update({
          name: PromoTagging.name,
          description: PromoTagging.description,
          tag_metadata: PromoTagging.tag_metadata,
          valid_from: PromoTagging.valid_from,
          status: PromoTagging.status
        })
        .eq('id', formEditPromoTagging.id);

      if (error) {
        showToastEditPromoTaggingFailed('Error Update Promo Tagging');
      }
      navigate('/promo-tagging');
      showToastEditPromoTaggingSuccesfully('Update Promo Tagging Succesfully');
      setIsLoading(false);
    } else if (formEditPromoTagging.category?.code === 'topKeywordList') {
      if (Array.isArray(selectedStreet)) {
        const applyToStreet: number[] = (selectedStreet as IDropdownPrimeReact[]).map((i) => Number(i.code));

        const metadata: ITagMetadataTopKeywordList = {
          showDescription: Boolean(formEditPromoTagging.showDescription?.code),
          action: String(formEditPromoTagging.action?.code),
          url: String(formEditPromoTagging.url),
          applyToStreet: applyToStreet
        };

        const PromoTagging: ITopKeywordList = {
          type: 'topKeywordList',
          name: String(formEditPromoTagging.name),
          description: String(formEditPromoTagging.description),
          tag_metadata: metadata,
          valid_from: String(formEditPromoTagging.valid_from),
          valid_to: String(formEditPromoTagging.valid_to),
          status: String(formEditPromoTagging.status?.code)
        };

        const { error } = await supabase.from(TABLE.PROMO_TAGGING)
          .update({
            name: PromoTagging.name,
            description: PromoTagging.description,
            tag_metadata: PromoTagging.tag_metadata,
            valid_from: PromoTagging.valid_from,
            valid_to: PromoTagging.valid_to,
            status: PromoTagging.status
          })
          .eq('id', formEditPromoTagging.id);

        if (error) {
          navigate('/promo-tagging/create');
          showToastEditPromoTaggingFailed('Error Create New Promo Tagging');
          setIsLoading(false);
        }
        navigate('/promo-tagging');
        showToastEditPromoTaggingSuccesfully('Update Promo Tagging Succesfully');
        setIsLoading(false);
      }
    } else if (formEditPromoTagging.category?.code === 'keywordRecommendation') {
      const metadata: ITagMetadataKeywordRecommendation = {
        showDescription: Boolean(formEditPromoTagging.showDescription?.code),
        action: String(formEditPromoTagging.action?.code),
        url: String(formEditPromoTagging.url)
      };

      const PromoTagging: IKeywordRecommendation = {
        type: String(formEditPromoTagging.type?.code),
        name: String(formEditPromoTagging.name),
        description: String(formEditPromoTagging.description),
        tag_metadata: metadata,
        valid_from: String(formEditPromoTagging.valid_from),
        valid_to: String(formEditPromoTagging.valid_to),
        status: String(formEditPromoTagging.status?.code)
      };

      const { error } = await supabase.from(TABLE.PROMO_TAGGING)
        .update({
          name: PromoTagging.name,
          description: PromoTagging.description,
          tag_metadata: PromoTagging.tag_metadata,
          valid_from: PromoTagging.valid_from,
          valid_to: PromoTagging.valid_to,
          status: PromoTagging.status
        })
        .eq('id', formEditPromoTagging.id);

      if (error) {
        showToastEditPromoTaggingFailed('Error Update Promo Tagging');
      }
      navigate('/promo-tagging');
      showToastEditPromoTaggingSuccesfully('Update Promo Tagging Succesfully');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProductVariant();
    getStore();
  }, []);

  return {
    data: {
      isLoadingMerchant,
      isLoadingProduct,
      detailMerchant,
      detailProduct,
      selectedStreet,
      searchEnter,
      dataProductVariants,
      dataStores,
      validate,
      isLoading,
      visible,
      productVariant,
      store,
      toast,
      selected,
      formEditPromoTagging,
      optionCategoryTags,
      street,
      navigate,
      optionStreet,
      detailPromo
    },
    method: {
      setSelectedStreet,
      handleOnEnter,
      handleSearch,
      onSubmit,
      setVisible,
      setSelected,
      onChangeFormEditPromotagging,
      optionStreet,
      onSavePromoTaggingById,
      onCancel
    }
  };
};
