import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import IconImage from '@/assets/IconImage';
import IconUpload from '@/assets/IconUpload';
import IconCancel from '@/assets/IconCancel';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { Json } from '@/types/services/notification';
import { useCategorySpec, useSpecification } from '@/services/rest/specification';
import { useAllVariants, useCategoryVariants } from '@/services/rest/variant';
import {
  useAllAttributeSetById,
  useAllAttributeSets,
  useGetSpecificationByid,
  useGetVariantsByid
} from '@/services/rest/attributeSet';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { debounce } from 'lodash';
import { useFormik } from 'formik';
import { FileUpload, FileUploadFilesEvent } from 'primereact/fileupload';
import { schemaCreateCategory } from './validation';
import { storageUpload } from '@/utils/storage';

export interface ICategory {
  id: number;
  name: string;
  position: number;
  parent_id: number;
  status: string;
  slug: string;
  media_url: Json;
}

interface IParentID {
  id: number;
  name: string;
}

const initailFormik = {
  maxImageCategory: 0
};

export const useCustom = () => {
  const navigate = useNavigate();
  const chooseOptions = {
    icon: IconImage,
    iconOnly: true,
    className: 'custom-choose-btn p-button-info p-button-rounded p-button-outlined'
  };
  const uploadOptions = {
    icon: IconUpload,
    iconOnly: true,
    className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'
  };
  const cancelOptions = {
    icon: IconCancel,
    iconOnly: true,
    className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false);
  const [selectedParent, setSelectedParent] = useState<IParentID | null>();
  const [categoryName, setCategoryName] = useState<string>('');
  const [categoryDescription, setCategoryDescription] = useState<string>('');
  const [metaTitle, setMetaTitle] = useState<string>('');
  const [metaKeywords, setMetaKeywords] = useState<string | null>(null);
  const [metaDescription, setMetaDescription] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<File | null>(null);
  const [dataParentID, setDataParentID] = useState<IParentID[]>([]);
  const [urlKey, setUrlKey] = useState<string | null>(null);
  const [attributSetId, setAttributSetId] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [merchantName, setMerchantName] = useState<string>('');
  const [dataProduct, setDataProduct] = useState<EIProduct[]>([]);
  const [popUpDetail, setPopUpDetail] = useState<boolean>(false);
  const [popUpChangeCategory, setPopUpChangeCategory] = useState<boolean>(false);
  const [popUpChangeSucces, setPopUpChangeSucces] = useState<boolean>(false);
  const [filterModal, setFilterModal] = useState<boolean>(false);
  const [popUpChangeAll, setPopUpChangeAll] = useState<boolean>();
  const [popUpChangeFailed, setPopUpChangeFailed] = useState<boolean>(false);
  const [currentCategory, setCurrentCategory] = useState<IParentID>();
  const [seoPage, setSeoPage] = useState<boolean>(true);
  const [isFollowCategoryName, setIsFollowCategoryName] = useState<boolean>(true);
  const [displayCategory, setDisplayCategory] = useState<string>('');
  const [parentChangeAll, setParentChangeAll] = useState<number | null>(null);
  const [selectedChangeAllParent, setSelectedChangeAllParent] = useState<IParentID | null>();
  const [selectedAttributeSets, setSelectedAttributeSets] = useState<IAttributeSetsID | null>();
  const [rangeSpecification, setRangeSpecification] = useState<ISpecificationID[]>([]);
  const [rangeVariant, setRangeVariant] = useState<IVariantID[]>([]);
  const { data: attributSetbyId, isLoading: isLoadingSpec } = useAllAttributeSetById(attributSetId);
  const { data: variantsById, isLoading: isLoadingVariant } = useGetVariantsByid(attributSetbyId?.data?.variant_ids);
  const { data: attributSpecById } = useGetSpecificationByid(attributSetbyId?.data?.specification_ids);
  const [foundVariant, setFoundVariant] = useState<{ id: number | string, name?: string }[]>([]);
  const [foundSpec, setFoundSpec] = useState<{ id: number | string, name?: string }[]>([]);
  const [dataStatus, setDataStatus] = useState({ label: 'Active' });
  const fileUploadRef = useRef<FileUpload>(null);
  const [modalFilter, setModalFilter] = useState({
    idFrom: null,
    idTo: null,
    priceFrom: null,
    priceTo: null,
    specialPriceTo: null,
    specialPriceFrom: null,
    productName: '',
    sku: '',
    status: true
  });

  const [dataChangeCategory, setDataChangeCategory] = useState<IProducts>({
    id: '',
    name: '',
    sku: '',
    status: '',
    main_price: 0,
    merchantId: '',
    category_id: 0
  });
  const [idCreated, setIdCreated] = useState({ id: 0, parent_id: 0 });

  const toast = useRef<Toast>(null);

  const formik = useFormik({
    initialValues: initailFormik,
    validationSchema: schemaCreateCategory,
    onSubmit: () => {
      onSubmitCategory();
    }
  });

  const getAllParentId = async () => {
    const { data } = await supabase.from(TABLE.CATEGORIES).select('*').order('id', { ascending: true });

    if (data) {
      const dropdownOptions: IParentID[] = data?.map((category) => {
        if (category.parent_id !== null) {
          const parentCategory = data?.find((cat) => cat.id === category.parent_id);
          const item: IParentID = {
            id: category.id,
            name: `${parentCategory?.name} > ${category.name}`
          };
          return item;
        }
        const itemNoParent: IParentID = {
          id: category.id,
          name: `${category.name}`
        };
        return itemNoParent;
      });
      setDataParentID(dropdownOptions);
    }
  };
  interface ISpecificationID {
    id: number;
  }

  interface IVariantID {
    id: number;
  }

  interface IAttributeSetsID {
    id: number;
    name: string;
  }

  const addSpecification = useCallback(() => {
    const tempSpecification = { id: 0 };
    setRangeSpecification((prev) => [...prev, tempSpecification]);
  }, [rangeSpecification, setRangeSpecification]);

  const deleteSpecification = useCallback(
    (index: number) => {
      const arrayOfNumbers = rangeSpecification;
      arrayOfNumbers.splice(index, 1);
      setRangeSpecification([...arrayOfNumbers]);
    },
    [rangeSpecification, setRangeSpecification]
  );

  const addVariant = useCallback(() => {
    const tempVariant = { id: 0 };
    setRangeVariant((prev) => [...prev, tempVariant]);
  }, [rangeVariant, setRangeVariant]);

  const deleteVariant = useCallback(
    (index: number) => {
      const arrayOfNumbers = rangeVariant;
      arrayOfNumbers.splice(index, 1);
      setRangeVariant([...arrayOfNumbers]);
    },
    [rangeVariant, setRangeVariant]
  );

  const dataSpecs = useMemo(() => {
    const data = attributSpecById?.data?.map((item) => {
      return {
        id: item.id,
        name: String(item.name)
      };
    });
    return data;
  }, [attributSpecById]);

  const dataVariants = useMemo(() => {
    const data = variantsById?.data?.map((item) => {
      return {
        id: item.id,
        name: String(item.name)
      };
    });
    return data;
  }, [variantsById]);

  const validateDataAttributSet: Boolean = useMemo(() => {
    return Boolean(dataVariants && dataVariants?.length > 0) || Boolean(dataSpecs && dataSpecs?.length > 0);
  }, [dataVariants]);

  const { data: parentSpect } = useCategorySpec(idCreated.parent_id as number);
  const parentSpecification = useMemo(() => {
    if (!Array.isArray(parentSpect?.data)) return [];
    return parentSpect?.data.map((item)=>{
      return {
        id: item.specifications?.id,
        name: item.specifications?.name
      };
    });
  }, [parentSpect]);

  const { data: dataSpecification } = useSpecification();
  const specifications = useMemo(() => {
    if (!Array.isArray(dataSpecification?.data)) return [];
    return dataSpecification?.data.map((item) => {
      return {
        id: item?.id || '',
        name: item?.name || ''
      };
    });
  }, [dataSpecification]);

  const { data: parentVar } = useCategoryVariants(idCreated.parent_id as number);
  const parentVariant = useMemo(() => {
    if (!Array.isArray(parentVar?.data)) return [];
    return parentVar?.data.map((item)=>{
      return {
        id: item.variants?.id,
        name: item.variants?.name
      };
    });
  }, [parentVar]);

  const { data: dataVariant } = useAllVariants();
  const variants = useMemo(() => {
    if (!Array.isArray(dataVariant?.data)) return [];
    return dataVariant?.data.map((item) => {
      return {
        id: item?.id || '',
        name: item?.name || ''
      };
    });
  }, [dataVariant]);

  const { data: dataAttributeSets } = useAllAttributeSets();
  const attributeSets = useMemo(() => {
    if (!Array.isArray(dataAttributeSets?.data)) return [];
    return dataAttributeSets?.data.map((item) => {
      return {
        id: item?.id || '',
        name: item?.name || ''
      };
    });
  }, [dataAttributeSets]);

  // Handle search variant and specifications
  const findVariantDebounce = debounce(async (findName: string | null) => {
    if (!findName) {
      return;
    }

    const { data } = await supabase.from('catalogue.variants').select('id, name').ilike('name', `*${findName}*`).limit(10);

    if (data) {
      if (variants) {
        setFoundVariant((prev)=>{
          const newData = [...prev, ...data];
          const distinctData = [...new Set(newData.map((item)=> JSON.stringify(item)))];
          const parseDistinct = distinctData.map((item)=> JSON.parse(item));
          return parseDistinct;
        });
      } else {
        setFoundVariant(data);
      }
    }
  }, 300);

  const findVariant = async (findName: string | null) => {
    findVariantDebounce(findName);
  };

  const findSpecDebounce = debounce(async (findName: string | null) => {
    if (!findName) {
      return;
    }

    const { data } = await supabase.from('catalogue.specifications').select('id, name').is('deleted_at', null).ilike('name', `*${findName}*`).limit(10);

    if (data) {
      if (specifications) {
        setFoundSpec((prev)=>{
          const newData = [...prev, ...data];
          const distinctData = [...new Set(newData.map((item)=> JSON.stringify(item)))];
          const parseDistinct = distinctData.map((item)=> JSON.parse(item));
          return parseDistinct;
        });
      } else {
        setFoundSpec(data);
      }
    }
  }, 300);

  const findSpecificaiton = async (findName: string | null) => {
    findSpecDebounce(findName);
  };

  const handleAcceptDialog = useCallback(() => {
    setRangeSpecification([]);
    setRangeVariant([]);
    if (dataVariants) {
      setFoundVariant(dataVariants);
    }
    if (dataSpecs) {
      setFoundSpec(dataSpecs);
    }
    const variants: IVariantID[] =
      dataVariants?.map((item) => {
        return {
          id: Number(item?.id)
        };
      }) ?? [];
    setRangeVariant(variants);

    const specs: ISpecificationID[] =
      dataSpecs?.map((item) => {
        return {
          id: Number(item?.id)
        };
      }) ?? [];
    setRangeSpecification(specs);
  }, [dataVariants, dataSpecs, rangeSpecification, rangeVariant]);

  const handleClickApply = useCallback(() => {
    setVisible(true);
  }, []);

  const selectVariant = useCallback(
    (index: number, e: number) => {
      const temp = [...rangeVariant];
      const dataFilter = temp[index];
      dataFilter.id = e;
      temp.splice(index, 1, dataFilter);
      setRangeVariant(temp);
    },
    [rangeVariant, setRangeVariant]
  );

  const selectSpecification = useCallback(
    (index: number, e: number) => {
      const temp = [...rangeSpecification];
      const dataFilter = temp[index];
      dataFilter.id = e;
      temp.splice(index, 1, dataFilter);
      setRangeSpecification(temp);
    },
    [rangeSpecification, setRangeSpecification]
  );

  //toast
  const showToastCreateCategorySuccesfully = (msg: string) => {
    toast.current?.show({ severity: 'success', summary: 'Success', detail: msg });
  };

  const showToastCreateCategoryFailed = (msg: string) => {
    toast.current?.show({ severity: 'error', summary: 'Error', detail: msg });
  };
  const onSubmitCategory = useCallback(async () => {
    setIsLoading(true);

    if (selectedParent?.id === undefined && checked === false) {
      showToastCreateCategoryFailed('Parent category is not allowed to empty');
      setIsLoading(false);
      return false;
    }

    if (!categoryName) {
      showToastCreateCategoryFailed('missing file input');
      setIsLoading(false);
      return false;
    }

    if (!isFollowCategoryName && (!urlKey || urlKey === '')) {
      showToastCreateCategoryFailed('URL Key is required if not following category name');
      setIsLoading(false);
      return false;
    }

    if (!metaTitle) {
      showToastCreateCategoryFailed('meta title is not allowed to empty');
      setIsLoading(false);
      return false;
    }
    if (idCreated.id) {
      const payloadVariant = (rangeVariant as IVariantID[]).map((item)=> {
        return {
          variant_id: item.id,
          category_id: idCreated.id
        };
      });
      const payloadSpec = (rangeSpecification as ISpecificationID[]).map((item)=> {
        return {
          specification_id: item.id,
          category_id: idCreated.id
        };
      });
      const { error: errorCategoryVariant } = await supabase.from(TABLE.CATEGORY_VARIANTS).insert(payloadVariant);
      const { error: errorCategorySpesification } = await supabase.from(TABLE.CATEGORY_SPECIFICATION).insert(payloadSpec);
      if (errorCategoryVariant) {
        return showToastCreateCategoryFailed('Error Insert to Category Variant');
      }
      if (errorCategorySpesification) {
        return showToastCreateCategoryFailed('Error Insert to Category Specification');
      }
      showToastCreateCategorySuccesfully('New Category Created Succesfully');
      setTimeout(() => {
        navigate('/category');
      }, 3000);
      return;
    }

    const { data } = await supabase.from(TABLE.CATEGORIES).select('name, id').eq('name', categoryName).maybeSingle();

    if (data?.name === categoryName) {
      setIsLoading(false);
      showToastCreateCategoryFailed('Error Duplicate Category Name');
      return false;
    }

    if (imageUrl !== null) {
      const imageUpload = await storageUpload('web/', imageUrl);

      if (imageUpload === null) {
        showToastCreateCategoryFailed('failed upload image');
        onCreateCategory('');
      }

      onCreateCategory(imageUpload?.src || '');
    } else {
      onCreateCategory('');
    }
    fileUploadRef.current?.clear();
  }, [
    metaKeywords,
    metaDescription,
    urlKey,
    isFollowCategoryName,
    categoryName,
    categoryDescription,
    status,
    selectedParent,
    imageUrl,
    checked,
    metaTitle,
    rangeSpecification,
    rangeVariant,
    idCreated,
    fileUploadRef
  ]);

  const handleSelectedAttributeSets = useCallback((e: DropdownChangeEvent) => {
    setSelectedAttributeSets(e.value);
  }, []);

  const onCreateCategory = useCallback(
    async (urlImage: string) => {
      //get public url
      if (urlImage == '') {
        const query = supabase
          .from(TABLE.CATEGORIES)
          .insert({
            name: categoryName,
            description: categoryDescription,
            meta_title: metaTitle,
            images: {
              data: {
                image: urlImage
              }
            },
            meta_keyword: metaKeywords,
            meta_description: metaDescription,
            slug: urlKey?.replace(/\s/g, '-'),
            parent_id: checked === false ? selectedParent?.id : null,
            status: status ? 'ACTIVE' : 'INACTIVE'
          })
          .select('id, parent_id')
          .single();

        const { error, data } = await query;

        if (error) {
          showToastCreateCategoryFailed('Error Create New Category');
          return;
        }

        if (data) {
          setIdCreated({ id: data.id, parent_id: data.parent_id });
          showToastCreateCategorySuccesfully('New Category Created Succesfully');
          setIsLoading(false);
        }
      } else {
        const query = supabase
          .from(TABLE.CATEGORIES)
          .insert({
            name: categoryName,
            description: categoryDescription,
            parent_id: checked === false ? selectedParent?.id : null,
            meta_title: metaTitle,
            meta_keyword: metaKeywords,
            meta_description: metaDescription,
            slug: isFollowCategoryName ? null : urlKey,
            status: status ? 'ACTIVE' : 'INACTIVE',
            images: {
              data: {
                image: urlImage
              }
            }
          })
          .select('id, parent_id')
          .single();

        const { error, data: createCategory } = await query;

        if (error) {
          showToastCreateCategoryFailed('Error Create New Category');
          return;
        }

        if (createCategory) {
          setIdCreated({ id: createCategory.id, parent_id: createCategory.parent_id });
          showToastCreateCategorySuccesfully('Create New Category Succesfully');
          setIsLoading(false);
          onScrollTop();
        }
      }
    },
    [
      categoryName,
      categoryDescription,
      status,
      selectedParent,
      imageUrl,
      metaTitle,
      rangeSpecification,
      rangeVariant,
      idCreated,
      metaKeywords,
      metaDescription,
      urlKey
    ]
  );

  const handleFileUpload = useCallback(
    (e: FileUploadFilesEvent) => {
      const file = e.files[0];
      if (file.size <= 50000) {
        if (file.type.includes('image/')) {
          setImageUrl(file);
        } else {
          showToastCreateCategoryFailed('Selected File is not an Image type');
        }
      } else {
        fileUploadRef.current?.clear();
      }
    },
    [imageUrl, fileUploadRef]
  );

  useEffect(() => {
    if (checked === true) {
      setSelectedParent(null);
    }
  }, [checked, selectedParent]);

  interface IProducts {
    id: string;
    name: string;
    sku: string;
    status: string;
    main_price: number;
    merchantId: string;
    category_id: number;
  }
  interface EIProduct extends IProducts {
    specialPrice: number;
  }

  const getProducts = async () => {
    setIsLoading(true);
    let api = supabase
      .from(TABLE.PRODUCT)
      .select('*, product_variants(special_price, price, saleable_stock)')
      .eq('product_variants.is_primary_variant', true).limit(50)
      .eq('category_id', idCreated.id);

    if (modalFilter.productName) {
      api = api.ilike('name', `%${modalFilter.productName}%`);
    }

    if (modalFilter.idFrom || modalFilter.idTo) {
      api = api.gt('id', modalFilter.idFrom).lt('id', modalFilter.idTo);
    }

    if (modalFilter.priceFrom || modalFilter.priceTo) {
      api = api.gt('main_price', modalFilter.priceFrom).lt('main_price', modalFilter.priceTo);
    }

    if (modalFilter.specialPriceFrom || modalFilter.specialPriceTo) {
      api = api
        .gt('product_variants.special_price', modalFilter.specialPriceFrom)
        .lt('product_variants.special_price', modalFilter.specialPriceTo);
    }

    const { data, error } = await api;
    if (error) {
      setIsLoading(false);
      return;
    }

    const newData = data.map((item) => {
      return {
        id: item?.id,
        name: item?.name,
        sku: item?.sku,
        stok: item?.product_variants[0]?.saleable_stock,
        status: item?.status,
        main_price: item?.product_variants[0]?.price,
        merchantId: item.merchant_id,
        category_id: item.category_id,
        specialPrice: item.product_variants.map((item) => item.special_price)[0]
      };
    });
    setDataProduct(newData as EIProduct[]);
    setIsLoading(false);
  };

  const onActionProduct = useCallback(
    (value, funcName: string) => {
      if (funcName === 'onClickDetail') {
        setPopUpDetail(false);
      } else if (funcName === 'onClickChangeCategory') {
        setPopUpChangeCategory(true);
        setDataChangeCategory(value);
        const data = dataParentID;
        const current = data.filter((item) => item.id == value.category_id);
        setCurrentCategory(current[0]);
        getMerchantName(value.merchantId);
      }
    },
    [popUpDetail, popUpChangeCategory, dataChangeCategory, currentCategory, dataParentID, merchantName, dataProduct]
  );

  const onChangeAllParent = (e) => {
    setSelectedChangeAllParent(e.value);
  };

  const onChangeParent = useCallback(
    (e) => {
      setDataChangeCategory((prev) => {
        return {
          ...prev,
          category_id: e.id
        };
      });

      setCurrentCategory({ name: e.name, id: e.id });
    },
    [dataChangeCategory, currentCategory]
  );

  const getMerchantName = useCallback(
    async (merchantId: string) => {
      const { data } = await supabase.from(TABLE.MERCHANTS).select('name').eq('id', merchantId).limit(20);

      data?.map((item) => setMerchantName(item.name));
    },
    [merchantName]
  );

  const onSubmitChangeCategoryProduct = useCallback(async () => {
    const { error } = await supabase
      .from(TABLE.PRODUCT)
      .update({ category_id: currentCategory?.id })
      .eq('id', dataChangeCategory.id);
    if (error) {
      setPopUpChangeFailed(true);
    } else {
      getProducts();
      setPopUpChangeCategory(false);
      const info = `Produk ${dataChangeCategory.name} dengan sku ${dataChangeCategory.sku} dari ${merchantName} berhasil dipindah ke kategori ${currentCategory?.name}`;

      setMessage(info);
      setPopUpChangeSucces(true);
    }
  }, [dataChangeCategory, currentCategory, dataProduct]);

  const onChangeFilterValue = (e) => {
    setModalFilter((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const onChangeStatusFilter = (e) => {
    if (e.value.label === 'Active') {
      setModalFilter((prevState) => ({ ...prevState, status: true }));
      setDataStatus({ label: 'Active' });
    } else {
      setModalFilter((prevState) => ({ ...prevState, status: false }));
      setDataStatus({ label: 'Inactive' });
    }
  };

  const onSubmitFilterValue = async () => {
    setIsLoading(true);
    await getProducts();
    setFilterModal(false);
    const Filtered = `${modalFilter.productName ? 'Nama Produk' : ''}${modalFilter.sku ? 'SKU' : ''}${
      modalFilter.idFrom || modalFilter.idTo ? 'ID' : ''
    }${modalFilter.priceFrom || modalFilter.priceTo ? 'Harga' : ''}${
      modalFilter.specialPriceFrom || modalFilter.specialPriceTo ? 'Harga Spesial' : ''
    }`;
    setDisplayCategory(Filtered);
    setIsLoading(false);
  };

  useEffect(() => {
    getAllParentId();
    getProducts();
  }, []);

  useEffect(() => {
    if (selectedAttributeSets?.id) setAttributSetId(String(selectedAttributeSets?.id));
  }, [selectedAttributeSets]);


  const onScrollTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return {
    data: {
      visible,
      isLoadingSpec,
      dataSpecs,
      isLoadingVariant,
      validateDataAttributSet,
      dataVariants,
      chooseOptions,
      uploadOptions,
      cancelOptions,
      checked,
      status,
      selectedParent,
      categoryName,
      categoryDescription,
      isLoading,
      toast,
      imageUrl,
      dataParentID,
      dataProduct,
      popUpDetail,
      popUpChangeCategory,
      dataChangeCategory,
      currentCategory,
      merchantName,
      popUpChangeSucces,
      popUpChangeFailed,
      filterModal,
      popUpChangeAll,
      message,
      seoPage,
      urlKey,
      isFollowCategoryName,
      modalFilter,
      dataStatus,
      displayCategory,
      parentChangeAll,
      selectedChangeAllParent,
      specifications,
      variants,
      attributeSets,
      selectedAttributeSets,
      rangeSpecification,
      rangeVariant,
      metaTitle,
      metaDescription,
      metaKeywords,
      idCreated,
      parentVariant,
      parentSpecification,
      foundSpec,
      foundVariant,
      fileUploadRef,
      formik
    },
    methods: {
      setVisible,
      handleAcceptDialog,
      handleClickApply,
      handleSelectedAttributeSets,
      setSelectedParent,
      onSubmitCategory,
      setChecked,
      setStatus,
      setCategoryName,
      setCategoryDescription,
      handleFileUpload,
      onActionProduct,
      setPopUpDetail,
      setPopUpChangeCategory,
      onChangeParent,
      onSubmitChangeCategoryProduct,
      setPopUpChangeSucces,
      setPopUpChangeFailed,
      setFilterModal,
      setPopUpChangeAll,
      setSeoPage,
      setUrlKey,
      setIsFollowCategoryName,
      setModalFilter,
      onChangeFilterValue,
      onSubmitFilterValue,
      onChangeStatusFilter,
      setParentChangeAll,
      onChangeAllParent,
      setSelectedAttributeSets,
      addSpecification,
      addVariant,
      deleteSpecification,
      deleteVariant,
      selectSpecification,
      selectVariant,
      setMetaTitle,
      setMetaDescription,
      setMetaKeywords,
      findVariant,
      findSpecificaiton,
      onScrollTop
    }
  };
};
