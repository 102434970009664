import { useDetailMember } from '@/services/rest/userManagement';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

const useDetailMemberGroup = () => {
  const params = useParams();
  const { data: detailMemberGroup } = useDetailMember(Number(params.id), Number(params.memberId));

  const detailMember = useMemo(() => {
    const data = detailMemberGroup?.data;
    const status = data?.accounts?.status ? 'ACTIVE' : 'INACTIVE';
    return {
      id: data?.id,
      name: `${data?.accounts?.first_name || ''} ${data?.accounts?.last_name || ''}`,
      type: data?.accounts?.type,
      created: dayjs(data?.accounts?.created_at).format('DD MMMM YYYY'),
      status: status,
      email: data?.accounts?.email
    };
  }, [detailMemberGroup]);

  return {
    data: {
      params,
      detailMember
    }
  };
};

export default useDetailMemberGroup;
