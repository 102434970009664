import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { PostgrestMaybeSingleResponse } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Nullable } from 'primereact/ts-helpers';
import { UserGroup } from './helper';
import { IPagination } from '@/types/pagination';

type filter = {
  from: Nullable<string | Date | Date[]>;
  to: Nullable<string | Date | Date[]>;
};

export interface IFilterUserGroup {
  id_from: number | null,
  id_to: number | null,
  total_user_from: number | null,
  total_user_to: number | null,
  created_at: Date[] | null,
  group_name: string,
  status: string
}


const useQueryGroup = () => {
  const getGroupWithCount = async (search: string, selectedFilter: Array<string>, range: IPagination = { start: 0, end: 10 }, filter?: IFilterUserGroup | null) => {
    const query = supabase
      .from(TABLE.TEAM)
      .select('*, members(count)', { count: 'exact' })
      .eq('application_id', 1)
      .order('created_at', { ascending: false });

    if (filter?.total_user_from == null && filter?.total_user_to == null) {
      query.range(range.start, range.end);
    }

    if (filter) {
      // ID
      if (filter.id_from != null) {
        query.gte('id', filter.id_from);
      }

      if (filter.id_to != null) {
        query.lte('id', filter.id_to);
      }

      // Created At
      if (filter.created_at && filter.created_at?.length != 0) {
        const fromDate = dayjs(String(filter.created_at[0])).format('YYYY-MM-DD');
        query.gte('created_at:date', fromDate);

        if (filter.created_at.length > 1 && filter.created_at[1] != null) {
          const toDate = dayjs(String(filter.created_at[1])).format('YYYY-MM-DD');
          query.lte('created_at:date', toDate);
        }
      }

      // Status
      if (filter.status != '') {
        query.eq('status', (filter.status.toLowerCase() === 'active'));
      }

      // Group Name
      if (filter.group_name != '') {
        query.ilike('name', `%${filter.group_name}%`);
      }
    }

    if (selectedFilter.length > 0) {
      query.in('id', selectedFilter);
    }

    if (search != '') {
      query.ilike('name', `%${search}%`);
    }

    const { data: teams, error, count } = await query;

    return {
      teams,
      error,
      count
    };
  };

  const getMemberGroup = async (id: number, search: string, filters: Array<boolean>) => {
    const query = supabase.from(TABLE.MEMBER).select('*, accounts!inner(*)').eq('team_id', id);

    if (search) {
      query.or(`first_name.ilike.%${search}%,last_name.ilike.%${search}%`, { foreignTable: 'accounts' });
    }

    if (filters.length > 0) {
      query.in('accounts.status', filters);
    }

    const { data, error } = await query;

    return {
      data,
      error
    };
  };
  return {
    getGroupWithCount,
    getMemberGroup
  };
};


export const useQueryAdmin = () => {
  const getAdmin = async (status: Array<boolean>, type: Array<string>, date?: filter) => {
    const query = supabase
      .from(TABLE.ACCOUNTS)
      .select('*, members(teams(name))').limit(10)
      .order('created_at', { ascending: false });

    if (type?.length > 0) {
      query.in('type', type);
    }

    if (date?.from && date?.to) {
      const fromDate = dayjs(String(date?.from)).format('YYYY-MM-DD HH:mm:ss');
      const toDate = dayjs(String(date?.to)).format('YYYY-MM-DD HH:mm:ss');
      query.gte('created_at', fromDate).lte('created_at', toDate);
    }

    if (status?.length > 0) {
      query.in('status', status);
    }

    const { data: admin, error } = await query;

    return {
      admin,
      error
    };
  };

  return {
    getAdmin
  };
};


const getAdminOptionFilters = async () => {
  return (await supabase
    .from(TABLE.ACCOUNTS)
    .select('*, members(teams(name))').limit(10)
    .select('type'));
};

export const useAdminOptionFilters = () =>
  useQuery({
    queryKey: ['all-filter'],
    queryFn: getAdminOptionFilters
  });


export const getQueryAdmin = () => async (status: Array<boolean>, date?: filter) => {
  const query = supabase
    .from(TABLE.ACCOUNTS)
    .select('*, members(teams(name))').limit(10);

  if (status.length > 0) {
    query.in('status', status);
  }

  if (date?.from && date?.to) {
    const fromDate = dayjs(String(date?.from)).format('YYYY-MM-DD HH:mm:ss');
    const toDate = dayjs(String(date?.to)).format('YYYY-MM-DD HH:mm:ss');
    query.gte('created_at', fromDate).lte('created_at', toDate);
  }
  const { data: teams, error } = await query;

  return {
    teams,
    error
  };
};


export default useQueryGroup;

const getGroupOptionFilters = async () => {
  return (await supabase
    .from(TABLE.TEAM)
    .select('name,status,created_at, members(count)')
    .eq('application_id', 1)) as PostgrestMaybeSingleResponse<UserGroup[]>;
};

export const useGroupOptionFilters = () =>
  useQuery({
    queryKey: ['all-group-filter'],
    queryFn: getGroupOptionFilters
  });
