import { Column } from 'primereact/column';
import { DataTable, DataTableValue } from 'primereact/datatable';
import { FC, useState } from 'react';
import { useCustom } from '../CreateCategory/hooks';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
// import { useNavigate } from 'react-router-dom';

import { Button } from 'primereact/button';
import ChangeSucces from '../Components/ChangeSucces';
// import FilterModal from '../Components/FilterModal';
import PopUpChangeAll from '../Components/PopUpChangeAll';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { useParams } from 'react-router-dom';

type ITableTab = {
  value?: DataTableValue[];
  isLoading?: boolean
};

const ProductTab: FC<ITableTab> = ({ value, isLoading = false }) => {
  const { data, methods } = useCustom();
  const { detailId } = useParams();

  const [filters, setFilters] = useState<string>('');
  const header = [
    { field: 'id', header: 'ID' },
    { field: 'name', header: 'Product Name' },
    { field: 'sku', header: 'SKU' },
    { field: 'stok', header: 'Stock' },
    { field: 'status', header: 'Status' },
    { field: 'main_price', header: 'Price' },
    { field: 'specialPrice', header: 'Special Price' }
  ];
  const PaginationTemplate = {
    layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
        { label: 'All', value: options.totalRecords }
      ];

      const autoDisabled: boolean = options.totalRecords === 0;

      return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} disabled={autoDisabled} />;
    }
  };

  return (
    <>
      {isLoading ? <LoadingOverlay show /> : (
        <div>
          <div id="search" className=" mb-4 flex justify-end w-full">
            <div className="flex gap-3">
              <Button label="Bulk Change" disabled={Boolean(detailId)}
                onClick={() => methods.setPopUpChangeAll(true)} />
              <Button label="Filter" disabled={Boolean(detailId)}
                onClick={() => methods.setFilterModal(true)} />
            </div>
          </div>
          {data.displayCategory !== '' ? (
            <div id="detailFilter" className="border-y-2 border-black my-4 py-2">
              <p className="font-semibold">Filter Aktif : {data.displayCategory}</p>
            </div>
          ) : null}
          <div className="w-1/4 flex justify-start mb-6">
            <InputText
              className="w-full"
              placeholder="Search"
              type="search" disabled={Boolean(detailId)}
              onChange={(e) => setFilters(e.target.value)}
            />
          </div>
          <DataTable
            removableSort
            value={value}
            paginator
            rows={10}
            paginatorTemplate={PaginationTemplate}
            globalFilter={filters}
          >
            {header.map((item, index) => {
              return <Column sortable key={index} field={item.field} header={item.header} />;
            })}
            <Column
              header="Action"
              body={(rowData) => (
                <div>
                  <Dropdown
                    placeholder="Detail" disabled={Boolean(detailId)}
                    onChange={(e) => methods.onActionProduct(rowData, e.target.value)}
                    options={[
                      { label: 'Detail', value: 'onClickDetail' },
                      { label: 'Change Category', value: 'onClickChangeCategory' }
                    ]}
                    optionLabel="label"
                  />
                </div>
              )}
            />
          </DataTable>
          {data.popUpDetail && (
            <div className="bg-black bg-opacity-30 w-full h-screen fixed top-0 left-0 z-50 flex items-center justify-center">
              <div className="bg-white p-28 relative">
                <p>Detail Product</p>
                <button type="button" className="absolute right-3 top-3" onClick={() => methods.setPopUpDetail(false)}>
                  close
                </button>
              </div>
            </div>
          )}
          {/* pop up change category */}
          {data.popUpChangeCategory && (
            <div className="bg-black bg-opacity-30 w-full h-screen fixed top-0 left-0 z-50 flex items-center justify-center">
              <div className="bg-white p-8 relative w-3/5 rounded-lg z-51">
                <h4 className="mb-10 font-semibold">Change Category</h4>
                <div className="grid grid-cols-2 gap-10">
                  <p>
                    {data.dataChangeCategory?.name} - <span>{data.dataChangeCategory?.sku}</span>
                  </p>
                  <p>{data.merchantName}</p>
                  <h4 className="font-semibold">Current Category</h4>
                  <p>{data.currentCategory?.name}</p>
                  <h4 className="font-semibold">New Category</h4>
                  <div>
                    <Dropdown
                      value={data.selectedParent}
                      options={data.dataParentID}
                      onChange={(e) => {
                        methods.onChangeParent(e.target.value);
                      }}
                      optionLabel="name"
                      placeholder="Change New Category"
                      className="w-full"
                    />
                  </div>
                </div>
                <div className="my-5">
                  <Checkbox checked={false} inputId="notificate" />
                  <label htmlFor="notificate">Notify Merchant for this Category Change</label>
                </div>
                <div>
                  <SubmitButton
                    onCancel={() => methods.setPopUpChangeCategory(false)}
                    onSubmit={() => {
                      methods.onSubmitChangeCategoryProduct();
                    }}
                    labelBack="Cancel"
                    labelSubmit="Submit"
                  />
                </div>
              </div>
            </div>
          )}
          {data.popUpChangeSucces && (
            <div className="bg-black bg-opacity-30 w-full h-screen fixed top-0 left-0 z-50 flex items-center justify-center">
              <ChangeSucces message={data.message} onCloseButton={() => methods.setPopUpChangeSucces(false)} />
            </div>
          )}
          {
            data.popUpChangeFailed && (
              <div className="bg-black bg-opacity-30 w-full h-screen fixed top-0 left-0 z-50 flex items-center justify-center">
                <ChangeSucces message={`Oops, The product was not successfully moved.
                Please try again, if the problem persists,
                please contact your administrator.`} onCloseButton={() => methods.setPopUpChangeFailed(false)} />
              </div>
            )
          }
          {
            data.filterModal && (
              <div className="bg-black bg-opacity-30 w-screen h-screen fixed top-0 left-0 z-50 flex justify-center items-center">
                <div className="flex flex-col gap-y-3 bg-white w-4/5 relative p-10 z-50">
                  <h1 className="font-semibold">All Filter</h1>
                  <h1 className="font-semibold" >ID</h1>
                  <div className="flex items-center gap-4">
                    <label htmlFor="">From</label>
                    <InputText type="number" value={String(data.modalFilter.idFrom) || ''} name="idFrom" onChange={(e) => methods.onChangeFilterValue(e)} />
                    <label htmlFor="">To</label>
                    <InputText type="number" value={String(data.modalFilter.idTo) || ''} name="idTo" onChange={(e) => methods.onChangeFilterValue(e)} />
                  </div>
                  <h1 className="font-semibold">Price</h1>
                  <div className="flex items-center gap-4">
                    <label htmlFor="">From</label>
                    <InputText type="number" value={String(data.modalFilter.priceFrom) || ''} name="priceFrom" onChange={(e) => methods.onChangeFilterValue(e)} />
                    <label htmlFor="">To</label>
                    <InputText type="number" value={String(data.modalFilter.priceTo) || ''} name="priceTo" onChange={(e) => methods.onChangeFilterValue(e)} />
                  </div>
                  <h1 className="font-semibold">Special Price</h1>
                  <div className="flex items-center gap-4">
                    <label htmlFor="">From</label>
                    <InputText type="number" value={String(data.modalFilter.specialPriceFrom) || ''} name="specialPriceFrom" onChange={(e) => methods.onChangeFilterValue(e)} />
                    <label htmlFor="">To</label>
                    <InputText type="number" value={String(data.modalFilter.specialPriceTo) || ''} name="specialPriceTo" onChange={(e) => methods.onChangeFilterValue(e)} />
                  </div>
                  <div className="grid grid-cols-3">
                    <div>
                      <p className="font-semibold">Product Name</p>
                      <InputText value={data.modalFilter.productName} name="productName" onChange={(e) => methods.onChangeFilterValue(e)} />
                    </div>
                    <div>
                      <p className="font-semibold">SKU</p>
                      <InputText value={data.modalFilter.sku} name="sku" onChange={(e) => methods.onChangeFilterValue(e)} />
                    </div>
                    <div>
                      <p className="font-semibold">Status</p>
                      <Dropdown value={data.dataStatus} name="status" options={[{ label: 'Active' }, { label: 'Inactive' }]} optionLabel="label" onChange={(e) => methods.onChangeStatusFilter(e)} />
                    </div>
                  </div>
                  <SubmitButton
                    onCancel={() => {
                      methods.setFilterModal(false);
                    }}
                    onSubmit={() => {
                      methods.onSubmitFilterValue();
                    }}
                    labelBack="Batal"
                    labelSubmit="Terapkan"
                  />
                </div>
              </div >
            )
          }
          {
            data.popUpChangeAll && (
              <PopUpChangeAll disableOption={!data.selectedChangeAllParent} onChangeParent={methods.onChangeAllParent} options={data.dataParentID} value={data.selectedChangeAllParent ? data.selectedChangeAllParent : null} onClosePopUpFilter={() => methods.setPopUpChangeAll(false)} />
            )
          }
        </div>
      )}
    </>

  );
};

export default ProductTab;
