import {
  ArrowDownIcon,
  BlockButton,
  Checkbox,
  Dropdown,
  PlusIcon,
  Search,
  SystemIcon
} from 'akeneo-design-system';
import { SpecificationPagination } from './components/SpecificationPagination';
import { useCustom } from './hooks';
import { TableSpecification } from './components/TableSpecification';
import PopUpMessage from '@/components/base/PopUpMessage';
import './index.css';

const Specification = () => {
  const { data, methods } = useCustom();
  return (
    <div className="w-full px-6">
      {data.isError && <PopUpMessage type="failed" onClosePopUp={methods.handleClosePopUp} message="Delete Failed" />}
      <div className="w-full flex justify-end gap-2 relative">
        <div className="relative">
          <div
            className="flex gap-4 bg-orange-500 p-3 text-white cursor-pointer"
            onClick={methods.handleClickOpenListAdd}
          >
            <PlusIcon color="#ffff" size={24} /> | <ArrowDownIcon color="#ffff" size={24} />
          </div>
          {data.isOpenListAdd && (
            <Dropdown className="!bg-white !absolute top-full right-0 !z-10 !py-4">
              <div>
                <Dropdown.Header>
                  <Dropdown.Title>Tambah</Dropdown.Title>
                </Dropdown.Header>
                <Dropdown.ItemCollection>
                  <Dropdown.Item>Tambah Varian</Dropdown.Item>
                  <Dropdown.Item>Tambah Sekaligus</Dropdown.Item>
                  <Dropdown.Item>Ubah Sekaligus</Dropdown.Item>
                </Dropdown.ItemCollection>
              </div>
            </Dropdown>
          )}
        </div>
        <BlockButton
          className="!w-fit !flex-row-reverse !gap-6 !bg-gray-200 !font-bold !text-gray-800 !border-none !outline-none "
          icon={<SystemIcon color="#ff6500" size={28} />}
          onClick={methods.handleClickOpen}
        >
          Filter
        </BlockButton>
        {data.isOpen && (
          <Dropdown className="!absolute z-10 !right-0 !top-full !border py-4 !bg-white">
            <div>
              <Dropdown.Header>
                <Dropdown.Title>Filter</Dropdown.Title>
              </Dropdown.Header>
              <Dropdown.ItemCollection>
                <Dropdown.Section>Nama Spesifikasi</Dropdown.Section>
                {data.dropdownName?.map(
                  (item, index) =>
                    item !== null && (
                      <Dropdown.Item key={index} id={item} onClick={methods.handleClickDropdown}>
                        <Checkbox className="cursor-pointer" checked={data.listFilter.includes(item)} />
                        {item}
                      </Dropdown.Item>
                    )
                )}
                <Dropdown.Section>Label Spesifikasi</Dropdown.Section>
                {data.dropdownLabel?.map(
                  (item, index) =>
                    item !== null && (
                      <Dropdown.Item key={index} id={item} onClick={methods.handleClickDropdown}>
                        <Checkbox className="cursor-pointer" checked={data.listFilter.includes(item)} />
                        {item}
                      </Dropdown.Item>
                    )
                )}
                <Dropdown.Section>Deskripsi</Dropdown.Section>
                {data.dropdownDescription?.map(
                  (item, index) =>
                    item !== null && (
                      <Dropdown.Item key={index} id={item} onClick={methods.handleClickDropdown}>
                        <Checkbox className="cursor-pointer" checked={data.listFilter.includes(item)} />
                        {item}
                      </Dropdown.Item>
                    )
                )}
                <Dropdown.Section>Tipe Spesifikasi</Dropdown.Section>
                {data.dropdownType?.map(
                  (item, index) =>
                    item !== null && (
                      <Dropdown.Item key={index} id={item} onClick={methods.handleClickDropdown}>
                        <Checkbox className="cursor-pointer" checked={data.listFilter.includes(item)} />
                        {item}
                      </Dropdown.Item>
                    )
                )}
                <Dropdown.Section>Status</Dropdown.Section>
                {data.dropdownStatus?.map(
                  (item, index) =>
                    item !== null && (
                      <Dropdown.Item key={index} id={item} onClick={methods.handleClickDropdown}>
                        <Checkbox className="cursor-pointer" checked={data.listFilter.includes(item)} />
                        {item}
                      </Dropdown.Item>
                    )
                )}
              </Dropdown.ItemCollection>
            </div>
          </Dropdown>
        )}
      </div>
      {data.listFilter.length > 0 && (
        <div className="flex justify-start border-y border-black mt-10 px-2 py-4">
          <div>
            <span className="font-bold mr-2">Filter Aktif: </span>
            {data.listFilter.map((filter) => {
              if (String(data.listFilter.slice(-1)) === filter) {
                return filter;
              }
              return filter + ', ';
            })}
          </div>
        </div>
      )}

      <div className="mt-10 w-full flex justify-between items-center">
        <Search onSearchChange={methods.handleOnChange} searchValue={data.inputValue} placeholder="Search by id/name" />
        <SpecificationPagination
          totalPage={data.totalPage}
          perPage={String(data.perPage)}
          currentPage={String(data.currentPage)}
          handleClickNext={methods.handleClickNext}
          handleClickPrev={methods.handleClickPrev}
          handleSelect={methods.handleSelect}
          handleClickDropdown={methods.handleClickPaginationDropdown}
          isDropDownOpen={data.isOpenDropDown}
          handleOnChange={methods.handleOnChangePagination}
        />
      </div>

      <div className="w-full mt-10">
        <TableSpecification
          handleClickDelete={methods.handleClickDelete}
          data={data?.dataSpecification}
          isLoading={data?.isLoading}
          emptyMessage="Data not found"
        />
      </div>
    </div>
  );
};

export default Specification;
