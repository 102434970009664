import { Toast } from 'primereact/toast';
import { useRef, useState } from 'react';

const useCustom = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const toast = useRef<Toast>(null);

  const showError = (msg: string) => {
    toast.current?.show({ severity: 'error', summary: 'Error', detail: `${msg}`, life: 7000 });
  };

  return {
    data: {
      email,
      password,
      toast
    },
    method: {
      setEmail,
      setPassword,
      showError
    }
  };
};

export default useCustom;
