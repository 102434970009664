import * as yup from 'yup';
import { MESSAGE } from '@/constants';

const schemaCreatePromoOngkir = yup.object({
  promoName: yup.string().required(MESSAGE.REQUIRED),
  selectCourier: yup.array().min(1, MESSAGE.REQUIRED).required(MESSAGE.REQUIRED),
  selectService: yup.array().min(1, MESSAGE.REQUIRED).required(MESSAGE.REQUIRED),
  startDate: yup.string().required(MESSAGE.REQUIRED),
  endDate: yup.string().required(MESSAGE.REQUIRED),
  status: yup.boolean().required(MESSAGE.REQUIRED),
  promoQuota: yup.number().min(1, MESSAGE.REQUIRED).required(MESSAGE.REQUIRED),
  userUsage: yup.number().min(0, MESSAGE.REQUIRED).required(MESSAGE.REQUIRED),
  minOrder: yup.number().min(0, MESSAGE.REQUIRED).required(MESSAGE.REQUIRED),
  ammountDiscount: yup.number().required(MESSAGE.REQUIRED)
});

export { schemaCreatePromoOngkir };
