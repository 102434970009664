import React, { FC, ReactNode } from 'react';
import IconCancel from '@/assets/IconCancel';
import IconCheck from '@/assets/IconChecklist';
import IconUncheck from '@/assets/IconUnchek';

type IPopUpMessage = {
  children?: ReactNode;
  type: 'success' | 'failed';
  message?: string;
  onClosePopUp: () => void;
};

const PopUpMessage: FC<IPopUpMessage> = ({ type, onClosePopUp, message, children }) => {
  return (
    <div className="bg-black bg-opacity-30 w-full h-screen fixed top-0 left-0 z-50">
      <div className="absolute top-1/2 z-50 -translate-x-1/2 left-1/2 -translate-y-1/2">
        <div className="w-[360px] relative py-10 bg-white rounded-md border gap-6 flex flex-col justify-center items-center">
          {type === 'success' ? <IconCheck /> : <IconUncheck />}
          <span className="text-center">{message}</span>
          <div>{children}</div>
        </div>
        <div className="absolute top-4 right-4 cursor-pointer" onClick={onClosePopUp}>
          <IconCancel />
        </div>
      </div>
    </div>
  );
};

export default PopUpMessage;
