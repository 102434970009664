import React from 'react';
import useCustom from './hooks';
import { InputText } from 'primereact/inputtext';
import { Chips } from 'primereact/chips';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import { Toast } from 'primereact/toast';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputSwitch } from 'primereact/inputswitch';
import { usePermissionStore } from '@/store/usePermissions';

const CreateSpecification = () => {
  const { data, method } = useCustom();
  const getFormErrorMessage = (name) => {
    return method.isFormFieldValid(name) && <small className="p-error">{data.formik.errors[name]}</small>;
  };
  const { canEdit } = usePermissionStore();
  return (
    <>
      <LoadingOverlay show={false} />
      <BaseContainer>
        <MainTitle
          title={
            (data.params.id && 'Edit Specification') ||
            (data.params.detailId && 'Detail Specification') ||
            'Create Specification'
          }
          isWithButton={false}
        />
        <div id="form-create-specification" className="mt-10 flex flex-col gap-8">
          <div className="flex gap-8">
            <p className="font-medium w-44 mt-2" id="specification-name-label">
              Specification Name*
            </p>
            <div className="flex flex-col w-1/3">
              <InputText
                required
                id="specification-name-input"
                className="flex-1"
                placeholder="Input specification Name"
                value={data.formSpecification.name}
                onChange={method.onChangeInput('name')}
                disabled={Boolean(data.params.detailId)}
              />
              <div className="pt-1 pl-1">{getFormErrorMessage('name')}</div>
            </div>
          </div>
          <div className="flex gap-8">
            <p className="font-medium w-44 mt-2" id="specification-name-label">
              Specification Label*
            </p>
            <div className="flex flex-col w-1/3">
              <InputText
                required
                id="specification-name-input"
                className="flex-1"
                placeholder="Input specification Label"
                value={data.formSpecification.label}
                onChange={method.onChangeInput('label')}
                disabled={Boolean(data.params.detailId)}
              />
              <div className="pt-1 pl-1">{getFormErrorMessage('label')}</div>
            </div>
          </div>
          <div className="flex gap-8 items-center">
            <p className="font-medium w-44" id="specification-value-label">
              Description
            </p>
            <div className="p-fluid w-1/3">
              <InputTextarea
                value={data.formSpecification.description}
                rows={4} cols={20}
                placeholder="Input Description"
                onChange={Boolean(data.params.detailId)? ()=>{} : method.onChangeInput('description')}
              />
            </div>
          </div>
          <div className="flex gap-8">
            <p className="font-medium w-44 mt-2" id="specification-name-label">
              Specification Type*
            </p>
            <div className="flex flex-col w-1/3">
              <InputText
                required
                id="specification-name-input"
                className="flex-1"
                placeholder="Input specification Type"
                value={data.formSpecification.type}
                onChange={method.onChangeInput('type')}
                disabled={Boolean(data.params.detailId)}
              />
              <div className="pt-1 pl-1">{getFormErrorMessage('type')}</div>
            </div>
          </div>
          <div className="flex gap-8">
            <p className="font-medium w-44 mt-2" id="specification-name-label">
              Status*
            </p>
            <InputSwitch
              checked={data.formSpecification.is_active}
              disabled={Boolean(data.params.detailId)}
              onChange={method.onChangeToggle}
            />
          </div>
          <div className="flex gap-8 items-center">
            <p className="font-medium w-44" id="specification-value-label">
              Specification Value*
            </p>
            <div className="flex flex-col w-1/3">
              <div className="p-fluid flex-1">
                <Chips
                  separator="," allowDuplicate={false}
                  id="specification-value-input"
                  placeholder="Type Values Here Then Enter"
                  value={data.formSpecification.value}
                  onChange={method.onChangeChips}
                  disabled={Boolean(data.params.detailId)}
                />
              </div>
              <div className="pt-1 pl-1">{getFormErrorMessage('value')}</div>
            </div>
          </div>
          <div className="flex gap-8 items-center">
            <p className="font-medium w-44" id="specification-value-label">
              Input Instruction
            </p>
            <div className="flex flex-col w-1/3">
              <div className="p-fluid flex-1">
                <InputTextarea
                  autoResize
                  value={data.formSpecification.instruction}
                  placeholder="Input Charge Instruction" rows={8}
                  onChange={method.onChangeInput('instruction')}
                  disabled={Boolean(data.params.detailId)}
                />
              </div>
            </div>
          </div>
        </div>
        <Toast ref={data.toast} />
        <SubmitButton
          onSubmit={data.params.detailId ? method.handleCLickEdit : data.formik.handleSubmit}
          onCancel={method.handleCancelSubmit}
          labelBack="Cancel"
          labelSubmit={data.params.detailId ? 'Edit' : 'Submit'}
          disabledSubmit={data.params.detailId? !canEdit: false}
        />
      </BaseContainer>
    </>
  );
};

export default CreateSpecification;
