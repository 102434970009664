import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { IPagination } from '@/types/pagination';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

export type Banner = {
  created_at?: string | null;
  description: string | null;
  ended_time: string | null;
  id?: number;
  image: Array<Image>;
  name: string | null;
  started_time: string | null;
  status: string | null;
  type?: string | null;
  updated_at?: string | null;
  category_id?: string | null;
  product_id?: string | null;
  merchant_id?: string | null;
  promos_id?: string | null;
  url_path?: string | null;
  url_full?: string | null;
  banner_applied_streets?: BannerStreet[];
  size?:string | null;
};

type Image = {
  id: number;
  image: string;
  image_mobile?: string;
  thumbnail?: string;
};

export type BannerStreet = {
  id?: string;
  banner_id: string;
  street_id: string;
  streets?: Streets;
  status?: string;
};

type Streets = {
  id: string;
  name: string;
};

type PayloadPopularBrand = {
  banner_id: string;
  started_time: string | null;
  ended_time: string | null;
  merchant_id: string | null;
};

type OptionItem = {
  id: string;
  name: string;
};

export interface IFilter {
  type: Array<string>;
  status: Array<string>;
}

export interface IIdentity{
  productId?:string | null
  categoryId?:string | null
  promosId?:string | null
  merchantId?:string | null
  urlPath?:string | null
  urlFull?:string | null
  createdAt?:string | null
}

export interface IPayloadSoftDelete{
  id:number,
  identity:IIdentity
}

export interface IPayloadBrandBanner {
  banner_id: number;
  image: string;
  direct_to: string;
  product_id?: string;
  promos_id?: string;
  merchant_id?: string;
  url_full: string;
  type: string;
  sequence: number;
  tag_id?: string;
  started_time: string;
  ended_time: string;
  status: string;
}

export interface IBrandBanner extends IPayloadBrandBanner {
  id: number;
}

export interface IFilterBanner {
  id_from: number | null;
  id_to: number | null;
  name: string;
  created_at: Date[];
  type: string;
  status: string;
  street: string;
}

const getAllBanner = async (range: IPagination = { start: 0, end: 10 }, filter: IFilterBanner, search: string) => {
  const query = supabase
    .from(TABLE.BANNER)
    .select('*,banner_applied_streets(id,street_id,streets(id,name))', { count: 'exact' })
    .is('deleted_at', null)
    .not('type', 'in', `(${['PRODUCT_HOT_DEAL', 'BRAND_BANNER_LEFT', 'BRAND_BANNER_RIGHT'].join(',')})`)
    .order('id', { ascending: false })
    .range(range.start, range.end);

  // ID
  if (filter.id_from != null) {
    query.gte('id', filter.id_from);
  }

  if (filter.id_to != null) {
    query.lte('id', filter.id_to);
  }

  // Name
  if (filter.name != '' || search != '') {
    query.ilike('name', `%${(filter.name || search)}%`);
  }

  // Type
  if (filter.type != '') {
    query.eq('type', filter.type);
  }

  // Status
  if (filter.status != '') {
    query.eq('status', filter.status);
  }

  // Created at
  if (filter.created_at && filter.created_at?.length != 0) {
    const fromDate = dayjs(String(filter.created_at[0])).format('YYYY-MM-DD');
    query.gte('created_at:date', fromDate);

    if (filter.created_at.length > 1 && filter.created_at[1] != null) {
      const toDate = dayjs(String(filter.created_at[1])).format('YYYY-MM-DD');
      query.lte('created_at:date', toDate);
    }
  }

  return (await query) as PostgrestSingleResponse<Banner[]>;
};

export const insertBannerBrandId = async (id: PayloadPopularBrand[]) => {
  return await supabase.from(TABLE.POPULAR_BRAND).insert(id);
};

const softDeleteBannerById = async (id: Array<number>) => {
  return await supabase.from(TABLE.BANNER)
    .update({ 'deleted_at': dayjs().format('YYYY-MM-DD HH:mm:ssZ') })
    .in('id', id);
};

const softDeleteBrandBanner =async (payload:IPayloadSoftDelete) => {
  const brandBanner = ['BRAND_BANNER', 'BRAND_BANNER_LEFT', 'BRAND_BANNER_RIGHT'];
  const gte = payload.id - 3;
  const lte = payload.id + 3;
  const query = supabase
    .from(TABLE.BANNER)
    .select('id')
    .gte('id', gte)
    .lte('id', lte)
    .in('type', brandBanner);

  if (payload.identity.categoryId) {
    query.eq('category_id', payload.identity?.categoryId);
  }
  if (payload.identity.promosId) {
    query.eq('promos_id', payload.identity?.promosId);
  }
  if (payload.identity.productId) {
    query.eq('product_id', payload.identity?.productId);
  }
  if (payload.identity.promosId) {
    query.eq('promos_id', payload.identity?.promosId);
  }
  if (payload.identity.merchantId) {
    query.eq('merchant_id', payload.identity?.merchantId);
  }
  if (payload.identity.urlPath) {
    query.eq('url_path', payload.identity?.urlPath);
  }
  if (payload.identity.urlFull) {
    query.eq('url_full', payload.identity?.urlFull);
  }
  if (payload.identity.createdAt) {
    query.eq('created_at', payload.identity?.createdAt);
  }

  const { data } = await query;

  if (data) {
    return await supabase.from(TABLE.BANNER)
      .update({ 'deleted_at': dayjs().format('YYYY-MM-DD HH:mm:ssZ') })
      .in('id', data?.map((item)=>item.id));
  }
};

const deleteAppliedBannerStreet = async (id: string) => {
  return await supabase.from(TABLE.BANNER_APPLIED_STREET).delete().eq('banner_id', id);
};

const deletePopularBrandBanner = async (id: string) => {
  return await supabase.from(TABLE.POPULAR_BRAND).delete().eq('banner_id', id);
};

const getCategory = async () => {
  return await supabase.rpc('catalogue.get_list_categories');
};

export const getBannerById = async (id?: string) => {
  return (await supabase
    .from(TABLE.BANNER)
    .select('*,banner_applied_streets(id,street_id,streets(id,name))')
    .eq('id', id)
    .single()) as PostgrestSingleResponse<Banner>;
};

const getBrandBannerGroup = async (id?:number, identity?:IIdentity) => {
  if (id && identity) {
    const brandBanner = ['BRAND_BANNER', 'BRAND_BANNER_LEFT', 'BRAND_BANNER_RIGHT'];
    const gte = id - 3;
    const lte = id + 3;
    const query = supabase
      .from(TABLE.BANNER)
      .select('*')
      .gte('id', gte)
      .lte('id', lte)
      .in('type', brandBanner);

    if (identity.categoryId) {
      query.eq('category_id', identity?.categoryId);
    }
    if (identity.promosId) {
      query.eq('promos_id', identity?.promosId);
    }
    if (identity.productId) {
      query.eq('product_id', identity?.productId);
    }
    if (identity.promosId) {
      query.eq('promos_id', identity?.promosId);
    }
    if (identity.merchantId) {
      query.eq('merchant_id', identity?.merchantId);
    }
    if (identity.urlPath) {
      query.eq('url_path', identity?.urlPath);
    }
    if (identity.urlFull) {
      query.eq('url_full', identity?.urlFull);
    }
    if (identity.createdAt) {
      query.eq('created_at', identity?.createdAt);
    }

    return await query as PostgrestSingleResponse<Banner[]>;
  }
};

export const getAllBrandBanner = async (ids: number[]) => {
  return await (supabase
    .from(TABLE.BRAND_BANNER)
    .select('*')
    .in('banner_id', ids)) as PostgrestSingleResponse<IBrandBanner[]>;
};

const getPromos = async (status?: boolean) => {
  const query = supabase
    .from(TABLE.PROMOS)
    .select('id,name')
    .eq('type', 'landing_page')
    .eq('status', true)
    .order('created_at', { ascending: false })
    .range(0, 50);

  if (status) {
    query.eq('status', status);
  }

  return await query as PostgrestSingleResponse<OptionItem[]>;
};

const getMerchants = async () => {
  return (await supabase
    .from(TABLE.MERCHANTS)
    .select('id,name')
    .range(0, 10)
  ) as PostgrestSingleResponse<OptionItem[]>;
};

const getAllStreet = async () => {
  return (await supabase
    .from(TABLE.STREET)
    .select('*')
    .range(0, 10)
  ) as PostgrestSingleResponse<OptionItem[]>;
};

const getProduct = async () => {
  return (await supabase
    .from(TABLE.PRODUCT)
    .select('id,name')
    .range(0, 10)
  ) as PostgrestSingleResponse<OptionItem[]>;
};

export const useSoftDeleteBrandBanner = (refetchBanner?: () => void) =>
  useMutation({
    mutationKey: ['delete_brand_banner'],
    mutationFn: async (payload:IPayloadSoftDelete) => softDeleteBrandBanner(payload),
    onSuccess: refetchBanner
  });

export const upsertBanner = async (payload: Banner[]) => {
  return (await supabase
    .from(TABLE.BANNER)
    .upsert(payload)
    .select('*,banner_applied_streets(id)')) as PostgrestSingleResponse<Banner[]>;
};

export const upsertBrandBanner = async (payload: IBrandBanner[]) => {
  return (await supabase
    .from(TABLE.BRAND_BANNER)
    .upsert(payload));
};

export const insertBanner = async (payload: Banner[]) => {
  return (await supabase
    .from(TABLE.BANNER)
    .insert(payload)
    .select()) as PostgrestSingleResponse<Banner[]>;
};

export const insertBrandBanner = async (payload: IPayloadBrandBanner[]) => {
  return (await supabase
    .from(TABLE.BRAND_BANNER)
    .insert(payload)
    .select()) as PostgrestSingleResponse<Banner[]>;
};

export const insertBannerAppliedStreet = async (payload: BannerStreet[]) => {
  return await supabase.from(TABLE.BANNER_APPLIED_STREET).insert(payload);
};

export const upsertBannerAppliedStreet = async (payload: BannerStreet[]) => {
  return await supabase.from(TABLE.BANNER_APPLIED_STREET).upsert(payload);
};

export const useBanner = (range: IPagination, filter: IFilterBanner, search: string) =>
  useQuery({
    queryKey: ['banner', filter, range, search],
    queryFn: () => getAllBanner(range, filter, search),
    refetchOnWindowFocus: false
  });

export const usePromos = (status?: boolean) =>
  useQuery({
    queryKey: ['promo', status],
    queryFn: () => getPromos(status),
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });

export const useProduct = () =>
  useQuery({
    queryKey: ['product'],
    queryFn: getProduct,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });

export const useCategory = () =>
  useQuery({
    queryKey: ['category'],
    queryFn: getCategory,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });

export const useMerchatns = () =>
  useQuery({
    queryKey: ['merchants'],
    queryFn: getMerchants,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });

export const useBannerById = (id?: string) =>
  useQuery({
    queryKey: ['banner-id', id],
    queryFn: () => getBannerById(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });

export const useBrandBannerGroup = (id?:number, identity?:IIdentity) =>
  useQuery({
    queryKey: ['brand-banner', id, identity],
    queryFn: ()=> getBrandBannerGroup(id, identity),
    enabled: !!id && !!identity,
    refetchOnWindowFocus: false
  });

export const useStreet = () =>
  useQuery({
    queryKey: ['street'],
    queryFn: getAllStreet,
    refetchOnWindowFocus: false
  });

export const useSoftDeleteBanner = (refetchBanner?: () => void) =>
  useMutation({
    mutationKey: ['delete_banner'],
    mutationFn: async (id: Array<number>) => softDeleteBannerById(id),
    onSuccess: refetchBanner
  });

export const useDeletePopularBrand = (refetchBanner?: () => void) =>
  useMutation({
    mutationKey: ['delete_banner_populer'],
    mutationFn: async (id: string) => deletePopularBrandBanner(id),
    onSuccess: refetchBanner
  });

export const useDeleteBannerAppliedStreet = (refetchBanner?: () => void) =>
  useMutation({
    mutationKey: ['delete_banner_street'],
    mutationFn: async (id: string) => deleteAppliedBannerStreet(id),
    onSuccess: refetchBanner
  });

// export const useInsertBanner = (refetchBanner?: () => void) =>
//   useMutation({
//     mutationKey: ['inser-banner'],
//     mutationFn: (payload: Banner) => insertBanner(payload),
//     onSuccess: refetchBanner
//   });
