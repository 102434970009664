import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';

interface Data {
  field: string;
  header: string;
}
interface IProps {
  titleLabel: string;
  column: Data[],
  valueTable?: []
  textButton?: string
}

const TitleAndTable = ({ titleLabel, column, valueTable, textButton = 'Select' }: IProps) => {
  const [filter, setFilter] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [search, setSearch] = useState('');

  const onSearchFilter = (e) => {
    const value = e.target.value;
    const _filter = { ...filter };
    if (value) {
      // @ts-ignore
      _filter['global'].value = value;
    } else {
      _filter['global'].value = null;
    }

    setFilter(_filter);
    setSearch(value);
  };

  return (
    <BaseContainer>
      <label className="font-bold" id="title-label">
        {titleLabel}
      </label>
      <hr className="text-black mt-2 mb-4" />
      <InputText
        className="w-[300px] !my-5"
        id="customer-search-input"
        placeholder="Search"
        type="search"
        value={search}
        onChange={onSearchFilter}
        // onKeyDown={handleSearch}
      />
      <DataTable
        id="banner-table"
        value={valueTable as []}
        paginator
        rows={10}
        scrollable
        filters={filter}
        scrollHeight="36rem"
        showGridlines
        dataKey="id"
        tableStyle={{ minWidth: '12rem' }}
      >
        {column.map((column, idx) => {
          return (
            <Column key={idx} header={column.header} field={column.field} />
          );
        })}
        <Column
          header="Action"
          alignHeader="center"
          align="center"
          style={{ width: '6rem' }}
          body={(rowData) => (
            <div className="flex gap-2">
              <Button
                id="product-detail-button"
                outlined
                rounded
                text
                severity="secondary"
                onClick={() => {}}
              >
                {textButton}
              </Button>
            </div>
          )}
        />
      </DataTable>
    </BaseContainer>
  );
};

export default TitleAndTable;
