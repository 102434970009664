import React from 'react';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import useCustom from '../hooks';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { usePermissionStore } from '@/store/usePermissions';

const DetailAdmin = () => {
  const { data, method } = useCustom();
  const headerCreate = (
    <div id={data.formInput.editing? 'admin-edit-header' : 'admin-reset-password-header'} className="font-bold">{data.formInput.editing? 'Edit Admin Information' : 'Reset Password'}</div>
  );
  const footerCreate = (
    <div id="admin-edit-footer" className="grid grid-cols-2">
      {data.formInput.editing ?
        (<Button id="admin-cancle-btn" type="button" label="Cancel" onClick={() => method.formInputValue('editing', false)} severity="danger" outlined/>) :
        (<Button id="admin-cancle-btn" type="button" label="Cancel" onClick={() => method.formInputValue('reseting', false)} severity="danger" outlined/>)
      }
      <Button id="admin-add-btn" type="submit" label="Submit" onClick={() => data.formik.handleSubmit()} severity="danger"/>
    </div>
  );
  const getFormErrorMessage = (name) => {
    return method.isFormFieldValid(name) && <small className="p-error">{data.formik.errors[name]}</small>;
  };
  const { canEdit } = usePermissionStore();
  return (
    <BaseContainer>
      <MainTitle title="Detail Admin" nav="/user-admin" labelButton="Back" isWithButton/>
      <div className="card flex justify-content-center">
        <Dialog header={headerCreate} visible={(data.formInput.editing) || (data.formInput.reseting)}
          style={{ width: '35vw' }} onHide={() => {
            if (data.formInput.editing) {
              method.formInputValue('editing', false);
            };
            if (data.formInput.reseting) {
              method.formInputValue('reseting', false);
            }
          }} footer={footerCreate}>
          {data.formInput.editing &&
          <>
            <div className="py-2">
              <label className="pb-2 text-sm font-medium">Name*</label>
              <InputText
                id="admin-name-input" required name="adminName"
                value={data.formInput.name} className="w-full"
                onChange={(e) => {
                  data.formik.setFieldValue('adminName', e.target.value);
                  method.formInputValue('name', e.target.value);
                }} />
            </div><div className="pt-1 pl-1">
              {getFormErrorMessage('adminName')}
            </div><div className="py-2">
              <label className="py-2 text-sm font-medium">Email*</label>
              <InputText
                name="adminEmail" required
                className="w-full" disabled
                id="admin-email-input"
                value={data.formInput.email} />
            </div><div className="pt-1 pl-1">
              {getFormErrorMessage('adminEmail')}
            </div><div className="pt-1 pl-1">
              {getFormErrorMessage('adminPassword')}
            </div><div className="py-2">
              <label className="my-2 text-sm font-medium">Group*</label>
              <Dropdown name="adminGroup"
                options={data.teams} optionLabel="name" optionValue="id" className="w-full" id="admin-group-input"
                value={data.formInput.team_id} onChange={(e: DropdownChangeEvent) => {
                  data.formik.setFieldValue('adminGroup', e.target.value);
                  method.formInputValue('team_id', e.value);
                }} />
            </div><div className="pt-1 pl-1">
              {getFormErrorMessage('adminGroup')}
            </div><div className="py-2">
              <label className="my-2 text-xs font-medium">Account Type*</label>
              <Dropdown
                id="admin-type-input"
                name="adminType"
                className="w-full"
                options={data.accountTypeOptions}
                optionLabel="label"
                optionValue="code"
                value={data.formInput.type} onChange={(e: DropdownChangeEvent) => {
                  data.formik.setFieldValue('adminType', e.target.value);
                  method.formInputValue('type', e.value);
                }} />
            </div><div className="pt-1 pl-1">
              {getFormErrorMessage('adminType')}
            </div>
          </>
          }
          {data.formInput.reseting &&
            <div className="py-2">
              <label className="py-2 text-sm font-medium">Password*</label>
              <InputText
                name="adminPassword" required
                className="w-full"
                id="admin-password-input"
                value={data.formInput.password}
                onChange={(e) => {
                  data.formik.setFieldValue('adminPassword', e.target.value);
                  method.formInputValue('password', e.target.value);
                }}
              />
              <div className="pt-1 pl-1">
                {getFormErrorMessage('adminPassword')}
              </div>
            </div>
          }
        </Dialog>
      </div>
      <Toast ref={data.toast}/>
      <div className="p-6 mt-4 bg-white rounded-xl">
        <div className="flex px-2 justify-between items-center">
          <div className="w-1/4 flex gap-4 items-center">
            <div className="flex">
              <Avatar size="large" shape="circle" />
            </div>
            <div className="flex flex-col">
              <div className="font-semibold">{data.formInput.name}</div>
              <div className="text-sm">{data.accountTypeOptions.find((n) => n.code == data.formInput.type)?.label || '-'}</div>
            </div>
          </div>
          <Button label="Edit Profile" severity="danger" outlined className="h-10 w-1/5"
            onClick={() => method.formInputValue('editing', true)} disabled={!canEdit} />
        </div>
        <Divider />
        <div className="font-semibold px-2 pb-3">Users Data</div>
        <div className="w-full px-2 flex gap-3">
          <div className="flex flex-col w-1/2">
            <div className="text-slate-400">
              Name
            </div>
            <InputText
              name="admin-name-input" required
              value={data.formInput.name}
              className="w-full h-10" disabled
            />
          </div>
          <div className="flex flex-col w-1/2">
            <div className="text-slate-400">
              Email
            </div>
            <InputText
              name="admin-email-input" required
              value={data.formInput.email}
              className="w-full h-10" disabled
            />
          </div>
        </div>
        <div className="w-full px-2 py-3 flex flex-col">
          <div className="text-slate-400">
            Account Type
          </div>
          <InputText
            name="admin-account-type-input" required
            value={data.accountTypeOptions.find((n) => n.code == data.formInput.type)?.label || '-'}
            className="w-full h-10" disabled
          />
        </div>
        <Divider />
        <div className="font-semibold px-2 pb-3">Password</div>
        <Button disabled={!canEdit} label="Reset Password" className="h-10 w-1/5" onClick={()=> method.formInputValue('reseting', true)} outlined />
      </div>
    </BaseContainer>
  );
};

export default DetailAdmin;
