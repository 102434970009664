import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { IPagination } from '@/types/pagination';
import { PostgrestMaybeSingleResponse } from '@supabase/postgrest-js';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

type IFlashSaleSupabase = {
  id: number;
  product_id?: number;
  weight?: number;
  product_variant_id?: string;
  master_slot_id?: string;
  periode: IPeriode;
  name: string;
  description?: string;
  saleable_stock?: number;
  inflight_stock?: number;
  price_by?: string;
  price_value?: number;
  total_stock?: number;
  created_by?: string;
  updated_by?: string;
  created_at?: string;
  updated_at?: string;
}
type IPeriode = {
  date: String[];
  isRange: boolean;
  isExpired: boolean;
  isEnable: boolean;
}

export interface IFilterFlashsale {
  id_from: number | null;
  id_to: number | null;
  name: string;
  created_by: string;
  updated_by: string;
  created_at: Date[];
  updated_at: Date[];
  status: string;
}

export const getFlashSale = (range: { start: number, end: number } = { start: 0, end: 10 }, search?: string, filter?: IFilterFlashsale) => async () => {
  const query = supabase
    .from(TABLE.FLASHSALE)
    .select('id, name, created_by, updated_by, created_at, updated_at, periode, deleted_at', { count: 'exact' })
    .is('parent_id', null)
    .is('deleted_at', null)
    .order('created_at', { ascending: false })
    .range(range.start, range.end);

  if (filter) {
    // ID
    if (filter.id_from != null) {
      query.gte('id', filter.id_from);
    }

    if (filter.id_to != null) {
      query.lte('id', filter.id_to);
    }

    // Name
    if (filter.name != '') {
      query.ilike('name', `%${filter.name}%`);
    }

    // Created By
    if (filter.created_by != '') {
      query.ilike('created_by', `%${filter.created_by}%`);
    }

    // Updated By
    if (filter.updated_by != '') {
      query.ilike('updated_by', `%${filter.updated_by}%`);
    }

    // Created At
    if (filter.created_at && filter.created_at.length != 0) {
      const fromDate = dayjs(String(filter.created_at[0])).format('YYYY-MM-DD');
      query.gte('created_at:date', fromDate);

      if (filter.created_at.length > 1) {
        const toDate = dayjs(String(filter.created_at[1])).format('YYYY-MM-DD');
        query.lte('created_at:date', toDate);
      }
    }

    // Updated At
    if (filter.updated_at && filter.updated_at.length != 0) {
      const fromDate = dayjs(String(filter.updated_at[0])).format('YYYY-MM-DD');
      query.gte('updated_at:date', fromDate);

      if (filter.updated_at.length > 1) {
        const toDate = dayjs(String(filter.updated_at[1])).format('YYYY-MM-DD');
        query.lte('updated_at:date', toDate);
      }
    }

    // Status
    if (filter.status != '') {
      query.eq('periode->>isEnable', (filter.status === 'Enabled'));
    }
  }

  if (search != '') {
    query.or(`name.ilike.%${search}%, created_by.ilike.%${search}%, updated_by.ilike.%${search}%`);
  }

  return await query;
};

const getFlashSaleOption = async () => {
  const query = supabase
    .from(TABLE.FLASHSALE).select('name, id')
    .is('deleted_at', null)
    .is('parent_id', null)
    .order('created_at', { ascending: false })
    .not('name', 'is', null).limit(10);
  return await query as PostgrestMaybeSingleResponse<IFlashSaleSupabase[]>;
};

export const useFilterFlashSale = () =>
  useQuery({
    queryKey: ['filter-flash-sale'],
    queryFn: getFlashSaleOption,
    refetchOnWindowFocus: false
  });

export const useDataFlashSale = (range: IPagination = { start: 0, end: 10 }, search?: string, filter?: IFilterFlashsale) =>
  useQuery({
    queryKey: ['flash-sale', range, search, filter],
    queryFn: getFlashSale(range, search, filter),
    enabled: !!range,
    refetchOnWindowFocus: false
  });

export const useQueryAdmin = () => {
  const getFlashSale = async (status: Array<boolean>, type: Array<string>) => {
    const query = supabase
      .from(TABLE.FLASHSALE)
      .select('id, name, created_by, updated_by, created_at, updated_at, periode')
      .order('created_at', { ascending: false })
      .range(0, 10);

    if (type?.length > 0) {
      query.in('name', type);
    }

    if (status?.length > 0) {
      query.in('status', status);
    }

    const { data: flashSale, error } = await query;

    return {
      flashSale,
      error
    };
  };

  return {
    getFlashSale
  };
};

const deleteFlashSale = async (id: string[]) => {
  const { error } = await supabase
    .from(TABLE.FLASHSALE)
    .delete()
    .in('id', id);

  if (error) {
    throw error;
  }
};

export const useDeleteFlashSale = (refecthFlashSale?: () => void) =>
  useMutation({
    mutationKey: ['delete_flash_sale'],
    mutationFn: async (id:string[]) =>
      deleteFlashSale(id),
    onSuccess: refecthFlashSale
  });
