import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import useCustom from '../hooks';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import LoadingOverlay from '@/components/base/LoadingOverlay';

const CreateSlots = () => {
  const {
    data: {
      toast,
      optionsStatus,
      rangeTimeSlots,
      selectedStatus,
      formCreateSlots,
      formik,
      isLoading
    },
    methods: {
      navigate,
      deleteSlot,
      addMoreSlot,
      handleSetStatus,
      onChangeFormSlots,
      handleTimeSlotChange,
      isFormFieldValid
    }
  } = useCustom();

  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  return (
    <BaseContainer>
      <LoadingOverlay show={isLoading} />
      <MainTitle isWithButton={false} title="Master Slot" />
      <div className="border p-6">
        <div className="flex">
          <div className="w-1/4">
            <div id="slot-title1" className="border border-solid py-3 font-bold bg-gray-400 text-center">SLOT INFORMATION</div>
            <div id="slot-title2" className="border border-solid py-3 font-normal text-center">Slot Detail</div>
          </div>
          <div className="border p-10 w-full space-y-3">
            <div className="flex flex-col">
              <div id="slot-name-input" className="flex gap-[5.2rem] items-center">
                Slot Name*
                <InputText
                  id="slot-name-input"
                  className="w-2/5"
                  placeholder="Input Slot Name"
                  value={formCreateSlots.name}
                  onChange={(e) => {
                    formik.setFieldValue('name', e.target.value);
                    onChangeFormSlots('name', e.target.value);
                  }}
                />
              </div>
              <div id="slot-name-input" className="flex gap-[10rem] items-center">
                <div>&nbsp;</div>
                <div className="pt-1 pl-1">{getFormErrorMessage('name')}</div>
              </div>
            </div>
            <div className="flex flex-col">
              <div id="slot-description-input" className="flex gap-[5.2rem] items-center">
              Description
                <InputText
                  required
                  id="slot-description-input"
                  className="w-2/5"
                  name="desription"
                  placeholder="Input Slot Description"
                  value={formCreateSlots.description}
                  onChange={(e) => onChangeFormSlots('description', e.target.value)}
                />
              </div>
              <div id="slot-name-input" className="flex gap-[10rem] items-center">
                <div>&nbsp;</div>
              </div>
            </div>
            {rangeTimeSlots.map((timeSlots, index) => {
              return (<>
                <div className="flex flex-col">
                  <div key={index} className="flex w-full items-center gap-x-4">
                    {index == 0 ? <p className="flex w-[158px] gap-2">Select Slot Time*</p> : <div className="w-[158px] gap-2" />}
                    <div className="border border-solid flex items-center px-2 rounded-lg mr-3 w-[100px] h-[50px] bg-gray-400"> Slot {index + 1}</div>
                    <div id="slot-time-input" className="card flex flex-initial gap-2 p-fluid items-center">
                      <Calendar
                        className={timeSlots.started_time >= timeSlots.ended_time && 'p-invalid' || ''}
                        id="slot-started-time-input"
                        value={timeSlots.started_time}
                        onChange={(e: CalendarChangeEvent) => {
                          handleTimeSlotChange(index, 'started_time', e.value as Date);
                        }}
                        timeOnly
                        hideOnDateTimeSelect
                      />
                    -
                      <Calendar
                        className={timeSlots.started_time >= timeSlots.ended_time && 'p-invalid' || ''}
                        id="slot-ended-time-input"
                        value={timeSlots.ended_time}
                        onChange={(e: CalendarChangeEvent) => handleTimeSlotChange(index, 'ended_time', e.value as Date)}
                        timeOnly
                        hideOnDateTimeSelect
                      />
                    </div>
                    <Button disabled={rangeTimeSlots.length == 1} icon="pi pi-trash" id={`button-delete-slot-${index}`} onClick={()=>deleteSlot(index)} />
                    <div className="pt-1 pl-1">{getFormErrorMessage('slot_time')}</div>
                  </div>
                </div>
              </>);
            })}
            <div className="flex justify-content-start pl-5">
              <div className="w-[120px]" />
              <div className="flex gap-2 items-center justify-center">
                <div className="card">
                  <Button id="slot-add-button" label="+ Add More" severity="success" text onClick={addMoreSlot} />
                </div>
                <div id="slot-name-input" className="flex">
                  <div>&nbsp;</div>
                  <div className="pt-1 pl-1">{getFormErrorMessage('slot_time')}</div>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div id="slot-input-type" className="flex gap-[5.2rem] items-center">
              Status*
                <div className="card flex justify-content-center px-8 w-2/5">
                  <Dropdown
                    id="slot-input-type"
                    value={selectedStatus}
                    onChange={(e: DropdownChangeEvent) => {
                      formik.setFieldValue('status', e.value?.code);
                      handleSetStatus(e.value);
                    }}
                    options={optionsStatus}
                    optionLabel="name"
                    placeholder="Select a status"
                    className="w-full md:w-14rem"
                  />
                </div>
              </div>
              <div id="slot-name-input" className="flex gap-[10rem] items-center">
                <div>&nbsp;</div>
                <div className="pt-1 pl-1">{getFormErrorMessage('status')}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast ref={toast} />
      <SubmitButton
        disabledSubmit={isLoading}
        onSubmit={formik.handleSubmit}
        onCancel={() => {
          navigate('/master-slot');
        }}
        labelBack="Cancel"
        labelSubmit="Submit"
      />
    </BaseContainer>
  );
};

export default CreateSlots;
