import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { supabase } from '@/lib/supabase';
import { TABLE } from '@/constants';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import dayjs from 'dayjs';
import { IFilterPromoTag, PromoTagging, useGetPromoTaggings } from '@/services/rest/promoTagging';
import { debounce, uniqBy } from 'lodash';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { useHistoryStore } from '@/store/useHistoryStore';
import { IFilterHistoryItems } from '@/components/base/FilterHistory';
import { useDebounce } from 'primereact/hooks';

// HEADER TABLE
export const header = [
  { field: 'name', header: 'Promo Tagging Name' },
  { field: 'type', header: 'Category Tagging' },
  { field: 'product', header: 'Product' },
  { field: 'store', header: 'Store' },
  { field: 'valid_from', header: 'Valid From' },
  { field: 'valid_to', header: 'Valid To' },
  { field: 'status', header: 'Status' }
];
interface IOptions {
  label: string;
  items: Array<IOptionItems>;
}

interface IOptionItems {
  label: string;
  name: string;
  value: string | boolean;
}

export const useCustom = () => {
  const initialFilter: IFilterPromoTag = {
    name: '',
    type: '',
    product: '',
    store: '',
    valid_date: [],
    status: ''
  };

  const toast = useRef<Toast>(null);
  const [dataOptionName, setDataOptionName] = useState<{ id: number, name: string }[]>([]);
  const [perPage, setPerPage] = useState<number>(10);
  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const currentPage = parseInt(visitedPage.promoTagging.toString()) ?? 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : perPage - 1;
  const [paginator, setPaginator] = useState({ currentPage, range: { start, end } });
  const [jumpToPage, setJumpToPage] = useState<number>(1);
  const [itemFilters, setItemFilters] = useState<IFilterPromoTag>(lastFilterPage.promoTagging != '' ? JSON.parse(String(lastFilterPage.promoTagging)) : initialFilter);
  const [filters, setFilters] = useState<IFilterPromoTag>(lastFilterPage.promoTagging != '' ? JSON.parse(String(lastFilterPage.promoTagging)) : initialFilter);
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);
  const [search, debounceSearch, setSearch] = useDebounce('', 1500);

  const { data: dataPromoTagging, refetch: refetchPromoTaggings, isLoading } = useGetPromoTaggings(filters, paginator.range, debounceSearch);

  const totalRecords = useMemo(() => {
    return dataPromoTagging?.count ? dataPromoTagging?.count : 0;
  }, [dataPromoTagging]);

  const totalPages = useMemo(() => {
    return Math.ceil(totalRecords / perPage);
  }, [totalRecords, perPage]);

  const findPromoTagsDebounce = debounce(async (findName: string | null) => {
    if (!findName) return;

    const { data } = await supabase.from(TABLE.PROMO_TAGGING).select('id, name').ilike('name', `%${findName}%`).limit(10);

    if (data && data.length > 0) {
      const concatData = uniqBy([...dataOptionName, ...data], 'id');
      setDataOptionName(concatData);
    }
  }, 300);

  const findPromoTags = async (findName: string | null) => {
    findPromoTagsDebounce(findName);
  };

  const optionsFilter = useMemo((): IOptions[] => {
    const filterName =
      dataOptionName?.map((item) => ({
        label: item.name,
        name: 'name',
        value: item.name
      })) || [];
    return [
      {
        label: 'Name',
        items: filterName
      },
      {
        label: 'Category Tagging',
        items: [
          {
            label: 'New Product',
            name: 'type',
            value: 'newProduct'
          },
          {
            label: 'PLP',
            name: 'type',
            value: 'plp'
          },
          {
            label: 'Landing Page',
            name: 'type',
            value: 'landingPage'
          },
          {
            label: 'Store Recommendation',
            name: 'type',
            value: 'storeRecommendation'
          },
          {
            label: 'Top Keyword List',
            name: 'type',
            value: 'topKeywordList'
          }
        ]
      },
      {
        label: 'Status',
        items: [
          {
            label: 'Enabled',
            name: 'status',
            value: 'ACTIVE'
          },
          {
            label: 'Disabled',
            name: 'status',
            value: 'INACTIVE'
          }
        ]
      }
    ];
  }, [dataOptionName]);

  const getPromoTaggings = useMemo(() => {
    const data = dataPromoTagging?.data as PromoTagging[];
    const promoTaggings = data?.map((item) => {
      if (item.type == 'newProduct') {
        if (Array.isArray(item?.tag_metadata?.product)) {
          const result = item?.tag_metadata?.product.map((i) => i.product);
          const metadata: string = result?.join(', ') || '';
          const stat = item.status;
          return {
            ...item,
            id: item.id,
            name: item.name || '-',
            type: 'New Product',
            product: metadata,
            store: '-',
            valid_from: dayjs(item.valid_from).format('YYYY-MM-DD') || '-',
            valid_to: dayjs(item.valid_to).format('YYYY-MM-DD') || '-',
            status: stat
          };
        }
      }
      if (item.type == 'storeRecommendation') {
        if (Array.isArray(item?.tag_metadata?.store)) {
          const result: string[] = item?.tag_metadata?.store.map((i) => i.store);
          const metadata: string = result.join(', ');
          const stat = item.status;
          return {
            ...item,
            id: item.id,
            name: item.name || '-',
            type: 'Store Recommendation',
            product: '-',
            store: metadata,
            valid_from: dayjs(item.valid_from).format('YYYY-MM-DD') || '-',
            valid_to: dayjs(item.valid_to).format('YYYY-MM-DD') || '-',
            status: stat
          };
        }
      }
      if (item.type == 'topKeywordList') {
        const stat = item.status;
        return {
          ...item,
          id: item.id,
          name: item.name || '-',
          type: 'Top Keyword List',
          product: '-',
          store: '-',
          valid_from: dayjs(item.valid_from).format('YYYY-MM-DD'),
          valid_to: dayjs(item.valid_to).format('YYYY-MM-DD'),
          status: stat
        };
      }
      if (item.type == 'plp') {
        const stat = item.status;
        return {
          ...item,
          id: item.id,
          name: item.name,
          type: 'PLP',
          product: '-',
          store: '-',
          valid_from: dayjs(item.valid_from).format('YYYY-MM-DD'),
          valid_to: dayjs(item.valid_to).format('YYYY-MM-DD'),
          status: stat
        };
      }
      const stat = item.status;
      return {
        ...item,
        id: item.id,
        name: item.name,
        type: 'Landing Page',
        product: '-',
        store: '-',
        valid_from: dayjs(item.valid_from).format('YYYY-MM-DD'),
        valid_to: dayjs(item.valid_to).format('YYYY-MM-DD'),
        status: stat
      };
    });

    return promoTaggings;
  }, [dataPromoTagging]);

  // DELETE PROMO TAGS BY ID
  const deletePromoTagging = useCallback(async (id: string) => {
    const { data: productPromoTag } = await supabase.rpc('catalogue.delete_action', {
      'table_name': TABLE.PRODUCT_VARIANT_PROMO_TAGS.split('.').splice(1, 1)[0], 'column_name': 'promo_tag_id', 'val': [String(id)]
    });
    if (!productPromoTag[0].success) {
      return showError('Gagal Menghapus Promo Tag');
    }

    const { data: promoTag } = await supabase.rpc('catalogue.delete_action', {
      'table_name': TABLE.PROMO_TAGGING.split('.').splice(1, 1)[0], 'column_name': 'id', 'val': [String(id)]
    });
    if (!promoTag[0].success) {
      return showError('Gagal Menghapus Promo Tag');
    }
    showSuccess();
    refetchPromoTaggings();
  }, []);

  const showError = (msg: string) => {
    toast.current?.show({ severity: 'error', summary: 'Error', detail: `${msg}`, life: 3000 });
  };

  const showSuccess = () => {
    toast.current?.show({
      severity: 'success',
      summary: 'Confirmed',
      detail: 'Promo tagging deleted succesfully',
      life: 3000
    });
  };

  const onDeletePromoTagging = (id: string) => {
    confirmDialog({
      message: 'Are you sure you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept() {
        deletePromoTagging(id);
      }
    });
  };

  const handleClearFilter = useCallback(() => {
    setLastFilterPage({
      ...lastFilterPage,
      promoTagging: ''
    });
    setItemFilters(initialFilter);
    setFilters(initialFilter);
    setFilterHistory([]);
    setSearch('');
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [lastFilterPage, perPage]);

  const handleDeleteFilterHistory = useCallback(
    (key: string, value: string[]) => {
      const items = value[0].split(',');
      items.forEach((i) => {
        setFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));

        setItemFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));
      });

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setSearch('');
    },
    []
  );

  const handleSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>)=>{
    setSearch(currentTarget.value);
    const newKeyword = {
      name: 'Keyword',
      items: [{
        label: currentTarget.value,
        value: currentTarget.value,
        name: currentTarget.value
      }]
    };

    if (currentTarget.value != '') {
      setFilterHistory((prev: IFilterHistoryItems[]) => {
        const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
        if (existingIndex !== -1) {
          prev[existingIndex] = newKeyword;
        } else {
          prev.push(newKeyword);
        }
        return [...prev];
      });

      setPaginator({
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      });
    } else {
      handleDeleteFilterHistory('Keyword', ['Keyword']);
    }
  }, [perPage]);

  const handleFilter = useCallback((e, field: string) => {
    setItemFilters((prev) => ({
      ...prev,
      [field]: e.target ? e.target.value : e.value
    }));
  }, []);

  const handleClickSubmitFilter = useCallback(() => {
    setFilters(itemFilters);
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [itemFilters]);

  const storeFilterHistory = useCallback((filter: IFilterPromoTag) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter?.valid_date && filter.valid_date[0]) {
      let filterDate = `${dayjs(filter.valid_date[0]).format('YYYY-MM-DD')}`;
      if (filter.valid_date.length > 1 && filter.valid_date[1] !== null) {
        filterDate = `${dayjs(filter.valid_date[0]).format('YYYY-MM-DD')} - ${dayjs(filter.valid_date[1]).format('YYYY-MM-DD')}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Customer Since', 'valid_date', filterDate));
    }

    if (filter.name !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Name', 'name', filter.name));
    }

    if (filter.product !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Product', 'product', filter.product));
    }

    if (filter.store !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Store', 'store', filter.store));
    }

    if (filter.type !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Type', 'type', filter.type));
    }

    if (filter.status !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Status', 'status', filter.status));
    }

    setFilterHistory(filterHistoryItems);
  }, []);

  // Pagination
  const handleClickPage = useCallback((page:number)=>()=>{
    setPaginator((prev) => ({
      ...prev,
      currentPage: page,
      range: {
        start: page === 1 ? page - 1 : perPage * page - perPage,
        end: page * perPage - 1
      }
    }));
  }, [perPage]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent)=>{
    setPerPage(event.value);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleClickNext = useCallback(()=>{
    paginator.currentPage <= totalPages &&
    setPaginator((prev) => ({
      ...prev,
      currentPage: paginator.currentPage + 1,
      range: {
        start: paginator.range.start + perPage,
        end: paginator.range.end + perPage
      }
    }));
  }, [paginator, totalPages, perPage]);

  const handleClickPrev = useCallback(()=>{
    setPaginator((prev) => ({
      ...prev,
      currentPage: prev.currentPage - 1,
      range: {
        start: prev.range.start - perPage,
        end: prev.range.end - perPage
      }
    }));
  }, [paginator, perPage]);

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, totalRecords - 1);

      setPaginator(() => ({
        currentPage: jumpToPage > totalPages ? totalPages : value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, totalRecords]);

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  useEffect(() => {
    // update store visitedPage with latest page
    setVisitedPage({
      ...visitedPage,
      promoTagging: paginator.currentPage
    });
  }, [paginator.currentPage]);

  // update store lastFilterPage with latest filter
  useEffect(() => {
    setLastFilterPage({
      ...lastFilterPage,
      promoTagging: JSON.stringify(filters)
    });
  }, [filters]);

  return {
    data: {
      getPromoTaggings,
      optionsFilter,
      header,
      toast,
      isLoading,
      filterHistory,
      perPage,
      totalPages,
      paginator,
      totalRecords,
      itemFilters,
      search
    },
    methods: {
      onDeletePromoTagging,
      handleDeleteFilterHistory,
      handleClearFilter,
      findPromoTags,
      handleClickNext,
      handleClickPage,
      handleClickPrev,
      handleChangeDropdownPage,
      handleChangeJumpTopage,
      handleJumpToPage,
      handleSearch,
      handleFilter,
      handleClickSubmitFilter
    }
  };
};
