import { supabase } from '@/lib/supabase';
import { IParentLayout, Json } from '@/services/rest/pageBuilder';
import { useCreateProductTabulation } from '@/services/rest/pageBuilder';
import { storageUpload } from '@/utils/storage';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { ColorPickerHSBType, ColorPickerRGBType } from 'primereact/colorpicker';
import { Toast } from 'primereact/toast';
import { Nullable } from 'primereact/ts-helpers';
import { ChangeEvent, SyntheticEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface IStreetItem {
  id: number;
  title: string
  isDisabled: boolean;
  sectionValue: string;
  attribute?: Json
  slot: string;
  images?: Json;
  isMobile: boolean
  color?: Nullable<string | ColorPickerRGBType | ColorPickerHSBType>
  marginTop?: string;
  marginBottom?: string
  display?: string;
}

interface IAttribute {
  display: string;
  marginTop: string;
  marginBottom: string;
}

const initialStateAttributeStyle = {
  display: '',
  marginTop: '',
  marginBottom: ''
};

const initialStateTitleType = { name: 'Icon', code: 'ICON' };

export const useCustom = () => {
  const navigation = useNavigate();

  const toast = useRef<Toast>(null);
  const [title, setTitle] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<string>('');
  const [iconName, setIconName] = useState<string>('');
  const [activeIndex, setActiveIndex] = useState<number>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [sectionText, setSectionText] = useState<string>('');
  const [streetItems, setStreetItems] = useState<IStreetItem[]>([]);
  const [selectedSection, setSelectedSection] = useState<null | Record<string, string>>(null);
  const [color, setColor] = useState<Nullable<string | ColorPickerRGBType | ColorPickerHSBType>>(null);
  const [selectedTileType, setSelectedTileType] = useState<null | Record<string, string>>(initialStateTitleType);
  const [attributeStyle, setAttributeStyle] = useState<IAttribute>(initialStateAttributeStyle);
  const [productTabulationItems, setProductTabulationItems] = useState<{ id: number; title: string, isSelected: boolean }[]>([
    {
      id: 1,
      title: '',
      isSelected: true
    }
  ]);

  const sections = useMemo(() => {
    return [
      {
        name: 'Street',
        code: 'street',
        type: 'street'
      },
      {
        name: 'Banner',
        code: 'mainBanner',
        subSection: [
          { name: 'Main Banner', code: 'mainBanner', type: 'banner' },
          { name: 'Brand Banner', code: 'brandBanner', type: 'banner' },
          { name: 'Ads Banner Slider', code: 'adsBannerSlider', type: 'banner' },
          { name: 'Promo Banner Ads 1', code: 'promoBannerAds1', type: 'banner' }
        ]
      },
      {
        name: 'Product Tabulation',
        code: 'productTabulation',
        type: 'product-tabulation'
      },
      {
        name: 'Product Pilihan',
        code: 'curatedProduct'
      },
      {
        name: 'Video',
        code: 'video'
      },
      {
        name: 'Article',
        code: 'article'
      },
      {
        name: 'Flash Sale',
        code: 'flashSale'
      },
      {
        name: 'Official Store',
        code: 'officialStore'
      },
      {
        name: 'Hot Deals',
        code: 'hotDeals'
      },
      {
        name: 'Popular Brand',
        code: 'popularBrand'
      }
    ];
  }, []);

  const handleResetTabulationTabs = useCallback(() => {
    setProductTabulationItems([
      {
        id: 1,
        title: '',
        isSelected: true
      }
    ]);
    handleResetSelectSection();
  }, []);

  const handleResetSelectSection = useCallback(() => {
    setSelectedSection(null);
    setTitle('');
    setSelectedTileType(initialStateTitleType);
  }, []);

  // create product tabulation
  const { mutate: createProductTabulation } = useCreateProductTabulation();

  const tileType = [
    { name: 'Icon', code: 'ICON' },
    { name: 'Text', code: 'TEXT' }
  ];

  // reset attribute style
  const handleResetAttributeStyle = useCallback(() => {
    setAttributeStyle(initialStateAttributeStyle);
  }, []);

  // reset sectionText
  const handleResetSectionText = useCallback(() => {
    setSectionText('');
  }, []);

  const showSuccess = (msg: string) => {
    if (toast.current != null) {
      toast.current.show({
        severity: 'success',
        summary: `${msg}`,
        detail: `Page Builder is ${msg}`,
        life: 1500
      });
      setTimeout(() => {
        navigation('/frontstore-management/page-builder');
      }, 2500);
    }
  };

  const showFailed = (msg: string) => {
    if (toast.current != null) {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `Failed to Create because ${msg}`,
        life: 1500
      });
    }
  };

  const onChangeImage = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (event?.currentTarget?.files) {
        const dataImage = event?.currentTarget?.files[0];
        const fileName = dataImage.name;
        const extension = fileName.split('.').pop();
        if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
          // upload image to bucket
          const responsUploadPhoto = await storageUpload('web/', dataImage);

          if (responsUploadPhoto) {
            setImageUrl(responsUploadPhoto.src);
            setIconName(responsUploadPhoto.label);
          }
        }
      }
    },
    []
  );

  const addSection = useCallback(() => {
    if (selectedSection?.type === 'street') {
      setStreetItems(
        (prevState) => [
          ...prevState,
          {
            id: (prevState[prevState.length - 1]?.id || 0) + 1,
            slot: 'street',
            title: title,
            isDisabled: false,
            isMobile: false,
            sectionValue: title,
            color: color,
            marginTop: attributeStyle.marginTop,
            marginBottom: attributeStyle.marginBottom,
            display: attributeStyle.display
          }
        ]
      );
    } else {
      setStreetItems(
        (prevState) => [
          ...prevState,
          {
            id: (prevState[prevState.length - 1]?.id || 0) + 1,
            title: sectionText,
            isDisabled: false,
            slot: selectedSection?.code || '',
            sectionValue: sectionText,
            isMobile: false,
            images: '',
            marginTop: attributeStyle.marginTop,
            marginBottom: attributeStyle.marginBottom,
            display: attributeStyle.display
          }
        ]
      );
    }
    handleResetSectionText();
    handleResetSelectSection();
    handleResetAttributeStyle();
  }, [setStreetItems, sectionText, selectedSection, title, imageUrl, color, attributeStyle]);

  const deleteSection = useCallback(
    (index: number) => (event: SyntheticEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setStreetItems((prevState) => prevState.filter((_, i) => i !== index));
    },
    [streetItems, setStreetItems]
  );


  const addProductTabulation = useCallback(() => {
    setProductTabulationItems(
      (prevState) => [
        ...prevState,
        {
          id: (prevState[prevState.length - 1]?.id || 0) + 1,
          title: '',
          isSelected: false
        }
      ]
    );
  }, [setProductTabulationItems]);

  const deleteProductTabulation = useCallback(
    (index: number) => (event: SyntheticEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setProductTabulationItems((prevState) => prevState.filter((_, i) => i !== index));
    },
    [productTabulationItems, setProductTabulationItems]
  );

  const selectProductTabulation = useCallback(
    (id: number) => (event: SyntheticEvent<HTMLDivElement>) => {
      event.stopPropagation();
      setProductTabulationItems(
        (prevState) =>
          prevState.map(
            (item) => (item.id === id) ? ({ ...item, isSelected: !item.isSelected }) : ({ ...item, isSelected: false })
          )
      );
    },
    [productTabulationItems, setProductTabulationItems]
  );

  const changeProductTabulation = useCallback(
    (id: number, value = '') => {
      setProductTabulationItems(
        (prevState) =>
          prevState.map(
            (item) => (item.id === id) ? ({ ...item, title: value }) : item
          )
      );
    },
    [productTabulationItems, setProductTabulationItems]
  );

  const dragItem = useRef<number | null>(null);
  const dragOverItem = useRef<number | null>(null);
  const handleSort = useCallback(() => {
    if (dragItem.current !== null && dragOverItem.current !== null) {
      const newItems = [...streetItems];
      const draggedItem = newItems[dragItem.current];
      newItems.splice(dragItem.current ?? -1, 1);
      newItems.splice(dragOverItem.current ?? -1, 0, draggedItem);
      setStreetItems(newItems);
    }
  }, [dragItem, dragOverItem, streetItems]);

  const handleDragStart = useCallback(
    (index: number) => {
      dragItem.current = index;
    },
    [dragItem]
  );

  const handleDragEnter = useCallback(
    (index: number) => {
      dragOverItem.current = index;
    },
    [dragOverItem]
  );

  const handleClick = useCallback(
    (index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const handleBack = useCallback(() => {
    navigation(-1);
  }, []);

  const getIndexSelectedSection = useCallback((slot: string) => {
    return streetItems.findIndex((item) => item.slot === slot);
  }, [streetItems]);
  // create page builder
  const handleSubmitPageBuilder = useCallback( async () => {
    setLoading(true);
    const streetParent = streetItems?.find((item) => item.slot === 'street');
    const attributeParent = {
      color: streetParent?.color || '',
      margin_top: Number(streetParent?.marginTop) || Number(0),
      margin_bottom: Number(streetParent?.marginBottom) || Number(0),
      tab_name: productTabulationItems.map((item) => item.title) || null,
      image: imageUrl || null,
      display: streetParent?.display || ''
    };
    const payloadParent = {
      platform: streetParent?.isMobile ? 'MOBILE' : 'WEB',
      attribute: attributeParent,
      slot: streetParent?.slot ?? '',
      sequence: getIndexSelectedSection(streetParent?.slot ?? ''),
      section_name: streetParent?.sectionValue ? streetParent?.sectionValue : null
    };

    // post parent layout
    const { data: dataParent, error: errorParent } = await supabase
      .from('user.layouts')
      .insert(payloadParent)
      .select()
      .single() as PostgrestSingleResponse<IParentLayout>;

    if (dataParent && productTabulationItems.length > 1) {
      const payload = productTabulationItems.map((it) => {
        return {
          layout_id: dataParent?.id,
          name: it.title
        };
      });

      createProductTabulation(payload as []);
    }

    if (!errorParent && dataParent) {
      const filterStreet = streetItems?.filter((item) => item.slot !== 'street');
      const payloadLayout = filterStreet?.map((item) => {
        const attributeProductTabulation = {
          color: streetParent?.color || '',
          margin_top: Number(item.marginTop) || Number(0),
          margin_bottom: Number(item.marginBottom) || Number(0),
          tab_name: productTabulationItems.map((item) => item.title) || null,
          image: imageUrl || null,
          display: item.display || ''
        };
        return {
          slot: item.slot,
          attribute: attributeProductTabulation,
          parent_id: dataParent?.id,
          section_name: item.sectionValue,
          platform: item.isMobile ? 'MOBILE' : 'WEB',
          sequence: getIndexSelectedSection(item.slot)
        };
      });

      // post child layout
      await supabase
        .from('user.layouts')
        .insert(payloadLayout);
      setStreetItems([]);
      showSuccess('Created');
      setLoading(false);
    }
    if (errorParent) {
      showFailed(errorParent.message);
      setLoading(false);
    };
  }, [streetItems, imageUrl, productTabulationItems]);

  // handle set attribute new section
  const handleSetMargin = useCallback((type: string, value: string) => {
    switch (type) {
    case 'marginTop':
      setAttributeStyle((prev) => ({
        ...prev,
        marginTop: value
      }));
      return;
    case 'marginBottom':
      setAttributeStyle((prev) => ({
        ...prev,
        marginBottom: value
      }));
      return;
    case 'display':
      setAttributeStyle((prev) => ({
        ...prev,
        display: value
      }));

    default:
    }
  }, []);

  // set attribute style
  const handleSetAttributeStyle = useCallback((type: string, index?:number) => (e: ChangeEvent<HTMLInputElement>) => {
    if (index !== null && index) {
      const tempStreet = streetItems;
      switch (type) {
      case 'marginTop':
        tempStreet[index].marginTop = e.target.value;
        setStreetItems(tempStreet);
        setAttributeStyle((prev) => ({
          ...prev,
          marginTop: e.target.value
        }));
        return;
      case 'marginBottom':
        tempStreet[index].marginBottom = e.target.value;
        setStreetItems(tempStreet);
        setAttributeStyle((prev) => ({
          ...prev,
          marginBottom: e.target.value
        }));
        return;
      case 'display':
        tempStreet[index].marginBottom = e.target.value;
        setStreetItems(tempStreet);
        setAttributeStyle((prev) => ({
          ...prev,
          display: e.target.value
        }));

      default:
      }
    } else {
      handleSetMargin(type, e.target.value);
    }
  }, [streetItems]);

  // checked platform
  const handleCheckPlatform = useCallback((id: number, value: boolean) => {
    const updateStreet = streetItems.map((item) => item.id === id ? {
      ...item,
      isMobile: value
    } : item);
    setStreetItems(updateStreet);
  }, [streetItems, setStreetItems]);

  // check title type
  const handleCheckTitleType = useCallback((item: IStreetItem) => {
    if (item.sectionValue) {
      setSelectedTileType({ name: 'Text', code: 'TEXT' });
    } else {
      setSelectedTileType({ name: 'Icon', code: 'ICON' });
    }
  }, []);

  // handle click edit
  const handleClickEditSection = useCallback((item: IStreetItem) => {
    const filteredSection = sections.find((i) => i.code == item.slot);

    setSelectedSection(filteredSection as {});
    setAttributeStyle({
      display: item.display || '',
      marginTop: item.marginTop || '',
      marginBottom: item.marginBottom || ''
    });
    setColor(item.color);
    setSectionText(item.title);
    setTitle(item.sectionValue);
    handleCheckTitleType(item);
  }, [sections]);

  return {
    data: {
      title,
      color,
      toast,
      tileType,
      iconName,
      imageUrl,
      sections,
      isLoading,
      sectionText,
      streetItems,
      activeIndex,
      attributeStyle,
      selectedSection,
      selectedTileType,
      productTabulationItems
    },
    methods: {
      setTitle,
      setColor,
      handleSort,
      handleBack,
      addSection,
      handleClick,
      onChangeImage,
      deleteSection,
      setSectionText,
      handleDragEnter,
      handleDragStart,
      setSelectedSection,
      handleCheckPlatform,
      setSelectedTileType,
      addProductTabulation,
      handleResetSectionText,
      handleClickEditSection,
      handleSetAttributeStyle,
      handleSubmitPageBuilder,
      selectProductTabulation,
      changeProductTabulation,
      deleteProductTabulation,
      handleResetSelectSection,
      handleResetTabulationTabs,
      handleResetAttributeStyle
    }
  };
};

export default useCustom;
