import dayjs from 'dayjs';
import Dropdown from '@/components/base/Dropdown';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputNumber } from 'primereact/inputnumber';
import { Paginator } from 'primereact/paginator';
import { flashsaleProductSortBy } from '@/utils/type';

import type { ColumnEditorOptions } from 'primereact/column';
import type { CalendarChangeEvent } from 'primereact/calendar';
import type {
  DataTableRowEditCompleteEvent,
  DataTableRowEditEvent,
  DataTableSelectionChangeEvent,
  DataTableStateEvent
} from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';

import type { InputNumberValueChangeEvent } from 'primereact/inputnumber';
import type { PaginatorPageChangeEvent } from 'primereact/paginator';
import type { DropdownValue, IDropdownOptions } from '@/components/base/Dropdown';
import type { ITableDataProducts } from '@/views/FlashSaleManagement/Create/hooks';
import type { ITime } from '@/views/FlashSaleManagement/type';

import './table.css';

type TempSortValue = {
  value: number;
  index: number;
}

interface ITableFlashSaleProducts {
  className?: string
  data: ITableDataProducts[]
  selectedProduct: ITableDataProducts[]
  onSelectProduct: (e: ITableDataProducts[]) => void
  flashSaleSlot: ITime[] | undefined
  onRowEditComplete: (newData: ITableDataProducts, index: number, newSortValue: string) => void
  isSelectToEdit?: boolean
  totalData?: number
  currentPage?: number
  perPage?: number
  onPageChange?: (first: number, rows: number) => void
  includePrice?: boolean
  disabledEditStatus?: boolean
  viewOnly?: boolean
  onInvalid?: (isInvalid: boolean) => void
  currentProducts?: ITableDataProducts[]
  onOverwriteSequence?: (newData: ITableDataProducts, index: number) => void
  minDate?: Date
  maxDate?: Date
  onDelete?: (index: number) => void
}

const Table: FC<ITableFlashSaleProducts> = ({
  className,
  data,
  selectedProduct,
  onSelectProduct,
  flashSaleSlot,
  onRowEditComplete,
  isSelectToEdit,
  totalData,
  currentPage,
  perPage,
  onPageChange,
  includePrice,
  disabledEditStatus,
  viewOnly,
  onInvalid,
  currentProducts,
  onOverwriteSequence,
  minDate,
  maxDate,
  onDelete
}) => {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [tempSortValue, setTempSortValue] = useState<string[]>(
    data.map(() => '').slice()
  );
  const [tempSortBy, setTempSortBy] = useState<string[]>(
    data.map((item) => item['Sort By']).slice()
  );
  const [invalidStockFs, setInvalidStockFs] = useState<boolean[]>(
    data.map(() => false)
  );
  const [invalidSequence, setInvalidSequence] = useState<boolean[]>(
    data.map(() => false)
  );
  const [invalidSlot, setInvalidSlot] = useState<boolean[]>(
    data.map(() => false)
  );
  const [isChangingPage, setIsChangingPage] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [isShowSortValueConfirmationModal, setIsShowSortValueConfirmationModal] = useState(false);
  const [tempSequenceSortValue, setTempSequenceSortValue] = useState<TempSortValue | null>(null);
  const [productsWithSameSequence, setProductsWithSameSequence] = useState<ITableDataProducts[]>([]);
  const [productsWithSameSequenceIndex, setProductsWithSameSequenceIndex] = useState<number[]>([]);
  const [isSubmitOverwriteSequence, setIsSubmitOverwriteSequence] = useState(false);

  const _first = useMemo(() => {
    if (!currentPage) return first;
    return currentPage * perPage!;
  }, [first, currentPage, perPage]);

  const _rows = useMemo(() => perPage ?? rows, [rows, perPage]);

  const _onPageChange = useCallback((event: DataTableStateEvent | PaginatorPageChangeEvent) => {
    if (onPageChange) {
      // if (isSelectToEdit) {
      //   const columns = document.querySelectorAll('.selectToEditTable td.rowEditors');
      //   // for (const item of selectedProduct) {
      //   //   const index = data.findIndex((elmt) => elmt['Product Variant ID'] === item['Product Variant ID']);
      //   //   if (index !== -1) {
      //   //     const column = columns[index] as HTMLElement;
      //   //     const button = column.querySelector('button.p-row-editor-save') as HTMLElement;
      //   //     button?.click();
      //   //   }
      //   // }
      //   const selectedProductVariantIds = selectedProduct.map((item) => item['Product Variant ID']);
      //   for (let i = 0; i < data.length; i += 1) {
      //     const productVariantId = data[i]['Product Variant ID'];
      //     if (selectedProductVariantIds.includes(productVariantId)) {
      //       const column = columns[i] as HTMLElement;
      //       const button = column.querySelector('button.p-row-editor-save') as HTMLButtonElement;
      //       button?.click();
      //     }
      //   }
      // }
      setIsChangingPage(true);
      onPageChange(event.first, event.rows);
    } else {
      setFirst(event.first);
      setRows(event.rows);
    }
  }, [_first, _rows]);

  const cancelSequence = () => {
    setIsShowSortValueConfirmationModal(false);
    setProductsWithSameSequence([]);
    setProductsWithSameSequenceIndex([]);
    setIsSubmitOverwriteSequence(false);
  };

  const submitSequence = () => {
    setInvalidSequence((items) => {
      const _items = items.slice();
      _items[tempSequenceSortValue?.index!] = false;
      return _items;
    });
    if (isSelectToEdit) {
      overwriteSequence();
    } else {
      setIsSubmitOverwriteSequence(true);
    }
    setIsShowSortValueConfirmationModal(false);
  };

  const overwriteSequence = () => {
    productsWithSameSequence.forEach((item, index) => {
      const productIndex = productsWithSameSequenceIndex[index];
      if (isSelectToEdit && currentProducts?.length === 0) {
        const tempSelectedProducts = selectedProduct.slice();
        tempSelectedProducts[productIndex]['Sort Value'] = '';
        onSelectProduct(tempSelectedProducts);
      } else if (isSelectToEdit && currentProducts?.length! > 0 && onOverwriteSequence) {
        onOverwriteSequence(item, productIndex);
      } else onRowEditComplete(item, productIndex, '');
    });
  };

  const headerDialog = () => (
    <div id="admin-create-header" className="font-bold">Konfirmasi</div>
  );

  const footerDialog = () => (
    <div id="admin-create-footer" className="-mt-6 -mr-10">
      <SubmitButton
        onCancel={cancelSequence}
        onSubmit={submitSequence}
        labelBack="Cancel"
        labelSubmit="Submit"
      />
    </div>
  );

  const onSelectionChange = (e: DataTableSelectionChangeEvent<ITableDataProducts[]>) => {
    const selectedData = e.value as ITableDataProducts[];
    if (selectedData.length > selectedProduct.length) {
      onSelectData(selectedData);
    } else if (selectedData.length < selectedProduct.length) {
      onUnselectData(selectedData);
    }
    onSelectProduct(selectedData);
  };

  const onUnselectData = (selectedData: ITableDataProducts[]) => {
    const selectedProductVariantIds = selectedData.map((item) => item['Product Variant ID']);
    const deletedData = selectedProduct.find((item) => (
      !selectedProductVariantIds.includes(item['Product Variant ID'])
    ));

    if (deletedData && isSelectToEdit) {
      const index = data.findIndex((item) => item['Product Variant ID'] === deletedData['Product Variant ID']);
      if (index !== -1) {
        const columns = document.querySelectorAll('.selectToEditTable td.rowEditors');
        const column = columns[index] as HTMLElement;
        const button = column.querySelector('button.p-row-editor-cancel') as HTMLButtonElement;
        button?.click();
      }
    }
  };

  const onSelectData = (selectedData: ITableDataProducts[]) => {
    const selectedProductVariantIds = selectedProduct.map((item) => item['Product Variant ID']);
    const insertedData = selectedData.find((item) => (
      !selectedProductVariantIds.includes(item['Product Variant ID'])
    ));

    if (insertedData && isSelectToEdit) {
      const index = data.findIndex((item) => item['Product Variant ID'] === insertedData['Product Variant ID']);
      if (index !== -1) {
        const columns = document.querySelectorAll('.selectToEditTable td.rowEditors');
        const column = columns[index] as HTMLElement;
        const button = column.querySelector('button.p-row-editor-init') as HTMLButtonElement;
        button?.click();
      }
    }
  };

  const _onRowEditComplete = useCallback((e: DataTableRowEditCompleteEvent) => {
    const { newData, index } = e;
    const _newData = newData as ITableDataProducts;
    const _sortValue = _newData['Sort By'] === 'sequence' ? _newData['Sort Value'] : tempSortValue[index];
    onRowEditComplete(_newData, index, _sortValue);

    const _tempSortValue = [...tempSortValue];
    _tempSortValue[index] = '';
    setTempSortValue(_tempSortValue);
  }, [tempSortValue, _first]);

  const numberFormat = useCallback((data: string | number) => {
    const formatter = new Intl.NumberFormat('id-ID');
    return formatter.format(Number(data));
  }, []);

  const flashSaleValueTemplate = useCallback((rowData: ITableDataProducts) => {
    return numberFormat(rowData?.['Flash Sale Price Value']);
  }, []);

  const priceTemplate = useCallback((rowData: ITableDataProducts) => {
    return numberFormat(rowData?.['Price']);
  }, []);

  const stockTemplate = useCallback((rowData: ITableDataProducts) => {
    return numberFormat(rowData?.['Stock']);
  }, []);

  const flashSaleStockTemplate = useCallback((rowData: ITableDataProducts) => {
    return numberFormat(rowData?.['Jumlah Stock Flash Sale']);
  }, []);

  const periodeTemplate = useCallback((rowData: ITableDataProducts) => {
    if (!rowData?.Periode) return '';
    return dayjs(rowData?.Periode).format('MM-DD-YYYY');
  }, []);

  const totalRecords = useMemo(() => totalData ?? data.length, [totalData, data]);

  const sortByTemplate = useCallback((rowData: ITableDataProducts) => (
    flashsaleProductSortBy?.[rowData?.['Sort By']]
  ), [flashsaleProductSortBy]);

  const sortValueTemplate = useCallback((rowData: ITableDataProducts) => {
    if (rowData?.['Sort By'] === 'sequence') return rowData?.['Sort Value'];
    return flashsaleProductSortBy?.[rowData?.['Sort Value']];
  }, [flashsaleProductSortBy]);

  const slotTemplate = useCallback((rowData: ITableDataProducts) => {
    const dataSlot = flashSaleSlot?.[Number(rowData?.['Slot']) - 1];

    if (!flashSaleSlot || !dataSlot) return rowData?.['Slot'];
    return (
      <>
        <p>Slot {rowData?.['Slot']}</p>
        <p>{dataSlot?.started_time} - {dataSlot?.ended_time}</p>
      </>
    );
  }, [flashSaleSlot]);

  const actionTemplate = useCallback((rowData: ITableDataProducts) => {
    const { index } = rowData;
    const columns = document?.querySelectorAll('td.rowEditors');
    const column = columns[index! % _rows] as HTMLElement;
    const editButton = column?.querySelector('button.p-row-editor-init') as HTMLButtonElement;
    const cancelButton = column?.querySelector('button.p-row-editor-cancel') as HTMLButtonElement;
    const saveButton = column?.querySelector('button.p-row-editor-save') as HTMLButtonElement;
    const isEdit = index === editingIndex;
    const isNotEditingRow = editingIndex !== null && !isEdit;

    const onEdit = () => editButton.click();
    const onSave = () => saveButton.click();
    const onCancel = () => cancelButton.click();
    const deleteProduct = () => {
      if (onDelete) onDelete(index!);
    };

    if (isEdit) {
      return (
        <div className="flex">
          <Button icon="pi pi-check" text onClick={onSave} />
          <Button icon="pi pi-times" text onClick={onCancel} />
        </div>
      );
    }
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          text
          disabled={isNotEditingRow}
          onClick={onEdit}
        />
        <Button icon="pi pi-trash" text severity="danger" onClick={deleteProduct} />
      </div>
    );
  }, [editingIndex, onDelete]);

  const numberEditor = useCallback((options: ColumnEditorOptions) => {
    if (isChangingPage) return;
    const { rowIndex, field, rowData } = options;
    const _onChange = (e: InputNumberValueChangeEvent) => {
      if (!options.editorCallback) return;
      options.editorCallback(e.value);

      const dataFsExcludeCurrentData = data
        .filter((item, index) => {
          const rowIndexOnData = first + rowIndex;
          return item['Product Variant ID'] === rowData?.['Product Variant ID'] && index !== rowIndexOnData;
        })
        .map((item) => item['Jumlah Stock Flash Sale']);

      const totalStockFsExcludeCurrentData = dataFsExcludeCurrentData.length === 0 ?
        0 :
        dataFsExcludeCurrentData.reduce((prev, curr) => (prev + curr));

      const totalStockFs = totalStockFsExcludeCurrentData + Number(e.value);

      setInvalidStockFs((prev) => {
        const current = prev.slice();
        current[rowIndex] = field === 'Jumlah Stock Flash Sale' && totalStockFs > data[rowIndex]['Stock'];
        return current;
      });

      if (isSelectToEdit) {
        const tempSelectedProducts = selectedProduct.slice();
        const index = selectedProduct.findIndex((item) => item['Product Variant ID'] === data[rowIndex]['Product Variant ID']);
        if (index !== -1) tempSelectedProducts[index][field] = e.value;
        onSelectProduct(tempSelectedProducts);
      }
    };

    return (
      <>
        <InputNumber
          className={field === 'Jumlah Stock Flash Sale' && invalidStockFs[rowIndex] ? 'p-invalid' : ''}
          value={options.value}
          onValueChange={_onChange}
          locale="id-ID"
        />
        {field === 'Jumlah Stock Flash Sale' && invalidStockFs[rowIndex] && (
          <p className="text-[#e24c4c] text-xs font-bold">Jumlah stock flashsale melebihi jumlah stock produk</p>
        )}
      </>
    );
  }, [setInvalidStockFs, invalidStockFs, data, selectedProduct, isChangingPage]);

  const dropdownEditor = (options: ColumnEditorOptions) => {
    if (isChangingPage) return;
    const { rowIndex, field, rowData } = options;
    const _onChange = (value: DropdownValue) => {
      if (!options.editorCallback) return;
      options.editorCallback(value);

      if (field === 'Slot') {
        const productsForComparison = isSelectToEdit ? currentProducts?.slice() : [...data];
        const _productsWithSameSlot = productsForComparison?.filter((item, index) => {
          const isSameProduct = item['Product Variant ID'] === rowData['Product Variant ID'];
          const isSameSlot = item.Slot === value;
          const isSamePeriod = item['Periode'] === dayjs(rowData['Periode']).format('MM-DD-YYYY');
          const isDifferentIndex = index !== rowIndex;
          return isSameProduct && isSameSlot && isSamePeriod && isDifferentIndex;
        });

        const invalidSlot = !!_productsWithSameSlot && _productsWithSameSlot.length > 0;

        setInvalidSlot((items) => {
          const _items = items.slice();
          _items[rowIndex] = invalidSlot;
          return _items;
        });
      }

      const _tempSortBy = [...tempSortBy];
      const _tempSortValue = [...tempSortValue];

      if (field === 'Sort By') {
        _tempSortBy[rowIndex] = value as string;
        if (value !== 'sequence') _tempSortValue[rowIndex] = value as string;
        else _tempSortValue[rowIndex] = '';
      }

      setTempSortBy(_tempSortBy);
      setTempSortValue(_tempSortValue);

      if (isSelectToEdit) {
        const tempSelectedProducts = selectedProduct.slice();
        const index = selectedProduct.findIndex((item) => item['Product Variant ID'] === data[rowIndex]['Product Variant ID']);
        if (index !== -1) tempSelectedProducts[index][field] = value;
        onSelectProduct(tempSelectedProducts);
      }
    };

    let listOptions: IDropdownOptions[] = [];

    if (field === 'Flash Sale Price By') {
      listOptions = [
        { label: 'Amount', value: 'Amount' },
        { label: 'Percentage', value: 'Percentage' }
      ];
    } else if (field === 'Sort By') {
      listOptions = [
        { label: 'Sequence', value: 'sequence' },
        { label: 'Lowest Price', value: 'lowest_price' },
        { label: 'Random', value: 'random' }
      ];
    } else if (field === 'Slot') {
      listOptions = !Array.isArray(flashSaleSlot) ? [] : flashSaleSlot.map((slot, index) => {
        return {
          label: (
            <div>
              <p>Slot {index + 1}</p>
              <p>{slot.started_time} - {slot.ended_time}</p>
            </div>
          ),
          value: `${index + 1}`
        };
      });
    }

    return (
      <div>
        <Dropdown
          value={options.value}
          options={listOptions}
          onChange={_onChange}
          field={field}
        />
        {field === 'Slot' && invalidSlot[rowIndex] && (
          <p className="text-[#e24c4c] text-xs font-bold">Slot sudah digunakan oleh produk ini</p>
        )}
      </div>
    );
  };

  const sortValueEditor = useCallback((options: ColumnEditorOptions) => {
    if (isChangingPage) return;
    const { rowIndex, field, rowData } = options;
    const _onChange = (value: DropdownValue) => {
      if (!options.editorCallback) return;
      options.editorCallback(value);

      const productsForComparison = isSelectToEdit ? [...selectedProduct, ...currentProducts?.slice()!] : [...data];
      const _productsWithSameSequence = productsForComparison?.filter((item, index) => {
        const itemPeriode = dayjs(item['Periode']).format('MM-DD-YYYY');
        const isSameSequence = item['Sort Value'] === value;
        const isSameSlot = item.Slot === rowData['Slot'];
        const isSamePeriod = itemPeriode === dayjs(rowData['Periode']).format('MM-DD-YYYY');
        const isSameIndex = !isSelectToEdit && rowIndex === index;

        if (isSameSequence && isSameSlot && isSamePeriod && !isSameIndex) {
          setProductsWithSameSequenceIndex((productIndex) => [...productIndex, index]);
        }
        return isSameSequence && isSameSlot && isSamePeriod && !isSameIndex;
      });

      const indexOnSelectedProducts = selectedProduct
        .findIndex((item) => item['Product Variant ID'] === data[rowIndex]['Product Variant ID']);
      const index = isSelectToEdit ? indexOnSelectedProducts : rowIndex;

      const isDuplicateSequence = (productsWithSameSequence && productsWithSameSequence.length > 0) ||
        (_productsWithSameSequence && _productsWithSameSequence.length > 0);

      setInvalidSequence((items) => {
        const _items = items.slice();
        _items[index] = isDuplicateSequence!;
        return _items;
      });

      if (isDuplicateSequence) {
        setProductsWithSameSequence(_productsWithSameSequence ?? productsWithSameSequence);
        setTempSequenceSortValue({ value: Number(value), index });
        setIsShowSortValueConfirmationModal(true);
      }
      if (isSelectToEdit && index !== -1) {
        const tempSelectedProducts = selectedProduct.slice();
        tempSelectedProducts[index][field] = value;
        onSelectProduct(tempSelectedProducts);
      }
    };
    // edit is disabled
    if (options.rowData?.['Sort By'] !== 'sequence') {
      return flashsaleProductSortBy[tempSortValue[rowIndex]];
    }

    const listOptions = new Array(100).fill('')
      .map((_, index) => {
        const option: IDropdownOptions = {
          label: `${index + 1}`,
          value: `${index + 1}`
        };
        return option;
      });

    return (
      <Dropdown
        value={options.value}
        options={listOptions}
        onChange={_onChange}
        field={field}
      />
    );
  }, [tempSortValue, tempSortBy, selectedProduct, currentProducts, isSelectToEdit, data, productsWithSameSequence, isChangingPage]);

  const periodeEditor = (options: ColumnEditorOptions) => {
    if (isChangingPage) return;
    const { rowIndex, field } = options;
    let value: Date | null = null;
    if (options.value && options.value !== 'Invalid Date') value = new Date(options.value);

    const _onChange = (e: CalendarChangeEvent) => {
      if (!options.editorCallback) return;
      options.editorCallback(e.value);
      if (isSelectToEdit) {
        const tempSelectedProducts = selectedProduct.slice();
        const index = selectedProduct.findIndex((item) => item['Product Variant ID'] === data[rowIndex]['Product Variant ID']);
        if (index !== -1) tempSelectedProducts[index][field] = e.value;
        onSelectProduct(tempSelectedProducts);
      }
    };

    return (
      <Calendar
        value={value}
        onChange={_onChange}
        dateFormat="dd-mm-yy"
        hideOnDateTimeSelect
        readOnlyInput
        className="min-w-[140px]"
        minDate={minDate}
        maxDate={maxDate}
      />
    );
  };

  const resetInvalid = () => {
    if (!isSelectToEdit && editingIndex !== null) {
      setInvalidSlot((invalids) => {
        const _invalids = [...invalids];
        _invalids[editingIndex] = false;
        return _invalids;
      });
      setInvalidSequence((invalids) => {
        const _invalids = [...invalids];
        _invalids[editingIndex] = false;
        return _invalids;
      });
      setInvalidStockFs((invalids) => {
        const _invalids = [...invalids];
        _invalids[editingIndex] = false;
        return _invalids;
      });
      setEditingIndex(null);
      setProductsWithSameSequence([]);
      setProductsWithSameSequenceIndex([]);
    }
  };

  const onEditing = (e: DataTableRowEditEvent) => {
    if (!isSelectToEdit) {
      const { index } = e;
      setEditingIndex(index);
    }
  };

  const onCancelEditing = () => {
    if (!isSelectToEdit) resetInvalid();
  };

  const onFinishEditing = () => {
    if (!isSelectToEdit) {
      resetInvalid();
      if (isSubmitOverwriteSequence) {
        overwriteSequence();
        setIsSubmitOverwriteSequence(false);
      }
    }
  };

  useEffect(() => {
    if (!isSelectToEdit) {
      const columns = document?.querySelectorAll('td.rowEditors');
      for (let index = 0; index < data.length; index += 1) {
        const column = columns?.[index % _rows] as HTMLElement;
        const button = column?.querySelector('button.p-row-editor-init') as HTMLElement;
        if (editingIndex !== null && index !== editingIndex) {
          button?.setAttribute('disabled', '');
        } else {
          button?.removeAttribute('disabled');
        }
      }
    }
  }, [editingIndex, _first, _rows]);

  useEffect(() => {
    setIsChangingPage(false);
    if (isSelectToEdit) {
      const columns = document.querySelectorAll('.selectToEditTable td.rowEditors');
      const selectedProductVariantIds = selectedProduct.map((item) => item['Product Variant ID']);

      for (let i = 0; i < data.length; i += 1) {
        const productVariantId = data[i]['Product Variant ID'];
        if (selectedProductVariantIds.includes(productVariantId)) {
          const column = columns[i] as HTMLElement;
          const button = column.querySelector('button.p-row-editor-init') as HTMLButtonElement;
          button?.click();
        }
      }
    }
  }, [data, isSelectToEdit]);

  useEffect(() => {
    if (!isSelectToEdit) {
      const columns = document?.querySelectorAll('td.rowEditors');
      for (let index = 0; index < data.length; index += 1) {
        const isInvalidStockFs = invalidStockFs[index];
        const isInvalidSequence = invalidSequence[index];
        const isInvalidSlot = invalidSlot[index];
        const column = columns?.[index % _rows] as HTMLElement;
        const button = column?.querySelector('button.p-row-editor-save') as HTMLElement;

        if (isInvalidStockFs || isInvalidSequence || isInvalidSlot) button?.setAttribute('disabled', '');
        else button?.removeAttribute('disabled');
      }
    } else if (!!onInvalid) {
      const isValidStockFs = invalidStockFs.reduce((prev, curr) => (prev || curr));
      const isValidSequence = invalidSequence.reduce((prev, curr) => (prev || curr));
      const isValidSlot = invalidSlot.reduce((prev, curr) => (prev || curr));
      onInvalid(isValidStockFs || isValidSequence || isValidSlot);
    }
  }, [invalidStockFs, invalidSequence, invalidSlot, _first, _rows]);

  return (
    <div>
      <Dialog
        header={headerDialog}
        footer={footerDialog}
        draggable={false}
        visible={isShowSortValueConfirmationModal}
        className="w-[500px]"
        onHide={cancelSequence}
      >
        <p>Sort Value {tempSequenceSortValue?.value} sudah digunakan oleh produk:</p>
        <ul className="list-disc ml-4">
          {productsWithSameSequence.map((item, index) => (
            <li key={`item-${index + 1}`}>
              {item['Product Name']}
            </li>
          ))}
        </ul>
        <br />
        <p>Produk tersebut akan dihapus sort value nya jika anda tetap ingin menggunakan sort value {tempSequenceSortValue?.value}, apakah anda bersedia untuk melanjutkan?</p>
      </Dialog>
      <div className="w-full flex overflow-x-scroll">
        <DataTable
          className={isSelectToEdit ? 'selectToEditTable' : `${className} flashsale-products-table`}
          value={data}
          paginator
          paginatorClassName="invisible absolute bottom-0"
          tableClassName="f-custom-table"
          first={currentPage !== undefined ? 0 : _first}
          rows={_rows}
          onPage={_onPageChange}
          showGridlines
          scrollable
          scrollHeight="30rem"
          size="small"
          selectionMode="checkbox"
          dataKey="Product Variant ID"
          selection={selectedProduct}
          onSelectionChange={onSelectionChange}
          editMode="row"
          selectionAutoFocus={false}
          onRowEditComplete={_onRowEditComplete}
          emptyMessage="No Data Found"
          onRowEditInit={onEditing}
          onRowEditCancel={onCancelEditing}
          onRowEditSave={onFinishEditing}
        >
          {!viewOnly && <Column alignHeader="center" selectionMode="multiple" />}
          <Column alignHeader="center" field="Product Variant ID" header="Variant ID" />
          <Column alignHeader="center" field="Product Name" header="Product Name" />
          <Column alignHeader="center" field="Merchant" header="Merchant" />
          {includePrice && <Column alignHeader="center" field="Price" header="Normal Price" body={priceTemplate} />}
          <Column
            alignHeader="center"
            className="rowPriceBy"
            field="Flash Sale Price By"
            header="FS Price By"
            editor={dropdownEditor}
          />
          <Column
            alignHeader="center"
            className="rowPriceValue"
            field="Flash Sale Price Value"
            header="FS Price Value"
            body={flashSaleValueTemplate}
            editor={numberEditor}
          />
          <Column field="Stock" header="Stock" body={stockTemplate} />
          <Column
            alignHeader="center"
            className="rowFsStock"
            field="Jumlah Stock Flash Sale"
            header="FS Stock"
            body={flashSaleStockTemplate}
            editor={numberEditor}
          />
          <Column alignHeader="center" field="Periode" header="Periode" body={periodeTemplate} editor={periodeEditor} />
          <Column alignHeader="center" className="rowSlot" field="Slot" header="Slot" body={slotTemplate} editor={dropdownEditor} />
          <Column alignHeader="center" className="rowSortBy" field="Sort By" header="Sort By" body={sortByTemplate} editor={dropdownEditor} />
          <Column
            alignHeader="center"
            className="rowSortValue"
            field="Sort Value"
            header="Sort Value"
            body={sortValueTemplate}
            editor={sortValueEditor}
          />
          {includePrice && <Column alignHeader="center" field="Status Product" header="Status Product" />}
          {!viewOnly && <Column alignHeader="center" className="rowEditors" header="Edit Action" rowEditor />}
          {!viewOnly && <Column alignHeader="center" className="newRowEditors" header="Action" body={actionTemplate} />}
        </DataTable>
      </div>
      <Paginator
        first={_first}
        rows={_rows}
        totalRecords={totalRecords}
        rowsPerPageOptions={[5, 10, 25, 50]}
        onPageChange={_onPageChange}
      />
    </div>
  );
};

export default Table;
