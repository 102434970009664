import { Button } from 'primereact/button';
import { FC, ReactNode, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

type IMainTitle = {
  title: string;
  labelButton?: string;
  nav?: string;
  isWithButton?: boolean;
  children?:ReactNode;
  severity?: 'danger' | 'warning' | 'info' | 'secondary' | 'success' | 'help';
  isBack?: boolean;
};

const MainTitle: FC<IMainTitle> = ({ title, labelButton, nav = '', isWithButton = false, children, severity = 'danger', isBack = false }) => {
  const navigate = useNavigate();
  const onClickBackBtn = useCallback(
    () => {
      if (isBack) {
        navigate(-1);
      }
    },
    [isBack, nav]
  );
  return (
    <div className="flex justify-between">
      <div id="main-title" className="font-bold lg:text-2xl mt-3 mb-5">
        {title}
      </div>
      {children}
      {isWithButton && (
        <Link to={nav} onClick={onClickBackBtn}>
          <Button id="button-create" label={labelButton} severity={severity} />
        </Link>
      )}
    </div>
  );
};

export default MainTitle;
