import React, { FC } from 'react';
import { Checkbox } from 'primereact/checkbox';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { Button } from 'primereact/button';
import IconCancel from '@/assets/IconCancel';
import { IMenuPermission } from '../hooks';

type TCreateFeature
 = {
  onCloseFilterModal: () => void;
  onUpdateCheckbox: (name) => void;
  onSubmitUpdateCheckbox: () => void;
  id: number | undefined
  roleName: string | undefined
  dataFeaturePermissions: IMenuPermission[];
  isLoading: boolean
}

const CreateFeaturePermission: FC<TCreateFeature
> = ({ onCloseFilterModal, id, roleName, dataFeaturePermissions, isLoading, onUpdateCheckbox, onSubmitUpdateCheckbox }) => {
  const handleClose = () => {
    onCloseFilterModal();
  };


  const handleUpdate = (name) => {
    onUpdateCheckbox(name);
  };

  const handleSubmit = () => {
    onSubmitUpdateCheckbox();
  };
  return (
    <>
      { isLoading ? (<LoadingOverlay show />) : (
        <div id="user-popup-create-feature" className="bg-black bg-opacity-30 w-screen h-screen fixed top-0 left-0 z-50 flex justify-center items-center">
          <div className="bg-white relative p-6 z-50 w-[628px] rounded-lg h-[723px]">
            <div className="flex justify-between">
              <h1 className="font-bold text-xl">Seller Center Feature Permission</h1>
              <button type="button" id="user-button-close-popup" onClick={() => handleClose()}><IconCancel /></button>
            </div>
            <div className="flex justify-between items-center mt-4">
              <p className="font-normal">{id}</p>
            </div>
            <h1 className="font-bold text-xl">{roleName}</h1>
            <div className="flex flex-col gap-8">
              <div id="detail permisions">
                <div className="flex justify-between">
                  <h2 className="w-1/2 font-semibold">Feature</h2>
                  <div className="flex justify-around w-1/2">
                    <span className="font-semibold">Lihat</span>
                    <span className="font-semibold">Tambah</span>
                    <span className="font-semibold">Edit</span>
                    <span className="font-semibold">Hapus</span>
                  </div>
                </div>
              </div>
              <div className="overflow-y-scroll h-[350px]">
                {dataFeaturePermissions.map((item, index) => (
                  <div key={index}>
                    <div className="flex justify-between">
                      <h2 className="w-1/2 font-medium">{item.name}</h2>
                      <div className="grid grid-cols-4 w-1/2">
                        <div className="flex justify-center">
                          <Checkbox
                            id="user-read-checkbox"
                            checked={item.read}
                            onChange={(event) => handleUpdate(event.target.name)
                            }
                            name={`read.${item.id}`}
                          />
                        </div>
                        <div className="flex justify-center">
                          <Checkbox
                            id="user-create-checkbox"
                            checked={item.create}
                            onChange={(event) => handleUpdate(event.target.name)
                            }
                            name={`create.${item.id}`}
                          />
                        </div>
                        <div className="flex justify-center">
                          <Checkbox
                            id="user-update-checkbox"
                            checked={item.update}
                            onChange={(event) => handleUpdate(event.target.name)
                            }
                            name={`update.${item.id}`}
                          />
                        </div>
                        <div className="flex justify-center">
                          <Checkbox
                            id="user-delete-checkbox"
                            checked={item.delete}
                            onChange={(event) => handleUpdate(event.target.name)
                            }
                            name={`delete.${item.id}`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-8 mt-8">
                      {item.children !== undefined && item.children.length > 0 ? item.children.map((item, index) => {
                        return (
                          <div key={index}>
                            <div className="flex justify-between">
                              <h2 className="w-1/2 pl-8">{item.name}</h2>
                              <div className="grid grid-cols-4 w-1/2">
                                <div className="flex justify-center">
                                  <Checkbox
                                    id="user-read-checkbox"
                                    checked={item.read}
                                    onChange={(event) => handleUpdate(event.target.name)
                                    }
                                    name={`read.${item.id}`}
                                  />
                                </div>
                                <div className="flex justify-center">
                                  <Checkbox
                                    id="user-create-checkbox"
                                    checked={item.create}
                                    onChange={(event) => handleUpdate(event.target.name)
                                    }
                                    name={`create.${item.id}`}
                                  />
                                </div>
                                <div className="flex justify-center">
                                  <Checkbox
                                    id="user-update-checkbox"
                                    checked={item.update}
                                    onChange={(event) => handleUpdate(event.target.name)
                                    }
                                    name={`update.${item.id}`}
                                  />
                                </div>
                                <div className="flex justify-center pb-6">
                                  <Checkbox
                                    id="user-delete-checkbox"
                                    checked={item.delete}
                                    onChange={(event) => handleUpdate(event.target.name)
                                    }
                                    name={`delete.${item.id}`}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }) : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="absolute w-full bottom-6 left-0">
              <div className="grid grid-cols-2 mx-6 gap-4">
                <Button
                  id="user-cancel-button"
                  outlined
                  label="BATAL"
                  onClick={() => handleClose()}/>
                <Button
                  id="user-save-button"
                  outlined
                  label="SIMPAN"
                  onClick={() => handleSubmit()}/>
              </div>
            </div>
          </div>

        </div>
      )}
    </>
  );
};

export default CreateFeaturePermission;


