import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  deleteCategoryVariants,
  deleteMultiplePresetVariants,
  deletePresetVariants,
  deleteVariants,
  IFilterVariant,
  insertVariantPreset,
  upsertVariants,
  useAllVariants,
  useVariantOptionFilters
} from '@/services/rest/variant';
import { useNavigate } from 'react-router-dom';
import { FileUpload, FileUploadFilesEvent } from 'primereact/fileupload';
import * as XLSX from 'xlsx';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { useAuthStore } from '@/store/useAuthStore';
import { IHistory, insertImportHistory } from '@/services/rest/importHistory';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { useHistoryStore } from '@/store/useHistoryStore';
import { IFilterHistoryItems } from '@/components/base/FilterHistory';
import { useDebounce } from 'primereact/hooks';

interface IVariant{
  id?:string
  name:string
  description?:string
  label:string
  type:string
  status:boolean
  value:Array<string>
  instruction?:string
}

interface IOptions {
  label: string;
  items: Array<IItemsOption>;
}

interface IItemsOption {
  label: string;
  value: string | boolean;
  name: string;
}

const useCustom = () => {
  const initialFilter: IFilterVariant = {
    id_from: null,
    id_to: null,
    name: '',
    label: '',
    description: '',
    type: '',
    status: ''
  };

  const headerVariant = [
    'Nama Varian',
    'Deskripsi',
    'Label',
    'Type',
    'Status',
    'Value Varian',
    'Petunjuk Pengisian'
  ];

  const headerUpdateVariant = [
    'ID Varian',
    'Nama Varian',
    'Deskripsi',
    'Label',
    'Type',
    'Status',
    'Value Varian',
    'Petunjuk Pengisian'
  ];
  const navigate = useNavigate();
  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
  const [importedVariant, setImportedVariant] = useState<IVariant[]>([]);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [uploadType, setUploadType] = useState<'create'|'edit'>('create');
  const user = useAuthStore((state)=>state.user);
  const [fileName, setFileName] = useState<string|null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const [itemFilters, setItemFilters] = useState(lastFilterPage.variant != '' ? JSON.parse(String(lastFilterPage.variant)) : initialFilter);
  const [filters, setFilters] = useState(lastFilterPage.variant != '' ? JSON.parse(String(lastFilterPage.variant)) : initialFilter);
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);
  const [search, debounceSearch, setSearch] = useDebounce('', 1500);
  const currentPage = parseInt(visitedPage.variant.toString()) ?? 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : perPage - 1;
  const [paginator, setPaginator] = useState({
    currentPage,
    range: { start, end }
  });
  const [jumpToPage, setJumpToPage] = useState<number>(1);

  const toast = useRef<Toast>(null);
  const fileUploadRef = useRef<FileUpload>(null);

  const showFailedToast = (msg?:string) => {
    toast.current?.show({
      severity: 'error',
      summary: 'Warning',
      detail: msg || 'Delete Failed'
    });
  };

  const showSuccessToast = (msg:string, isBulk?:boolean) => {
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: msg,
      life: 2000
    });
    if (isBulk) {
      const timeout = setTimeout(()=>{
        navigate('/import-history/list');
      }, 2000);
      return ()=>clearTimeout(timeout);
    }
  };

  const confirmDelete = (id: string) => {
    confirmDialog({
      message: 'Are you sure you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept() {
        deleteDataVariant(id);
      }
    });
  };

  const { data: dataVariants, isLoading, refetch: refetchVariants } = useAllVariants(filters, debounceSearch, paginator.range);

  const { data: dataVariantsOptionsFilter } = useVariantOptionFilters();

  const variant = useMemo(() => {
    if (!Array.isArray(dataVariants?.data)) return [];
    return dataVariants?.data.map((item)=>({
      ...item,
      is_active: item.is_active ? 'Active' : 'Inactive'
    }));
  }, [dataVariants]);

  const totalRecords = useMemo(() => {
    return dataVariants?.count ? dataVariants.count : 0;
  }, [dataVariants]);

  const totalPages = useMemo(() => {
    return Math.ceil(totalRecords / perPage);
  }, [totalRecords, perPage]);

  const options = useMemo(():IOptions[] => {
    if (!Array.isArray(dataVariantsOptionsFilter?.data)) return [];
    const dataFilterType = dataVariantsOptionsFilter?.data.filter((i) => i.type);
    return [
      {
        label: 'Name',
        items: dataVariantsOptionsFilter?.data.map((v) => {
          return {
            label: String(v.name),
            name: 'name',
            value: `name-${String(v.id)}`
          };
        }) || []
      },
      {
        label: 'Label',
        items: dataVariantsOptionsFilter?.data.map((v) => ({
          label: v.label,
          name: 'label',
          value: `label-${v.id}`
        })) || []
      },
      {
        label: 'Type',
        items: dataFilterType?.map((v) => ({
          label: v.type,
          name: 'type',
          value: `type-${v.id}`
        })) || []
      },
      {
        label: 'Status',
        items: [
          { label: 'Active', name: 'status', value: 'true' },
          { label: 'Inactive', name: 'status', value: 'false' }
        ]
      }
    ];
  }, [dataVariantsOptionsFilter]);

  const handleChangeSearch = useCallback(({ currentTarget }:SyntheticEvent<HTMLInputElement, Event>)=>{
    setSearch(currentTarget.value);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    }));
  }, []);

  const deleteDataVariant = useCallback(
    async (id: string) => {
      const { error: errorVariantsPreset } = await deletePresetVariants(id);
      const { error: errorCategoryVariant } = await deleteCategoryVariants(id);
      const { error: errorVariants } = await deleteVariants(id);
      if (errorCategoryVariant || errorVariants || errorVariantsPreset) {
        showFailedToast();
        refetchVariants();
        return;
      }
      showSuccessToast('Delete Success');
      refetchVariants();
    },
    []
  );

  const handleClickDropdownAddVariant = useCallback(()=>{
    setIsOpenDropdown(!isOpenDropdown);
  }, [isOpenDropdown]);

  const handleClickDownload = useCallback(()=>{
    const link = document.createElement('a');
    link.href = `/variant/upload-variant/${uploadType === 'create' ? 'templateVariant.xlsx' : 'templateUbahVariant.xlsx'}`;
    link.target = '_blank';
    link.download = '';
    link.click();
  }, [uploadType]);

  const handleChangeFileUpload = useCallback(async (event:FileUploadFilesEvent)=>{
    const text = await event.files[0].arrayBuffer();
    const wb = XLSX.read(text, { type: 'binary', cellDates: true });
    const sn = wb.SheetNames[0];
    const ws = wb.Sheets[sn];
    const json = XLSX.utils.sheet_to_json<Object[]>(ws, { raw: false, dateNF: 'dd-mm-yyyy' });
    const option = { header: 1 };
    const sheet2 = XLSX.utils.sheet_to_json(ws, option);
    const header = sheet2.shift();
    const isHeaderValid = JSON.stringify(header) === JSON.stringify(headerVariant);
    const isHeaderUploadValid = JSON.stringify(header) === JSON.stringify(headerUpdateVariant);
    if (!isHeaderValid && uploadType === 'create') {
      showFailedToast('File is not Same With Template, Please Check your file correctly');
      fileUploadRef.current?.clear();
      return;
    }
    if (!isHeaderUploadValid && uploadType === 'edit') {
      showFailedToast('File is not Same With Template, Please Check your file corectly');
      fileUploadRef.current?.clear();
      return;
    }
    setFileName(event.files[0].name);
    if (json.length > 1) {
      json.shift();
      const variantResult:IVariant[] = json.map((item)=>{
        return uploadType === 'create' ? {
          label: item['Label'],
          name: item['Nama Varian'],
          status: item['Status'],
          type: item['Type'],
          value: item['Value Varian'],
          description: item['Deskripsi'],
          instruction: item['Petunjuk Pengisian']
        }:{
          id: item['ID Varian'],
          label: item['Label'],
          name: item['Nama Varian'],
          status: item['Status'],
          type: item['Type'],
          value: item['Value Varian'],
          description: item['Deskripsi'],
          instruction: item['Petunjuk Pengisian']
        };
      });
      const variantName = variantResult.map((variant)=>variant.name);
      const duplicatedName = variantName.filter((item, index)=>variantName.indexOf(item)!== index);
      if (duplicatedName.length>0) {
        showFailedToast(`Error name must be unique at ${duplicatedName.join(',')}`);
        fileUploadRef.current?.clear();
        return;
      }
      if (variantResult?.length>300) {
        showFailedToast('Can not Create more than 300 Specifications');
        fileUploadRef.current?.clear();
        return;
      }
      setImportedVariant(variantResult);
    } else {
      fileUploadRef.current?.clear();
      showFailedToast('File Can not be Empty');
    }
  }, [uploadType]);

  const handleClickUpload = useCallback(async ()=>{
    const payload = importedVariant.map((item)=>{
      return uploadType === 'create' ? {
        description: item.description,
        is_active: item.status,
        label: item.label,
        name: item.name,
        type: item.type,
        instruction: item.instruction
      }:{
        id: item.id,
        description: item.description,
        is_active: item.status,
        label: item.label,
        name: item.name,
        type: item.type,
        instruction: item.instruction
      };
    });

    if (uploadType === 'edit') {
      const { error: errorDeleteMultiple } = await deleteMultiplePresetVariants(importedVariant.map((item)=>String(item.id)));
      if (errorDeleteMultiple) {
        return;
      }
    }

    const errorImport:Array<string> = [];

    for (const [index, item] of payload.entries()) {
      const { data: dataVariant, error: errorVariant } = await upsertVariants(item);
      if (errorVariant) {
        errorImport.push(`Baris ke-${index+3}: `+ errorVariant.message);
        continue;
      }

      const payloadVariantPreset = importedVariant[index].value.toString().includes(',') ? importedVariant[index].value.toString().split(',').map((val)=>({
        variant_id: dataVariant.id,
        name: val
      })) : [{ variant_id: dataVariant.id, name: importedVariant[index].value.toString() }];
      const { error: errorVariantPreset } = await insertVariantPreset(payloadVariantPreset);
      if (errorVariantPreset) {
        errorImport.push(`Baris ke-${index+3}: `+ errorVariantPreset.message);
        continue;
      }
    }

    const payloadImportHistorySuccess = payload.length - errorImport.length > 0 && {
      account_id: user?.id || '',
      file_path: fileName || '',
      row_number: payload.length - errorImport.length,
      status: true,
      type: uploadType === 'create' ? 'bulk_upload_variant' : 'bulk_update_variant'
    };

    const payloadImportHistoryError = errorImport.length>0&&{
      account_id: user?.id || '',
      file_path: fileName || '',
      errors: errorImport,
      row_number: errorImport.length,
      status: false,
      type: uploadType === 'create' ? 'bulk_upload_variant' : 'bulk_update_variant'
    };

    const payloadImportHistory = [payloadImportHistorySuccess, payloadImportHistoryError].filter((item)=> typeof item != 'boolean');
    await insertImportHistory(payloadImportHistory as IHistory[]);
    fileUploadRef.current?.clear();
    showSuccessToast('Success', true);
    refetchVariants();
    setIsShowModal(false);
  }, [importedVariant, uploadType, fileName, user]);

  const handleToggleModal = useCallback((type:'create'|'edit')=>()=>{
    setUploadType(type);
    setIsOpenDropdown(false);
    setIsShowModal(!isShowModal);
  }, [isShowModal]);

  const handleClearFilter = useCallback(() => {
    setLastFilterPage({
      ...lastFilterPage,
      variant: ''
    });
    setItemFilters(initialFilter);
    setFilters(initialFilter);
    setFilterHistory([]);
    setSearch('');
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [lastFilterPage, perPage]);

  const handleDeleteFilterHistory = useCallback(
    (key: string, value: string[]) => {
      const items = value[0].split(',');
      items.forEach((i) => {
        setFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));

        setItemFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));
      });

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setSearch('');
    },
    []
  );


  const handleClickNext = useCallback(() => {
    paginator.currentPage <= totalPages &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: paginator.currentPage + 1,
        range: {
          start: paginator.range.start + perPage,
          end: paginator.range.end + perPage
        }
      }));
  }, [paginator, totalPages, perPage]);

  const handleClickPrev = useCallback(() => {
    paginator.range.start > 0 &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: prev.currentPage - 1,
        range: {
          start: prev.range.start - perPage,
          end: prev.range.end - perPage
        }
      }));
  }, [paginator, perPage]);

  const handleClickLastPage = useCallback(() => {
    paginator.currentPage <= totalPages &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: totalPages,
        range: {
          start: totalRecords - perPage + (totalPages * perPage - totalRecords),
          end: totalRecords
        }
      }));
  }, [perPage, totalRecords, totalPages, paginator]);

  const handleClickFirstPage = useCallback(() => {
    paginator.currentPage > 0 &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      }));
  }, [paginator, perPage]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent) => {
    setPerPage(event.value);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleClickPage = useCallback(
    (page: number) => () => {
      setPaginator((prev) => ({
        ...prev,
        currentPage: page,
        range: {
          start: page === 1 ? page - 1 : perPage * page - perPage,
          end: page * perPage - 1
        }
      }));
    },
    [perPage]
  );

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, totalRecords - 1);

      setPaginator(() => ({
        currentPage: jumpToPage > totalPages ? totalPages : value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, totalRecords]);

  const handleSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>)=>{
    setSearch(currentTarget.value);
    const newKeyword = {
      name: 'Keyword',
      items: [{
        label: currentTarget.value,
        value: currentTarget.value,
        name: currentTarget.value
      }]
    };

    if (currentTarget.value != '') {
      setFilterHistory((prev: IFilterHistoryItems[]) => {
        const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
        if (existingIndex !== -1) {
          prev[existingIndex] = newKeyword;
        } else {
          prev.push(newKeyword);
        }
        return [...prev];
      });

      setPaginator({
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      });
    } else {
      handleDeleteFilterHistory('Keyword', ['Keyword']);
    }
  }, [perPage]);

  const handleFilter = useCallback((e, field: string) => {
    setItemFilters((prev) => ({
      ...prev,
      [field]: e.target ? e.target.value : e.value
    }));
  }, []);

  const handleClickSubmitFilter = useCallback(() => {
    setFilters(itemFilters);
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [itemFilters, perPage]);

  const storeFilterHistory = useCallback((filter: IFilterVariant) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter.id_from != null) {
      let filterID = filter.id_from.toString();
      if (filter.id_to != null) {
        filterID = `${filter.id_from}-${filter.id_to}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('ID', 'id_from,id_to', filterID));
    }

    if (filter.name !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Name', 'name', filter.name));
    }

    if (filter.label !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Label', 'label', filter.label));
    }

    if (filter.description !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Description', 'description', filter.description));
    }

    if (filter.type !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Type', 'type', filter.type));
    }

    if (filter.status !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Status', 'status', filter.status));
    }

    setFilterHistory(filterHistoryItems);
  }, []);

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  useEffect(() => {
    // update store visitedPage with latest page
    setVisitedPage({
      ...visitedPage,
      variant: paginator.currentPage
    });
  }, [paginator.currentPage]);

  // update store lastFilterPage with latest filter
  useEffect(() => {
    setLastFilterPage({
      ...lastFilterPage,
      variant: JSON.stringify(filters)
    });
  }, [filters]);

  return {
    data: {
      variant,
      search,
      isLoading,
      toast,
      options,
      isOpenDropdown,
      importedVariant,
      fileUploadRef,
      isShowModal,
      filterHistory,
      uploadType,
      paginator,
      perPage,
      totalPages,
      totalRecords,
      itemFilters
    },
    method: {
      deleteDataVariant,
      confirmDelete,
      handleFilter,
      handleChangeSearch,
      handleClickDropdownAddVariant,
      handleClickDownload,
      handleChangeFileUpload,
      handleClickUpload,
      handleToggleModal,
      handleDeleteFilterHistory,
      handleClearFilter,
      handleChangeDropdownPage,
      handleClickFirstPage,
      handleClickLastPage,
      handleClickNext,
      handleClickPage,
      handleClickPrev,
      handleChangeJumpTopage,
      handleJumpToPage,
      handleSearch,
      handleClickSubmitFilter
    }
  };
};

export default useCustom;
