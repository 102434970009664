import { DeleteIcon, SelectInput } from 'akeneo-design-system';
import React, { useContext } from 'react';
import { CategoryContext } from '../hooks';

const TabAttributeSet = () => {
  const category = useContext(CategoryContext);

  const {
    data: { variants, spesifications, attributeSetFrom },
    methods: {
      addSpesification,
      onChangeSpesification,
      removeSpesification,
      addVariants,
      onChangeVariant,
      removeVariants,
      setAttributeSetFrom
    }
  } = category();
  return (
    <div id="tab-attribute-set" className="border border-black px-6 py-8">
      <div className="flex gap-4 items-center py-4">
        <div className="font-medium min-w-max">Tambahkan Attribute Set dari</div>
        <SelectInput
          openLabel=""
          emptyResultLabel="No result found"
          onChange={setAttributeSetFrom}
          placeholder="-- Tidak Ada --"
          value={attributeSetFrom}
        >
          <SelectInput.Option title="" value="-">
            -
          </SelectInput.Option>
          <SelectInput.Option title="" value="--">
            -
          </SelectInput.Option>
        </SelectInput>
        <button type="button" id="button-attribute-save" onClick={() => {}} className="px-10 py-3 bg-azure rounded-md text-white">
          Terapkan
        </button>
      </div>
      <div id="attribute-spec" className="border-t-2 border-black py-8">
        <p className="font-medium text-black mb-2">1. Spesifikasi</p>
        <div className="pl-4 flex flex-col gap-2">
          <p>Spesifikasi dari Kategori Parent akan ikut dalam kategori ini dan tidak dapat diubah</p>
          {spesifications.map((specs: unknown, index: number) => (
            <div className="flex gap-20 w-full items-center" key={`specs-${index}`}>
              <div className="w-2/5">
                <SelectInput
                  openLabel=""
                  emptyResultLabel="No result found"
                  onChange={(value: string) => onChangeSpesification(value, index)}
                  placeholder="Pilih Spesifikasi"
                  value={specs?.['value']}
                  readOnly={specs?.['isParent']}
                >
                  <SelectInput.Option title="" value="-">
                    -
                  </SelectInput.Option>
                  <SelectInput.Option title="" value="--">
                    -
                  </SelectInput.Option>
                </SelectInput>
              </div>
              {!specs?.['isParent'] && (
                <div className="cursor-pointer" onClick={() => removeSpesification(index)}>
                  <DeleteIcon color="#000" size={24} />
                </div>
              )}
            </div>
          ))}
          <div className="font-medium cursor-pointer" onClick={addSpesification}>
            + Tambah Spesifikasi
          </div>
        </div>
      </div>
      <div id="attribute-variant" className="border-t-2 border-black py-8">
        <p className="font-medium text-black mb-2">2. Varian</p>
        <div className="pl-4 flex flex-col gap-2">
          <p>Varian dari Kategori Parent akan ikut dalam kategori ini dan tidak dapat diubah</p>
          {variants.map((variant: unknown, index: number) => (
            <div className="flex gap-20 w-full items-center" key={`variant-${index}`}>
              <div className="w-2/5">
                <SelectInput
                  openLabel=""
                  emptyResultLabel="No result found"
                  onChange={(value: string) => onChangeVariant(value, index)}
                  placeholder="Pilih Varian"
                  value={variant?.['value']}
                  readOnly={variant?.['isParent']}
                >
                  <SelectInput.Option title="" value="-">
                    -
                  </SelectInput.Option>
                  <SelectInput.Option title="" value="--">
                    -
                  </SelectInput.Option>
                </SelectInput>
              </div>
              {!variant?.['isParent'] && (
                <div className="cursor-pointer" onClick={() => removeVariants(index)}>
                  <DeleteIcon color="#000" size={24} />
                </div>
              )}
            </div>
          ))}
          <div className="font-medium cursor-pointer" onClick={addVariants}>
            + Tambah Varian
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabAttributeSet;
