import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { Nullable } from 'primereact/ts-helpers';
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { useNavigate, useParams } from 'react-router-dom';
import { ChipsChangeEvent } from 'primereact/chips';
import {
  deletePresetVariants,
  insertVariantPreset,
  PayloadVariantPreset,
  upsertVariants,
  useVariantsById,
  Variants
} from '@/services/rest/variant';
import { useFormik } from 'formik';
import { schemaCreateVariants } from './validation';
import { InputSwitchChangeEvent } from 'primereact/inputswitch';

export interface FormCreateVariantInterface {
  name: string;
  value: string[];
  label: string;
  description: string;
  type: string;
  is_active: boolean;
  instruction: string;
}

export interface Values {
  id: number;
  label: string;
}

export interface Categories {
  id: number;
  name: string;
}
type InitialFormik = {
  name: string;
  value: Array<string>;
  label: string;
  type: string;
};
const useVariant = () => {
  const initialFormikState: InitialFormik = {
    name: '',
    value: [],
    label: '',
    type: ''
  };
  const [selectedCategory, setSelectedCategory] = useState<Categories | null>(null);
  const [variantValues, setVariantValues] = useState<Nullable[] | string[]>([]);
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const params = useParams();

  const formik = useFormik({
    initialValues: initialFormikState,
    validationSchema: schemaCreateVariants,
    onSubmit: () => {
      validateNameVariant();
    }
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const { data: dataDetailVariants } = useVariantsById(params.id || params.detailId);

  const detailVariants = useMemo(() => {
    return dataDetailVariants?.data;
  }, [dataDetailVariants]);


  const showSuccess = (msg:string) => {
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: msg,
      life: 2000
    });
    setTimeout(() => {
      navigate('/variant');
    }, 2000);
  };
  const showError = () => {
    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to create new variant', life: 3000 });
  };

  const showErrorDuplicateName = () => {
    toast.current?.show({
      severity: 'error',
      summary: 'Error',
      detail: `${formCreateVariant.name} already exist!`,
      life: 3000
    });
  };

  const initialFormVariant: FormCreateVariantInterface = {
    name: '',
    value: [],
    instruction: '',
    description: '',
    is_active: false,
    label: '',
    type: ''
  };

  const [formCreateVariant, setFormCreateVariant] = useState<FormCreateVariantInterface>(initialFormVariant);

  const resetFormCreateVariant = () => {
    setFormCreateVariant(initialFormVariant);
    setVariantValues([]);
    setSelectedCategory({ id: 0, name: '' });
  };

  const onChangeFormVariantValue =
    (key: string) =>
      ({ currentTarget }: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement, Event>) => {
        formik.setFieldValue(key, currentTarget.value);
        setFormCreateVariant((prevState) => {
          return {
            ...prevState,
            [key]: currentTarget.value
          };
        });
      };

  const onChangeToggle = (event: InputSwitchChangeEvent) => {
    formik.setFieldValue('is_active', event.value);
    setFormCreateVariant((prev) => ({
      ...prev,
      is_active: Boolean(event.value)
    }));
  };

  const onChangeChips = (e: ChipsChangeEvent) => {
    formik.setFieldValue('value', e.value);
    setFormCreateVariant((prev) => ({
      ...prev,
      value: e.value as []
    }));
  };

  const validateNameVariant = async () => {
    if (params.id) {
      const { data } = await supabase.from(TABLE.VARIANTS).select('name').ilike('name', `%${formCreateVariant.name}%`).neq('id', params.id).limit(20);
      if (data != null) {
        if (data.length > 0) {
          showErrorDuplicateName();
        } else {
          onSubmitForm();
        }
      }
      return;
    }
    const { data } = await supabase.from(TABLE.VARIANTS).select('name').ilike('name', `%${formCreateVariant.name}%`).limit(20);
    if (data != null) {
      if (data.length > 0) {
        showErrorDuplicateName();
      } else {
        onSubmitForm();
      }
    }
  };

  const onSubmitForm = useCallback(async () => {
    if (params.id) {
      const { error: errorDeletePreset } = await deletePresetVariants(params.id);
      if (errorDeletePreset) {
        showError();
        return;
      }
    }
    const payload: Variants = params.id ?
      {
        id: Number(params.id),
        description: formCreateVariant.description,
        is_active: formCreateVariant.is_active,
        label: formCreateVariant.label,
        name: formCreateVariant.name,
        type: formCreateVariant.type,
        instruction: formCreateVariant.instruction
      } :
      {
        description: formCreateVariant.description,
        is_active: formCreateVariant.is_active,
        label: formCreateVariant.label,
        name: formCreateVariant.name,
        type: formCreateVariant.type,
        instruction: formCreateVariant.instruction
      };
    const { data, error } = await upsertVariants(payload);
    if (error && data) {
      showError();
      return;
    }
    const payloadVariantsPreset: PayloadVariantPreset[] = formCreateVariant.value.map((v) => {
      return {
        name: v,
        variant_id: data?.id || ''
      };
    });

    const { error: errorVariantPreset } = await insertVariantPreset(payloadVariantsPreset);
    if (errorVariantPreset) {
      showError();
      return;
    }
    if (params.id) {
      showSuccess('Update Variant Successfull');
    } else {
      showSuccess('New Variant Successfull Created');
    }
    resetFormCreateVariant();
    setVariantValues([]);
  }, [formCreateVariant, params]);

  useEffect(() => {
    setFormCreateVariant((prev) => ({
      ...prev,
      description: detailVariants?.description || '',
      instruction: detailVariants?.instruction || '',
      is_active: Boolean(detailVariants?.is_active),
      label: detailVariants?.label || '',
      name: detailVariants?.name || '',
      type: detailVariants?.type || '',
      value: detailVariants?.variant_presets?.map((vp) => vp.name) || []
    }));
    formik.setValues({
      label: detailVariants?.label || '',
      name: detailVariants?.name || '',
      type: detailVariants?.type || '',
      value: detailVariants?.variant_presets?.map((vp) => vp.name) || []
    });
  }, [detailVariants]);

  return {
    data: { formCreateVariant, params, formik, variantValues, toast, selectedCategory },
    methods: {
      validateNameVariant,
      onChangeFormVariantValue,
      setVariantValues,
      onChangeChips,
      resetFormCreateVariant,
      navigate,
      onChangeToggle,
      isFormFieldValid
    }
  };
};

export default useVariant;
