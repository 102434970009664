// import { ReactElement } from 'react';
import { Button } from 'primereact/button';
import { Checkbox } from 'akeneo-design-system';
import useTabAccessSetting from './hooks';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { Toast } from 'primereact/toast';
import CustomAccess from './CustomAccess';
import { usePermissionStore } from '@/store/usePermissions';

const TabAccessSetting = () => {
  const {
    data: { isLoading, displaySettingPermission, id, team, toast, menusData, defaultRenderMenus },
    method: { handleClickCustomAccess, handleClickClosePopUp, handleClickSubmitPopUp, handleChangeCheckboxParent, handleChangeCheckboxChild }
  } = useTabAccessSetting();
  const { canEdit }= usePermissionStore();
  return (
    <>
      {displaySettingPermission && (
        <CustomAccess
          onChangeCheckboxChildren={handleChangeCheckboxChild}
          onChangeCheckboxParent={handleChangeCheckboxParent}
          handleClickClosePopUp={handleClickClosePopUp}
          handleClickSubmitPopUp={handleClickSubmitPopUp}
          team={team}
          data={menusData as []}
          id={Number(id)}
        />
      )}
      {/* loading screen */}
      <LoadingOverlay show={isLoading}/>
      {/* toast */}
      <Toast ref={toast} />
      <div className="grid grid-cols-2 p-5 items-start">
        <div className="flex flex-col gap-8">
          <div id="detail permisions">
            <div className="flex justify-between">
              <h2 className="w-1/2 font-semibold">Feature</h2>
              <div className="flex justify-around w-1/2">
                <span className="font-semibold">Read</span>
                <span className="font-semibold">Create</span>
                <span className="font-semibold">Edit</span>
                <span className="font-semibold">Delete</span>
              </div>
            </div>
          </div>
          <div className="overflow-y-scroll h-[400px]">
            {defaultRenderMenus.map((item, idx) => (
              <div key={idx}>
                <div className="flex justify-between mt-6">
                  <h2 className="w-1/2 font-medium">{item.parent.menus}</h2>
                  <div className="grid grid-cols-4 w-1/2">
                    <div className="flex justify-center">
                      <Checkbox
                        id={`user-read-checkbox-${idx}`}
                        checked={Boolean(item.parent.read)}
                      />
                    </div>
                    <div className="flex justify-center">
                      <Checkbox
                        id={`user-create-checkbox-${idx}`}
                        checked={Boolean(item.parent.create)}
                      />
                    </div>
                    <div className="flex justify-center">
                      <Checkbox
                        id={`user-update-checkbox-${idx}`}
                        checked={Boolean(item.parent.update)}
                      />
                    </div>
                    <div className="flex justify-center">
                      <Checkbox
                        id={`user-delete-checkbox-${idx}`}
                        checked={Boolean(item.parent.delete)}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-8 mt-4">
                  {item.child.map((child, idxChild) => (
                    <div key={idxChild}>
                      <div className="flex justify-between">
                        <h2 className="w-1/2 pl-8">{child.menus}</h2>
                        <div className="grid grid-cols-4 w-1/2">
                          <div className="flex justify-center">
                            <Checkbox
                              id={`user-read-checkbox-${idxChild}`}
                              checked={Boolean(child.read)}
                            />
                          </div>
                          <div className="flex justify-center">
                            <Checkbox
                              id={`user-create-checkbox-${idxChild}`}
                              checked={Boolean(child.create)}
                            />
                          </div>
                          <div className="flex justify-center">
                            <Checkbox
                              id={`user-update-checkbox-${idxChild}`}
                              checked={Boolean(child.update)}
                            />
                          </div>
                          <div className="flex justify-center">
                            <Checkbox
                              id={`user-delete-checkbox-${idxChild}`}
                              checked={Boolean(child.delete)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>


        <div className="flex justify-end">
          <Button
            disabled={!canEdit}
            onClick={handleClickCustomAccess}
            id="user-custom-akses"
            label="Custom Access" />
        </div>
      </div>
    </>
  );
};

export default TabAccessSetting;
