import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import useCustom from './hooks';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthStore } from '@/store/useAuthStore';
import { shallow } from 'zustand/shallow';
import { Toast } from 'primereact/toast';

const Login = () => {
  const { data, method } = useCustom();
  const { user, login } = useAuthStore(
    (state) => ({ user: state.user, login: state.login }),
    shallow
  );

  const accessLocal = localStorage.getItem('previous_access_token');

  const location = useLocation();

  if (user !== null || accessLocal !== null) return <Navigate to="/" state={{ from: location }} replace />;

  return (
    <div className="h-screen w-screen flex items-center justify-center bg-gray-900 text-gray-100">
      <div className="flex flex-col max-w-md p-6 rounded-md sm:p-10">
        <div className="mb-8 text-center">
          <h1 className="font-bold lg:text-7xl md:text-5xl flex justify-center">B O S</h1>
          <p className="text-sm dark:text-gray-400">Login to access your account</p>
        </div>
        <Toast ref={data.toast} />
        <form noValidate action="" className="space-y-12 ng-untouched ng-pristine ng-valid">
          <div className="space-y-4">
            <div>
              <label htmlFor="email" className="block mb-2 text-sm">
                Email address
              </label>
              <InputText
                id="email"
                type="email"
                name="email"
                className="w-full"
                value={data.email}
                onChange={(e) => method.setEmail(e.target.value)}
              />
            </div>
            <div>
              <div className="flex justify-between mb-2">
                <label htmlFor="password" className="text-sm">
                  Password
                </label>
              </div>
              <Password
                id="password"
                type="password"
                name="password"
                className="w-full"
                inputClassName="w-full"
                value={data.password}
                onChange={(e) => method.setPassword(e.target.value)}
                feedback={false}
                toggleMask
              />
            </div>
          </div>
          <div className="space-y-2 flex flex-col items-center">
            <Button
              type="submit"
              label="Login"
              severity="success"
              onClick={(e) => {
                e.preventDefault();
                login(data.email, data.password, method.showError);
                // errorMessage && method.showError(errorMessage);
              }}
              text
            />
            {/* <p className="px-6 text-sm text-center dark:text-gray-400">
              Don&apos;t have an account yet?{' '}
              <a rel="noopener noreferrer" href="/register" className="hover:underline dark:text-violet-400">
                Register
              </a>
              .
            </p> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
