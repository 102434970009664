import React from 'react';
import useCustom from './hooks';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect } from 'primereact/multiselect';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar, CalendarSelectEvent } from 'primereact/calendar';
// import { Editor } from 'primereact/editor';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { Toast } from 'primereact/toast';

const PromoOngkir = () => {
  const { data, method } = useCustom();
  const navigate = useNavigate();
  const titles = data.id !== undefined ? 'Edit Promo Ongkir' : 'Create Promo Ongkir';

  const getFormErrorMessage = (name) => {
    return method.isFormFieldValid(name) && <small className="p-error">{data.formik.errors[name]}</small>;
  };
  return (
    <BaseContainer>
      <MainTitle title={titles} nav="" />
      <Toast ref={data.toast}/>

      {/* loading screen */}
      <LoadingOverlay show={data.isLoadingCreate} />

      <div id="form group">
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="input-promo-name" className="w-1/6">
            Postage Promo Name*
          </label>
          <div className="card w-1/3">
            <InputText
              required id="promo-input-name"
              name="promoName"
              className="w-full" placeholder="Postage Promo Name"
              value={data.firstForm.name}
              onChange={(e) => {
                data.formik.setFieldValue('promoName', e.target.value);
                method.firstFormValue('name', e.target.value);
              }}
            />
            <div className="pt-1 pl-1">
              {getFormErrorMessage('promoName')}
            </div>
          </div>
        </div>
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="hipping_provider_selection" className="w-1/6">
            Choose Shipping Provider<span>*</span>
          </label>
          <div className="card w-1/3">
            <MultiSelect
              id="promo-shipping-provider-selection-input"
              name="selectCourier"
              value={data.firstForm.provider}
              onChange={(e) => {
                data.formik.setFieldValue('selectCourier', e.value);
                method.firstFormValue('provider', e.value);
              }}
              options={data.providers}
              optionLabel="name"
              placeholder="Select Shipping Providers"
              maxSelectedLabels={3}
              className="w-full md:w-20rem"
              optionValue="id"
            />
            <div className="pt-1 px-1">
              {getFormErrorMessage('selectCourier')}
            </div>
          </div>
        </div>
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="shipping_method_selection" className="w-1/6">
            Choose Shipping Method<span>*</span>
          </label>
          <div className="card w-1/3">
            <MultiSelect
              optionValue="id"
              id="promo-shipping-method-selection-input"
              name="selectService"
              value={data.firstForm.methods}
              onChange={(e) => {
                data.formik.setFieldValue('selectService', e.value);
                method.firstFormValue('methods', e.value);
              }}
              options={data.shipMethod}
              optionLabel="name"
              placeholder="Select Shipping Method"
              maxSelectedLabels={3}
              className="w-full md:w-20rem"
            />
            <div className="pt-1 px-1">
              {getFormErrorMessage('selectService')}
            </div>
          </div>
        </div>
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="select_platform" className="w-1/6">
            Platform*
          </label>
          <div className="card w-1/3">
            <Checkbox
              checked={data.firstForm.isAllPlatform}
              onChange={(e: CheckboxChangeEvent) => method.firstFormValue('isAllPlatform', e.checked as boolean)}
            />
            <label className="ml-5">All Platform</label>
          </div>
        </div>
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="select_customer" className="w-1/6">
            Customer Group*
          </label>
          <div className="card w-1/3 flex">
            <Checkbox
              checked={data.firstForm.isRegisteredCustomer}
              onChange={(e: CheckboxChangeEvent) => method.firstFormValue('isRegisteredCustomer', e.checked as boolean)}
            />
            <label className="ml-5">Registered Customer</label>
          </div>
        </div>
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="start_date" className="w-1/6">
            Valid From*
          </label>
          <div className="card justify-content-center w-1/3">
            <Calendar
              value={data.firstForm.startDate}
              name="startDate"
              showTime hourFormat="24"
              dateFormat="dd/mm/yy"
              id="promo-start-date-input"
              hideOnDateTimeSelect
              onSelect={(e : CalendarSelectEvent) => {
                data.formik.setFieldValue('startDate', e.value);
                method.firstFormValue('startDate', e.value as string);
              }}
              showIcon
              minDate={new Date()}
              maxDate={new Date(data.firstForm.endDate)}
            />
            <div className="pt-1 px-1">
              {getFormErrorMessage('startDate')}
            </div>
          </div>
        </div>
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="end_date" className="w-1/6">
            Valid To*
          </label>
          <div className="card justify-content-center w-1/3">
            <Calendar
              value={data.firstForm.endDate}
              name="endDate"
              showTime
              hourFormat="24"
              dateFormat="dd/mm/yy"
              id="promo-end-date-input"
              hideOnDateTimeSelect
              onSelect={(e : CalendarSelectEvent) => {
                data.formik.setFieldValue('endDate', e.value);
                method.firstFormValue('endDate', e.value as string);
              }}
              showIcon
              disabled={!Boolean(data.firstForm.startDate)}
              minDate={data.firstForm.startDate == '' ? new Date() : new Date(data.firstForm.startDate)}
            />
            <div className="pt-1 px-1">
              {getFormErrorMessage('endDate')}
            </div>
          </div>
        </div>
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="status" className="w-1/6">
            Status*
          </label>
          <div className="card flex justify-content-center w-1/3">
            <InputSwitch
              name="status"
              checked={data.firstForm.is_active}
              onChange={(e) => {
                data.formik.setFieldValue('status', e.value);
                method.firstFormValue('is_active', e.value as boolean);
              }}
              id="promo-status-input"
            />
            {getFormErrorMessage('status')}
          </div>
        </div>
        <div className="py-3 flex gap-8">
          <label htmlFor="input_description" className="w-1/6 mt-2">
            Promo Description
          </label>
          <div className="card flex justify-content-center w-1/3">
            {/* <Editor value={data.firstForm.description} id='promo-description-input'
              onTextChange={(e) => method.firstFormValue('description', e.htmlValue as string)}
              style={{ height: '200px' }} showHeader={false} modules={{
                toolbar: [
                  [{ 'header': [1, 2, 3, 4, false] }],
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  [{ 'script': 'sub' }, { 'script': 'super' }],
                  [{ 'indent': '-1' }, { 'indent': '+1' }],
                  [{ 'direction': 'rtl' }],
                  [{ 'align': [] }],
                  ['clean']
                ]
              }}/> */}
            <InputTextarea value={data.firstForm.description} id="promo-description-input"
              onChange={(e)=> method.firstFormValue('description', e.target.value as string)}
              rows={5} cols={40}
            />
          </div>
        </div>
        <div className="flex justify-between items-center">
          <label htmlFor="label" className="flex items-center font-bold mt-8 mb-3">
          Usage Quota
          </label>
          <Button type="button" icon={data.collapsible.quota? 'pi pi-minus' : 'pi pi-plus'} onClick={()=> method.onCollapse('quota', !data.collapsible.quota)} rounded text severity="secondary" />
        </div>
        <hr className="w-full font-bold text-solid bg-black h-1" />
        {data.collapsible.quota &&
          <>
            <div className="py-3 mt-4 flex gap-8 items-center">
              <label htmlFor="input_promo_quota" className="w-1/6">
              Promo Quota*
              </label>
              <div className="card w-1/3">
                <InputNumber
                  required
                  name="promoQuota"
                  value={data.secondForm.quota}
                  onChange={(e) => {
                    data.formik.setFieldValue('promoQuota', e.value);
                    method.secondFormValue('quota', e.value as number);
                  }}
                  id="promo-quota-input"
                  className="w-full"
                  placeholder="Input Kuota Promo" />
                {getFormErrorMessage('promoQuota')}
              </div>
            </div>
            <div className="py-3 flex gap-8 items-center">
              <label htmlFor="promo-user-quota-input-label" className="w-1/6">
                Usage per User*
              </label>
              <div className="card w-1/3">
                <InputNumber
                  required
                  id="promo-user-quota-input"
                  name="userUsage"
                  value={data.secondForm.is_user_quota_unlimited ? data.secondForm.user_quota = 0 : data.secondForm.user_quota}
                  onChange={(e) => {
                    data.formik.setFieldValue('userUsage', e.value);
                    method.secondFormValue('user_quota', e.value as number);
                  }}
                  className="w-full"
                  placeholder="Input Penggunaan Per User"
                  disabled={data.secondForm.is_user_quota_unlimited} />
                {getFormErrorMessage('userUsage')}
              </div>
              <div className="flex ml-4">
                <Checkbox
                  id="voucher-is-user-quota-unlimited-input"
                  onChange={(e: CheckboxChangeEvent) => {
                    method.secondFormValue('is_user_quota_unlimited', e.checked as boolean);
                    if (e.checked) {
                      data.formik.setFieldValue('userUsage', 0);
                      method.secondFormValue('user_quota', 0 as number);
                    }
                  }}
                  checked={data.secondForm.is_user_quota_unlimited} />
                <label htmlFor="is-quota-unlimited" className="ml-2">
                  No Usage Limit
                </label>
              </div>
            </div>
          </>
        }
        <div className="flex justify-between mt-8 items-center">
          <label htmlFor="label" className="flex items-center font-bold">
              Discount and Condition
          </label>
          <Button type="button" icon={data.collapsible.condition? 'pi pi-minus' : 'pi pi-plus'} onClick={()=> method.onCollapse('condition', !data.collapsible.condition)} rounded text severity="secondary" />
        </div>
        <hr className="w-full font-bold text-solid bg-black h-1" />
        {data.collapsible.condition &&
        <>
          <div className="flex gap-8 mt-4 py-3 items-center">
            <label htmlFor="input_min_payment" className="w-1/6">
              Minimum Subtotal Order*
            </label>
            <div className="card w-1/3">
              <InputNumber
                required
                value={data.secondForm.is_no_minimum_subtotal ? data.secondForm.min_payment_amount = 0 : data.secondForm.min_payment_amount}
                name="minOrder"
                onChange={(e) => {
                  data.formik.setFieldValue('minOrder', e.value);
                  method.secondFormValue('min_payment_amount', e.value as number);
                }}
                disabled={data.secondForm.is_no_minimum_subtotal}
                id="promo-min-payment-input"
                className="w-full" />
              {getFormErrorMessage('minOrder')}
            </div>
            <div className="flex ml-4">
              <Checkbox
                id="is-no-minimum-subtotal-input"
                onChange={(e: CheckboxChangeEvent) => {
                  method.secondFormValue('is_no_minimum_subtotal', e.checked as boolean);
                  if (e.checked) {
                    data.formik.setFieldValue('minOrder', 0);
                    method.secondFormValue('min_payment_amount', 0 as number);
                  }
                }}
                checked={data.secondForm.is_no_minimum_subtotal} />
              <label htmlFor="is-no-minimum-subtotal" className="ml-2">
                No Minimum Subtotal Order
              </label>
            </div>
          </div><div className="flex items-center gap-8">
            <label htmlFor="discount_type" className="w-1/6">
              Discount Amount*
            </label>
            <div className="w-3/4">
              <div className="py-3 flex w-full gap-4 items-center">
                <InputNumber
                  value={data.secondForm.amount_discount}
                  className="w-1/3"
                  name="ammountDiscount"
                  id="promo-amount-input"
                  onChange={(e) => {
                    data.formik.setFieldValue('ammountDiscount', e.value);
                    method.secondFormValue('amount_discount', e.value as number);
                  }} />
              </div>
              <div>
                {getFormErrorMessage('ammountDiscount')}
              </div>
            </div>
          </div>
        </>
        }
      </div>
      <div id="promo-action-button" className="w-full flex mt-4 justify-end gap-6">
        <SubmitButton
          onCancel={() => {
            method.resetAllForm();
            navigate('/ongkir');
          }}
          onSubmit={() => {
            data.formik.handleSubmit();
          }}
          labelBack="Cancel"
          labelSubmit="Submit"
        />
      </div>
    </BaseContainer>
  );
};

export default PromoOngkir;
