import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useQueryGroup from '../repositoryUserManagement';

interface useDetailUserMangementInterface {
  data: {
    isLoading: boolean;
    team: {
      [key: string]: string;
    } | null;
    idGroup: number;
  };
  method: {};
}

const useDetailUserMangement = (): useDetailUserMangementInterface => {
  const [idGroup, setIdGroup] = useState<number>(NaN);
  const [team, setTeam] = useState<{
    [key: string]: string;
  } | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id } = useParams();
  const { getGroupWithCount } = useQueryGroup();

  const getGroup = async () => {
    const selectedFilter: Array<string> = [];
    if (id) {
      setIsLoading(true);
      selectedFilter.push(id);
      const { teams } = await getGroupWithCount('', selectedFilter, { start: 0, end: 10 }, null);

      setIdGroup(teams && teams[0]?.id);

      if (teams) {
        const teamsMapper = teams?.map((item) => {
          return {
            id: item.id,
            name: item.name,
            count: (item && item?.members[0] && item?.members[0].count) || 0,
            created_at: dayjs(item.created_at).format('DD MMM YYYY'),
            code: item.code,
            status: item.status,
            description: item.description || '-'
          };
        });
        setTeam(teamsMapper[0]);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    getGroup();
  }, []);

  return {
    data: {
      isLoading,
      team,
      idGroup
    },
    method: {}
  };
};

export default useDetailUserMangement;
