import { ArrowSimpleUpIcon, Modal, SelectInput } from 'akeneo-design-system';
import React, { useContext } from 'react';
import { CategoryContext, CategoryProductInterface } from '../hooks';
interface ModalMoveCategoryProps {
  onMove: () => void;
  onCancel: () => void;
  product: CategoryProductInterface;
}
const ModalMoveCategory = ({ product, onMove, onCancel }: ModalMoveCategoryProps) => {
  const category = useContext(CategoryContext);

  const {
    data: { moveCategoryForm },
    methods: { onSelectNewCategory, onChangeNotifyMove }
  } = category();
  return (
    <Modal id="modal-product-category" className="modal-move-category" onClose={() => {}} closeTitle="close">
      <div className="py-4 px-4 flex flex-col gap-8 w-7/12 ml-80 font-sans text-base">
        <div className="w-full text-left">
          <p className="text-black font-medium">Pindah Kategori</p>
        </div>

        <div className="w-full flex text-black">
          <div className="w-1/4">{product.sku}</div>
          <div className="w-1/4">{product.productName}</div>
          <div className="flex-1">Nama Merchant</div>
        </div>
        <div className="w-full flex text-black">
          <div className="w-1/4 font-medium">Kategori Sekarang</div>
          <div className="w-1/4" />
          <div className="flex-1">{product.category}</div>
        </div>
        <div className="w-full flex text-black items-center">
          <div className="w-1/4 font-medium">Kategori Baru</div>
          <div className="w-1/4">
            <ArrowSimpleUpIcon className="rotate-90 ml-auto mr-4" color="#000" size={24} />
          </div>
          <div className="flex-1">
            <SelectInput
              id="select-new-category"
              openLabel=""
              emptyResultLabel="No result found"
              onChange={onSelectNewCategory}
              placeholder="-"
              value={moveCategoryForm.newCategory}
            >
              <SelectInput.Option title="" value="Fashion Pria dan Wanita > Atasan Pria">
                Fashion Pria dan Wanita &lt; Atasan Pria
              </SelectInput.Option>
              <SelectInput.Option title="" value="Fashion Pria dan Wanita > Atasan Wanita">
                Fashion Pria dan Wanita &lt; Atasan Wanita
              </SelectInput.Option>
            </SelectInput>
          </div>
        </div>
        <div className="flex items-center gap-4 text-black w-full">
          <input type="checkbox" name="notifMerchant" id="notif-merchant" onChange={onChangeNotifyMove} />
          <label className="cursor-pointer" htmlFor="notif-merchant">
            Notifikasi Merchant untuk memindahkan kategori ini
          </label>
        </div>
        <div id="action-move-category" className="w-full flex justify-end gap-6">
          <button
            type="button"
            id="move-category-cancel"
            className="px-10 py-3 bg-[#F1F2F4] rounded-md text-dark-electric-blue"
            onClick={onCancel}
          >
            <p className="font-sans">Batal</p>
          </button>
          <button type="button" id="move-category-submit" className="px-10 py-3 bg-azure rounded-md text-white" onClick={onMove}>
            <p className="font-sans">Lanjutkan</p>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalMoveCategory;
