import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { ReactElement } from 'react';
import useDetailUserMangement from './hooks';
import TabAccessSetting from './TabAccessSetting/TabAccessSetting';
import TabGroupInformation from './TabGroupInformation/TabGroupInformation';
import TabGroupMember from './TabGroupMember/TabGroupMember';

const DetailUserManagement = (): ReactElement => {
  const {
    data: { isLoading, team }
  } = useDetailUserMangement();
  return (
    <>
      <BaseContainer>
        <MainTitle title={`${team?.name}` || 'Teams'} isWithButton={false} />
        <TabView className="mt-1">
          <TabPanel header="Group Information">
            <TabGroupInformation isLoading={isLoading} form={team} />
          </TabPanel>
          <TabPanel header="Group Member">
            <TabGroupMember />
          </TabPanel>
          <TabPanel header="Access Setting">
            <TabAccessSetting />
          </TabPanel>
        </TabView>
      </BaseContainer>
    </>
  );
};

export default DetailUserManagement;
