import { useDeleteLayout, useGetListLayout } from '@/services/rest/pageBuilder';
import dayjs from 'dayjs';
import { confirmDialog } from 'primereact/confirmdialog';
import { useDeleteProductTabulationByLayoutId } from '@/services/rest/pageBuilder';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { useHistoryStore } from '@/store/useHistoryStore';
import { IFilterHistoryItems } from '@/components/base/FilterHistory';
import _ from 'lodash';

export interface ILayoutList {
  id: number
  name: string | null
  created_at: string
  updated_at: string
  iconImage: string
}

interface ISelectedDataIds {
  id: string
}

const initialFilter = {
  street_id_from: 0,
  street_id_to: 0,
  street_name: '',
  created_at: [],
  updated_at: []
};

const useCustom = () => {
  const [search, setSearch] = useState<string>('');
  const [searchInput, setSearchInput] = useState<string>('');
  const [ids, setIds] = useState<number[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedDataPageBuilder, setSelectedDataPageBuilder] = useState<ISelectedDataIds[]>([]);
  const [perPage, setPerPage] = useState<number>(10);
  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const [itemFilters, setItemFilters] = useState(lastFilterPage.pageBuilder != '' ? JSON.parse(String(lastFilterPage.pageBuilder)) : initialFilter);
  const [filters, setFilters] = useState(lastFilterPage.pageBuilder != '' ? JSON.parse(String(lastFilterPage.pageBuilder)) : initialFilter);
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);
  const currentPage = parseInt(visitedPage.pageBuilder.toString()) ?? 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : perPage - 1;
  const [paginator, setPaginator] = useState({ currentPage, range: { start, end } });
  const [jumpToPage, setJumpToPage] = useState<number>(1);

  // get list layout
  const { data: responseListLayout, refetch: refetchListLayout, isLoading: isLoadingListlayout } = useGetListLayout(paginator.range, search, filters);

  // delete layout
  const { mutate: mutateDeleteLayout } = useDeleteLayout(refetchListLayout);

  // delete product tabulation by layout id
  const { mutate: muatateDeleteProductTabulation } = useDeleteProductTabulationByLayoutId();

  const dataListLayout = useMemo(() => {
    return Array.isArray(responseListLayout?.data) ?
      responseListLayout?.data?.map((item): ILayoutList => {
        const attr = JSON.parse(JSON.stringify(item?.attribute));
        return {
          id: item.id,
          name: item.section_name,
          created_at: dayjs(item.created_at).format('DD/MM/YYYY'),
          updated_at: dayjs(item.updated_at).format('DD/MM/YYYY'),
          iconImage: attr?.image
        };
      }) :
      [];
  }, [responseListLayout]);


  const onCheckboxClick = useCallback(
    (data: number) => () => {
      const listIds = ids;
      if (ids.includes(data)) {
        const index = ids.findIndex((index) => index === data);
        listIds.splice(index, 1);
        setIds([...listIds]);
      } else {
        ids.push(data);
        setIds([...listIds]);
      }
    },
    [ids, setIds]
  );

  const handleOnChangeSearchInput = useCallback(
    _.debounce((e) => {
      setSearch(e.target.value);
      const newKeyword = {
        name: 'Keyword',
        items: [{
          label: e.target.value,
          value: e.target.value,
          name: e.target.value
        }]
      };

      if (e.target.value != '') {
        setFilterHistory((prev: IFilterHistoryItems[]) => {
          const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
          if (existingIndex !== -1) {
            prev[existingIndex] = newKeyword;
          } else {
            prev.push(newKeyword);
          }
          return [...prev];
        });
      }
    }, 1000), []);

  const onSearchChange = useCallback(
    (searchValue: string) => {
      setSearchInput(searchValue);
    },
    [setSearchInput]
  );

  const handleDeleteList = useCallback((id: number) => {
    confirmDialog({
      message: 'Are you sure you want to delete street?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept() {
        mutateDeleteLayout(id);
        muatateDeleteProductTabulation(id);
      },
      reject() {

      }
    });
  }, [confirmDialog]);

  const onCloseModal = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  const totalRecords = useMemo(() => {
    return responseListLayout?.count ? responseListLayout.count : 0;
  }, [responseListLayout]);

  const totalPages = useMemo(() => {
    return Math.ceil(totalRecords / perPage);
  }, [totalRecords, perPage]);


  const handleClickNext = useCallback(() => {
    paginator.currentPage <= totalPages &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: paginator.currentPage + 1,
        range: {
          start: paginator.range.start + perPage,
          end: paginator.range.end + perPage
        }
      }));
  }, [paginator, totalPages, perPage]);

  const handleClickPrev = useCallback(() => {
    paginator.range.start > 0 &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: prev.currentPage - 1,
        range: {
          start: prev.range.start - perPage,
          end: prev.range.end - perPage
        }
      }));
  }, [paginator, perPage]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent) => {
    setPerPage(event.value);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, totalRecords - 1);

      setPaginator(() => ({
        currentPage: jumpToPage > totalPages ? totalPages : value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, totalRecords]);

  const handleFilter = useCallback((e, field: string) => {
    setItemFilters((prev) => ({
      ...prev,
      [field]: e.target ? e.target.value : e.value
    }));
  }, []);

  const handleClickSubmitFilter = useCallback(() => {
    setFilters(itemFilters);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: perPage - 1 }
    }));
  }, [itemFilters, perPage]);

  const storeFilterHistory = useCallback((filter) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter.street_id_from !== 0) {
      let filterID = filter.street_id_from.toString();
      if (filter.street_id_to !== 0) {
        filterID = `${filter.street_id_from}-${filter.street_id_to}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Street ID', 'id_from,id_to', filterID));
    }

    if (filter?.created_at && filter.created_at[0]) {
      let filterDate = `${dayjs(filter.created_at[0]).format('YYYY-MM-DD')}`;
      if (filter.created_at.length > 1 && filter.created_at[1] !== null) {
        filterDate = `${dayjs(filter.created_at[0]).format('YYYY-MM-DD')} - ${dayjs(filter.created_at[1]).format('YYYY-MM-DD')}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Created Date', 'created_at', filterDate));
    }

    if (filter?.updated_at && filter.updated_at[0]) {
      let filterDate = `${dayjs(filter.updated_at[0]).format('YYYY-MM-DD')}`;
      if (filter.updated_at.length > 1 && filter.updated_at[1] !== null) {
        filterDate = `${dayjs(filter.updated_at[0]).format('YYYY-MM-DD')} - ${dayjs(filter.updated_at[1]).format('YYYY-MM-DD')}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Updated Date', 'updated_at', filterDate));
    }

    if (filter.street_name !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Street Name', 'street_name', filter.street_name));
    }

    setFilterHistory(filterHistoryItems);
  }, []);

  const handleClearFilter = useCallback(() => {
    setLastFilterPage({
      ...lastFilterPage,
      pageBuilder: ''
    });
    setFilters(initialFilter);
    setItemFilters(initialFilter);
    setFilterHistory([]);
    setSearch('');
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [perPage]);

  const handleDeleteFilterHistory = useCallback(
    (_: string, value: string[]) => {
      const items = value[0].split(',');
      items.forEach((i) => {
        setFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));

        setItemFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));
      });

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setSearch('');
    },
    []
  );

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  // update store lastFilterPage with latest filter
  useEffect(() => {
    setLastFilterPage({
      ...lastFilterPage,
      pageBuilder: JSON.stringify(filters)
    });
  }, [filters]);

  useEffect(() => {
    // update store visitedPage with latest page
    setVisitedPage({
      ...visitedPage,
      pageBuilder: paginator.currentPage
    });
  }, [paginator.currentPage]);

  return {
    data: {
      ids,
      search,
      openModal,
      searchInput,
      dataListLayout,
      isLoadingListlayout,
      selectedDataPageBuilder,
      paginator,
      perPage,
      totalPages,
      totalRecords,
      itemFilters,
      filterHistory
    },
    methods: {
      setSearch,
      onCloseModal,
      onSearchChange,
      onCheckboxClick,
      handleDeleteList,
      handleClickNext,
      handleClickPrev,
      handleChangeDropdownPage,
      handleChangeJumpTopage,
      handleJumpToPage,
      setSelectedDataPageBuilder,
      handleOnChangeSearchInput,
      handleFilter,
      handleClickSubmitFilter,
      handleClearFilter,
      handleDeleteFilterHistory
    }
  };
};

export default useCustom;
