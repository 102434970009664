import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PRODUCT_HIGHTLIGHT_SORT_TYPE, TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { DataTableSelection, DataTableSelectionChangeEvent } from 'primereact/datatable';
import _, { concat, orderBy, uniq } from 'lodash';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { IProduct } from '@/services/rest/product';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { useHistoryStore } from '@/store/useHistoryStore';
import dayjs from 'dayjs';
import { IFilterHistoryItems } from '@/components/base/FilterHistory';

export interface IProductTabulations {
    id: number;
    product_variant_id: string;
    product_tabulation_id: number;
    created_at: Date;
    updated_at: Date;
    product_id: number;
    started_time: Date;
    ended_time: Date;
    weight: number | null;
    sort_type: string;
    is_active: boolean;
    products: Products;
}

export interface Products {
  sku: string;
  name: string;
}


export interface Highlight {
  id: number;
  product_variants: ProductVariant;
  product_variant_id: string;
  products: Product;
  weight?: string;
  sort_type?: string;
  status: string;
  started_time: Date;
  ended_time: Date;
  created_at: Date;
}
export interface ProductVariant {
  sku_id: string;
  products: Product
}
export interface Product {
  id?: number;
  name: string;
  sku?: string;
}
export interface IHighlight {
  ids:number;
  product_variant_id: string;
  name: string;
  sku: string;
  section: string;
  street: string;
  type: string;
  started_time: Date;
  ended_time: Date;
  created_at: Date
}

export interface ISelectedProduct {
  id?: number;
  ids: number;
  name: string;
  product_id: number;
  section: string;
  started_time: Date;
  ended_time: Date;
  status: string;
  street: string;
}
export interface HotDeals {
  id: number;
  product_id: number;
  status: string;
  started_time: Date;
  ended_time: Date;
  merchant_id?: string;
  products: Product;
  created_at: Date;
}

interface Data {
  label: string;
  name:string;
  value: string | boolean;
}

const initialOptions = [{
  label: '',
  items: []
}];

interface Options {
  label: string;
  items: Data[]
}

export const OptionsDropdown = [
  {
    label: 'All Sections',
    value: 'all'
  },
  {
    label: 'Product Pilihan',
    value: 'product_pilihan'
  },
  {
    label: 'Product Tabulation',
    value: 'product_tabulation'
  },
  {
    label: 'Product Hot Deal',
    value: 'product_hot_deal'
  },
  {
    label: 'Official Store',
    value: 'official_store'
  }
];


export const OptionsDropdown1 = [
  {
    label: 'Product Pilihan',
    value: 'product_pilihan',
    name: 'section'
  },
  {
    label: 'Product Tabulation',
    value: 'product_tabulation',
    name: 'section'
  },
  {
    label: 'Product Hot Deal',
    value: 'product_hot_deal',
    name: 'section'
  },
  {
    label: 'Official Store',
    value: 'official_store',
    name: 'section'
  }
];

interface ProductHighlight {
  id: number;
  product_id: number;
  name: string | undefined;
  sku: string | undefined;
  section: string;
  street: string;
  type: string;
  status: string;
  started_time: Date;
  ended_time: Date;
  created_at: Date;
}

interface IFilterProductHighlight {
  name: string;
  sku: string;
  section: string;
  sort_type: string;
  status: string;
  valid_date: Date[];
}

const useCustom = () => {
  const initialFilter: IFilterProductHighlight = {
    name: '',
    sku: '',
    section: '',
    sort_type: '',
    status: '',
    valid_date: []
  };

  const [selectedProductHightlight, setSelectedProductHightlight] = useState<ISelectedProduct[]>([]);
  const [highlight, setHighlight] = useState<ProductHighlight[]>([]);
  const [selectedProductRow, setSelectedProductRow] = useState<DataTableSelection<[]> | undefined>(undefined);
  const [filter, setFilter] = useState<string>('');
  const toast = useRef<Toast>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<Options[]>(initialOptions);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(10);
  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const currentPage = parseInt(visitedPage.productHighlight.toString()) ?? 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : perPage - 1;
  const [pagination, setPagination] = useState({ currentPage, range: { start, end } });
  const [jumpToPage, setJumpToPage] = useState<number>(1);
  const [itemFilters, setItemFilters] = useState<IFilterProductHighlight>(
    lastFilterPage.productHighlight != '' ? JSON.parse(String(lastFilterPage.productHighlight)) : initialFilter
  );
  const [filters, setFilters] = useState<IFilterProductHighlight>(
    lastFilterPage.productHighlight != '' ? JSON.parse(String(lastFilterPage.productHighlight)) : initialFilter
  );
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);
  const [search, setSearch] = useState<string>('');

  // check if filter is product tabulation
  const isProductTabulation = useMemo(() => {
    return filters.section === 'Product Tabulation';
  }, [filters]);

  // get all product highlight
  const getProductHighlight = useCallback(async () => {
    setIsLoading(true);
    const queryOfficialStore = supabase
      .from('catalogue.official_merchants')
      .select('*, product_variants!inner(product_id,sku_id,vendor_sku)', { count: 'exact' })
      .order('created_at', { ascending: false });

    const queryProductTabulation = supabase
      .from(TABLE.PRODUCT_TABULATION)
      .select('*, products!inner(sku,name)', { count: 'exact' })
      .order('created_at', { ascending: false });

    const queryProductSubscription = supabase
      .from(TABLE.PRODUCT_SUBSCRIPTION)
      .select('*, products!inner(sku,name)', { count: 'exact' })
      .order('created_at', { ascending: false });

    const queryProductBanner = supabase
      .from(TABLE.BANNER)
      .select('product_id, started_time, ended_time, merchant_id, id, products!inner(sku,name), status, created_at', { count: 'exact' })
      .eq('type', 'PRODUCT_HOT_DEAL')
      .order('created_at', { ascending: false });

    // Range by Filter Section
    switch (filters.section) {
    case 'Official Store':
      queryOfficialStore.range(pagination.range.start, pagination.range.end);
      break;
    case 'Product Tabulation':
      queryProductTabulation.range(pagination.range.start, pagination.range.end);
      break;
    case 'Product Pilihan':
      queryProductSubscription.range(pagination.range.start, pagination.range.end);
      break;
    case 'Product Hot Deal':
      queryProductBanner.range(pagination.range.start, pagination.range.end);
      break;
    default:
      queryOfficialStore.range(Math.floor(pagination.range.start / 4), Math.floor(pagination.range.end / 4));
      queryProductTabulation.range(Math.floor(pagination.range.start / 4), Math.floor(pagination.range.end / 4));
      queryProductSubscription.range(Math.floor(pagination.range.start / 4), Math.floor(pagination.range.end / 4));
      queryProductBanner.range(Math.floor(pagination.range.start / 4), Math.floor(pagination.range.end / 4));
      break;
    }

    // Search
    if (search != '') {
      queryOfficialStore.or(`sku_id.ilike.%${search}%`, {
        foreignTable: 'product_variants'
      });
      queryProductTabulation.or(`name.ilike.%${search}%, sku.ilike.%${search}%`, {
        foreignTable: 'products'
      });
      queryProductSubscription.or(`name.ilike.%${search}%, sku.ilike.%${search}%`, {
        foreignTable: 'products'
      });
      queryProductBanner.or(`name.ilike.%${search}%, sku.ilike.%${search}%`, {
        foreignTable: 'products'
      });
    }

    // Name
    if (filters.name != '') {
      queryOfficialStore.ilike('products.name', `%${filters.name}%`);
      queryProductTabulation.ilike('products.name', `%${filters.name}%`);
      queryProductSubscription.ilike('products.name', `%${filters.name}%`);
      queryProductBanner.ilike('products.name', `%${filters.name}%`);
    }

    // SKU
    if (filters.sku != '') {
      queryOfficialStore.ilike('product_variants.sku_id', `%${filters.sku}%`);
      queryProductTabulation.ilike('products.sku', `%${filters.sku}%`);
      queryProductSubscription.ilike('products.sku', `%${filters.sku}%`);
      queryProductBanner.ilike('products.sku', `%${filters.sku}%`);
    }

    // Sort Type
    if (filters.sort_type != '') {
      queryOfficialStore.eq('sort_type', PRODUCT_HIGHTLIGHT_SORT_TYPE[filters.sort_type]);
      queryProductTabulation.eq('sort_type', PRODUCT_HIGHTLIGHT_SORT_TYPE[filters.sort_type]);
      queryProductSubscription.eq('sort_type', PRODUCT_HIGHTLIGHT_SORT_TYPE[filters.sort_type]);
      queryProductBanner.eq('sort_type', PRODUCT_HIGHTLIGHT_SORT_TYPE[filters.sort_type]);
    }

    // Status
    if (filters.status != '') {
      queryOfficialStore.eq('is_active', (filters.status === 'active'));
      queryProductTabulation.eq('is_active', (filters.status === 'active'));
      queryProductSubscription.eq('is_active', (filters.status === 'active'));
      queryProductBanner.eq('status', (filters.status.toUpperCase()));
    }

    // Valid Date
    if (filters.valid_date.length > 0) {
      const fromDate = dayjs(String(filters.valid_date[0])).format('YYYY-MM-DD');
      queryOfficialStore.gte('started_time:date', fromDate);
      queryProductTabulation.gte('started_time:date', fromDate);
      queryProductSubscription.gte('started_time:date', fromDate);
      queryProductBanner.gte('started_time:date', fromDate);

      if (filters.valid_date.length > 1 && filters.valid_date[1] != null) {
        const toDate = dayjs(String(filters.valid_date[1])).format('YYYY-MM-DD');
        queryOfficialStore.lte('ended_time:date', toDate);
        queryProductTabulation.lte('ended_time:date', toDate);
        queryProductSubscription.lte('ended_time:date', toDate);
        queryProductBanner.lte('ended_time:date', toDate);
      }
    }

    const { data: dataQueryOfficial, count: countOfficial } = await queryOfficialStore;
    const { data: dataQueryProductTabulation, count: countTabulation } = await queryProductTabulation;
    const { data: dataQueryProductSubscription, count: countSubs } = await queryProductSubscription;
    const { data: dataQueryProductBanner, count: countBanner } = await queryProductBanner as PostgrestSingleResponse<HotDeals[]>;

    let totalRecords = 0;

    switch (filters.section) {
    case 'Official Store':
      totalRecords += countOfficial || 0;
      break;
    case 'Product Tabulation':
      totalRecords += countTabulation || 0;
      break;
    case 'Product Pilihan':
      totalRecords += countSubs || 0;
      break;
    case 'Product Hot Deal':
      totalRecords += countBanner || 0;
      break;
    default:
      totalRecords += (countOfficial || 0) + (countTabulation || 0) + (countSubs || 0) + (countBanner || 0);
      break;
    }

    setTotalRecords(totalRecords);
    const { data: productData } = await supabase
      .from(TABLE.PRODUCT)
      .select('*')
      .order('created_at', { ascending: false })
      .in('id', uniq(concat(
        dataQueryOfficial?.map((it) => it.product_variants?.product_id),
        dataQueryProductTabulation?.map((it) => it.product_id),
        dataQueryProductSubscription?.map((it) => it.product_id),
        dataQueryProductBanner?.map((it) => it.product_id)
      )).filter((it) => it)) as PostgrestSingleResponse<IProduct[]>;

    if (dataQueryProductTabulation && dataQueryProductSubscription && dataQueryOfficial) {
      const dataOfficialStore: ProductHighlight[] = dataQueryOfficial.map((item) => {
        const product = productData?.find((it)=>it.id === item.product_variants?.product_id);
        return {
          id: item.id,
          product_id: item.product_variants?.product_id,
          name: product?.name,
          sku: item?.product_variants?.vendor_sku || item?.product_variants?.sku_id || '',
          section: 'Official Store',
          street: 'Aladin Mall',
          type: item.sort_type as string,
          status: item.is_active ? 'Enable' : 'Disable',
          started_time: item.started_time as Date,
          ended_time: item.ended_time as Date,
          created_at: item.created_at
        };
      });
      const dataTabulation: ProductHighlight[] = (dataQueryProductTabulation).map((item) => {
        return {
          id: item.id,
          product_id: item.product_id,
          name: item.products.name,
          sku: item.products.sku,
          section: 'Product Tabulation',
          status: item.is_active ? 'Enable' : 'Disable',
          street: 'Aladin Mall',
          type: item.sort_type,
          ended_time: item.ended_time,
          started_time: item.started_time,
          created_at: item.created_at
        };
      });
      const dataSubscription: ProductHighlight[] = (dataQueryProductSubscription).map((item) => {
        return {
          id: item.id,
          product_id: item.product_id,
          name: item.products.name,
          sku: item.products.sku,
          section: 'Product Pilihan',
          street: 'Aladin Mall',
          type: item.sort_type as string,
          status: item.is_active ? 'Enable' : 'Disable',
          started_time: item.started_time as Date,
          ended_time: item.ended_time as Date,
          created_at: item.created_at
        };
      });
      let dataHots: ProductHighlight[] = [];
      if (dataQueryProductBanner) {
        dataHots = (dataQueryProductBanner as HotDeals[]).map((item) => {
          return {
            id: item.id,
            product_id: item.product_id,
            name: item.products.name,
            sku: item.products.sku,
            status: item.status === 'ACTIVE' ? 'Enable' : 'Disable',
            started_time: item.started_time,
            ended_time: item.ended_time,
            section: 'Product Hot Deal',
            street: 'Aladin Mall',
            type: '-',
            created_at: item.created_at
          };
        });
      }

      const productHighlights: ProductHighlight[] = [];

      // Filter Section
      switch (filters.section) {
      case 'Product Pilihan':
        productHighlights.push(...dataSubscription);
        break;
      case 'Product Tabulation':
        productHighlights.push(...dataTabulation);
        break;
      case 'Product Hot Deal':
        productHighlights.push(...dataHots);
        break;
      case 'Official Store':
        productHighlights.push(...dataOfficialStore);
        break;
      default:
        productHighlights.push(...dataTabulation, ...dataSubscription, ...dataHots, ...dataOfficialStore);
        break;
      }

      const sortedHighlight = orderBy(productHighlights, ['created_at'], ['desc']);

      setHighlight(sortedHighlight);
    }
    setIsLoading(false);
  }, [filters, pagination, search]);

  // get product tabulation
  const getproductTabulations = useCallback( async () => {
    setIsLoading(true);
    const queryProductTabulation = supabase
      .from(TABLE.PRODUCT_TABULATION)
      .select('*, products!inner(sku,name)', { count: 'exact' })
      .order('created_at', { ascending: false })
      .range(pagination.range.start, pagination.range.end);

    // Search
    if (search != '') {
      queryProductTabulation.or(`name.ilike.%${search}%, sku.ilike.%${search}%`, {
        foreignTable: 'products'
      });
    }

    // Name
    if (filters.name != '') {
      queryProductTabulation.ilike('products.name', `%${filters.name}%`);
    }

    // Sku
    if (filters.sku != '') {
      queryProductTabulation.ilike('products.sku', `%${filters.sku}%`);
    }

    // Sort Type
    if (filters.sort_type != '') {
      queryProductTabulation.in('sort_type', PRODUCT_HIGHTLIGHT_SORT_TYPE[filters.sort_type]);
    }

    // Status
    if (itemFilters.status != '') {
      queryProductTabulation.eq('is_active', (itemFilters.status === 'active'));
    }

    // Valid Date
    if (filters.valid_date.length > 0) {
      const fromDate = dayjs(String(filters.valid_date[0])).format('YYYY-MM-DD');
      queryProductTabulation.gte('started_time:date', fromDate);

      if (filters.valid_date.length > 1 && filters.valid_date[1] != null) {
        const toDate = dayjs(String(filters.valid_date[1])).format('YYYY-MM-DD');
        queryProductTabulation.lte('ended_time:date', toDate);
      }
    }

    const result: PostgrestSingleResponse<IProductTabulations[]> = { data: [] as IProductTabulations[] } as PostgrestSingleResponse<IProductTabulations[]>;

    const { data, error, status, statusText, count } = await queryProductTabulation;

    result.status = status;
    result.error = error;
    result.statusText = statusText;
    result.count = count;
    result.data = data;

    setTotalRecords(result.count as number);

    const dataTabulation: ProductHighlight[] = result.data?.map((item) => {
      return {
        id: item.id,
        product_id: item.product_id,
        name: item.products.name,
        sku: item.products.sku,
        section: 'Product Tabulation ',
        status: item.is_active ? 'Enable' : 'Disable',
        street: 'Aladin Mall',
        type: item.sort_type,
        ended_time: item.ended_time,
        started_time: item.started_time,
        created_at: item.created_at
      };
    }) || [];

    setIsLoading(false);
    setHighlight(dataTabulation);
  }, [filters, pagination, search]);

  const getOptionsFilter = useCallback(() => {
    const dataOption: Options[] = [
      {
        label: 'Section',
        items: OptionsDropdown1
      },
      {
        label: 'Street',
        items: [
          { label: 'Aladinmall', name: 'street', value: 'Aladinmall' }
        ]
      },
      {
        label: 'Sort Type',
        items: [
          { label: 'Sequence', name: 'sortType', value: 'sequence' },
          { label: 'Random', name: 'sortType', value: 'random' },
          { label: 'Lowest Price', name: 'sortType', value: 'lowest_price' }
        ]
      },
      {
        label: 'Status',
        items: [
          { label: 'Enable', name: 'status', value: 'Enable' },
          { label: 'Disable', name: 'status', value: 'Disable' }
        ]
      }
    ];
    setOptions(dataOption);
  }, []);

  useEffect(() => {
    // when filter is product tabulations
    if (isProductTabulation) {
      getproductTabulations();
    }
  }, [isProductTabulation, filters, pagination, search]);

  useEffect(()=> {
    // get all product highlight
    if (!isProductTabulation) {
      getProductHighlight();
    }

    getOptionsFilter();
  }, [isProductTabulation, filters, pagination, search]);

  const showError = (msg: string) => {
    toast.current?.show({ severity: 'error', summary: 'Error', detail: `${msg}`, life: 3000 });
  };

  const showSuccess = () => {
    toast.current?.show({
      severity: 'success',
      summary: 'Confirmed',
      detail: 'Product Highlight deleted succesfully',
      life: 2000
    });
  };

  const onDeleteRow = useCallback(
    async (rows) => {
      if (rows.section === 'Product Tabulation') {
        const { data } = await supabase.rpc('catalogue.delete_action', {
          'table_name': 'product_tabulation_variants', 'column_name': 'id', 'val': [String(rows.id)]
        });
        if (!data[0].success) {
          return showError('Gagal Menghapus Produk Tabulasi');
        }
        showSuccess();
        getProductHighlight();
      }
      if (rows.section === 'Product Pilihan') {
        const { data } = await supabase.rpc('catalogue.delete_action', {
          'table_name': 'product_subscriptions', 'column_name': 'id', 'val': [String(rows.id)]
        });
        if (!data[0].success) {
          return showError('Gagal Menghapus Produk Pilihan');
        }
        showSuccess();
        getProductHighlight();
      }
      if (rows.section === 'Product Hot Deal') {
        const { data } = await supabase.rpc('catalogue.delete_action', {
          'table_name': 'banners', 'column_name': 'id', 'val': [String(rows.id)]
        });
        if (!data[0].success) {
          return showError('Gagal Menghapus Produk Hot Deal');
        }
        showSuccess();
        getProductHighlight();
      }
      if (rows.section === 'Official Store') {
        const { data } = await supabase.rpc('catalogue.delete_action', {
          'table_name': 'official_merchants', 'column_name': 'id', 'val': [String(rows.id)]
        });
        if (!data[0].success) {
          return showError('Gagal Menghapus Produk Official');
        }
        showSuccess();
        getProductHighlight();
      }
    }, []);

  const onDeleteBulkRow = useCallback(
    async () => {
      const idProdTab = selectedProductHightlight.filter((item)=> item.section === 'Product Tabulation').map((it)=>it.id);
      const idProdSubs = selectedProductHightlight.filter((item)=> item.section === 'Product Pilihan').map((it)=>it.id);
      const idProdHotDeal = selectedProductHightlight.filter((item)=> item.section === 'Product Hot Deal').map((it)=>it.id);
      const idProdOfficial = selectedProductHightlight.filter((item)=> item.section === 'Official Store').map((it)=>it.id);
      if (idProdTab.length > 0) {
        const { data } = await supabase.rpc('catalogue.delete_action', {
          'table_name': 'product_tabulation_variants', 'column_name': 'id', 'val': idProdTab.map(String)
        });
        if (!data[0].success) {
          return showError('Gagal Menghapus Produk Tabulasi');
        }
        showSuccess();
        getProductHighlight();
      }
      if (idProdSubs) {
        const { data } = await supabase.rpc('catalogue.delete_action', {
          'table_name': 'product_subscriptions', 'column_name': 'id', 'val': idProdSubs.map(String)
        });
        if (!data[0].success) {
          return showError('Gagal Menghapus Produk Pilihan');
        }
        showSuccess();
        getProductHighlight();
      }
      if (idProdHotDeal) {
        const { data } = await supabase.rpc('catalogue.delete_action', {
          'table_name': 'banners', 'column_name': 'id', 'val': idProdHotDeal.map(String)
        });
        if (!data[0].success) {
          return showError('Gagal Menghapus Produk Hot Deal');
        }
        showSuccess();
        getProductHighlight();
      }
      if (idProdOfficial) {
        const { data } = await supabase.rpc('catalogue.delete_action', {
          'table_name': 'official_merchants', 'column_name': 'id', 'val': idProdOfficial.map(String)
        });
        if (!data[0].success) {
          return showError('Gagal Menghapus Produk Official');
        }
        showSuccess();
        getProductHighlight();
      }
    }, [selectedProductHightlight]);

  const handleSelectCheckbox = useCallback((e: DataTableSelectionChangeEvent<[]>) => {
    setSelectedProductRow(e.value);
  }, []);

  const confirmDelete = (rows) => {
    confirmDialog({
      message: 'Are you sure you want to delete this record?',
      header: 'Delete Confirmation',
      acceptClassName: 'p-button-danger',
      accept() {
        if (selectedProductHightlight.length > 1) {
          onDeleteBulkRow();
        } else {
          onDeleteRow(rows);
        }
      }
    });
  };

  const handleFilter = useCallback((e, field: string) => {
    setItemFilters((prev) => ({
      ...prev,
      [field]: e.target ? e.target.value : e.value
    }));
  }, []);

  const handleClearFilter = useCallback(() => {
    setLastFilterPage({
      ...lastFilterPage,
      productHighlight: ''
    });
    setFilters(initialFilter);
    setItemFilters(initialFilter);
    setFilterHistory([]);
    setSearch('');
    setPagination({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [perPage, lastFilterPage]);

  const handleDeleteFilterHistory = useCallback(
    (_: string, value: string[]) => {
      const items = value[0].split(',');
      items.forEach((i) => {
        setFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));

        setItemFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));
      });

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setSearch('');
    },
    []
  );

  useEffect(() => {
    getProductHighlight();
  }, [onDeleteRow]);


  // pagination
  const totalPages = useMemo(() => {
    return Math.ceil(Number(totalRecords) / perPage);
  }, [perPage, totalRecords]);

  const handleClickNext = useCallback(()=>{
    pagination.currentPage <= totalPages &&
    setPagination((prev) => ({
      ...prev,
      currentPage: pagination.currentPage + 1,
      range: {
        start: pagination.range.start + perPage,
        end: pagination.range.end + perPage
      }
    }));
  }, [pagination, totalPages, perPage]);

  const handleClickPrev = useCallback(()=>{
    setPagination((prev) => ({
      ...prev,
      currentPage: prev.currentPage - 1,
      range: {
        start: prev.range.start - perPage,
        end: prev.range.end - perPage
      }
    }));
  }, [pagination, perPage]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent)=>{
    setPerPage(event.value);
    setPagination((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, totalRecords - 1);

      setPagination(() => ({
        currentPage: jumpToPage > totalPages ? totalPages : value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, totalRecords]);

  const handleOnChangeSearchInput = useCallback(
    _.debounce((e) => {
      setSearch(e.target.value);
      const newKeyword = {
        name: 'Keyword',
        items: [{
          label: e.target.value,
          value: e.target.value,
          name: e.target.value
        }]
      };

      if (e.target.value != '') {
        setFilterHistory((prev: IFilterHistoryItems[]) => {
          const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
          if (existingIndex !== -1) {
            prev[existingIndex] = newKeyword;
          } else {
            prev.push(newKeyword);
          }
          return [...prev];
        });

        setPagination({
          currentPage: 1,
          range: {
            start: 0,
            end: perPage - 1
          }
        });
      }
    }, 1000), [perPage]);

  const handleClickSubmitFilter = useCallback(() => {
    setFilters(itemFilters);
    setPagination({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [itemFilters, perPage]);

  const storeFilterHistory = useCallback((filter: IFilterProductHighlight) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter?.valid_date && filter.valid_date[0]) {
      let filterDate = `${dayjs(filter.valid_date[0]).format('YYYY-MM-DD')}`;
      if (filter.valid_date.length > 1 && filter.valid_date[1] !== null) {
        filterDate = `${dayjs(filter.valid_date[0]).format('YYYY-MM-DD')} - ${dayjs(filter.valid_date[1]).format('YYYY-MM-DD')}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Valid Date', 'valid_date', filterDate));
    }

    if (filter.name !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Banner Name', 'name', filter.name));
    }

    if (filter.status !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Status', 'status', filter.status));
    }

    if (filter.sku !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Street', 'sku', filter.sku));
    }

    if (filter.section !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Section', 'section', filter.section));
    }

    if (filter.sort_type !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Sort Type', 'sort_type', filter.sort_type));
    }

    setFilterHistory(filterHistoryItems);
  }, []);

  useEffect(() => {
    // update store visitedPage with latest page
    setVisitedPage({
      ...visitedPage,
      productHighlight: pagination.currentPage
    });
  }, [pagination.currentPage]);

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  // update store lastFilterPage with latest filter
  useEffect(() => {
    setLastFilterPage({
      ...lastFilterPage,
      productHighlight: JSON.stringify(filters)
    });
  }, [filters]);

  return {
    data: {
      isLoading,
      selectedProductHightlight,
      OptionsDropdown,
      highlight,
      filter,
      toast,
      options,
      selectedProductRow,
      filterHistory,
      pagination,
      totalRecords,
      totalPages,
      perPage,
      isProductTabulation,
      itemFilters,
      search
    },
    method: {
      setSelectedProductHightlight,
      confirmDelete,
      setFilter,
      handleSelectCheckbox,
      handleFilter,
      handleClearFilter,
      handleDeleteFilterHistory,
      handleClickPrev,
      handleClickNext,
      handleChangeDropdownPage,
      handleChangeJumpTopage,
      handleJumpToPage,
      handleClickSubmitFilter,
      handleOnChangeSearchInput
    }
  };
};

export default useCustom;
