import * as yup from 'yup';
import { MESSAGE } from '@/constants';

const schemaUserAdmin = yup.object({
  adminName: yup.string().required(MESSAGE.REQUIRED),
  adminEmail: yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, MESSAGE.EMAIL)
    .required(MESSAGE.REQUIRED),
  adminGroup: yup.number().min(1, MESSAGE.REQUIRED).required(MESSAGE.REQUIRED),
  adminType: yup.string().required(MESSAGE.REQUIRED),
  adminPassword: yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Password Must Contain 8 Chars minimum, One of Uppercase, Lowercase, Number and Special Character'
    ).required(MESSAGE.REQUIRED)
});

export { schemaUserAdmin };
