import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { IPagination } from '@/types/pagination';
import { Database } from '@/types/services/catalogue';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

type MasterSlot = Database['public']['Tables']['master_slots']['Row'];

export interface IFilterMasterSlot {
  name: string;
  created_by: string;
  updated_by: string;
  created_at: Date[];
  updated_at: Date[];
  status: string;
}

const getAllMasterSlots = async (range: IPagination, filter?: IFilterMasterSlot, search?: string) => {
  const query = supabase
    .from(TABLE.MASTER_SLOTS)
    .select('*')
    .is('deleted_at', null)
    .order('id', { ascending: true })
    .range(range.start, range.end);

  if (filter) {
    // ID
    if (filter.name != '') {
      query.ilike('name', `%${filter.name}%`);
    }

    // Created By
    if (filter.created_by != '') {
      query.ilike('created_by', `%${filter.created_by}%`);
    }

    // Updated By
    if (filter.updated_by != '') {
      query.ilike('updated_by', `%${filter.updated_by}%`);
    }

    // Created At
    if (filter.created_at && filter.created_at.length != 0) {
      const fromDate = dayjs(String(filter.created_at[0])).format('YYYY-MM-DD');
      query.gte('created_at:date', fromDate);

      if (filter.created_at.length > 1) {
        const toDate = dayjs(String(filter.created_at[1])).format('YYYY-MM-DD');
        query.lte('created_at:date', toDate);
      }
    }

    // Updated At
    if (filter.updated_at && filter.updated_at.length != 0) {
      const fromDate = dayjs(String(filter.updated_at[0])).format('YYYY-MM-DD');
      query.gte('updated_at:date', fromDate);

      if (filter.updated_at.length > 1) {
        const toDate = dayjs(String(filter.updated_at[1])).format('YYYY-MM-DD');
        query.lte('updated_at:date', toDate);
      }
    }

    // Status
    if (filter.status != '') {
      query.eq('status', filter.status);
    }
  }

  if (search != '') {
    query.or(`name.ilike.%${search}%, created_by.ilike.%${search}%, updated_by.ilike.%${search}%`);
  }

  return await query as PostgrestSingleResponse<MasterSlot[]>;
};

const getTotalMasterSlots = async (range: IPagination, filter?: IFilterMasterSlot, search?: string) => {
  const query = supabase
    .from(TABLE.MASTER_SLOTS)
    .select('id', { count: 'exact', head: true })
    .is('deleted_at', null)
    .order('id', { ascending: true })
    .range(range.start, range.end);

  if (filter) {
    // ID
    if (filter.name != '') {
      query.ilike('name', `%${filter.name}%`);
    }

    // Created By
    if (filter.created_by != '') {
      query.ilike('created_by', `%${filter.created_by}%`);
    }

    // Updated By
    if (filter.updated_by != '') {
      query.ilike('updated_by', `%${filter.updated_by}%`);
    }

    // Created At
    if (filter.created_at && filter.created_at.length != 0) {
      const fromDate = dayjs(String(filter.created_at[0])).format('YYYY-MM-DD');
      query.gte('created_at:date', fromDate);

      if (filter.created_at.length > 1) {
        const toDate = dayjs(String(filter.created_at[1])).format('YYYY-MM-DD');
        query.lte('created_at:date', toDate);
      }
    }

    // Updated At
    if (filter.updated_at && filter.updated_at.length != 0) {
      const fromDate = dayjs(String(filter.updated_at[0])).format('YYYY-MM-DD');
      query.gte('updated_at:date', fromDate);

      if (filter.updated_at.length > 1) {
        const toDate = dayjs(String(filter.updated_at[1])).format('YYYY-MM-DD');
        query.lte('updated_at:date', toDate);
      }
    }

    // Status
    if (filter.status != '') {
      query.eq('status', filter.status);
    }
  }

  if (search != '') {
    query.or(`name.ilike.%${search}%, created_by.ilike.%${search}%, updated_by.ilike.%${search}%`);
  }

  return await query as PostgrestSingleResponse<MasterSlot[]>;
};

// mapper
const mapAllMasterSlots = (data: PostgrestSingleResponse<MasterSlot[]>) =>
  data?.data?.map?.((d) => ({
    id: d?.id,
    name: d?.name,
    created_by: d?.created_by,
    updated_by: d?.updated_by,
    type: d?.type,
    created_at: d?.created_at ? new Date(d?.created_at).toLocaleDateString('en-GB') : '',
    updated_at: d?.updated_at ? new Date(d?.updated_at).toLocaleDateString('en-GB') : '',
    time: d?.time,
    status: d?.status,
    description: d?.description
  })) || [];

export const useAllMasterSlots = (range: IPagination, filter?: IFilterMasterSlot, search?: string) =>
  useQuery({
    queryKey: ['all_master_slots', range, filter, search],
    queryFn: async () => await getAllMasterSlots(range, filter, search),
    select: mapAllMasterSlots,
    enabled: !!range,
    refetchOnWindowFocus: false
  });

export const useTotalMasterSlot = (range: IPagination, filter?: IFilterMasterSlot, search?: string) =>
  useQuery({
    queryKey: ['total_master_slots', range, filter, search],
    queryFn: async () => await getTotalMasterSlots(range, filter, search),
    enabled: !!range,
    refetchOnWindowFocus: false
  });

const deleteMasterSlot = async (id: string[]) => {
  const { error } = await supabase
    .from(TABLE.MASTER_SLOTS)
    .update({ deleted_at: dayjs().format('YYYY-MM-DD HH:mm:ssZ') })
    .in('id', id);

  if (error) {
    throw error;
  }
};

export const useDeleteMasterSlot = (refecthMasterSlots?: () => void) =>
  useMutation({
    mutationKey: ['delete_master_slot'],
    mutationFn: async (id:string[]) =>
      deleteMasterSlot(id),
    onSuccess: refecthMasterSlots
  });


export const getMasterSlotsById = (id: string) => async () => {
  return (await supabase
    .from(TABLE.MASTER_SLOTS)
    .select('*')
    .eq('id', id)
    .single());
};

export const useMasterSlotsById = (id: string) =>
  useQuery({
    queryKey: ['master_slots_by_id', id],
    queryFn: getMasterSlotsById(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });
