import IconCancel from '@/assets/IconCancel';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import React, { FC } from 'react';
import { IEditTeam } from '../../helper';

type child = {
    create: boolean;
    delete: boolean;
    update: boolean;
    read: boolean;
    menus: string;
    id: number;
    parent_id: number;
};

type parent = {
    create: boolean;
    delete: boolean;
    update: boolean;
    read: boolean;
    menus: string;
    id: number;
    parent_id: number;
};

type merge = {
    parent: parent;
    child: child[];
}

export type IMenusPermissionsProps = {
    data: merge[]
    id: number;
    team: IEditTeam
    handleClickSubmitPopUp: () => void
    handleClickClosePopUp: () => void
    onChangeCheckboxParent: (checked: boolean, index: number, access: string) => void
    onChangeCheckboxChildren: (checked: boolean, index: number, access: string, indexChild: number) => void
}

const CustomAccess: FC<IMenusPermissionsProps> = ({ data, id, team, handleClickClosePopUp, handleClickSubmitPopUp, onChangeCheckboxParent, onChangeCheckboxChildren }) => {
  return (
    <div id="user-popup setting-permission" className="bg-black bg-opacity-30 w-screen h-screen fixed top-0 left-0 z-50 flex justify-center items-center">
      <div className="bg-white relative p-6 z-50 w-[628px] rounded-lg h-[723px]">
        <div className="flex justify-between">
          <h1 className="font-bold text-xl">Group Feature Permission</h1>
          <button type="button" onClick={handleClickClosePopUp} id="user-button-close-popup"><IconCancel /></button>
        </div>
        <div className="flex justify-between items-center mt-4">
          <p className="font-normal">{id}</p>
        </div>
        <h1 className="font-bold text-xl mb-2">{team.name}</h1>
        <hr className="border-3 mx-[-20px] mb-2" />
        <div className="flex flex-col gap-8">
          <div id="detail permisions">
            <div className="flex justify-between">
              <h2 className="w-1/2 font-semibold">Feature</h2>
              <div className="flex justify-around w-1/2">
                <span className="font-semibold">Read</span>
                <span className="font-semibold">Create</span>
                <span className="font-semibold">Edit</span>
                <span className="font-semibold">Delete</span>
              </div>
            </div>
          </div>
          <div className="overflow-y-scroll h-[400px]">
            {data.map((item, idx) => (
              <div key={idx}>
                <div className="flex justify-between mt-6">
                  <h2 className="w-1/2 font-medium">{item.parent.menus}</h2>
                  <div className="grid grid-cols-4 w-1/2">
                    <div className="flex justify-center">
                      <Checkbox
                        id={`user-read-checkbox-${idx}`}
                        onChange={(e) => onChangeCheckboxParent(e.checked as boolean, idx, 'read')}
                        checked={item.parent.read}
                        name={`read.${item.parent.id}`} />
                    </div>
                    <div className="flex justify-center">
                      <Checkbox
                        onChange={(e) => onChangeCheckboxParent(e.checked as boolean, idx, 'create')}
                        id={`user-create-checkbox-${idx}`}
                        checked={item.parent.create}
                        name={`create.${item.parent.id}`} />
                    </div>
                    <div className="flex justify-center">
                      <Checkbox
                        onChange={(e) => onChangeCheckboxParent(e.checked as boolean, idx, 'update')}
                        id={`user-update-checkbox-${idx}`}
                        checked={item.parent.update}
                        name={`update.${item.parent.id}`} />
                    </div>
                    <div className="flex justify-center">
                      <Checkbox
                        onChange={(e) => onChangeCheckboxParent(e.checked as boolean, idx, 'delete')}
                        id={`user-delete-checkbox-${idx}`}
                        checked={item.parent.delete}
                        name={`delete.${item.parent.id}`} />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-8 mt-4">
                  {item.child.map((child, idxChild) => (
                    <div key={idxChild}>
                      <div className="flex justify-between">
                        <h2 className="w-1/2 pl-8">{child.menus}</h2>
                        <div className="grid grid-cols-4 w-1/2">
                          <div className="flex justify-center">
                            <Checkbox
                              onChange={(e) => onChangeCheckboxChildren(e.checked as boolean, idx, 'read', idxChild)}
                              id={`user-read-checkbox-${idxChild}`}
                              checked={child.read}
                              name={`read.${child.id}`} />
                          </div>
                          <div className="flex justify-center">
                            <Checkbox
                              onChange={(e) => onChangeCheckboxChildren(e.checked as boolean, idx, 'create', idxChild)}
                              id={`user-create-checkbox-${idxChild}`}
                              checked={child.create}
                              name={`create.${child.id}`} />
                          </div>
                          <div className="flex justify-center">
                            <Checkbox
                              onChange={(e) => onChangeCheckboxChildren(e.checked as boolean, idx, 'update', idxChild)}
                              id={`user-update-checkbox-${idxChild}`}
                              checked={child.update}
                              name={`update.${child.id}`} />
                          </div>
                          <div className="flex justify-center">
                            <Checkbox
                              onChange={(e) => onChangeCheckboxChildren(e.checked as boolean, idx, 'delete', idxChild)}
                              id={`user-delete-checkbox-${idxChild}`}
                              checked={child.delete}
                              name={`delete.${child.id}`} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="absolute w-full bottom-6 left-0">
          <div className="grid grid-cols-2 mx-6 gap-4">
            <Button
              id="user-cancel-button"
              outlined
              onClick={handleClickClosePopUp}
              label="Cancel" />
            <Button
              id="user-save-button"
              outlined
              onClick={handleClickSubmitPopUp}
              label="Submit"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomAccess;
