import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import { Categories, deleteCategorySpec, deleteCategoryVar, upsertCategory } from '@/services/rest/categories';
import { FileUpload, FileUploadFilesEvent } from 'primereact/fileupload';
import { debounce, filter as lodashFilter, uniqBy } from 'lodash';
import * as XLSX from 'xlsx';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { IHistory, insertImportHistory } from '@/services/rest/importHistory';
import { useAuthStore } from '@/store/useAuthStore';
import { TreeExpandedKeysType } from 'primereact/tree';
import { TreeNode } from 'primereact/treenode';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { useHistoryStore } from '@/store/useHistoryStore';
import { IFilterHistoryItems } from '@/components/base/FilterHistory';
import { useDebounce } from 'primereact/hooks';

interface ICategoryImport{
  id?:string
  parent:string
  name:string
  status:string
  description?:string
  meta_title:string
  icon?:string
  attribute_set: number[]
  specifications: number[]
  variants: number[]
}

export interface ICategory {
  id: number;
  name: string;
  position: number;
  parent_id: number;
  status: string;
  images: {
    data: {
      image: string;
    };
  };
  description: string;
}
interface IAttributeSet {
  specification_ids: string[]
  variant_ids: string[]
}

export const header = [
  { field: 'name', header: 'Name' },
  { field: 'description', header: 'Description' },
  { field: 'position', header: 'Position' },
  { field: 'parent_id', header: 'Parent' },
  { field: 'status', header: 'Status' }
];

export const headerSeller = [
  { field: 'id', header: 'ID' },
  { field: 'categoryName', header: 'Category Name' },
  { field: 'merchant', header: 'Merchant' },
  { field: 'status', header: 'Status' }
];

const headerCategory = [
  'Nama Kategori',
  'Parent Kategori',
  'Status',
  'Deskripsi Kategori',
  'Icon Kategori',
  'URL Key',
  'Meta Title',
  'Meta Keyword',
  'Meta Description',
  'Attribute Set',
  'Spesifikasi',
  'Varian'
];
const headerUpCategory = [
  'ID',
  'Nama Kategori',
  'Parent Kategori',
  'Status',
  'Deskripsi Kategori',
  'Icon Kategori',
  'URL Key',
  'Meta Title',
  'Meta Keyword',
  'Meta Description',
  'Attribute Set',
  'Spesifikasi',
  'Varian'
];

interface IFilterCategory {
  id_from: number | null;
  id_to: number | null;
  name: string;
  level: number | null;
  status: string;
}

export const useCustom = () => {
  const initialFilter: IFilterCategory = {
    id_from: null,
    id_to: null,
    name: '',
    level: null,
    status: ''
  };

  const navigate = useNavigate();
  const [dataTreeCategory, setDataTreeCategory] = useState<TreeNode[]>([{}]);
  const [dataAllCategory, setDataAllCategory] = useState<TreeNode[]>([]);
  const [initialTreeCategory, setInitialTreeCategory] = useState<TreeNode[]>([{}]);
  const [filter, setFilter] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [first, setFirst] = useState<number>(0);
  const [rows, setRows] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [jumpToPage, setJumpToPage] = useState<number>(1);
  const [selectedNodeIndex, setSelectedNodeIndex] = useState<string | number | null>(null);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [uploadType, setUploadType] = useState<'create'|'edit'>('create');
  const [importedCategory, setImportedCategory] = useState<ICategoryImport[]>([]);
  const [fileName, setFileName] = useState<string>('');
  const [expandedKeys, setExpandedKeys] = useState<TreeExpandedKeysType>({});
  const user = useAuthStore((state)=> state.user);
  const toast = useRef<Toast>(null);
  const fileUploadRef = useRef<FileUpload>(null);
  const [foundCategory, setFoundCategory] = useState<{ name: string, value: string }[]|null>(null);
  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const [itemFilters, setItemFilters] = useState<IFilterCategory>(lastFilterPage.category != '' ? JSON.parse(String(lastFilterPage.category)) : initialFilter);
  const [filters, setFilters] = useState<IFilterCategory>(lastFilterPage.category != '' ? JSON.parse(String(lastFilterPage.category)) : initialFilter);
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);
  const currentPage = parseInt(visitedPage.category.toString()) ?? 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : rows - 1;
  const [paginator, setPaginator] = useState({
    currentPage,
    range: {
      start,
      end
    }
  });
  const [search, debounceSearch, setSearch] = useDebounce('', 1500);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);

  const getAllCategory = async (firstPage: number = first, rowsPage: number = rows, search: string = debounceSearch, filters?: IFilterCategory) => {
    setIsLoading(true);

    const query = supabase.rpc('catalogue.get_all_categories');
    const { data: trees, error } = await query;

    if (error) {
      setIsLoading(false);
      return;
    }
    const manipulateData = (data) => {
      return data.map((node) => manipulateNode(node));
    };
    const manipulateNode = (node) => {
      const newNode = { ...node };
      if ( newNode.child ) {
        newNode.children = manipulateData(newNode.child);
        delete newNode.child;
      }
      return newNode;
    };

    const result = manipulateData(trees);

    const totalData = result.length;
    setTotalRecords(totalData);
    const addKeys = (array, prefix = '0') => {
      let count = 0;
      return array.map((item, index) => {
        const key = prefix === '0' && item.parent_id === null ? `${index}` : `${prefix}-${count}`;
        count++;
        const children = item.children ? addKeys(item.children, key) : null;
        return { ...item, key, children };
      });
    };
    const resultWithKeys = addKeys(result);
    setInitialTreeCategory(resultWithKeys);

    let data = lodashFilter(resultWithKeys, (item) => {
      return (
        item.name.toLowerCase().includes(search.toLowerCase()) ||
        lodashFilter(item.children, (_item) => _item.name.toLowerCase().includes(search.toLowerCase())).length > 0 ||
        lodashFilter(
          item.children,
          (_item) =>
            lodashFilter(_item.children, (__item) => __item.name.toLowerCase().includes(search.toLowerCase()))
              .length > 0
        ).length > 0
      );
    }).sort((a, b) => a.position - b.position);

    // Filter Name
    if (filters && filters?.name != '' && search != '') {
      data = data.filter((item) => {
        if (item.name.toLowerCase().includes(filters.name.toLowerCase() || search.toLowerCase())) {
          return true; // Include the item
        }

        const filteredChildren = item.children
          .filter((itemChildren) => {
            if (itemChildren.name.toLowerCase().includes(filters.name.toLowerCase() || search.toLowerCase())) {
              return true; // Include the itemChildren
            }

            const filteredGrandchildren = itemChildren.children.filter((child) => {
              if (child.name.toLowerCase().includes(filters.name.toLowerCase() || search.toLowerCase())) {
                return true; // Include the child
              }
              return false; // Exclude the child
            });

            return filteredGrandchildren.length > 0; // Include itemChildren if there are filtered grandchildren
          });

        return filteredChildren.length > 0;
      });
    }

    // Filter Status
    if (filters && filters?.status != '') {
      data = data.filter((item) => {
        if (item.status == filters.status) {
          return true; // Include the item
        }

        const filteredChildren = item.children
          .filter((itemChildren) => {
            if (itemChildren.status == filters.status) {
              return true; // Include the itemChildren
            }

            const filteredGrandchildren = itemChildren.children.filter((child) => {
              if (child.status == filters.status) {
                return true; // Include the child
              }
              return false; // Exclude the child
            });

            return filteredGrandchildren.length > 0; // Include itemChildren if there are filtered grandchildren
          });

        return filteredChildren.length > 0;
      });
    }

    // Filter ID
    if (filters && filters?.id_from != null && filters?.id_to != null) {
      data = data.filter((item) => {
        if (item.id >= (filters?.id_from || 0) && item.id <= (filters?.id_to || 0)) {
          return true; // Include the item
        }

        const filteredChildren = item.children
          .filter((itemChildren) => {
            if (itemChildren.id >= (filters?.id_from || 0) && itemChildren.id <= (filters?.id_to || 0)) {
              return true; // Include the itemChildren
            }

            const filteredGrandchildren = itemChildren.children.filter((child) => {
              if (child.id >= (filters?.id_from || 0) && child.id <= (filters?.id_to || 0)) {
                return true; // Include the child
              }
              return false; // Exclude the child
            });

            return filteredGrandchildren.length > 0; // Include itemChildren if there are filtered grandchildren
          });

        return filteredChildren.length > 0;
      });
    }

    setTotalRecords(data.length);
    setDataAllCategory(data as TreeNode[]);
    setDataTreeCategory(data.slice((firstPage), (firstPage + rowsPage)) as TreeNode[]);
    setIsLoading(false);
  };
  interface Data {
    label: string | '';
    value: string | '';
  }

  const findCategoryDebounce = debounce(async (findName: string ) => {
    if (!findName) {
      return;
    }

    const { data } = await supabase.from('catalogue.categories').select('id, name, parent_id').ilike('name', `*${findName}*`).limit(10);

    if (data) {
      const parentIds = data.filter((item) => item.parent_id !== null).map((item) => item.parent_id);
      const { data: dataParent } = await supabase.from(TABLE.CATEGORIES).select('id, name, parent_id').in('id', parentIds).limit(10);
      if (dataParent) {
        const datasOptions = data?.map((category) => {
          if (category.parent_id !== null) {
            const parentCategory = dataParent?.find((cat) => cat.id === category.parent_id);
            if (parentCategory?.name !== undefined) {
              const item = {
                name: `${parentCategory?.name} > ${category?.name}`,
                value: category?.name
              };
              return item;
            }
          }

          const itemNoParent = {
            name: category?.name,
            value: category?.name
          };
          return itemNoParent;
        });
        setFoundCategory(datasOptions);
      }
    }
  }, 250);

  const findCategory = (findName: string) => {
    findCategoryDebounce(findName);
  };

  const dataOptions = useMemo(() => {
    const data = foundCategory;
    const filters =
      Array.isArray(data) &&
      data?.map((v) => {
        return {
          label: v?.name || '',
          value: v?.value || ''
        };
      });

    const uniqueData: Data[] = [];
    if (filters) {
      filters?.forEach((item) => {
        if (item?.label !== null && !uniqueData?.some((data) => data?.label === item?.label)) {
          uniqueData.push(item);
        }
      });
    }

    return [
      {
        label: 'Category',
        items: uniqueData
      }
    ];
  }, [foundCategory]);

  const handleFilter = useCallback((e, field: string) => {
    setItemFilters((prev) => ({
      ...prev,
      [field]: e.target ? e.target.value : e.value
    }));
  }, []);

  const handleReset = useCallback(async (e) => {
    handleClearFilter();
    setDataTreeCategory(initialTreeCategory);
  }, [setFilter, initialTreeCategory]);

  const onPageChange = async (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
  };
  const onCreateCategory = (e) => {
    if (e.label === 'Tambah Kategori') {
      navigate('create');
    } else {
      setUploadType('create');
      setIsShowModal(!isShowModal);
    }
  };

  const onConfirmDelete = (id) => {
    confirmDialog({
      message: 'Are you sure you want to delete category?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept() {
        handleDelete(id);
      }
    });
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    const { data: categorySpec } = await supabase.rpc('catalogue.delete_action', {
      'table_name': TABLE.CATEGORY_SPECIFICATION.split('.').splice(1, 1)[0], 'column_name': 'category_id', 'val': [String(id)]
    });
    const { data: categoryVars } = await supabase.rpc('catalogue.delete_action', {
      'table_name': TABLE.CATEGORY_VARIANTS.split('.').splice(1, 1)[0], 'column_name': 'category_id', 'val': [String(id)]
    });
    const { data: category } = await supabase.rpc('catalogue.delete_action', {
      'table_name': TABLE.CATEGORIES.split('.').splice(1, 1)[0], 'column_name': 'id', 'val': [String(id)]
    });
    if (!categorySpec[0].success || !categoryVars[0].success) {
      showToastFailed('Gagal Delete Category');
      setIsLoading(false);
      getAllCategory();
      return;
    }
    if (!category[0].success) {
      showToastFailed('Gagal Delete Category');
      setIsLoading(false);
      getAllCategory();
      return;
    }
    setIsLoading(false);
    showToastSucces('Delete Category Succesfully');
    getAllCategory();

    setIsLoading(false);
  };

  const showToastSucces = (msg:string) => {
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: msg,
      life: 2000
    });
  };
  const showToastFailed = (msg) => {
    toast.current?.show({ severity: 'error', summary: 'Error', detail: msg, life: 2000 });
  };

  const handleClickDownload = useCallback(()=>{
    const link = document.createElement('a');
    link.href = `${window.location.href}/${uploadType === 'create' ? 'template-category.xlsx' : 'template-ubah-category.xlsx'}`;
    link.target = '_blank';
    link.download = '';
    link.click();
  }, [uploadType]);

  const handleChangeFileUpload = useCallback(async (event:FileUploadFilesEvent)=>{
    const text = await event.files[0].arrayBuffer();
    const wb = XLSX.read(text, { type: 'binary', cellDates: true });
    const sn = wb.SheetNames[0];
    const ws = wb.Sheets[sn];
    const json = XLSX.utils.sheet_to_json<Object[]>(ws, { raw: false, dateNF: 'dd-mm-yyyy' });
    const option = { header: 1 };
    const sheet2 = XLSX.utils.sheet_to_json(ws, option);
    const header = sheet2.shift();
    const isHeaderValid = JSON.stringify(header) === JSON.stringify(headerCategory);
    const isHeaderUpValid = JSON.stringify(header) === JSON.stringify(headerUpCategory);
    if (!isHeaderValid && uploadType === 'create') {
      showToastFailed('File is not Same With Template, Please Check your file corectly');
      fileUploadRef.current?.clear();
      return;
    }
    if (!isHeaderUpValid && uploadType === 'edit') {
      showToastFailed('File is not Same With Template, Please Check your file corectly');
      fileUploadRef.current?.clear();
      return;
    }
    setFileName(event.files[0].name);
    if (json.length > 1) {
      json.shift();
      const categoryResult:ICategoryImport[] = json.map((item)=>{
        return uploadType === 'create' ? {
          parent: item['Parent Kategori'],
          name: item['Nama Kategori'],
          status: item['Status'],
          meta_title: item['Meta Title'],
          description: item['Deskripsi Kategori'] != undefined ? item['Deskripsi Kategori'] : '',
          icon: item['Icon Kategori'] != undefined ? item['Icon Kategori'] : '',
          attribute_set: item['Attribute Set'] && item['Attribute Set'].includes(',') ?
            item['Attribute Set'].split(',').map((it)=> Number(it)) :
            item['Attribute Set'] != undefined ? [item['Attribute Set']] : [],
          specifications: item['Spesifikasi'] && item['Spesifikasi'].includes(',') ?
            item['Spesifikasi'].split(',').map((it)=> Number(it)) :
            item['Spesifikasi'] != undefined ? [item['Spesifikasi']] : [],
          variants: item['Varian'] && item['Varian'].includes(',') ?
            item['Varian'].split(',').map((it)=> Number(it)) :
            item['Varian'] != undefined ? [item['Varian']] : []
        }:{
          id: item['ID'],
          parent: item['Parent Kategori'],
          name: item['Nama Kategori'],
          status: item['Status'],
          meta_title: item['Meta Title'],
          description: item['Deskripsi Kategori'] != undefined ? item['Deskripsi Kategori'] : '',
          icon: item['Icon Kategori'] != undefined ? item['Icon Kategori'] : '',
          attribute_set: item['Attribute Set'] && item['Attribute Set'].includes(',') ?
            item['Attribute Set'].split(',').map((it)=> Number(it)) :
            item['Attribute Set'] != undefined ? [item['Attribute Set']] : [],
          specifications: item['Spesifikasi'] && item['Spesifikasi'].includes(',') ?
            item['Spesifikasi'].split(',').map((it)=> Number(it)) :
            item['Spesifikasi'] != undefined ? [item['Spesifikasi']] : [],
          variants: item['Varian'] && item['Varian'].includes(',') ?
            item['Varian'].split(',').map((it)=> Number(it)) :
            item['Varian'] != undefined ? [item['Varian']] : []
        };
      });
      const categoryName = categoryResult.map((category)=>category.name);
      const duplicatedName = categoryName.filter((item, index)=>categoryName.indexOf(item)!== index);
      if (duplicatedName.length > 0) {
        showToastFailed(`Error name must be unique at ${duplicatedName.join(',')}`);
        fileUploadRef.current?.clear();
        return;
      }
      if (categoryResult?.length > 100) {
        showToastFailed('Can not Create more than 100 Category in one time');
        fileUploadRef.current?.clear();
        return;
      }
      setImportedCategory(categoryResult);
    } else {
      showToastFailed('File Can not be Empty');
      fileUploadRef.current?.clear();
    }
  }, [uploadType]);

  const handleToggleModal = useCallback((type:'create'|'edit')=>()=>{
    setUploadType(type);
    setIsShowModal(!isShowModal);
  }, [isShowModal]);

  const handleClickUpload = useCallback(async ()=>{
    importedCategory.forEach(async (item)=> {
      if (item.attribute_set.length > 0) {
        const { data: attribute } = await supabase.from(TABLE.ATTRIBUTE_SETS).select('*').in('id', item.attribute_set) as PostgrestSingleResponse<IAttributeSet[]>;
        if (attribute) {
          attribute.map((it)=> it.specification_ids.map((i)=> item.specifications.push(Number(i))));
          attribute.map((it)=> it.variant_ids.map((i)=>item.variants.push(Number(i))));
          importedCategory.map((item)=> {
            const uniqueSpec = [...new Set(item.specifications)];
            item.specifications = uniqueSpec;
            const uniqueVar = [...new Set(item.variants)];
            item.variants = uniqueVar;
            return item;
          });
        }
      }
    });
    const payload = importedCategory.map((item)=>{
      return uploadType === 'create' ? {
        parent_id: item.parent,
        name: item.name,
        status: item.status == 'TRUE' ? 'ACTIVE' : 'INACTIVE',
        meta_title: item.meta_title,
        description: item.description,
        images: {
          data: {
            image: item.icon
          }
        }
      }:{
        id: item.id,
        parent_id: item.parent,
        name: item.name,
        status: item.status == 'TRUE' ? 'ACTIVE' : 'INACTIVE',
        meta_title: item.meta_title,
        description: item.description,
        images: {
          data: {
            image: item.icon
          }
        }
      };
    });
    if (uploadType === 'edit') {
      const { error: errorSpec } = await deleteCategorySpec(importedCategory.map((item)=> String(item.id)));
      const { error: errorVar } = await deleteCategoryVar(importedCategory.map((item)=> String(item.id)));
      if (errorSpec || errorVar) {
        return;
      }
    }

    const errorImport:Array<string> = [];

    for (const [index, item] of payload.entries()) {
      const { data: dataCategory, error: errorCategory } = await upsertCategory(item);
      if (errorCategory) {
        errorImport.push(`Baris ke-${index+3}: `+ errorCategory.message);
        continue;
      }
      if (importedCategory[0].specifications.length > 0) {
        const payloadSpec = importedCategory[index].specifications.map((item)=>({
          category_id: dataCategory.id,
          specification_id: Number(item)
        }));
        const { error: errorCategorySpesification } = await supabase.from(TABLE.CATEGORY_SPECIFICATION).insert(payloadSpec);
        if (errorCategorySpesification) {
          errorImport.push(`Baris ke-${index+3}: `+ errorCategorySpesification.details);
          continue;
        };
      }
      if (importedCategory[index].variants.length > 0) {
        const payloadVar = importedCategory[index].variants.map((item)=>({
          category_id: dataCategory.id,
          variant_id: Number(item)
        }));
        const { error: errorCategoryVariant } = await supabase.from(TABLE.CATEGORY_VARIANTS).insert(payloadVar);
        if (errorCategoryVariant) {
          errorImport.push(`Baris ke-${index+3}: `+ errorCategoryVariant.details);
          continue;
        };
      }
    }
    const payloadImportHistorySuccess = payload.length - errorImport.length > 0 && {
      account_id: user?.id || '',
      file_path: fileName || '',
      row_number: payload.length - errorImport.length,
      status: true,
      type: uploadType === 'create' ? 'bulk_upload_category' : 'bulk_update_category'
    };

    const payloadImportHistoryError = errorImport.length>0&&{
      account_id: user?.id || '',
      file_path: fileName || '',
      errors: errorImport,
      row_number: errorImport.length,
      status: false,
      type: uploadType === 'create' ? 'bulk_upload_category' : 'bulk_update_category'
    };

    const payloadImportHistory = [payloadImportHistorySuccess, payloadImportHistoryError].filter((item)=> typeof item != 'boolean');
    await insertImportHistory(payloadImportHistory as IHistory[]);
    showToastSucces('Success');
    fileUploadRef.current?.clear();
    setIsShowModal(false);
  }, [importedCategory, fileName, user, uploadType]);

  useEffect(() => {
    getAllCategory(0, 100, debounceSearch, filters);
  }, [filters, debounceSearch]);

  useEffect(() => {
    setDataTreeCategory(dataAllCategory.slice(paginator.range.start, paginator.range.end + 1) as TreeNode[]);
  }, [dataAllCategory, paginator]);

  const expandAll = () => {
    const _expandedKeys = {};

    for (const node of dataTreeCategory) {
      expandNode(node, _expandedKeys);
    }

    setExpandedKeys(_expandedKeys);
  };

  const collapseAll = () => {
    setExpandedKeys({});
  };

  const expandNode = (node: TreeNode, _expandedKeys: TreeExpandedKeysType) => {
    if (node.children && node.children.length && node.key) {
      _expandedKeys[node.key] = true;

      for (const child of node.children) {
        expandNode(child, _expandedKeys);
      }
    }
  };

  const handleClearFilter = useCallback(() => {
    setLastFilterPage({
      ...lastFilterPage,
      specification: ''
    });
    setItemFilters(initialFilter);
    setFilters(initialFilter);
    setFilterHistory([]);
    setSearch('');
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: rows - 1
      }
    });
  }, [lastFilterPage, rows]);

  const handleDeleteFilterHistory = useCallback(
    (key: string, value: string[]) => {
      const items = value[0].split(',');
      items.forEach((i) => {
        setFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));

        setItemFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));
      });

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setSearch('');
    },
    []
  );

  const [payloadUpsert, setPayloadUpsert] = useState<{ id: number, position: number, parent_id: number | null }[]>([]);
  const updatePositions = async () => {
    const { error } = await supabase.from(TABLE.CATEGORIES).upsert(payloadUpsert);
    if (error) return showToastFailed('Gagal Update Position');
    return showToastSucces('Success update position');
  };

  const handleDragDrop = (e) => {
    const { dragNode, dropNode, dropIndex } = e;
    const dragKey = dragNode?.['key']?.split('-')?.map((item: string) => Number(item));
    const dropKey = dropNode?.['key']?.split('-')?.map((item: string) => Number(item)) ?? [dropIndex];
    if (dragKey?.length === 1 && dropNode && dropKey?.length > 1) dropKey[0] -= 1;
    else if (dragKey?.length === 2 && dropNode && dragKey?.[0] === dropKey?.[0]) {
      dropKey[1] -= 1;
    }

    if (dragNode.count > 0 && dragKey.length !== dropKey.length) return;

    const tempNewValue = dataAllCategory.slice();

    if (dragKey?.length === 1) tempNewValue.splice(dragKey?.[0], 1);
    else if (dragKey?.length === 2) {
      tempNewValue[dragKey?.[0]]?.children?.splice(dragKey?.[1], 1);
    } else if (dragKey?.length === 3) {
      tempNewValue[dragKey?.[0]]?.children?.[dragKey?.[1]]?.children?.splice(dragKey?.[2], 1);
    }

    if (dropKey?.length === 1) tempNewValue.splice(dropKey?.[0], 0, dragNode);
    else if (dropKey?.length === 2) {
      tempNewValue[dropKey?.[0]]?.children?.splice(dropKey?.[1], 0, dragNode);
    } else if (dropKey?.length === 3) {
      tempNewValue[dropKey?.[0]]?.children?.[dropKey?.[1]]?.children
        ?.splice(dropKey?.[2], 0, dragNode);
    }

    const newValue = tempNewValue.map((item1, index1) => ({
      ...item1,
      name: item1?.['name'],
      status: item1?.['status'],
      images: item1?.['images'],
      meta_title: item1?.['meta_title'] ?? '',
      parent_id: null,
      position: index1,
      key: `${index1}`,
      children: item1?.children?.map((item2, index2) => ({
        ...item2,
        name: item2?.['name'],
        status: item2?.['status'],
        images: item2?.['images'],
        meta_title: item2?.['meta_title'] ?? '',
        parent_id: item1?.['id'],
        position: index2,
        key: `${index1}-${index2}`,
        children: item2?.children?.map((item3, index3) => ({
          ...item3,
          name: item3?.['name'],
          status: item3?.['status'],
          images: item3?.['images'],
          meta_title: item3?.['meta_title'] ?? '',
          parent_id: item2?.['id'],
          position: index3,
          key: `${index1}-${index2}-${index3}`
        }))
      }))
    }));

    setDataTreeCategory(newValue?.slice(paginator.range.start, paginator.range.end + 1));
    setDataAllCategory(newValue);

    const selectedIndex = newValue && newValue.length > 0 ? newValue : null;
    setSelectedNodeIndex(selectedIndex as number | null);
    const dataCategory = newValue as Categories[];
    const pagesIndex = first;
    const dragParentId = dragNode.parent_id;
    const dropParentId = dropNode?.parent_id ?? null;
    const parents = dataCategory.map((item, index)=> {
      return {
        id: Number(item.id),
        position: index + pagesIndex,
        parent_id: item.parent_id
      };
    }).filter((item)=> item.parent_id === dropParentId || item.parent_id === dragParentId);
    const subParent = dataCategory.flatMap((item)=> {
      return item.children && item.children.map((i, idx)=> {
        return {
          id: Number(i.id),
          position: idx,
          parent_id: Number(i.parent_id)
        };
      });
    }).filter((i)=> i?.parent_id === dropParentId || i?.parent_id === dragParentId);
    const childs = dataCategory.flatMap((it)=> {
      return it.children && it.children.flatMap((i)=> {
        return i.children && i.children.map((c, idx)=> {
          return {
            id: Number(c.id),
            position: idx,
            parent_id: Number(c.parent_id)
          };
        });
      });
    }).filter((it)=> it?.parent_id === dropParentId || it?.parent_id === dragParentId);
    if (parents && parents.length > 0) {
      setPayloadUpsert((prev) => {
        const concatData = uniqBy([...parents, ...prev], 'id');
        return concatData as [];
      });
    }
    if (subParent && subParent.length > 0) {
      setPayloadUpsert((prev)=> {
        const concatData = uniqBy([...subParent, ...prev], 'id');
        return concatData as [];
      });
    }
    if (childs && childs.length > 0) {
      setPayloadUpsert((prev)=> {
        const concatData = uniqBy([...childs, ...prev], 'id');
        return concatData as [];
      });
    }
  };

  const totalPages = useMemo(() => {
    return Math.ceil(totalRecords / rows);
  }, [totalRecords, rows]);


  const handleClickNext = useCallback(() => {
    paginator.currentPage <= totalPages &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: paginator.currentPage + 1,
        range: {
          start: paginator.range.start + rows,
          end: paginator.range.end + rows
        }
      }));
  }, [paginator, totalPages]);

  const handleClickPrev = useCallback(() => {
    paginator.range.start > 0 &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: prev.currentPage - 1,
        range: {
          start: prev.range.start - rows,
          end: prev.range.end - rows
        }
      }));
  }, [paginator]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent) => {
    setRows(event.value);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * rows;
      const rangeEnd = Math.min(value * rows - 1, totalRecords - 1);

      setPaginator(() => ({
        currentPage: value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, rows, totalRecords]);

  const handleClickSubmitFilter = useCallback(() => {
    setFilters(itemFilters);
    setIsOpenDialog(false);
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: rows - 1
      }
    });
  }, [itemFilters, rows]);

  const storeFilterHistory = useCallback((filter: IFilterCategory) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter.id_from != null) {
      let filterID = filter.id_from.toString();
      if (filter.id_to != null) {
        filterID = `${filter.id_from}-${filter.id_to}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('ID', 'id_from,id_to', filterID));
    }

    if (filter.name !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Name', 'name', filter.name));
    }

    if (filter.level != null) {
      filterHistoryItems.push(createFilterHistoryItem('Level', 'level', filter.level.toString()));
    }

    if (filter.status !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Status', 'status', filter.status));
    }

    setFilterHistory(filterHistoryItems);
  }, []);

  const handleSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>)=>{
    setSearch(currentTarget.value);
    const newKeyword = {
      name: 'Keyword',
      items: [{
        label: currentTarget.value,
        value: currentTarget.value,
        name: currentTarget.value
      }]
    };

    if (currentTarget.value != '') {
      setFilterHistory((prev: IFilterHistoryItems[]) => {
        const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
        if (existingIndex !== -1) {
          prev[existingIndex] = newKeyword;
        } else {
          prev.push(newKeyword);
        }
        return [...prev];
      });

      setPaginator({
        currentPage: 1,
        range: {
          start: 0,
          end: rows - 1
        }
      });
    } else {
      handleDeleteFilterHistory('Keyword', ['Keyword']);
    }
  }, [rows]);

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  useEffect(() => {
    // update store visitedPage with latest page
    setVisitedPage({
      ...visitedPage,
      category: paginator.currentPage
    });
  }, [paginator.currentPage]);

  // update store lastFilterPage with latest filter
  useEffect(() => {
    setLastFilterPage({
      ...lastFilterPage,
      category: JSON.stringify(filters)
    });
  }, [filters]);

  return {
    data: {
      filter,
      toast,
      isLoading,
      dataTreeCategory,
      first,
      rows,
      totalRecords,
      selectedNodeIndex,
      dataOptions,
      fileUploadRef,
      isShowModal,
      uploadType,
      filters,
      expandedKeys,
      filterHistory,
      paginator,
      totalPages,
      itemFilters,
      search,
      isOpenDialog
    },
    method: {
      setFilter,
      onConfirmDelete,
      setDataTreeCategory,
      onCreateCategory,
      handleClickUpload,
      handleClickDownload,
      handleChangeFileUpload,
      handleToggleModal,
      setFirst,
      setRows,
      onPageChange,
      setSelectedNodeIndex,
      handleFilter,
      setFilters,
      handleReset,
      expandAll,
      collapseAll,
      setExpandedKeys,
      handleDeleteFilterHistory,
      handleClearFilter,
      findCategory,
      handleDragDrop,
      updatePositions,
      handleClickNext,
      handleClickPrev,
      handleChangeDropdownPage,
      handleChangeJumpTopage,
      handleJumpToPage,
      handleClickSubmitFilter,
      setIsOpenDialog,
      handleSearch
    }
  };
};

