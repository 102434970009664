import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { useFormik } from 'formik';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { schemaUserAdmin } from './validation';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { IFilterAdmin, useAllUserAdmin } from '@/services/rest/userAdmin';
import { DataTableSortEvent } from 'primereact/datatable';
import { DropdownChangeEvent } from 'primereact/dropdown';
import dayjs from 'dayjs';
import { createUser, deleteUserById, updateUserById } from '@/services/rest/userManagement';
import { concat, debounce, uniqBy } from 'lodash';
import { accountTypeOptions } from '@/utils';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { useHistoryStore } from '@/store/useHistoryStore';
import { IFilterHistoryItems } from '@/components/base/FilterHistory';
import { useDebounce } from 'primereact/hooks';

export interface IUsers {
  birth_date: Date;
  created_at: Date;
  email: string;
  first_name: string;
  gender: string;
  id: string;
  password?: string;
  last_name: string;
  phone_number: string;
  photo: string;
  safe_mode: boolean;
  status: boolean;
  type: string;
  username: string;
  members: IMember[];
  user_metadata: UserMetadata
}
interface UserMetadata {
  application_id: string;
  first_name: string;
  last_name: string;
  merchant_id: string;
  team_id: string;
  type: string;
  username: string;
}
export interface IMember {
    teams: ITeams;
}
export interface ITeams {
  id: number;
  name: string;
}
export interface IUser {
  id: string;
  name: string;
  teams: string;
  password?:string;
  type: string;
  created_at: string;
  status: string;
}
const initialForm = {
  name: '',
  email: '',
  password: '',
  first_name: '',
  last_name: '',
  type: '',
  team_name: '',
  team_id: 0,
  editing: false,
  creating: false,
  reseting: false
};
export const initialValue = {
  adminName: '',
  adminEmail: '',
  adminType: '',
  adminGroup: 0,
  adminPassword: ''
};

interface Data {
  label: string;
  name:string;
  value: string | boolean;
}
interface Options {
  label: string;
  items: Data[]
}

const initialOptions = [{
  label: '',
  items: []
}];

const useCustom = () => {
  const initialFilter = {
    admin_name: '',
    user_group: '',
    created_at: [],
    status: ''
  };

  const [search, debounceSearch, setSearch] = useDebounce('', 1500);
  const [teams, setTeams] = useState<ITeams[]>([]);
  const [formInput, setFormInput] = useState(initialForm);
  const toast = useRef<Toast>(null);
  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const [filters, setFilters] = useState(lastFilterPage.userAdmin != '' ? JSON.parse(String(lastFilterPage.userAdmin)) : initialFilter);
  const [itemFilters, setItemFilters] = useState(lastFilterPage.userAdmin != '' ? JSON.parse(String(lastFilterPage.userAdmin)) : initialFilter);
  const [options, setOptions] = useState<Options[]>(initialOptions);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(10);
  const currentPage = parseInt(visitedPage.userAdmin.toString()) ?? 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : perPage - 1;
  const [paginator, setPaginator] = useState({ currentPage, range: { start, end } });
  const [jumpToPage, setJumpToPage] = useState<number>(1);
  const [sort, setSort] = useState({
    field: 'created_at',
    asc: false
  });
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);

  const { data: dataUserAdmin, isLoading: isLoadingUser, refetch: refetchUserAdmin } = useAllUserAdmin(paginator.range, sort, filters, debounceSearch);

  const users = useMemo(() =>{
    if (!Array.isArray(dataUserAdmin?.data)) return [];
    return dataUserAdmin?.data.map((user)=>({
      ...user,
      status: user.status ? 'Active' : 'Inactive',
      name: `${String(user.first_name)} ${user.last_name}`,
      teams: user.members[0]?.teams?.name || '-',
      created_at: dayjs(user.created_at).format('DD MMM YYYY') || '-',
      type: accountTypeOptions.find((n) => n.code == user?.user_metadata?.type)?.label || '-'
    }));
  }, [dataUserAdmin]);

  const totalRecords = useMemo(()=>{
    return dataUserAdmin?.count ? dataUserAdmin.count : 0;
  }, [dataUserAdmin]);

  const totalPages = useMemo(()=>{
    return Math.ceil(totalRecords / perPage);
  }, [totalRecords]);

  //formik initial
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schemaUserAdmin,
    onSubmit: (value, { resetForm }) => {
      if (id && formInput.reseting) {
        onResetPassword(id);
      }
      if (id) {
        editUser();
      } else {
        return onCreateUser();
      }
      resetForm();
    }
  });

  const showSuccess = (msg: string) => {
    if (toast.current != null) {
      toast.current.show({
        severity: 'success',
        summary: msg=='Created'? 'Sukses!' : `${msg}`,
        detail: `Admin ${msg} Successfully`,
        life: 2500
      });
      setTimeout(() => {
        if (id) {
          formInputValue('editing', false);
        } else {
          formInputValue('creating', false);
        }
      }, 1000);
    }
  };

  const showError = (msg: string) => {
    if (toast.current !== null) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: `${msg}`,
        life: 2000
      });
      setIsLoading(false);
    }
  };

  const findNamesDebounce = debounce(async (findName: string | null) => {
    if (!findName) {
      return;
    }

    const { data } = await supabase.from('user.accounts').select('id, first_name, last_name, members!inner(teams!inner(application_id))').or(`first_name.ilike.*${findName}*,last_name.ilike.*${findName}*`).eq('members.teams.application_id', 1).limit(10) as PostgrestSingleResponse<IUsers[]>;

    if (data) {
      const names: Data[] = data.map((item)=> ({
        label: item.first_name + item.last_name,
        value: item.id,
        name: 'name'
      }));
      const _options = options.map((o) => {
        if (o.label === 'Name') {
          o.items = uniqBy(concat(o.items || [], names), 'value');
        }
        return o;
      });
      setOptions([..._options]);
    }
  }, 250);

  const findNames = async (findName: string | null) => {
    findNamesDebounce(findName);
  };

  const filterOption = useCallback(async ()=> {
    const { data } = await supabase.from(TABLE.TEAM).select('id,name').limit(10).eq('application_id', 1) as PostgrestSingleResponse<ITeams[]>;
    if (data) {
      const teamData: Data[] = data.map((item) => ({
        label: item.name,
        value: item.name,
        name: 'group'
      } as Data));

      const dataOption:Options[] = [
        {
          label: 'Name',
          items: []
        },
        {
          label: 'Group',
          items: teamData
        },
        {
          label: 'Status',
          items: [
            { label: 'Active', name: 'status', value: true },
            { label: 'Inactive', name: 'status', value: false }
          ]
        }
      ];
      setOptions(dataOption);
    }
  }, []);

  const getTeams = useCallback(async ()=> {
    const { data } = await supabase.from(TABLE.TEAM).select('id, name, application_id').eq('application_id', 1).limit(50).order('id', { ascending: false });
    setTeams(data as ITeams[]);
  }, []);

  const handleFilter = useCallback((e, field: string) => {
    setItemFilters((prev) => ({
      ...prev,
      [field]: e.target ? e.target.value : e.value
    }));
  }, []);

  const handleDeleteFilterHistory = useCallback(
    (key: string, value: string[]) => {
      setFilters((prev) => ({
        ...prev,
        [value[0]]: initialFilter[value[0]]
      }));

      setItemFilters((prev) => ({
        ...prev,
        [value[0]]: initialFilter[value[0]]
      }));

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setSearch('');
    },
    []
  );

  //delete all filter
  const handleClearFilter = useCallback(()=>{
    setLastFilterPage({
      ...lastFilterPage,
      userAdmin: ''
    });
    setItemFilters(initialFilter);
    setFilters(initialFilter);
    setFilterHistory([]);
    setSearch('');
  }, [lastFilterPage]);

  const formInputValue = (key: string, value: string | boolean | number) => {
    if (key === 'name' && typeof(value) === 'string') {
      const firstName = value.split(' ').length === 1 ? value : value.split(' ').splice(0, 1).join('');
      const lastName = value.split(' ').length === 1 ? '' : value.split(' ').splice(1).join(' ');
      setFormInput((prevState) => {
        return {
          ...prevState,
          ['first_name']: firstName,
          ['last_name']: lastName
        };
      });
      setFormInput((prevState) => {
        return {
          ...prevState,
          ['name']: value
        };
      });
    }
    setFormInput((prevState) => {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  //formik validate
  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const { id } = useParams();

  const getUserByID = useCallback(async ()=> {
    const { data, error } = await supabase.from(TABLE.ACCOUNTS).select('*, members(teams(name, id))').eq('id', id).single();
    const response = await fetch(`${import.meta.env.VITE_APP_SUPABASE_URL}/auth/v1/admin/users/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'ApiKey': import.meta.env.VITE_APP_SUPABASE_KEY,
        'Authorization':
          'Bearer ' +
          (await supabase.auth.getSession()).data.session?.access_token
      }
    });

    const resJson = await response.json();
    data.user_metadata = resJson.user_metadata;
    if (data) {
      const user = data as IUsers;
      const fullName = `${user.first_name} ${user.last_name}`;
      formInputValue('name', fullName);
      formik.setFieldValue('adminName', fullName);
      formInputValue('email', user.email);
      formik.setFieldValue('adminEmail', user.email);
      formInputValue('type', user?.user_metadata?.type);
      formik.setFieldValue('adminType', user?.user_metadata?.type);
      formInputValue('team_id', user.members[0]?.teams?.id);
      formik.setFieldValue('adminGroup', user.members[0]?.teams?.id);
      formInputValue('team_name', user.members[0]?.teams?.name);
      formik.setFieldValue('adminPassword', 'Password123$');
    }
    if (error) {
      showError(error.message);
    }
  }, [formInput]);

  const editUser = useCallback(async () => {
    if (id) {
      const { data: user, error } = await updateUserById(
        id,
        { user_metadata: {
          first_name: formInput.first_name,
          last_name: formInput.last_name,
          team_id: formInput.team_id,
          type: formInput.type,
          username: ''
        } }
      );
      if (error) showError(error.message);
      if (user.user) {
        const { error: updateErr } = await supabase.from(TABLE.ACCOUNTS).update({
          first_name: user.user.user_metadata.first_name,
          last_name: user.user.user_metadata.last_name
        }).eq('id', user.user.id);
        if (updateErr) showError(updateErr.message);
        const { error: memberErr } = await supabase.from(TABLE.MEMBER).update({
          account_id: user.user.id,
          team_id: user.user.user_metadata.team_id
        }).eq('account_id', user.user.id);
        if (memberErr) showError(memberErr.message);
      }
      getUserByID();
      refetchUserAdmin();
      showSuccess('Updated');
    }
  }, [formInput]);

  const onCreateUser = useCallback(async ()=> {
    setIsLoading(true);
    const { data: newUser, error: errorCreate } = await createUser({
      email: formInput.email,
      password: formInput.password,
      email_confirm: true,
      user_metadata: {
        application_id: 1,
        first_name: formInput.first_name,
        last_name: formInput.last_name,
        team_id: formInput.team_id,
        type: formInput.type,
        username: ''
      }
    });
    if (errorCreate) {
      return showError(errorCreate.msg);
    }
    if (newUser.user && newUser.user.email) {
      const { error: updateErr } = await supabase.from(TABLE.ACCOUNTS).update({
        first_name: newUser.user.user_metadata.first_name,
        last_name: newUser.user.user_metadata.last_name
      }).eq('id', newUser.user.id);
      if (updateErr) {
        return showError(updateErr.message);
      }
      const { error } = await supabase.from(TABLE.MEMBER).insert({
        account_id: newUser.user.id,
        team_id: newUser.user.user_metadata.team_id
      });
      if (error) {
        return showError(error.message);
      }
    }
    resetForm();
    refetchUserAdmin();
    setIsLoading(false);
    showSuccess('Created');
  }, [formInput]);

  const onResetPassword = async (ids: string) => {
    const { data: user, error } = await updateUserById(
      ids,
      { password: formInput.password }
    );
    if (user) {
      showSuccess('Password Reset');
      formInputValue('reseting', false);
    } else {
      showError(error?.message || 'Failed Reset Password');
    }
  };

  const onDelete = async (ids: string) => {
    const { error } = await deleteUserById(ids);
    if (error) showError(error.message);
    if (!error) {
      refetchUserAdmin();
      showSuccess('Deleted');
    };
  };

  const confirmDelete = (ids: string) => {
    confirmDialog({
      message: 'Are you sure you want to delete this record?',
      header: 'Delete Confirmation',
      acceptClassName: 'p-button-danger',
      accept() {
        onDelete(ids);
      }
    });
  };

  const resetForm = () => {
    setFormInput(initialForm);
  };

  const handleClickNext = useCallback(() => {
    paginator.currentPage <= totalPages &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: paginator.currentPage + 1,
        range: {
          start: paginator.range.start + perPage,
          end: paginator.range.end + perPage
        }
      }));
  }, [paginator, totalPages, perPage]);

  const handleClickPrev = useCallback(() => {
    paginator.range.start > 0 &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: prev.currentPage - 1,
        range: {
          start: prev.range.start - perPage,
          end: prev.range.end - perPage
        }
      }));
  }, [paginator, perPage]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent) => {
    setPerPage(event.value);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, totalRecords - 1);

      setPaginator(() => ({
        currentPage: jumpToPage > totalPages ? totalPages : value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, totalRecords]);

  const handleChangeSort = useCallback((e: DataTableSortEvent) => {
    setSort((prev) => ({
      ...prev,
      field: e.sortField === 'name' ? 'first_name' : e.sortField,
      asc: !prev.asc
    }));
  }, []);

  const handleClickSubmitFilter = useCallback(() => {
    setFilters(itemFilters);
    setPaginator({
      currentPage: 1,
      range: { start: 0, end: perPage - 1 }
    });
  }, [itemFilters, perPage]);

  const storeFilterHistory = useCallback((filter: IFilterAdmin) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter?.created_at && filter.created_at[0]) {
      let filterDate = `${dayjs(filter.created_at[0]).format('YYYY-MM-DD')}`;
      if (filter.created_at.length > 1 && filter.created_at[1] !== null) {
        filterDate = `${dayjs(filter.created_at[0]).format('YYYY-MM-DD')} - ${dayjs(filter.created_at[1]).format('YYYY-MM-DD')}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Created At', 'created_at', filterDate));
    }

    if (filter.admin_name !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Admin Name', 'admin_name', filter.admin_name));
    }

    if (filter.status !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Status', 'status', filter.status));
    }

    if (filter.user_group !== '') {
      filterHistoryItems.push(createFilterHistoryItem('User Group', 'user_group', filter.user_group));
    }

    setFilterHistory(filterHistoryItems);
  }, []);

  const handleSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>)=>{
    setSearch(currentTarget.value);
    const newKeyword = {
      name: 'Keyword',
      items: [{
        label: currentTarget.value,
        value: currentTarget.value,
        name: currentTarget.value
      }]
    };

    if (currentTarget.value != '') {
      setFilterHistory((prev: IFilterHistoryItems[]) => {
        const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
        if (existingIndex !== -1) {
          prev[existingIndex] = newKeyword;
        } else {
          prev.push(newKeyword);
        }
        return [...prev];
      });

      setPaginator({
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      });
    } else {
      handleDeleteFilterHistory('Keyword', ['Keyword']);
    }
  }, [perPage]);

  useEffect(()=>{
    filterOption();
  }, []);

  useEffect(()=>{
    getTeams();
  }, [formInput.creating, formInput.editing]);

  useEffect(()=>{
    if (id) {
      getUserByID();
    }
  }, [formInput.editing, id]);

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  // update store visitedPage with latest page
  useEffect(() => {
    setVisitedPage({
      ...visitedPage,
      userAdmin: paginator.currentPage
    });
  }, [paginator.currentPage]);

  // update store lastFilterPage with latest filter
  useEffect(() => {
    setLastFilterPage({
      ...lastFilterPage,
      userAdmin: JSON.stringify(filters)
    });
  }, [filters]);

  return {
    data: {
      users,
      search,
      teams,
      toast,
      formInput,
      formik,
      options,
      filters,
      filterHistory,
      isLoading,
      isLoadingUser,
      perPage,
      paginator,
      totalPages,
      totalRecords,
      accountTypeOptions,
      itemFilters
    },
    method: {
      formInputValue,
      resetForm,
      onCreateUser,
      confirmDelete,
      editUser,
      isFormFieldValid,
      handleFilter,
      onResetPassword,
      handleClearFilter,
      handleDeleteFilterHistory,
      handleClickNext,
      handleClickPrev,
      handleChangeSort,
      handleChangeDropdownPage,
      findNames,
      handleChangeJumpTopage,
      handleJumpToPage,
      handleClickSubmitFilter,
      handleSearch
    }
  };
};

export default useCustom;
