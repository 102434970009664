import React, { useEffect, useState } from 'react';
import './style.css';
interface ICToggleProps {
  value?: boolean;
  onToggle?: (e: React.ChangeEvent<HTMLInputElement> | boolean) => void;
  disabled?: boolean;
  isReturnBoolean?: boolean;
}
const Toggle = ({ value, onToggle, disabled, isReturnBoolean }: ICToggleProps) => {
  const [isToggled, setIsToggled] = useState(!!value);
  const _onToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    if (onToggle) onToggle(e);
  };
  const _onClickLabel = () => {
    setIsToggled((c) => !c);
  };
  useEffect(() => {
    if (isReturnBoolean && onToggle) onToggle(isToggled);
  }, [isToggled]);
  return (
    <div className="flex item-center justify-center">
      <input className="input-toggle" type="checkbox" id="switch" onChange={_onToggle} checked={value} disabled={disabled} />
      <label className="label-toggle" htmlFor="switch" onClick={_onClickLabel}>
        Toggle
      </label>
    </div>
  );
};

export default Toggle;
