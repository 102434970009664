import dayjs from 'dayjs';
import * as XLSX from 'xlsx';

import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { toUpper } from 'lodash';
import { Toast } from 'primereact/toast';
import { useNavigate, useParams } from 'react-router-dom';
import { FLASH_SALE_PRICE_BY, FLASH_SALE_SORT_BY, FLASH_SALE_SORT_VALUE, TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { menuFlashSaleInformation } from '../helpers';
import { useAuthStore } from '@/store/useAuthStore';
import { optionPeriode, optionStatus } from '../helpers';
import { HeaderFlashSale } from '../type';
import { schemaFlashSale } from './validation';
import { getProductMerchantDetail } from '@/services/rest/product';
import { insertImportHistory } from '@/services/rest/importHistory';

import type { FileUpload, FileUploadFilesEvent } from 'primereact/fileupload';
import type { IHistory } from '@/services/rest/importHistory';
import type { ITableDataProducts } from '@/views/FlashSaleManagement/Create/hooks';
import type { IDropdown, IFormFlashSale, IPeriode, ISlotSupabase, ITemplateXLS, ITime, ITimeRange } from '../type';

interface IFormikState {
  name: string;
  description: string;
  slot_type: string;
  periode: string;
  status: string;
}

export const useEditFlashSale = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [optionSlot, setOptionSlot] = useState<IDropdown[]>();
  const [time, setTime] = useState<ITime[]>();
  const [slot, setSlot] = useState<ISlotSupabase[]>();
  const [isOpenDropdownSlot, setIsOpenDropdownSlot] = useState<boolean>(false);
  const [slotName, setSlotName] = useState<string>('');
  const [formEditFlashSale, setFormEditFlashSale] = useState<IFormFlashSale>({
    product_variant_id: '',
    master_slot_id: {} as IDropdown,
    periode: {} as IDropdown,
    multiple: [],
    valid_from: '',
    valid_to: '',
    name: '',
    description: '',
    status: {} as IDropdown
  });
  const [history, setHistory] = useState<string[]>([]);
  const [products, setProducts] = useState<ITableDataProducts[]>([]);
  const [flashsaleEditTable, setFlashsaleEditTable] = useState<ITableDataProducts[]>([]);
  const [productUpload, setProductUpload] = useState<ITableDataProducts[]>([]);
  const [deletedProducts, setDeletedProducts] = useState<ITableDataProducts[]>([]);
  const [timeRange, setTimeRange] = useState<ITimeRange[]>();
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useAuthStore(
    (state) => ({ user: state.user })
  );

  const initialFormik:IFormikState = {
    name: '',
    description: '',
    slot_type: '',
    periode: '',
    status: ''
  };

  const formik = useFormik({
    initialValues: initialFormik,
    validationSchema: schemaFlashSale,
    onSubmit: ()=>{
      const validFrom = dayjs(formEditFlashSale.valid_from);
      const validTo = dayjs(formEditFlashSale.valid_to);
      const isSameMultiple = checkMultipleDate(formEditFlashSale.multiple);

      if (validFrom.isAfter(validTo)) {
        showToastEditFlashSaleFailed('Valid from date is not allowed to grater than valid to date!');
      } else if (checkEmptyArray(formEditFlashSale.multiple)) {
        showToastEditFlashSaleFailed('Multiple date is empty');
      } else if (isSameMultiple==true) {
        showToastEditFlashSaleFailed('Multiple date is not allowed with same date');
      } else {
        onSaveFlashSale();
      }
    }
  });

  const checkMultipleDate = (arr) => {
    const seen = {};
    for (let i = 0; i < arr.length; i++) {
      if (seen[arr[i]]) {
        return true;
      }
      seen[arr[i]] = true;
    }
    return false;
  };

  function checkEmptyArray(arr) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] == '') {
        return true;
      }
    }
    return false;
  }

  const showToastEditFlashSaleSuccesfully = (msg: string) => {
    toast.current?.show({ severity: 'success', summary: 'Success', detail: msg });
  };

  const showToastEditFlashSaleFailed = (msg: string) => {
    toast.current?.show({ severity: 'error', summary: 'Error', detail: msg });
  };

  const onChangeFormEditFlashSale = (key: string, value: string | number | null | IDropdown) => {
    setFormEditFlashSale((prevState) => {
      return {
        ...prevState,
        [key]: value
      };
    });

    if (key == 'master_slot_id') {
      const times = slot?.find((item) => item.id === (value as IDropdown).code)?.time;
      setTime(times as ITime[]);
    }
  };

  const getFlashSaleById = async (opSlot: IDropdown[], slotSupa: ISlotSupabase[]) => {
    const { data, error } = await supabase.from(TABLE.FLASHSALE).select('id, name, description, master_slot_id, periode, parent_id').eq('id', params.id||params.detailId);
    if ( data != null ) {
      if (
        (data[0].periode as IPeriode).isRange === true ||
        ((data[0].periode as IPeriode).date.length === 1 && data && data[0].parent_id !== null)
      ) {
        const masterSlotId = opSlot.find((item) => item.code === data[0].master_slot_id) as IDropdown;
        const result: IFormFlashSale = {
          id: data[0].id,
          name: data[0].name,
          description: data[0].description,
          master_slot_id: masterSlotId,
          valid_from: String((data[0].periode as IPeriode).date[0]),
          valid_to: String((data[0].periode as IPeriode).date[1]),
          status: optionStatus.find((item) => item.code === (data[0].periode as IPeriode).isEnable) as IDropdown,
          product_variant_id: '',
          periode: optionPeriode.find((item) => item.code === 'range') as IDropdown,
          multiple: [],
          isEnable: data[0].periode.isEnable
        };
        const timeSlot = slotSupa.find((slotData) => slotData.id === masterSlotId.code)?.time;
        setTime(timeSlot);
        setFormEditFlashSale(result);
        formik.setFieldValue('name', data[0].name);
        formik.setFieldValue('description', data[0].description);
        formik.setFieldValue('slot_type', String(data[0].master_slot_id));
        formik.setFieldValue('periode', optionPeriode.find((item) => item.code === 'range')?.code);

        formik.setFieldValue(
          'status',
          optionStatus.find((item) => item.code === (data[0].periode as IPeriode).isEnable)?.code
        );
      } else if ((data[0].periode as IPeriode).isRange === false) {
        const masterSlotId = opSlot.find((item) => item.code === data[0].master_slot_id) as IDropdown;
        const result: IFormFlashSale = {
          id: data[0].id,
          name: data[0].name,
          description: data[0].description,
          master_slot_id: masterSlotId,
          status: optionStatus.find((item) => item.code === (data[0].periode as IPeriode).isEnable) as IDropdown,
          product_variant_id: '',
          periode: optionPeriode.find((item) => item.code === 'multiple') as IDropdown,
          multiple: (data[0].periode as IPeriode).date
        };
        const timeSlot = slotSupa.find((slotData) => slotData.id === masterSlotId.code);
        setTime(timeSlot?.time);
        if (timeSlot?.name) setSlotName(timeSlot?.name);
        setFormEditFlashSale(result);
        formik.setFieldValue('name', data[0].name);
        formik.setFieldValue('description', data[0].description);
        formik.setFieldValue('slot_type', String(data[0].master_slot_id));
        formik.setFieldValue('periode', optionPeriode.find((item) => item.code === 'range')?.code);

        formik.setFieldValue(
          'status',
          optionStatus.find((item) => item.code === (data[0].periode as IPeriode).isEnable)?.code
        );
      }
    } else {
      showToastEditFlashSaleFailed(`data flash sale tidak ditemukan, ${error.details}`);
    }
  };

  const getFlashSaleProducts = useCallback(async (slots: ISlotSupabase[]) => {
    const query = supabase.from(TABLE.FLASHSALE)
      .select('*,product_variants(*,products(*,categories(name),merchants(*)),attribute_set_variants(value))')
      .eq('parent_id', params.id || params.detailId)
      .eq('periode->>isEnable', true);
    const { data, error } = await query;

    if (!error) {
      const dataTable: ITableDataProducts[] = data.map((item) => {
        const slotTable = slots?.find((slotData) => slotData?.id === item.master_slot_id);
        const slotIndex = slotTable?.time?.findIndex((slotTime) => (
          slotTime.started_time === item?.time_slot?.start_time &&
          slotTime.ended_time === item?.time_slot?.ended_time
        ))! + 1;
        let productName = item.product_variants.products.name;
        if (item.product_variants?.['attribute_set_variants']?.length > 0) {
          const variants = item.product_variants?.['attribute_set_variants']
            ?.map((variant) => variant.value)?.join(', ');
          productName += ` (Varian: ${variants})`;
        }
        const periode = dayjs(item.periode.date[0]).format('MM-DD-YYYY');
        const elmt: ITableDataProducts = {
          'Product Variant ID': item.product_variant_id,
          'Product Name': productName,
          'Merchant': item.product_variants.products.merchants.name,
          'Merchant ID': item.product_variants.products.merchants.id,
          'Flash Sale Price By': item.price_by,
          'Flash Sale Price Value': item.price_value,
          'Stock': item.product_variants.saleable_stock,
          'Jumlah Stock Flash Sale': item.total_stock,
          'Sort By': item.sort_by,
          'Sort Value': item.sort_value,
          'Periode': periode,
          'Slot': slotIndex?.toString()!,
          'Status': item.periode.isEnable,
          'id': item.id
        };
        return elmt;
      });
      setFlashsaleEditTable(dataTable);
    }
  }, [params.id, params.detailId]);

  const getSlot = async () => {
    const { data, error } = await supabase.from(TABLE.SLOT).
      select('id, name, time').
      eq('status', 'ACTIVE').is('deleted_at', null).
      order('id', { ascending: false }).limit(10);

    if (data !== null && JSON.stringify(data) !== '{}' && !error) {
      const result = data.map((item) => {
        return {
          name: item.name,
          code: item.id
        };
      });
      const _timeRange = data?.map((m) => {
        const time: ITime[] = m.time.map((t, idx) => {
          return {
            index: idx + 1,
            started_time: t.started_time,
            ended_time: t.ended_time
          };
        });
        return {
          id: String(m.id),
          name: String(m.name),
          time: time
        };
      });
      setTimeRange(_timeRange);
      setOptionSlot(result as IDropdown[]);
      getFlashSaleById(result as IDropdown[], data as ISlotSupabase[]);
      setSlot(data as ISlotSupabase[]);
      getFlashSaleProducts(data as ISlotSupabase[]);
    }
  };

  const showFailed = () => {
    if (toast.current !== null) {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: 'Data Empty, Please check your file before upload',
        life: 2500
      });
    }
  };

  const showPartialSuccess = () => {
    if (toast.current !== null) {
      toast.current.show({
        severity: 'warn',
        summary: 'Uploaded',
        detail: 'File Uploaded Successfully, But Some Data Is Fail To Proceed',
        life: 4000
      });
    }
  };

  const showUploaded = () => {
    if (toast.current !== null) {
      toast.current.show({
        severity: 'success',
        summary: 'Uploaded',
        detail: 'File Uploaded Successfully',
        life: 2500
      });
    }
  };

  const fileUploaded = useRef<FileUpload>(null);
  const fileUpdateUploaded = useRef<FileUpload>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [fileLength, setFileLength] = useState(0);

  const handleSelectFile = useCallback(async (e: FileUploadFilesEvent) => {
    setIsLoading(true);
    setFileLength(0);
    if (e.files) {
      const text = await e.files[0].arrayBuffer();
      const wb = XLSX.read(text, { type: 'binary', cellDates: true });
      const sn = wb.SheetNames[0];
      const ws = wb.Sheets[sn];
      const json = XLSX.utils.sheet_to_json<ITemplateXLS>(ws, { raw: false, dateNF: 'YYYY-MM-DD' });
      const options = { header: 1 };
      const sheetData2 = XLSX.utils.sheet_to_json(ws, options);
      const header = sheetData2.shift();

      // Validasi header
      const isHeaderValid = JSON.stringify(header) === JSON.stringify(HeaderFlashSale);
      setFileName(e.files[0].name);

      if (json.length === 0) {
        showFailed();
        fileUploaded.current?.clear();
      } else if (!isHeaderValid) {
        toast?.current?.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'File Not Same With Template, Check your file corectly',
          life: 2500
        });
        fileUploaded.current?.clear();
      } else {
        fileUploaded.current?.clear();
        mapTableData(json.slice(1));
      }
    }
    setIsLoading(false);
  }, [
    formEditFlashSale.valid_from,
    formEditFlashSale.valid_to,
    formEditFlashSale.multiple,
    formEditFlashSale.periode.code
  ]);

  const handleSelectFileUpdate = useCallback(async (e: FileUploadFilesEvent) => {
    setIsLoading(true);
    if (e.files) {
      const text = await e.files[0].arrayBuffer();
      const wb = XLSX.read(text, { type: 'binary', cellDates: true });
      const sn = wb.SheetNames[0];
      const ws = wb.Sheets[sn];
      const json = XLSX.utils.sheet_to_json<ITemplateXLS>(ws, { raw: false, dateNF: 'YYYY-MM-DD' });
      const options = { header: 1 };
      const sheetData2 = XLSX.utils.sheet_to_json(ws, options);
      const header = sheetData2.shift();
      // Validasi header
      const isHeaderValid = JSON.stringify(header) === JSON.stringify(HeaderFlashSale);
      setFileName(e.files[0].name);

      if (json.length === 0) {
        showFailed();
        fileUpdateUploaded.current?.clear();
      } else if (!isHeaderValid) {
        toast?.current?.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'File Not Same With Template, Check your file corectly',
          life: 2500
        });
        fileUpdateUploaded.current?.clear();
      } else {
        fileUpdateUploaded.current?.clear();
        updateTableData(json.slice(1));
      }
    }
    setIsLoading(false);
  }, [
    flashsaleEditTable,
    formEditFlashSale.valid_from,
    formEditFlashSale.valid_to,
    formEditFlashSale.multiple,
    formEditFlashSale.periode.code
  ]);

  const getMappedDataAndValidateData = useCallback(async (data: ITemplateXLS[]) => {
    const listProductMerchantDetail = await Promise.all(data.map(async (item) => {
      const detail = await getProductMerchantDetail(item?.['Product Variant ID']);
      if (!!detail?.error && detail?.error.message.includes('uuid')) {
        return { error: true, message: 'invalid product variant' };
      }
      if (!!detail?.error) return { error: true, message: 'failed to get data detail' };
      return detail.data;
    }));

    const fsValidFrom = String(formEditFlashSale.valid_from);
    const fsValidTo = String(formEditFlashSale.valid_to);

    const mappedData: ITableDataProducts[] = [];

    setHistory([]);

    data.forEach((item, index) => {
      const isError = !!listProductMerchantDetail[index]?.['error'];
      const errorMessage = isError ? listProductMerchantDetail[index]?.['message'] : '';
      const saleableStock = listProductMerchantDetail[index]?.['saleable_stock'];
      const isValidPrice = listProductMerchantDetail[index]?.['price'] !== null;
      const periode = String(dayjs(item['Periode']).format('YYYY-MM-DD'));

      const isFsStockMoreThanSaleableStock = item['Jumlah Stock Flash Sale'] > saleableStock;
      const isOnPeriode = formEditFlashSale.periode.code === 'multiple' ?
        formEditFlashSale.multiple.includes(periode) :
        periode >= fsValidFrom && periode <= fsValidTo;
      const isNotDuplicatePeriodeAndSlot = mappedData.findIndex((fsProduct) => (
        fsProduct['Product Variant ID'] === item['Product Variant ID'] &&
        fsProduct['Periode'] === item['Periode'] &&
        fsProduct['Slot'] === item['Slot']
      )) === -1;
      const isNotDuplicateSequence = mappedData.findIndex((fsProduct) => (
        fsProduct['Periode'] === item['Periode'] &&
        fsProduct['Slot'] === item['Slot'] &&
        item['Sort By'] === 'sequence' &&
        item['Sort Value'] === fsProduct['Sort Value']
      )) === -1;
      const isNotDuplicatePeriodeAndSlotWithExistingData = flashsaleEditTable.findIndex((fsProduct) => (
        fsProduct['Product Variant ID'] === item['Product Variant ID'] &&
        fsProduct['Periode'] === item['Periode'] &&
        fsProduct['Slot'] === item['Slot']
      )) === -1;
      const isNotDuplicateSequenceWithExistingData = flashsaleEditTable.findIndex((fsProduct) => (
        fsProduct['Periode'] === item['Periode'] &&
        fsProduct['Slot'] === item['Slot'] &&
        item['Sort By'] === 'sequence' &&
        item['Sort Value'] === fsProduct['Sort Value']
      )) === -1;

      if (
        !isError &&
        !isFsStockMoreThanSaleableStock &&
        isValidPrice &&
        isOnPeriode &&
        isNotDuplicatePeriodeAndSlot &&
        isNotDuplicateSequence &&
        isNotDuplicatePeriodeAndSlotWithExistingData &&
        isNotDuplicateSequenceWithExistingData
      ) {
        const variants = listProductMerchantDetail[index]?.['attribute_set_variants']
          ?.map((variant: unknown) => variant?.['value'])
          ?.join(', ');
        const productName: string = listProductMerchantDetail[index]?.['products']?.['name'];
        const merchant = listProductMerchantDetail[index]?.['products']?.['merchants']?.['name'];

        const productFsData = {
          ...item,
          'Product Name': `${productName}${!variants ? '' : ` Varian: (${variants})`}`,
          'Merchant': merchant,
          'Stock': saleableStock,
          'Status': true,
          index
        };
        mappedData.push(productFsData);
      } else {
        const errorMessages: string[] = [];
        if (isError) errorMessages.push(errorMessage);
        if (isFsStockMoreThanSaleableStock) {
          errorMessages.push('stock flash sale exceeds the stock of the product');
        }
        if (!isValidPrice) {
          errorMessages.push('product price is invalid');
        }
        if (!isOnPeriode) {
          errorMessages.push('period is not aligned with parent\'s period');
        }
        if (!isNotDuplicatePeriodeAndSlot) {
          errorMessages.push('slot is duplicate');
        }
        if (!isNotDuplicateSequence) {
          errorMessages.push('sequence is duplicate');
        }
        if (!isNotDuplicatePeriodeAndSlotWithExistingData) {
          errorMessages.push('slot is already occupied by other product');
        }
        if (!isNotDuplicateSequenceWithExistingData) {
          errorMessages.push('sequence is already occupied by other product');
        }
        setHistory((current) => [
          ...current,
          `Baris-${index + 3} ${errorMessages.join(', ')}`
        ]);
      }
    });

    return mappedData;
  }, [
    flashsaleEditTable,
    formEditFlashSale.valid_from,
    formEditFlashSale.valid_to,
    formEditFlashSale.multiple,
    formEditFlashSale.periode.code
  ]);

  const mapTableData = useCallback(async (data: ITemplateXLS[]) => {
    setFileLength(data.length);
    const mappedData = await getMappedDataAndValidateData(data);

    const isSomeDataFailToProceed = data.length !== mappedData.length;
    if (isSomeDataFailToProceed) showPartialSuccess();
    else showUploaded();

    setProducts((current) => [...current, ...mappedData]);
  }, [
    formEditFlashSale.valid_from,
    formEditFlashSale.valid_to,
    formEditFlashSale.multiple,
    formEditFlashSale.periode.code
  ]);

  const updateTableData = useCallback(async (data: ITemplateXLS[]) => {
    const newTableData = flashsaleEditTable.slice();
    const mappedUpdatedData = await getMappedDataAndValidateData(data);

    const isSomeDataFailToProceed = data.length !== mappedUpdatedData.length;
    if (isSomeDataFailToProceed) showPartialSuccess();
    else showUploaded();

    for (const item of mappedUpdatedData) {
      const index = newTableData.findIndex(
        (elmt) => elmt['Product Variant ID'] === item['Product Variant ID']
      );
      const isDuplicatePeriodeAndSlot = newTableData.filter((fsProduct) => (
        fsProduct['Product Variant ID'] === item['Product Variant ID'] &&
        fsProduct['Periode'] === item['Periode'] &&
        fsProduct['Slot'] === item['Slot']
      )).length > 1;
      const isDuplicateSequence = newTableData.filter((fsProduct) => (
        fsProduct['Product Variant ID'] !== item['Product Variant ID'] &&
        fsProduct['Periode'] === item['Periode'] &&
        fsProduct['Slot'] === item['Slot'] &&
        item['Sort By'] === 'sequence' &&
        item['Sort Value'] === fsProduct['Sort Value']
      )).length > 0;

      // eslint-disable-next-line no-console
      console.log(JSON.stringify({ index, isDuplicatePeriodeAndSlot, isDuplicateSequence }));

      if (index !== -1 && !isDuplicatePeriodeAndSlot && !isDuplicateSequence) {
        newTableData[index]['Flash Sale Price By'] = item['Flash Sale Price By'];
        newTableData[index]['Flash Sale Price Value'] = item['Flash Sale Price Value'];
        newTableData[index]['Jumlah Stock Flash Sale'] = item['Jumlah Stock Flash Sale'];
        newTableData[index]['Sort By'] = item['Sort By'];
        newTableData[index]['Sort Value'] = item['Sort Value'];
        newTableData[index]['Periode'] = item['Periode'];
        newTableData[index]['Slot'] = item['Slot'];
      }
    }

    setProducts(newTableData);
  }, [
    formEditFlashSale.valid_from,
    formEditFlashSale.valid_to,
    formEditFlashSale.multiple,
    formEditFlashSale.periode.code,
    flashsaleEditTable
  ]);

  // click + add more
  const addMultipleDate = useCallback(() => {
    formEditFlashSale.multiple.push('');
    setFormEditFlashSale((prevState) => {
      return {
        ...prevState,
        multiple: [...formEditFlashSale.multiple]
      };
    });
  }, [formEditFlashSale.multiple, setFormEditFlashSale]);

  // onchange on calendar
  const addToMultipleDate = useCallback(
    (index, value) => {
      if (formEditFlashSale.multiple.includes(dayjs(value).format('YYYY-MM-DD'))) {
        showToastEditFlashSaleFailed('Multiple date is not allowed with same date');
      } else {
        formEditFlashSale.multiple.map(
          () => (formEditFlashSale.multiple[index] = dayjs(value).format('YYYY-MM-DD'))
        );
        setFormEditFlashSale((prevState) => {
          return {
            ...prevState,
            multiple: [...formEditFlashSale.multiple]
          };
        });
      }
    },
    [formEditFlashSale.multiple, setFormEditFlashSale]
  );

  // delete one of calendar field
  const deleteMultipleDate = useCallback(
    (index: number) => () => {
      formEditFlashSale.multiple.splice(index, 1);
      setFormEditFlashSale((prevState) => {
        return {
          ...prevState,
          multiple: [...formEditFlashSale.multiple]
        };
      });
    },
    [formEditFlashSale.multiple, setFormEditFlashSale]
  );

  useEffect(() => {
    getSlot();
  }, []);

  const onSaveFlashSale = async () => {
    setIsLoading(true);
    const tempTimeRange = timeRange?.find((f) => f.id === formEditFlashSale.master_slot_id.code);
    const today = dayjs().format('YYYY-MM-DD');
    const parentId = params.id;

    const activeFlashSale = await supabase
      .from('catalogue.flash_sales')
      .select('*, product_variants(*, products(*, categories(name), merchants(*)))')
      .eq('parent_id', parentId)
      .eq('time_slot->start_time', `"${tempTimeRange?.time[0].started_time}"`)
      .eq('time_slot->ended_time', `"${tempTimeRange?.time[0].ended_time}"`)
      .eq('periode->>date', JSON.stringify([today]));

    const editedProducts = productUpload;

    // TODO: backup just in case
    // const { data } = await supabase.rpc('catalogue.get_active_parent_flash_sales');
    // if (data && data.length > 0) {
    //   const fs = data.filter((item)=> item.deleted_at === null);
    //   const running = fs[0].id === params.id;
    //   if (running) {
    //     return showToastEditFlashSaleFailed('Flash Sale Masih Berjalan');
    //   }
    // }

    if (formEditFlashSale.periode.code === 'multiple') {
      const metadata: IPeriode = {
        date: formEditFlashSale.multiple,
        isRange: false,
        isExpired: dayjs(formEditFlashSale.multiple[formEditFlashSale.multiple.length-1]).local().format('YYYY-MM-DD') < dayjs().local().format('YYYY-MM-DD'),
        isEnable: Boolean(formEditFlashSale.status.code)
      };
      // TODO: backup just in case
      // if (!metadata.isExpired && formEditFlashSale.isEnable) {
      //   return showToastEditFlashSaleFailed('Flash Sale Masih Berjalan');
      // }

      const { error: errUpdateHeaderFS } = await supabase.from(TABLE.FLASHSALE).update([{
        name: formEditFlashSale.name,
        description: formEditFlashSale.description,
        master_slot_id: formEditFlashSale.master_slot_id.code,
        periode: metadata,
        updated_by: user?.email
      }]).eq('id', params.id);

      if (errUpdateHeaderFS) {
        return showToastEditFlashSaleFailed('Failed to Update Flash Sale');
      }
      const { error: errUpdateDetailFS } = await supabase.from(TABLE.FLASHSALE).update([{
        master_slot_id: formEditFlashSale.master_slot_id.code,
        periode: metadata
      }]).eq('parent_id', params.id);

      if (errUpdateDetailFS) {
        return showToastEditFlashSaleFailed('Failed to Update Flash Sale Detail');
      }

      editedProducts.forEach(async (item) => {
        const tempTimeSlot = tempTimeRange ?
          [tempTimeRange].flatMap((m) => m.time.filter((t) => t.index === Number(item.Slot))) :
          null;

        const timeSlot = Array.isArray(tempTimeSlot) && tempTimeSlot[0]?.started_time && tempTimeSlot[0]?.ended_time ? {
          start_time: tempTimeSlot[0]?.started_time,
          ended_time: tempTimeSlot[0]?.ended_time
        } : null;

        const metadataProduct: IPeriode = {
          date: [String(dayjs(item.Periode).format('YYYY-MM-DD'))],
          isRange: false,
          isExpired: false,
          isEnable: Boolean(formEditFlashSale.status.code) && item['Status']!
        };

        const productIndex = flashsaleEditTable.findIndex((tableData) => tableData.id === item.id);
        const isNewProduct = productIndex === -1;

        const activeFlashSaleData = activeFlashSale.data && activeFlashSale.data.length > 0 ?
          activeFlashSale.data :
          null;

        const activeProduct = activeFlashSaleData ?
          activeFlashSaleData.find((fsData) => fsData.id === item.id) :
          null;

        let saleableStock = Number(item['Jumlah Stock Flash Sale']);

        const currentSaleableStock = activeProduct?.saleable_stock ?? 0;
        const currentTotalStock = activeProduct?.total_stock ?? 0;
        const difference = Number(item['Jumlah Stock Flash Sale']) - Number(currentTotalStock ?? 0);
        saleableStock = Number(currentSaleableStock) + difference;

        const result = {
          product_variant_id: item['Product Variant ID'],
          product_id: item['Product ID'],
          master_slot_id: formEditFlashSale.master_slot_id.code,
          periode: metadataProduct,
          saleable_stock: saleableStock,
          price_by: FLASH_SALE_PRICE_BY[item['Flash Sale Price By']],
          price_value: Number(item['Flash Sale Price Value']),
          total_stock: Number(item['Jumlah Stock Flash Sale']),
          parent_id: parentId,
          sort_value: isNaN(Number(item['Sort Value'])) ? FLASH_SALE_SORT_VALUE[item['Sort Value']] : item['Sort Value'],
          sort_by: FLASH_SALE_SORT_BY[item['Sort By']],
          updated_by: user?.email,
          time_slot: timeSlot
        };

        if (isNewProduct) {
          const query = supabase.from(TABLE.FLASHSALE).insert(result).eq('parent_id', parentId);
          await query;
        } else {
          const isDataNotChanged = JSON.stringify(flashsaleEditTable[productIndex]) === JSON.stringify(item);
          if (!isDataNotChanged) {
            const query = supabase.from(TABLE.FLASHSALE).update(result).eq('parent_id', parentId).eq('id', item.id);
            await query;
          }
        }
      });

      deletedProducts.forEach(async (item) => {
        const { data: dataDeletedProduct } = await supabase.from(TABLE.FLASHSALE)
          .select('saleable_stock, product_variant_id')
          .eq('parent_id', parentId)
          .eq('id', item.id)
          .single();

        const currentSaleableStock = dataDeletedProduct?.saleable_stock;

        if (currentSaleableStock > 0) {
          const { data: dataDetailDeletedProduct } = await supabase.from(TABLE.PRODUCT_VARIANTS)
            .select('*')
            .eq('id', dataDeletedProduct?.product_variant_id)
            .single();
          const finalSaleableStock = dataDetailDeletedProduct.saleable_stock + currentSaleableStock;

          await supabase.from(TABLE.PRODUCT_VARIANTS)
            .update({ saleable_stock: finalSaleableStock })
            .eq('id', dataDeletedProduct?.product_variant_id);
        }

        await supabase.from(TABLE.FLASHSALE).delete().eq('parent_id', parentId).eq('id', item.id);
      });

      showToastEditFlashSaleSuccesfully('Update Flash Sale Succesfully');
      setTimeout(() => {
        navigate('/flash-sale');
      }, 2000);
      setIsLoading(false);
    } else if (formEditFlashSale.periode.code === 'range') {
      const metadata: IPeriode = {
        date: [String(formEditFlashSale.valid_from), String(formEditFlashSale.valid_to)],
        isRange: true,
        isExpired: dayjs(String(formEditFlashSale.valid_to)).local().format('YYYY-MM-DD') < dayjs().local().format('YYYY-MM-DD'),
        isEnable: Boolean(formEditFlashSale.status.code)
      };
      // TODO: backup just in case
      // if (!metadata.isExpired && formEditFlashSale.isEnable) {
      //   return showToastEditFlashSaleFailed('Flash Sale Masih Berjalan');
      // }
      const { error: errUpdateHeaderFS } = await supabase.from(TABLE.FLASHSALE).update([{
        name: formEditFlashSale.name,
        description: formEditFlashSale.description,
        master_slot_id: formEditFlashSale.master_slot_id.code,
        periode: metadata,
        updated_by: user?.email
      }]).eq('id', params.id);

      if (errUpdateHeaderFS) {
        return showToastEditFlashSaleFailed('Failed to Update Flash Sale');
      }
      const { error: errUpdateDetailFS } = await supabase.from(TABLE.FLASHSALE).update([{
        master_slot_id: formEditFlashSale.master_slot_id.code,
        periode: metadata
      }]).eq('parent_id', params.id);

      if (errUpdateDetailFS) {
        return showToastEditFlashSaleFailed('Failed to Update Flash Sale Detail');
      }

      editedProducts.forEach(async (item) => {
        const tempTimeSlot = tempTimeRange ?
          [tempTimeRange].flatMap((m) => m.time.filter((t) => t.index === Number(item.Slot))) :
          null;

        const timeSlot = Array.isArray(tempTimeSlot) && tempTimeSlot[0]?.started_time && tempTimeSlot[0]?.ended_time ? {
          start_time: tempTimeSlot[0]?.started_time,
          ended_time: tempTimeSlot[0]?.ended_time
        } : null;

        const metadataProduct: IPeriode = {
          date: [String(dayjs(item.Periode).format('YYYY-MM-DD'))],
          isRange: false,
          isExpired: false,
          isEnable: Boolean(formEditFlashSale.status.code) && item['Status']!
        };

        const productIndex = flashsaleEditTable.findIndex((tableData) => tableData.id === item.id);
        const isNewProduct = productIndex === -1;

        const activeFlashSaleData = activeFlashSale.data && activeFlashSale.data.length > 0 ?
          activeFlashSale.data :
          null;

        const activeProduct = activeFlashSaleData ?
          activeFlashSaleData.find((fsData) => fsData.id === item.id) :
          null;

        let saleableStock = Number(item['Jumlah Stock Flash Sale']);

        const currentSaleableStock = activeProduct?.saleable_stock ?? 0;
        const currentTotalStock = activeProduct?.total_stock ?? 0;
        const difference = Number(item['Jumlah Stock Flash Sale']) - Number(currentTotalStock ?? 0);
        saleableStock = Number(currentSaleableStock) + difference;

        const result = {
          product_variant_id: item['Product Variant ID'],
          product_id: item['Product ID'],
          master_slot_id: formEditFlashSale.master_slot_id.code,
          periode: metadataProduct,
          saleable_stock: saleableStock,
          price_by: FLASH_SALE_PRICE_BY[item['Flash Sale Price By']],
          price_value: Number(item['Flash Sale Price Value']),
          total_stock: Number(item['Jumlah Stock Flash Sale']),
          parent_id: parentId,
          sort_value: isNaN(Number(item['Sort Value'])) ? FLASH_SALE_SORT_VALUE[item['Sort Value']] : item['Sort Value'],
          sort_by: FLASH_SALE_SORT_BY[item['Sort By']],
          updated_by: user?.email,
          time_slot: timeSlot
        };

        if (isNewProduct) {
          const query = supabase.from(TABLE.FLASHSALE).insert(result).eq('parent_id', parentId);
          await query;
        } else {
          const isDataNotChanged = JSON.stringify(flashsaleEditTable[productIndex]) === JSON.stringify(item);
          if (!isDataNotChanged) {
            const query = supabase.from(TABLE.FLASHSALE).update(result).eq('parent_id', parentId).eq('id', item.id);
            await query;
          }
        }
      });

      deletedProducts.forEach(async (item) => {
        const { data: dataDeletedProduct } = await supabase.from(TABLE.FLASHSALE)
          .select('saleable_stock, product_variant_id')
          .eq('parent_id', parentId)
          .eq('id', item.id)
          .single();

        const currentSaleableStock = dataDeletedProduct?.saleable_stock;

        if (currentSaleableStock > 0) {
          const { data: dataDetailDeletedProduct } = await supabase.from(TABLE.PRODUCT_VARIANTS)
            .select('*')
            .eq('id', dataDeletedProduct?.product_variant_id)
            .single();
          const finalSaleableStock = dataDetailDeletedProduct.saleable_stock + currentSaleableStock;

          await supabase.from(TABLE.PRODUCT_VARIANTS)
            .update({ saleable_stock: finalSaleableStock })
            .eq('id', dataDeletedProduct?.product_variant_id);
        }

        await supabase.from(TABLE.FLASHSALE).delete().eq('parent_id', parentId).eq('id', item.id);
      });

      const payloadImportHistorySuccess = history.length === 0 && {
        account_id: user?.id || '',
        file_path: fileName || '',
        row_number: fileLength,
        status: true,
        type: 'bulk_edit_flash_sale'
      };

      const payloadImportHistoryError = history.length > 0 && {
        account_id: user?.id || '',
        file_path: fileName || '',
        errors: history,
        row_number: history.length,
        status: false,
        type: 'bulk_edit_flash_sale'
      };
      const payloadImportHistory = [payloadImportHistorySuccess, payloadImportHistoryError].filter((item) => typeof item !== 'boolean');

      await insertImportHistory(payloadImportHistory as IHistory[]);

      showToastEditFlashSaleSuccesfully('Update Flash Sale Succesfully');
      setTimeout(() => {
        navigate('/flash-sale');
      }, 2000);
      setIsLoading(false);
    }
  };

  const handleClickDropdownSlot = useCallback(()=>{
    setIsOpenDropdownSlot(!isOpenDropdownSlot);
  }, [isOpenDropdownSlot]);

  const handleMouseEnter = useCallback((name:string)=>(event:MouseEvent<HTMLOptionElement>)=>{
    setSlotName(name);
    const times = slot?.find((item) => item.id === event.currentTarget.value)?.time;
    setTime(times as ITime[]);
  }, [slot]);

  const handleClickDropdownType = useCallback((name:string, code:string)=>()=>{
    formik.setFieldValue('slot_type', code);
    setFormEditFlashSale((prev)=>({
      ...prev,
      master_slot_id: { code: code, name: name }
    }));
    setIsOpenDropdownSlot(false);
  }, []);

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const [activeMenu, setActiveMenu] = useState<number>(0);
  const [dataMenuFlashSale, setDataMenuFlashSale] = useState<string>('FLASH SALE DETAIL');

  // HANDLE MENU SIDEBAR FLASHSALE
  const handleMenu = useCallback(
    (menu: string) => {
      setDataMenuFlashSale(menu);
    },
    [setDataMenuFlashSale, dataMenuFlashSale]
  );

  // HANDLE ACTIVE MENU
  const handleActiveMenu = (id: number, label: string) => () => {
    handleMenu(toUpper(label));
    setActiveMenu(id);
  };

  // CHANGE COLOR ACTIVE MENU
  const handleActiveColorMenu = useCallback(
    (idx: number): string => {
      return idx === activeMenu ? 'bg-gray-100' : 'bg-gray-300';
    },
    [activeMenu]
  );

  return {
    data: {
      formEditFlashSale,
      optionSlot,
      isLoading,
      time,
      params,
      slotName,
      isOpenDropdownSlot,
      formik,
      toast,
      menuFlashSaleInformation,
      dataMenuFlashSale,
      flashsaleEditTable,
      products,
      slot,
      fileUploaded,
      fileUpdateUploaded
    },
    methods: {
      onChangeFormEditFlashSale,
      addMultipleDate,
      addToMultipleDate,
      deleteMultipleDate,
      onSaveFlashSale,
      handleClickDropdownType,
      handleMouseEnter,
      handleClickDropdownSlot,
      setProducts,
      isFormFieldValid,
      handleActiveColorMenu,
      handleActiveMenu,
      setDeletedProducts,
      setProductUpload,
      handleSelectFile,
      handleSelectFileUpdate
    }
  };
};
