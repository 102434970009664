import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import '../style.css';
import useDetailCustomer from './DetailHooks';
import CustomerInformation from './CustomerInformation/CustomerInformation';
import Shop from './Shop/Shop';
// import Feedback from './Feedback/Feedback';

const DetailCustomer = () => {
  const {
    data: { tabMenuActiveIndex, fieldAccountInfo },
    method: { setTabMenuActiveIndex } } = useDetailCustomer();

  const items: MenuItem[] = [
    { label: 'Customer Information' },
    { label: 'Shop' }
    // { label: 'Feedback' }
  ];
  return (
    <>
      <BaseContainer>
        <MainTitle title={`${fieldAccountInfo.first_name} ${fieldAccountInfo.last_name}`} isWithButton labelButton="Back" nav="/customer-management" severity="secondary" />
        <TabMenu id="customer-tab-menu-detail-customer" className="mb-5" model={items} activeIndex={tabMenuActiveIndex} onTabChange={(e) => setTabMenuActiveIndex(e.index)}/>
        {tabMenuActiveIndex === 0 && (
          <CustomerInformation/>
        )}
        {tabMenuActiveIndex === 1 && (
          <Shop/>
        )}
        {/* TODO: hidden for now */}
        {/* {tabMenuActiveIndex === 2 && (
          <Feedback/>
        )} */}
      </BaseContainer>
    </>
  );
};

export default DetailCustomer;

