import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FC, useCallback } from 'react';
import { FileUpload } from 'primereact/fileupload';

interface IOptions {
  id: number,
  name: string
}

type IPopUpFilter = {
  onClosePopUpFilter: () => void;
  onChangeParent: (e) => void;
  value: {} | null;
  options: IOptions[];
  disableOption: boolean;
}

const PopUpChangeAll: FC<IPopUpFilter> = ({ onClosePopUpFilter, value, options, onChangeParent, disableOption }) => {
  const handleClose = () => {
    onClosePopUpFilter();
  };
  const change = useCallback(
    (e) => {
      onChangeParent(e);
    }, [onChangeParent]
  );

  const exportTemplate = () => {
    import('xlsx').then((xlsx) => {
      const worksheet1 = xlsx.utils.aoa_to_sheet([['ID Product', 'Nama Product', 'SKU Product', 'ID Kategori Awal', 'ID Kategori Tujuan']]);
      const worksheet2 = xlsx.utils.aoa_to_sheet([['ID Kategori', 'Nama Kategori', 'Level Kategori']]);
      const pindahKategori = { Sheets: { 'Pindah Kategori': worksheet1, 'List Kategori': worksheet2 }, SheetNames: ['Pindah Kategori', 'List Kategori'] };
      const excelBuffer = xlsx.write(pindahKategori, {
        bookType: 'xlsx',
        type: 'array'
      });

      saveAsExcelFile(excelBuffer, 'template');
    });
  };
  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  };
  return (
    <div id="popUpChangeAllParentId" className="bg-black bg-opacity-30 w-screen h-screen fixed top-0 left-0 z-50 flex justify-center items-center">
      <div className="bg-white w-4/5 relative p-10 z-50 rounded-lg">
        <button type="button" className="absolute top-3 right-3" onClick={() => handleClose()}>batal</button>
        <h1 className="font-semibold">Pindah Kategori Sekaligus</h1>
        <p className="mt-14">Template hanya bisa diisi dengan MS. Excel 2007 ke atas atau Libre Office. <br />
          Jangan menambah kolom atau baris apa pun di file Excel.</p>
        <h1 className="my-5 font-semibold">Pilih Kategori Awal</h1>
        <div className="flex items-center w-full gap-20 ">
          <div>
            <Dropdown
              value={value}
              options={options}
              //   onChange={(e) => {
              //     methods.onChangeParent(e.target.value);
              //   }}
              onChange={(e) => {
                change(e);
              }}
              optionLabel="name"
              placeholder="Ganti Category Baru"
              className="w-full"
            />
          </div>
          <div>
            <Button onClick={exportTemplate} label="Download Template" />
          </div>
        </div>
        <h4 className="my-5 font-semibold">2.Upload File Excel</h4>
        <p>Pilih atau letakkan file Excel (.xlsx) kamu di sini, maks. 300 SKU per kategori.</p>
        <div className="card flex items-center gap-36 mt-5">
          <FileUpload name="demo[]" url="/api/upload" accept=".xlsx, xls" maxFileSize={1000000} emptyTemplate={<p className="m-0">Drag and drop to upload or click here</p>} />
          <div>
            <Button disabled={disableOption} label="Upload" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpChangeAll;
