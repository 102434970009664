import React from 'react';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import Table from '@/components/base/DataTable/DataTable';
import useCustom, { IUser } from './hooks';
import { ConfirmDialog } from 'primereact/confirmdialog';
import IconDot from '@/assets/IconDot';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { usePermissionStore } from '@/store/usePermissions';
import { IPagination } from '@/components/base/Pagination';
import { Calendar } from 'primereact/calendar';

const Admin = () => {
  const { data, method } = useCustom();
  const statusTemplate = (users: IUser) => {
    return (
      <div id={`admin-status-${users.id}`} className="flex gap-x-3 items-center">
        {users.status === 'Active' ? (
          <>
            <IconDot fill="#39ac76" />
            <span>Active</span>
          </>
        ) : (
          <>
            <IconDot fill="#ef4444" />
            <span>Inactive</span>
          </>
        )}
      </div>
    );
  };

  const headerTable = [
    { field: 'id', header: 'User ID' },
    { field: 'name', header: 'Admin Name' },
    { field: 'teams', header: 'User Group' },
    { field: 'type', header: 'Acc Type' },
    { field: 'created_at', header: 'Created Date' },
    { field: 'status', header: 'Status', body: statusTemplate }
  ];

  const headerCreate = (
    <div id="admin-create-header" className="font-bold">
      Create Admin User
    </div>
  );

  const footerCreate = (
    <div id="admin-create-footer" className="-mt-6 -mr-10">
      <SubmitButton
        onCancel={() => {
          data.formik.resetForm();
          method.resetForm();
          method.formInputValue('creating', false);
        }}
        onSubmit={() => {
          data.formik.handleSubmit();
        }}
        labelBack="Cancel"
        labelSubmit="Submit"
        loadingSubmit={data.isLoading}
      />
    </div>
  );

  const getFormErrorMessage = (name) => {
    return method.isFormFieldValid(name) && <small className="p-error">{data.formik.errors[name]}</small>;
  };

  // pagination
  const paginationOptions: IPagination = {
    perPage: data.perPage,
    totalPages: data.totalPages,
    currentPage: data.paginator.currentPage,
    disabledDropdown: data.totalRecords === 0,
    onChangeDropdownPage: method.handleChangeDropdownPage,
    onClickNext: method.handleClickNext,
    onClickPrev: method.handleClickPrev,
    onChangeJumptoPage: method.handleChangeJumpTopage,
    onJumptoPage: method.handleJumpToPage
  };

  const filterContent = (
    <div className="flex flex-col gap-4 w-[900px]">
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Admin Name</label>
          <InputText
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
            onChange={(e) => method.handleFilter(e, 'admin_name')}
            value={data.itemFilters.admin_name}
            name="admin_name"
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">User Group</label>
          <InputText
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
            onChange={(e) => method.handleFilter(e, 'user_group')}
            value={data.itemFilters.user_group}
            name="user_group"
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-status" className="font-bold">Created Date</label>
          <Calendar
            onChange={(e) => method.handleFilter(e, 'created_at')}
            value={data.itemFilters.created_at}
            name="created_at"
            className="w-full"
            selectionMode="range"
            showIcon
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-status" className="font-bold">Status</label>
          <Dropdown
            onChange={(e) => method.handleFilter(e, 'status')}
            value={data.itemFilters.status}
            name="status"
            placeholder="Pilih Status"
            options={[{ label: 'Active', value: 'Active' }, { label: 'Inactive', value: 'Inactive' }]}
            optionLabel="label"
            optionValue="value"
            className="w-full"
          />
        </div>
      </div>
    </div>
  );

  const { canEdit, canCreate, canView, canDelete } = usePermissionStore();
  return (
    <BaseContainer>
      <div id="admin-header" className="flex justify-between items-center">
        <MainTitle title="User Admin" />
        <Button
          id="admin-add-btn"
          label="Create Admin"
          className="h-12"
          onClick={() => method.formInputValue('creating', true)}
          outlined
          severity="danger"
          disabled={!canCreate}
        />
      </div>
      <ConfirmDialog />
      <div id="admin-list" className="card flex justify-content-center">
        <Dialog
          id="admin-create-form"
          header={headerCreate}
          visible={data.formInput.creating}
          style={{ width: '35vw' }}
          onHide={() => method.formInputValue('creating', false)}
          footer={footerCreate}
        >
          <div className="py-2">
            <label className="pb-2 text-sm font-medium">Name*</label>
            <InputText
              id="admin-name-input"
              required
              name="adminName"
              value={data.formInput.name}
              className="w-full"
              onChange={(e) => {
                data.formik.setFieldValue('adminName', e.target.value);
                method.formInputValue('name', e.target.value);
              }}
            />
          </div>
          <div className="pt-1 pl-1">{getFormErrorMessage('adminName')}</div>
          <div className="py-2">
            <label className="py-2 text-sm font-medium">Email*</label>
            <InputText
              name="adminEmail"
              required
              className="w-full"
              id="admin-email-input"
              value={data.formInput.email}
              onChange={(e) => {
                data.formik.setFieldValue('adminEmail', e.target.value);
                method.formInputValue('email', e.target.value);
              }}
            />
          </div>
          <div className="pt-1 pl-1">{getFormErrorMessage('adminEmail')}</div>
          <div className="py-2">
            <label className="py-2 text-sm font-medium">Password*</label>
            <InputText
              name="adminPassword"
              required
              className="w-full"
              id="admin-password-input"
              value={data.formInput.password}
              onChange={(e) => {
                data.formik.setFieldValue('adminPassword', e.target.value);
                method.formInputValue('password', e.target.value);
              }}
            />
          </div>
          <div className="pt-1 pl-1">{getFormErrorMessage('adminPassword')}</div>
          <div className="py-2">
            <label className="my-2 text-sm font-medium">Group*</label>
            <Dropdown
              name="adminGroup"
              options={data.teams}
              optionLabel="name"
              optionValue="id"
              className="w-full"
              id="admin-group-input"
              value={data.formInput.team_id}
              onChange={(e: DropdownChangeEvent) => {
                data.formik.setFieldValue('adminGroup', e.target.value);
                method.formInputValue('team_id', e.value);
              }}
            />
          </div>
          <div className="pt-1 pl-1">{getFormErrorMessage('adminGroup')}</div>
          <div className="py-2">
            <label className="my-2 text-xs font-medium">Account Type*</label>
            <Dropdown
              name="adminType"
              options={data.accountTypeOptions}
              optionLabel="label"
              optionValue="code"
              className="w-full"
              id="admin-type-input"
              value={data.formInput.type}
              onChange={(e: DropdownChangeEvent) => {
                data.formik.setFieldValue('adminType', e.target.value);
                method.formInputValue('type', e.value);
              }}
            />
          </div>
          <div className="pt-1 pl-1">{getFormErrorMessage('adminType')}</div>
        </Dialog>
      </div>
      <Toast ref={data.toast} />
      <Table
        filter={data.search}
        headerTable={headerTable}
        rows={data.totalRecords}
        onDeleted={method.confirmDelete}
        value={data.users as []}
        isEditAction={false}
        isLoadingTable={data.isLoadingUser}
        paginationOptions={paginationOptions}
        onSort={method.handleChangeSort}
        onSearch={method.handleSearch}
        isDeleteAction={canDelete}
        isDetailAction={canView || canEdit}
        filterContent={filterContent}
        filterHistory={data.filterHistory}
        handleClearFilter={method.handleClearFilter}
        handleDeleteFilterHistory={method.handleDeleteFilterHistory}
        onSubmitFilter={method.handleClickSubmitFilter}
      />
    </BaseContainer>
  );
};

export default Admin;
