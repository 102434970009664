import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { IPagination, ISort } from '@/types/pagination';
import { Database } from '@/types/services/user';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

export interface IFilterAdmin {
  admin_name: string;
  user_group: string;
  created_at: Date[];
  status: string;
}

type User = Database['public']['Tables']['accounts']['Row']
type Members = Database['public']['Tables']['members']['Row']
type Teams = Database['public']['Tables']['teams']['Row']
interface IMembers extends Members {
    teams:Teams
}
interface IUser extends User {
    members:IMembers
    user_metadata: UserMetadata
}
interface UserMetadata {
  application_id: string;
  first_name: string;
  last_name: string;
  merchant_id: string;
  team_id: string;
  type: string;
  username: string;
}

const getAllUserAdmin =async (range:IPagination, sort:ISort, filter: IFilterAdmin, search: string) => {
  const query = supabase
    .from(TABLE.ACCOUNTS)
    .select('*,members!inner(teams!inner(application_id,name))', { count: 'exact' })
    .eq('members.teams.application_id', 1)
    .range(range.start, range.end);

  if (sort.field === 'teams') {
    query.order('name', { foreignTable: 'members.teams', ascending: sort.asc });
  } else {
    query.order(sort.field, { ascending: sort.asc });
  }

  // Status
  if (filter.status != '') {
    query.eq('status', (filter.status.toLowerCase() === 'active'));
  }

  // Group
  if (filter.user_group != '') {
    query.ilike('members.teams.name', `%${filter.user_group}%`);
  }

  // Admin Name
  if (filter.admin_name != '' || search != '') {
    query.or(`first_name.ilike.%${filter.admin_name || search}%,last_name.ilike.${filter.admin_name || search}%`);
  }

  // Created At
  if (filter.created_at && filter.created_at?.length != 0) {
    const fromDate = dayjs(String(filter.created_at[0])).format('YYYY-MM-DD');
    query.gte('created_at:date', fromDate);

    if (filter.created_at.length > 1 && filter.created_at[1] != null) {
      const toDate = dayjs(String(filter.created_at[1])).format('YYYY-MM-DD');
      query.lte('created_at:date', toDate);
    }
  }

  const result = await query as PostgrestSingleResponse<IUser[]>;
  if (result.data && result.data.length > 0) {
    result.data = await getUserAdmin(result.data);
  }
  return result;
};

const getUserAdmin =async (users: IUser[]) => {
  const userIds = users.map((u)=> u.id);
  for (let i = 0; i < userIds.length; i++) {
    const response = await fetch(`${import.meta.env.VITE_APP_SUPABASE_URL}/auth/v1/admin/users/${userIds[i]}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'ApiKey': import.meta.env.VITE_APP_SUPABASE_KEY,
        'Authorization':
          'Bearer ' +
          (await supabase.auth.getSession()).data.session?.access_token
      }
    });

    const resJson = await response.json();
    users[i].user_metadata = resJson.user_metadata;
  };
  return users;
};


const getTotalUserAdmin =async () => {
  const query = supabase
    .from(TABLE.ACCOUNTS)
    .select('id,members!inner(teams!inner(application_id))')
    .eq('members.teams.application_id', 1).range(0, 50);
  return await query;
};

export const useAllUserAdmin = (range:IPagination, sort:ISort, filter: IFilterAdmin, search: string) =>
  useQuery({
    queryKey: ['all-user-admin', filter, range, sort, search],
    queryFn: ()=> getAllUserAdmin(range, sort, filter, search),
    refetchOnWindowFocus: false
  });

export const useTotalUserAdmin = () =>
  useQuery({
    queryKey: ['total-user'],
    queryFn: getTotalUserAdmin,
    refetchOnWindowFocus: false
  });
