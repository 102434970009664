import { useState } from 'react';

const useCustomerInfo = () => {
  const [tabMenuActiveIndex, setTabMenuActiveIndex] = useState(0);
  const [sideMenuActiveIndex, setSideMenuActiveIndex] = useState(0);

  return {
    data: {
      tabMenuActiveIndex,
      sideMenuActiveIndex },
    method: {
      setTabMenuActiveIndex,
      setSideMenuActiveIndex
    }
  };
};

export default useCustomerInfo;

