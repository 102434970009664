import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { useCustom } from './hooks';
import { TabPanel, TabView } from 'primereact/tabview';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import ProductTab from '../Components/ProductTab';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { usePermissionStore } from '@/store/usePermissions';

const DetailCategory = () => {
  const { data, methods } = useCustom();
  const navigate = useNavigate();
  const params = useParams();
  const { canEdit } = usePermissionStore();
  return (
    <BaseContainer>
      <TabView id="tab-category">
        <TabPanel header="General">
          <div>
            <MainTitle title="Detail Category" nav="" />
            <div id="form_group">
              <div id="parent_input" className="mb-3 flex gap-8">
                <label htmlFor="parent" className="font-bold w-1/6 mt-2">
                  Parent Category*
                </label>
                <div id="value_parent" className="w-1/2">
                  <Dropdown
                    value={data.selectedDataParentID}
                    optionLabel="name" filter emptyFilterMessage="Parent Not Found"
                    placeholder={data.selectedDataParentID?.name}
                    className="w-full" disabled
                  />
                  <div className="flex items-center gap-2 py-2">
                    <Checkbox
                      disabled
                      checked={data.selectedDataParentID === undefined}
                    />
                    <label className="cursor-pointer" htmlFor="not-have-parent">
                        No Parent
                    </label>
                  </div>
                </div>
              </div>
              <div id="name_input" className="mb-3 flex gap-8 items-center">
                <label htmlFor="name" className="w-1/6 font-bold">
                  Category Name*
                </label>
                <InputText
                  id="name" disabled
                  className="w-1/2"
                  placeholder="Category name"
                  value={data.detailCategory.name}
                />
              </div>
              <div id="status_input" className="mb-3 flex gap-8 items-center">
                <label htmlFor="isActive" className="w-1/6 font-bold">
                  Status
                </label>

                {/* is active */}
                <InputSwitch
                  disabled
                  id="isActive"
                  className="w-1/2"
                  checked={data.detailCategory.status}
                />
              </div>
              <div id="description_input" className="mb-3 flex gap-8 items-center">
                <label htmlFor="description" className="w-1/6 font-bold">
                  Category Description
                </label>
                <InputTextarea
                  autoResize disabled
                  className="w-1/2"
                  id="description"
                  placeholder="Description"
                  value={data.detailCategory.description}
                />
              </div>
              <div id="image_input" className="mb-3 flex gap-8 items-center">
                <label htmlFor="media_url" className="w-1/6 font-bold">
                  Category Icon
                </label>
                {data.detailCategory.images && (
                  <div>
                    <img src={data.detailCategory.images.data.image} width={200} alt="" />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end py-2 px-6 ">
            {data.seoPage ? <MinusIcon onClick={() => methods.setSeoPage(!data.seoPage)} className="w-10 cursor-pointer" /> : <PlusIcon onClick={() => methods.setSeoPage(!data.seoPage)} className="w-10 cursor-pointer" />}
          </div>
          {data.seoPage && (
            <div className="mt-2 flex flex-col gap-y-6">
              <h3 className="font-bold">Search Engine Optimization</h3>
              <hr />
              <p>Recommended icon images no larger than 50KB, 1:1 ratio, file formats: JPG, GIF, SVG, PNG</p>
              <div className="flex gap-x-4">
                <div className="grid grid-cols-2 w-2/3 gap-y-5">
                  <div>
                    <label className="font-bold" htmlFor="">URL Key*</label>
                  </div>
                  <InputText value={data.detailCategory.slug ? data.detailCategory.slug : data.detailCategory.name.toLowerCase().replace(/\s/g, '-')} disabled/>
                  <div>
                    <label className="font-bold" htmlFor="">Meta Title*</label>
                  </div>
                  <InputText value={data.detailCategory.metaTitle} disabled name="meta-title" />
                  <div>
                    <label className="font-bold" htmlFor="">Meta Keywords</label>
                  </div>
                  <InputTextarea value={data.detailCategory.metaKeyword || undefined} disabled
                    onChange={(e) => methods.setMetaKeywords(e.target.value)} />
                  <div>
                    <label className="font-bold" htmlFor="">Meta Description</label>
                  </div>
                  <InputTextarea value={data.detailCategory.metaDescription || undefined} disabled
                    onChange={(e) => methods.setMetaDescription(e.target.value)} />
                </div>
                <div>
                  <Checkbox checked={data.detailCategory.slug ? !data.isFollowCategoryName : data.isFollowCategoryName} disabled
                    onChange={() => methods.setIsFollowCategoryName(!data.isFollowCategoryName)} /> <span>Follow Category Name</span>
                </div>
              </div>
            </div>
          )}

        </TabPanel>
        {/* Tab Product */}
        <TabPanel header={`Product(${data.isLoadingProduct ? 0 : data.detailCategory.count})`}>
          <div>
            <MainTitle title="Product" />
            <ProductTab
              value={data.dataAllProduct}
              isLoading={data.isLoadingProduct}
            />
          </div>
        </TabPanel>

        <TabPanel header="Attribute Set">
          <div>
            <div id="container-attribute-set" className="flex gap-2 mb-4 mt-4 justify-center items-center">
              <span className="w-[220px]">Add Attribute Set From: </span>
              <Dropdown
                id="dropdown-terapkan"
                optionLabel="name" filter emptyFilterMessage="Atribute Set Not Found"
                placeholder="Select Attribute Set"
                className="w-3/6" disabled
              />
              <div className="flex justify-center items-center w-1/6">
                <Button
                  id="button-terapkan"
                  className="w-full"
                  label="Apply" disabled
                  style={{ textAlign: 'center' }}
                />
              </div>
            </div>
            <hr />
            <div >
              <div id="container-specification" className="mb-4 mt-4">
                <span>1. Specification</span>
                <div className="flex flex-col gap-y-2 ml-2 justify-start">
                  <span>Specifications from the Parent Category will be included in this category and cannot be changed</span>
                  {Array.isArray(data.parentCategorySpec) && (data.parentCategorySpec.map((item, index) => {
                    return (
                      <div className="flex gap-5" key={index}>
                        <Dropdown
                          id={`dropdown-parent-spec-${index}`}
                          optionLabel="name" filter emptyFilterMessage="Specification Not Found"
                          optionValue="id" placeholder="Select specification"
                          className="w-1/4" disabled
                          value={item?.id}
                          options={data.parentCategorySpec}
                        />
                      </div>
                    );
                  }))}
                  {Array.isArray(data.rangeSpecification) && (data.rangeSpecification.map((item, index) => {
                    return (
                      <div className="flex gap-5" key={index}>
                        <Dropdown
                          id={`dropdown-specification-${index}`}
                          optionLabel="name" filter emptyFilterMessage="Specification Not Found"
                          optionValue="id" placeholder="Select specification"
                          className="w-1/4" disabled
                          value={item?.id}
                          options={data.rangeSpecification}
                        />
                        <Button icon="pi pi-trash" id={`button-hapus-specification-${index}`} disabled/>
                      </div>
                    );
                  }))}
                  <div className="flex justify-start">
                    <Button id="button-tambah-specification" disabled
                      label="+ Add Specification" link />
                  </div>
                </div>
              </div>
              <hr />
              <div id="container-varian" className="mb-4 mt-4">
                <span>2. Varian</span>
                <div className="flex flex-col gap-y-2 ml-2 justify-start">
                  <span>Variants from the Parent Category will be included in this category and cannot be changed</span>
                  {Array.isArray(data.parentCategoryVars) && (data.parentCategoryVars.map((item, index) => {
                    return (
                      <div className="flex gap-5" key={index}>
                        <Dropdown
                          id={`dropdown-variant-${index}`}
                          optionLabel="name" filter emptyFilterMessage="Varian Not Found"
                          optionValue="id" placeholder="Select varian"
                          className="w-1/4" disabled
                          options={data.parentCategoryVars}
                          value={item?.id}
                        />
                      </div>
                    );
                  }))}
                  {Array.isArray(data.rangeVariant) && (data.rangeVariant.map((item, index) => {
                    return (
                      <div className="flex gap-5" key={index}>
                        <Dropdown
                          id={`dropdown-variant-${index}`}
                          optionLabel="name" filter emptyFilterMessage="Varian Not Found"
                          optionValue="id" placeholder="Select varian"
                          className="w-1/4" disabled
                          options={data.rangeVariant}
                          value={item?.id}
                        />
                        <Button icon="pi pi-trash" id={`button-hapus-variant-${index}`} disabled/>
                      </div>
                    );
                  }))}
                  <div className="flex justify-start">
                    <Button id="button-tambah-variant" disabled
                      label="+ Add Varian" link />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
      <div id="buttons-action" className="w-full flex justify-end gap-6">
        <SubmitButton
          onCancel={() => {
            navigate('/category');
          }}
          onSubmit={() => {
            navigate(`/category/edit/${params.detailId}`);
          }}
          labelBack="Close"
          labelSubmit="Edit"
          disabledSubmit={!canEdit}
        />
      </div>
    </BaseContainer>
  );
};

export default DetailCategory;
