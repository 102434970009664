import React from 'react';
import useCustom from './hooks';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect } from 'primereact/multiselect';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { usePermissionStore } from '@/store/usePermissions';

const DetailOngkir = () => {
  const { data, method } = useCustom();
  const navigate = useNavigate();
  const { canEdit } = usePermissionStore();
  return (
    <BaseContainer>
      <MainTitle title="Detail Promo Ongkir" nav="" />
      <div id="form group">
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="input-promo-name" className="w-1/6">
            Postage Promo Name*
          </label>
          <div className="card w-1/3">
            <InputText
              required id="input-promo-name"
              className="w-full" placeholder="Postage Promo Name"
              value={data.firstForm.name} disabled
              onChange={(e) => method.firstFormValue('name', e.target.value)}
            />
          </div>
        </div>
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="hipping_provider_selection" className="w-1/6">
            Choose Shipping Provider
          </label>
          <div className="card flex justify-content-center w-1/3">
            <MultiSelect value={data.firstForm.provider} onChange={(e) => method.firstFormValue('provider', e.value)} options={data.providers} optionLabel="name"
              placeholder="Select Shipping Providers" maxSelectedLabels={3} disabled className="w-full md:w-20rem" id="shipping_provider_selection" optionValue="id"/>
          </div>
        </div>
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="shipping_method_selection" className="w-1/6">
            Choose Shipping Method
          </label>
          <div className="card flex justify-content-center w-1/3">
            <MultiSelect
              optionValue="id"
              value={data.firstForm.methods}
              onChange={(e) => method.firstFormValue('methods', e.value)}
              options={data.shipMethod}
              optionLabel="name"
              placeholder="Select Shipping Method"
              maxSelectedLabels={3}
              disabled className="w-full md:w-20rem"
              id="shipping_method_selection" />
          </div>
        </div>
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="select_platform" className="w-1/6">
            Platform*
          </label>
          <div className="card w-1/3">
            <Checkbox
              checked={data.firstForm.isAllPlatform}
            />
            <label className="ml-5">All Platform</label>
          </div>
        </div>
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="select_customer" className="w-1/6">
            Customer Group*
          </label>
          <div className="card w-1/3 flex">
            <Checkbox
              checked={data.firstForm.isRegisteredCustomer}
            />
            <label className="ml-5">Registered Customer</label>
          </div>
        </div>
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="start_date" className="w-1/6">
            Valid From*
          </label>
          <div className="card flex justify-content-center w-1/3">
            <Calendar value={(data.firstForm.startDate)} showTime hourFormat="24"
              dateFormat="dd/mm/yy" id="start_date" hideOnDateTimeSelect
              onChange={(e : CalendarChangeEvent) => method.firstFormValue('startDate', e.value as string)}
              showIcon disabled maxDate={new Date(data.firstForm.endDate)} minDate={new Date()}
            />
          </div>
        </div>
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="end_date" className="w-1/6">
            Valid To*
          </label>
          <div className="card flex justify-content-center w-1/3">
            <Calendar value={(data.firstForm.endDate)} showTime hourFormat="24"
              dateFormat="dd/mm/yy" id="end_date" hideOnDateTimeSelect
              onChange={(e : CalendarChangeEvent) => method.firstFormValue('endDate', e.value as string)}
              showIcon disabled minDate={new Date(data.firstForm.startDate)}
            />
          </div>
        </div>
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="status" className="w-1/6">
            Status*
          </label>
          <div className="card flex justify-content-center w-1/3">
            <InputSwitch checked={data.firstForm.is_active} disabled onChange={(e) => method.firstFormValue('is_active', e.value as boolean)} id="status" />
          </div>
        </div>
        <div className="py-3 flex gap-8">
          <label htmlFor="input_description" className="w-1/6 mt-2">
            Promo Description*
          </label>
          <div className="card flex justify-content-center w-1/3">
            <InputTextarea value={data.firstForm.description} id="promo-description-input" rows={5} cols={40}
            />
          </div>
        </div>
        <div className="flex justify-between items-center">
          <label htmlFor="label" className="flex items-center font-bold mt-8 mb-3">
          Usage Quota
          </label>
          <Button type="button" icon={data.collapsible.quota? 'pi pi-minus' : 'pi pi-plus'} onClick={()=> method.onCollapse('quota', !data.collapsible.quota)} rounded text severity="secondary" />
        </div>
        <hr className="w-full font-bold text-solid bg-black h-1" />
        {data.collapsible.quota &&
        (<>
          <div className="py-3 flex gap-8 items-center">
            <label htmlFor="input_promo_quota" className="w-1/6">
            Promo Quota*
            </label>
            <div className="card w-1/3">
              <InputNumber disabled
                required id="input_promo_quota" className="w-full" placeholder="Input Kuota Promo"
                value={data.secondForm.quota} onChange={(e) => method.secondFormValue('quota', e.value as number)} />
            </div>
          </div>
          <div className="py-3 flex gap-8 items-center">
            <label htmlFor="input_user_quota" className="w-1/6">
              Usage per User*
            </label>
            <div className="card w-1/3">
              <InputNumber
                required id="input_user_quota" disabled
                className="w-full" placeholder="Input Penggunaan Per User"
                value={data.secondForm.is_user_quota_unlimited ? data.secondForm.user_quota = 0 : data.secondForm.user_quota}
                onChange={(e) => method.secondFormValue('user_quota', e.value as number)} />
            </div>
            <div className="flex ml-4">
              <Checkbox
                id="voucher-is-user-quota-unlimited-input"
                onChange={(e) => method.secondFormValue('is_user_quota_unlimited', e.value)}
                checked={data.secondForm.is_user_quota_unlimited || data.secondForm.user_quota == 0} />
              <label htmlFor="is-quota-unlimited" className="ml-2">
                No Usage Limit
              </label>
            </div>
          </div>
        </>
        )}
        <div className="flex justify-between mt-8 items-center">
          <label htmlFor="label" className="flex items-center font-bold">
              Discount and Condition
          </label>
          <Button type="button" icon={data.collapsible.condition? 'pi pi-minus' : 'pi pi-plus'} onClick={()=> method.onCollapse('condition', !data.collapsible.condition)} rounded text severity="secondary" />
        </div>
        <hr className="w-full font-bold text-solid bg-black h-1" />
        {data.collapsible.condition &&
        (<>
          <div className="flex gap-8 py-3 items-center">
            <label htmlFor="input_min_payment" className="w-1/6">
            Minimum Subtotal Order*
            </label>
            <div className="card w-1/3">
              <InputNumber
                required id="input_min_payment" disabled className="w-full"
                value={data.secondForm.is_no_minimum_subtotal ? data.secondForm.min_payment_amount = 0 : data.secondForm.min_payment_amount}
                onChange={(e) => method.secondFormValue('min_payment_amount', e.value as number)} />
            </div>
            <div className="flex ml-4">
              <Checkbox
                id="is-no-minimum-subtotal-input"
                onChange={(e) => method.secondFormValue('is_no_minimum_subtotal', e.value)}
                checked={data.secondForm.is_no_minimum_subtotal || (data.secondForm.min_payment_amount == 0)} />
              <label htmlFor="is-no-minimum-subtotal" className="ml-2">
              No Minimum Subtotal Order
              </label>
            </div>
          </div>
          <div className="flex items-center gap-8">
            <label htmlFor="discount_type" className="w-1/6">
              Discount Amount*
            </label>
            <div className="py-3 flex w-3/4 gap-4 items-center">
              <Dropdown
                required id="discount_type" className="w-1/3" disabled
                value={data.secondForm.type} options={data.type} optionLabel="name" showClear
                onChange={(e) => method.secondFormValue('type', e.value)} />
              {(data.secondForm.type === 'fixed' || data.secondForm.type === 'percentage') &&
                <div className="w-1/4">
                  <div id="amount" className="p-inputgroup">
                    <label htmlFor="amount" className="self-center mr-3">Amount</label>
                    <InputNumber value={data.secondForm.amount_discount} className="w-1/3" id="amount" disabled
                      onChange={(e) => method.secondFormValue('amount_discount', e.value as number)} />
                    {data.secondForm.type === 'percentage' &&
                      <span className="p-inputgroup-addon">%</span>}
                  </div>
                </div>}
              {data.secondForm.type === 'percentage' &&
                <div className="flex items-center gap-2">
                  <label htmlFor="max_amount">Max</label>
                  <InputNumber value={data.secondForm.max_discount_amount} id="max_amount" disabled
                    onChange={(e) => method.secondFormValue('max_discount_amount', e.value as number)} />
                </div>}
            </div>
          </div>
        </>
        )}
      </div>
      <div id="buttons-action" className="w-full flex justify-end gap-6">
        {/* <Toast position='center' ref={data.toast} /> */}
        <SubmitButton
          onCancel={() => {
            method.resetAllForm();
            navigate('/ongkir');
          }}
          onSubmit={() => {
            navigate(`/ongkir/edit/${data.id}`);
          }}
          labelBack="Cancel"
          labelSubmit="Edit"
          disabledSubmit={!canEdit}
        />
      </div>
    </BaseContainer>
  );
};

export default DetailOngkir;
