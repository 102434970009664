import React, { FC } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Badge } from 'primereact/badge';
import { ICategories, ICategory } from '@/services/rest/product';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

type Category = {
  category: ICategories[];
  selectedCategory: ICategory;
  selectedMerchant: string;
  selectedParent: { '1': ICategory, '2': ICategory, '3': ICategory };
  secondChild: [{ id: number, name: string, parent_id: number, child: ICategories[] }];
  lastChild: [{ id: number, name: string, parent_id: number, child: ICategories[] }];
};

const CategoryField: FC<Category> = ({ category, selectedCategory, selectedMerchant, selectedParent, secondChild, lastChild }) => {
  const styled = 'w-full text-red-500';
  return (
    <div id="form_kategori" className="py-3 px-4 mt-6">
      <div id="kategori" className="flex gap-x-20">
        <label className="flex w-32 p-overlay-badge" htmlFor="category">Pilih Kategori
          <Badge value="wajib" severity="danger" size="normal" />
        </label>
        <div className="flex flex-col ml-2">
          {category?.map((item)=> {
            return (
              <div key={item.id} className="flex flex-row mb-2 gap-2 items-center">
                <p className={item.name === selectedCategory?.name || selectedParent['1'].name === item.name ? styled : 'w-full'}>{item.name}</p>
                <div id="arrow-right-button" className="h-4 w-4">
                  <ChevronRightIcon className="h-4 w-4"/>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col ml-4">
          {selectedParent['2'].name != '' &&
            <div className="flex flex-row mb-2 gap-2 items-center">
              <p className="w-full font-semibold">{selectedParent['2'].name}</p>
              <div id="arrow-right-button" className="h-4 w-4">
                <ChevronDownIcon className="h-4 w-4"/>
              </div>
            </div>
          }
          { secondChild.map((item)=> {
            return (
              <div key={item.id} className="flex flex-row mb-2 gap-2 items-center">
                <p className={item.name === selectedCategory?.name || item.name === selectedParent['2'].name ? styled : 'w-full'}>{item.name}</p>
                <div id="arrow-right-button" className="h-4 w-4">
                  <ChevronRightIcon className="h-4 w-4"/>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col ml-4">
          {selectedParent['3'].name != '' &&
            <div className="flex flex-row mb-2 gap-2 items-center">
              <p className="w-full font-semibold">{selectedParent['3']?.name}</p>
              <div id="arrow-right-button" className="h-4 w-4">
                <ChevronDownIcon className="h-4 w-4"/>
              </div>
            </div>
          }
          { lastChild.map((item)=> {
            return (
              <div key={item.id} className="flex flex-row mb-2 gap-2 items-center">
                <p className={item.name === selectedCategory?.name ? styled : 'w-full'}>{item.name}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="border flex flex-row rounded-full border-slate-400 w-max text-sm text-center ml-52 inline-block py-3 px-2 my-5">
        <p>Anda memilih kategori: &nbsp;</p>
        {selectedCategory?.name ? (
          <p className="text-red-500">{selectedCategory?.name}</p>
        ) : (
          <p className="text-red-500">&mdash;</p>
        )}
      </div>
      <div id="merchants" className="flex mb-6 items-center gap-x-20">
        <label className="flex w-32 p-overlay-badge" htmlFor="category">Pilih Merchant
          <Badge value="wajib" severity="danger" size="normal" />
        </label>
        <div className="flex-1">
          <Dropdown id="product-merchant-selection-input" disabled value={selectedMerchant} options={[selectedMerchant]} optionLabel="name"
            placeholder={selectedMerchant} className="w-1/4 md:w-14rem" />
        </div>
      </div>
    </div>
  );
};

export default CategoryField;
