import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import useEditProductHighlight from './hooks';
import { optionSection, optionSortBy, optionStatus } from '../helper';
import { MultiSelect } from 'primereact/multiselect';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import { useNavigate } from 'react-router-dom';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { Toast } from 'primereact/toast';

const EditProductHighlight = () => {
  const { data, method } = useEditProductHighlight();
  const navigate = useNavigate();

  const getFormErrorMessage = (name) => {
    return method.isFormFieldValid(name) && <small className="p-error">{data.formik.errors[name]}</small>;
  };

  const values = data.formEdit.sortBy !== 'sequence' ? 'default' : data.formEdit.sortValue;
  return (
    <BaseContainer>
      <Toast ref={data.toast} />
      <LoadingOverlay show={data.isFetchingOfficialMerchant ||
        data.isFetchingProductHotDeals ||
        data.isFetchingProductSubscrribe ||
        data.isFetchingProductTabulation || data.isLoading} />
      <MainTitle title="PRODUCT HIGHLIGHT" />

      <div className="border border-gray-800 rounded-lg p-6">
        <div className="flex">
          <div className="w-1/4" id="title">
            <div className="border border-solid px-16 py-3 font-bold bg-gray-400">Product Information</div>
            <div className="border border-solid px-16 py-3 font-normal text-center" />
          </div>
          <div className="border border-gray-800 rounded-lg p-10 w-full space-y-3">
            <div className="grid grid-cols-3">
              <p className="grid items-center">
                Apply to Street
              </p>
              <div className="grid items-center">
                <MultiSelect
                  className="w-[320px]"
                  id="product-highlight-street-multiselect-input"
                  value={data.selectedStreet}
                  onChange={(e) => {
                    data.formik.setFieldValue('street', e.value);
                    method.setSelectedStreet(e.value);
                  }}
                  options={data.street}
                  optionLabel="name"
                  placeholder="Select Street" maxSelectedLabels={3}
                />
                {getFormErrorMessage('street')}
              </div>
            </div>
            <div className="grid grid-cols-3">
              <p className="grid items-center">
                Apply to Section
              </p>
              {Boolean(data.id) ?
                (
                  <div className="grid items-center">
                    <InputText
                      value={data.formEdit.applyToSection}
                      className="w-[320px]"
                      disabled
                    />
                  </div>
                ) :
                (<div className="grid items-center">
                  <Dropdown
                    value={data.formEdit.applyToSection}
                    options={optionSection}
                    className="w-[320px]"
                    disabled={Boolean(data.id)}
                    optionLabel="label"
                    optionValue="code"
                    onChange={(e)=>method.onChangeFormEdit('applyToSection', e.value)}
                  />
                </div>
                )
              }
            </div>
            {data.formEdit?.applyToSection === 'Product Tabulation' && (
              <div className="grid grid-cols-3">
                <p className="grid items-center">
                 Apply to Tab
                </p>
                <div className="grid items-center">
                  <Dropdown
                    options={data.tabulation}
                    onChange={(e) => method.onChangeFormEdit('applyToTab', e.value)}
                    value={data.formEdit.applyToTab}
                    optionValue="code"
                    className="w-[320px]"
                    optionLabel="name"
                  />
                </div>
              </div>
            )}
            {data.formEdit?.applyToSection === 'Product Hot Deal' && (
              <div className="grid grid-cols-3">
                <p className="grid items-center">
                  Product
                </p>
                <div className="grid items-center">
                  <Dropdown
                    filter
                    className="w-[320px]"
                    filterBy="name"
                    onFilter={(e)=> method.findProducts(e.filter)}
                    options={data.products || []}
                    onChange={(e) => method.setFormEditHotDeal(e.value)}
                    value={data.formEditHotDeal}
                    optionValue="code"
                    optionLabel="name"
                  />
                </div>
              </div>
            ) || (
              <div className="grid grid-cols-3">
                <p className="grid items-center">
                  Product
                </p>
                <div className="grid items-center">
                  <Dropdown
                    filter
                    className="w-[320px]"
                    filterBy="name"
                    placeholder={data.defaultProduct?.name}
                    onFilter={(e)=> method.findProducts(e.filter)}
                    options={data.getProducts || []}
                    onChange={(e) => method.onChangeFormEdit('product', e.value)}
                    value={data.formEdit.product}
                    optionValue="code"
                    optionLabel="name"
                  />
                </div>
              </div>
            )}
            {(data.formEdit?.applyToSection !== 'Product Hot Deal') && (
              <>
                <div className="grid grid-cols-3">
                  <p className="grid items-center">
                    Sort By
                  </p>
                  <div className="grid items-center">
                    <Dropdown
                      className="w-[320px]"
                      options={optionSortBy}
                      onChange={(e) => method.onChangeFormEdit('sortBy', e.value)}
                      value={data.formEdit.sortBy}
                      optionValue="code"
                      optionLabel="label"
                    />
                    {getFormErrorMessage('sortBy')}
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  <p className="grid items-center">
                    Sort Value
                  </p>
                  <div className="grid items-center">
                    <InputText
                      className="w-[320px]"
                      disabled={data.formEdit.sortBy !== 'sequence'}
                      value={values}
                      onChange={(e) => method.onChangeFormEdit('sortValue', e.target.value)}
                    />
                    {getFormErrorMessage('sortValue')}
                  </div>
                </div>
              </>
            )}
            <div className="grid grid-cols-3">
              <p className="grid items-center">
                Valid From
              </p>
              <div className="grid items-center">
                <Calendar
                  showIcon
                  className="w-[320px]"
                  hideOnDateTimeSelect
                  maxDate={new Date(data.formEdit.validTo as string)}
                  minDate={new Date()}
                  value={new Date(data.formEdit.validFrom as string)}
                  onChange={(e: CalendarChangeEvent) => method.onChangeFormEdit('validFrom', new Date(String(e.value)))}
                />
              </div>
            </div>
            <div className="grid grid-cols-3">
              <p className="grid items-center">
                Valid To
              </p>
              <div className="grid items-center">
                <Calendar
                  showIcon
                  className="w-[320px]"
                  hideOnDateTimeSelect
                  value={new Date(data.formEdit.validTo as string)}
                  minDate={new Date(data.formEdit.validFrom as string)}
                  onChange={(e: CalendarChangeEvent) => method.onChangeFormEdit('validTo', new Date(String(e.value)))}
                />
              </div>
            </div>
            <div className="grid grid-cols-3">
              <p className="grid items-center">
                Status
              </p>
              <div className="grid items-center">
                <Dropdown
                  disabled={(data.isCreate && data.formEdit.applyToSection !== 'Product Tabulation') || (method.dateRangeCheck() && Boolean(data.id))}
                  className="w-[320px]"
                  value={data.formEdit.status}
                  options={optionStatus} optionLabel="label" optionValue="code"
                  onChange={(e)=> method.onChangeFormEdit('status', e.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SubmitButton
        onSubmit={() => data.formik.handleSubmit()}
        onCancel={() => {
          navigate('/product-highlight');
        }}
        labelBack="Cancel"
        labelSubmit="Submit"
      />
    </BaseContainer>
  );
};

export default EditProductHighlight;
