import React from 'react';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import useCustom, { Promos } from './hooks';
import { Image } from 'primereact/image';
import IconImage from '@/assets/IconImage';
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { Button } from 'primereact/button';
import Table from '@/components/base/DataTable/DataTable';
import { exportTable } from '@/utils/exportTable';
import dayjs from 'dayjs';
import { usePermissionStore } from '@/store/usePermissions';
import { IPagination } from '@/components/base/Pagination';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';

const PromoPage = () => {
  const { data, method } = useCustom();
  const { canCreate, canView, canDelete, canEdit } = usePermissionStore();
  const imageTemplate = (promos: Promos) => {
    if (promos.images != null) {
      return (
        <div className="">
          <Image
            src={promos.images.desktop}
            zoomSrc={promos.images.desktop}
            alt={promos.images.desktop}
            className="mx-0.5"
            width="300px"
            preview
          />
        </div>
      );
    }
    return (
      <div>
        <IconImage />
      </div>
    );
  };
  const startTemplate = (promo: Promos) => {
    return <div id={`promo-page-start-date-${promo.id}`}>{dayjs(promo.periode_start).format('DD MMM YYYY')}</div>;
  };
  const endTemplate = (promo: Promos) => {
    return <div id={`promo-page-end-date-${promo.id}`}>{dayjs(promo.periode_end).format('DD MMM YYYY')}</div>;
  };
  const statusTemplate = (promos: Promos) => {
    return (
      <div>
        <InputSwitch checked={promos.status} id="status" disabled onChange={(e) => method.handleSwitch(e.value, promos.id)}/>
      </div>
    );
  };
  const header = [
    { field: 'id', header: 'Promo ID' },
    { field: 'name', header: 'Promo Page Name' },
    { field: 'start_date', header: 'Promo Show From', body: startTemplate },
    { field: 'end_date', header: 'Promo Show To', body: endTemplate },
    { field: 'category_promos.name', header: 'Promo Category' },
    { field: 'image', header: 'Thumb Image', body: imageTemplate },
    { field: 'status', header: 'Status', body: statusTemplate }
  ];

  // pagination
  const paginationOptions: IPagination = {
    perPage: data.perPage,
    totalPages: data.totalPages,
    currentPage: data.paginator.currentPage,
    disabledDropdown: data.totalRecords === 0,
    onChangeDropdownPage: method.handleChangeDropdownPage,
    onClickNext: method.handleClickNext,
    onClickPrev: method.handleClickPrev,
    onJumptoPage: method.handleJumpToPage,
    onChangeJumptoPage: method.handleChangeJumpTopage
  };

  const filterContent = (
    <div className="flex flex-col gap-4 w-[900px]">
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Promo Name</label>
          <InputText
            onChange={(e) => method.handleFilter(e, 'name')}
            value={data.itemFilters.name}
            name="name"
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Show Date</label>
          <Calendar
            onChange={(e) => method.handleFilter(e, 'show_date')}
            value={data.itemFilters.show_date}
            name="show_date"
            selectionMode="range"
            showIcon
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Category</label>
          <Dropdown
            onChange={(e) => method.handleFilter(e, 'category')}
            options={data.optionCategoryPromo}
            value={data.itemFilters.category}
            name="category"
            placeholder="Select Category"
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Status</label>
          <Dropdown
            options={[{ label: 'ACTIVE', value: 'active' }, { label: 'INACTIVE', value: 'inactive' }]}
            onChange={(e) => method.handleFilter(e, 'status')}
            value={data.itemFilters.status}
            placeholder="Select Status"
          />
        </div>
      </div>
    </div>
  );

  return (
    <BaseContainer>
      <LoadingOverlay show={data.isLoadingPromos} />
      <ConfirmDialog />
      <Toast ref={data.toast} />
      <MainTitle title="Promo Page" labelButton="Create Promo Page" nav="create" isWithButton={canCreate} />
      <div className="flex gap-4 justify-end">
        <Button disabled={!canView} label="Export" icon="pi pi-download" severity="success" onClick={() => exportTable(data.promos as [], 'Promo Page.xlsx')} />
      </div>
      <Table
        filter={data.search}
        headerTable={header}
        rows={data.totalRecords}
        paginationOptions={paginationOptions}
        onDeleted={method.confirmDelete}
        onSearch={method.handleSearch}
        value={data.promos as []}
        isDeleteAction={canDelete}
        isDetailAction={canView}
        isEditAction={canEdit}
        filterHistory={data.filterHistory}
        handleClearFilter={method.handleClearFilter}
        handleDeleteFilterHistory={method.handleDeleteFilterHistory}
        onSubmitFilter={method.handleClickSubmitFilter}
        filterContent={filterContent}
      />
    </BaseContainer>
  );
};

export default PromoPage;
