import { useMemo, useState } from 'react';
import { useDebounce } from 'primereact/hooks';
import { useProductVariant } from '@/services/rest/product';

import type { ITableDataProducts } from '@/views/FlashSaleManagement/Create/hooks';

const useCustom = () => {
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginator, setPaginator] = useState({
    range: {
      start: 0,
      end: perPage - 1
    }
  });
  const [searchInputValue, debounceSearch, setSearchInputValue] = useDebounce('', 500);
  //get all product
  const {
    data: dataProduct,
    refetch: refetchProduct,
    isFetching: isFetchingProduct
  } = useProductVariant(paginator.range, debounceSearch);

  const tableData = useMemo(() => {
    if (!dataProduct?.data) return [];
    return dataProduct?.data.map((item) => {
      let productName = item?.products?.name;
      if (item?.['attribute_set_variants']?.length > 0) {
        const variants = item?.['attribute_set_variants']?.map((variant) => variant.value)?.join(', ');
        productName += ` (Varian: ${variants})`;
      }
      return {
        'Product Variant ID': item?.id,
        'Product Name': productName,
        'Merchant': item?.products?.merchants?.name,
        'Merchant ID': item?.products?.merchants?.id,
        'Price': item?.price,
        'Flash Sale Price By': '',
        'Flash Sale Price Value': '',
        'Stock': item?.saleable_stock,
        'Jumlah Stock Flash Sale': 0,
        'Sort By': '',
        'Sort Value': '',
        'Periode': '',
        'Slot': '',
        'Status': true,
        'Status Product': item?.products?.status
      } as ITableDataProducts;
    });
  }, [dataProduct?.data]);

  const totalData = useMemo(() => dataProduct?.count || 0, [dataProduct?.count]);

  const onPageChange = (first: number, rows: number) => {
    setCurrentPage(first / rows);
    setPerPage(rows);
    setPaginator({
      range: {
        start: first,
        end: first + rows - 1
      }
    });
  };

  const onSearchProduct = (value: string) => {
    setCurrentPage(0);
    setPaginator({
      range: {
        start: 0,
        end: perPage - 1
      }
    });
    setSearchInputValue(value);
  };

  return {
    data: {
      tableData,
      isFetchingProduct,
      searchInputValue,
      totalData,
      currentPage,
      perPage
    },
    method: {
      onPageChange,
      onSearchProduct,
      refetchProduct
    }
  };
};

export default useCustom;
