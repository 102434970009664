import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import dayjs from 'dayjs';
import { headerProduct, headerStore, optionAction, optionShowDescription, optionStatus } from '../helpers';
import { MultiSelect } from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IProductVariant, IStore } from '../type';
import { usePromoTaggingCreatePromo } from './hooks';
import LoadingOverlay from '@/components/base/LoadingOverlay';

const PromoTaggingCreate = () => {
  const { data, methods } = usePromoTaggingCreatePromo();

  const getFormErrorMessage = (name) => {
    return methods.isFormFieldValid(name) && <small className="p-error">{data.formik.errors[name]}</small>;
  };

  const footerContent = (
    <div>
      <Button label="Cancel" icon="pi pi-times" onClick={() => methods.onCancel()} className="p-button-text" />
      <Button label="Confirm" icon="pi pi-check" onClick={() => methods.onSubmit()} autoFocus />
      {data.validate ?
        (<p className="w-full mt-3 text-end text-sm text-red-600">
          {data.tempSelected.length} {data.selectedCategory === 'newProduct' ? 'product variant' : 'store '} Selected (max. 6)
        </p>
        ) :
        (<p className="w-full mt-3 text-end text-sm text-gray-600">
          {data.tempSelected.length} {data.selectedCategory === 'newProduct' ? 'product variant' : 'store '} Selected (max. 6)
        </p>
        )}
    </div>
  );
  const prevPage = () => {
    return (
      <Button rounded icon="pi pi-angle-left" disabled={Boolean(data.paginator.currentPage===1)} outlined severity="secondary" onClick={methods.handleClickPrev} className="!border-none"/>
    );
  };
  const nextPage = () => {
    return (
      <Button rounded icon="pi pi-angle-right" disabled={Boolean(data.paginator.currentPage===data.totalPages)} outlined severity="secondary" onClick={methods.handleClickNext} className="!border-none"/>
    );
  };
  const firstPage = () => {
    return (
      <Button rounded icon="pi pi-angle-double-left" outlined severity="secondary" onClick={methods.handleClickFirstPage} className="!border-none"/>
    );
  };
  const lastPage = () => {
    return (
      <Button rounded icon="pi pi-angle-double-right" outlined severity="secondary" onClick={methods.handleClickLastPage} className="!border-none"/>
    );
  };
  const pageLink = ()=>{
    const lastPage = data.totalPages - 4;
    if (data.totalPages > 1 && data.totalPages <=5) {
      return Array.from({ length: data.totalPages }).map((_, index)=>{
        const pages = index+1;
        return <Button onClick={methods.handleClickPage(pages)} className={`!border-none !font-thin ${pages === data.paginator.currentPage && '!text-blue-700 !bg-blue-100'}`} rounded outlined severity="secondary" key={pages} label={String(pages)}/>;
      });
    }
    if (data.totalPages>5) {
      return Array.from({ length: 5 }).map((_, index)=>{
        const pages = (data.paginator.currentPage >= data.totalPages-3 && lastPage + index) || (data.paginator.currentPage <= 3 ? index + 1 : index + data.paginator.currentPage - 2);
        return <Button onClick={methods.handleClickPage(pages)} className={`!border-none !font-thin ${pages === data.paginator.currentPage && '!text-blue-700 !bg-blue-100'}`} rounded outlined severity="secondary" key={pages} label={String(pages)}/>;
      });
    }
    return <Button onClick={methods.handleClickPage(1)} className="!border-none !font-thin !text-blue-700 !bg-blue-100" rounded outlined severity="secondary" label="1"/>;
  };
  const dropdownOptions = (options) => {
    const dropdownOptions = [
      { label: 10, value: 10 },
      { label: 25, value: 25 },
      { label: 50, value: 50 },
      { label: 100, value: 100 }
    ];
    const autoDisabled: boolean = options.totalRecords === 0;

    return <Dropdown value={data.perPage} options={dropdownOptions} onChange={methods.handleChangeDropdownPage} disabled={autoDisabled} />;
  };
  const paginationTemplate = {
    'layout': 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
    'PrevPageLink': prevPage,
    'NextPageLink': nextPage,
    'FirstPageLink': firstPage,
    'LastPageLink': lastPage,
    'PageLinks': pageLink,
    'RowsPerPageDropdown': dropdownOptions
  };
  return (
    <BaseContainer>
      <LoadingOverlay show={data.isLoading}/>
      <Toast ref={data.toast} />
      <Dialog id="promo-tagging-dialog" header={data.selectedCategory === 'newProduct' ? 'List Produk' : 'List Store'} visible={data.visible} style={{ width: '50vw' }} onHide={() => methods.setVisible(false)} footer={footerContent}>
        <div id="search" className="mb-4">
          <InputText onKeyUp={methods.handleOnEnter} className="w-full" placeholder="Search With 3 Letters or More" type="search" onChange={(e) => methods.handleSearch(e.target.value)} />
        </div>
        <DataTable
          selectionPageOnly paginator rows={data.perPage}
          paginatorTemplate={paginationTemplate}
          id="promo-tagging-data-table"
          value={data.selectedCategory === 'newProduct' ? data.dataProductVariants : data.dataStores}
          selectionMode="multiple"
          selection={data.tempSelected}
          onSelectionChange={(e) => {
            const value = data.selectedCategory === 'newProduct' ? e.value as IProductVariant[] : e.value as IStore[];
            data.formik.setFieldValue('selectedItem', value);
            methods.setTempSelected(value);
          }}
          dataKey="id"
          tableStyle={{ minWidth: '50rem' }}>
          <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
          {data.selectedCategory === 'newProduct' ? headerProduct.map((item) =>
            <Column key={item.field} field={item.field} header={item.header} />
          ) : headerStore.map((item) =>
            <Column key={item.field} field={item.field} header={item.header} />
          )}
        </DataTable>
      </Dialog>

      <MainTitle isWithButton={false} title="Create Promo Tagging" />
      <div className="border p-6">
        <div className="flex">
          <div id="title" className="w-1/4">
            <div className="border border-solid px-16 py-3 font-bold bg-gray-400">PROMO TAGGING INFORMATION</div>
            <div className="border border-solid px-16 py-3 font-normal text-center" />
          </div>
          <div className="border p-10 w-full space-y-3">
            <div className="grid grid-cols-3 items-center">
              <p className="grid items-center">
                  Promo Tagging Category*
              </p>
              <div className="grid items-center">
                <Dropdown
                  id="promo-tagging-category-selection-input"
                  value={data.selectedCategory}
                  options={data.optionCategoryTags}
                  onChange={(e) => methods.handleOnchangeCategory(e.value)}
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Select Category"
                  className="w-full md:w-14rem" />
                {getFormErrorMessage('selectedCategory')}
              </div>
            </div>
            {data.selectedCategory !== 'storeRecommendation' &&
                <>
                  <div className="grid grid-cols-3">
                    <p className="grid items-center">
                      Promo Tagging Name*
                    </p>
                    <div id="promo-tagging-category-tagging-name" className="grid items-center">
                      <InputText id="promo-tagging-name-input" value={data.formEditPromoTagging.name} onChange={(e) => methods.onChangeFormEditPromotagging('name', e.target.value)} />
                      {getFormErrorMessage('name')}
                    </div>
                  </div>
                  <div className="grid grid-cols-3">
                    <p className="grid items-center">
                      Description
                    </p>
                    <div id="promo-tagging-category-description" className="grid items-center">
                      <InputText id="promo-tagging-description-input" value={data.formEditPromoTagging.description} onChange={(e) => methods.onChangeFormEditPromotagging('description', e.target.value)} />
                    </div>
                  </div>
                </>
            }

            {(data.selectedCategory === 'topKeywordList') &&
                <div className="grid grid-cols-3">
                  <p className="grid items-center">
                    Show Description
                  </p>
                  <div className="grid items-center">
                    <Dropdown id="promo-tagging-show-description-show-description" value={data.formEditPromoTagging.showDescription} onChange={(e) => methods.onChangeFormEditPromotagging('showDescription', e.value)} options={optionShowDescription} optionLabel="name"
                      placeholder="Show Description" className="w-full md:w-14rem" />
                  </div>
                </div>
            }
            {(data.selectedCategory === 'newProduct' || data.selectedCategory === 'storeRecommendation') &&
                <>
                  <div className="grid grid-cols-3">
                    <div className="grid items-center">
                      {data.selectedCategory === 'newProduct' ? 'Select Product' : 'Select Store'}
                    </div>
                    <div className="grid items-center">
                      <Button id="promo-tagging-add-button"
                        label={data.selectedCategory === 'newProduct' ? '+ Add Product' : '+ Add Store'} onClick={() => methods.handleOpenModal()}
                      />
                      {
                        (
                          <p className="w-full mt-3 text-end text-sm text-blue-600"> {data.selected.length} {data.selectedCategory === 'newProduct' ? 'product variant' : 'store '} Selected (max. 6)
                          </p>
                        )
                      }
                      <div>
                        {getFormErrorMessage('selectedItem')}
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-3">
                    <p className="grid items-center">
                      Action*
                    </p>
                    <div id="promo-tagging-category-action" className="grid items-center">
                      <Dropdown id="promo-tagging-action-dropdown-input-action" value={data.formEditPromoTagging.action} onChange={(e) => methods.onChangeFormEditPromotagging('action', e.value)} options={optionAction} optionLabel="name"
                        placeholder="Select Action" className="w-full md:w-14rem" />
                      {getFormErrorMessage('action')}
                    </div>
                  </div>
                </>
            }
            {(data.selectedCategory === 'topKeywordList' || data.selectedCategory === 'keywordRecommendation') && (
              <>
                <div id="apply-to-street" className="grid grid-cols-3">
                  <p id="apply-street-label" className="grid items-center">
                      Direct To*
                  </p>
                  <div className="grid items-center" id="dropdown_field">
                    <div className="card grid justify-content-center">
                      <Dropdown
                        id="promo-tagging-category-apply-street"
                        value={data.formEditPromoTagging.directTo}
                        optionLabel="label"
                        className="w-full"
                        optionValue="code"
                        placeholder="Select"
                        options={data.selectedCategory === 'keywordRecommendation' ? data.optionKeywordRecomendations :data.optionDirectTo}
                        onChange={(e) => methods.onChangeFormEditPromotagging('directTo', e.value)}
                      />
                      {getFormErrorMessage('directTo')}

                    </div>
                  </div>
                </div>
                {data.formEditPromoTagging.directTo === 'STORE_DETAIL' && (
                  <div id="apply-to-street" className="grid grid-cols-3">
                    <div id="apply-street-label" className="grid items-center" />
                    <div className="grid items-center" id="dropdown_field">
                      <div className="card f;ex flex-col justify-content-center">
                        <Dropdown
                          id="promo-tagging-category-merchant-list"
                          value={data.formEditPromoTagging.merchantId}
                          className="w-full h-full"
                          placeholder="Select"
                          filter
                          filterBy="label,code"
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => methods.findStores(e.target.value)}
                          options={data.optionStores as []}
                          onChange={(e) => methods.onChangeFormEditPromotagging('merchantId', e.value)}
                          resetFilterOnHide
                        />
                        {getFormErrorMessage('merchantId')}

                      </div>
                    </div>
                  </div>
                )}
                {(data.formEditPromoTagging.directTo === 'PDP') && (
                  <div className="grid grid-cols-3">
                    <div className="grid items-center" />
                    <div className="grid items-center" id="dropdown_field">
                      <div className="card flex flex-col justify-content-center">
                        <Dropdown
                          id="promo-tagging-category-products-list"
                          value={data.formEditPromoTagging.productId}
                          className="w-full"
                          placeholder="Select"
                          filter
                          filterBy="label,code"
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => methods.findProducts(e.target.value)}
                          options={data.optionProducts as []}
                          onChange={(e) => methods.onChangeFormEditPromotagging('productId', e.value)}
                          resetFilterOnHide
                        />
                        {getFormErrorMessage('productId')}

                      </div>
                    </div>
                  </div>
                )}
                {data.formEditPromoTagging.directTo === 'PROMO_DETAIL_PAGE' && (
                  <div className="grid grid-cols-3">
                    <div className="grid items-center" />
                    <div className="grid items-center" id="dropdown_field">
                      <div className="card flex flex-col justify-content-center">
                        <Dropdown
                          id="promo-tagging-category-promos-list"
                          value={data.formEditPromoTagging.promoDetailId}
                          className="w-full"
                          placeholder="Select"
                          filter
                          filterBy="label,code"
                          options={data.optionPromos as []}
                          onChange={(e) => methods.onChangeFormEditPromotagging('promoDetailId', e.value)}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => methods.findPromos(e.target.value)}
                          resetFilterOnHide
                        />
                        {getFormErrorMessage('promoDetailId')}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            {data.formEditPromoTagging.directTo === 'LANDING_PAGE' && (
              <div id="apply-to-street" className="grid grid-cols-3">
                <div id="apply-street-label" className="grid items-center" />
                <div className="grid items-center" id="dropdown_field">
                  <div className="card f;ex flex-col justify-content-center">
                    <InputText
                      id="promo-tagging-category-merchant-list"
                      value={data.formEditPromoTagging.landingPageType}
                      onChange={(e) => methods.onChangeFormEditPromotagging('landingPageType', e.target.value)}
                      placeholder="https://aladinmall.id/......"
                      className="w-full h-full"
                    />
                    {getFormErrorMessage('landingPageType')}
                  </div>
                </div>
              </div>
            )}
            <div className="grid grid-cols-3">
              <p className="grid items-center">
                  Valid From*
              </p>
              <div className="grid items-center">
                <Calendar
                  showIcon
                  id="promo-tagging-valid-from-valid-from-input"
                  placeholder={data.formEditPromoTagging.valid_from}
                  value={data.formEditPromoTagging.valid_from}
                  minDate={new Date()} hideOnDateTimeSelect
                  maxDate={new Date(data.formEditPromoTagging.valid_to)}
                  onChange={(e: CalendarChangeEvent) => methods.onChangeFormEditPromotagging('valid_from', dayjs(e.target.value as string).format('YYYY-MM-DD'))}
                  dateFormat="yy-mm-dd" />
                {getFormErrorMessage('valid_from')}
              </div>
            </div>
            <div className="grid grid-cols-3">
              <p className="grid items-center">
                  Valid To*
              </p>
              <div className="grid items gap-2">
                <Calendar id="promo-tagging-valid-to-valid-to-input"
                  showIcon
                  inputStyle={{
                    border: data.validateDate ? '1px solid red' : ''
                  }}
                  placeholder={data.formEditPromoTagging.valid_to}
                  value={data.formEditPromoTagging.valid_to}
                  disabled={!Boolean(data.formEditPromoTagging.valid_from)}
                  minDate={data.formEditPromoTagging.valid_from == '' ? new Date() : new Date(data.formEditPromoTagging.valid_from)}
                  onChange={(e: CalendarChangeEvent) => methods.onChangeFormEditPromotagging('valid_to',
                    dayjs(e.target.value as string).format('YYYY-MM-DD'))}
                  dateFormat="yy-mm-dd" hideOnDateTimeSelect />
                {getFormErrorMessage('valid_to')}
                {data.validateDate && (
                  <span className="text-red-500 text-sm">End time must be greater than start time</span>
                )}
              </div>
            </div>

            {data.selectedCategory === 'topKeywordList' &&
                <div className="grid grid-cols-3">
                  <p className="grid items-center">
                    Apply to Street*
                  </p>
                  <div className="flex flex-col">
                    <MultiSelect
                      id="promo-tagging-street-multiselect-input"
                      value={data.selectedStreet}
                      onChange={(e) => {
                        methods.setSelectedStreet(e.value);
                        data.formik.setFieldValue('street', e.value.toString());
                      }}
                      options={data.optionStreet}
                      optionLabel="label"
                      optionValue="label"
                      placeholder="Select Street"
                      maxSelectedLabels={3} className="w-full md:w-20rem" />
                    {getFormErrorMessage('street')}
                  </div>
                </div>
            }
            <div className="grid grid-cols-3">
              <p className="grid items-center">
                  Status*
              </p>
              <div className="grid items-center">
                <Dropdown id="promo-tagging-status-dropdown-input" value={data.formEditPromoTagging.status} onChange={(e) => methods.onChangeFormEditPromotagging('status', e.value)} options={optionStatus} optionLabel="name"
                  placeholder="Select Status" className="w-full md:w-14rem" />
                {getFormErrorMessage('status')}
              </div>
            </div>
          </div>

        </div>
      </div>
      <SubmitButton
        disabledSubmit={data.isLoading || Boolean(data.validateDate)}
        onSubmit={data.formik.handleSubmit}
        onCancel={methods.handleClickCancel}
        labelBack="Cancel"
        labelSubmit="Create"
      />
    </BaseContainer>
  );
};

export default PromoTaggingCreate;
