import React from 'react';
import useDetailProductHighlight from './hooks';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import { InputText } from 'primereact/inputtext';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { usePermissionStore } from '@/store/usePermissions';

const DetailProductHighlight = () => {
  const { data } = useDetailProductHighlight();
  const navigate = useNavigate();
  const { canEdit } = usePermissionStore();
  return (
    <BaseContainer>
      <LoadingOverlay show={data?.productHighlight?.loading} />
      <MainTitle title="PRODUCT HIGHLIGHT" nav="/product-highlight"/>

      <div className="border border-gray-800 rounded-lg p-6">
        <div className="flex">
          <div className="w-1/4" id="title">
            <div className="border border-solid px-16 py-3 font-bold bg-gray-400">Product Information</div>
            <div className="border border-solid px-16 py-3 font-normal text-center" />
          </div>
          <div className="border border-gray-800 rounded-lg p-10 w-full space-y-3">
            <div className="grid grid-cols-3">
              <p className="grid items-center">
                Apply to Street
              </p>
              <div className="grid items-center">
                <InputText value={data?.productHighlight?.applyToStreet} disabled/>
              </div>
            </div>
            <div className="grid grid-cols-3">
              <p className="grid items-center">
                Apply to Section
              </p>
              <div className="grid items-center">
                <InputText value={data?.productHighlight?.applyToSection || ''} disabled />
              </div>
            </div>
            {data.productHighlight?.applyToSection === 'Product Tabulation' && (
              <div className="grid grid-cols-3">
                <p className="grid items-center">
                  Apply to Tab
                </p>
                <div className="grid items-center">
                  <InputText value={String(data?.productHighlight?.applyToTab)} disabled />
                </div>
              </div>
            )}
            <div className="grid grid-cols-3">
              <p className="grid items-center">
                Product
              </p>
              <div className="grid items-center">
                <InputText value={data?.productHighlight?.product || ''} disabled />
              </div>
            </div>
            {data.productHighlight?.applyToSection !== 'Product Hot Deal' && (
              <>
                <div className="grid grid-cols-3">
                  <p className="grid items-center">
                Sort By
                  </p>
                  <div className="grid items-center">
                    <InputText value={data?.productHighlight?.sortBy || ''} disabled />
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  <p className="grid items-center">
                Sort Value
                  </p>
                  <div className="grid items-center">
                    <InputText value={String(data?.productHighlight?.sortValue)} disabled />
                  </div>
                </div>
              </>
            )}
            <div className="grid grid-cols-3">
              <p className="grid items-center">
                Valid From
              </p>
              <div className="grid items-center">
                <InputText value={dayjs(data?.productHighlight?.validFrom).format('MM/DD/YYYY')} disabled />
              </div>
            </div>
            <div className="grid grid-cols-3">
              <p className="grid items-center">
                Valid To
              </p>
              <div className="grid items-center">
                <InputText value={dayjs(data?.productHighlight?.validTo).format('MM/DD/YYYY')} disabled />
              </div>
            </div>
            <div className="grid grid-cols-3">
              <p className="grid items-center">
                Status
              </p>
              <div className="grid items-center">
                <InputText value={data?.productHighlight?.status} disabled />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SubmitButton
        onSubmit={()=> navigate(`/product-highlight/edit/${data.id}?section=${data.query}`)}
        onCancel={() => {
          navigate('/product-highlight');
        }}
        labelBack="Back"
        labelSubmit="Edit"
        disabledSubmit={!canEdit}
      />
    </BaseContainer>
  );
};

export default DetailProductHighlight;
