import React, { FC } from 'react';
// import { category } from './helper';
import { Image } from 'primereact/image';

interface IMenuCategory {
  images: string[];
}
export const MenuCategory: FC<IMenuCategory> = ({ images }) => {
  const categories = [
    {
      title: 'Aksesoris Wanita',
      child: [
        {
          title: 'Kacamata Wanita'
        },
        {
          title: 'Kacamata Wanita'
        },
        {
          title: 'Kacamata Wanita'
        },
        {
          title: 'Kacamata Wanita'
        }
      ]
    },
    {
      title: 'Sepatu Wanita',
      child: [
        {
          title: 'Kacamata Wanita'
        },
        {
          title: 'Kacamata Wanita'
        },
        {
          title: 'Kacamata Wanita'
        },
        {
          title: 'Kacamata Wanita'
        }
      ]
    }
  ];
  return (
    <div className="bg-[#EAEAEA] h-fit py-8 w-full flex justify-center items-center p-2 gap-10">
      <div className="flex flex-col gap-2 text-[8px]">
        <div className="text-[8px]">Fashion Wanita & Pria</div>
        <div className="flex gap-4 pr-8 border-r-2 border-r-gray-400">
          {categories.map((item, index) => {
            return (
              <div key={index}>
                <div className="font-bold">{item.title}</div>
                <div className="flex flex-col">
                  {item.child.map((child, idx) => {
                    return <div key={idx}>{child.title}</div>;
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col gap-1">
        {images.map((item, index) => {
          return <Image key={index} src={item} width="60px" />;
        })}
      </div>
    </div>
  );
};
