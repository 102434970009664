import { PayloadEdit, useGetCountMembers, useGetTeamById, useUpdateGroupInformation } from '@/services/rest/userManagement/index';
import dayjs from 'dayjs';
import { Toast } from 'primereact/toast';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { schemaEditGroup } from './validation';

interface FormEdit {
  name: string;
  description: string;
  status: boolean;
}

interface IEditTeam {
  id: number | null;
  name: string;
  count?: number;
  created_at: string;
  code: string;
  status: string;
  description: string;
}

const useTabEditGroupInformation = () => {
  const navigate = useNavigate();
  const params = useParams();
  const toast = useRef<Toast>(null);
  const { data: dataTeam, isFetching } = useGetTeamById(Number(params.id));
  const { mutate, error: errorUpdate } = useUpdateGroupInformation();
  const initialFormik = {
    name: '',
    status: false
  };

  const showSuccessToast = () => {
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Successfully Edit Information',
      life: 2000
    });
    const timeOut = setTimeout(() => {
      navigate('/user-management');
      return () => clearTimeout(timeOut);
    }, 2000);
  };

  const showFailedToast = (message?: string) => {
    toast.current?.show({
      severity: 'error',
      summary: 'Failed',
      detail: 'Failed Update Information',
      life: 2000
    });
  };

  //formik validate
  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const formik = useFormik({
    initialValues: initialFormik,
    validationSchema: schemaEditGroup,
    onSubmit: () => {
      handleClickSave();
    }
  });

  const team:IEditTeam = useMemo(() => {
    const data = dataTeam?.data;
    const status = data?.status ? 'Active' : 'Inactive';
    return {
      code: data?.code || '',
      created_at: dayjs(data?.created_at).format('DD MMM YYYY'),
      description: data?.description || '',
      id: data?.id || null,
      name: data?.name || '',
      status: status
    };
  }, [dataTeam]);

  const { data: countMembers } = useGetCountMembers(Number(team.id));
  const count = useMemo(() => {
    return {
      count: countMembers?.data?.length || 0
    };
  }, [team, countMembers]);

  const mergedData = useMemo(() => {
    return {
      ...team,
      count: count?.count
    };
  }, [team, count]);

  const [formEdit, setFormEdit] = useState<FormEdit>({
    description: '-',
    name: '-',
    status: true
  });

  const handleFormEdit = (key: string, value: string | boolean) => {
    formik.setFieldValue(key, value);
    setFormEdit((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const handleClickCancel = useCallback(() => {
    navigate(`/user-management/${params.id}`);
  }, []);

  useEffect(()=>{
    if (params.id) {
      formik.setValues({
        name: mergedData.name,
        status: mergedData.status === 'Active'
      });
      setFormEdit({
        description: mergedData.description,
        name: mergedData.name,
        status: mergedData.status == 'Active'
      });
    }
  }, [params, mergedData]);

  const handleClickSave = useCallback(() => {
    const payload: PayloadEdit = {
      description: formEdit.description,
      id: Number(params.id),
      name: formEdit.name,
      status: formEdit.status
    };
    mutate(payload);
    if (errorUpdate) {
      showFailedToast();
      return;
    }
    showSuccessToast();
  }, [formEdit]);

  return {
    data: {
      isFetching,
      formEdit,
      mergedData,
      toast,
      formik
    },
    method: {
      handleClickSave,
      handleFormEdit,
      handleClickCancel,
      isFormFieldValid
    }
  };
};

export default useTabEditGroupInformation;
