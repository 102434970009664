import * as yup from 'yup';
import { MESSAGE } from '@/constants';

const schemaUploadBanner = yup.object({
  name: yup.string().required(MESSAGE.REQUIRED),
  type: yup.string().required(MESSAGE.REQUIRED),
  desktop: yup.string()
    .when('type', (type, schema)=>{
      if (!type.includes('BRAND_BANNER')) {
        return schema.required(MESSAGE.REQUIRED);
      }
      return schema;
    }),
  mobile: yup.string()
    .when('type', (type, schema)=>{
      if (!type.includes('BRAND_BANNER')) {
        return schema.required(MESSAGE.REQUIRED);
      }
      return schema;
    }),
  thumbnail: yup.string()
    .when('type', (type, schema)=>{
      if (!type.includes('BRAND_BANNER') &&
        !type.includes('SECTION_FLASH_SALE_BACKGROUND') &&
        !type.includes('VIDEO_BANNER') &&
        !type.includes('PROMO_BANNER') &&
        !type.includes('PRODUCT_CATEGORY_BANNER') &&
        !type.includes('SECTION_BACKGROUND') &&
        !type.includes('POPUP_BANNER')) {
        return schema.required(MESSAGE.REQUIRED);
      }
      return schema;
    }),
  centerImageBrand: yup.array()
    .when('type', (type, schema)=>{
      if (type.includes('BRAND_BANNER')) {
        return schema.min(1, MESSAGE.REQUIRED);
      }
      return schema;
    }),
  leftImageBrand: yup.array()
    .when('type', (type, schema)=>{
      if (type.includes('BRAND_BANNER')) {
        return schema.min(1, MESSAGE.REQUIRED);
      }
      return schema;
    }),
  rightImageBrand: yup.array()
    .when('type', (type, schema)=>{
      if (type.includes('BRAND_BANNER')) {
        return schema.min(1, MESSAGE.REQUIRED);
      }
      return schema;
    }),
  status: yup.string().required(MESSAGE.REQUIRED)
});

const schemaDetailBanner = yup.object({
  street: yup.string().required(MESSAGE.REQUIRED)
});

export { schemaUploadBanner, schemaDetailBanner };
