import React, { useEffect, useMemo, useState } from 'react';
import { routes } from '@/routes';
import { Menu, useGetMenus, useGetParentMenus, useGetTeamMenus } from '@/services/rest/user';
import { useAuthStore } from '@/store/useAuthStore';
import { usePermissionStore } from '@/store/usePermissions';
import { useLocation } from 'react-router-dom';

interface ChildRoute {
  name: string;
  to: string;
  isActive: boolean
}

export interface Route {
  name: string;
  to: string;
  icon?: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
  isActive: boolean;
  childrens?: ChildRoute[];
}

const useCustom = () => {
  const location = useLocation();
  const user = useAuthStore((state) => state.user);
  const setPermissions = usePermissionStore((state) => state.setPermissions);

  const [menus, setMenus] = useState<Route[]>([]);

  // get team menus
  const { data: dataTeamMenus } = useGetTeamMenus(user?.user_metadata?.team_id);

  // filter team menu which CRUD false
  const teamMenuIds = useMemo(() => {
    return Array.isArray(dataTeamMenus?.data) ?
      dataTeamMenus?.data?.filter((it) => it.create || it.delete || it.read || it.update)?.map((it) => it.menu_id) : [];
  }, [dataTeamMenus]);

  // get menu
  const { data: dataMenus } = useGetMenus(teamMenuIds as number[]);

  // parent id
  const parentMenuIds = useMemo(() => {
    const idParent = dataMenus?.data?.map((it) => it.id) as number[];
    if (idParent) {
      const parentId = [
        ...idParent
      ];

      return Array.isArray(dataMenus?.data) ?
        parentId :
        [];
    }
    return [];
  }, [dataMenus?.data]);

  // get parrentMenus
  const { data: parentMenus } = useGetParentMenus(parentMenuIds as number[]);

  // filter code those with details, edit, create, delete
  const filterMenus = useMemo(() => {
    return parentMenus?.data?.filter((c) => !c?.code?.includes('detail') && !c?.code?.includes('edit') && !c?.code?.includes('create') && !c?.code?.includes('delete'));
  }, [parentMenus?.data]);

  // filter route role
  const routerRole = useMemo(() => {
    return Array.isArray(filterMenus) ?
      filterMenus?.filter((it) => it.parent_id == null)?.map((p) => {
        const children = dataMenus?.data?.filter((c) => c.parent_id === p.id && !c?.code?.includes('detail') && !c?.code?.includes('edit') && !c?.code?.includes('create') && !c?.code?.includes('delete'));

        return {
          code: p?.code,
          children: children?.map((c) => c?.code)
        };
      }) : [];
  }, [parentMenus?.data]);


  // filter route by route role
  const filteredArray = useMemo(() => {
    const routerHistory = [
      ...routerRole,
      {
        code: 'bos.importhistory',
        children: []
      }
    ];
    return routes.filter((obj1) => {
      if (Array.isArray(routerHistory)) {
        return routerHistory.some((obj2) => obj2.code?.includes(obj1.code as string));
      }

      return false;
    });
  }, [routerRole]);

  useEffect(() => {
    setMenus(filteredArray);
  }, [filteredArray]);

  setPermissions(menus, location.pathname, dataMenus?.data as Menu[], dataTeamMenus?.data as { [k: string]: string | number | boolean }[]);

  return {
    data: {
      routerRole,
      menus
    },
    methods: {
    }
  };
};

export default useCustom;
