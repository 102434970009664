import * as yup from 'yup';
import { MESSAGE } from '@/constants';

const schemaEditPromoTagging = yup.object({
  name: yup.string().when('category', (category, schema)=>{
    if (category.includes('storeRecommendation')) {
      return schema.notRequired();
    }
    return schema.required(MESSAGE.REQUIRED);
  }),
  valid_from: yup.string().required(MESSAGE.REQUIRED),
  valid_to: yup.string().required(MESSAGE.REQUIRED),
  street: yup.string().when('selectedCategory', (selectedCategory, schema)=>{
    return selectedCategory.includes('topKeywordList') ? schema.required(MESSAGE.REQUIRED) : schema;
  }),
  status: yup.string().required(MESSAGE.REQUIRED),
  directTo: yup.string().when('selectedCategory', (selectedCategory, schema) => {
    if (selectedCategory.includes('topKeywordList') || selectedCategory.includes('keywordRecommendation')) {
      return schema.required(MESSAGE.REQUIRED);
    }
    return schema;
  }),
  productId: yup.string().when('directTo', (directTo, schema) => {
    return directTo.includes('PDP') ? schema.required(MESSAGE.REQUIRED) : schema;
  }),
  merchantId: yup.string().when('directTo', (directTo, schema) => {
    return directTo.includes('STORE_DETAIL') ? schema.required(MESSAGE.REQUIRED) : schema;
  }),
  promoDetailId: yup.string().when('directTo', (directTo, schema) => {
    return directTo.includes('PROMO_DETAIL_PAGE') ? schema.required(MESSAGE.REQUIRED) : schema;
  }),
  landingPageType: yup.string().when('directTo', (directTo, schema) => {
    return directTo.includes('LANDING_PAGE') ? schema.required(MESSAGE.REQUIRED) : schema;
  }),
  action: yup.string().when('selectedCategory', (selectedCategory, schema) => {
    if (selectedCategory.includes('newProduct') || selectedCategory.includes('storeRecommendation')) {
      return schema.required(MESSAGE.REQUIRED);
    }
    return schema;
  }),
  selectedItem: yup.array().when('selectedCategory', (selectedCategory, schema) => {
    if (selectedCategory.includes('newProduct') || selectedCategory.includes('storeRecommendation')) {
      return schema.min(1, MESSAGE.MIN_SELECTED).required(MESSAGE.MIN_SELECTED);
    }
    return schema;
  })
});

export { schemaEditPromoTagging };
