import React from 'react';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import useCustom from './hooks';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { useNavigate } from 'react-router-dom';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import { Toast } from 'primereact/toast';
import { usePermissionStore } from '@/store/usePermissions';

const CreateAtributeSet = () => {
  const { data, method } = useCustom();
  const navigate = useNavigate();
  const getFormErrorMessage = (name) => {
    return method.isFormFieldValid(name) && <small className="p-error">{data.formik.errors[name]}</small>;
  };
  const { canEdit } =usePermissionStore();
  return (
    <BaseContainer>
      <LoadingOverlay show={data.isLoading} />
      <Toast ref={data.toast} />
      <MainTitle title={data.params.id && 'Edit Attribute Set'||data.params.detailId && 'Detail Attribute Set' || 'Create Attribute Set'} />
      <div className="mt-6">
        <div className="flex w-full mt-3">
          <div className="font-bold w-1/5">
              Nama Attribute Set <span className="text-red=500">*</span>
          </div>
          <div className="w-3/4">
            <InputText
              id="inputNameAttributeSet"
              className="w-1/3"
              value={data.formValue.name}
              onChange={method.changeInputText('name')}
              placeholder="Masukan Atribut Set"
              disabled={Boolean(data.params.detailId)}
            />
            <div className="pt-1 pl-1">{getFormErrorMessage('name')}</div>
          </div>
        </div>
        <div className="flex w-full mt-3">
          <div className="font-bold w-1/5">is Active</div>
          <InputSwitch
            color="black"
            id="inputStatusAttributeSet"
            checked={data.formValue.is_active}
            onChange={method.handleChangeInputSwitch}
            disabled={Boolean(data.params.detailId)}
          />
        </div>
        <div className="flex w-full mt-3">
          <div className="font-bold w-1/5">Deskripsi</div>
          <div className="w-1/2">
            <InputTextarea
              id="inputDescriptionAttributeSet"
              placeholder="Deskripsi"
              value={data.formValue.description}
              onChange={method.changeInputText('description')}
              rows={5}
              cols={33}
              disabled={Boolean(data.params.detailId)}
            />
          </div>
        </div>
      </div>
      <div className="mt-5 pt-5 border-t-2">
        <h3 className="font-semibold mb-2">1. Spesifikasi</h3>
        {data.formValue.specification_ids.map((_, index) => {
          return (
            <div key={index} className="flex gap-5 mb-2">
              <Dropdown
                id="InputListSpecification"
                onChange={method.handleChangeDropdown(index, 'specification_ids')}
                value={data.formValue.specification_ids[index]}
                options={data.optionListSpecification as []}
                optionLabel="label"
                optionValue="value"
                placeholder="Pilih Spesifikasi"
                className="w-1/3 md:w-14rem"
                onFilter={(e) => method.handleSearchDropdown(e.filter, 'specification')}
                filter
                filterBy="label"
                disabled={Boolean(data.params.detailId)}
              />
              <Button
                id="buttonDeleteSpecification"
                icon="pi pi-trash"
                text
                severity="danger"
                onClick={method.handleDeleteOption(index, 'specification_ids')}
                disabled={Boolean(data.params.detailId)}
              />
            </div>
          );
        })}
        <Button
          id="buttonAddSpecification"
          outlined
          severity="secondary"
          label="Tambah Spesifikasi"
          type="button"
          className="mt-2 !border-none"
          icon="pi pi-plus"
          onClick={method.handleAddOption('specification_ids')}
          disabled={Boolean(data.params.detailId)}
        />
        <div className="pt-1 pl-1">{getFormErrorMessage('specification_ids')}</div>
      </div>
      <div className="mt-5 py-5 border-t-2">
        <h3 className="font-semibold mb-2">2. Variant</h3>
        {data.formValue.variant_ids.map((_, index) => {
          return (
            <div key={index} className="flex gap-5 mb-2">
              <Dropdown
                id="inputListVariant"
                onChange={method.handleChangeDropdown(index, 'variant_ids')}
                value={data.formValue.variant_ids[index]}
                options={data.optionListVariant as []}
                optionLabel="label"
                placeholder="Pilih Variant"
                optionValue="value"
                className="w-1/3 md:w-14rem"
                onFilter={(e) => method.handleSearchDropdown(e.filter, 'variant')}
                filter
                filterBy="label"
                disabled={Boolean(data.params.detailId)}
              />
              <Button
                id="buttonDeleteVariant"
                icon="pi pi-trash"
                text
                severity="danger"
                onClick={method.handleDeleteOption(index, 'variant_ids')}
                disabled={Boolean(data.params.detailId)}
              />
            </div>
          );
        })}
        <Button
          id="buttonAddVariant"
          outlined
          label="Tambah Variant"
          severity="secondary"
          type="button"
          icon="pi pi-plus"
          className="mt-2 !border-none"
          onClick={method.handleAddOption('variant_ids')}
          disabled={Boolean(data.params.detailId)}
        />
        <div className="pt-1 pl-1">{getFormErrorMessage('variant_ids')}</div>
      </div>
      <SubmitButton
        labelBack="Cancel"
        onCancel={() => navigate('/attribute-set')}
        onSubmit={data.formik.handleSubmit}
        labelSubmit={data.params.detailId ? 'Edit' : 'Submit'}
        disabledSubmit={data.params.detailId ? !canEdit : false}
      />
    </BaseContainer>
  );
};

export default CreateAtributeSet;
