import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import Table from '@/components/base/DataTable/DataTable';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import useMasterSlots, { header } from './hooks';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { Button } from 'primereact/button';
import { usePermissionStore } from '@/store/usePermissions';
import { IPagination } from '@/components/base/Pagination';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';

const MasterSlots = () => {
  const {
    data: {
      dataSlots,
      isLoadingAllMasterSlots,
      toast,
      selectedDataCustomer,
      selectedTypeFilter,
      pagination,
      perPage,
      totalPages,
      isLoading,
      totalRecords,
      itemFilters,
      filterHistory,
      search
    },
    methods: {
      confirmDelete,
      handleFilter,
      setSelectedDataCustomer,
      deleteFilterHistory,
      handleChangeDropdownPage,
      handleClickNext,
      handleClickPrev,
      handleJumpToPage,
      handleChangeJumpTopage,
      handleClearFilter,
      handleDeleteFilterHistory,
      handleSearch,
      handleClickSubmitFilter
    }
  } = useMasterSlots();
  const { canCreate, canView, canDelete, canEdit } = usePermissionStore();

  // pagination
  const paginationOptions: IPagination = {
    perPage: perPage,
    totalPages: totalPages,
    currentPage: pagination.currentPage,
    disabledDropdown: totalRecords === 0,
    onChangeDropdownPage: handleChangeDropdownPage,
    onClickPrev: handleClickPrev,
    onClickNext: handleClickNext,
    onJumptoPage: handleJumpToPage,
    onChangeJumptoPage: handleChangeJumpTopage
  };

  const filterContent = (
    <div className="flex flex-col gap-4 w-[900px]">
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Slot Name</label>
          <InputText
            onChange={(e) => handleFilter(e, 'name')}
            value={itemFilters.name}
            name="name"
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Created By</label>
          <InputText
            onChange={(e) => handleFilter(e, 'created_by')}
            value={itemFilters.created_by}
            name="created_by"
            keyfilter="email"
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Updated By</label>
          <InputText
            onChange={(e) => handleFilter(e, 'updated_by')}
            value={itemFilters.updated_by}
            name="updated_by"
            keyfilter="email"
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-status" className="font-bold">Created Date</label>
          <Calendar
            onChange={(e) => handleFilter(e, 'created_at')}
            value={itemFilters.created_at}
            name="created_at"
            className="w-full"
            selectionMode="range"
            showIcon
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-status" className="font-bold">Updated Date</label>
          <Calendar
            onChange={(e) => handleFilter(e, 'updated_at')}
            value={itemFilters.updated_at}
            name="updated_at"
            className="w-full"
            selectionMode="range"
            showIcon
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Status</label>
          <Dropdown
            options={[{ label: 'ACTIVE', value: 'ACTIVE' }, { label: 'INACTIVE', value: 'INACTIVE' }]}
            onChange={(e) => handleFilter(e, 'status')}
            value={itemFilters.status}
            placeholder="Select Status"
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <ConfirmDialog />
      <Toast ref={toast} />
      <LoadingOverlay show={isLoading}/>
      <BaseContainer>
        <MainTitle title="Manage Slot" nav="create" labelButton="Create New Slot" isWithButton={canCreate} />
        {selectedTypeFilter.length !== 0 ? ( <div id="slot-filter-history" className="flex items-center gap-1 border-y-2 border-black my-4">
          <p className="font-semibold">Filter Aktif : Type: {selectedTypeFilter.join(', ')}</p> <Button icon="pi pi-times" size="small" rounded text severity="danger" aria-label="Cancel" onClick={deleteFilterHistory} />
        </div>) : <></>}
        <Table
          isLoadingTable={isLoadingAllMasterSlots}
          isWithCheckBox
          selection={selectedDataCustomer as []}
          onSelectionChange={
            (e) => {
              const value = e.value as [];
              setSelectedDataCustomer(value);
            }}
          filter={search}
          headerTable={header}
          onDeleted={confirmDelete}
          onSearch={handleSearch}
          value={dataSlots as []}
          rows={totalRecords}
          paginationOptions={paginationOptions}
          isDeleteAction={canDelete}
          isDetailAction={canView}
          isEditAction={canEdit}
          filterHistory={filterHistory}
          filterContent={filterContent}
          handleClearFilter={handleClearFilter}
          handleDeleteFilterHistory={handleDeleteFilterHistory}
          onSubmitFilter={handleClickSubmitFilter}
        />
      </BaseContainer>
    </>
  );
};

export default MasterSlots;
