import { useLocation, useParams } from 'react-router-dom';
import { IAccountDetails, useGetAccount, useOrderDetail } from '@/services/rest/transaction';
import { useCallback, useMemo, useState } from 'react';
import { STATUS_TRANSACTION } from '@/constants';
import dayjs from 'dayjs';

interface ITrackingStatus {
  created_at: string;
  order_id: number;
  status: string
  updated_by: string
  account: IAccountDetails;
}

interface IOrder {
  created_at: string;
  status: string;
  account: string;
}

const useCustom = () => {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const poId = searchParams.get('po_id');
  const { data: dataOrderDetail, isLoading } = useOrderDetail(id);
  const [dataTrackorder, setDataTrackorder] = useState<IOrder[]>([]);
  const [displayTrackingOrder, setDisplayTrackingOrder] = useState(false);

  const ids = dataOrderDetail?.traking_status?.filter((f) => f.updated_by !== null).map((item) => item.updated_by);
  const { data: dataAccount, refetch: refetchAccount, isLoading: loadingAccount } = useGetAccount(ids);

  const sortByCreatedAt = (data: ITrackingStatus[]) => {
    data?.sort((curr, prev) => {
      const curretntDate = new Date(curr.created_at).getTime();
      const prevDate = new Date(prev.created_at).getTime();
      return curretntDate - prevDate;
    });

    return data;
  };

  const setNameForTrackingOrder = (data: IAccountDetails) => {
    if (data?.merchant_id) {
      return 'Seller';
    }

    if (data?.type === 'customer') {
      return 'Pembeli';
    }

    return 'Aladin Mall';
  };


  const trackingStatus = sortByCreatedAt(dataOrderDetail?.traking_status as []);
  const dataTracking = useCallback(() => {
    const data = dataOrderDetail;
    const temp = data?.traking_status?.map((item) => {
      const resultData = {
        id: dataAccount?.data?.find((a) => item.updated_by === a.id)?.id,
        type: dataAccount?.data?.find((a) => item.updated_by === a.id)?.type,
        first_name: dataAccount?.data?.find((a) => item.updated_by === a.id)?.first_name,
        last_name: dataAccount?.data?.find((a) => item.updated_by === a.id)?.last_name,
        merchant_id: dataAccount?.data?.find((a) => item.updated_by === a.id)?.merchant_id
      };
      return {
        created_at: item.created_at,
        status: item.status,
        account: setNameForTrackingOrder(resultData)
      };
    }) || [];
    setDataTrackorder(temp);
  }, [dataOrderDetail]);

  const handleTrackOrder = useCallback(() => {
    refetchAccount();
    setDisplayTrackingOrder(true);
    dataTracking();
  }, [dataOrderDetail]);

  const handleCloseDialog = useCallback(() => {
    refetchAccount();
    setDisplayTrackingOrder(false);
  }, []);

  const orderDate = useMemo(() => {
    const data = dataOrderDetail?.traking_status?.find((item) => item.status == STATUS_TRANSACTION.WAITING_PAYMENT);
    if (data) {
      return dayjs(data.created_at).format('ddd, DD MMM YYYY HH:mm:ss');
    }
    return '-';
  }, [dataOrderDetail]);

  return {
    data: {
      orderId: id,
      trackingStatus,
      dataOrderDetail,
      isLoading,
      poId,
      displayTrackingOrder,
      loadingAccount,
      dataTrackorder,
      orderDate
    },
    method: {
      setDisplayTrackingOrder,
      handleTrackOrder,
      handleCloseDialog
    }
  };
};

export default useCustom;
