import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { Database as DB_USER } from '@/types/services/user';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';

export type TeamMenu = DB_USER['public']['Tables']['team_menus']['Row']
export type Menu = DB_USER['public']['Tables']['menus']['Row']

const getTeamMenus = async (teamId: number) => {
  return await supabase
    .from(TABLE.TEAM_MENUS)
    .select('*')
    .eq('team_id', teamId) as PostgrestSingleResponse<TeamMenu[]>;
};

const getMenus = async (menuIds: number[]) => {
  return await supabase
    .from(TABLE.MENUS)
    .select('*').range(0, 30)
    .in('id', menuIds) as PostgrestSingleResponse<Menu[]>;
};

const getParentMenus = async (parentIds: number[]) => {
  return await supabase
    .from(TABLE.MENUS)
    .select('*').range(0, 30)
    .ilike('code', '%bos%')
    .in('id', parentIds) as PostgrestSingleResponse<Menu[]>;
};

export const useGetTeamMenus = (teamId: number) =>
  useQuery({
    queryKey: ['getTeamMenu'],
    queryFn: () => getTeamMenus(teamId),
    enabled: !!teamId,
    refetchOnWindowFocus: false
  });

export const useGetMenus = (menuIds: number[]) =>
  useQuery({
    queryKey: ['getMenus', menuIds],
    queryFn: () => getMenus(menuIds),
    enabled: !!(menuIds.length >= 1),
    refetchOnWindowFocus: false
  });

export const useGetParentMenus = (parentIds: number[]) =>
  useQuery({
    queryKey: ['getParentMenu', parentIds],
    queryFn: () => getParentMenus(parentIds),
    enabled: !!(parentIds.length >= 1),
    refetchOnWindowFocus: false
  });
