import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';
import { Checkbox } from 'primereact/checkbox';
import { useNavigate } from 'react-router-dom';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { useCustom } from './hooks';
import { Toast } from 'primereact/toast';
import ProductTab from '../Components/ProductTab';
import { TabPanel, TabView } from 'primereact/tabview';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import IconCancel from '@/assets/IconCancel';
import IconUpload from '@/assets/IconUpload';

const CreateCategory = () => {
  const { data, methods } = useCustom();
  const navigate = useNavigate();

  const uploadOptions = {
    icon: IconUpload,
    iconOnly: true,
    className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'
  };
  const cancelOptions = {
    icon: IconCancel,
    iconOnly: true,
    className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'
  };
  return (
    <BaseContainer>
      <ConfirmDialog
        visible={data.visible}
        onHide={() => methods.setVisible(false)}
        message="Are you sure? specifications and variants will be reset"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={methods.handleAcceptDialog}
        reject={() => methods.setVisible(false)}
      />
      <LoadingOverlay show={data.isLoading} />
      {/* Tab General */}
      <Toast ref={data.toast} />
      <TabView id="tab-category-product">
        <TabPanel header="General">
          <div>
            <MainTitle title="Create Category" nav="" />
            <div id="form_group">
              <div id="parent_input" className="mb-3 flex gap-8">
                <label htmlFor="parent" className="w-1/6 mt-2">
                  Parent Category*
                </label>
                <div id="value_parent" className="w-1/2">
                  <Dropdown
                    value={data.selectedParent}
                    options={data.dataParentID}
                    onChange={(e: DropdownChangeEvent) => methods.setSelectedParent(e.value)}
                    optionLabel="name" filter emptyFilterMessage="Parent Not Found"
                    placeholder="Select Parent"
                    className="w-full"
                    disabled={data.checked}
                  />
                  <div className="flex items-center gap-2 py-2">
                    <Checkbox
                      checked={data.checked}
                      value={data.checked}
                      onChange={() => methods.setChecked(!data.checked)}
                    />
                    <label className="cursor-pointer" htmlFor="not-have-parent">
                        No Parent
                    </label>
                  </div>
                </div>
              </div>
              <div id="name_input" className="mb-3 flex gap-8 items-center">
                <label htmlFor="name" className="w-1/6 font-bold">
                  Category Name*
                </label>
                <InputText
                  id="name"
                  className="w-1/2"
                  placeholder="Category name"
                  value={data.categoryName}
                  onChange={(e) => methods.setCategoryName(e.target.value)}
                />
              </div>
              <div id="status_input" className="mb-3 flex gap-8 items-center">
                <label htmlFor="isActive" className="w-1/6 font-bold">
                  Status
                </label>

                {/* is active */}
                <InputSwitch
                  id="isActive"
                  className="w-1/2"
                  checked={data.status}
                  onChange={(e) => methods.setStatus(!data.status)}
                />
              </div>
              <div id="description_input" className="mb-3 flex gap-8 items-center">
                <label htmlFor="description" className="w-1/6 font-bold">
                  Category Description
                </label>
                <InputTextarea
                  autoResize
                  className="w-1/2"
                  id="description"
                  placeholder="Description"
                  value={data.categoryDescription}
                  onChange={(e) => methods.setCategoryDescription(e.target.value)}
                />
              </div>
              <div id="image_input" className="mb-3 flex gap-8 items-center justify-between">
                <label htmlFor="media_url" className="w-1/6 font-bold">
                  Category Icon
                </label>
                <div className="w-full ml-11">
                  <FileUpload
                    ref={data.fileUploadRef}
                    id="media_url"
                    className="w-1/2"
                    accept="image/*"
                    uploadOptions={uploadOptions}
                    cancelOptions={cancelOptions}
                    emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>}
                    onSelect={(e) => {
                      methods.handleFileUpload(e);
                      data.formik.setFieldValue('maxImageCategory', e.files[0].size);
                    }}
                  />
                  {/* validation size image */}
                  {data.formik.errors['maxImageCategory'] && (
                    <small className="p-error mt-2">{data.formik.errors['maxImageCategory']}</small>
                  )}
                  <p className="mt-5">Recommended icon images no larger than 50KB, 1:1 ratio, file formats: JPG, GIF, SVG, PNG</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end py-2 pt-4 px-6 ">
            {data.seoPage ? <MinusIcon onClick={() => methods.setSeoPage(!data.seoPage)} className="w-10 cursor-pointer" /> : <PlusIcon onClick={() => methods.setSeoPage(!data.seoPage)} className="w-10 cursor-pointer" />}
          </div>
          {data.seoPage && (
            <div className="mt-2 flex flex-col gap-y-6">
              <h3 className="font-bold">Search Engine Optimization</h3>
              <hr />
              <div className="flex gap-x-4">
                <div className="grid grid-cols-2 w-2/3 gap-y-5">
                  <div>
                    <label className="font-bold" htmlFor="">URL Key*</label>
                  </div>
                  <InputText value={data.isFollowCategoryName ? data.categoryName.replace(/\s/g, '-') : (data.urlKey || undefined)} onChange={(e)=> methods.setUrlKey(e.target.value)} disabled={data.isFollowCategoryName} />
                  <div>
                    <label className="font-bold" htmlFor="">Meta Title*</label>
                  </div>
                  <InputText value={data.metaTitle} onChange={(e) => methods.setMetaTitle(e.target.value)} name="meta-title" />
                  <div>
                    <label className="font-bold" htmlFor="">Meta Keywords</label>
                  </div>
                  <InputTextarea value={data.metaKeywords || undefined} onChange={(e) => methods.setMetaKeywords(e.target.value)} />
                  <div>
                    <label className="font-bold" htmlFor="">Meta Description</label>
                  </div>
                  <InputTextarea value={data.metaDescription || undefined} onChange={(e) => methods.setMetaDescription(e.target.value)} />
                </div>
                <div>
                  <Checkbox checked={data.isFollowCategoryName} onChange={() => methods.setIsFollowCategoryName(!data.isFollowCategoryName)} /> <span>Follow Category Name</span>
                </div>
              </div>
            </div>
          )}
        </TabPanel>
        {/* Tab Product */}
        { data.idCreated.id && (
          <TabPanel header={`Product(${data.dataProduct.length})`} disabled={Boolean(!data.idCreated.id)}>
            <div>
              <MainTitle title="Product" nav="" />
              <ProductTab
                value={data.dataProduct}
                isLoading={data.isLoading}
              />
            </div>
          </TabPanel>
        )}
        {
          data.idCreated.id && (
            <TabPanel header="Attribute Set" disabled={Boolean(!data.idCreated.id)}>
              <div>
                <div id="container-attribute-set" className="flex gap-2 mb-4 mt-4 justify-center items-center">
                  <span className="w-[220px]">Add Attribute Set From: </span>
                  <Dropdown
                    id="dropdown-terapkan"
                    optionLabel="name" filter emptyFilterMessage="Atribute Set Not Found"
                    placeholder="Select Attribute Set"
                    className="w-3/6"
                    options={data.attributeSets}
                    onChange={(e: DropdownChangeEvent) => methods.handleSelectedAttributeSets(e)}
                    value={data.selectedAttributeSets} />
                  <div className="flex justify-center items-center w-1/6">
                    <Button
                      onClick={methods.handleClickApply}
                      id="button-terapkan"
                      className="w-full"
                      label="Apply"
                      style={{ textAlign: 'center' }} />
                  </div>
                </div>
                <hr />
                <div>
                  <div id="container-specification" className="mb-4 mt-4">
                    <span>1. Specification</span>
                    <div className="flex flex-col gap-y-2 ml-2 justify-start">
                      <span>Specifications from the Parent Category will be included in this category and cannot be changed</span>
                      {Array.isArray(data.parentSpecification) && (data.parentSpecification.map((item, index) => {
                        return (
                          <div className="flex gap-5" key={index}>
                            <Dropdown
                              id={`dropdown-specification-${index}`}
                              optionLabel="name" filter emptyFilterMessage="Specification Not Found"
                              optionValue="id"
                              placeholder="Select specification"
                              className="w-1/4" disabled
                              options={data.specifications}
                              onChange={(e: DropdownChangeEvent) => methods.selectSpecification(index, e.value)}
                              value={item?.id} />
                            {/* <Button icon="pi pi-trash" id={`button-hapus-specification-${index}`} onClick={() => methods.deleteSpecification(index)} /> */}
                          </div>
                        );
                      }))}
                      {Array.isArray(data.rangeSpecification) && (data.rangeSpecification.map((item, index) => {
                        return (
                          <div className="flex gap-5" key={index}>
                            <Dropdown
                              id={`dropdown-specification-${index}`}
                              optionLabel="name" filter emptyFilterMessage="Specification Not Found"
                              optionValue="id" placeholder="Select specification" className="w-1/4"
                              options={data.foundSpec.length > 0 ? data.foundSpec : data.specifications}
                              onChange={(e: DropdownChangeEvent) => methods.selectSpecification(index, e.value)}
                              onInput={(e: React.ChangeEvent<HTMLInputElement>)=> methods.findSpecificaiton(e.target.value)}
                              value={item?.id} filterBy="name" />
                            <Button icon="pi pi-trash" id={`button-hapus-specification-${index}`} onClick={() => methods.deleteSpecification(index)} />
                          </div>
                        );
                      }))}
                      <div className="flex justify-start">
                        <Button id="button-tambah-specification" onClick={methods.addSpecification} label="+ Add Specification" link />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div id="container-varian" className="mb-4 mt-4">
                    <span>2. Varian</span>
                    <div className="flex flex-col gap-y-2 ml-2 justify-start">
                      <span>Variants from the Parent Category will be included in this category and cannot be changed</span>
                      {Array.isArray(data.parentVariant) && (data.parentVariant.map((item, index) => {
                        return (
                          <div className="flex gap-5" key={index}>
                            <Dropdown
                              id={`dropdown-variant-${index}`}
                              optionLabel="name" filter emptyFilterMessage="Varian Not Found"
                              optionValue="id" placeholder="Select varian" className="w-1/4"
                              options={data.variants} filterBy="name"
                              onChange={(e: DropdownChangeEvent) => methods.selectVariant(index, e.value)}
                              value={item?.id} />
                            <Button icon="pi pi-trash" id={`button-hapus-variant-${index}`} onClick={() => methods.deleteVariant(index)} />
                          </div>
                        );
                      }))}
                      {Array.isArray(data.rangeVariant) && (data.rangeVariant.map((item, index) => {
                        return (
                          <div className="flex gap-5" key={index}>
                            <Dropdown
                              id={`dropdown-variant-${index}`}
                              optionLabel="name" filter emptyFilterMessage="Varian Not Found"
                              optionValue="id" placeholder="Select varian" className="w-1/4"
                              options={data.foundVariant.length > 0 ? data.foundVariant : data.variants}
                              onChange={(e: DropdownChangeEvent) => methods.selectVariant(index, e.value)}
                              onInput={(e: React.ChangeEvent<HTMLInputElement>)=> methods.findVariant(e.target.value)}
                              value={item?.id} filterBy="name" />
                            <Button icon="pi pi-trash" id={`button-hapus-variant-${index}`} onClick={() => methods.deleteVariant(index)} />
                          </div>
                        );
                      }))}
                      <div className="flex justify-start">
                        <Button id="button-tambah-variant" onClick={methods.addVariant} label="+ Add Varian" link />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          )
        }
      </TabView>
      <div id="buttons-action" className="w-full flex justify-end gap-6">
        <SubmitButton
          onCancel={() => {
            navigate('/category');
          }}
          onSubmit={()=> data.formik.handleSubmit()}
          disabledSubmit={data.isLoading}
          labelBack="Cancel"
          labelSubmit="Submit"
        />
      </div>
    </BaseContainer>
  );
};

export default CreateCategory;
