import { useCallback, useEffect, useRef, useState } from 'react';
import { supabase } from '@/lib/supabase';
import { PRODUCT_HIGHTLIGHT_SORT_TYPE, TABLE } from '@/constants';
import * as XLSX from 'xlsx';
import { FileUpload, FileUploadFilesEvent } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { IHistory, insertImportHistory } from '@/services/rest/importHistory';
import { useAuthStore } from '@/store/useAuthStore';
import { useFormik } from 'formik';
import { schemaCreateHighlight } from './validation';
import dayjs from 'dayjs';

declare interface Section {
  id: number;
  name: string;
}

declare interface Street {
  id: number;
  name: string;
}

declare interface Tabulation {
  id: number;
  name: string;
}

export interface ITemplate {
  product_id: string;
  merchant_id: string;
  sort_by: string;
  sort_value: string;
  valid_from: string;
  valid_to: string;
}

const initialSelected = {
  street: 0,
  section: 0,
  tabulation: 0
};

const initialFormik = {
  street: 0,
  section: 0,
  tabulation: 0,
  fileName: ''
};

const section: Section[] = [
  { id: 1, name: 'Product Pilihan' },
  { id: 2, name: 'Product Tabulation' },
  { id: 3, name: 'Product Hot Deal' },
  { id: 4, name: 'Official Store' }
];

const useCustom = () => {
  const headerProductHighlight = [
    'Product Id',
    'Merchant Id',
    'Sort By',
    'Sort Value',
    'Valid From',
    'Valid To'

  ];
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState<boolean[]>([]);
  const [ids, setIds] = useState<number[]>([]);
  const [street, setStreet] = useState<Street[]>([]);
  const [tabulation, setTabulation] = useState<Tabulation[]>([]);
  const [selected, setSelected] = useState(initialSelected);
  const [productUpload, setProductUpload] = useState<ITemplate[]>([]);
  const [fileName, setFileName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useRef<Toast>(null);
  const user = useAuthStore((state)=>state.user);
  const fileUploadRef = useRef<FileUpload>(null);
  const urlPath = window.location.href;
  const baseUrl = urlPath.split('/').slice(0, 5).join('/');

  const onClickDownload = useCallback(() => {
    const link = document.createElement('a');
    link.href = `${baseUrl}/Template.xlsx`;
    link.target = '_blank';
    link.download = '';
    link.click();
  }, [baseUrl]);

  const formik = useFormik({
    initialValues: initialFormik,
    validationSchema: schemaCreateHighlight,
    onSubmit: ()=>{
      onSaveHighlight();
    }
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const handleCheckBox = useCallback(
    (data: number) => () => {
      const listIds = ids;
      if (ids.includes(data)) {
        isActive[data] = false;
        const index = ids.findIndex((index) => index === data);
        listIds.splice(index, 1);
        setIds([...listIds]);
      } else {
        ids.push(data);
        setIds([...listIds]);
        isActive[data] = true;
      }
    },
    [ids, setIds, setIsActive, isActive]
  );

  const showUploaded = () => {
    setIsLoading(false);
    if (toast.current != null) {
      toast.current.show({
        severity: 'success',
        summary: 'Uploaded',
        detail: 'File Uploaded',
        life: 2500
      });
    }
  };
  const showFailed = (msg: string) => {
    setIsLoading(false);
    if (toast.current != null) {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: msg,
        life: 2500
      });
    }
  };

  const showSuccessToast = (msg:string) => {
    setIsLoading(false);
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: msg,
      life: 2500
    });
    navigate('/product-highlight');
  };

  const handleSelectFile = async (e: FileUploadFilesEvent) => {
    if (e.files) {
      const text = await e.files[0].arrayBuffer();
      const wb = XLSX.read(text, { type: 'binary', cellDates: true });
      const sn = wb.SheetNames[0];
      const ws = wb.Sheets[sn];
      const json = XLSX.utils.sheet_to_json<ITemplate>(ws, { raw: false, dateNF: 'dd-mm-yyy' });
      const option = { header: 1 };
      const sheet2 = XLSX.utils.sheet_to_json(ws, option);
      const header = sheet2.shift();
      const isHeaderValid = JSON.stringify(header) === JSON.stringify(headerProductHighlight);
      if (!isHeaderValid) {
        showFailed('File is not Same With Template, Please Check your file corectly');
        fileUploadRef.current?.clear();
        return;
      }
      setFileName(e.files[0].name);
      if (json.length > 1) {
        json.shift();
        showUploaded();
        formik.setFieldValue('fileName', e.files[0].name);
        setProductUpload(json as ITemplate[]);
      } else {
        fileUploadRef.current?.clear();
        setFileName('');
        showFailed('Data can not Empty');
      }
    }
  };
  const inputSelected = (key: string, value: number ) => {
    formik.setFieldValue(key, value);
    setSelected((prevState) => {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  const resetSelected = () => {
    setSelected(initialSelected);
  };

  const onSaveHighlight = useCallback(async () => {
    setIsLoading(true);
    const currentDate = dayjs().local().format('YYYY-MM-DD');
    if (selected.section === 1) {
      const payloadProd = await Promise.all(
        productUpload.map(async (item) => {
          const { data: dataProduct } = await supabase
            .from(TABLE.PRODUCT_VARIANTS)
            .select('product_id')
            .eq('id', item['Product Id'])
            .single();
          if (dataProduct && PRODUCT_HIGHTLIGHT_SORT_TYPE[item['Sort By']] === 'sequence') {
            return {
              product_id: dataProduct.product_id,
              product_variant_id: item['Product Id'],
              sort_type: PRODUCT_HIGHTLIGHT_SORT_TYPE[item['Sort By']],
              weight: item['Sort Value'],
              ended_time: dayjs(dayjs(item['Valid To']).format('YYYY-MM-DD') + ' 23:59:59').utc().format('YYYY-MM-DD HH:mm:ss'),
              started_time: dayjs(dayjs(item['Valid From']).format('YYYY-MM-DD') + ' 00:00:00').utc().format('YYYY-MM-DD HH:mm:ss'),
              is_active: currentDate >= dayjs(item['Valid From']).local().format('YYYY-MM-DD') &&
              currentDate <= dayjs(item['Valid To']).local().format('YYYY-MM-DD')
            };
          }
          if (dataProduct && PRODUCT_HIGHTLIGHT_SORT_TYPE[item['Sort By']] != 'sequence') {
            return {
              product_id: dataProduct.product_id,
              product_variant_id: item['Product Id'],
              sort_type: PRODUCT_HIGHTLIGHT_SORT_TYPE[item['Sort By']],
              ended_time: dayjs(dayjs(item['Valid To']).format('YYYY-MM-DD') + ' 23:59:59').utc().format('YYYY-MM-DD HH:mm:ss'),
              started_time: dayjs(dayjs(item['Valid From']).format('YYYY-MM-DD') + ' 00:00:00').utc().format('YYYY-MM-DD HH:mm:ss'),
              is_active: currentDate >= dayjs(item['Valid From']).local().format('YYYY-MM-DD') &&
              currentDate <= dayjs(item['Valid To']).local().format('YYYY-MM-DD')
            };
          }
        })
      );
      const errorImport: Array<string> = [];
      for (const [index, item] of payloadProd.entries()) {
        const { error: errProdSub } = await supabase.from(TABLE.PRODUCT_SUBSCRIPTION).insert(item);
        if (errProdSub) {
          errorImport.push(`Baris ke-${index+3}: `+ errProdSub.message);
        };
      }
      const payloadImportHistorySuccess = payloadProd.length - errorImport.length > 0 && {
        account_id: user?.id || '',
        file_path: fileName || '',
        row_number: payloadProd.length - errorImport.length,
        status: true,
        type: 'bulk_upload_product_pilihan'
      };

      const payloadImportHistoryError = errorImport.length>0&&{
        account_id: user?.id || '',
        file_path: fileName || '',
        errors: errorImport,
        row_number: errorImport.length,
        status: false,
        type: 'bulk_upload_product_pilihan'
      };
      const payloadImportHistory = [payloadImportHistorySuccess, payloadImportHistoryError].filter((item) => typeof item != 'boolean');
      await insertImportHistory(payloadImportHistory as IHistory[]);
      fileUploadRef.current?.clear();
      showSuccessToast('File Product Uploaded');
    }
    if (selected.section === 2) {
      const payloadTab = await Promise.all(
        productUpload.map(async (item) => {
          const { data: dataProduct } = await supabase
            .from(TABLE.PRODUCT_VARIANTS)
            .select('product_id')
            .eq('id', item['Product Id'])
            .single();
          if (dataProduct && PRODUCT_HIGHTLIGHT_SORT_TYPE[item['Sort By']] === 'sequence') {
            return {
              product_id: dataProduct.product_id,
              product_variant_id: item['Product Id'],
              product_tabulation_id: selected.tabulation,
              sort_type: PRODUCT_HIGHTLIGHT_SORT_TYPE[item['Sort By']],
              weight: item['Sort Value'],
              ended_time: dayjs(dayjs(item['Valid To']).format('YYYY-MM-DD') + ' 23:59:59').utc().format('YYYY-MM-DD HH:mm:ss'),
              started_time: dayjs(dayjs(item['Valid From']).format('YYYY-MM-DD') + ' 00:00:00').utc().format('YYYY-MM-DD HH:mm:ss'),
              is_active: currentDate >= dayjs(item['Valid From']).local().format('YYYY-MM-DD') &&
              currentDate <= dayjs(item['Valid To']).local().format('YYYY-MM-DD')
            };
          }
          if (dataProduct && PRODUCT_HIGHTLIGHT_SORT_TYPE[item['Sort By']] != 'sequence') {
            return {
              product_id: dataProduct.product_id,
              product_variant_id: item['Product Id'],
              product_tabulation_id: selected.tabulation,
              sort_type: PRODUCT_HIGHTLIGHT_SORT_TYPE[item['Sort By']],
              ended_time: dayjs(dayjs(item['Valid To']).format('YYYY-MM-DD') + ' 23:59:59').utc().format('YYYY-MM-DD HH:mm:ss'),
              started_time: dayjs(dayjs(item['Valid From']).format('YYYY-MM-DD') + ' 00:00:00').utc().format('YYYY-MM-DD HH:mm:ss'),
              is_active: currentDate >= dayjs(item['Valid From']).local().format('YYYY-MM-DD') &&
              currentDate <= dayjs(item['Valid To']).local().format('YYYY-MM-DD')
            };
          }
        }));
      const errorImport: Array<string> = [];
      for (const [index, item] of payloadTab.entries()) {
        const { error: errTabulation } = await supabase.from(TABLE.PRODUCT_TABULATION).insert(item);
        if (errTabulation) {
          errorImport.push(`Baris ke-${index+3}: `+ errTabulation.message);
        };
      }

      const payloadImportHistorySuccess = payloadTab.length - errorImport.length > 0 && {
        account_id: user?.id || '',
        file_path: fileName || '',
        row_number: payloadTab.length - errorImport.length,
        status: true,
        type: 'bulk_upload_product_tabulation'
      };

      const payloadImportHistoryError = errorImport.length>0&&{
        account_id: user?.id || '',
        file_path: fileName || '',
        errors: errorImport,
        row_number: errorImport.length,
        status: false,
        type: 'bulk_upload_product_tabulation'
      };
      const payloadImportHistory = [payloadImportHistorySuccess, payloadImportHistoryError].filter((item) => typeof item != 'boolean');
      await insertImportHistory(payloadImportHistory as IHistory[]);
      fileUploadRef.current?.clear();
      showSuccessToast('File Product Uploaded');
    }
    if (selected.section === 3) {
      const payloadHot = await Promise.all(
        productUpload.map(async (item) => {
          const { data: dataProduct } = await supabase
            .from(TABLE.PRODUCT_VARIANTS)
            .select('product_id')
            .eq('id', item['Product Id'])
            .single();
          if (dataProduct) {
            return {
              image: [],
              name: ' Product Hot Deal',
              type: 'PRODUCT_HOT_DEAL',
              status: currentDate >= dayjs(item['Valid From']).local().format('YYYY-MM-DD') &&
              currentDate <= dayjs(item['Valid To']).local().format('YYYY-MM-DD') ? 'ACTIVE' : 'INACTIVE',
              ended_time: dayjs(dayjs(item['Valid To']).format('YYYY-MM-DD') + ' 23:59:59').utc().format('YYYY-MM-DD HH:mm:ss'),
              started_time: dayjs(dayjs(item['Valid From']).format('YYYY-MM-DD') + ' 00:00:00').utc().format('YYYY-MM-DD HH:mm:ss'),
              product_id: dataProduct.product_id
            };
          }
        }));
      const errorImport: Array<string> = [];
      for (const [index, item] of payloadHot.entries()) {
        const { error: errHotDeal } = await supabase.from(TABLE.BANNER).insert(item);
        if (errHotDeal) {
          errorImport.push(`Baris ke-${index+3}: `+ errHotDeal.message);
        };
      }

      const payloadImportHistorySuccess = payloadHot.length - errorImport.length > 0 && {
        account_id: user?.id || '',
        file_path: fileName || '',
        row_number: payloadHot.length - errorImport.length,
        status: true,
        type: 'bulk_upload_product_hot_deal'
      };

      const payloadImportHistoryError = errorImport.length>0&&{
        account_id: user?.id || '',
        file_path: fileName || '',
        errors: errorImport,
        row_number: errorImport.length,
        status: false,
        type: 'bulk_upload_product_hot_deal'
      };
      const payloadImportHistory = [payloadImportHistorySuccess, payloadImportHistoryError].filter((item) => typeof item != 'boolean');
      await insertImportHistory(payloadImportHistory as IHistory[]);
      fileUploadRef.current?.clear();
      showSuccessToast('File Product Uploaded');
    }
    if (selected.section === 4) {
      const payloadOfficial = productUpload.map((item) => {
        if (PRODUCT_HIGHTLIGHT_SORT_TYPE[item['Sort By']] === 'sequence') {
          return {
            ended_time: dayjs(dayjs(item['Valid To']).format('YYYY-MM-DD') + ' 23:59:59').utc().format('YYYY-MM-DD HH:mm:ss'),
            merchant_id: item['Merchant Id'],
            product_variant_id: item['Product Id'],
            sort_type: PRODUCT_HIGHTLIGHT_SORT_TYPE[item['Sort By']],
            started_time: dayjs(dayjs(item['Valid From']).format('YYYY-MM-DD') + ' 00:00:00').utc().format('YYYY-MM-DD HH:mm:ss'),
            weight: item['Sort Value'],
            is_active: currentDate >= dayjs(item['Valid From']).local().format('YYYY-MM-DD') &&
            currentDate <= dayjs(item['Valid To']).local().format('YYYY-MM-DD')
          };
        }
        if (PRODUCT_HIGHTLIGHT_SORT_TYPE[item['Sort By']] != 'sequence') {
          return {
            ended_time: dayjs(dayjs(item['Valid To']).format('YYYY-MM-DD') + ' 23:59:59').utc().format('YYYY-MM-DD HH:mm:ss'),
            merchant_id: item['Merchant Id'],
            product_variant_id: item['Product Id'],
            sort_type: PRODUCT_HIGHTLIGHT_SORT_TYPE[item['Sort By']],
            started_time: dayjs(dayjs(item['Valid From']).format('YYYY-MM-DD') + ' 00:00:00').utc().format('YYYY-MM-DD HH:mm:ss'),
            is_active: currentDate >= dayjs(item['Valid From']).local().format('YYYY-MM-DD') &&
            currentDate <= dayjs(item['Valid To']).local().format('YYYY-MM-DD')
          };
        }
        return item;
      });
      const errorImport: Array<string> = [];
      for (const [index, item] of payloadOfficial.entries()) {
        const { error: erroOfficaial } = await supabase.from(TABLE.OFFICIAL_MERCHANT).insert(item);
        if (erroOfficaial) {
          errorImport.push(`Baris ke-${index+3}: `+ erroOfficaial.message);
        }
      }

      const payloadImportHistorySuccess = payloadOfficial.length - errorImport.length > 0 && {
        account_id: user?.id || '',
        file_path: fileName || '',
        row_number: payloadOfficial.length - errorImport.length,
        status: true,
        type: 'bulk_upload_product_official_store'
      };

      const payloadImportHistoryError = errorImport.length>0&&{
        account_id: user?.id || '',
        file_path: fileName || '',
        errors: errorImport,
        row_number: errorImport.length,
        status: false,
        type: 'bulk_upload_product_official_store'
      };
      const payloadImportHistory = [payloadImportHistorySuccess, payloadImportHistoryError].filter((item) => typeof item != 'boolean');
      await insertImportHistory(payloadImportHistory as IHistory[]);
      fileUploadRef.current?.clear();
      showSuccessToast('File Product Uploaded');
    }
    resetSelected();
  }, [selected, productUpload]);

  const getStreet = useCallback(async () => {
    const { data } = await supabase
      .from(TABLE.STREET)
      .select('id, name').limit(10)
      .order('id');
    setStreet(data as Street[]);
  }, []);

  const getTab = useCallback(async () => {
    const { data } = await supabase
      .from(TABLE.TABULATION)
      .select('id, name').limit(10)
      .order('id');

    setTabulation(data as Tabulation[]);
  }, []);

  useEffect(() => {
    getStreet();
    getTab();
  }, []);

  return {
    data: {
      isActive,
      selected,
      street,
      fileName,
      tabulation,
      section,
      toast,
      isLoading,
      formik,
      fileUploadRef
    },
    method: {
      onClickDownload,
      handleCheckBox,
      inputSelected,
      handleSelectFile,
      onSaveHighlight,
      isFormFieldValid
    }
  };
};

export default useCustom;
