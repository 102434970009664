import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { IPagination } from '@/types/pagination';
import { Database } from '@/types/services/catalogue';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';

export type AttributeSets = {
  id: number;
  name: string;
  description: string | null;
  updated_at?: string | null;
  created_at?: string | null;
  status?: string | null;
  specification_ids: Array<string>;
  variant_ids: Array<string>;
};

type Variants = Database['public']['Tables']['variants']['Row']
type Specification = Database['public']['Tables']['specifications']['Row']

export type PayloadAttributeSet = {
  id?: number | string;
  name: string | null;
  updated_at?: string | null;
  created_at?: string | null;
  status?: string | null;
  variant_ids: Array<string>;
  description?: string;
  specification_ids: Array<string>;
};

export interface IFilterAttribute {
  id_from: number | null;
  id_to: number | null;
  name: string;
  description: string;
  status: string;
};

const getAllAttributeSets = async (filter?: IFilterAttribute, search?: string, range?: IPagination) => {
  const query = supabase
    .from(TABLE.ATTRIBUTE_SETS)
    .select('*', { count: 'exact' })
    .order('id', { ascending: false })
    .range(
      range?.start || 0,
      range?.end || 10
    );

  if (filter) {
    // ID
    if (filter.id_from != null) {
      query.gte('id', filter.id_from);
    }

    if (filter.id_to != null) {
      query.lte('id', filter.id_to);
    }

    // Name
    if (filter.name != '') {
      query.ilike('name', `%${filter.name}%`);
    }

    // Description
    if (filter.description != '') {
      query.ilike('description', `%${filter.description}%`);
    }

    // Status
    if (filter.status != '') {
      query.eq('status', filter.status);
    }
  }

  if (search && search != '') {
    query.or(`name.ilike.%${search}%, description.ilike.%${search}%`);
  }

  return (await query) as PostgrestSingleResponse<AttributeSets[]>;
};

const getAllAttributeSetById = async (id?: string) => {
  return await supabase.from(TABLE.ATTRIBUTE_SETS).select('*').eq('id', id).single() as PostgrestSingleResponse<AttributeSets>;
};

const getAllAttributeSetName = async (name?:string, range: IPagination = { start: 0, end: 10 }) => {
  return await supabase.from(TABLE.ATTRIBUTE_SETS).select('name').neq('name', name).range(range.start, range.end) as PostgrestSingleResponse<AttributeSets[]>;
};

export const upsertAttributeSet = async (payload: PayloadAttributeSet) => {
  return await supabase.from(TABLE.ATTRIBUTE_SETS).upsert([payload]);
};

export const deleteAttributeSet =async (id:string) => {
  return await supabase.from(TABLE.ATTRIBUTE_SETS).delete().eq('id', id);
};

const getAttributeSetFilterOptions = async (range: IPagination = { start: 0, end: 10 }) => {
  return await supabase.from(TABLE.ATTRIBUTE_SETS).select('id,name').range(range.start, range.end) as PostgrestSingleResponse<AttributeSets[]>;
};

const getAllVariantByIds = async (id?: string[]) => {
  return (await supabase
    .from(TABLE.VARIANTS)
    .select('id, name, label')
    .eq('is_active', true)
    .in('id', id || [])
  ) as PostgrestSingleResponse<Variants[]>;
};

const getAllSpecificationByIds = async (id?: string[]) => {
  return (await supabase
    .from(TABLE.SPESIFICATION)
    .select('id, name, label')
    .is('deleted_at', null)
    .eq('is_active', true)
    .in('id', id || [])
  ) as PostgrestSingleResponse<Specification[]>;
};

const getTotalAttributeSet =async () => {
  const query = supabase
    .from(TABLE.ATTRIBUTE_SETS)
    .select('*', { count: 'exact', head: true })
    .limit(50);

  return await query;
};

export const useAttributeSetFilterOptions = (range: IPagination = { start: 0, end: 10 }) =>
  useQuery({
    queryKey: ['attributeset-filter', range],
    queryFn: () => getAttributeSetFilterOptions(range),
    refetchOnWindowFocus: false
  });

export const useAllAttributeSets = (filter?: IFilterAttribute, search?: string, range?: IPagination) =>
  useQuery({
    queryKey: ['attributeSets', filter, search, range],
    queryFn: () => getAllAttributeSets(filter, search, range),
    refetchOnWindowFocus: false
  });

export const useAllAttributeSetById = (id?: string) =>
  useQuery({
    queryKey: ['attributeSets', id],
    queryFn: () => getAllAttributeSetById(id),
    refetchOnWindowFocus: false,
    enabled: !!id
  });

export const useAllAttributeSetName = (name?:string) =>
  useQuery({
    queryKey: ['attributeSets', name],
    queryFn: ()=>getAllAttributeSetName(name),
    refetchOnWindowFocus: false
  });

export const useGetVariantsByid = (id?: string[]) =>
  useQuery({
    queryKey: ['variantsById', id],
    queryFn: () => getAllVariantByIds(id),
    enabled: !!id
  });

export const useGetSpecificationByid = (id?: string[]) =>
  useQuery({
    queryKey: ['specificationById', id],
    queryFn: () => getAllSpecificationByIds(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });

export const useTotalAttributeSet = () =>
  useQuery({
    queryKey: ['total-attribute-sets'],
    queryFn: getTotalAttributeSet,
    refetchOnWindowFocus: false
  });
