import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';


const useTabGroupInformation = () => {
  const navigate = useNavigate();

  const handleClickEdit = useCallback((id:number|null) => {
    navigate(`/user-management/${id}/edit-information`);
  }, []);
  return {
    data: {},
    method: {
      handleClickEdit
    }
  };
};

export default useTabGroupInformation;
