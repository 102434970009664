import useCustom from '@/hooks/FlashSaleManagement/AddProducts';
import ProductTable from '@/section/FlashSaleManagement/Table';

import { ChangeEvent, FC, KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { defaultValueFormMassUpdate } from '@/views/FlashSaleManagement/Create/CreateFlashSale';

import type { ITableDataProducts } from '@/views/FlashSaleManagement/Create/hooks';
import type { InputNumberValueChangeEvent } from 'primereact/inputnumber';
import type { IMassUpdateField, NullableNumber } from '@/views/FlashSaleManagement/Create/CreateFlashSale';
import type { ITime } from '@/views/FlashSaleManagement/type';

import DOMPurify from 'dompurify';

interface ISelectedProduct {
  indexProduct: number
  product: ITableDataProducts
}

interface IModalAddProduct {
  isSubmit: boolean
  flashSaleSlot: ITime[] | undefined
  onFinishedAddProduct: (products: ITableDataProducts[]) => void;
  onInvalid?: (isDisabled: boolean) => void;
  currentProducts: ITableDataProducts[]
  onOverwriteSequence: (newData: ITableDataProducts, index: number) => void
  minDate?: Date
  maxDate?: Date
}

const priceByOptions = ['Percentage', 'Amount'];

const ModalAddProduct: FC<IModalAddProduct> = ({
  isSubmit,
  flashSaleSlot,
  onFinishedAddProduct,
  onInvalid,
  currentProducts,
  onOverwriteSequence,
  minDate,
  maxDate
}) => {
  const files = {};

  const {
    data: { tableData, totalData, currentPage, perPage },
    method: { onPageChange, onSearchProduct }
  } = useCustom();

  const [products, setProducts] = useState<ITableDataProducts[]>([]);
  const [checkedProducts, setCheckedProducts] = useState<ITableDataProducts[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<ISelectedProduct[]>([]);
  const [search, setSearch] = useState('');
  const [formMassUpdate, setFormMassUpdate] = useState<IMassUpdateField>(
    { ...defaultValueFormMassUpdate }
  );

  const onRowEditComplete = (newData: ITableDataProducts, index: number, newSortValue: string) => {
    const _products = [...products];
    _products[index] = newData;
    _products[index]['Sort Value'] = newSortValue;

    setProducts(_products);
  };

  const _onPageChange = (first: number, rows: number) => {
    const _selectedProducts = selectedProducts.slice();
    for (const product of checkedProducts) {
      const index = products.findIndex((item) => item['Product Variant ID'] === product['Product Variant ID']);
      const indexProduct = (currentPage * perPage) + index;

      const indexOnSelectedProducts = _selectedProducts.findIndex((item) => item.indexProduct === indexProduct);

      if (indexOnSelectedProducts === -1) {
        _selectedProducts.push({ indexProduct, product });
      } else {
        _selectedProducts[indexOnSelectedProducts].product = product;
      }
    }
    setSelectedProducts(_selectedProducts);
    onPageChange(first, rows);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const handleKeyDownSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onFilter();
    }
  };
  const onFilter = () => {
    onSearchProduct(search);
  };

  const disabledMassUpdateButton = useMemo(() => {
    const isEmptyData = checkedProducts.length === 0;
    const isEmptyForm = JSON.stringify(formMassUpdate) === JSON.stringify(defaultValueFormMassUpdate);

    return isEmptyData || isEmptyForm;
  }, [formMassUpdate, checkedProducts]);

  const resetFormMassUpdate = () => {
    setFormMassUpdate({ ...defaultValueFormMassUpdate });
  };

  const onInputPriceBy = useCallback((e: DropdownChangeEvent) => {
    setFormMassUpdate((c) => ({
      ...c,
      priceBy: e.value
    }));
  }, []);

  const onInputPriceValue = useCallback((e: InputNumberValueChangeEvent) => {
    setFormMassUpdate((c) => ({
      ...c,
      priceValue: e.value as NullableNumber
    }));
  }, []);

  const onInputStock = useCallback((e: InputNumberValueChangeEvent) => {
    setFormMassUpdate((c) => ({
      ...c,
      stock: e.value as NullableNumber
    }));
  }, []);

  const checkedProductsIndex = useMemo(() => {
    return checkedProducts.map((item) => {
      const index = products.findIndex(
        (product) => product?.['Product Variant ID'] === item?.['Product Variant ID']
      );
      return index;
    });
  }, [checkedProducts, products]);

  const onMassUpdate = useCallback(() => {
    const _products = products.slice();

    const columnsPriceBy = document.querySelectorAll('.selectToEditTable td.rowPriceBy');
    const columnsPriceValue = document.querySelectorAll('.selectToEditTable td.rowPriceValue');
    const columnsFsStock = document.querySelectorAll('.selectToEditTable td.rowFsStock');

    for (const index of checkedProductsIndex) {
      if (!!formMassUpdate.priceBy) {
        const columnPriceBy = columnsPriceBy[index] as HTMLElement;
        const dropdown = columnPriceBy.querySelector('.dropdown-custom-trigger') as HTMLElement;

        const newContent = `${formMassUpdate.priceBy}${dropdown.innerHTML}`;
        const cleanContent = DOMPurify.sanitize(newContent);
        dropdown.innerHTML = cleanContent;

        _products[index]['Flash Sale Price By'] = formMassUpdate.priceBy;
      }

      if (!!formMassUpdate.priceValue) {
        const columnPriceValue = columnsPriceValue[index] as HTMLElement;
        const inputText = columnPriceValue.querySelector('input.p-inputtext') as HTMLInputElement;

        const valueText = new Intl.NumberFormat('id-ID').format(formMassUpdate.priceValue);
        inputText.value = valueText;

        _products[index]['Flash Sale Price Value'] = `${formMassUpdate.priceValue}`;
      }

      if (!!formMassUpdate.stock) {
        const columnFsStock = columnsFsStock[index] as HTMLElement;
        const inputText = columnFsStock.querySelector('input.p-inputtext') as HTMLInputElement;
        const currentStock = _products[index]['Stock'];
        let fsStock = formMassUpdate.stock;

        if (formMassUpdate.stock > currentStock) fsStock = currentStock;

        const valueText = new Intl.NumberFormat('id-ID').format(fsStock);
        inputText.value = valueText;

        _products[index]['Jumlah Stock Flash Sale'] = fsStock;
      }
    }

    // setCheckedProducts([]);
    resetFormMassUpdate();
    setProducts(_products);
  }, [products, formMassUpdate, checkedProductsIndex]);

  useEffect(() => {
    const _products = tableData.slice();
    for (const product of selectedProducts) {
      const index = _products.findIndex((item) => item['Product Variant ID'] === product.product['Product Variant ID']);
      if (index !== -1) {
        _products[index] = product.product;
        const columns = document.querySelectorAll('.selectToEditTable td.rowEditors');
        const column = columns[index] as HTMLElement;
        const button = column.querySelector('button.p-row-editor-init') as HTMLElement;
        button?.click();
      }
    }
    setProducts(_products);
  }, [tableData]);

  useEffect(() => {
    if (isSubmit) {
      // const _selectedProducts = selectedProducts.slice();
      // for (const product of checkedProducts) {
      //   const index = products.findIndex((item) => item['Product Variant ID'] === product['Product Variant ID']);
      //   if (index !== -1) {
      //     const columns = document.querySelectorAll('.selectToEditTable td.rowEditors');
      //     const column = columns[index] as HTMLElement;
      //     const button = column.querySelector('button.p-row-editor-save') as HTMLElement;
      //     button?.click();

      //     const indexProduct = (currentPage * perPage) + index;

      //     const indexOnSelectedProducts = _selectedProducts.findIndex((item) => item.indexProduct === indexProduct);

      //     if (indexOnSelectedProducts === -1) {
      //       _selectedProducts.push({ indexProduct, product: products[index] });
      //     } else {
      //       _selectedProducts[indexOnSelectedProducts].product = products[index];
      //     }
      //   }
      // }
      // setSelectedProducts(_selectedProducts);
      const _checkedProducts = checkedProducts.slice().map((item) => {
        const sortValue = item?.['Sort By'] === 'sequence' ? item?.['Sort Value'] : item?.['Sort By'];
        return {
          ...item,
          'Sort Value': sortValue
        };
      });
      onFinishedAddProduct(_checkedProducts);
    }
  }, [isSubmit]);

  return (
    <div>
      <div className="text-sm">
        <div className="flex items-center p-4 rounded-lg bg-white">
          <InputText
            className="w-60"
            placeholder="Product ID / Name"
            type="search"
            onInput={onSearch}
            onKeyDown={handleKeyDownSearch}
            value={search}
          />
          <div className="ml-4">
            <Button
              id="filter-table"
              label="Search"
              onClick={onFilter}
            />
          </div>
        </div>
        <br />
        <div className="p-4 rounded-lg bg-white">
          <div className="flex items-center gap-4 h-20">
            <div className="h-full flex flex-col justify-between">
              <p className="font-bold">UPDATE MASAL</p>
              <div className="h-[50px] flex items-center">
                <p className="text-gray-400">{checkedProducts.length} produk dipilih</p>
              </div>
            </div>
            <div className="h-full flex flex-col justify-between">
              <p>FS Price By</p>
              <Dropdown
                className="w-full"
                options={priceByOptions}
                onChange={onInputPriceBy}
                value={formMassUpdate.priceBy}
              />
            </div>
            <div className="h-full flex flex-col justify-between">
              <p>FS Price Value</p>
              <InputNumber
                className="w-full text-sm"
                style={{ fontSize: '14px' }}
                placeholder="Percentage/Amount"
                onValueChange={onInputPriceValue}
                value={formMassUpdate.priceValue}
                locale="id-ID"
              />
            </div>
            <div className="h-full flex flex-col justify-between">
              <p>Flash Sale Stock</p>
              <InputNumber
                className="w-full"
                onValueChange={onInputStock}
                value={formMassUpdate.stock}
                locale="id-ID"
              />
            </div>
            <div className="h-full flex flex-col justify-end">
              <Button
                label="Update Masal"
                outlined
                disabled={disabledMassUpdateButton}
                onClick={onMassUpdate}
              />
            </div>
          </div>
        </div>
        <br />
        {!!files && !!products && products.length > 0 && (
          <ProductTable
            data={products}
            selectedProduct={checkedProducts}
            onSelectProduct={setCheckedProducts}
            flashSaleSlot={flashSaleSlot}
            onRowEditComplete={onRowEditComplete}
            totalData={totalData}
            onPageChange={_onPageChange}
            currentPage={currentPage}
            perPage={perPage}
            includePrice
            isSelectToEdit
            disabledEditStatus
            onInvalid={onInvalid}
            currentProducts={currentProducts}
            onOverwriteSequence={onOverwriteSequence}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
      </div>
    </div>
  );
};

export default ModalAddProduct;
