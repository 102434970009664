import bannerBgFlashsale from '@/assets/banner-flash-sale-background.png';
import bannerBgVideo from '@/assets/banner-video-background.png';
import bannerPromoHeader from '@/assets/banner-promo-header.png';
import brandBannerLeft1 from '@/assets/brand-banner-left-1.jpg';
import brandBannerLeft2 from '@/assets/brand-banner-left-2.jpg';
import brandBannerLeft3 from '@/assets/brand-banner-left-3.jpg';
import brandBannerLeft4 from '@/assets/brand-banner-left-4.jpg';
import brandBannerRight1 from '@/assets/brand-banner-right-1.jpg';
import brandBannerRight2 from '@/assets/brand-banner-right-2.jpg';
import brandBannerRight3 from '@/assets/brand-banner-right-3.jpg';
import brandBannerRight4 from '@/assets/brand-banner-right-4.jpg';
import brandBanner from '@/assets/brand-banner-1.jpg';
import bannerSquareCarousel from '@/assets/banner-square-carousel.jpg';
import bannerPromoAds from '@/assets/banner-promo-ads.png';
import bannerMain from '@/assets/banner-main.png';
import bannerPromoPage from '@/assets/banner-promo-page.jpg';
import bannerHotDeals from '@/assets/banner-hot-deals.png';
import bannerFlashsale from '@/assets/banner-flash-sale.jpg';
import bannerCategory1 from '@/assets/banner-category-1.jpg';
import bannerCategory2 from '@/assets/banner-category-2.jpg';
import bannerStreet from '@/assets/banner-street.jpg';
import bannerPopup from '@/assets/banner-popup.jpg';

const brandBannerLeft = [
  brandBannerLeft1,
  brandBannerLeft2,
  brandBannerLeft3,
  brandBannerLeft4
];

const brandBannerRight = [
  brandBannerRight1,
  brandBannerRight2,
  brandBannerRight3,
  brandBannerRight4
];

const bannerCategory = [
  bannerCategory1,
  bannerCategory2
];

export {
  bannerBgFlashsale,
  bannerBgVideo,
  bannerPromoHeader,
  brandBannerLeft,
  brandBannerRight,
  brandBanner,
  bannerSquareCarousel,
  bannerPromoAds,
  bannerMain,
  bannerPromoPage,
  bannerHotDeals,
  bannerFlashsale,
  bannerCategory,
  bannerStreet,
  bannerPopup
};
