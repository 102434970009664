import { TabMenu } from 'primereact/tabmenu';
import useDetailCustomer from '../../DetailHooks';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import LoadingOverlay from '@/components/base/LoadingOverlay';

const CustomerView = ({ items, tabMenuActiveIndex, setTabMenuActiveIndex }) => {
  const { data: { fieldAccountInfo, isLoading } } = useDetailCustomer();
  const column = [
    { field: 'website', header: 'Website' },
    { field: 'balance', header: 'Balance' },
    { field: 'currency_ammount', header: 'Currency Ammount' },
    { field: 'reward_point_threshold', header: 'Reward Point Threshold' },
    { field: 'reward_ponts_cap', header: 'Reward Ponts Cap' }
  ];
  return (
    <>
      <LoadingOverlay show={isLoading}/>
      <TabMenu id="customer-tab-menu-view" model={items} activeIndex={tabMenuActiveIndex} onTabChange={(e) => setTabMenuActiveIndex(e.index)}/>
      {tabMenuActiveIndex === 0 && (
        <>
          <div className="p-8 w-full space-y-8">
            <div className="flex gap-x-28">
              <label className="w-[150px]" htmlFor="label-last-login">Last Logged In</label>
              <label className="font-bold" htmlFor="label-value">{fieldAccountInfo.last_sign_in}</label>
            </div>
            <div className="flex gap-x-28">
              <label className="w-[150px]" htmlFor="label-account-lock">Account Lock</label>
              <label className="font-bold" htmlFor="label-value">{fieldAccountInfo.status === true && 'Unlocked' || 'Locked'}</label>
            </div>
            <div className="flex gap-x-28">
              <label className="w-[150px]" htmlFor="label-confirmed-email">Confirmed Email</label>
              <label className="font-bold" htmlFor="label-value">{fieldAccountInfo.email}</label>
            </div>
            <div className="flex gap-x-28">
              <label className="w-[150px]" htmlFor="label-account-created">Account Created</label>
              <label className="font-bold" htmlFor="label-value">{fieldAccountInfo.created_at as string}</label>
            </div>
            <div className="flex gap-x-28">
              <label className="w-[150px]" htmlFor="label-account-created-in">Account Created In</label>
              <label className="font-bold" htmlFor="label-value">Frontstore</label>
            </div>
            <div className="flex gap-x-28">
              <label className="w-[150px]" htmlFor="label-customer-group">Customer Group</label>
              <label className="font-bold" htmlFor="label-value">{fieldAccountInfo.members}</label>
            </div>
            <div className="w-full mt-5">
              <label className="font-bold" htmlFor="label">Default Shipping Address</label>
            </div>
            <div id="address">{fieldAccountInfo.address}</div>
          </div>
        </>
      )}
      {tabMenuActiveIndex === 1 && (
        <BaseContainer>
          <DataTable id="customer-table" emptyMessage="We couldn't find any records">
            {column.map((item, index) => <Column key={index} header={item.header}/>)}
          </DataTable>
        </BaseContainer>)}
    </>
  );
};

export default CustomerView;

