import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { useCategorySpec } from '@/services/rest/specification';
import { useCategoryVariants } from '@/services/rest/variant';
import { concat, uniqBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

export interface IDetailCategory {
  description: string;
  id: number;
  isSelectable: boolean | null;
  name: string;
  position: number | null;
  parent_id: number | null;
  status: boolean;
  metaTitle: string;
  metaKeyword?: string | null;
  metaDescription?: string | null;
  slug?: string | null;
  mediaUrl: string | null;
  images: {
    data: {
      image: string;
    };
  };
  count: number;
}
interface IProducts {
  id: string;
  name: string;
  sku: string;
  status: string;
  main_price: number;
  merchantId: string;
  category_id: number;
}
interface EIProduct extends IProducts {
  specialPrice: number;
}
interface ISpecificationID {
  id: number;
  name?: string;
}
interface IVariantID {
  id: number;
  name?: string;
}
interface IParentID {
  id: number;
  name: string;
}

export const header = [
  { field: 'name', header: 'Name' },
  { field: 'position', header: 'Position' },
  { field: 'parent_id', header: 'Parent' },
  { field: 'status', header: 'Status' },
  { field: 'media_url', header: 'Image' }
];

export const useCustom = () => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [seoPage, setSeoPage] = useState<boolean>(true);
  const [urlKey, setUrlKey] = useState<string>('');
  const [isFollowCategoryName, setIsFollowCategoryName] = useState<boolean>(true);
  const [metaTitle, setMetaTitle] = useState<string>('');
  const [metaKeywords, setMetaKeywords] = useState<string>('');
  const [metaDescription, setMetaDescription] = useState<string>('');
  const [rangeSpecification, setRangeSpecification] = useState<ISpecificationID[]>([]);
  const [rangeVariant, setRangeVariant] = useState<IVariantID[]>([]);
  const [selectedDataParentID, setSelectedDataParentID] = useState<IParentID>();
  const [subParentIds, setSubParentIds] = useState<Array<number>>([]);
  const [childIds, setChildIds] = useState<Array<number>>([]);
  const [isLoadingProductParent, setIsLoadingProductParent] = useState<boolean>(false);
  const [isLoadingProductSubParent, setIsLoadingProductSubParent] = useState<boolean>(false);
  const [isLoadingProductChild, setIsLoadingProductChild] = useState<boolean>(false);
  const [detailCategory, setDetailCategory] = useState<IDetailCategory>({
    description: '',
    id: 0,
    images: { data: { image: '' } },
    isSelectable: false,
    name: '',
    position: 0,
    parent_id: 0,
    status: false,
    mediaUrl: '',
    metaTitle: '',
    slug: '',
    count: 0
  });

  // data product parent
  const [dataProduct, setDataProduct] = useState<EIProduct[]>([]);

  // data product subparent
  const [dataProductSubParent, setDataProductSubParent] = useState<EIProduct[]>([]);

  // data product children
  const [dataProductChildren, setDataProductChildren] = useState<EIProduct[]>([]);

  // data all product category
  const dataAllProduct = useMemo(() => {
    const concatArr = concat(dataProduct, dataProductSubParent, dataProductChildren);
    return concatArr;
  }, [dataProduct, dataProductSubParent, dataProductChildren]);

  const isLoadingProduct = isLoadingProductParent || isLoadingProductSubParent || isLoadingProductChild;


  const getDetailCategoryById = useCallback(async () => {
    setIsLoading(true);
    const { data, error } = await supabase.from(TABLE.CATEGORIES).select('*').eq('id', params.detailId).single();
    const { data: allCategory } = await supabase.rpc('catalogue.get_all_categories');

    const dataCategory = allCategory?.flatMap((i)=> {
      const children = i.child?.flatMap((c)=> [c, ...c?.child]);
      return [i, ...children];
    }).find((cat)=> cat.id === data.id);

    if (error) {
      return;
    }
    if (data as IDetailCategory) {
      setDetailCategory({
        description: data.description,
        id: data.id,
        images: data.images,
        isSelectable: data.is_selectable,
        name: data.name,
        position: data.position,
        parent_id: data.parent_id,
        status: data.status === 'ACTIVE',
        mediaUrl: data.media_url,
        metaTitle: data.meta_title,
        metaKeyword: data.meta_keyword,
        metaDescription: data.meta_description,
        slug: data.slug,
        count: Number(dataCategory?.count) || 0
      });
    }

    setIsLoading(false);
  }, []);

  const getSubParentCategoryId = useCallback( async (parentId: number) => {
    const { data } = await supabase.from(TABLE.CATEGORIES).select('id').eq('parent_id', parentId);

    const subParentIds = data?.map((it) => it.id);

    setSubParentIds(subParentIds as number[]);
  }, []);

  const getChildCategoryId = useCallback( async (subParentIds: number[]) => {
    const { data } = await supabase.from(TABLE.CATEGORIES).select('id').in('parent_id', subParentIds);

    const childIds = data?.map((it) => it.id);
    setChildIds(childIds as number[]);
  }, []);

  const getProducts = async () => {
    setIsLoadingProductParent(true);
    const query = supabase
      .from(TABLE.PRODUCT)
      .select('*, product_variants(special_price, price, saleable_stock)')
      .eq('product_variants.is_primary_variant', true)
      .eq('category_id', detailCategory.id);

    const { data, error } = await query;
    if (error) {
      setIsLoadingProductParent(false);
      return;
    }

    const newData = data.map((item) => {
      return {
        id: item?.id,
        name: item?.name,
        sku: item?.sku,
        stok: item?.product_variants[0]?.saleable_stock,
        status: item?.status,
        main_price: item?.product_variants[0]?.price,
        merchantId: item.merchant_id,
        category_id: item.category_id,
        specialPrice: item.product_variants.map((item) => item.special_price)[0]
      };
    });
    setDataProduct(newData as EIProduct[]);
    setIsLoadingProductParent(false);
  };

  const getProductSubParent = async (categoryIds: number[]) => {
    setIsLoadingProductSubParent(true);
    const query = supabase
      .from(TABLE.PRODUCT)
      .select('*, product_variants(special_price, price, saleable_stock)')
      .eq('product_variants.is_primary_variant', true)
      .in('category_id', categoryIds);

    const { data, error } = await query;
    if (error) {
      setIsLoadingProductSubParent(false);
      return;
    }

    const newData = data.map((item) => {
      return {
        id: item?.id,
        name: item?.name,
        sku: item?.sku,
        stok: item?.product_variants[0]?.saleable_stock,
        status: item?.status,
        main_price: item?.product_variants[0]?.price,
        merchantId: item.merchant_id,
        category_id: item.category_id,
        specialPrice: item.product_variants.map((item) => item.special_price)[0]
      };
    });

    const uniqProduct = uniqBy(newData, 'id');

    setDataProductSubParent(uniqProduct as EIProduct[]);
    setIsLoadingProductSubParent(false);
  };

  const getProductChild = async (categoryIds: number[]) => {
    setIsLoadingProductChild(true);
    const query = supabase
      .from(TABLE.PRODUCT)
      .select('*, product_variants(special_price, price, saleable_stock)')
      .eq('product_variants.is_primary_variant', true)
      .in('category_id', categoryIds);

    const { data, error } = await query;
    if (error) {
      setIsLoadingProductChild(false);
      return;
    }

    const newData = data.map((item) => {
      return {
        id: item?.id,
        name: item?.name,
        sku: item?.sku,
        stok: item?.product_variants[0]?.saleable_stock,
        status: item?.status,
        main_price: item?.product_variants[0]?.price,
        merchantId: item.merchant_id,
        category_id: item.category_id,
        specialPrice: item.product_variants.map((item) => item.special_price)[0]
      };
    });

    const uniqProduct = uniqBy(newData, 'id');

    setDataProductChildren(uniqProduct as EIProduct[]);
    setIsLoadingProductChild(false);
  };

  const getAllParentId = async () => {
    setIsLoading(true);
    const { data } = await supabase.from(TABLE.CATEGORIES)
      .select('id, name').eq('id', detailCategory.parent_id)
      .single();
    if (data) {
      setSelectedDataParentID(data as IParentID);
      setIsLoading(false);
    }
  };
  const { data: dataSpec } = useCategorySpec(Number(params.detailId));
  const { data: parentSpect } = useCategorySpec(detailCategory.parent_id as number);
  const parentCategorySpec = useMemo(()=> {
    if (!Array.isArray(parentSpect?.data)) return [];
    const parentSpecification = parentSpect?.data.map((items)=>{
      return {
        id: items.specifications?.id as number,
        name: items.specifications?.name as string
      };
    });
    return parentSpecification;
  }, [parentSpect, detailCategory]);
  const categorySpecification = ()=> {
    if (dataSpec?.data) {
      const categorySpec = dataSpec.data.map((item)=>{
        return {
          id: item.specifications?.id as number,
          name: item.specifications?.name as string
        };
      });

      if (parentCategorySpec && parentCategorySpec.length > 0) {
        const parentSpecIds = parentCategorySpec?.map((i) => i.id);
        const filterSpec = categorySpec.filter((it) => !parentSpecIds.includes(it.id));
        setRangeSpecification(filterSpec);
        return;
      }

      setRangeSpecification(categorySpec);
    }
  };
  const { data: dataVariant } = useCategoryVariants(Number(params.detailId));
  const { data: dataParentVars } = useCategoryVariants(Number(detailCategory.parent_id as number));
  const parentCategoryVars = useMemo(() => {
    if (!Array.isArray(dataParentVars?.data)) return [];
    const parentCategoryVars = dataParentVars?.data.map((item)=>{
      return {
        id: item.variants?.id as number,
        name: item.variants?.name as string
      };
    });
    return parentCategoryVars;
  }, [dataParentVars, detailCategory]);
  const categoryVariant = ()=> {
    if (dataVariant?.data) {
      const categoryVars = dataVariant.data.map((item)=>{
        return {
          id: item.variants?.id as number,
          name: item.variants?.name as string
        };
      });

      if (parentCategoryVars && parentCategoryVars.length > 0) {
        const parentVarsIds = parentCategoryVars?.map((i) => i.id);
        const filterVars = categoryVars.filter((it) => !parentVarsIds.includes(it.id));
        setRangeVariant(filterVars);
        return;
      }

      setRangeVariant(categoryVars);
    }
  };
  useEffect(() => {
    getDetailCategoryById();
  }, []);

  useEffect(() => {
    if (detailCategory.id) {
      getSubParentCategoryId(detailCategory.id);
    }
  }, [detailCategory.id]);

  useEffect(() => {
    categorySpecification();
  }, [params.detailId, dataSpec, parentCategorySpec]);

  useEffect(() => {
    categoryVariant();
  }, [params.detailId, dataVariant, parentCategoryVars]);

  useEffect(() => {
    if (detailCategory.parent_id) {
      getAllParentId();
    }
  }, [detailCategory, params.detailId]);


  useEffect(() => {
    if (detailCategory.id) {
      getProducts();
    }
  }, [detailCategory.id]);

  useEffect(() => {
    if (subParentIds.length > 0) {
      getProductSubParent(subParentIds);
    }
  }, [subParentIds]);

  useEffect(() => {
    if (subParentIds.length > 0) {
      getChildCategoryId(subParentIds);
    }
  }, [subParentIds]);


  useEffect(() => {
    if (childIds.length > 0) {
      getProductChild(childIds);
    }
  }, [childIds]);


  return {
    data: {
      isLoading,
      detailCategory,
      selectedDataParentID,
      seoPage,
      urlKey,
      metaDescription,
      metaKeywords,
      metaTitle,
      dataProduct,
      rangeSpecification,
      parentCategoryVars,
      parentCategorySpec,
      rangeVariant,
      isFollowCategoryName,
      dataAllProduct,
      isLoadingProductParent,
      isLoadingProductSubParent,
      isLoadingProductChild,
      isLoadingProduct
    },
    methods: {
      setSeoPage,
      setUrlKey,
      setMetaDescription,
      setMetaKeywords,
      setMetaTitle,
      setIsFollowCategoryName
    }
  };
};
