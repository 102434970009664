import { KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  IDropdownPrimeReact,
  IFormEditPromoTaggingCreate,
  IMerchantSupabase,
  IMetaProductVariant,
  IMetaStore,
  INewProduct,
  IProductOptions,
  IProductSupabase,
  IProductVariant,
  IProductVariantSupabase,
  IStore,
  IStoreOptions,
  ITagMetadataNewProduct,
  ITagMetadataStoreRecomendation,
  ITopKeywordList
} from '../type';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { optionCategoryTags } from '../helpers';
import { supabase } from '@/lib/supabase';
import { TABLE } from '@/constants';
import { useMerchatns, useProduct, usePromos, useStreet } from '@/services/rest/banner';
import { IBannerOptions } from '@/views/BannerManagement/CreateEditBanner/hooks';
import { useFormik } from 'formik';
import { schemaCreatePromoTagging } from './validation';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { useDebounce } from 'primereact/hooks';
import { debounce, uniqBy } from 'lodash';
import { PostgrestMaybeSingleResponse } from '@supabase/supabase-js';

export const usePromoTaggingCreatePromo = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [productVariant, setProductVariant] = useState<IProductVariant[]>([]);
  const [selectedStreet, setSelectedStreet] = useState<[]>([]);
  const [selectedCategory, setSelectedCategory] = useState('topKeywordList');
  const [search, debounceSearch, setSearch] = useDebounce('', 500);
  const [validate, setValidate] = useState(false);
  const [searchEnter, setSearchEnter] = useState('');
  const [dataProductVariants, setDataProductVariants] = useState<IProductVariant[]>([]);
  const [dataStores, setDataStores] = useState<IStore[]>([]);
  const [dataStoresDirectTo, setDataStoresDirectTo] = useState<IStoreOptions[]>([]);
  const [dataProductsDirectTo, setDataProductsDirectTo] = useState<IProductOptions[]>([]);
  const [dataPromoDirectTo, setDataPromoDirectTo] = useState<IPromoTagOptions[]>([]);
  const [formEditPromoTagging, setFormEditPromoTagging] = useState<IFormEditPromoTaggingCreate>({
    id: '',
    category: {} as IDropdownPrimeReact, // validated
    type: {} as IDropdownPrimeReact, // validated
    name: '', // validated
    description: '', // validated
    tag_metadata: {} as ITagMetadataNewProduct | {} as ITagMetadataStoreRecomendation,
    action: {} as IDropdownPrimeReact,
    url: '',
    directTo: '',
    merchantId: { code: '', label: '' },
    productId: { code: '', label: '' },
    promoDetailId: { code: '', label: '' },
    landingPageType: '',
    showDescription: {} as IDropdownPrimeReact,
    valid_from: '',
    valid_to: '',
    street: [] as IDropdownPrimeReact[],
    status: {} as IDropdownPrimeReact
  });

  interface IPromoTagOptions {
    label: string | null;
    code: string | null;
  }

  const initialValueFormik = {
    selectedCategory: selectedCategory,
    name: '',
    valid_from: '',
    valid_to: '',
    street: '',
    status: '',
    directTo: '',
    productId: '',
    merchantId: '',
    promoDetailId: '',
    action: '',
    landingPageType: '',
    selectedItem: []
  };

  const optionDirectTo: IPromoTagOptions[] = [
    {
      label: 'Store Detail',
      code: 'STORE_DETAIL'
    },
    {
      label: 'PDP',
      code: 'PDP'
    },
    {
      label: 'Landing Page',
      code: 'LANDING_PAGE'
    },
    {
      label: 'Promo Detail Page',
      code: 'PROMO_DETAIL_PAGE'
    }
  ];

  const optionKeywordRecomendations: IPromoTagOptions[] = [
    {
      label: 'Landing Page',
      code: 'LANDING_PAGE'
    },
    {
      label: 'PLP',
      code: 'PLP'
    }
  ];

  const [selected, setSelected] = useState<IProductVariant[] | IStore[]>([]);
  // for temporary state selected
  const [tempSelected, setTempSelected] = useState<IProductVariant[] | IStore[]>([]);
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const [perPage, setPerPage] = useState<number>(10);
  const [totalProduct, setTotalProduct] = useState<number>(50);
  const [totalStore, setTotalStore] = useState<number>(50);

  const [paginator, setPaginator] = useState({
    currentPage: 1,
    range: {
      start: 0,
      end: perPage - 1
    }
  });

  const handleClickCancel = useCallback(()=>{
    setIsLoading(false);
    navigate('/promo-tagging');
  }, []);

  const handleOnchangeCategory = useCallback((value: string) => {
    formik.setFieldValue('selectedCategory', value);
    setSelectedCategory(value);
    setSelected([]);
  }, []);

  const handleOnEnter = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        setSearchEnter(search);
      }
    },
    [search]
  );

  const handleSearch = useCallback((value: string) => {
    setSearch(value);
  }, []);

  const showSuccessToast = () => {
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: 'New Promo Tagging successfully created',
      life: 2000
    });

    setTimeout(() => {
      setIsLoading(false);
      navigate('/promo-tagging');
    }, 1500);
  };

  const showFailedToast = (message?: string) => {
    toast.current?.show({
      severity: 'error',
      summary: 'Failed',
      detail: message || 'Failed Create Promo Tagging',
      life: 2000
    });
    setIsLoading(false);
  };
  const arrayProduct: IProductVariant[] = useMemo(() => [], []);
  const getProdVars = async (start: number, end: number, search?: string) => {
    const query = supabase.from(TABLE.PRODUCT_VARIANTS)
      .select('id, products(name),merchants(name)')
      .eq('is_primary_variant', true)
      .eq('is_active', true);
    let counter: number = 0;
    if (search && search.length >= 3) {
      const { data: prodIds, count: prodCount } = await supabase.from(TABLE.PRODUCT)
        .select('id', { count: 'exact' }).eq('status', 'APPROVED').eq('is_active', true)
        .ilike('name', `%${search}%`).range(start, end);
      if (prodIds) {
        const ids = prodIds.map((item)=> item.id).filter((item)=> item != null);
        query.in('product_id', ids);
        counter = Number(prodCount);
        const { data: prodVar } = await query as PostgrestMaybeSingleResponse<IProductVariantSupabase[]>;
        if (prodVar) {
          const prodVariants = prodVar.map((item) => {
            return {
              id: String(item.id),
              product: (item.products as IProductSupabase)?.name,
              store: (item.merchants as IMerchantSupabase)?.name
            };
          });
          const total = counter;
          setTotalProduct(total);
          setDataProductVariants(prodVariants);
        }
      }
    } else {
      query.eq('products.status', 'APPROVED').
        eq('products.is_active', true);
      let rangeStart = 0;
      let rangeEnd = 9;
      while (arrayProduct.length < perPage*5 + end) {
        const { data: prodVar } = await query.range(rangeStart, rangeEnd) as PostgrestMaybeSingleResponse<IProductVariantSupabase[]>;
        if (prodVar) {
          const prods = prodVar.map((item)=> {
            return {
              id: String(item.id),
              product: (item.products as IProductSupabase)?.name,
              store: (item.merchants as IMerchantSupabase)?.name
            };
          }).filter((item)=> item.product != null);
          arrayProduct.push(...prods);
        }
        rangeStart += perPage;
        rangeEnd += perPage;
      }
      const prodVariants = arrayProduct.slice(start, end+1);
      const total = arrayProduct.length;
      setTotalProduct(total);
      setDataProductVariants(prodVariants);
    }
  };
  const getStore = async (start: number, end: number, search?: string) => {
    const query = supabase.from(TABLE.MERCHANTS)
      .select('id, name', { count: 'exact' })
      .range(start, end);
    if (search && search != '') {
      query.ilike('name', `%${search}%`);
    }
    const { data: stores, count: merchantCount } = await query;
    if (stores && merchantCount) {
      const merchants = stores.map((item) => {
        return {
          id: item?.id,
          store: item?.name
        };
      });
      setTotalStore(merchantCount);
      setDataStores(merchants);
    } else {
      setTotalStore(0);
      setDataStores([]);
    }
  };

  const totalRecords = useMemo(() => {
    const total = selectedCategory === 'newProduct' ? totalProduct : totalStore;
    return total || 0;
  }, [totalProduct, totalStore]);

  const totalPages = useMemo(() => {
    return Math.ceil(totalRecords / perPage);
  }, [totalRecords, perPage]);

  const onChangeFormEditPromotagging = (key: string, value: string | number | null) => {
    formik.setFieldValue(key, value?.toString());
    setFormEditPromoTagging((prevState) => {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  const handleOnChangeDirectTo = useCallback(() => {
    setFormEditPromoTagging((prevState) => {
      return {
        ...prevState,
        productId: { code: '', label: '' },
        merchantId: { code: '', label: '' },
        promoDetailId: { code: '', label: '' }
      };
    });
  }, []);

  const findProductsDebounce = debounce(async (findName: string | null) => {
    if (!findName) return;

    const { data } = await supabase.from(TABLE.PRODUCT).select('id, name')
      .eq('status', 'APPROVED').eq('is_active', true).ilike('name', `%${findName}%`).limit(10);

    if (data && data.length > 0) {
      const dataProducts = data.map((it) => {
        return {
          code: it.id,
          label: it.name
        };
      });
      const concatData = uniqBy([...dataProductsDirectTo || [], ...dataProducts], 'code');
      setDataProductsDirectTo(concatData);
    }
  }, 300);

  const findProducts = async (findName: string | null) => {
    findProductsDebounce(findName);
  };

  const optionProducts = useMemo(() => {
    return dataProductsDirectTo;
  }, [dataProductsDirectTo]);

  const findStoresDebounce = debounce(async (findName: string | null) => {
    if (!findName) return;

    const { data } = await supabase.from(TABLE.MERCHANTS).select('id, name').ilike('name', `*${findName}*`).limit(10);

    if (data && data.length > 0) {
      const dataStores = data.map((it) => {
        return {
          code: it.id,
          label: it.name
        };
      });
      const concatData = uniqBy([...dataStoresDirectTo || [], ...dataStores], 'code');
      setDataStoresDirectTo(concatData);
    }
  }, 300);

  const findStores = async (findName: string | null) => {
    findStoresDebounce(findName);
  };

  const optionStores = useMemo(() => {
    return dataStoresDirectTo;
  }, [dataStoresDirectTo]);

  const findPromosDebounce = debounce(async (findName: string | null) => {
    if (!findName) return;

    const { data } = await supabase.from(TABLE.PROMOS).select('id, name')
      .eq('type', 'landing_page').eq('status', true).ilike('name', `*${findName}*`).limit(10);

    if (data && data.length > 0) {
      const dataPromos = data.map((it) => {
        return {
          code: it.id,
          label: it.name
        };
      });
      const concatData = uniqBy([...dataPromoDirectTo || [], ...dataPromos], 'code');
      setDataPromoDirectTo(concatData);
    }
  }, 300);

  const findPromos = async (findName: string | null) => {
    findPromosDebounce(findName);
  };

  const optionPromos = useMemo(()=> {
    return dataPromoDirectTo;
  }, [dataPromoDirectTo]);

  const { data: dataMerchants } = useMerchatns();
  const optionListMerchants = useMemo(() => {
    return (
      (Array.isArray(dataMerchants?.data) &&
        dataMerchants?.data.map((merchant) => {
          return {
            code: merchant.id,
            label: merchant.name
          };
        })) ||
      []
    );
  }, [dataMerchants]);

  const { data: dataProduct } = useProduct();
  const optionListProduct: IProductOptions[] = useMemo(() => {
    return (
      (Array.isArray(dataProduct?.data) &&
        dataProduct?.data.map((product) => {
          return {
            label: product.name,
            code: product.id
          };
        })) ||
      []
    );
  }, [dataProduct]);

  const { data: dataPromos } = usePromos(true);
  const optionListPromos: IBannerOptions[] = useMemo(() => {
    return (
      (Array.isArray(dataPromos?.data) &&
        dataPromos?.data.map((promo) => {
          return {
            label: promo.name,
            code: promo.id
          };
        })) ||
      []
    );
  }, [dataPromos]);

  const validateDate: Boolean = useMemo(() => {
    return new Date(formEditPromoTagging.valid_to).getTime() < new Date(formEditPromoTagging.valid_from).getTime();
  }, [formEditPromoTagging]);

  const onSavePromoTaggingById = useCallback(async () => {
    setIsLoading(true);
    if (selectedCategory === 'topKeywordList') {
      if (Array.isArray(selectedStreet)) {
        const applyToStreet = selectedStreet.join(', ');

        const metadata = {
          showDescription: Boolean(formEditPromoTagging.showDescription?.code),
          action: String(formEditPromoTagging.action?.code),
          applyToStreet: applyToStreet,
          product_id: Number(formEditPromoTagging.productId.code),
          merchant_id: formEditPromoTagging.merchantId.code,
          directTo: formEditPromoTagging.directTo,
          promos_id: formEditPromoTagging.promoDetailId.code || null
        };

        const PromoTagging: ITopKeywordList = {
          type: 'topKeywordList',
          name: String(formEditPromoTagging.name),
          description: String(formEditPromoTagging.description),
          tag_metadata: metadata,
          valid_from: String(formEditPromoTagging.valid_from),
          valid_to: String(formEditPromoTagging.valid_to),
          status: String(formEditPromoTagging.status?.code),
          url: formEditPromoTagging.directTo === 'LANDING_PAGE' ? formEditPromoTagging.landingPageType : null
        };

        const { error } = await supabase.from(TABLE.PROMO_TAGGING).insert(PromoTagging);

        if (error) {
          showFailedToast();
          navigate('/promo-tagging/create');
        } else {
          setSelected([]);
          setSelectedCategory('topKeywordList');
          setProductVariant([]);
          showSuccessToast();
        }
      }
    } else if (selectedCategory === 'newProduct') {
      const metaProductVariant: IMetaProductVariant[] = (selected as IProductVariant[]).map((i) => {
        return {
          id: i.id,
          product: i.product
        };
      });

      const metadata: ITagMetadataNewProduct = {
        product: metaProductVariant,
        action: String(formEditPromoTagging.action?.code)
      };

      const PromoTagging: INewProduct = {
        type: 'newProduct',
        name: String(formEditPromoTagging.name),
        description: String(formEditPromoTagging.description),
        tag_metadata: metadata,
        valid_from: String(formEditPromoTagging.valid_from),
        valid_to: String(formEditPromoTagging.valid_to),
        status: String(formEditPromoTagging.status?.code)
      };

      const { data, error } = await supabase.from(TABLE.PROMO_TAGGING).insert(PromoTagging).select('id').single();
      if (data) {
        (selected as IProductVariant[]).forEach(async (i) => {
          const { error: errInsertProductVariantPromoTag } = await supabase
            .from(TABLE.PRODUCT_VARIANT_PROMO_TAGS)
            .insert([
              {
                product_variant_id: i.id,
                promo_tag_id: data.id
              }
            ]);

          if (errInsertProductVariantPromoTag) {
            setIsLoading(false);
            showFailedToast();
          }
        });
      }

      if (error) {
        navigate('/promo-tagging/create');
        showFailedToast();
      } else {
        setSelected([]);
        setSelectedCategory('topKeywordList');
        setProductVariant([]);
        showSuccessToast();
      }
    } else if (selectedCategory === 'storeRecommendation') {
      const metaStore: IMetaStore[] = (selected as IStore[]).map((i) => {
        return {
          id: i.id,
          store: i.store
        };
      });

      const metadata: ITagMetadataStoreRecomendation = {
        action: String(formEditPromoTagging.action?.code),
        store: metaStore
      };

      const PromoTagging = {
        type: 'storeRecommendation',
        tag_metadata: metadata,
        valid_from: String(formEditPromoTagging.valid_from),
        valid_to: String(formEditPromoTagging.valid_to),
        status: String(formEditPromoTagging.status?.code)
      };
      const { error } = await supabase.from(TABLE.PROMO_TAGGING).insert(PromoTagging);

      if (error) {
        navigate('/promo-tagging/create');
        showFailedToast();
      } else {
        setSelected([]);
        setSelectedCategory('topKeywordList');
        setProductVariant([]);
        showSuccessToast();
      }
    } else if (selectedCategory === 'keywordRecommendation') {
      let type: string = '';
      if (formEditPromoTagging?.directTo === 'PLP') {
        type = 'plp';
      }
      if (formEditPromoTagging?.directTo === 'LANDING_PAGE') {
        type = 'landingPage';
      }

      const metadata = {
        product_id: Number(formEditPromoTagging.productId.code) || null,
        merchant_id: formEditPromoTagging.merchantId.code || null,
        directTo: formEditPromoTagging.directTo,
        promos_id: formEditPromoTagging.promoDetailId.code || null
      };

      const PromoTagging = {
        type: type,
        name: String(formEditPromoTagging.name),
        description: String(formEditPromoTagging.description),
        tag_metadata: metadata,
        valid_from: String(formEditPromoTagging.valid_from),
        valid_to: String(formEditPromoTagging.valid_to),
        status: String(formEditPromoTagging.status?.code),
        url: formEditPromoTagging.directTo === 'LANDING_PAGE' ? formEditPromoTagging.landingPageType : null
      };

      const { error } = await supabase.from(TABLE.PROMO_TAGGING).insert(PromoTagging);

      if (error) {
        showFailedToast();
      } else {
        setSelected([]);
        setSelectedCategory('topKeywordList');
        setProductVariant([]);
        showSuccessToast();
      }
    }
  }, [selectedCategory, formEditPromoTagging, selected]);

  const formik = useFormik({
    initialValues: initialValueFormik,
    validationSchema: schemaCreatePromoTagging,
    onSubmit: onSavePromoTaggingById
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const onCancel = () => {
    setVisible(false);
    setTempSelected([]);
    setSearch('');
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  };

  const onSubmit = useCallback(() => {
    if (tempSelected.length < 1 || tempSelected.length > 6) {
      setValidate(true);
    } else {
      formik.setFieldValue('selectedItem', tempSelected);
      setSelected(tempSelected);
      setValidate(false);
      setVisible(false);
    }
  }, [tempSelected]);

  const { data: getStreets } = useStreet();
  const optionStreet: IBannerOptions[] = useMemo(() => {
    return (
      (Array.isArray(getStreets?.data) &&
        getStreets?.data.map((street) => {
          return {
            label: street.name,
            code: street.id
          };
        })) ||
      []
    );
  }, [getStreets]);

  // Pagination Product
  const handleClickNext = useCallback(() => {
    paginator.currentPage <= totalPages &&
        setPaginator((prev) => ({
          ...prev,
          currentPage: paginator.currentPage + 1,
          range: {
            start: paginator.range.start + perPage,
            end: paginator.range.end + perPage
          }
        }));
  }, [paginator, totalPages]);

  const handleClickPrev = useCallback(() => {
    paginator.range.start > 0 &&
        setPaginator((prev) => ({
          ...prev,
          currentPage: prev.currentPage - 1,
          range: {
            start: prev.range.start - perPage,
            end: prev.range.end - perPage
          }
        }));
  }, [paginator]);

  const handleClickLastPage = useCallback(() => {
    paginator.currentPage <= totalPages &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: totalPages,
        range: {
          start: totalRecords - (totalPages * perPage - totalRecords),
          end: totalRecords
        }
      }));
  }, [perPage, totalRecords, totalPages, paginator]);

  const handleClickFirstPage = useCallback(() => {
    paginator.currentPage > 0 &&
        setPaginator((prev) => ({
          ...prev,
          currentPage: 1,
          range: {
            start: 0,
            end: perPage - 1
          }
        }));
  }, [paginator, perPage]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent) => {
    setPerPage(event.value);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleClickPage = useCallback(
    (page: number) => () => {
      setPaginator((prev) => ({
        ...prev,
        currentPage: page,
        range: {
          start: page === 1 ? page - 1 : perPage * page - perPage,
          end: page * perPage - 1
        }
      }));
    },
    [perPage]
  );

  const handleOpenModal = useCallback(() => {
    setVisible(true);
    setTempSelected(selected);
  }, [selected]);

  // set default option list merchants
  useEffect(() => {
    setDataStoresDirectTo(optionListMerchants);
  }, [optionListMerchants]);

  // set default option list products
  useEffect(() => {
    setDataProductsDirectTo(optionListProduct);
  }, [optionListProduct]);

  // set default option list promo
  useEffect(()=> {
    setDataPromoDirectTo(optionListPromos);
  }, [optionListPromos]);

  useEffect(()=>{
    if (selectedCategory === 'newProduct' && visible) {
      getProdVars(paginator.range.start, paginator.range.end, debounceSearch);
    }
  }, [paginator, selectedCategory, perPage, visible, debounceSearch]);
  useEffect(()=> {
    if (selectedCategory === 'storeRecommendation' && visible) {
      getStore(paginator.range.start, paginator.range.end, debounceSearch);
    }
  }, [paginator, selectedCategory, perPage, visible, debounceSearch]);

  return {
    data: {
      validateDate,
      optionKeywordRecomendations,
      validate,
      searchEnter,
      dataProductVariants,
      dataStores,
      optionDirectTo,
      selectedCategory,
      isLoading,
      visible,
      productVariant,
      toast,
      selected,
      formEditPromoTagging,
      optionCategoryTags,
      optionStreet,
      selectedStreet,
      search,
      formik,
      paginator,
      perPage,
      totalPages,
      totalRecords,
      tempSelected,
      optionStores,
      optionProducts,
      optionPromos
    },
    methods: {
      handleClickCancel,
      onSubmit,
      handleOnEnter,
      handleSearch,
      handleOnChangeDirectTo,
      handleOnchangeCategory,
      setVisible,
      setSelected,
      onChangeFormEditPromotagging,
      setSelectedStreet,
      onSavePromoTaggingById,
      onCancel,
      showSuccessToast,
      isFormFieldValid,
      handleChangeDropdownPage,
      handleClickFirstPage,
      handleClickLastPage,
      handleClickNext,
      handleClickPrev,
      handleClickPage,
      setTempSelected,
      handleOpenModal,
      findStores,
      findProducts,
      findPromos
    }
  };
};
