import React from 'react';
import { useNavigate } from 'react-router-dom';
import useCustom from './hooks';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import LoadingOverlay from '@/components/base/LoadingOverlay';

const UploadProduct = () => {
  const { data, method } = useCustom();
  const navigate = useNavigate();
  const getFormErrorMessage = (name) => {
    return method.isFormFieldValid(name) && <small className="p-error">{data.formik.errors[name]}</small>;
  };
  return (
    <BaseContainer>
      <LoadingOverlay show={data.isLoading}/>
      <MainTitle title="Create Product Highlight" />
      <div className="flex mt-6">
        <div className="w-full h-screen">
          <div id="form-containeer" className="w-full ml-6 mr-6">
            <div id="sub-title" className="mt-10 mb-8 text-base font-bold">
                Upload Product
            </div>
            <div className="relative mt-4">
              <div id="apply-to-street" className="flex">
                <div id="apply-street-label" className="w-1/5">
                    Apply to Street *
                </div>
                <div className="!w-1/2" id="dropdown_field">
                  <div className="card flex justify-content-center">
                    <Dropdown
                      value={data.selected.street} id="product-highlight-input-street"
                      onChange={(e: DropdownChangeEvent) => method.inputSelected('street', e.value)}
                      options={data.street} optionValue="id"
                      optionLabel="name"
                      placeholder="Select a Street"
                      className="w-1/2 md:w-14rem"
                    />
                  </div>
                  <div className="pt-1 pl-1">{getFormErrorMessage('street')}</div>
                </div>
              </div>
            </div>
            <div className="relative mt-4">
              <div id="apply-to-section" className="flex">
                <div id="apply-section-label" className="w-1/5">
                    Apply to Section *
                </div>
                <div className="!w-1/2" id="dropdown_field">
                  <div className="card flex justify-content-center">
                    <Dropdown
                      value={data.selected.section} id="product-highlight-input-section"
                      onChange={(e: DropdownChangeEvent) => method.inputSelected('section', e.value)}
                      options={data.section}
                      optionLabel="name" optionValue="id"
                      placeholder="Select a Section"
                      className="w-1/2 md:w-14rem"
                    />
                  </div>
                  <div className="pt-1 pl-1">{getFormErrorMessage('section')}</div>
                </div>
              </div>
            </div>
            {data.selected.section === 2 &&
              <div className="relative mt-4">
                <div id="apply-to-tabulation" className="flex">
                  <div id="apply-tabulation-label" className="w-1/5">
                      Apply to Tabulation *
                  </div>
                  <div className="!w-1/2" id="dropdown-field">
                    <div className="card flex justify-content-center">
                      <Dropdown
                        value={data.selected.tabulation} id="product-highlight-input-tabulation"
                        onChange={(e: DropdownChangeEvent) => method.inputSelected('tabulation', e.value)}
                        options={data.tabulation}
                        optionLabel="name" optionValue="id"
                        placeholder="Select a Tabulation"
                        className="w-1/2 md:w-14rem"
                      />
                    </div>
                    <div className="pt-1 pl-1">{getFormErrorMessage('tabulation')}</div>
                  </div>
                </div>
              </div>
            }
            <div className="mt-6">
              <div id="upload-bulk" className="flex items-center">
                <div id="upload-bulk-label" className="w-1/5">
                    Upload Bulk *
                </div>
                <FileUpload mode="basic" accept=".xlsx" auto customUpload ref={data.fileUploadRef}
                  uploadHandler={method.handleSelectFile} chooseLabel="Upload" id="product-highlight-input-product" />
                {data.fileName && (
                  <div className="text-sm ml-4">
                    {data.fileName}
                  </div>
                )}
                <div className="pt-1 pl-1">{getFormErrorMessage('fileName')}</div>
              </div>
            </div>
            <div className="flex w-full pt-4 pb-3 px-10 justify-end">
              <Button onClick={method.onClickDownload} outlined label="Download Template" className="w-1/5" id="product-highlight-download-template" />
            </div>
            <Toast ref={data.toast} />
            <SubmitButton
              labelBack="Cancel"
              labelSubmit="Create"
              onCancel={() => navigate('/product-highlight')}
              onSubmit={data.formik.handleSubmit}
            />
          </div>
        </div>
      </div>
    </BaseContainer>
  );
};

export default UploadProduct;
