import dayjs from 'dayjs';
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useQueryGroup, { IFilterUserGroup } from './repositoryUserManagement';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { insertGroupAdmin } from '@/services/rest/userManagement';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import { schemaUserGroup } from './validation';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { useHistoryStore } from '@/store/useHistoryStore';
import { IFilterHistoryItems } from '@/components/base/FilterHistory';
import { useDebounce } from 'primereact/hooks';

export interface IFormCreate {
  name: string;
  code: string;
  description: string;
  application_id: number;
  status: boolean;
}

const useUserManagement = () => {
  const initialFormCreate:IFormCreate = {
    name: '',
    code: '',
    description: '',
    application_id: 1,
    status: false
  };
  const initialFormik = {
    name: '',
    code: '',
    status: null
  };

  const initialFilter: IFilterUserGroup = {
    id_from: null,
    id_to: null,
    total_user_from: null,
    total_user_to: null,
    created_at: [],
    group_name: '',
    status: ''
  };

  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const [search, debounceSearch, setSearch] = useDebounce('', 1500);
  const [dataTeams, setDataTeams] = useState<[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<Array<string>>([]);
  const [status, setStatus] = useState<Array<boolean>>([true, false]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState(lastFilterPage.usergroup != '' ? JSON.parse(String(lastFilterPage.usergroup)) : initialFilter);
  const [itemFilters, setItemFilters] = useState(lastFilterPage.usergroup != '' ? JSON.parse(String(lastFilterPage.usergroup)) : initialFilter);
  const { getGroupWithCount } = useQueryGroup();
  const [visible, setVisible] = useState<boolean>(false);
  const [formCreate, setFormCreate] = useState<IFormCreate>(initialFormCreate);
  const toast = useRef<Toast>(null);
  const statusOption = [
    { code: 'true', label: 'Active' },
    { code: 'false', label: 'Inactive' }
  ];
  //formik validate
  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const formik = useFormik({
    initialValues: initialFormik,
    validationSchema: schemaUserGroup,
    onSubmit: () => {
      handleClickSubmit();
    }
  });
  const [perPage, setPerPage] = useState<number>(10);
  const currentPage = parseInt(visitedPage.usergroup.toString()) ?? 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : perPage - 1;
  const [paginator, setPaginator] = useState({ currentPage, range: { start, end } });
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [jumpToPage, setJumpToPage] = useState<number>(1);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);

  const header = [
    { field: 'id', header: 'Group ID' },
    { field: 'name', header: 'Group Name ' },
    { field: 'count', header: 'Total User' },
    { field: 'created_at', header: 'Created At' },
    { field: 'status', header: 'Status' }
  ];

  const handleClickCreate = useCallback(() => {
    setVisible(true);
  }, []);

  const handleCancelCreate = useCallback(() => {
    setVisible(false);
  }, []);

  const handleSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>)=>{
    setSearch(currentTarget.value);
    const newKeyword = {
      name: 'Keyword',
      items: [{
        label: currentTarget.value,
        value: currentTarget.value,
        name: currentTarget.value
      }]
    };

    if (currentTarget.value != '') {
      setFilterHistory((prev: IFilterHistoryItems[]) => {
        const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
        if (existingIndex !== -1) {
          prev[existingIndex] = newKeyword;
        } else {
          prev.push(newKeyword);
        }
        return [...prev];
      });

      setPaginator({
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      });
    } else {
      handleDeleteFilterHistory('Keyword', ['Keyword']);
    }
  }, [perPage]);

  const handleOnChangeInput = useCallback(
    (key: string) =>
      ({ currentTarget }: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement, Event>) => {
        formik.setFieldValue(key, currentTarget.value);
        setFormCreate((prev) => ({
          ...prev,
          [key]: currentTarget.value
        }));
      },
    [formCreate]
  );

  const handleDropdown = useCallback((key: string) => (e: DropdownChangeEvent) => {
    formik.setFieldValue(key, e.value);
    setFormCreate((prev) => ({
      ...prev,
      [key]: e.value
    }));
  }, [formCreate]);

  const getTeams = useCallback(async () => {
    setIsLoading(true);
    const { teams, count } = await getGroupWithCount(debounceSearch, selectedFilter, paginator.range, filters);
    setIsLoading(false);
    setTotalRecords(Number(count || 0));

    let data = teams;
    if (filters?.total_user_from != null && filters?.total_user_to != null) {
      const filteredTeams = teams?.filter((team) => {
        if (filters.total_user_from != null && filters.total_user_to != null) {
          return team.members[0].count >= filters.total_user_from && team.members[0].count <= filters.total_user_to;
        }
        return true;
      });

      if (filteredTeams) {
        data = filteredTeams.slice(paginator.range.start, paginator.range.end);
      }
    }

    const teamsMapper = data?.map((item) => {
      return {
        id: item.id,
        name: item.name,
        count: (item && item.members[0] && item.members[0].count) || 0,
        created_at: dayjs(item.created_at).format('DD MMM YYYY'),
        code: item.code,
        status: item.status ? 'Active' : 'Inactive'
      };
    });

    setDataTeams(teamsMapper as []);
  }, [debounceSearch, paginator, filters, selectedFilter]);

  useEffect(()=> {
    getTeams();
  }, [selectedFilter, filters, debounceSearch, paginator]);

  const handleFilter = useCallback((e, field: string) => {
    setItemFilters((prev) => ({
      ...prev,
      [field]: e.target ? e.target.value : e.value
    }));
  }, []);

  const showSuccessToast = () => {
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: 'successfully created group',
      life: 2000
    });
    const timeOut = setTimeout(() => {
      setVisible(false);
      return () => clearTimeout(timeOut);
    }, 2000);
  };

  const showFailedToast = (msg: string) => {
    toast.current?.show({
      severity: 'error',
      summary: 'Failed',
      detail: msg || 'Failed Create group',
      life: 2000
    });
  };

  const handleClickSubmit = useCallback(async () => {
    const payload:IFormCreate = {
      name: formCreate.name,
      application_id: 1,
      code: formCreate.code,
      description: formCreate.description,
      status: formCreate.status
    };

    const { error } = await insertGroupAdmin(payload);

    if (error) {
      showFailedToast('You Cannot Create in This Application');
      setVisible(true);
    } else {
      showSuccessToast();
      setFormCreate(initialFormCreate);
      setVisible(false);
    }
  }, [formCreate]);

  const totalPages = useMemo(() => {
    return Math.ceil(totalRecords / perPage);
  }, [totalRecords, perPage]);

  const handleClickNext = useCallback(() => {
    paginator.currentPage <= totalPages &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: paginator.currentPage + 1,
        range: {
          start: paginator.range.start + perPage,
          end: paginator.range.end + perPage
        }
      }));
  }, [paginator, totalPages, perPage]);

  const handleClickPrev = useCallback(() => {
    paginator.range.start > 0 &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: prev.currentPage - 1,
        range: {
          start: prev.range.start - perPage,
          end: prev.range.end - perPage
        }
      }));
  }, [paginator, perPage]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent) => {
    setPerPage(event.value);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, totalRecords - 1);

      setPaginator(() => ({
        currentPage: jumpToPage > totalPages ? totalPages : value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, totalRecords]);

  const handleIsOpenDialog = useCallback(() => {
    setIsOpenDialog(!isOpenDialog);
  }, [isOpenDialog]);

  const handleClickSubmitFilter = useCallback(() => {
    setIsOpenDialog(false);
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
    setFilters(itemFilters);
  }, [itemFilters, perPage]);

  const storeFilterHistory = useCallback((filter) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter.id_from != null) {
      let filterID = filter.id_from.toString();
      if (filter.id_to != null) {
        filterID = `${filter.id_from}-${filter.id_to}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('ID', 'id_from,id_to', filterID));
    }

    if (filter.total_user_from != null) {
      let filterTotal = filter.total_user_from.toString();
      if (filter.total_user_to != null) {
        filterTotal = `${filter.total_user_from}-${filter.total_user_to}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Total User', 'total_user_from,total_user_to', filterTotal));
    }

    if (filter?.created_at && filter.created_at[0]) {
      let filterDate = `${dayjs(filter.created_at[0]).format('YYYY-MM-DD')}`;
      if (filter.created_at.length > 1 && filter.created_at[1] !== null) {
        filterDate = `${dayjs(filter.created_at[0]).format('YYYY-MM-DD')} - ${dayjs(filter.created_at[1]).format('YYYY-MM-DD')}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Created At', 'created_at', filterDate));
    }

    if (filter.group_name !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Group Name', 'group_name', filter.group_name));
    }

    if (filter.status !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Status', 'status', filter.status));
    }
    setFilterHistory(filterHistoryItems);
  }, []);

  const handleClearFilterHistory = useCallback(() => {
    setLastFilterPage({
      ...lastFilterPage,
      usergroup: ''
    });
    setFilterHistory([]);
    setFilters(initialFilter);
    setItemFilters(initialFilter);
    setSearch('');
  }, [lastFilterPage]);

  const handleDeleteFilterHistory = useCallback(
    (_: string, value: string[]) => {
      const items = value[0].split(',');
      items.forEach((i) => {
        setFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));

        setItemFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));
      });

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setSearch('');
    },
    []
  );

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  useEffect(() => {
    // update store visitedPage with latest page
    setVisitedPage({
      ...visitedPage,
      usergroup: paginator.currentPage
    });
  }, [paginator.currentPage]);

  // update store lastFilterPage with latest filter
  useEffect(() => {
    setLastFilterPage({
      ...lastFilterPage,
      usergroup: JSON.stringify(filters)
    });
  }, [filters]);

  return {
    data: {
      toast,
      formCreate,
      visible,
      search,
      dataTeams,
      header,
      selectedFilter,
      isLoading,
      status,
      filters,
      statusOption,
      formik,
      perPage,
      paginator,
      totalPages,
      totalRecords,
      isOpenDialog,
      itemFilters,
      filterHistory
    },
    method: {
      handleDropdown,
      handleOnChangeInput,
      handleClickSubmit,
      handleCancelCreate,
      handleClickCreate,
      setSearch,
      setSelectedFilter,
      setStatus,
      handleSearch,
      getTeams,
      handleFilter,
      isFormFieldValid,
      handleClickNext,
      handleClickPrev,
      handleChangeDropdownPage,
      handleChangeJumpTopage,
      handleJumpToPage,
      handleIsOpenDialog,
      handleClickSubmitFilter,
      handleClearFilterHistory,
      handleDeleteFilterHistory
    }
  };
};

export default useUserManagement;
