import React, { FC, useCallback } from 'react';

interface IFilterHistory {
  show: boolean;
  filterHistory: Array<IFilterHistoryItems>;
  handleDeleteFilterHistory: (name: string, value: string[]) => void;
  handleClearFilter: () => void;
}

export interface IFilterHistoryItems {
  name: string;
  items: Array<IItemsOption>;
}

interface IItemsOption {
  label: string;
  value: string | boolean | number;
  name: string;
}

const FilterHistory: FC<IFilterHistory> = ({ show, filterHistory, handleDeleteFilterHistory, handleClearFilter }) => {
  const onDeleteFilterHistory = useCallback(
    (name: string, value: string[]) => () => handleDeleteFilterHistory(name, value),
    [handleDeleteFilterHistory]
  );
  return (
    <>
      {show && (
        <div className="border-y mb-4 px-2 py-4 flex justify-between w-full">
          <div className="flex gap-4">
            <span className="font-bold">Filter Aktif :</span>
            {filterHistory?.map((section, index) => {
              return (
                <div key={index} className="flex font-bold">
                  <div>{section.name.charAt(0).toLocaleUpperCase() + section.name.substring(1)}&nbsp;:&nbsp;</div>
                  <div className="font-normal">{section.items?.map((item) => item.value).join(', ')}&nbsp;</div>
                  {section.items?.length > 0 && (
                    <button
                      type="button"
                      onClick={onDeleteFilterHistory(
                        section.name,
                        section.items?.map((item) => String(item.label))
                      )}
                    >
                      <i className="pi pi-times text-red-500" />
                    </button>
                  )}
                </div>
              );
            })}
          </div>
          <button type="button" className="flex items-center gap-2" onClick={handleClearFilter}>
            <i className="pi pi-times text-red-500" />
            Hapus Filter
          </button>
        </div>
      )}
    </>
  );
};

export default FilterHistory;
