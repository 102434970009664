// import { useAuthStore } from '@/store/useAuthStore';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronRightIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { setIsActive } from '@/routes';
import React, { Fragment, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useCustom from './hook';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const Sidebar: React.FC = () => {
  const { data: { menus } } = useCustom();

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const location = useLocation();

  const navigation = setIsActive(menus, location.pathname);

  return (
    <div className="shadow-sm">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-4 w-4 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>

                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                      alt="Your Company"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="-mx-2 flex-1 space-y-1">
                      {navigation.map((item) => (
                        <li key={item.name}>
                          <a
                            href={item.to}
                            className={classNames(
                              item.isActive ?
                                'bg-gray-800 text-white' :
                                'text-gray-400 hover:text-white hover:bg-gray-800',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                            )}
                          >
                            {item.icon && <item.icon className="h-4 w-4 shrink-0" aria-hidden="true" />}
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden border border-gray-100 lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:block lg:w-64 lg:overflow-y-auto lg:bg-white lg:pb-4">
        <div className="flex h-16 shrink-0 items-center justify-center">
          <div className="font-bold mt-5 lg:text-4xl md:text-2xl">B O S</div>
        </div>
        <nav className="mt-8 items-center">
          <ul role="list" className="flex flex-col space-y-1 lg:px-2">
            {navigation.map((item) => (
              <li key={item.name}>
                {!item.childrens ? (
                  <Link
                    to={item.to}
                    className={classNames(
                      item.isActive ? 'bg-primary text-white' : 'text-gray-700 hover:text-white hover:bg-primary',
                      'group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer'
                    )}
                  >
                    {item.icon && <item.icon className="h-4 w-4 shrink-0" aria-hidden="true" />}
                    <span>{item.name}</span>
                  </Link>
                ) : (
                  <Disclosure as="div">
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={classNames(
                            item.isActive ? 'bg-primary text-white' : 'text-gray-700 hover:text-white hover:bg-primary',
                            'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700'
                          )}
                        >
                          {item.icon && (
                            <item.icon
                              className={classNames(
                                item.isActive ? 'text-white' : 'text-gray-700',
                                'h-4 w-4 shrink-0 text-inherit'
                              )}
                              aria-hidden="true"
                            />
                          )}

                          {item.name}
                          {open && item.isActive ? (
                            <ChevronDownIcon
                              className={classNames(
                                item.isActive ? 'rotate-90 text-gray-100' : 'text-gray-700',
                                'ml-auto h-4 w-4 shrink-0 text-inherit'
                              )}
                              aria-hidden="true"
                            />
                          ) : (
                            <ChevronRightIcon
                              className={classNames(
                                item.isActive ? 'rotate-90 text-gray-100' : 'text-gray-700',
                                'ml-auto h-4 w-4 shrink-0 text-inherit'
                              )}
                              aria-hidden="true"
                            />
                          )}
                        </Disclosure.Button>

                        <Disclosure.Panel as="ul" className="mt-1 px-2" static={item.isActive}>
                          {item.childrens?.map((subItem) => (
                            <Link to={subItem.to} key={subItem.name}>
                              {/* 44px */}
                              <Disclosure.Button
                                as="span"
                                className={classNames(
                                  subItem.isActive ?
                                    'bg-primary text-white' :
                                    'text-gray-700 hover:text-white hover:bg-primary',
                                  'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700 cursor-pointer mb-1'
                                )}
                              >
                                {subItem.name}
                              </Disclosure.Button>
                            </Link>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
