import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAuthStore } from '@/store/useAuthStore';
import { useProfileByEmail } from '@/services/rest/profile';
import { supabase } from '@/lib/supabase';
import { TABLE } from '@/constants';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import { schemaUserAdmin } from '../UserManagement/Admin/validation';
import IconImage from '@/assets/IconImage';
import IconCancel from '@/assets/IconCancel';
import IconUpload from '@/assets/IconUpload';
import { storageUpload } from '@/utils/storage';

const initialForm = {
  id: '',
  email: '',
  first_name: '',
  last_name: '',
  phone_number: '',
  photo: '',
  role: '',
  type: ''
};

export interface IUsers {
    birth_date: Date;
    created_at: Date;
    email: string;
    first_name: string;
    gender: string;
    id: string;
    last_name: string;
    phone_number: string;
    photo: string;
    safe_mode: boolean;
    status: boolean;
    type: string;
    username: string;
  }

export const initialValue = {
  adminName: '',
  adminEmail: '',
  adminType: '',
  adminGroup: 0
};

const initailFormPassword = {
  currentPassword: '',
  newPassword: '',
  confirmationNewPassword: ''
};

const useCustom = () => {
  const [formInput, setFormInput] = useState(initialForm);
  const user = useAuthStore(
    (state) => state.user
  );
  const toast = useRef<Toast>(null);
  const [fileName, setFileName] = useState<string>('');
  const [fileImage, setFileImage] = useState();
  const chooseOptions = { icon: IconImage, iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
  const cancelOptions = { icon: IconCancel, iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };
  const uploadOptions = { icon: IconUpload, iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
  const [password, setPassword] = useState(initailFormPassword);

  const { data: dataProfile, isLoading: isLoadingDetailUser, refetch: refetchDetailUser } = useProfileByEmail(String(user?.email));
  const profile = useMemo(() => {
    const data = dataProfile?.data;
    return {
      id: data?.id || '',
      email: data?.email || '',
      first_name: data?.first_name || '',
      last_name: data?.last_name || '',
      phone: data?.phone_number || '',
      role: (data?.members?.length > 1 ? data?.members[1]?.teams?.name : data?.members[0]?.teams?.name) || '',
      photo: data?.photo || '',
      type: data?.type || ''
    };
  }, [dataProfile]);

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schemaUserAdmin,
    onSubmit: () => {
      if (id) {
        editUser();
      }
    }
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getUserByID = useCallback(async ()=> {
    const { data, error } = await supabase.from(TABLE.ACCOUNTS).select('*, members(teams(name, id))').eq('id', id).single();
    if (data) {
      const user = data as IUsers;
      formik.setFieldValue('adminName', user.first_name);
      formik.setFieldValue('adminName', user.last_name);
      formik.setFieldValue('adminPhone', user.phone_number);
    }
    if (error) {
      showError(error.message);
    }
  }, [formInput]);

  useEffect(()=>{
    setFormInput((prevState) => {
      return {
        ...prevState,
        ['id']: profile?.id,
        ['first_name']: profile?.first_name,
        ['last_name']: profile?.last_name,
        ['phone_number']: profile?.phone,
        ['role']: profile?.role,
        ['photo']: profile?.photo,
        ['type']: profile?.type
      };
    });
  }, [setFormInput, profile]);
  const id = formInput?.id;

  const detailUser = useMemo(() => {
    if (profile) {
      return {
        role: profile?.role || '',
        type: profile?.type || '',
        photo: profile?.photo || '',
        phone: profile?.phone || '',
        lastName: profile?.last_name || '',
        firstName: profile?.first_name || ''
      };
    }
  }, [profile]);

  const showError = (msg: string) => {
    if (toast.current !== null) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: `${msg}`,
        life: 2000
      });
    }
  };

  const showSuccess = (msg: string) => {
    if (toast.current != null) {
      toast.current.show({
        severity: 'success',
        summary: `${msg}`,
        detail: `User Data ${msg} Successfully`,
        life: 2500
      });
    }
  };

  const changeUserPassword = async (): Promise<boolean> => {
    if (
      password.currentPassword.length > 0 &&
      password.newPassword.length > 7 &&
      password.confirmationNewPassword.length > 7 &&
      password.newPassword == password.confirmationNewPassword
    ) {
      const { error: errorUpdatePassword } = await supabase.rpc('user.change_user_password', {
        current_plain_password: password.currentPassword,
        new_plain_password: password.newPassword
      });

      if (errorUpdatePassword) {
        showError('Failed Update Password');
        return false;
      }
    }
    return true;
  };

  const editUser = useCallback(async () => {
    if (id) {
      if (fileImage != undefined) {
        const uploadImage = await storageUpload('web/', fileImage);

        if (uploadImage) {
          const payloadUpdateUser = {
            first_name: formInput.first_name,
            last_name: formInput.last_name,
            photo: uploadImage.src,
            phone_number: formInput.phone_number,
            id: id
          };

          const { error: errorUpdateUser } = await supabase.from(TABLE.ACCOUNTS)
            .upsert(payloadUpdateUser);

          if (errorUpdateUser) {
            showError('Failed Update Profile');
            return;
          }

          const isSuccess = await changeUserPassword();
          if (!isSuccess) {
            return;
          }
        }
      } else {
        const payloadUpdateUser = {
          first_name: formInput.first_name,
          last_name: formInput.last_name,
          phone_number: formInput.phone_number,
          id: id
        };

        const { error: errorUpdateUser } = await supabase.from(TABLE.ACCOUNTS)
          .upsert(payloadUpdateUser);

        if (errorUpdateUser) {
          showError('Failed Update Profile');
          return;
        }

        if (password.currentPassword.length > 0) {
          const { error: errorUpdatePassword } = await supabase.rpc('user.change_user_password', {
            current_plain_password: password.currentPassword,
            new_plain_password: password.newPassword
          });

          if (errorUpdatePassword) {
            showError('Failed Update Password');
            return;
          }
        }
      }
      setIsOpen(false);
      getUserByID();
      showSuccess('Updated');
      refetchDetailUser();
    }
  }, [formInput, password]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleEditProfile = () => {
    setIsOpen(!isOpen);
    setPassword(initailFormPassword);
  };

  const closeEditProfile = () => {
    setIsOpen(false);
  };

  const handleFileUpload = useCallback(
    (key: string, e) => {
      const file = e.files[0];
      const timeStamp = + new Date();
      const name = `${file?.name}${timeStamp}`;
      setFileName(name);
      setFileImage(file);
      setFormInput((prevState) => {
        return {
          ...prevState,
          [key]: file
        };
      });
    },
    []
  );

  const handleChangePassword = (key: string, value: string) => {
    setPassword((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const isMatchPassword = useMemo(() => {
    return (password.confirmationNewPassword.length > 0 && password.newPassword !== password.confirmationNewPassword && password.newPassword.length >= 8);
  }, [password]);

  return {
    data: {
      formInput,
      profile,
      isOpen,
      formik,
      toast,
      chooseOptions,
      cancelOptions,
      uploadOptions,
      fileName,
      fileImage,
      password,
      isMatchPassword,
      detailUser,
      isLoadingDetailUser
    },
    methods: {
      setFormInput,
      toggleEditProfile,
      setIsOpen,
      closeEditProfile,
      editUser,
      showSuccess,
      isFormFieldValid,
      getUserByID,
      handleFileUpload,
      setFileName,
      handleChangePassword
    }
  };
};

export default useCustom;
