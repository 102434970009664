import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import ImageUserLogin from '@/assets/ImageUserLogin.svg';
import React from 'react';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import useCustom from './hooks';
import { Dialog } from 'primereact/dialog';
import { Password } from 'primereact/password';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { Image } from 'primereact/image';
import cx from 'classnames';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import { MESSAGE } from '@/constants';
import LoadingOverlay from '@/components/base/LoadingOverlay';

const Profile = () => {
  const {
    methods,
    data
  } = useCustom();

  const headerCreate = (
    <div id="admin-edit-header" className="font-bold">Edit Profil</div>
  );

  const footerCreate = (
    <div id="admin-edit-footer" className="grid grid-cols-2">
      <Button id="admin-cancle-btn" label="Cancel" onClick={methods.closeEditProfile} severity="danger" outlined />
      <Button disabled={data.isMatchPassword} id="admin-add-btn" label="Simpan" onClick={methods.editUser} severity="danger" />
    </div>
  );

  const getFormErrorMessage = (name) => {
    return methods.isFormFieldValid(name) && <small className="p-error">{data.formik.errors[name]}</small>;
  };

  return <BaseContainer>
    <MainTitle title="Profile" />
    {/* loading screen */}
    <LoadingOverlay show={data.isLoadingDetailUser} />
    <div className="card flex justify-content-center">
      <Dialog header={headerCreate} visible={data.isOpen} style={{ width: '35vw' }} onHide={methods.closeEditProfile} footer={footerCreate}>
        <div className="flex flex-col py-2">
          <label className="pb-2 text-sm font-medium">Name</label>
          <div className="flex gap-x-2">
            <InputText
              id="admin-edit-first-name"
              required
              name="editFirstName"
              value={data?.formInput?.first_name}
              className="w-full"
              onChange={(e) => {
                methods.setFormInput((prevState) => {
                  return {
                    ...prevState,
                    ['first_name']: e.target.value
                  };
                });
              }}
            />
            <InputText
              id="admin-edit-last-name"
              required
              name="editlastName"
              value={data?.formInput?.last_name}
              className="w-full"
              onChange={(e) => {
                methods.setFormInput((prevState) => {
                  return {
                    ...prevState,
                    ['last_name']: e.target.value
                  };
                });
              }}
            />
          </div>
        </div>
        <div className="pt-1 pl-1">
          {getFormErrorMessage('adminName')}
        </div>
        <div className="flex flex-col py-2">
          <label className="py-2 text-sm font-medium">Nomer HP</label>
          <InputText
            name="editPhone"
            required
            className="w-full"
            id="admin-edit-phone"
            value={data?.formInput?.phone_number}
            onChange={(e) => {
              methods.setFormInput((prevState) => {
                return {
                  ...prevState,
                  ['phone_number']: e.target.value
                };
              });
            }}
          />
        </div>
        <div className="pt-1 pl-1">
          {getFormErrorMessage('adminPhone')}
        </div>
        <div className="flex flex-col py-2">
          <label className="py-2 text-sm font-medium">Kata Sandi Lama</label>
          <Password
            feedback={false}
            toggleMask
            value={data.password.currentPassword}
            onChange={(e) => methods.handleChangePassword('currentPassword', e.target.value)}
            name="editOldPassword"
            required
            className="w-full"
            id="admin-edit-old-password"
          />
        </div>
        <div className="flex flex-col py-2">
          <label className="py-2 text-sm font-medium">Kata Sandi Baru</label>
          <Password
            toggleMask
            value={data.password.newPassword}
            onChange={(e) => methods.handleChangePassword('newPassword', e.target.value)}
            name="editNewPassword"
            required
            className="w-full"
            id="admin-edit-new-password"
          />
        </div>
        <div className="flex flex-col py-2">
          <label className="py-2 text-sm font-medium">Konfirmasi Kata Sandi Baru</label>
          <Password
            feedback={false}
            toggleMask
            value={data.password.confirmationNewPassword}
            onChange={(e) => methods.handleChangePassword('confirmationNewPassword', e.target.value)}
            name="editConfirmPassword"
            required
            className={cx('w-full', data.isMatchPassword ? 'p-invalid' : '')}
            id="admin-edit-confirm-password"
          />
          {data.isMatchPassword && (
            <div>
              <small className="p-error">{MESSAGE.PASSWORD_NOT_MATCH}</small>
            </div>
          )}
        </div>
        <div id="image_input" className="mb-3 flex flex-col gap-y-2 ">
          <label htmlFor="media_url" className="w-2/6">
            Foto Profil
          </label>
          <p className="text-[#B3B3B3] text-[10px]">*Format wajib .jpg / .jpeg / .png</p>
          <p className="text-[#B3B3B3] text-[10px]">*Maksimal file size 5 mb</p>
          { true &&
            <Image src={data.formInput.photo} alt="Image" width="250" className="w-1/2 object-cover" preview />
          }
          <FileUpload
            id="media_url" className="w-1/2" accept="image/jpeg, image/png, image/jpg" maxFileSize={5000000}
            chooseOptions={data.chooseOptions} uploadOptions={data.uploadOptions} cancelOptions={data.cancelOptions}
            invalidFileSizeMessageDetail="File must be under 150 kb"
            emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>}
            onSelect={(e) => methods.handleFileUpload('images', e)}
          />
        </div>
      </Dialog>
    </div>

    <Toast ref={data.toast} />

    <div className="p-6 mt-4 bg-white rounded-xl">
      <div className="flex flex-col gap-y-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            <img
              className="h-[100px] w-[100px] object-cover rounded-full bg-gray-50 mt-2"
              src={data.detailUser?.photo ? data.detailUser?.photo : ImageUserLogin}
              alt="profile image"
              width={100}
              height={100}
            />
            <div className="flex flex-col">
              <span className="text-black font-bold">{`${data.detailUser?.firstName} ${data.detailUser?.lastName}`}</span>
              <span className="text-black font-bold">{data.detailUser?.type}</span>
            </div>
          </div>
          <div>
            <Button onClick={methods.toggleEditProfile} label="Edit Profile" />
          </div>
        </div>
        <div className="flex gap-5 items-center">
          <span className="w-1/12 font-bold">Name:</span>
          <div className="w-11/12">
            <InputText
              required
              name="name"
              disabled
              className="w-full"
              value={`${data.detailUser?.firstName} ${data.detailUser?.lastName}`}
            />
          </div>
        </div>
        <div className="flex gap-5 items-center">
          <span className="w-1/12 font-bold">Phone:</span>
          <div className="w-11/12">
            <InputText
              required
              disabled
              name="phone"
              className="w-full"
              value={data.detailUser?.phone}
            />
          </div>
        </div>
        <div className="flex gap-5 items-center">
          <span className="w-1/12 font-bold">Role:</span>
          <div className="w-11/12">
            <InputText
              required
              name="Role"
              className="w-full"
              value={data.detailUser?.role}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  </BaseContainer>;
};

export default Profile;
