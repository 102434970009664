import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { schemaCreatePromoOngkir } from './validation';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { useLocation } from 'react-router-dom';
import {
  deletePromoAmountCounts,
  deletePromoRules,
  deletePromos,
  deleteShippingApplied,
  IFilterPromos,
  useAllPromos,
  usePromosFilterOption
} from '@/services/rest/promo';
import { uniqBy } from 'lodash';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { useHistoryStore } from '@/store/useHistoryStore';
import { IFilterHistoryItems } from '@/components/base/FilterHistory';
import dayjs from 'dayjs';
import { useDebounce } from 'primereact/hooks';

export interface Promos {
  id: string;
  code: string;
  description: string;
  image: string;
  amount_type: string;
  amount: number;
  start_date: Date;
  end_date: Date;
  status: boolean;
  type: string;
  accepted_payment_provider_ids: AcceptedPaymentProviderID[];
  payment_bin_rule: string;
  quota: number;
  user_quota: number;
  is_quota_unlimited: boolean;
  is_user_quota_unlimited: boolean;
  min_discount_amount: number;
  max_discount_amount: number;
  min_payment_amount: number;
  max_payment_amount: number;
  name: string;
  created_at: Date;
}
export interface AcceptedPaymentProviderID {
  id: number;
  code: string;
  label: string;
}
export interface Providers {
  code: string;
  name: string;
  description: string;
  status: boolean;
  icon: string;
  id: number;
  shipping_methods: Methods[];
}
export interface Methods {
  code: string;
  type: string;
  name: string;
  description: string;
  status: boolean;
  icon: string;
  shipping_provider_id: number;
  id: number;
}

interface IMethodsProvider {
  code: string;
  description: null | string;
  icon: string;
  id: number;
  name: string;
  shipping_provider_id: number;
  status: boolean;
  type: string
}

interface IFirstForm {
  name: string,
  provider: Providers[],
  methods: IMethodsProvider[],
  platforms: string[],
  customers: string[],
  startDate: string,
  endDate: string,
  is_active: boolean,
  description: string,
  voucher: string,
  voucher_code: string,
  use_generator: boolean,
  isAllPlatform: boolean,
  isRegisteredCustomer: boolean
}


const initFirstForm = {
  name: '',
  provider: [],
  methods: [],
  platforms: [],
  customers: [],
  startDate: '',
  endDate: '',
  is_active: false,
  description: '',
  voucher: '',
  voucher_code: '',
  use_generator: false,
  isAllPlatform: false,
  isRegisteredCustomer: false
};
const initSecondForm = {
  quota: 0,
  user_quota: 0,
  co_quota: 1,
  renewal: '',
  day: '',
  date: '',
  min_payment_amount: 0,
  areas: '',
  type: '',
  is_user_quota_unlimited: false,
  is_no_minimum_subtotal: false,
  amount_discount: 0,
  max_discount_amount: 0,
  no_other_postage: false,
  no_other_voucher: false
};
const initCodeGen = {
  amount: 0,
  length: 0,
  format: '',
  prefix: '',
  suffix: '',
  separator: 0
};
type InitialValueForm = {
  promoName: string;
  selectCourier: Array<number>;
  selectService: Array<Object>;
  platform: Array<string>;
  customerGroup: string[];
  startDate: string;
  endDate: string;
  status: boolean;
  promoQuota: number;
  userUsage: number;
  minOrder: number;
  nominalDiscount: string;
  ammountDiscount: number;
  maxDiscount: number;
}
const initialValueForm:InitialValueForm = {
  promoName: '',
  selectCourier: [],
  selectService: [],
  platform: [],
  customerGroup: [],
  startDate: '',
  endDate: '',
  status: false,
  promoQuota: 0,
  userUsage: 0,
  minOrder: 0,
  nominalDiscount: '',
  ammountDiscount: 0,
  maxDiscount: 0
};
const initCollapse = {
  quota: true,
  condition: true
};
const optionsPlatform = [
  { name: 'Web Browser', code: 'web' },
  { name: 'IOS', code: 'ios' },
  { name: 'Android', code: 'ard' }
];

const optionsCustomer = [
  { name: 'Visitor', code: 'vst' },
  { name: 'Registered Customer', code: 'rcs' }
];

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const areas = ['Seluruh Indonesia', 'Pembeli dan Penjual di Jabodetabek', 'Pembeli dan Penjual dalam Kota', 'Pembeli dan Penjual antar Kota'];
const type = [{ name: 'Sesuai Jumlah Ongkir per Pembelian', value: 'sesuai_ongkir' }, { name: 'Fixed Amount', value: 'fixed' }, { name: 'Percentage', value: 'percentage' }];

const useCustom = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const initialFilter: IFilterPromos = {
    name: '',
    show_date: [],
    category: '',
    status: ''
  };
  const [selectedFilter, setSelectedFilter] = useState<string[]>([]);
  const [providers, setProviders] = useState<Providers[]>([]);
  const [shipMethod, setShipMethod] = useState<Methods[]>([]);
  // const [promos, setPromos] = useState<Promos[]>([]);
  const [collapsible, setCollapsible] = useState(initCollapse);
  const [firstForm, setFirstForm] = useState<IFirstForm>(initFirstForm as IFirstForm);
  const [secondForm, setSecondForm] = useState(initSecondForm);
  const [codeGen, setCodeGen] = useState(initCodeGen);
  const toast = useRef<Toast>(null);
  const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const currentPage = parseInt(visitedPage.promoOngkir.toString()) ?? 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : perPage - 1;
  const [paginator, setPaginator] = useState({
    currentPage,
    range: {
      start,
      end
    }
  });
  const [jumpToPage, setJumpToPage] = useState<number>(1);
  const [itemFilters, setItemFilters] = useState(lastFilterPage.promoOngkir != '' ? JSON.parse(String(lastFilterPage.promoOngkir)) : initialFilter);
  const [filters, setFilters] = useState(lastFilterPage.promoOngkir != '' ? JSON.parse(String(lastFilterPage.promoOngkir)) : initialFilter);
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);
  const [search, debounceSearch, setSearch] = useDebounce('', 1500);

  //get all data promo ongkir
  const { data: dataPromos, isLoading, refetch: refectPromos } = useAllPromos(filters, 'free_delivery', debounceSearch, paginator.range);
  const promos = useMemo(()=>{
    if (!Array.isArray(dataPromos?.data)) return [];
    return dataPromos?.data;
  }, [dataPromos]);

  // validation with formik
  const formik = useFormik({
    initialValues: initialValueForm,
    validationSchema: schemaCreatePromoOngkir,
    onSubmit: () => {
      onSave();
    }
  });

  // form valid formik
  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  //toast success
  const showSuccess = (msg?:string) => {
    if (toast.current != null) {
      toast.current.show({
        severity: 'success',
        summary: 'Created',
        detail: msg || 'Saved successfully',
        life: 2500
      });
      const timeout = setTimeout(() => {
      }, 3000);
      return () => clearTimeout(timeout);
    }
  };

  const { data: dataFilterOption } = usePromosFilterOption('free_delivery');

  const options = useMemo(()=>{
    if (!Array.isArray(dataFilterOption?.data)) return [];
    const filterName = dataFilterOption?.data.map((promo)=>promo);
    return [
      {
        label: 'Name',
        items: filterName?.map((p)=>({
          label: p.name,
          name: 'name',
          value: p.id
        }))||[]
      },
      {
        label: 'Status',
        items: [
          {
            label: 'Active',
            name: 'status',
            value: true
          },
          {
            label: 'Inactive',
            name: 'status',
            value: false
          }
        ]
      }
    ];
  }, [dataFilterOption]);

  const handleSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>)=>{
    setSearch(currentTarget.value);
    const newKeyword = {
      name: 'Keyword',
      items: [{
        label: currentTarget.value,
        value: currentTarget.value,
        name: currentTarget.value
      }]
    };

    if (currentTarget.value != '') {
      setFilterHistory((prev: IFilterHistoryItems[]) => {
        const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
        if (existingIndex !== -1) {
          prev[existingIndex] = newKeyword;
        } else {
          prev.push(newKeyword);
        }
        return [...prev];
      });

      setPaginator({
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      });
    } else {
      handleDeleteFilterHistory('Keyword', ['Keyword']);
    }
  }, [perPage]);

  const handleFilter = useCallback((e, field: string) => {
    setItemFilters((prev) => ({
      ...prev,
      [field]: e.target ? e.target.value : e.value
    }));
  }, []);

  const onCollapse = (key: string, value: boolean) => {
    setCollapsible((prevState)=> {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  const firstFormValue = (key: string, value: Providers | Methods | boolean | string | Date | string[] | Methods[]) => {
    setFirstForm((prevState) => {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  const secondFormValue = (key: string, value: number | boolean | string ) => {
    setSecondForm((prevState) => {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  const codeGenValue = (key: string, value: number | string ) => {
    setCodeGen((prevState) => {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  const resetAllForm = useCallback(()=> {
    setFirstForm(initFirstForm);
    setSecondForm(initSecondForm);
    setCodeGen(initCodeGen);
  }, []);

  const getProviders = useCallback(async () => {
    const { data } = await supabase.from(TABLE.SHIPPING_PROVIDER).select('*')
      .eq('status', true)
      .not('code', 'eq', 'on_site_pick_up');
    setProviders(data as Providers[]);
  }, []);

  const getShippingMethodProviders = useCallback( async (providerIds: number[]) => {
    const { data } = await supabase.from(TABLE.SHIPPING_METHODS).select('*')
      .eq('status', true).in('shipping_provider_id', providerIds);

    setShipMethod(data as []);
  }, []);


  const getShippingProvider = useCallback(async (prov)=> {
    const { data } = await supabase.from(TABLE.SHIPPING_METHODS).select('*')
      .eq('status', true).in('id', prov);

    const providerId = uniqBy(data, 'shipping_provider_id')?.map((i) => i.shipping_provider_id);

    firstFormValue('provider', providerId);

    formik.setValues((prev)=>({
      ...prev,
      selectService: data as []
    }));
  }, [providers, firstForm]);

  const { id } = useParams();
  const onSave = useCallback(async ()=> {
    setIsLoadingCreate(true);

    const providerMerthodId = firstForm.methods.map((it) => String(it));


    if (id) {
      const { data } = await supabase.from(TABLE.PROMOS).update({
        code: firstForm.voucher_code,
        description: firstForm.description,
        image: 'https://img.inews.co.id/media/822/files/inews_new/2023/04/15/Aladinmall_kartu_kredit.png',
        amount_type: 'fixed',
        amount: secondForm.amount_discount,
        start_date: firstForm.startDate,
        end_date: firstForm.endDate,
        status: firstForm.is_active,
        accepted_payment_provider_ids: null,
        payment_bin_rule: null,
        quota: secondForm.quota,
        user_quota: secondForm.user_quota,
        is_quota_unlimited: secondForm.co_quota === 0,
        is_user_quota_unlimited: secondForm.user_quota === 0,
        min_discount_amount: secondForm.amount_discount,
        max_discount_amount: secondForm.amount_discount,
        min_payment_amount: secondForm.min_payment_amount,
        max_payment_amount: secondForm.min_payment_amount * 2,
        type: 'free_delivery',
        name: firstForm.name,
        target_platform: firstForm.isAllPlatform ? ['web', 'ios', 'android'] : null,
        is_public: !firstForm.isRegisteredCustomer
      }).eq('id', id).select().single();
      if (data) {
        const { error } = await supabase.functions.invoke('promo-engine', ({
          body: {
            'action': 'add',
            'promo': {
              'id': data.id,
              'code': data.code,
              'description': data.description,
              'image': data.image,
              'amount_type': data.amount_type,
              'amount': data.amount,
              'start_date': data.start_date,
              'end_date': data.end_date,
              'status': data.status,
              'type': 'free_delivery',
              'accepted_payment_provider_ids': data.accepted_payment_provider_ids,
              'payment_bin_rule': null,
              'quota': data.quota,
              'user_quota': data.user_quota,
              'target_platform': data.target_platform,
              'is_public': data.is_public,
              'is_quota_unlimited': data.is_quota_unlimited,
              'is_user_quota_unlimited': data.is_user_quota_unlimited,
              'min_discount_amount': data.min_discount_amount,
              'max_discount_amount': data.max_discount_amount,
              'min_payment_amount': data.min_payment_amount,
              'max_payment_amount': data.max_payment_amount,
              'promo_applied_merchant_variants': [
                {
                  'merchant_ids': [
                  ]
                }
              ],
              'promo_applied_product_variants': [
                {
                  'product_variant_ids': [
                  ]
                }
              ],
              'promo_applied_shipping_providers': [
                {
                  'shipping_provider_ids': providerMerthodId
                }
              ],
              'promo_applied_product_variant_promo_tags': [
                {
                  'product_variant_promo_tag_ids': [
                  ]
                }
              ],
              'promo_excluded_product_variants': [
                {
                  'product_variant_ids': [
                  ]
                }
              ]
            }
          }
        }));
        const { error: shippingError } = await supabase.from(TABLE.SHIPPING_APPLIED).update({
          promo_id: data.id,
          shipping_provider_ids: providerMerthodId
        }).eq('promo_id', data.id);
        if (shippingError) {
          showFailedToast();
          return;
        }
        if (error) {
          showFailedToast();
          return;
        }
        setIsLoadingCreate(false);
        showSuccessToastCreatePromo(true);
        resetAllForm();
        refectPromos();
      }
    } else {
      const { data } = await supabase.from(TABLE.PROMOS).insert({
        code: firstForm.voucher_code,
        description: firstForm.description,
        image: 'https://img.inews.co.id/media/822/files/inews_new/2023/04/15/Aladinmall_kartu_kredit.png',
        amount_type: 'fixed',
        amount: secondForm.amount_discount,
        start_date: firstForm.startDate,
        end_date: firstForm.endDate,
        status: firstForm.is_active,
        accepted_payment_provider_ids: null,
        payment_bin_rule: null,
        quota: secondForm.quota,
        user_quota: secondForm.user_quota,
        is_quota_unlimited: secondForm.co_quota === 0,
        is_user_quota_unlimited: secondForm.user_quota === 0,
        min_discount_amount: secondForm.amount_discount,
        max_discount_amount: secondForm.amount_discount,
        min_payment_amount: secondForm.min_payment_amount,
        max_payment_amount: secondForm.min_payment_amount * 2,
        type: 'free_delivery',
        name: firstForm.name,
        target_platform: firstForm.isAllPlatform ? ['web', 'ios', 'android'] : null,
        is_public: !firstForm.isRegisteredCustomer
      }).select().single();
      if (data) {
        const { error } = await supabase.functions.invoke('promo-engine', ({
          body: {
            'action': 'add',
            'promo': {
              'id': data.id,
              'code': data.code,
              'description': data.description,
              'image': data.image,
              'amount_type': data.amount_type,
              'amount': data.amount,
              'start_date': data.start_date,
              'end_date': data.end_date,
              'status': data.status,
              'type': 'free_delivery',
              'accepted_payment_provider_ids': data.accepted_payment_provider_ids,
              'payment_bin_rule': null,
              'quota': data.quota,
              'target_platform': data.target_platform,
              'is_public': data.is_public,
              'user_quota': data.user_quota,
              'is_quota_unlimited': data.is_quota_unlimited,
              'is_user_quota_unlimited': data.is_user_quota_unlimited,
              'min_discount_amount': data.min_discount_amount,
              'max_discount_amount': data.max_discount_amount,
              'min_payment_amount': data.min_payment_amount,
              'max_payment_amount': data.max_payment_amount,
              'promo_applied_merchant_variants': [
                {
                  'merchant_ids': [
                  ]
                }
              ],
              'promo_applied_product_variants': [
                {
                  'product_variant_ids': [
                  ]
                }
              ],
              'promo_applied_shipping_providers': [
                {
                  'shipping_provider_ids': providerMerthodId
                }
              ],
              'promo_applied_product_variant_promo_tags': [
                {
                  'product_variant_promo_tag_ids': [
                  ]
                }
              ],
              'promo_excluded_product_variants': [
                {
                  'product_variant_ids': [
                  ]
                }
              ]
            }
          }
        }));
        const { error: shippingError } = await supabase.from(TABLE.SHIPPING_APPLIED).insert({
          promo_id: data.id,
          shipping_provider_ids: providerMerthodId
        });
        if (shippingError) {
          showFailedToast();
          return;
        }
        if (error) {
          showFailedToast();
          return;
        }
        setIsLoadingCreate(false);
        showSuccessToastCreatePromo();
        resetAllForm();
        refectPromos();
      }
    }
  }, [firstForm, secondForm]);

  const getPromoById = useCallback(async ()=> {
    const { data } = await supabase.from(TABLE.PROMOS).select('*').eq('id', id).single();
    if (data) {
      // check when target all platform
      const isAllPlatform = data?.target_platform?.includes('web') && data?.target_platform?.includes('ios') && data?.target_platform?.includes('android');
      const { data: shippping } = await supabase.from(TABLE.SHIPPING_APPLIED).select('shipping_provider_ids').eq('promo_id', id).single();
      if (!shippping) return;
      const methodProviderIds = shippping.shipping_provider_ids.map((item) => parseInt(item));

      firstFormValue('methods', methodProviderIds);
      firstFormValue('name', data.name);
      firstFormValue('platforms', ['web', 'ios', 'ard']);
      firstFormValue('customers', ['rcs']);
      firstFormValue('voucher_code', data.code);
      firstFormValue('description', data.description);
      firstFormValue('startDate', new Date(data.start_date));
      firstFormValue('endDate', new Date(data.end_date));
      firstFormValue('is_active', data.status);
      firstFormValue('isRegisteredCustomer', !data.is_public);
      firstFormValue('isAllPlatform', isAllPlatform);
      secondFormValue('quota', data.quota);
      secondFormValue('amount_discount', data.amount);
      secondFormValue('type', data.amount_type);
      secondFormValue('user_quota', data.user_quota);
      secondFormValue('max_discount_amount', data.max_discount_amount);
      secondFormValue('min_payment_amount', data.min_payment_amount);
      secondFormValue('is_user_quota_unlimited', data.user_quota === 0);
      secondFormValue('is_no_minimum_subtotal', data.min_payment_amount === 0);
      formik.setValues((prev)=>({
        ...prev,
        customerGroup: ['rsc'],
        ammountDiscount: data.amount,
        endDate: data.end_date,
        startDate: data.start_date,
        maxDiscount: data.max_discount_amount,
        minOrder: data.min_payment_amount,
        platform: ['web', 'ios', 'amd'],
        promoName: data.name,
        promoQuota: data.quota,
        userUsage: data.user_quota,
        nominalDiscount: data.amount_type,
        selectCourier: methodProviderIds
      }));
      getProviders();
    }
  }, [shipMethod, providers, firstForm, id]);


  const onDelete = async (id: string) => {
    const { error: errorDeleteShippingApplied } = await deleteShippingApplied(id);
    const { error: errorDeleteAmountCounts } = await deletePromoAmountCounts(id);
    const { error: errorDeletePromoRules } = await deletePromoRules(id);
    const { error: errorDeletePromos } = await deletePromos(id);
    if (errorDeleteShippingApplied || errorDeletePromoRules || errorDeletePromos || errorDeleteAmountCounts) {
      showFailedDeleteToast();
      return;
    };
    showSuccessToast();
    refectPromos();
  };

  const showFailedDeleteToast = (message?: string) => {
    toast.current?.show({
      severity: 'error',
      summary: 'Warning',
      detail: message ? message : 'Delete Failed'
    });
  };

  const showFailedToast = () => {
    setIsLoadingCreate(false);
    const isEdit = location.pathname.includes('edit');
    toast.current?.show({
      severity: 'error',
      summary: 'Warning',
      detail: isEdit ? 'Failed Edit Promo' : 'Failed Create Promo'
    });
  };

  const showSuccessToast = () => {
    toast.current?.show({
      severity: 'success',
      summary: 'Confirmed',
      detail: 'Promo Ongkir deleted succesfully',
      life: 3000
    });
  };

  const showSuccessToastCreatePromo = (isEdit = false) => {
    toast.current?.show({
      severity: 'success',
      summary: 'Confirmed',
      detail: isEdit ? 'Promo Ongkir successfully edited' : 'Promo Ongkir successfully created',
      life: 3000
    });

    setTimeout(() => {
      navigate('/ongkir');
    }, 800);
  };

  const confirmDelete = (id: string) => {
    confirmDialog({
      message: 'Are you sure you want to delete this record?',
      header: 'Delete Confirmation',
      acceptClassName: 'p-button-danger',
      accept() {
        onDelete(id);
      }
    });
  };

  const handleDuplicateRow = useCallback(async (promoOngkir:Object) => {
    const { data: dataOngkir } = await supabase
      .from('trade.promos')
      .select(
        `id,
        promo_applied_shipping_providers(id, shipping_provider_ids)`
      )
      .eq('id', promoOngkir['id'])
      .single();
    const delivery = dataOngkir?.promo_applied_shipping_providers![0].shipping_provider_ids || [];
    delete promoOngkir['id'];
    delete promoOngkir['code'];
    delete promoOngkir['category_promos'];
    const { data, error } = await supabase.from(TABLE.PROMOS).insert(promoOngkir).select().single();
    if (data) {
      const { error: errorRule } = await supabase.functions.invoke('promo-engine', ({
        body: {
          'action': 'add',
          'promo': {
            'id': data.id,
            'code': data.code,
            'description': data.description,
            'image': data.image,
            'amount_type': data.amount_type,
            'amount': data.amount,
            'start_date': data.start_date,
            'end_date': data.end_date,
            'status': data.status,
            'type': 'product',
            'accepted_payment_provider_ids': data.accepted_payment_provider_ids,
            'payment_bin_rule': data.payment_bin_rule,
            'is_payment_cc': data.is_payment_cc,
            'quota': data.quota,
            'user_quota': data.user_quota,
            'is_quota_unlimited': data.is_quota_unlimited,
            'is_user_quota_unlimited': data.is_user_quota_unlimited,
            'min_discount_amount': data.min_discount_amount,
            'max_discount_amount': data.max_discount_amount,
            'min_payment_amount': data.min_payment_amount,
            'max_payment_amount': data.max_payment_amount,
            'promo_applied_merchant_variants': [
              {
                'merchant_ids': []
              }
            ],
            'promo_applied_product_variants': [
              {
                'product_variant_ids': []
              }
            ],
            'promo_applied_shipping_providers': [
              {
                'shipping_provider_ids': delivery
              }
            ],
            'promo_applied_product_variant_promo_tags': [
              {
                'product_variant_promo_tag_ids': []
              }
            ],
            'promo_excluded_product_variants': [
              {
                'product_variant_ids': []
              }
            ]
          }
        }
      }));
      const { error: shippingError } = await supabase.from(TABLE.SHIPPING_APPLIED).insert({
        promo_id: data.id,
        shipping_provider_ids: delivery
      });
      if (shippingError) {
        showFailedDeleteToast(shippingError.details);
      }
      if (errorRule) {
        showFailedDeleteToast(errorRule);
        return;
      }
      showSuccess('Duplicate Promo Ongkir Successfull');
      refectPromos();
    }
    if (error) {
      showFailedDeleteToast(error.details);
    }
  }, []);

  const onListStatus = useCallback(async (promoOngkir:Object) => {
    const { data: dataOngkir } = await supabase
      .from('trade.promos')
      .select(
        `id,
        promo_applied_shipping_providers(id, shipping_provider_ids)`
      )
      .eq('id', promoOngkir['id'])
      .single();
    const delivery = dataOngkir?.promo_applied_shipping_providers![0].shipping_provider_ids || [];
    delete promoOngkir['category_promos'];
    const payload = {
      ...promoOngkir,
      status: !promoOngkir['status']
    };
    const { data, error } = await supabase.from(TABLE.PROMOS).upsert(payload).select().single();
    if (data) {
      const { error: errorRule } = await supabase.functions.invoke('promo-engine', ({
        body: {
          'action': 'add',
          'promo': {
            'id': data.id,
            'code': data.code,
            'description': data.description,
            'image': data.image,
            'amount_type': data.amount_type,
            'amount': data.amount,
            'start_date': data.start_date,
            'end_date': data.end_date,
            'status': data.status,
            'type': 'product',
            'accepted_payment_provider_ids': data.accepted_payment_provider_ids,
            'payment_bin_rule': data.payment_bin_rule,
            'is_payment_cc': data.is_payment_cc,
            'quota': data.quota,
            'user_quota': data.user_quota,
            'is_quota_unlimited': data.is_quota_unlimited,
            'is_user_quota_unlimited': data.is_user_quota_unlimited,
            'min_discount_amount': data.min_discount_amount,
            'max_discount_amount': data.max_discount_amount,
            'min_payment_amount': data.min_payment_amount,
            'max_payment_amount': data.max_payment_amount,
            'promo_applied_merchant_variants': [
              {
                'merchant_ids': []
              }
            ],
            'promo_applied_product_variants': [
              {
                'product_variant_ids': []
              }
            ],
            'promo_applied_shipping_providers': [
              {
                'shipping_provider_ids': delivery
              }
            ],
            'promo_applied_product_variant_promo_tags': [
              {
                'product_variant_promo_tag_ids': []
              }
            ],
            'promo_excluded_product_variants': [
              {
                'product_variant_ids': []
              }
            ]
          }
        }
      }));
      if (errorRule) showFailedDeleteToast(errorRule);
      refectPromos();
    }
    if (error) {
      showFailedDeleteToast(error.details);
    }
  }, []);

  useEffect(()=> {
    getProviders();
  }, [onSave]);

  useEffect(()=> {
    if (id) {
      getPromoById();
    }
  }, [id]);

  useEffect(() => {
    getShippingMethodProviders(firstForm.provider as []);
  }, [firstForm.provider]);

  useEffect(()=> {
    if (id) {
      getShippingProvider(firstForm.methods);
    }
  }, [firstForm.methods]);

  const totalRecords = useMemo(() => {
    return dataPromos?.count ? dataPromos.count : 0;
  }, [dataPromos]);

  const totalPages = useMemo(() => {
    return Math.ceil(totalRecords / perPage);
  }, [totalRecords, perPage]);


  const handleClickNext = useCallback(() => {
    paginator.currentPage <= totalPages &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: paginator.currentPage + 1,
        range: {
          start: paginator.range.start + perPage,
          end: paginator.range.end + perPage
        }
      }));
  }, [paginator, totalPages, perPage]);

  const handleClickPrev = useCallback(() => {
    paginator.range.start > 0 &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: prev.currentPage - 1,
        range: {
          start: prev.range.start - perPage,
          end: prev.range.end - perPage
        }
      }));
  }, [paginator, perPage]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent) => {
    setPerPage(event.value);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, totalRecords - 1);

      setPaginator(() => ({
        currentPage: jumpToPage > totalPages ? totalPages : value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, totalRecords]);

  const handleClearFilter = useCallback(() => {
    setLastFilterPage({
      ...lastFilterPage,
      promoOngkir: ''
    });
    setItemFilters(initialFilter);
    setFilters(initialFilter);
    setFilterHistory([]);
    setSearch('');
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [lastFilterPage, perPage]);

  const handleDeleteFilterHistory = useCallback(
    (key: string, value: string[]) => {
      const items = value[0].split(',');
      items.forEach((i) => {
        setFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));

        setItemFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));
      });

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setSearch('');
    },
    []
  );

  const handleClickSubmitFilter = useCallback(() => {
    setFilters(itemFilters);
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [itemFilters]);

  const storeFilterHistory = useCallback((filter) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter?.show_date && filter.show_date[0]) {
      let filterDate = `${dayjs(filter.show_date[0]).format('YYYY-MM-DD')}`;
      if (filter.show_date.length > 1 && filter.show_date[1] !== null) {
        filterDate = `${dayjs(filter.show_date[0]).format('YYYY-MM-DD')} - ${dayjs(filter.show_date[1]).format('YYYY-MM-DD')}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Valid Date', 'show_date', filterDate));
    }

    if (filter.name !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Name', 'name', filter.name));
    }

    if (filter.status !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Status', 'status', filter.status.toUpperCase()));
    }

    setFilterHistory(filterHistoryItems);
  }, []);

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  useEffect(() => {
    // update store visitedPage with latest page
    setVisitedPage({
      ...visitedPage,
      promoOngkir: paginator.currentPage
    });
  }, [paginator.currentPage]);

  // update store lastFilterPage with latest filter
  useEffect(() => {
    setLastFilterPage({
      ...lastFilterPage,
      promoOngkir: JSON.stringify(filters)
    });
  }, [filters]);

  return {
    data: {
      id,
      days,
      type,
      areas,
      toast,
      promos,
      selectedFilter,
      formik,
      codeGen,
      collapsible,
      providers,
      firstForm,
      shipMethod,
      secondForm,
      optionsPlatform,
      optionsCustomer,
      isLoading,
      options,
      isLoadingCreate,
      totalPages,
      totalRecords,
      perPage,
      paginator,
      itemFilters,
      filterHistory,
      search
    },
    method: {
      isFormFieldValid,
      firstFormValue,
      secondFormValue,
      codeGenValue,
      resetAllForm,
      setSelectedFilter,
      onSave,
      onListStatus,
      onCollapse,
      confirmDelete,
      handleDuplicateRow,
      handleClickNext,
      handleClickPrev,
      handleChangeDropdownPage,
      handleChangeJumpTopage,
      handleJumpToPage,
      handleClearFilter,
      handleDeleteFilterHistory,
      handleClickSubmitFilter,
      handleSearch,
      handleFilter
    }
  };
};

export default useCustom;
