import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import dayjs from 'dayjs';
import { Nullable } from 'primereact/ts-helpers';
import { Database as DB_USER, Json } from '@/types/services/user';
import { IFormCreate } from '@/views/UserManagement/UserGroup/helper';
import { AdminUserAttributes, PostgrestSingleResponse } from '@supabase/supabase-js';
import { useMutation, useQuery } from '@tanstack/react-query';

type Team = DB_USER['public']['Tables']['teams']['Row'];
type Members = DB_USER['public']['Tables']['members']['Row'];
type Accounts = DB_USER['public']['Tables']['accounts']['Row'];
type Applications = DB_USER['public']['Tables']['applications']['Row']
type TeamMenus = {
  created_at: string | null;
  id: number;
  menu_id: number | null;
  privileges: Json;
  team_id: number | null;
  create: boolean | null;
  read: boolean | null;
  update: boolean | null;
  delete: boolean | null;
};

export type Menus = {
  application_id: number | null;
  code: string | null;
  created_at: string | null;
  id: number;
  name: string | null;
  parent_id: number | null;
  path_uri: string | null;
};

interface MenusPermission extends TeamMenus {
  menus: Menus
}

interface MemberGroups extends Members {
  accounts: Accounts;
}

export interface PayloadEdit {
  id: number;
  name: string;
  description: string;
  status: boolean;
}

type Filter = {
  type: Array<string>;
  from: Nullable<string | Date | Date[]>
  to: Nullable<string | Date | Date[]>
  status: Array<boolean>
}

const getTeamById = async (id: number) => {
  return (await supabase
    .from(TABLE.TEAM)
    .select('*')
    .eq('id', id)
    .single()) as PostgrestSingleResponse<Team>;
};

const getMemmbersCount = async (id: number, filter?: Filter) => {
  const query = supabase
    .from(TABLE.MEMBER)
    .select('*, accounts!inner(id,type,first_name,last_name,created_at,status)')
    .eq('team_id', id);


  if (filter?.status && filter.status.length > 0) {
    query.in('accounts.status', filter.status);
  }

  if (filter?.from && filter.to) {
    const fromDate = dayjs(String(filter?.from)).format('YYYY-MM-DD HH:mm:ss');
    const toDate = dayjs(String(filter?.to)).format('YYYY-MM-DD HH:mm:ss');
    query.gte('accounts.created_at', fromDate).lte('accounts.created_at', toDate);
  }

  if (filter?.type && filter.type.length > 0) {
    query.in('accounts.type', filter.type);
  }

  return await query as PostgrestSingleResponse<MemberGroups[]>;
};

const getLastTeamId = async () => {
  return (await supabase
    .from(TABLE.TEAM)
    .select('id')
    .order('id', { ascending: false })
    .limit(1)) as PostgrestSingleResponse<Team>;
};

const getDetailMember = async (id: number, idMember: number) => {
  return (await supabase
    .from(TABLE.MEMBER)
    .select('*,accounts!inner(*)')
    .eq('team_id', id)
    .eq('id', idMember)
    .single()) as PostgrestSingleResponse<MemberGroups>;
};

const getMemberFilterOption = async (id: number, idMember?:number) => {
  return await supabase
    .from(TABLE.MEMBER)
    .select('id,accounts!inner(type)').range(0, 10)
    .eq('team_id', id) as PostgrestSingleResponse<MemberGroups[]>;
};

const getApplication = async () => {
  return (await supabase
    .from(TABLE.APPLICATION)
    .select('*')
    .range(0, 10)
  ) as PostgrestSingleResponse<Applications[]>;
};

export const insertGroupAdmin = async (payload: IFormCreate) => {
  return await supabase.from(TABLE.TEAM).insert([payload]);
};

const getMenuPermissions = async (id: number) => {
  return (await supabase
    .from(TABLE.TEAM_MENUS)
    .select('*, menus(*)')
    .eq('team_id', id)
    .order('id', { ascending: true })) as PostgrestSingleResponse<MenusPermission[]>;
};

const getTeamsByAccountId = async (applicationId: number) => {
  return await supabase
    .from(TABLE.TEAM)
    .select('*')
    .eq('application_id', applicationId) as PostgrestSingleResponse<Team[]>;
};

const getMenuPermissionsByAccountId = async (applicationId: number) => {
  return await supabase
    .from(TABLE.MENUS)
    .select('*')
    .eq('application_id', applicationId) as PostgrestSingleResponse<Menus[]>;
};


export const updateUserById = async (id: string, data: AdminUserAttributes) => {
  const response = await fetch(`${import.meta.env.VITE_APP_SUPABASE_URL}/auth/v1/admin/users/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'ApiKey': import.meta.env.VITE_APP_SUPABASE_KEY,
      'Authorization':
        'Bearer ' +
        (await supabase.auth.getSession()).data.session?.access_token
    },
    body: JSON.stringify(data)
  });

  const resJson = await response.json();
  if (response.status >= 400) {
    return { data: {}, error: resJson };
  }

  return { data: { user: resJson }, error: undefined };
};

export const createUser = async (data: AdminUserAttributes) => {
  const response = await fetch(
    `${import.meta.env.VITE_APP_SUPABASE_URL}/auth/v1/admin/users`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'ApiKey': import.meta.env.VITE_APP_SUPABASE_KEY,
        'Authorization':
          'Bearer ' +
          (
            await supabase.auth.getSession()
          ).data.session?.access_token
      },
      body: JSON.stringify(data)
    }
  );

  const resJson = await response.json();
  if (response.status >= 400) {
    return { data: {}, error: resJson };
  }

  return { data: { user: resJson }, error: undefined };
};

export const deleteUserById = async (id: string) => {
  const response = await fetch(`${import.meta.env.VITE_APP_SUPABASE_URL}/auth/v1/admin/users/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'ApiKey': import.meta.env.VITE_APP_SUPABASE_KEY,
      'Authorization':
        'Bearer ' +
        (await supabase.auth.getSession()).data.session?.access_token
    },
    body: JSON.stringify({ should_soft_delete: false })
  });

  const resJson = await response.json();
  if (response.status >= 400) {
    return { data: {}, error: resJson };
  }

  return { data: { user: resJson }, error: undefined };
};

export const useLastTeamId = () =>
  useQuery({
    queryKey: ['lastId'],
    queryFn: getLastTeamId,
    refetchOnWindowFocus: false
  });

export const useGetApplication = () =>
  useQuery({
    queryKey: ['applications'],
    queryFn: getApplication,
    refetchOnWindowFocus: false
  });

export const useGetTeamById = (id: number) =>
  useQuery({
    queryKey: ['getTeamById', id],
    queryFn: async () => await getTeamById(id),
    refetchOnWindowFocus: false
  });

export const useGetCountMembers = (id: number, filter?: Filter) =>
  useQuery({
    queryKey: ['getCountMembers', id, filter],
    queryFn: async () => await getMemmbersCount(id, filter),
    refetchOnWindowFocus: false
  });

export const useMemberFilterOptions = (id: number) =>
  useQuery({
    queryKey: ['membersType'],
    queryFn: () => getMemberFilterOption(id),
    refetchOnWindowFocus: false
  });

export const useDetailMember = (id: number, idMember:number) =>
  useQuery({
    queryKey: ['detailMember'],
    queryFn: () => getDetailMember(id, idMember),
    refetchOnWindowFocus: false
  });

export const useGetMenus = (id: number) =>
  useQuery({
    queryKey: ['getMenus', id],
    queryFn: () => getMenuPermissions(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });

export const useGetTeamsByAccoundId = (applicationId: number) =>
  useQuery({
    queryKey: ['getTeamMenusByApplicationId', applicationId],
    queryFn: () => getTeamsByAccountId(applicationId),
    enabled: !!applicationId,
    refetchOnWindowFocus: false
  });

export const useGetMenusByApplicationId = (applicationId: number) =>
  useQuery({
    queryKey: ['getMenusByApllicationId', applicationId],
    queryFn: () => getMenuPermissionsByAccountId(applicationId),
    enabled: !!applicationId,
    refetchOnWindowFocus: false
  });

export const useUpdateGroupInformation = () =>
  useMutation({
    mutationKey: ['updateGroupInformation'],
    mutationFn: async (payload: PayloadEdit) => {
      return await supabase
        .from(TABLE.TEAM)
        .update({ name: payload.name, description: payload.description, status: payload.status })
        .eq('id', payload.id);
    }
  });
