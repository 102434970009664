import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { IPagination } from '@/types/pagination';
import { Database } from '@/types/services/trade';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

type Promo = Database['public']['Tables']['promos']['Row'];
type PromoCategories = {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
};

export interface Promos extends Promo {
  category_promos: PromoCategories;
  is_used: boolean
}

type PayloadPromos = Database['public']['Tables']['promos']['Insert']

export interface IFilterVouchers {
  name: string;
  valid_date: Date[];
  status: string;
  code: string;
};

export interface IFilterPromos {
  name: string;
  show_date: Date[];
  category: string;
  status: string ;
};

const getAllVouchers = async (filter?: IFilterVouchers, search?: string, page: number = 1, perPage: number = 10) => {
  const params = {};

  // Name & Search
  if ((search && search != '') || (filter?.name && filter?.name != '')) {
    params['keyword'] = search || filter?.name;
  }

  // Status
  if (filter?.status && filter?.status != '') {
    params['is_active'] = [(filter?.status == 'active')];
  }

  if ((filter?.valid_date && filter?.valid_date?.length != 0) || (filter?.code && filter?.code != '')) {
    params['size'] = null;
  } else {
    params['size'] = perPage;
  }

  params['page'] = page;
  const results = await supabase.rpc('trade.get_promos', params);

  // return data by filter valid date
  if (filter?.valid_date && filter?.valid_date?.length != 0) {
    const fromDate = dayjs(String(filter?.valid_date[0])).format('YYYY-MM-DD');
    const filtereddata = Array.isArray(results?.data?.data) ? results?.data?.['data']?.filter((item: Promo) => {
      const periodeStart = dayjs(String(item.periode_start)).format('YYYY-MM-DD');
      if (filter?.valid_date.length > 1 && filter?.valid_date[1] != null) {
        const periodeEnd = dayjs(String(item.periode_end)).format('YYYY-MM-DD');
        const toDate = dayjs(String(filter?.valid_date[1])).format('YYYY-MM-DD');
        return dayjs(periodeEnd).isBefore(toDate);
      }
      return dayjs(periodeStart).isAfter(fromDate);
    }) : [];

    const data = filtereddata.slice((perPage * page - perPage), (page * perPage - 1));

    return { data: { data, total_data: filtereddata.length } };
  }

  // return data by filter code
  if (filter?.code && filter?.code != '') {
    const filtereddata = Array.isArray(results?.data?.data) ?
      results?.data?.['data']?.filter((item) => {
        return item?.code != null && item?.code !== '' && item?.code?.toLowerCase().includes(filter?.code.toLowerCase());
      }) :
      [];
    const data = filtereddata.slice((perPage * page - perPage), (page * perPage - 1));

    return { data: { data, total_data: filtereddata.length } };
  }

  return results;
};

const getAllPromos = async (filter?: IFilterPromos, type?:string, search?: string, range: IPagination = { start: 0, end: 10 }) => {
  const query = supabase
    .from(TABLE.PROMOS)
    .select('*,category_promos(id,name)', { count: 'exact' })
    .range(range.start, range.end)
    .order('created_at', { ascending: false });

  // Name
  if (filter?.name != '') {
    query.ilike('name', `%${filter?.name}%`);
  }

  // Category
  if (filter?.category != '') {
    query.eq('category_promos.name', filter?.category);
  }

  // Type
  if (type != '') {
    query.eq('type', type);
  }

  // Show Date
  if (filter?.show_date && filter?.show_date?.length != 0) {
    const fromDate = dayjs(String(filter?.show_date[0])).format('YYYY-MM-DD');
    query.gte('start_date:date', fromDate);

    if (filter?.show_date.length > 1 && filter?.show_date[1] != null) {
      const toDate = dayjs(String(filter?.show_date[1])).format('YYYY-MM-DD');
      query.lte('end_date:date', toDate);
    }
  }

  // Status
  if (filter?.status != '') {
    query.eq('status', (filter?.status == 'active'));
  }

  if (search) {
    query.or(`name.ilike.%${search}%`).or(`name.ilike.%${search}%`, { foreignTable: 'category_promos' });
  }

  return (await query) as PostgrestSingleResponse<Promos[]>;
};

const getTotalPromo = async (type: string) => {
  return (await supabase
    .from(TABLE.PROMOS)
    .select('*', { count: 'exact', head: true })
    .eq('type', type)
    .limit(50)) as PostgrestSingleResponse<Promos[]>;
};

export const insertPromos = async (payload: PayloadPromos) => {
  return await supabase.from(TABLE.PROMOS).insert([payload]);
};

export const deleteShippingApplied = async (id:string) =>{
  return await supabase.from(TABLE.SHIPPING_APPLIED).delete({ count: 'exact' }).eq('promo_id', id);
};
export const deletePromoRules = async (id:string) =>{
  return await supabase.from(TABLE.PROMO_RULES).delete({ count: 'exact' }).eq('promo_id', id);
};
export const deletePromos = async (id:string) =>{
  return await supabase.from(TABLE.PROMOS).delete({ count: 'exact' }).eq('id', id);
};

export const deletePromoAmountCounts = async (id: string) => {
  return await supabase.from(TABLE.PROMO_AMOUNT_COUNTS).delete({ count: 'exact' }).eq('promo_id', id);
};

export const deletePromosExcludedProduct = async (id:string) =>{
  return await supabase.from(TABLE.PROMO_EXCLUDE_PRODUCT).delete({ count: 'exact' }).eq('promo_id', id);
};

export const deletePromosAppliedMerchants = async (id:string) =>{
  return await supabase.from(TABLE.PROMO_APPLIED_MERCHANTS).delete({ count: 'exact' }).eq('promo_id', id);
};

export const deletePromosAmountCounts = async (id:string) =>{
  return await supabase.from(TABLE.PROMO_AMOUNT_COUNTS).delete({ count: 'exact' }).eq('promo_id', id);
};

export const deletePromosAppliedProductVariantTags = async (id:string) =>{
  return await supabase.from(TABLE.PROMO_APPLIED_PROMO_TAGS).delete({ count: 'exact' }).eq('promo_id', id);
};

export const deletePromoAppliedsProductVariant = async (id:string) =>{
  return await supabase.from(TABLE.PROMO_APPLIED_PRODUCT).delete({ count: 'exact' }).eq('promo_id', id);
};

const getPromosFilterOption = async (type?:string) => {
  const query = supabase
    .from(TABLE.PROMOS)
    .select('id,name,type,status,category_promos(id,name)')
    .range(0, 25);

  if (type) {
    query.eq('type', type);
  }

  return await query as PostgrestSingleResponse<Promos[]>;
};

const getCategoryPromosFilterOption = async (type?:string) => {
  const query = supabase
    .from(TABLE.CATEGORY_PROMO)
    .select('id,name')
    .range(0, 25);

  return await query as PostgrestSingleResponse<Promos[]>;
};

export const useAllPromos = (filter?: IFilterPromos, type?:string, search?: string, range?: IPagination) =>
  useQuery({
    queryKey: ['promos', filter, search, range],
    queryFn: () => getAllPromos(filter, type, search, range),
    refetchOnWindowFocus: false
  });

export const useAllVouchers = (filter?: IFilterVouchers, search?: string, page: number = 1, perPage: number = 10) =>
  useQuery({
    queryKey: ['vouchers', filter, search, page, perPage],
    queryFn: () => getAllVouchers(filter, search, page, perPage),
    refetchOnWindowFocus: false
  });

export const useTotalPromos = (type: string) =>
  useQuery({
    queryKey: ['total-promos', type],
    queryFn: () => getTotalPromo(type),
    refetchOnWindowFocus: false
  });

export const usePromosFilterOption = (type?:string) =>
  useQuery({
    queryKey: ['promos-option'],
    queryFn: ()=>getPromosFilterOption(type),
    refetchOnWindowFocus: false
  });

export const useCategoryPromosFilterOption = (type?:string) =>
  useQuery({
    queryKey: ['category-promos-option'],
    queryFn: ()=>getCategoryPromosFilterOption(type),
    refetchOnWindowFocus: false
  });
