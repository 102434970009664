import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAllVariants, Variants } from '@/services/rest/variant';
import { Specification, useSpecification } from '@/services/rest/specification';
import { InputSwitchChangeEvent } from 'primereact/inputswitch';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { useNavigate, useParams } from 'react-router-dom';
import { PayloadAttributeSet, upsertAttributeSet, useAllAttributeSetById, useAllAttributeSetName } from '@/services/rest/attributeSet';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import { schemaAttributeSets } from './validation';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { debounce, uniqBy } from 'lodash';
import { supabase } from '@/lib/supabase';
import { TABLE } from '@/constants';

interface IFormValue {
  name: string;
  is_active: boolean;
  description: string;
  specification_ids: Array<string>;
  variant_ids: Array<string>;
}
interface IFormikState {
  name: string;
  specification_ids: Array<string>;
  variant_ids: Array<string>;
}
const useCustom = () => {
  const initialForm: IFormValue = {
    name: '',
    is_active: false,
    description: '',
    specification_ids: [],
    variant_ids: []
  };
  const initialFormik:IFormikState = {
    name: '',
    specification_ids: [],
    variant_ids: []
  };
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [responseMessage] = useState<string>('Terjadi Kesalahan');
  const [formValue, setFormValue] = useState<IFormValue>(initialForm);
  const [checked, setChecked] = useState<boolean>(false);
  const [specification, setSpecification] = useState<Specification[]>([]);
  const [variant, setVariant] = useState<Variants[]>([]);
  const toast = useRef<Toast>(null);

  const navigate = useNavigate();

  const params = useParams();

  const { data: dataDetailAttributeset, isLoading: isLoadingAttributeSet } = useAllAttributeSetById(params.id||params.detailId);

  const detailAttributeSet = useMemo(()=>{
    return dataDetailAttributeset && dataDetailAttributeset.data;
  }, [dataDetailAttributeset]);

  const { data: dataAttributeSetName } = useAllAttributeSetName(detailAttributeSet?.name);

  const attributeSetNames = useMemo(()=>{
    if (!Array.isArray(dataAttributeSetName?.data)) return [];
    return dataAttributeSetName?.data.map((item)=>{
      return item.name?.toLocaleLowerCase();
    });
  }, [dataAttributeSetName]);


  const { data: dataVariants, isLoading: isLoadingVariant } = useAllVariants();

  const showErrorToast = (msg: string) => {
    toast.current?.show({
      severity: 'error',
      summary: 'Failed',
      detail: msg,
      life: 2000
    });
  };

  const showSucessToast = (msg: string) => {
    toast.current?.show({
      severity: 'success',
      summary: 'Confirmed',
      detail: msg,
      life: 2000
    });

    navigate('/attribute-set');
  };

  const optionListVariant = useMemo(() => {
    if (!Array.isArray(variant)) return [];
    return variant.map((item) => ({
      label: `(ID: ${item.id}) ${item.name}`,
      value: String(item.id)
    }));
  }, [variant]);

  const { data: dataSpecification, isLoading: isLoadingSpecification } = useSpecification();

  const optionListSpecification = useMemo(() => {
    if (!Array.isArray(specification)) return [];
    return specification?.map((item) => ({
      label: `(ID: ${item.id}) ${item.name}`,
      value: String(item.id)
    }));
  }, [specification]);

  const handleSearchDebounce = debounce(async (findName: string | [], type: string ) => {
    if (!findName) {
      return;
    }
    if (type === 'specification') {
      const { data } = await supabase.from(TABLE.SPESIFICATION).select('*')
        .is('deleted_at', null)
        .ilike('name', `%${findName}%`).limit(10) as PostgrestSingleResponse<Specification[]>;

      if (data) {
        if (specification) {
          setSpecification((prev)=> {
            const newData = [...prev, ...data];
            const uniqueData = uniqBy(newData, 'id');
            return uniqueData;
          });
        } else {
          setSpecification(data as Specification[]);
        }
      }
    }

    if (type === 'variant') {
      const { data } = await supabase.from(TABLE.VARIANTS).select('*')
        .ilike('name', `%${findName}%`).limit(10) as PostgrestSingleResponse<Variants[]>;

      if (data) {
        if (specification) {
          setVariant((prev)=> {
            const newData = [...prev, ...data];
            const uniqueData = uniqBy(newData, 'id');
            return uniqueData;
          });
        } else {
          setVariant(data as Variants[]);
        }
      }
    }
  }, 300);

  const handleSearchDropdown = async (findName: string | [], type: string) => {
    handleSearchDebounce(findName, type);
  };

  const handleChangeDropdown = useCallback(
    (index: number, key: string) => (event: DropdownChangeEvent) => {
      formValue[key].map(() => (formValue[key][index] = event.value));
      //set value dropdown to formik state
      formik.setFieldValue(key, [...formValue[key]]);
      //set value dropdown to state
      setFormValue((prev) => ({
        ...prev,
        [key]: [...formValue[key]]
      }));
    },
    [formValue]
  );

  const handleAddOption = useCallback(
    (key: string) => () => {
      //push array to state
      formValue[key].push('');
      setFormValue((prev) => ({
        ...prev,
        [key]: [...formValue[key]]
      }));
    },
    [formValue]
  );

  const handleDeleteOption = useCallback(
    (index: number, key: string) => () => {
      formValue[key].splice(index, 1);
      //set formik state
      formik.setFieldValue(key, [...formValue[key]]);
      //splice array state
      setFormValue((prev) => ({
        ...prev,
        [key]: [...formValue[key]]
      }));
    },
    [formValue]
  );

  const changeInputText = useCallback(
    (key: string) =>
      ({ currentTarget }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        //set value to formik state
        formik.setFieldValue(key, currentTarget.value);
        //set value to form state
        setFormValue((prev) => ({
          ...prev,
          [key]: currentTarget.value
        }));
      },
    []
  );

  const handleChangeInputSwitch = useCallback((event: InputSwitchChangeEvent) => {
    setFormValue((prev) => ({
      ...prev,
      is_active: Boolean(event.value)
    }));
  }, []);

  const handleOnSubmit = async () => {
    //validation unique name
    if (attributeSetNames?.includes(formValue.name.toLocaleLowerCase())) {
      showErrorToast('Attribute Set Name must be unique value');
      return;
    }
    //if in detail page redirect to edit page
    if (params.detailId) {
      navigate(`/attribute-set/edit/${params.detailId}`);
      return;
    }
    //payload for create and edit
    const payloadUpsert: PayloadAttributeSet = params.id ?
      {
        id: params.id,
        status: formValue.is_active ? 'ACTIVE' : 'INACTIVE',
        name: formValue.name,
        specification_ids: formValue.specification_ids,
        variant_ids: formValue.variant_ids,
        description: formValue.description
      } :
      {
        status: formValue.is_active ? 'ACTIVE' : 'INACTIVE',
        name: formValue.name,
        specification_ids: formValue.specification_ids,
        variant_ids: formValue.variant_ids,
        description: formValue.description
      };

    const { error: errorUpsert } = await upsertAttributeSet(payloadUpsert);
    if (errorUpsert) {
      showErrorToast(params.id ? 'Update Failed' : 'Insert Failed');
      return;
    }
    setFormValue(initialForm);
    showSucessToast(params.id ? 'Update Attribute Set Successfull' : 'New Attribute Set Successfull Created');
  };

  const formik = useFormik({
    initialValues: initialFormik,
    validationSchema: schemaAttributeSets,
    onSubmit: ()=>{
      handleOnSubmit();
    }
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const onCloseModal = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  const onChangeSwitch = useCallback(
    () => (value: string) => {
      setChecked(!!value);
    },
    [setChecked, checked]
  );

  const isLoading = useMemo(()=>{
    if (!params.id || !params.detailId) {
      return false;
    }
    return (isLoadingAttributeSet || isLoadingSpecification || isLoadingVariant);
  }, [params, isLoadingAttributeSet, isLoadingSpecification, isLoadingVariant]);

  useEffect(() => {
    if (!isLoadingAttributeSet) {
      setSpecification(dataSpecification?.data as Specification[]);
      setVariant(dataVariants?.data as Variants[]);
    }
  }, [dataSpecification, dataVariants, isLoadingAttributeSet]);

  useEffect(()=>{
    if (detailAttributeSet) {
      setFormValue({
        description: detailAttributeSet?.description || '',
        is_active: Boolean(detailAttributeSet?.status === 'ACTIVE'),
        name: detailAttributeSet?.name || '',
        specification_ids: detailAttributeSet?.specification_ids || [],
        variant_ids: detailAttributeSet?.variant_ids || []
      });
      formik.setValues({
        name: detailAttributeSet.name,
        specification_ids: detailAttributeSet.specification_ids,
        variant_ids: detailAttributeSet.variant_ids
      });
    }
  }, [detailAttributeSet]);

  return {
    data: {
      dataSpecification,
      optionListVariant,
      openModal,
      isError,
      responseMessage,
      checked,
      optionListSpecification,
      formValue,
      toast,
      isLoading,
      params,
      formik
    },
    method: {
      handleChangeDropdown,
      handleSearchDropdown,
      changeInputText,
      handleChangeInputSwitch,
      handleOnSubmit,
      onCloseModal,
      setIsError,
      onChangeSwitch,
      handleDeleteOption,
      handleAddOption,
      isFormFieldValid
      // onSubmitAll,
    }
  };
};

export default useCustom;
