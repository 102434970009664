import * as yup from 'yup';
import { MESSAGE } from '@/constants';

const schemaMasterSlot = yup.object({
  name: yup.string().required(MESSAGE.REQUIRED),
  status: yup.string().required(MESSAGE.REQUIRED),
  slot_time: yup.array().min(1, MESSAGE.REQUIRED).required(MESSAGE.REQUIRED)
});

export { schemaMasterSlot };
