import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { IPagination } from '@/types/pagination';
import { Database as DB_CATALOGUE } from '@/types/services/catalogue';
import { Database as DB_TRADE } from '@/types/services/trade';
import { IProductVariantSupabase, TagMetadata } from '@/views/PromoTagging/type';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

type Merchants = DB_CATALOGUE['public']['Tables']['merchants']['Row'];
export type PromoTagging = {
  created_at: string | null;
  description: string | null;
  id: number;
  name: string | null;
  status: 'ACTIVE' | 'INACTIVE' | null;
  tag_metadata: TagMetadata;
  type: string | null;
  valid_from: string | null;
  valid_to: string | null;
};
type Products = DB_CATALOGUE['public']['Tables']['products']['Row'];

type Promo = DB_TRADE['public']['Tables']['promos']['Row']

export interface IFilterPromoTag {
  name: string;
  type: string;
  product: string;
  store: string;
  valid_date: Date[];
  status: string;
};

const getPromoTaggings = async (filter?: IFilterPromoTag, range: IPagination= { start: 0, end: 9 }, search?: string ) => {
  const query = supabase
    .from(TABLE.PROMO_TAGGING)
    .select('id, name, description, type, tag_metadata, valid_from, valid_to, status', { count: 'exact' })
    .range(range.start, range.end)
    .order('created_at', { ascending: false });

  if (search && search != '') {
    query.or(`name.ilike.%${search}%`);
  }

  if (filter) {
    // Name
    if (filter.name != '') {
      query.ilike('name', `%${filter.name}%`);
    }

    // Type
    if (filter.type != '') {
      query.eq('type', filter.type);
    }

    // Product
    if (filter.product != '') {
      query.ilike('tag_metadata->product[*]->>product', `%${filter.product}%`);
    }

    // Store
    if (filter.store != '') {
      query.ilike('tag_metadata->store', `%${filter.store}%`);
    }

    // Valid Date
    if (filter.valid_date && filter.valid_date?.length != 0) {
      const fromDate = dayjs(String(filter.valid_date[0])).format('YYYY-MM-DD');
      query.gte('valid_from:date', fromDate);

      if (filter.valid_date.length > 1 && filter.valid_date[1] != null) {
        const toDate = dayjs(String(filter.valid_date[1])).format('YYYY-MM-DD');
        query.lte('valid_to:date', toDate);
      }
    }

    // Status
    if (filter.status != '') {
      query.eq('status', filter.status);
    }
  }

  return (await query) as PostgrestSingleResponse<PromoTagging[]>;
};

const getProducts = async (id?: number) => {
  return (await supabase
    .from(TABLE.PRODUCT)
    .select('id, name')
    .eq('id', id)
    .single()) as PostgrestSingleResponse<Products>;
};

const getMerchant = async (id?: string) => {
  return (await supabase
    .from(TABLE.MERCHANTS)
    .select('id,name')
    .eq('id', id)
    .single()) as PostgrestSingleResponse<Merchants>;
};

const getProductVariant = async () => {
  return (await supabase
    .from(TABLE.PRODUCT_VARIANTS)
    .select('id, products(name),merchants(name)').limit(50)) as PostgrestSingleResponse<IProductVariantSupabase[]>;
};

const getStores = async () => {
  return (await supabase.from(TABLE.MERCHANTS).select('id, name').limit(50)) as PostgrestSingleResponse<
    Merchants[]
  >;
};

const getPromoTaggingById = async (id: number) => {
  return await supabase.from(TABLE.PROMO_TAGGING).select('*').eq('id', id).single();
};

const getPromoTaggingFilterOptions =async () => {
  return await supabase.from(TABLE.PROMO_TAGGING).select('name').limit(10);
};

const getPromoById = async (id: string) => {
  return await supabase
    .from(TABLE.PROMOS)
    .select('*')
    .eq('id', id)
    .single() as PostgrestSingleResponse<Promo>;
};

export const usePromoTaggingFilterOption = () =>
  useQuery({
    queryKey: ['filter-promotags'],
    queryFn: getPromoTaggingFilterOptions,
    refetchOnWindowFocus: false
  });

export const useProductVariants = () =>
  useQuery({
    queryKey: ['productVariants'],
    queryFn: getProductVariant,
    refetchOnWindowFocus: false
  });

export const useGetStores = () =>
  useQuery({
    queryKey: ['stores'],
    queryFn: getStores,
    refetchOnWindowFocus: false
  });

export const useGetPromoTaggings = (filter?: IFilterPromoTag, range?: IPagination, search?: string) =>
  useQuery({
    queryKey: ['promoTaggings', filter, range, search],
    queryFn: () => getPromoTaggings(filter, range, search),
    refetchOnWindowFocus: false
  });

export const useProductById = (id?: number) =>
  useQuery({
    queryKey: ['productById', id],
    queryFn: () => getProducts(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });

export const useMerchantById = (id?: string) =>
  useQuery({
    queryKey: ['merchantById', id],
    queryFn: () => getMerchant(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });

export const useGetPromoTaggingById = (id: number) =>
  useQuery({
    queryKey: ['promoTaggingById', id],
    queryFn: () => getPromoTaggingById(id),
    refetchOnWindowFocus: false
  });

export const useGetPromoById = (id: string) =>
  useQuery({
    queryKey: ['getPromoByid', id],
    queryFn: () => getPromoById(id),
    refetchOnWindowFocus: false,
    enabled: !!id
  });
