import { MediaFileInput, Modal, SelectInput } from 'akeneo-design-system';
import { useContext } from 'react';
import { CategoryContext } from '../hooks';
interface ModalMoveAllCategoryProps {
  onDownloadTemplate: (category) => void;
  onUpload: () => void;
  onClose: () => void;
}
const ModalMoveAllCategory = ({ onDownloadTemplate, onUpload, onClose }: ModalMoveAllCategoryProps) => {
  const category = useContext(CategoryContext);

  const {
    data: { moveAllCategoryForm, thumbnailProductUrl },
    methods: { uploaderProduct, setMoveAllCategoryForm }
  } = category();

  return (
    <Modal id="modal-product-category" className="modal-move-all-category" onClose={() => {}} closeTitle="close">
      <div className="relative py-4 px-4 flex flex-col gap-8 w-7/12 ml-80 font-sans text-base">
        <div className="absolute top-2 right-4 cursor-pointer text-black text-2xl" onClick={onClose}>
          x
        </div>
        <div className="w-full text-left">
          <p className="text-black font-medium">Pindah Sekaligus Kategori</p>
        </div>

        <div className="w-full text-black">
          <h1 className="font-medium">1. Download & ubah File Excel</h1>
          <div className="flex justify-between gap-4 pl-4 mt-2 items-end">
            <div>
              <p>
                Template hanya bisa diisi dengan MS. Excel 2007 ke atas atau Libre Office. <br />
                Jangan menambahkan kolom atau baris apapun di file Excel.
              </p>
              <p className="font-medium pt-2 pb-3">Pilih Kategori Awal</p>
              <SelectInput
                id="select-recent-category"
                openLabel=""
                emptyResultLabel="No result found"
                onChange={(value: string) =>
                  setMoveAllCategoryForm({
                    ...moveAllCategoryForm,
                    recentCategory: value
                  })
                }
                placeholder="-"
                value={moveAllCategoryForm.recentCategory}
              >
                <SelectInput.Option title="" value="-">
                  -
                </SelectInput.Option>
                <SelectInput.Option title="" value="--">
                  -
                </SelectInput.Option>
              </SelectInput>
            </div>
            <button
              type="button"
              id="download-template"
              className="h-fit w-44 py-3 bg-azure rounded-md text-white"
              onClick={() => onDownloadTemplate('category')}
            >
              <p className="font-sans text-base w-max text-center inline-block">Download Template</p>
            </button>
          </div>
        </div>
        <div className="w-full text-black">
          <h1 className="font-medium">2. Upload File Excel</h1>
          <div className="pl-4 mt-2">
            <p>Pilih atau letakkan file Excel (.xlsx) kamu di sini, maks. 300 SKU per kategori.</p>
            <div className="flex justify-between gap-4 mt-8 items-center">
              <div className="w-2/3">
                <MediaFileInput
                  accept=".xlsx"
                  id="upload-category"
                  value={moveAllCategoryForm.file}
                  onChange={(value) => {
                    setMoveAllCategoryForm({
                      ...moveAllCategoryForm,
                      file: value
                    });
                  }}
                  thumbnailUrl={thumbnailProductUrl}
                  uploader={uploaderProduct}
                  clearTitle="Clear"
                  placeholder="Drag and drop to upload or click here"
                  uploadErrorLabel="Gagal Upload File!"
                  uploadingLabel="Uploading..."
                />
              </div>
              <button
                type="button"
                id="upload-product"
                className={`h-fit w-44 py-3 ${
                  moveAllCategoryForm.file ? 'bg-azure' : 'bg-gray-400 cursor-not-allowed'
                } rounded-md text-white`}
                disabled={!moveAllCategoryForm.file}
                onClick={onUpload}
              >
                <p className="font-sans text-center text-base inline-block">Upload</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalMoveAllCategory;
