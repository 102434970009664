import IconTrash from '@/assets/IconTrash';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import React, { FC } from 'react';
import { IProduct } from '@/services/rest/product';
import { toGram } from '@/utils';

type PriceWeight = {
    product: IProduct;
    stocks: number;
};

const PriceWeight: FC<PriceWeight> = ({ product, stocks }) => {
  const weight = toGram(product.volume?.weight);
  return (
    <><div id="form_price_weight" className="font-bold mt-6 text-xl mb-2">Price & Weight Information</div>
      <div id="price_weight" className="py-3 px-4">
        <div id="form_container" className="flex w-full">
          <div className="flex flex-col w-1/2">
            <div id="price" className="flex items-center py-3 gap-2">
              <label htmlFor="main_price">Main Price</label>
              <InputSwitch disabled id="product-main-price-input-switch" checked={product?.is_main_price} />
            </div>
          </div>
          <div id="main_price_value" className="flex w-1/2 items-center">
            <InputNumber disabled id="product-main-price-input"
              value={product?.main_price || 0} mode="currency"
              currency="IDR" locale="id-ID" inputClassName="h-9"
              style={{ width: '100%' }}
            />
          </div>
        </div>
        <div id="form_container" className="flex w-full">
          <div id="min_purchase" className="flex flex-col py-3 w-1/2">
            <div id="min_purchase">Minimum Pembelian</div>
            <span className="text-xs text-gray-500">Atur jumlah minimum pembelian unutuk produk ini</span>
          </div>
          <div id="min_purchase_value" className="flex w-1/2 items-center">
            <InputNumber disabled id="product-min-value-input" value={product?.minimum_purchase || 1} inputClassName="h-9" style={{ width: '100%' }} />
          </div>
        </div>
        <div id="form_container" className="flex w-full">
          <div id="min_purchase" className="flex flex-col py-3 w-1/2">
            <div id="min_purchase">Maksimum Pembelian</div>
            <span className="text-xs text-gray-500">Atur jumlah maksimum pembelian unutuk produk ini</span>
          </div>
          <div id="min_purchase_value" className="flex w-1/2 items-center">
            <InputNumber disabled id="product-min-value-input" value={product?.maximum_purchase || 0} inputClassName="h-9" style={{ width: '100%' }} />
          </div>
        </div>
        {!product?.is_digital &&
          <div id="form_container" className="flex w-full">
            <div id="volume" className="flex py-3 w-1/2">
              <div id="volume">Volume</div>
            </div>
            <div id="volume_value" className="flex w-1/2 items-center">
              <div id="depth" className="p-inputgroup h-9">
                <span className="self-center mr-2">Panjang</span>
                <InputNumber id="product-length-input" value={product.volume?.depth || 0} disabled placeholder="Panjang" />
                <span className="p-inputgroup-addon">cm</span>
              </div>
              <div id="width" className="p-inputgroup h-9">
                <span className="self-center px-2">Lebar</span>
                <InputNumber id="product-width-input" value={product.volume?.width || 0} disabled placeholder="Lebar" />
                <span className="p-inputgroup-addon">cm</span>
              </div>
              <div id="height" className="p-inputgroup h-9">
                <span className="self-center px-2">Tinggi</span>
                <InputNumber id="product-height-input" value={product.volume?.height || 0} disabled placeholder="Tinggi" />
                <span className="p-inputgroup-addon">cm</span>
              </div>
            </div>
          </div>
        }
        {!product?.is_digital &&
          <div id="form_container" className="flex w-full">
            <div id="weight" className="flex py-3 w-1/2">
              <div id="weight">Weight</div>
            </div>
            <div id="weight_value" className="w-1/2 flex items-center">
              <div id="weight" className="p-inputgroup h-9">
                <InputNumber id="product-weight-input" value={weight || 0} disabled placeholder="Berat" />
                <span className="p-inputgroup-addon">gram</span>
              </div>
            </div>
          </div>
        }
        <div id="form_container" className="flex w-full">
          <div id="stock" className="flex py-3 w-1/2">
            <div id="stock">Stock &#40;Qty&#41;</div>
          </div>
          <div id="stock_value" className="flex items-center w-1/2">
            <InputNumber id="product-stock-value-input" value={stocks || 0} disabled placeholder="Jumlah Stock" inputClassName="h-9" style={{ width: '100%' }} />
          </div>
        </div>
        <div id="form_container" className="flex w-full">
          <div id="sku" className="flex py-3 w-1/2">
            <div id="sku">SKU &#40;Stock Keeping Unit&#41;</div>
          </div>
          <div id="sku_value" className="flex items-center w-1/2">
            <InputText id="product-stock-value-input" value={product?.sku ? product?.sku : 'SKU-0000'} disabled placeholder="Masukkan SKU" className="h-9" style={{ width: '100%' }} />
          </div>
        </div>
        <div id="form_container" className="flex w-full">
          <div id="status" className="flex py-3 w-1/2">
            <div id="status">Status Produk</div>
          </div>
          <div id="status_value" className="flex items-center">
            <InputSwitch id="product-status-value-input" disabled checked={product?.is_active} />
          </div>
        </div>
        <div id="form_container" className="flex w-full">
          <div id="grosir" className="flex flex-col py-3 w-1/2">
            <div id="grosir">Grosir</div>
            <span className="text-xs text-gray-500">Harga grosir untuk pembelian tertentu</span>
          </div>
          <div id="grosir_value" className="flex w-1/2 flex-col">
            {product.wholesale_price && product.wholesale_price.map((item)=> (
              <div key={item.id} className="flex gap-2 items-center">
                <div id="min_grosir" className="p-inputgroup h-9">
                  <InputNumber id="product-min-wholesale-input" value={item.min_purchase || 0} disabled placeholder="Minimal Pembelian" />
                </div>
                <div id="max_grosir" className="p-inputgroup h-9">
                  <InputNumber id="product-max-wholesale-input" value={item.max_purchase || 0} disabled placeholder="Maksimal Pembelian" />
                </div>
                <div id="price_grosir" className="p-inputgroup h-9">
                  <InputNumber id="product-wholesale-price-input" value={item.price || 0} disabled inputId="currency-id" placeholder="Rp" mode="currency" currency="IDR" locale="id-ID" inputClassName="h-9" />
                </div>
                <div className="flex items-center">
                  <Button id="product-submit-button" disabled type="submit" rounded severity="danger"
                    text icon={IconTrash} />
                </div>
              </div>
            ))
            }
            <div className="flex flex-col mt-2">
              <Button id="product-add-button" rounded className="h-8" disabled severity="danger" label="+ Tambah Harga Grosir" />
            </div>
          </div>
        </div>
      </div></>
  );
};

export default PriceWeight;
