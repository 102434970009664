import * as yup from 'yup';
import { MESSAGE } from '@/constants';

const schemaProductHighligh = yup.object({
  sortBy: yup.string().when('applyToSection', (applyToSection, schema) => {
    if (applyToSection.includes('Product Hot Deal')) {
      return schema;
    }
    return schema.required(MESSAGE.REQUIRED);
  }),
  sortValue: yup.string().when(['sortBy', 'applyToSection'], ([sortBy, applyToSection], schema) => {
    if (applyToSection === 'Product Hot Deal') {
      return schema;
    }
    if (sortBy === 'sequence') {
      return schema.matches(/^[0-9]+$/, MESSAGE.NUMBER_ONLY).required(MESSAGE.REQUIRED);
    }
    return schema;
  }),
  street: yup.array().min(1, MESSAGE.REQUIRED).required(MESSAGE.REQUIRED)
});

export { schemaProductHighligh };
