import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { PostgrestMaybeSingleResponse } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';

export type Categories = {
  id?: string;
  parent_id: string | number | null;
  name: string | null;
  status: string;
  meta_title: string;
  description?: string;
  images: { data: { image?: string } };
  position?: number;
  media_url?: string;
  is_selectable?: boolean;
  slug?: string;
  meta_keyword?: string;
  meta_description?: string;
  children?: Categories[];
};

export const getDataCategories = () => async () => {
  return (await supabase
    .from(TABLE.CATEGORIES)
    .select('*')
    .order('created_at', { ascending: false })
    .range(0, 10)
  );
};

export const useCategories = () =>
  useQuery({
    queryKey: ['categories'],
    queryFn: getDataCategories(),
    refetchOnWindowFocus: false
  });


export const getAllCategories = () => async () => {
  return (await supabase
    .from(TABLE.CATEGORIES)
    .select('*')
    .order('created_at', { ascending: false })
    .range(0, 10)
    .is('parent_id', null)
    .eq('status', 'ACTIVE')
  );
};

export const upsertBulkCategories = async (payload: Categories[]) => {
  return await supabase.from(TABLE.CATEGORIES).upsert(payload).select('id') as PostgrestMaybeSingleResponse<Categories[]>;
};

export const deleteCategorySpec =async (ids: string[]) => {
  return await supabase.from(TABLE.CATEGORY_SPECIFICATION).delete().in('category_id', ids);
};

export const deleteCategoryVar = async (ids: string[]) => {
  return await supabase.from(TABLE.CATEGORY_VARIANTS).delete().in('category_id', ids);
};

export const upsertCategory =async (payload: Categories) => {
  return await supabase.from(TABLE.CATEGORIES).upsert(payload).select('id').single();
};

export const useAllCategories = () =>
  useQuery({
    queryKey: ['all-categories'],
    queryFn: getAllCategories(),
    refetchOnWindowFocus: false
  });
