import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import useCustom from './hooks';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { usePermissionStore } from '@/store/usePermissions';

const DetailSlots = () => {
  const {
    data: { formEditSlots, rangeTimeSlots, params, optionsStatus, selectedStatus },
    methods: {
      navigate
    }
  } = useCustom();
  const { canEdit } = usePermissionStore();
  return (
    <>
      <LoadingOverlay show={false}/>
      <BaseContainer>
        <MainTitle isWithButton={false} title="Detail Slot" />
        <div className="border p-6">
          <div className="flex">
            <div className="w-1/4">
              <div id="slot-title1" className="border border-solid py-3 font-bold bg-gray-400 text-center">SLOT INFORMATION</div>
              <div id="slot-title2" className="border border-solid py-3 font-normal text-center">Slot Detail</div>
            </div>
            <div className="border p-10 w-full space-y-3">
              <div className="flex gap-20 items-center">
                Slot Name*{' '}
                <InputText
                  id="slot-name-input"
                  disabled
                  className="w-2/5"
                  placeholder="Masukkan Nama Slot"
                  value={formEditSlots.name}
                />
              </div>
              <div className="flex gap-[5.2rem] items-center">
                Description
                <InputText
                  disabled
                  value={formEditSlots.description}
                  id="slot-description-input"
                  className="w-2/5"
                  placeholder="Empty"
                />
              </div>
              {rangeTimeSlots.map((timeSlots, index) => {
                return (
                  <div key={index} className="flex w-full items-center">
                    {index == 0 ? <p className="flex w-[162px] gap-2">Select Slot Time*</p> : <div className="w-[162px] gap-2" />}
                    <div className="border border-solid p-3 rounded-lg mr-3 w-[10%] bg-gray-400"> Slot {index + 1}</div>
                    <div id="slot-time" className="card flex flex-initial gap-2 p-fluid items-center">
                      <Calendar
                        id="slot-started-time-input"
                        disabled
                        value={timeSlots.started_time as Date}
                        timeOnly
                        hideOnDateTimeSelect
                      />
                      -
                      <Calendar
                        id="slot-ended-time-input"
                        value={timeSlots.ended_time as Date}
                        disabled
                        timeOnly
                        hideOnDateTimeSelect
                      />
                    </div>
                  </div>
                );
              })}
              <div className="flex flex-col">
                <div id="slot-input-type" className="flex gap-[5.2rem] items-center">
                  Status*
                  <div className="card flex justify-content-center px-8 w-2/5">
                    <Dropdown
                      disabled
                      id="slot-input-type"
                      value={selectedStatus}
                      onChange={() => {}}
                      options={optionsStatus}
                      optionLabel="name"
                      placeholder="Select a status"
                      className="w-full md:w-14rem"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SubmitButton
          onSubmit={() => {
            navigate(`/master-slot/edit/${params.id}`);
          }}
          onCancel={() => {
            navigate('/master-slot');
          }}
          labelBack="Back"
          labelSubmit="Edit"
          disabledSubmit={!canEdit}
        />
      </BaseContainer>
    </>
  );
};

export default DetailSlots;
