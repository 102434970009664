import React, { FC, SetStateAction } from 'react';
import { IProductVariants } from '@/services/rest/product';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Chips } from 'primereact/chips';
import IconTrash from '@/assets/IconTrash';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import IconPencil from '@/assets/IconPencil';
import { Divider } from 'primereact/divider';
import { InputSwitch } from 'primereact/inputswitch';
import IconImage from '@/assets/IconImage';
import { Image } from 'primereact/image';
import { useCustom } from '../hooks';
import { Toast } from 'primereact/toast';

type Variant = {
  productStatus: string;
  prodVariants: IProductVariants[];
  varType1: { name: string; value: string[] }[];
  varType2: { name: string; value: string[] }[];
  selected: IProductVariants[];
  setSelected: (value: SetStateAction<IProductVariants[]>)=> void;
};

const VarianProduct: FC<Variant> = ({ prodVariants, varType1, varType2, selected, setSelected, productStatus }) => {
  const { data, method } = useCustom();
  const stockTemplate = (prodvar: IProductVariants) => {
    if (prodvar.voucher_stock) {
      return <div id="product-variant-stock">{prodvar.voucher_stock}</div>;
    }
    return <div id="product-variant-stock">{prodvar.saleable_stock + prodvar.inflight_stock}</div>;
  };
  const imageTemplate = (prodVar: IProductVariants) => {
    if (prodVar.image != null) {
      return (
        <div id="product-variant-id">
          <Image
            src={prodVar.image}
            zoomSrc={prodVar.image}
            alt={prodVar.image}
            className="mx-0.5"
            width="90px"
            preview
          />
        </div>
      );
    }
    return (
      <div>
        <IconImage />
      </div>
    );
  };
  const disabledStatus = productStatus === 'APPROVED' || productStatus === 'IN_REVIEW_UPDATE';
  const statusTemplate = (rowData: IProductVariants) => {
    return (
      <div className="flex flex-col items-center">
        <InputSwitch id="product-status-input" checked={rowData.is_active}
          onChange={(e)=> method.handleListVariantSwitch(e.value as boolean, rowData.id)}
          disabled={!disabledStatus}
        />
        <span className="text-black text-sm py-2">Active</span>
      </div>
    );
  };
  const primaryStatus = (rowData: IProductVariants) => {
    return (
      <div className="flex flex-col items-center">
        <InputSwitch id="product-set-as-input" disabled checked={rowData.is_primary_variant} />
        <span className="text-black text-sm py-2">Primary Variant</span>
      </div>
    );
  };
  const actionButton = () => {
    return (
      <Button
        id="product-edit-button"
        icon={IconPencil}
        outlined rounded disabled
        severity="secondary"
      />
    );
  };
  const selectedProduct = selected?.length || 0;
  return (
    <>
      <div id="varian_produk" className="font-bold text-xl py-3">Varian Produk</div>
      <div id="varian_form" className="flex w-full px-4 justify-between">
        <div id="header_form" className="w-1/3 flex flex-col">
          <span id="keterangan" className="text-xs">Tambahkan varian seperti warna, ukuran, atau lainnya.</span>
          <span id="keterangan" className="text-xs">Pilih maksimum 2 tipe varian.</span>
        </div>
        <div id="delete_varian" className="flex">
          <Button id="product-delete-variant-button" disabled label="Hapus Varian" rounded severity="danger" className="h-9 w-60" />
        </div>
      </div>
      <Divider />
      {prodVariants[0]?.id != '' && (
        <>
          {(varType1[0].name != undefined && varType1[0].value.length > 0 && varType1[0].value[0] != '') &&
            (<div id="varian_tipe" className="flex w-full px-3 py-3 flex-col">
              <div className="">Tipe Varian 1</div>
              <div id="type_form" className="flex gap-8 w-full">
                <Dropdown id="product-variant-type1-input" disabled optionLabel="name" value={varType1[0]?.name}
                  placeholder={varType1[0]?.name} className="w-1/5" />
                <div className="flex items-center w-full">
                  <div className="card p-fluid w-full">
                    <Chips id="product-variant-type1-chips-input" disabled value={varType1[0]?.value} />
                  </div>
                  <Button id="product-delete-button" icon={IconTrash} severity="danger" disabled rounded text />
                </div>
              </div>
            </div>
            )}
          {(varType2[0].name !== '' || varType2[0].value[0] !== '') &&
            (<div id="varian_tipe" className="flex w-full px-3 py-3 flex-col">
              <div className="w-1/3">Tipe Varian 2</div>
              <div id="type_form" className="flex gap-8 w-full">
                <Dropdown id="product-variant-type2-input" disabled optionLabel="name" value={varType2[0]?.name}
                  placeholder={varType2[0]?.name} className="w-1/5" />
                <div className="flex items-center w-full">
                  <div className="card p-fluid w-full">
                    <Chips id="product-variant-type2-chips-input" disabled value={varType2[0]?.value} />
                  </div>
                  <Button id="product-delete-button" icon={IconTrash} severity="danger" disabled rounded text />
                </div>
              </div>
            </div>)
          }
          <Divider />
          <div className="flex flex-col px-3">
            <span className="">Tabel Varian</span>
            <span className="text-rose-600">&#40;{selectedProduct} Produk Varian Terpilih&#41;</span>
          </div>
          <Divider />
          <div className="card border py-3 px-3">
            <Toast ref={data.toast} />
            <DataTable
              id="product-data-table"
              value={data.dataVariants} loading={data.isLoading}
              scrollable scrollHeight="30rem"
              onSelectionChange={(e)=> {
                const value = e.value as IProductVariants[];
                setSelected(value);
              }}
              selectionMode="multiple" selection={selected as IProductVariants[]}
              dataKey="id" tableStyle={{ minWidth: '3rem' }}
            >
              <Column selectionMode="multiple" alignHeader="center" align="center" headerStyle={{ width: '3rem' }}/>
              <Column field="attribute_set_variants.0.value" alignHeader="center" align="center" header={varType1[0]?.name}/>
              <Column field="attribute_set_variants.1.value" alignHeader="center" align="center" header={varType2[0]?.name}/>
              <Column alignHeader="center" align="center" field="price" header="Harga"/>
              <Column alignHeader="center" align="center" field="stock" header="Stok" body={stockTemplate} />
              <Column alignHeader="center" align="center" field="sku_id" header="SKU"/>
              <Column alignHeader="center" align="center" field="image" header="Gambar" body={imageTemplate} />
              <Column alignHeader="center" align="center" field="is_active" header="Status" body={statusTemplate}/>
              <Column alignHeader="center" align="center" field="is_primary_variant" header="Set Sebagai" body={primaryStatus}/>
              <Column alignHeader="center" align="center" header="Action" style={{ width: '5rem' }} body={actionButton}/>
            </DataTable>
            <div className="flex flex-row-reverse py-3">
              <Button id="product-apply-button" label="Terapkan" severity="danger" disabled rounded style={{ height: '2.5rem', width: '45%' }}/>
            </div>
          </div>
        </>
      )}
      {prodVariants[0]?.id === '' && (
        <div className="font-bold flex justify-center p-3">Tidak Ada Produk Variant</div>
      )}
    </>
  );
};

export default VarianProduct;
