import BaseContainer from '@/components/base/BaseContainer/BaseContainer';

const SocialAccounts = () => {
  return (
    <BaseContainer>
      <label className="font-bold">
        Social Accounts
      </label>
      <hr className="text-black mt-2 mb-2" />
      <div className="w-full bg-gray-100 h-[100px] grid place-items-center">Customer has not linked social accounts yet</div>
    </BaseContainer>
  );
};

export default SocialAccounts;
