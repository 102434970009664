/* eslint-disable no-unused-vars */
import { PRODUCT_HIGHTLIGHT_SORT_TYPE, TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { useFormik } from 'formik';
import { IProductVariant, updateOfficialStore, updateProductHotDeal, updateProductSubscription, updateProductTabulation, useGetOfficialMerchant, useGetProductHotDealById, useGetProductSubscriptionById, useGetProductTabulationsById } from '@/services/rest/productHighlight';
import { IDropdownPrimeReact } from '@/views/PromoTagging/type';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IFormEdit } from '../helper';
import { Toast } from 'primereact/toast';
import { debounce, uniqBy } from 'lodash';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { schemaProductHighligh } from './validation';
import dayjs from 'dayjs';

export const initialForm = {
  id: 0,
  applyToStreet: '',
  applyToSection: '',
  product_variant_id: '',
  product: '',
  merchant_id: '',
  sortBy: '',
  sortValue: '',
  validFrom: '',
  validTo: '',
  loading: false,
  status: false,
  name: '',
  applyToTab: 0
};

const initialFormik = {
  applyToSection: '',
  sortBy: '',
  sortValue: '',
  street: []
};

const useEditProductHighlight = () => {
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const [formEdit, setFormEdit] = useState<IFormEdit>(initialForm);
  const [formEditHotDeal, setFormEditHotDeal] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [street, setStreet] = useState<IDropdownPrimeReact[]>([]);
  const [tabulation, setTabulation] = useState<IDropdownPrimeReact[]>([]);
  const [products, setProducts] = useState<IDropdownPrimeReact[]>([]);
  const [dataProducts, setDataProducts] = useState<IProductVariant[]>([]);
  const [defaultProduct, setDefaultProduct] = useState<IDropdownPrimeReact>();
  const [selectedStreet, setSelectedStreet] = useState<IDropdownPrimeReact[]>([]);
  const [selectedTabulation, setSelectedTabulation] = useState<string[]>([]);

  const { search, pathname } = useLocation();
  const { id } = useParams();
  const query = new URLSearchParams(search).get('section');

  const isCreate = pathname.includes('create');

  const formik = useFormik({
    initialValues: initialFormik,
    validationSchema: schemaProductHighligh,
    onSubmit: ()=>{
      if (!id) {
        handleClickCreate();
      } else {
        handleClickSubmit();
      }
    }
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getStreet = useCallback(async () => {
    const { data, error } = (await supabase.from(TABLE.STREET).select('id, name'));

    if (data != null && !error) {
      const result: IDropdownPrimeReact[] = data.map((item) => {
        return {
          code: item.id,
          name: item.name
        };
      });
      setStreet(result);
    }
  }, []);

  const getTabulation = useCallback(async () => {
    const { data, error } = (await supabase.from(TABLE.TABULATION).select('id, name'));
    if (data != null && !error) {
      const result: IDropdownPrimeReact[] = data.map((item) => {
        return {
          code: item.id,
          name: item.name
        };
      });
      setTabulation(result);
    }
  }, []);

  const findProductsDebounce = debounce(async (findName: string | null) => {
    if (!findName) {
      return;
    }
    if (query === 'Product Hot Deal' || formEdit.applyToSection === 'Product Hot Deal') {
      const { data } = await supabase.from(TABLE.PRODUCT).select('id, name')
        .ilike('name', `%${findName}%`).eq('status', 'APPROVED').eq('is_active', true).limit(10);

      if (data) {
        if (products) {
          setProducts((prev)=>{
            const dataDropdown = data.map((item)=> {
              return {
                code: String(item?.id),
                name: String(item?.name)
              };
            });
            const newData = [...prev, ...dataDropdown];
            const distinctData = uniqBy(newData, 'code');
            return distinctData;
          });
        } else {
          const result = data?.map((item) => {
            return {
              code: String(item?.id),
              name: String(item?.name)
            };
          });
          setProducts(result as IDropdownPrimeReact[]);
          return;
        }
      }
    }
    const { data } = await supabase.from(TABLE.PRODUCT_VARIANTS).select('id, products!inner(id,name)')
      .ilike('products.name', `%${findName}%`).eq('products.status', 'APPROVED').eq('products.is_active', true).limit(10) as PostgrestSingleResponse<IProductVariant[]>;

    if (data) {
      if (dataProducts) {
        setDataProducts((prev)=> {
          const newData = [...prev, ...data];
          const distinctData = [...new Set(newData.map((item)=> JSON.stringify(item)))];
          const parseDistinct = distinctData.map((item)=> JSON.parse(item)).filter((item)=> item.products?.name != undefined);
          return parseDistinct;
        });
      } else {
        setDataProducts(data as IProductVariant[]);
      }
    }
  }, 300);

  const findProducts = async (findName: string | null) => {
    findProductsDebounce(findName);
  };
  const getProducts: IDropdownPrimeReact[] = useMemo(() => {
    const data = dataProducts;
    const result = data?.map((item) => {
      return {
        code: String(item?.id),
        name: String(item?.products?.name),
        productId: Number(item?.products?.id)
      };
    });
    return result as [];
  }, [dataProducts]);

  const getDefault = async (id: string) => {
    if (query === 'Product Hot Deal') {
      const { data } = (await supabase.from(TABLE.PRODUCT).select('id, name').eq('id', id).limit(10));
      if (data) {
        const defaultValue = [{ code: data[0].id, name: data[0].name }];
        setProducts(defaultValue as IDropdownPrimeReact[]);
        return;
      }
    }
    const { data } = await supabase.from(TABLE.PRODUCT_VARIANTS).select('id, products(id,name)').eq('id', id).single() as PostgrestSingleResponse<IProductVariant>;
    if (data && data.products) {
      const defaultValue = { code: data.id, name: data.products?.name };
      setDefaultProduct(defaultValue as IDropdownPrimeReact);
    }
  };

  const getProductList = async () => {
    const { data } = await supabase.from(TABLE.PRODUCT_VARIANTS).select('id, products!inner(id,name)')
      .eq('products.status', 'APPROVED').eq('products.is_active', true).limit(10) as PostgrestSingleResponse<IProductVariant[]>;
    if (data) {
      setDataProducts(data as IProductVariant[]);
    }
  };
  useEffect(()=> {
    if (formEdit.applyToSection === 'Product Hot Deal') {
      getProductHotDealOptions();
    } else {
      getProductList();
    }
  }, [formEdit.applyToSection]);

  useEffect(()=> {
    if (formEdit.product) {
      getDefault(formEdit.product as string);
    }
    if (query === 'Product Hot Deal' && formEdit.product_id) {
      getDefault(String(formEdit.product_id));
    }
  }, [formEdit.product]);

  const getProductHotDealOptions = useCallback( async () => {
    const { data } = (await supabase.from(TABLE.PRODUCT).select('id, name')
      .eq('status', 'APPROVED').eq('is_active', true).limit(10));
    if (data) {
      const result: IDropdownPrimeReact[] = data.map((item) => {
        return {
          code: item.id,
          name: item.name
        };
      });
      setProducts(result);
    }
  }, []);

  useEffect(() => {
    getStreet();
    getProductHotDealOptions();
    getTabulation();
  }, [id]);

  const selectedId = useMemo(() => {
    if (query === 'Product Pilihan') {
      return {
        productPilihanId: Number(id)
      };
    } else if (query === 'Product Tabulation') {
      return {
        productTabulationId: Number(id)
      };
    } else if (query === 'Product Hot Deal') {
      return {
        prodductHotDealId: Number(id)
      };
    } else if (query === 'Official Store') {
      return {
        officialStoreId: Number(id)
      };
    }
  }, [id, query]);

  const { data: productSubscribtion, isLoading: loadingProductSubscribtion, isFetching: isFetchingProductSubscrribe } = useGetProductSubscriptionById(selectedId?.productPilihanId);
  const { data: productTabulation, isLoading: loadingProductTabulation, isFetching: isFetchingProductTabulation } = useGetProductTabulationsById(selectedId?.productTabulationId);
  const { data: productHotDeal, isLoading: loadingProductHotDeal, isFetching: isFetchingProductHotDeals } = useGetProductHotDealById(selectedId?.prodductHotDealId);
  const { data: officialStore, isLoading: loadingOfficial, isFetching: isFetchingOfficialMerchant } = useGetOfficialMerchant(selectedId?.officialStoreId);


  const validateDate: boolean = useMemo(() => {
    return new Date(String(formEdit.validTo)).getTime() < new Date(String(formEdit.validFrom)).getTime();
  }, [formEdit]);

  const onChangeFormEdit = (key: string, value: string | number | Date | Date[] | boolean | null) => {
    const findProductId = getProducts.find((it) => it.code === value);
    if (key === 'product') {
      setFormEdit((prevState) => {
        return {
          ...prevState,
          product_id: findProductId?.productId
        };
      });
    }

    formik.setFieldValue(key, value);
    setFormEdit((prevState) => {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  const showSuccessToast = (msg?: string) => {
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: msg ? msg : 'Successfully Update',
      life: 2000
    });
    const timeOut = setTimeout(() => {
      navigate('/product-highlight');
      return () => clearTimeout(timeOut);
    }, 500);
  };

  const showFailedToast = (message?: string) => {
    toast.current?.show({
      severity: 'error',
      summary: 'Failed',
      detail: message || 'Failed update',
      life: 2000
    });
  };
  // handle for edit
  const handleClickSubmit = useCallback(async () => {
    setIsLoading(true);
    if (formEdit.applyToSection === 'Product Pilihan') {
      const payload = {
        ended_time: dayjs(dayjs(formEdit.validTo).format('YYYY-MM-DD') + ' 23:59:59').utc().format('YYYY-MM-DD HH:mm:ss'),
        id: formEdit.id,
        product_id: formEdit.product_id,
        product_variant_id: formEdit.product,
        sort_type: formEdit.sortBy,
        started_time: dayjs(dayjs(formEdit.validFrom).format('YYYY-MM-DD') + ' 00:00:00').utc().format('YYYY-MM-DD HH:mm:ss'),
        updated_at: new Date(),
        weight: formEdit.sortValue,
        is_active: Boolean(formEdit.status)
      };
      if (formEdit.sortBy !== 'sequence') {
        delete payload.weight;
      }
      const { error } = await updateProductSubscription(payload);
      if (error) {
        showFailedToast('failed to update');
        setIsLoading(false);
        return;
      }
      showSuccessToast();
      setIsLoading(false);
      return;
    }
    if (formEdit.applyToSection === 'Product Tabulation') {
      const payload = {
        ended_time: dayjs(dayjs(formEdit.validTo).format('YYYY-MM-DD') + ' 23:59:59').utc().format('YYYY-MM-DD HH:mm:ss'),
        id: formEdit.id,
        product_id: formEdit.product_id,
        product_tabulation_id: formEdit.applyToTab,
        product_variant_id: formEdit.product,
        sort_type: formEdit.sortBy,
        started_time: dayjs(dayjs(formEdit.validFrom).format('YYYY-MM-DD') + ' 00:00:00').utc().format('YYYY-MM-DD HH:mm:ss'),
        updated_at: new Date(),
        weight: formEdit.sortValue,
        is_active: Boolean(formEdit.status)
      };
      if (formEdit.sortBy !== 'sequence') {
        delete payload.weight;
      }
      const { error } = await updateProductTabulation(payload);
      if (error) {
        showFailedToast();
        setIsLoading(false);
        return;
      }
      showSuccessToast();
      setIsLoading(false);
      return;
    }
    if (formEdit.applyToSection === 'Product Hot Deal') {
      const payload = {
        ended_time: dayjs(dayjs(formEdit.validTo).format('YYYY-MM-DD') + ' 23:59:59').utc().format('YYYY-MM-DD HH:mm:ss'),
        id: formEdit.id,
        name: formEdit.name,
        product_id: formEditHotDeal,
        started_time: dayjs(dayjs(formEdit.validFrom).format('YYYY-MM-DD') + ' 00:00:00').utc().format('YYYY-MM-DD HH:mm:ss'),
        updated_at: new Date(),
        status: Boolean(formEdit.status) ? 'ACTIVE' : 'INACTIVE'
      };

      const { error } = await updateProductHotDeal(payload);
      if (error) {
        showFailedToast();
        setIsLoading(false);
        return;
      }
      showSuccessToast();
      setIsLoading(false);
      return;
    }
    if (formEdit.applyToSection === 'Official Store') {
      const payload = {
        ended_time: dayjs(dayjs(formEdit.validTo).format('YYYY-MM-DD') + ' 23:59:59').utc().format('YYYY-MM-DD HH:mm:ss'),
        id: formEdit.id,
        name: formEdit.name,
        product_variant_id: formEdit.product,
        started_time: dayjs(dayjs(formEdit.validFrom).format('YYYY-MM-DD') + ' 00:00:00').utc().format('YYYY-MM-DD HH:mm:ss'),
        updated_at: new Date(),
        weight: formEdit.sortValue,
        sort_type: formEdit.sortBy,
        is_active: Boolean(formEdit.status)
      };
      if (formEdit.sortBy !== 'sequence') {
        delete payload.weight;
      }
      const { error } = await updateOfficialStore(payload);
      if (error) {
        showFailedToast();
        setIsLoading(false);
        return;
      }
      showSuccessToast();
      setIsLoading(false);
    }
  }, [formEdit, selectedStreet, selectedTabulation, formEditHotDeal]);

  useEffect(() => {
    if (query === 'Product Pilihan') {
      const data = productSubscribtion?.data;
      const startDate = new Date(String(data?.started_time));
      const endDate = new Date(String(data?.ended_time));
      const result = {
        id: Number(data?.id),
        applyToStreet: 'AladinMall',
        applyToSection: String(query),
        product: String(data?.product_variants.id),
        product_id: Number(data?.product_variants.products.id),
        sortBy: PRODUCT_HIGHTLIGHT_SORT_TYPE[String(data?.sort_type)],
        sortValue: data?.sort_type === 'sequence' ? String(data?.weight) : 'default',
        product_variants: String(data?.product_variant_id),
        validFrom: startDate,
        validTo: endDate,
        loading: Boolean(loadingProductSubscribtion),
        status: Boolean(data?.is_active)
      };
      setFormEdit(result);
      setSelectedStreet(street);
      formik.setFieldValue('street', street);
      formik.setFieldValue('sortBy', PRODUCT_HIGHTLIGHT_SORT_TYPE[String(data?.sort_type)]);
      formik.setFieldValue('sortValue', data?.sort_type === 'sequence' ? String(data?.weight) : 'default');
      formik.setFieldValue('applyToSection', query);
    }
    if (query === 'Product Tabulation') {
      const data = productTabulation?.data;
      const startDate = new Date(String(data?.started_time));
      const endDate = new Date(String(data?.ended_time));
      const result = {
        id: Number(data?.id),
        applyToStreet: 'AladinMall',
        applyToSection: String(query),
        applyToTab: Number(data?.product_tabulations?.id),
        tabulationId: Number(data?.product_tabulations.id),
        tabulationName: String(data?.product_tabulations.name),
        product: String(data?.product_variants?.id),
        product_id: Number(data?.product_variants?.products?.id),
        sortBy: PRODUCT_HIGHTLIGHT_SORT_TYPE[String(data?.sort_type)],
        sortValue: data?.sort_type === 'sequence' ? String(data?.weight) : 'default',
        validFrom: startDate,
        validTo: endDate,
        loading: Boolean(loadingProductTabulation),
        status: data?.is_active
      };
      setFormEdit(result);
      setSelectedStreet(street);
      formik.setFieldValue('street', street);
      formik.setFieldValue('sortBy', PRODUCT_HIGHTLIGHT_SORT_TYPE[String(data?.sort_type)]);
      formik.setFieldValue('sortValue', data?.sort_type === 'sequence' ? String(data?.weight) : 'default');
      formik.setFieldValue('applyToSection', query);
    }
    if (query === 'Product Hot Deal') {
      const data = productHotDeal?.data;
      const startDate = new Date(String(data?.started_time));
      const endDate = new Date(String(data?.ended_time));
      const result = {
        id: Number(data?.id),
        applyToStreet: 'AladinMall',
        applyToSection: query,
        product_id: Number(data?.product_id),
        name: String(data?.name),
        merchant_id: String(data?.merchant_id),
        product: Number(data?.products.id),
        validFrom: startDate,
        validTo: endDate,
        loading: loadingProductHotDeal,
        status: data?.status === 'ACTIVE'
      };
      setFormEdit(result);
      setSelectedStreet(street);
      formik.setFieldValue('street', street);
      setFormEditHotDeal(Number(data?.product_id));
      formik.setFieldValue('applyToSection', query);
    }
    if (query === 'Official Store') {
      const data = officialStore?.data;
      const startDate = new Date(String(data?.started_time));
      const endDate = new Date(String(data?.ended_time));
      const result = {
        id: Number(data?.id),
        applyToStreet: 'AladinMall',
        applyToSection: query,
        product: String(data?.product_variants.id),
        product_id: Number(data?.product_variants.products.id),
        loading: Boolean(loadingOfficial),
        validFrom: startDate,
        validTo: endDate,
        sortBy: PRODUCT_HIGHTLIGHT_SORT_TYPE[String(data?.sort_type)],
        sortValue: data?.sort_type === 'sequence' ? String(data?.weight) : 'default',
        status: data?.is_active
      };
      setFormEdit(result);
      setSelectedStreet(street);
      formik.setFieldValue('street', street);
      formik.setFieldValue('sortBy', PRODUCT_HIGHTLIGHT_SORT_TYPE[String(data?.sort_type)]);
      formik.setFieldValue('sortValue', data?.sort_type === 'sequence' ? String(data?.weight) : 'default');
      formik.setFieldValue('applyToSection', query);
    }
  }, [
    productSubscribtion,
    loadingProductSubscribtion,
    loadingProductTabulation,
    loadingProductHotDeal,
    loadingOfficial,
    officialStore,
    productHotDeal,
    productSubscribtion,
    query,
    productSubscribtion?.data,
    productTabulation?.data,
    productHotDeal?.data,
    officialStore?.data,
    street
  ]);

  // check range valid
  const dateRangeCheck = () => {
    const validFrom = dayjs(formEdit.validFrom).local().format('YYYY-MM-DD');
    const validTo = dayjs(formEdit.validTo).local().format('YYYY-MM-DD');

    const today = dayjs().local().format('YYYY-MM-DD');

    const isTodayRange = today >= validFrom && today <= validTo;
    return !isTodayRange;
  };
  // handle click create
  const handleClickCreate = useCallback(async () => {
    setIsLoading(true);
    if (formEdit.applyToSection === 'Product Pilihan') {
      const payload = {
        ended_time: dayjs(dayjs(formEdit.validTo).format('YYYY-MM-DD') + ' 23:59:59').utc().format('YYYY-MM-DD HH:mm:ss'),
        product_id: formEdit.product_id,
        product_variant_id: formEdit.product,
        sort_type: formEdit.sortBy,
        started_time: dayjs(dayjs(formEdit.validFrom).format('YYYY-MM-DD') + ' 00:00:00').utc().format('YYYY-MM-DD HH:mm:ss'),
        weight: formEdit.sortValue,
        is_active: Boolean(formEdit.status)
      };
      if (formEdit.sortBy !== 'sequence') {
        delete payload.weight;
      }
      const { error } = await supabase.from(TABLE.PRODUCT_SUBSCRIPTION).insert(payload);
      if (error) {
        showFailedToast('failed to update');
        setIsLoading(false);
        return;
      }
      showSuccessToast('Successfully Create');
      setIsLoading(false);
      return;
    }
    if (formEdit.applyToSection === 'Product Tabulation') {
      const payload = {
        ended_time: dayjs(dayjs(formEdit.validTo).format('YYYY-MM-DD') + ' 23:59:59').utc().format('YYYY-MM-DD HH:mm:ss'),
        product_id: formEdit.product_id,
        product_tabulation_id: formEdit.applyToTab,
        product_variant_id: formEdit.product,
        sort_type: formEdit.sortBy,
        started_time: dayjs(dayjs(formEdit.validFrom).format('YYYY-MM-DD') + ' 00:00:00').utc().format('YYYY-MM-DD HH:mm:ss'),
        weight: formEdit.sortValue,
        is_active: Boolean(formEdit.status)
      };
      if (formEdit.sortBy !== 'sequence') {
        delete payload.weight;
      }
      const { error } = await supabase.from(TABLE.PRODUCT_TABULATION).insert(payload);
      if (error) {
        showFailedToast();
        setIsLoading(false);
        return;
      }
      showSuccessToast('Successfully Create');
      setIsLoading(false);
      return;
    }
    if (formEdit.applyToSection === 'Product Hot Deal') {
      const payload = {
        image: [],
        name: ' Product Hot Deal',
        type: 'PRODUCT_HOT_DEAL',
        ended_time: dayjs(dayjs(formEdit.validTo).format('YYYY-MM-DD') + ' 23:59:59').utc().format('YYYY-MM-DD HH:mm:ss'),
        product_id: formEditHotDeal,
        started_time: dayjs(dayjs(formEdit.validFrom).format('YYYY-MM-DD') + ' 00:00:00').utc().format('YYYY-MM-DD HH:mm:ss'),
        status: Boolean(formEdit.status) ? 'ACTIVE' : 'INACTIVE'
      };
      const { error } = await supabase.from(TABLE.BANNER).insert(payload);
      if (error) {
        showFailedToast();
        setIsLoading(false);
        return;
      }
      showSuccessToast('Successfully Create');
      setIsLoading(false);
      return;
    }
    if (formEdit.applyToSection === 'Official Store') {
      const { data } = await supabase.from(TABLE.PRODUCT_VARIANTS).select('merchant_id').eq('id', formEdit.product).maybeSingle();
      const payload = {
        ended_time: dayjs(dayjs(formEdit.validTo).format('YYYY-MM-DD') + ' 23:59:59').utc().format('YYYY-MM-DD HH:mm:ss'),
        product_variant_id: formEdit.product,
        started_time: dayjs(dayjs(formEdit.validFrom).format('YYYY-MM-DD') + ' 00:00:00').utc().format('YYYY-MM-DD HH:mm:ss'),
        weight: formEdit.sortValue,
        sort_type: formEdit.sortBy,
        is_active: Boolean(formEdit.status),
        merchant_id: data?.merchant_id
      };
      if (formEdit.sortBy !== 'sequence') {
        delete payload.weight;
      }
      const { error } = await supabase.from(TABLE.OFFICIAL_MERCHANT).insert(payload);
      if (error) {
        showFailedToast();
        setIsLoading(false);
        return;
      }
      showSuccessToast('Successfully Create');
      setIsLoading(false);
    }
  }, [formEdit, selectedStreet, selectedTabulation, formEditHotDeal]);

  useEffect(() => {
    if (formEdit.validTo && formEdit.validFrom) {
      const currentDate = dayjs().local().format('YYYY-MM-DD');
      const isActive = currentDate >= dayjs(formEdit.validFrom).local().format('YYYY-MM-DD') &&
      currentDate <= dayjs(formEdit.validTo).local().format('YYYY-MM-DD');

      if (!isActive) {
        onChangeFormEdit('status', false);
      }
    }
  }, [formEdit.validTo, formEdit.validFrom]);

  return {
    data: {
      formEditHotDeal,
      getProducts,
      toast,
      isLoading,
      validateDate,
      products,
      selectedTabulation,
      tabulation,
      formEdit,
      selectedStreet,
      street,
      selectedId,
      defaultProduct,
      isFetchingProductSubscrribe,
      isFetchingProductTabulation,
      isFetchingProductHotDeals,
      isFetchingOfficialMerchant,
      formik,
      id,
      isCreate
    },
    method: {
      setFormEditHotDeal,
      handleClickSubmit,
      setSelectedTabulation,
      onChangeFormEdit,
      setSelectedStreet,
      findProducts,
      isFormFieldValid,
      dateRangeCheck
    }
  };
};

export default useEditProductHighlight;
