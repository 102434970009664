import { FC, useCallback } from 'react';
import {
  Button,
  DeleteIcon,
  EditIcon,
  ExplanationPointIcon,
  Table
} from 'akeneo-design-system';
import { Link } from 'react-router-dom';
import '../index.css';
import Spinner from '@/components/base/Spinner';

interface Specification {
  id: number;
  name: string;
  label: string;
  description: string;
  type: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

interface ITableSpecification {
  emptyMessage?: string;
  data?: Specification[] | null;
  isLoading?: boolean;
  handleClickDelete: (id: number) => void;
}

export const TableSpecification: FC<ITableSpecification> = ({ emptyMessage, data, isLoading, handleClickDelete }) => {
  const onClickDelete = useCallback(
    (id: number) => () => {
      handleClickDelete(id);
    },
    [handleClickDelete]
  );
  return (
    <div>
      {isLoading ? (
        <Spinner text="Loading ..." />
      ) : data?.length! > 0 ? (
        <Table className="w-full">
          <Table.Header>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Nama Spesifikasi</Table.HeaderCell>
            <Table.HeaderCell>Label Spesifikasi</Table.HeaderCell>
            <Table.HeaderCell>Tipe Spesifikasi</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {data?.map((row, index) => (
              <Table.Row key={index}>
                <Table.Cell>{row.id}</Table.Cell>
                <Table.Cell>{row.name}</Table.Cell>
                <Table.Cell>{row.label}</Table.Cell>
                <Table.Cell>{row.type}</Table.Cell>
                <Table.Cell>{row.status}</Table.Cell>
                <Table.Cell>
                  <Link to={`detail-attribute/${row.id}`}>
                    <Button ghost level="secondary" className="!rounded-md !px-2">
                      <ExplanationPointIcon />
                    </Button>
                  </Link>
                  <Link to={`edit-attribute/${row.id}`}>
                    <Button ghost level="primary" className="!rounded-md !mx-2 !px-2">
                      <EditIcon />
                    </Button>
                  </Link>
                  <Button ghost level="danger" className="!rounded-md !px-2" onClick={onClickDelete(Number(row.id))}>
                    <DeleteIcon />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <div className="w-full item-center items-center h-60 text-xl font-bold justify-center flex">{emptyMessage}</div>
      )}
    </div>
  );
};
