import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import Table from '@/components/base/DataTable/DataTable';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import useVoucher, { DataVoucher } from './hooks';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { exportTable } from '@/utils/exportTable';
import dayjs from 'dayjs';
import { InputSwitch } from 'primereact/inputswitch';
import { usePermissionStore } from '@/store/usePermissions';
import { IPagination } from '@/components/base/Pagination';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';

const ListVoucher = () => {
  const {
    data: {
      dataVoucher,
      isLoading,
      toast,
      search,
      filterHistory,
      pagination,
      totalPages,
      perPage,
      totalRecords,
      itemFilters
    },
    method: {
      confirmDelete,
      handleSearch,
      onDuplicate,
      handleClearFilter,
      handleDeleteFilterHistory,
      handleChangeDropdownPage,
      handleClickNext,
      handleClickPrev,
      handleJumpToPage,
      handleChangeJumpTopage,
      handleClickSubmitFilter,
      handleFilter
    }
  } = useVoucher();
  const { canCreate, canView, canDelete, canEdit } = usePermissionStore();
  const startTemplate = (voucher: DataVoucher) => {
    return (
      <div id={`voucher-start-date-${voucher.id}`}>{dayjs(voucher?.start_date).format('DD MMM YYYY')}</div>
    );
  };
  const endTemplate = (voucher: DataVoucher) => {
    return (
      <div id={`voucher-end-date-${voucher.id}`}>{dayjs(voucher?.end_date).format('DD MMM YYYY')}</div>
    );
  };
  const statusTemplate = (voucher: DataVoucher) => {
    return (
      <div id={`voucher-status-${voucher.id}`}>
        <InputSwitch checked={voucher.status} id="status" disabled />
      </div>
    );
  };

  const platformTemplate = (voucher: DataVoucher) => {
    return (
      <div id={`voucher-platform-${voucher.id}`}>
        {voucher?.target_platform?.length > 0 ? voucher?.target_platform.join(', ') : '-'}
      </div>
    );
  };

  const codeTemplate = (voucher: DataVoucher) => {
    return (
      <div id={`voucher-platform-${voucher.id}`} className="w-40 break-words">
        {voucher?.code}
      </div>
    );
  };

  const header = [
    { field: 'id', header: 'Promo ID' },
    { field: 'name', header: 'Promo Voucher Name' },
    { field: 'start_date', header: 'Valid From', body: startTemplate },
    { field: 'end_date', header: 'Valid To', body: endTemplate },
    { field: 'platforms', header: 'Platform', body: platformTemplate },
    { field: 'code', header: 'Voucher Code', body: codeTemplate },
    { field: 'status', header: 'Status', body: statusTemplate }
  ];

  // pagination
  const paginationOptions: IPagination = {
    perPage: perPage,
    totalPages: totalPages,
    currentPage: pagination.currentPage,
    disabledDropdown: totalRecords === 0,
    onChangeDropdownPage: handleChangeDropdownPage,
    onClickPrev: handleClickPrev,
    onClickNext: handleClickNext,
    onJumptoPage: handleJumpToPage,
    onChangeJumptoPage: handleChangeJumpTopage
  };

  const filterContent = (
    <div className="flex flex-col gap-4 w-[900px]">
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Promo Voucher Name</label>
          <InputText
            onChange={(e) => handleFilter(e, 'name')}
            value={itemFilters.name}
            name="name"
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Voucher Code</label>
          <InputText
            onChange={(e) => handleFilter(e, 'code')}
            value={itemFilters.code}
            name="code"
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Valid Date</label>
          <Calendar
            onChange={(e) => handleFilter(e, 'valid_date')}
            value={itemFilters.valid_date}
            name="valid_date"
            selectionMode="range"
            showIcon
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Status</label>
          <Dropdown
            options={[{ label: 'ACTIVE', value: 'active' }, { label: 'INACTIVE', value: 'inactive' }]}
            onChange={(e) => handleFilter(e, 'status')}
            value={itemFilters.status}
            placeholder="Select Status"
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <ConfirmDialog />
      <Toast ref={toast} />
      <BaseContainer>
        <MainTitle title="Promo Voucher" isWithButton labelButton="Create Voucher" nav="create"/>
        <div className="flex gap-4 pt-6 justify-end">
          <Button label="Export" severity="success" icon="pi pi-download" disabled={!canView}
            onClick={() => exportTable(dataVoucher as [], 'Promo Product.xlsx')}
          />
        </div>
        <Table
          filter={search}
          isLoadingTable={isLoading}
          headerTable={header}
          paginationOptions={paginationOptions}
          onSearch={handleSearch}
          onDeleted={confirmDelete}
          value={dataVoucher as []}
          isDuplicateAction={canCreate}
          rows={totalRecords}
          onDuplicate={onDuplicate}
          isDeleteAction={canDelete}
          isEditAction={canEdit}
          isDetailAction={canView}
          handleClearFilter={handleClearFilter}
          handleDeleteFilterHistory={handleDeleteFilterHistory}
          filterHistory={filterHistory}
          filterContent={filterContent}
          onSubmitFilter={handleClickSubmitFilter}
        />
      </BaseContainer>
    </>
  );
};

export default ListVoucher;
