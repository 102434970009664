import { Button } from 'primereact/button';
import React, { FC } from 'react';

type IChangeMessage = {
  message?: string;
  onCloseButton: () => void;
};

const ChangeSucces: FC<IChangeMessage> = ({ message, onCloseButton }) => {
  const handleOk = () => {
    onCloseButton();
  };
  return <div id="popUpSuccesChangeCategoryProduct" className="w-1/3 p-10 bg-white">
    <div>
      <h1 className="my-5">Pindah Kategori Berhasil</h1>
      <p>{message}</p>
      <br />
      <div className="flex justify-center">
        <Button onClick={() => handleOk()}>OK</Button>
      </div>
    </div>
  </div>;
};

export default ChangeSucces;
