import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { IPagination } from '@/types/pagination';
import { Database as DatabaseCatalogue } from '@/types/services/catalogue';
import { Database as DatabaseUser } from '@/types/services/user';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { ColorPickerHSBType, ColorPickerRGBType } from 'primereact/colorpicker';
import { Nullable } from 'primereact/ts-helpers';

interface IAttribute {
  color: string | ColorPickerRGBType | ColorPickerHSBType;
  margin_top: number;
  margin_bottom: number;
  tab_name: string[];
  image: string;
  display: string;
}

export type Json =
  | string
  | number
  | boolean
  | null
  | { [key: string]: Json }
  | Json[]

export type Street = DatabaseCatalogue['public']['Tables']['streets']['Row'];
export type Banner = DatabaseCatalogue['public']['Tables']['banners']['Row']
export type ProductTabulation = DatabaseCatalogue['public']['Tables']['product_tabulations']['Row']
export type UserLayout = DatabaseUser['public']['Tables']['layouts']['Row']

export interface ILayoutList {
  id: number;
  slot: string | null;
  images: Json | null;
  attribute: Json | null;
  sequence: number | null;
  platform: string | null;
  updated_at: string | null;
  parent_id: number | null;
  created_at: string | null;
  section_name: string | null;
}
export interface IParentLayout {
  id?: number;
  section_name?: string;
  slot: string;
  attribute?: IAttribute;
  sequence: number;
  platform: string;
  images?: Json
}

export interface ILayout {
  id?: number;
  parent_id?: number;
  section_name?: string;
  slot: string;
  attribute?: Json;
  sequence: number;
  images?: Json;
  platform: string;
}

type AttributeLayout = {
  color: Nullable<string | ColorPickerRGBType | ColorPickerHSBType>;
  margin_top: number
  margin_bottom: number
  tab_name: string[],
  image: string | null,
  display: string;
}

interface IPayloadLayout {
  id: number;
  slot: string;
  images?: Json | null;
  attribute: AttributeLayout;
  sequence: number;
  platform: string;
  parent_id?: number;
  section_name?: string;
}

interface IPayloadProductTabulation {
  layout_id: number;
  name: string
}

interface IFilterLayout {
  street_id_from: number;
  street_id_to: number;
  street_name: string;
  created_at: string[];
  updated_at: string[];
}

const getCategoryStreet = async () => {
  return (await supabase
    .from(TABLE.STREET)
    .select('*')
    .range(0, 10)
  ) as PostgrestSingleResponse<Street[]>;
};

const getListLayout = async (range: IPagination = { start: 0, end: 10 }, search: string, filter: IFilterLayout) => {
  const query = supabase
    .from(TABLE.LAYOUTS)
    .select('*', { count: 'exact' })
    .order('created_at', { ascending: true })
    .range(range.start, range.end)
    .is('parent_id', null);

  // ID
  if (filter.street_id_from > 0) {
    query.gte('id', filter.street_id_from);
  }

  if (filter.street_id_to > 0) {
    query.lte('id', filter.street_id_to);
  }

  // Name
  if (filter.street_name != '' || search != '') {
    query.ilike('section_name', `%${(filter.street_name || search)}%`);
  }

  // Created at
  if (filter.created_at && filter.created_at?.length != 0) {
    const fromDate = dayjs(String(filter.created_at[0])).format('YYYY-MM-DD');
    query.gte('created_at:date', fromDate);

    if (filter.created_at.length > 1 && filter.created_at[1] != null) {
      const toDate = dayjs(String(filter.created_at[1])).format('YYYY-MM-DD');
      query.lte('created_at:date', toDate);
    }
  }

  // Updated at
  if (filter.updated_at && filter.updated_at?.length != 0) {
    const fromDate = dayjs(String(filter.updated_at[0])).format('YYYY-MM-DD');
    query.gte('updated_at:date', fromDate);

    if (filter.updated_at.length > 1 && filter.updated_at[1] != null) {
      const toDate = dayjs(String(filter.updated_at[1])).format('YYYY-MM-DD');
      query.lte('updated_at:date', toDate);
    }
  }


  return await query as PostgrestSingleResponse<ILayoutList[]>;
};

const deleteParentLayout = async (id: number) => {
  return await supabase
    .from(TABLE.LAYOUTS)
    .delete()
    .eq('id', id);
};

const deleteChildLayout = async (id: number) => {
  return await supabase
    .from(TABLE.LAYOUTS)
    .delete()
    .eq('parent_id', id);
};

export const deleteListLayout = async (id: number) => {
  const { error: errorDeleteParent } = await deleteParentLayout(id);
  const { error: errorDeleteChild } = await deleteChildLayout(id);

  return {
    errorDeleteChild,
    errorDeleteParent
  };
};

const getDetailParentLayout = async (id?: number) => {
  return await supabase
    .from(TABLE.LAYOUTS)
    .select('*')
    .eq('id', id)
    .single() as PostgrestSingleResponse<ILayoutList>;
};

const getDetailChildLayout = async (parentId: number) => {
  return await supabase
    .from(TABLE.LAYOUTS)
    .select('*')
    .order('sequence', { ascending: true })
    .eq('parent_id', parentId) as PostgrestSingleResponse<ILayoutList[]>;
};

const updateParentLayout = async (payload: IPayloadLayout) => {
  return await supabase
    .from(TABLE.LAYOUTS)
    .upsert(payload)
    .select()
    .single() as PostgrestSingleResponse<UserLayout>;
};

const insertProductTabulation = async (payload: IPayloadProductTabulation[]) => {
  return await supabase
    .from(TABLE.TABULATION)
    .insert(payload)
    .select();
};

const updateChildLayout = async (payload: IPayloadLayout[]) => {
  return await supabase
    .from(TABLE.LAYOUTS)
    .upsert(payload, { onConflict: 'id' });
};

const deleteSectionStreet = async (id: number) => {
  return await supabase
    .from(TABLE.LAYOUTS)
    .delete()
    .eq('id', id);
};

const getProductTabulationByLayoutId = async (id: number) => {
  return await supabase
    .from(TABLE.TABULATION)
    .select('*')
    .eq('layout_id', id) as PostgrestSingleResponse<ProductTabulation[]>;
};

const deleteProductTabulationById = async (ids: number[]) => {
  return await supabase
    .from(TABLE.TABULATION)
    .delete()
    .in('id', ids);
};

const deleteProductTabulationByLayoutId = async (id: number) => {
  return await supabase
    .from(TABLE.TABULATION)
    .delete()
    .eq('layout_id', id);
};

export const useCategoryStreetList = () =>
  useQuery({
    queryKey: ['categoryStreet'],
    queryFn: getCategoryStreet,
    refetchOnWindowFocus: false
  });

export const useGetListLayout = (range: IPagination = { start: 0, end: 10 }, search: string, filter: IFilterLayout) =>
  useQuery({
    queryKey: ['getListLayout', range, search, filter],
    queryFn: async () => await getListLayout(range, search, filter),
    refetchOnWindowFocus: false
  });

export const useDeleteLayout = (refetchList: () => void) =>
  useMutation({
    mutationKey: ['deleteParentLayout'],
    mutationFn: (id: number) => deleteListLayout(id),
    onSuccess: refetchList
  });

export const useGetParentLayout = (id?:number) =>
  useQuery({
    queryKey: ['getParentLayout', id],
    queryFn: async () => await getDetailParentLayout(id),
    refetchOnWindowFocus: false,
    enabled: !!id
  });

export const useGetChildLayout = (parentId: number) =>
  useQuery({
    queryKey: ['getChildLayout', parentId],
    queryFn: async () => await getDetailChildLayout(parentId),
    refetchOnWindowFocus: false
  });

export const useUpdateParentLayout = (refetch?: () => void) =>
  useMutation({
    mutationKey: ['updateParentLayout'],
    mutationFn: async (payload: IPayloadLayout) => await updateParentLayout(payload),
    onSuccess: refetch
  });

export const useUpdateChildLayout = (refetch?: () => void) =>
  useMutation({
    mutationKey: ['updateChildLayout'],
    mutationFn: async (payload: IPayloadLayout[]) => await updateChildLayout(payload),
    onSuccess: refetch
  });

export const useDeleteSectionStreet = (refetch?: () => void) =>
  useMutation({
    mutationKey: ['deleteSectionStreet'],
    mutationFn: async (id: number) => await deleteSectionStreet(id),
    onSuccess: refetch
  });

export const useGetProductTabulationByLayoutId = (id: number) =>
  useQuery({
    queryKey: ['getProductTabulation', id],
    queryFn: () => getProductTabulationByLayoutId(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });

export const useCreateProductTabulation = () =>
  useMutation({
    mutationKey: ['createProductTabulation'],
    mutationFn: (payload: IPayloadProductTabulation[]) => insertProductTabulation(payload)
  });

export const useDeleteProductTabulationById = () =>
  useMutation({
    mutationKey: ['deleteProductTabulationById'],
    mutationFn: (ids: number[]) => deleteProductTabulationById(ids)
  });

export const useDeleteProductTabulationByLayoutId = () =>
  useMutation({
    mutationKey: ['deleteProductTabulationByLayoutId'],
    mutationFn: (id: number) => deleteProductTabulationByLayoutId(id)
  });
