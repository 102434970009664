import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTableRowClickEvent, DataTableSortEvent } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { FC, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import IconPencil from '../../../assets/IconPencil';
import IconTrash from '@/assets/IconTrash';
import IconShow from '@/assets/IconShow';
import Pagination, { IPagination } from '../Pagination';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import FilterHistory, { IFilterHistoryItems } from '../FilterHistory';

type ITable = {
  value: [];
  filter: string;
  headerTable: { field: string; header: string, body?: (e) => JSX.Element, sortable?: boolean }[];
  onSearch: (e) => void;
  onDeleted?: (id) => void;
  isWithIcon?: boolean;
  isWithCheckBox?: boolean;
  customId?: string;
  selection?: [];
  onSelectionChange?: (e) => void;
  isLoadingTable?: boolean;
  isDetailAction?: boolean;
  isEditAction?: boolean;
  isDeleteAction?: boolean;
  isDuplicateAction?: boolean;
  onDuplicate?: (data: Object) => void;
  isProductHighlight?: boolean;
  isCustomAction?: boolean;
  paginationOptions?: IPagination;
  onSort?: (e: DataTableSortEvent) => void;
  rows?: number;
  filterDelay?: number;
  onRowClick?: (e: DataTableRowClickEvent) => void;
  filterContent?: JSX.Element;
  filterHistory?: IFilterHistoryItems[];
  handleClearFilter: () => void;
  handleDeleteFilterHistory: (name: string, value: string[]) => void;
  onSubmitFilter?: () => void;
  isWithButton?: boolean;
};


const Table: FC<ITable> = ({
  value,
  filter,
  headerTable,
  onSearch,
  onDeleted=()=>{},
  isWithIcon,
  isWithCheckBox,
  customId = 'id',
  selection,
  onSelectionChange,
  onDuplicate = () => { },
  isLoadingTable,
  isDetailAction = true,
  isEditAction = true,
  isDeleteAction = true,
  isDuplicateAction,
  isProductHighlight,
  isCustomAction,
  paginationOptions,
  onSort,
  rows = 10,
  filterDelay = 300,
  onRowClick,
  filterContent,
  filterHistory,
  handleClearFilter,
  handleDeleteFilterHistory,
  onSubmitFilter,
  isWithButton
}) => {
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);

  const deleted = useCallback(
    (id) => {
      onDeleted(id);
    },
    [onDeleted]
  );

  const duplicate = useCallback(
    (data: Object) => {
      onDuplicate(data);
    },
    [onDuplicate]
  );

  const onClickBtnFilter = useCallback(() => {
    setIsOpenDialog(true);
  }, []);

  const onClickSubmitFilter = useCallback(() => {
    if (onSubmitFilter) {
      onSubmitFilter();
    }
    setIsOpenDialog(false);
  }, [onSubmitFilter]);


  const footerContent = (
    <div className="flex justify-end py-4 gap-4">
      <Button label="Batal" severity="secondary" outlined onClick={() => setIsOpenDialog(false)} size="large" />
      <Button label="Terapkan" severity="info" onClick={onClickSubmitFilter} size="large" />
    </div>
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 my-4">
        <div className="flex justify-end gap-2">
          <Button
            icon="pi pi-sliders-h"
            onClick={onClickBtnFilter}
            label="Filter"
            severity="secondary"
            text
            raised
          />
          {(isWithButton && (
            <div className="flex gap-2">
              <Link to="/product-highlight/create">
                <Button label="Add Product" className="w-[180px] h-fit" icon="pi pi-plus" severity="danger" />
              </Link>
              <Link to="/product-highlight/create/bulk">
                <Button label="Upload Product" className="w-[180px] h-fit" severity="danger"/>
              </Link>
            </div>
          ))}
        </div>
        {filterHistory && filterHistory.length > 0 ?
          <FilterHistory
            filterHistory={filterHistory}
            handleClearFilter={handleClearFilter}
            handleDeleteFilterHistory={handleDeleteFilterHistory}
            show={filterHistory?.length > 0}
          /> :
          <Divider className="p-divider-solid " />
        }
        <div className="flex justify-between items-center">
          <div id="search" className="p-input-icon-right w-[20%]">
            <InputText className="w-full" placeholder="Cari Disini" type="search" onChange={onSearch} keyfilter={/^[a-zA-Z0-9()+\-\s]+$/} value={filter} />
            <i className="pi pi-search" />
          </div>
          <div className="flex items-center justify-end gap-6 w-full">
            <h4 className="font-bold justify-self-end"> {rows} data ditemukan</h4>
            <Pagination
              perPage={paginationOptions?.perPage}
              totalPages={paginationOptions?.totalPages}
              currentPage={paginationOptions?.currentPage}
              disabledDropdown={paginationOptions?.disabledDropdown}
              onClickPrev={paginationOptions?.onClickPrev}
              onClickNext={paginationOptions?.onClickNext}
              onChangeDropdownPage={paginationOptions?.onChangeDropdownPage}
              onJumptoPage={paginationOptions?.onJumptoPage}
              onChangeJumptoPage={paginationOptions?.onChangeJumptoPage}
            />
          </div>
        </div>
      </div>
      <div className="card border">
        <DataTable
          value={value}
          rows={rows}
          scrollable
          scrollHeight="34rem"
          dataKey={customId}
          showGridlines
          removableSort
          selectionPageOnly
          filterDelay={filterDelay}
          emptyMessage="No Data Found"
          tableStyle={{ minWidth: '12rem' }}
          selectionMode="checkbox"
          onSort={onSort}
          selection={selection}
          onSelectionChange={onSelectionChange}
          loading={isLoadingTable}
          onRowClick={onRowClick}
        >
          {isWithCheckBox && <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />}
          {headerTable.map((item, index) => {
            return item.body !== null ?
              (
                <Column key={`${item.header} ${index}`} alignHeader="center" body={item.body} sortable={item.sortable === undefined} align="center" field={item.field} header={item.header} />
              ) :
              (
                <Column key={`${item.header} ${index}`} alignHeader="center" align="center" field={item.field} header={item.header} />
              );
          })}
          {isWithIcon && (
            <Column
              header="icon"
              alignHeader="center"
              align="center"
              body={(rowData) =>
                rowData.images ? (
                  <div>
                    <img src={rowData.images.data.image} width={70} alt="" />
                  </div>
                ) : null
              }
            />
          )}
          {!isCustomAction && (
            <Column
              header="Action"
              alignHeader="center"
              align="center"
              body={(rowData) => (
                <div className="flex justify-center gap-2">
                  {isDetailAction && (
                    <Link to={isProductHighlight ? `${rowData.id}?section=${rowData.section}` : `${rowData.id}`}>
                      <Button
                        icon={IconShow}
                        outlined
                        rounded
                        severity="secondary"
                      />
                    </Link>
                  )}
                  {isEditAction && (
                    <Link to={isProductHighlight ? `edit/${rowData.id}?section=${rowData.section}` : `edit/${rowData.id}`}>
                      <Button
                        icon={IconPencil}
                        outlined
                        rounded
                        severity="secondary"
                      />
                    </Link>
                  )}
                  {isDeleteAction && (typeof rowData?.isEditable === 'undefined' || rowData?.isEditable) && (
                    <Button
                      icon={IconTrash}
                      outlined
                      rounded
                      severity="danger"
                      onClick={(e) => deleted(isProductHighlight ? rowData : rowData.id)} />
                  )}
                  {isDuplicateAction && (
                    <Button icon="pi pi-copy" outlined rounded onClick={() => duplicate(rowData)} />
                  )}
                </div>
              )}
            />
          )}
        </DataTable>
      </div>
      <Dialog onHide={() => setIsOpenDialog(false)} visible={isOpenDialog} header="Semua Filter" footer={footerContent}>
        {filterContent}
      </Dialog>
    </div>
  );
};


export default Table;
