import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import useCustom from '../hooks';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import LoadingOverlay from '@/components/base/LoadingOverlay';

const EditSlots = () => {
  const {
    data: {
      toast,
      formEditSlots,
      rangeTimeSlots,
      optionsStatus,
      selectedStatus,
      isLoading
    },
    methods: {
      addMoreSlot,
      onChangeFormEditSlots,
      validateEditSlots,
      navigate,
      handleTimeSlotChange,
      deleteSlot,
      handleSetStatus
    }
  } = useCustom();

  return (
    <>
      <BaseContainer>
        <LoadingOverlay show={isLoading}/>
        <MainTitle isWithButton={false} title="Edit Slot" />
        <div className="border p-6">
          <div className="flex">
            <div className="w-1/4">
              <div id="slot-title1" className="border border-solid py-3 font-bold bg-gray-400 text-center">SLOT INFORMATION</div>
              <div id="slot-title2" className="border border-solid py-3 font-normal text-center">Slot Detail</div>
            </div>
            <div className="border p-10 w-full space-y-3">
              <div className="flex gap-[5.2rem] items-center">
                Slot Name*
                <InputText
                  id="slot-name-input"
                  className="w-2/5"
                  placeholder="Masukkan Nama Slot"
                  value={formEditSlots.name}
                  onChange={(e) => {
                    onChangeFormEditSlots('name', e.target.value);
                  }}
                />
              </div>
              <div className="flex gap-[5.2rem] items-center">
                Description
                <InputText
                  value={formEditSlots.description}
                  onChange={(e) => {
                    onChangeFormEditSlots('description', e.target.value);
                  }}
                  id="slot-description-input"
                  className="w-2/5"
                  placeholder="Masukkan Deskripsi Slot"
                />
              </div>
              {rangeTimeSlots.map((timeSlots, index) => {
                return (
                  <div key={index} id="slot-time" className="flex w-full items-center gap-x-4">
                    {index == 0 ? <p className="flex w-[158px] gap-2">Select Slot Time*</p> : <div className="w-[158px] gap-2" />}
                    <div className="border border-solid p-3 rounded-lg mr-3 w-[10%] bg-gray-400"> Slot {index + 1}</div>
                    <div id="slot-time-input" className="card flex flex-initial gap-2 p-fluid items-center">
                      <Calendar
                        className={timeSlots.started_time >= timeSlots.ended_time && 'p-invalid' || ''}
                        id="slot-started-time-input"
                        value={timeSlots.started_time as Date}
                        onChange={(e: CalendarChangeEvent) =>
                          handleTimeSlotChange(index, 'started_time', e.value as Date)
                        }
                        timeOnly
                        hideOnDateTimeSelect
                      />
                      -
                      <Calendar
                        className={timeSlots.started_time >= timeSlots.ended_time && 'p-invalid' || ''}
                        id="slot-ended-time-input"
                        value={timeSlots.ended_time as Date}
                        onChange={(e: CalendarChangeEvent) =>
                          handleTimeSlotChange(index, 'ended_time', e.value as Date)
                        }
                        timeOnly
                        hideOnDateTimeSelect
                      />
                    </div>
                    <Button disabled={rangeTimeSlots.length == 1} icon="pi pi-trash" id={`button-delete-slot-${index}`} onClick={()=>deleteSlot(index)} />
                  </div>
                );
              })}
              <div className="flex justify-content-start pl-5">
                <div className="w-[120px]" />
                <div className="card">
                  <Button id="slot-add-button" label="+ Add More" severity="success" text onClick={addMoreSlot} />
                </div>
              </div>
              <div className="flex flex-col">
                <div id="slot-input-type" className="flex gap-[5.2rem] items-center">
                  Status*
                  <div className="card flex justify-content-center px-8 w-2/5">
                    <Dropdown
                      id="slot-input-type"
                      value={selectedStatus}
                      onChange={(e: DropdownChangeEvent) => {
                        handleSetStatus(e.value);
                      }}
                      options={optionsStatus}
                      optionLabel="name"
                      placeholder="Select a status"
                      className="w-full md:w-14rem"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Toast ref={toast} />
        <SubmitButton
          disabledSubmit={isLoading}
          onSubmit={() => {
            validateEditSlots();
          }}
          onCancel={() => {
            navigate('/master-slot');
          }}
          labelBack="Cancel"
          labelSubmit="Submit"
        />
      </BaseContainer>
    </>
  );
};

export default EditSlots;
