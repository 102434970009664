import { DataTable } from 'primereact/datatable';
import useStoreCredit from './StoreCreditHooks';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

const StoreCredit = () => {
  const {
    data: { tabMenuActiveIndex },
    method: {
      setTabMenuActiveIndex
    }
  } = useStoreCredit();

  const items: MenuItem[] = [
    { label: 'Store Credit' },
    { label: 'Update Balance' },
    { label: 'Balance History' }
  ];

  const columnStoreCredit = [
    { field: 'balance', header: 'Balance' },
    { field: 'website', header: 'Website' }
  ];


  const fieldUpdateBalance = [
    { label: 'Website', id: 'website-input', value: '' },
    { label: 'Update Balance', id: 'update-balance-input', value: '' },
    { label: 'Notify Customer', id: 'notify-customer-input', value: '' },
    { label: 'Send Email Notification From the Following Store View', id: 'send-email-input', value: '' },
    { label: 'Comment', id: 'comment-input', value: '' }
  ];

  const columnBalanceHistory = [
    { field: 'balance', header: 'Date' },
    { field: 'website', header: 'Website' },
    { field: 'website', header: 'Action' },
    { field: 'website', header: 'Balance Change' },
    { field: 'website', header: 'Balance' },
    { field: 'website', header: 'Customer Notifed' },
    { field: 'website', header: 'More Information' }
  ];
  return (
    <>
      <TabMenu id="customer-tab-menu" className="mb-5" model={items} activeIndex={tabMenuActiveIndex} onTabChange={(e) => {
        setTabMenuActiveIndex(e.index);
      }}/>
      {tabMenuActiveIndex === 0 && (
        <DataTable
          id="customer-table"
          paginator
          emptyMessage="We cloudn't find any records"
        >
          {columnStoreCredit.map((item, index) => <Column key={index} header={item.header} field={item.field} />)}
        </DataTable>
      )}
      {tabMenuActiveIndex === 1 && (
        <>
          {fieldUpdateBalance.map((item, index) => (
            <div key={index} className="flex gap-4 space-y-3 px-6">
              <div className="flex items-center w-[300px]">
                {item.label}
              </div>
              <div className="w-full">
                <InputText id={item.id} className="w-full" value={item.value}/>
              </div>
            </div>
          ))}
        </>
      )}
      {tabMenuActiveIndex === 2 && (
        <DataTable
          id="customer-table"
          paginator
          emptyMessage="We cloudn't find any records"
        >
          {columnBalanceHistory.map((item, index) => <Column key={index} header={item.header} field={item.field} />)}
        </DataTable>
      )}
    </>
  );
};

export default StoreCredit;
