import { IFilterHistoryItems } from '@/components/base/FilterHistory';
import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { getShippingAddressByName } from '@/services/rest/customerManagement';
import { useHistoryStore } from '@/store/useHistoryStore';
import dayjs from 'dayjs';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { useDebounce } from 'primereact/hooks';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';

interface ColumnTable {
  field: string,
  header: string
}

export interface DataCustomer {
  id: string;
  name: string;
  email: string;
  phone: string;
  province: Province | null;
  gender: string | null;
  created_at: string;
}

export interface Province {
  name: string;
}

interface IFilterCustomer{
  name: string;
  email: string;
  phone: string;
  province: string;
  created_at: Date[];
  gender: string;
}

const initialFilter: IFilterCustomer = {
  name: '',
  email: '',
  phone: '',
  province: '',
  created_at: [],
  gender: ''
};

const useCustomerManagement = () => {
  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const [search, debounceSearch, setSearch] = useDebounce('', 1000);
  const [filter, setFilter] = useState<Array<string>>([]);
  const [dataCustomer, setDataCustomer] = useState<DataCustomer[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<Array<string>>([]);
  const [dataToExport, setDataToExport] = useState<Partial<DataCustomer>[]>([]);
  const [itemFilters, setItemFilters] = useState<IFilterCustomer>(
    lastFilterPage.customerManagement != '' ? JSON.parse(String(lastFilterPage.customerManagement)) : initialFilter
  );
  const [filters, setFilters] = useState<IFilterCustomer>(
    lastFilterPage.customerManagement != '' ? JSON.parse(String(lastFilterPage.customerManagement)) : initialFilter
  );
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [jumpToPage, setJumpToPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const currentPage = parseInt(visitedPage.customerManagement.toString()) ?? 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : perPage - 1;
  const [paginator, setPaginator] = useState({ currentPage, range: { start, end } });
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);

  const column: ColumnTable[] = [
    { field: 'id', header: 'ID' },
    { field: 'name', header: 'Name' },
    { field: 'email', header: 'Email' },
    { field: 'phone', header: 'Phone' },
    { field: 'province', header: 'State/Province' },
    { field: 'created_at', header: 'Customer Since' },
    { field: 'gender', header: 'Gender' }
  ];
  const [visibleColumn, setVisibleColumn] = useState<ColumnTable[]>(column);

  const defaultView = () => {
    setVisibleColumn(column);
    setSearch('');
  };

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    const selectedColumn = event.value;
    const orderedSelectedColumn = column.filter((col) => selectedColumn.some((sCol: { field: string; }) => sCol.field === col.field));
    const filteredColumn = dataCustomer.map((data) => {
      const filteredField: Partial<DataCustomer> = {};
      selectedColumn.forEach((col) => {
        filteredField[col.field] = (data[col.field]);
      });
      return filteredField as [];
    });

    setDataToExport(filteredColumn as []);
    setVisibleColumn(orderedSelectedColumn);
  };

  const handleSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>)=>{
    setSearch(currentTarget.value);
    const newKeyword = {
      name: 'Keyword',
      items: [{
        label: currentTarget.value,
        value: currentTarget.value,
        name: currentTarget.value
      }]
    };

    if (currentTarget.value != '') {
      setFilterHistory((prev: IFilterHistoryItems[]) => {
        const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
        if (existingIndex !== -1) {
          prev[existingIndex] = newKeyword;
        } else {
          prev.push(newKeyword);
        }
        return [...prev];
      });

      setPaginator({
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      });
    } else {
      handleDeleteFilterHistory('Keyword', ['Keyword']);
    }
  }, [perPage]);

  const handleFilter = useCallback((e, field: string) => {
    setItemFilters((prev) => ({
      ...prev,
      [field]: e.target ? e.target.value : e.value
    }));
  }, []);

  const handleClearFilter = useCallback(() => {
    setLastFilterPage({
      ...lastFilterPage,
      customerManagement: ''
    });
    setItemFilters(initialFilter);
    setFilters(initialFilter);
    setFilterHistory([]);
    setSearch('');
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [lastFilterPage, perPage]);

  const handleDeleteFilterHistory = useCallback(
    (key: string, value: string[]) => {
      const items = value[0].split(',');
      items.forEach((i) => {
        setFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));

        setItemFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));
      });

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setSearch('');
    },
    []
  );

  const getDataCustomer = useCallback(async ()=>{
    setIsLoading(true);

    // get user type customer
    const query = supabase
      .from(TABLE.ACCOUNTS)
      .select(
        `
      *, shipping_addresses(
        is_primary,
        provinces(name)
      )
    `, { count: 'exact' }
      )
      .neq('first_name', '')
      .not('phone_number', 'is', null)
      .not('email', 'is', null)
      .or('type.ilike.%customer%,type.is.null')
      .order('created_at', { ascending: false })
      .range(paginator.range.start, paginator.range.end);

    // Gender
    if (filters.gender != '') {
      if (filters.gender === 'laki-laki') {
        query.or('gender.ilike.male%,gender.ilike.%laki%,gender.ilike.%pria%');
      } else if (filters.gender === 'perempuan') {
        query.or('gender.ilike.female%,gender.ilike.%wanita%,gender.ilike.%perempuan%');
      }
    }

    // Email
    if (filters.email != '') {
      query.eq('email', filters.email);
    }

    // Name
    if (filters.name != '') {
      query.or(`first_name.ilike.%${filters.name}%,last_name.ilike.%${filters.name}%`);
    }

    // Phone
    if (filters.phone != '') {
      query.ilike('phone_number', `%${filters.phone}%`);
    }

    // Province
    if (filters.province != '') {
      const { data: dataAddress } = await getShippingAddressByName(filters.province);
      const accountId = dataAddress?.map((item) => item.account_id);
      if (accountId && accountId?.length > 0) {
        query.in('id', accountId);
      }
    }

    // Created at
    if (filters.created_at && filters.created_at?.length != 0) {
      const fromDate = dayjs(String(filters.created_at[0])).format('YYYY-MM-DD');
      query.gte('created_at:date', fromDate);

      if (filters.created_at.length > 1 && filters.created_at[1] != null) {
        const toDate = dayjs(String(filters.created_at[1])).format('YYYY-MM-DD');
        query.lte('created_at:date', toDate);
      }
    }

    // General Search
    if (debounceSearch != '') {
      const { data: dataAddress } = await getShippingAddressByName(debounceSearch);
      const accountId = dataAddress?.map((item) => item.account_id);
      if (accountId && accountId?.length > 0) {
        query.in('id', accountId);
      } else {
        query.or(
          `first_name.ilike.%${debounceSearch}%,last_name.ilike.%${debounceSearch}%,email.ilike.%${debounceSearch}%`
        );
      }
    }

    const { data: customer, count: customerCount } = await query;

    const customerArr = customer;

    setIsLoading(false);

    const customerMap = customerArr?.map((item) => {
      const MALE_GENDER = ['male', 'pria', 'laki-laki'];
      const FEMALE_GENDER = ['female', 'wanita', 'perempuan'];
      let gender = '-';

      if (item.gender != '') {
        if (MALE_GENDER.includes(item.gender?.toLowerCase())) {
          gender ='Laki-laki';
        } else if (FEMALE_GENDER.includes(item.gender?.toLowerCase())) {
          gender = 'Perempuan';
        }
      }

      return {
        id: item.id,
        name: (item.first_name !== null && `${item.first_name || ''} ${item.last_name || ''}` || ''),
        email: item.email,
        phone: item.phone_number,
        province: (item.shipping_addresses.length > 0 && item.shipping_addresses?.find((item) => item.is_primary)?.provinces?.name || '-'),
        gender: gender,
        created_at: dayjs(item.created_at).format('MMM DD, YYYY')
      };
    });

    if (customerCount) {
      setTotalRecords(customerCount);
    } else {
      setTotalRecords(0);
    }
    setDataCustomer(customerMap as []);
  }, [debounceSearch, paginator, filters]);

  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExport.length == 0 && dataCustomer || dataToExport);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });

      saveAsExcelFile(excelBuffer, 'dataCustomer');
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  };

  const totalPages = useMemo(() => {
    return Math.ceil(totalRecords / perPage);
  }, [totalRecords, perPage]);

  const handleClickNext = useCallback(() => {
    paginator.currentPage <= totalPages &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: paginator.currentPage + 1,
        range: {
          start: paginator.range.start + perPage,
          end: paginator.range.end + perPage
        }
      }));
  }, [paginator, totalPages, perPage]);

  const handleClickPrev = useCallback(() => {
    paginator.range.start > 0 &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: prev.currentPage - 1,
        range: {
          start: prev.range.start - perPage,
          end: prev.range.end - perPage
        }
      }));
  }, [paginator, perPage]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent) => {
    setPerPage(event.value);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, totalRecords - 1);

      setPaginator(() => ({
        currentPage: jumpToPage > totalPages ? totalPages : value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, totalRecords]);

  const handleClickSubmitFilter = useCallback(() => {
    setFilters(itemFilters);
    setIsOpenDialog(false);
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [itemFilters, perPage]);

  const storeFilterHistory = useCallback((filter) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter?.created_at && filter.created_at[0]) {
      let filterDate = `${dayjs(filter.created_at[0]).format('YYYY-MM-DD')}`;
      if (filter.created_at.length > 1 && filter.created_at[1] !== null) {
        filterDate = `${dayjs(filter.created_at[0]).format('YYYY-MM-DD')} - ${dayjs(filter.created_at[1]).format('YYYY-MM-DD')}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Customer Since', 'created_at', filterDate));
    }

    if (filter.name !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Name', 'name', filter.name));
    }

    if (filter.email !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Email', 'email', filter.email));
    }

    if (filter.phone !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Phone', 'phone', filter.phone));
    }

    if (filter.province !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Province', 'province', filter.province));
    }

    if (filter.gender !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Gender', 'gender', filter.gender));
    }

    setFilterHistory(filterHistoryItems);
  }, []);

  useEffect(() => {
    getDataCustomer();
  }, [filters, debounceSearch, paginator.range]);

  useEffect(() => {
    // update store visitedPage with latest page
    setVisitedPage({
      ...visitedPage,
      customerManagement: paginator.currentPage
    });
  }, [paginator.currentPage]);

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  // update store lastFilterPage with latest filter
  useEffect(() => {
    setLastFilterPage({
      ...lastFilterPage,
      customerManagement: JSON.stringify(filters)
    });
  }, [filters]);

  return {
    data: {
      column,
      isLoading,
      dataCustomer,
      search,
      selectedFilter,
      filter,
      visibleColumn,
      filterHistory,
      filters,
      totalRecords,
      perPage,
      paginator,
      totalPages,
      itemFilters,
      isOpenDialog
    },
    method: {
      getDataCustomer,
      setSearch,
      setFilter,
      setSelectedFilter,
      onColumnToggle,
      exportExcel,
      handleSearch,
      defaultView,
      handleFilter,
      handleClearFilter,
      handleDeleteFilterHistory,
      handleClickSubmitFilter,
      handleClickNext,
      handleClickPrev,
      handleChangeDropdownPage,
      handleChangeJumpTopage,
      handleJumpToPage,
      setIsOpenDialog
    }
  };
};

export default useCustomerManagement;
