import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import useAuditTrail from './hooks';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabPanel, TabView } from 'primereact/tabview';
import Pagination from '@/components/base/Pagination';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Link } from 'react-router-dom';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import FilterHistory from '@/components/base/FilterHistory';

function AuditTrail() {
  const { data, methods } = useAuditTrail();
  const headerProductRevisions = [
    { field: 'product_id', header: 'Product ID' },
    { field: 'product_name', header: 'Product Name' },
    { field: 'old_fields', header: 'Data Before Changes' },
    { field: 'field_changes', header: 'Data Changed' },
    { field: 'new_fields', header: 'Data After Changes' },
    { field: 'merchant_name', header: 'Changed By' },
    { field: 'updated_at', header: 'Changed At' }
  ];

  const headerOrderStatusChanges = [
    { field: 'order_no', header: 'Order ID' },
    { field: 'sku', header: 'SKU' },
    { field: 'merchant_name', header: 'Merchant Name' },
    { field: 'qty', header: 'Quantity' },
    { field: 'amount', header: 'Amount' },
    { field: 'status', header: 'Status' },
    { field: 'updated_by_role', header: 'Updated By' },
    { field: 'updated_at', header: 'Updated At' }
  ];

  const filterContentProduct = (
    <div className="flex flex-col gap-4 w-[900px]">
      <div className="w-full flex flex-col items-start gap-2">
        <h1 className="font-bold">Product ID</h1>
        <div className="flex w-full gap-8">
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Dari</label>
            <InputNumber
              onChange={(e) => methods.handleFilter(e, 'product_id_from')}
              value={data.itemFilters.product_id_from}
              name="product_id_from"
            />
          </div>
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Sampai</label>
            <InputNumber
              onChange={(e) => methods.handleFilter(e, 'product_id_to')}
              value={data.itemFilters.product_id_to}
              name="product_id_to"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Product Name</label>
          <InputText
            onChange={(e) => methods.handleFilter(e, 'product_name')}
            value={data.itemFilters.product_name}
            name="product_name"
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Changed By</label>
          <InputText
            onChange={(e) => methods.handleFilter(e, 'created_by')}
            value={data.itemFilters.created_by}
            name="created_by"
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Changed At</label>
          <Calendar
            onChange={(e) => methods.handleFilter(e, 'created_at')}
            value={data.itemFilters.created_at}
            showIcon
            selectionMode="range"
          />
        </div>
      </div>
    </div>
  );

  const footerContentProduct = (
    <div className="flex justify-end py-4 gap-4">
      <Button label="Batal" severity="secondary" outlined onClick={() => methods.setIsOpenDialog(false)} size="large" />
      <Button label="Terapkan" severity="info" onClick={methods.handleClickSubmitFilter} size="large" />
    </div>
  );

  return (
    <BaseContainer>
      <MainTitle title="Audit Trail" />
      <TabView id="audit-trail-tabview" onBeforeTabChange={methods.onTabChange} activeIndex={Number(data.seacrhParams.get('index'))}>
        {/* product */}
        <TabPanel header={<Link to="/audit-trail?index=0">Product</Link>}>
          <div className="flex flex-col gap-2 my-4">
            <div className="flex justify-end">
              <Button
                icon="pi pi-sliders-h"
                onClick={() => methods.setIsOpenDialog(true)}
                label="Filter"
                severity="secondary"
                text
                raised
              />
            </div>
            {data.filterHistory && data.filterHistory.length > 0 ?
              <FilterHistory
                filterHistory={data.filterHistory}
                handleClearFilter={methods.handleClearFilter}
                handleDeleteFilterHistory={methods.handleDeleteFilterHistory}
                show={data.filterHistory?.length > 0}
              /> :
              <Divider className="p-divider-solid " />
            }
            <div className="flex justify-between items-center">
              <div className="w-[20%]">
                <InputText
                  id="audit-trail-search-input"
                  className="w-full"
                  placeholder="Search"
                  type="search"
                  onChange={methods.handleSearch}
                  keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
                  value={data.search}
                />
              </div>
              <div className="flex items-center justify-end gap-6 w-full">
                <h4 className="font-bold justify-self-end"> {data.totalRecords} data ditemukan</h4>
                <Pagination
                  perPage={data.perPage}
                  totalPages={data.totalPages}
                  currentPage={data.paginator.currentPage}
                  disabledDropdown={data.totalRecords === 0}
                  onClickPrev={methods.handleClickPrev}
                  onClickNext={methods.handleClickNext}
                  onChangeDropdownPage={methods.handleChangeDropdownPage}
                  onChangeJumptoPage={methods.handleChangeJumpTopage}
                  onJumptoPage={methods.handleJumpToPage}
                />
              </div>
            </div>
          </div>
          <div>
            <DataTable
              removableSort
              value={data.dataProductRevisions}
              loading={data.isLoading}
              totalRecords={data.totalRecords}
              rows={data.perPage}
              scrollable
              dataKey="id"
              showGridlines
              tableStyle={{ minWidth: '12rem' }}
            >
              {headerProductRevisions.map((item, index) => {
                return <Column key={index} sortable field={item.field} header={item.header} align="center" />;
              })}
            </DataTable>
          </div>
          <Dialog onHide={() => methods.setIsOpenDialog(false)} visible={data.isOpenDialog} header="Semua Filter" footer={footerContentProduct}>
            {filterContentProduct}
          </Dialog>
        </TabPanel>
        {/* order */}
        <TabPanel header={<Link to="/audit-trail?index=1">Order</Link>}>
          <div className="flex flex-col gap-2 my-4">
            <Divider className="p-divider-solid " />
            <div className="flex justify-between items-center">
              <div className="w-[20%]">
                <InputText
                  id="audit-trail-search-input"
                  className="w-full"
                  placeholder="Search"
                  type="search"
                  onChange={(e) => methods.setFilterOrder(e.target.value)}
                  value={data.filtersOrder}
                  keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
                />
              </div>
              <div className="flex justify-end w-full">
                <Pagination
                  perPage={data.perPage}
                  totalPages={data.totalPages}
                  currentPage={data.paginator.currentPage}
                  disabledDropdown={data.totalRecords === 0}
                  onClickPrev={methods.handleClickPrev}
                  onClickNext={methods.handleClickNext}
                  onChangeDropdownPage={methods.handleChangeDropdownPage}
                />
              </div>
            </div>
          </div>
          <div>
            <DataTable
              id="audit-trail-table"
              removableSort
              loading={data.isLoading}
              globalFilter={data.filterOrderDebounce}
              value={data.dataOrderTracking}
              totalRecords={data.dataOrderTracking?.length}
              rows={data.perPage}
              scrollable
              dataKey="id"
              showGridlines
              tableStyle={{ minWidth: '12rem' }}
            >
              {headerOrderStatusChanges.map((item, index) => {
                return <Column key={index} sortable field={item.field} header={item.header} align="center" />;
              })}
            </DataTable>
          </div>
        </TabPanel>
      </TabView>
    </BaseContainer>
  );
}

export default AuditTrail;
