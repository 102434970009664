import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import React, { FC, ReactNode, SyntheticEvent } from 'react';
import { IBannerOptions, IFormValue, ISectionOptions } from '../../hooks';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';

declare interface IFormBannerDetail {
  count: number;
  optionStreet: Array<Object>;
  bannerId?: string;
  selectedStreet?: IBannerOptions | null;
  onSearchTargetSelection: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeDropdown: (key: string) => (e: DropdownChangeEvent) => void;
  handleOnChangeDate: (key: string) => (e: CalendarChangeEvent) => void;
  handleCLickDetailBanner: () => void;
  handleCLickSubStreet: () => void;
  handleCLickAddStreet: () => void;
  bannerType: string | null;
  directTo: string | null;
  directToList: Array<IBannerOptions>;
  optionDirectTo: Array<IBannerOptions> | [];
  selectedDirectTo: IBannerOptions | null;
  validFrom: Date;
  validTo: Date;
  validateDate: Boolean;
  selectedCategory: Array<IBannerOptions> | IBannerOptions;
  optionsListCategories: Array<IBannerOptions>;
  tab: number;
  formValue: IFormValue;
  handleOnChangeInput: (key: string) => (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement, Event>) => void;
  optionsListSection: Array<ISectionOptions>
  selectedSection: Array<ISectionOptions>
  getFormErrorMessage: (name: string) => ReactNode
}

export const FormBannerDetail: FC<IFormBannerDetail> = ({
  count,
  optionStreet,
  bannerId,
  selectedStreet,
  handleChangeDropdown,
  handleCLickDetailBanner,
  handleCLickSubStreet,
  handleCLickAddStreet,
  handleOnChangeDate,
  bannerType,
  directTo,
  optionDirectTo,
  selectedDirectTo,
  directToList,
  validFrom,
  validTo,
  validateDate,
  selectedCategory,
  optionsListCategories,
  tab,
  onSearchTargetSelection,
  formValue,
  handleOnChangeInput,
  optionsListSection,
  selectedSection,
  getFormErrorMessage
}) => {
  return (
    <>
      {tab === 1 && (
        <div className="flex flex-col flex-1 gap-4">
          <div className="text-base font-bold">Apply To Street :</div>
          {Array.from({ length: count }).map((_, index) => {
            return (
              <div key={index} className="flex flex-1 items-center gap-4">
                <Dropdown
                  className="border !w-1/2"
                  value={selectedStreet}
                  options={optionStreet as []}
                  onChange={handleChangeDropdown('street')}
                  disabled={Boolean(bannerId)}
                />
                {bannerType !== 'BRAND_BANNER' && (
                  <Button onClick={handleCLickDetailBanner} disabled={Boolean(selectedStreet?.code === '')} type="button">
                    Banner details
                  </Button>
                )}
                <Button color="danger" type="button" onClick={handleCLickSubStreet} disabled={Boolean(index === 0)}>
                  Delete
                </Button>
              </div>
            );
          })}
          <div>
            <Button type="button" disabled={Boolean(bannerId)} onClick={handleCLickAddStreet}>
              + Add more
            </Button>
          </div>
        </div>
      )}
      {tab === 2 && (
        <div className="w-full flex flex-col gap-4">
          <div id="apply_street_label" className="font-bold">
            Apply to Street: {selectedStreet?.label}
          </div>
          {!['PRODUCT_CATEGORY_BANNER', 'SECTION_BACKGROUND'].includes(bannerType as string) && (
            <>
              <div id="apply_to_street" className="flex items-center">
                <div id="apply_street_label" className="w-1/5">
                  Direct to
                </div>
                <div className="!w-1/2" id="dropdown_field">
                  <div className="card flex justify-content-center">
                    <Dropdown
                      value={directTo}
                      optionLabel="label"
                      className="w-full"
                      optionValue="code"
                      placeholder="Select"
                      options={directToList}
                      onChange={handleChangeDropdown('directTo')}
                      disabled={Boolean(bannerId)}
                    />
                  </div>
                </div>
              </div>
              {directTo !== '' && (
                <div id="apply_to_street" className="flex items-center">
                  <div id="apply_street_label" className="w-1/5" />
                  <div className="!w-1/2" id="dropdown_field">
                    <div className="card flex justify-content-center flex-col">
                      {
                        directTo === 'LANDING_PAGE' ?
                          <>
                            <InputText
                              value={formValue.landingPage}
                              onChange={handleOnChangeInput('landingPage')}
                              placeholder="https://aladinmall.id/......"
                              className="w-full md:w-14rem"
                              disabled={Boolean(bannerId)}
                              required
                            />
                            {getFormErrorMessage('landingPage')}
                          </> :
                          <Dropdown
                            value={selectedDirectTo}
                            className="w-full"
                            placeholder="Select"
                            filter
                            filterBy="label"
                            options={optionDirectTo}
                            onInput={onSearchTargetSelection}
                            onChange={handleChangeDropdown(
                              (directTo === 'PDP' && 'productId') ||
                                // (directTo === 'LANDING_PAGE' && 'landingPage') ||
                                (directTo === 'STORE_DETAIL' && 'merchantId') ||
                                (directTo === 'PROMO_DETAIL_PAGE' && 'promoDetailId') ||
                                ''
                            )}
                            disabled={Boolean(bannerId)}
                          />
                      }
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          <div id="apply_to_street" className="flex items-center">
            <div id="apply_street_label" className="w-1/5">
              Valid from
            </div>
            <div className="!w-1/2" id="dropdown_field">
              <div className="card flex justify-content-center">
                <Calendar
                  value={validFrom}
                  onChange={handleOnChangeDate('validFrom')}
                  showIcon
                  hideOnDateTimeSelect
                  maxDate={new Date(validTo)}
                  minDate={new Date()}
                  className="w-full"
                  required
                  inputStyle={{
                    border: validateDate ? '1px solid red' : ''
                  }}
                  disabled={Boolean(bannerId)}
                />
              </div>
            </div>
          </div>
          <div id="apply_to_street" className="flex items-center">
            <div id="apply_street_label" className="w-1/5">
              Valid to
            </div>
            <div className="!w-1/2" id="dropdown_field">
              <div className="card flex justify-content-center">
                <Calendar
                  value={validTo}
                  onChange={handleOnChangeDate('validTo')}
                  showIcon
                  hideOnDateTimeSelect
                  minDate={new Date(validFrom)}
                  required
                  className="w-full"
                  inputStyle={{
                    border: validateDate ? '1px solid red' : ''
                  }}
                  disabled={Boolean(bannerId) || !Boolean(validFrom.getDate())}
                />
              </div>
              {validateDate && <span className="text-red-500 text-xs">End time must greater than start time</span>}
            </div>
          </div>
          {Boolean(bannerType === 'PRODUCT_CATEGORY_BANNER') && (
            <div id="apply_to_street" className="flex items-center">
              <div id="apply_street_label" className="w-1/5">
                Apply to Product Category
              </div>
              <div className="!w-1/2" id="dropdown_field">
                <div className="card flex justify-content-center">
                  {Array.isArray(selectedCategory) ? (
                    <MultiSelect
                      className="w-full"
                      placeholder="Select"
                      value={selectedCategory}
                      filter
                      filterBy="label,code"
                      options={optionsListCategories as []}
                      onChange={handleChangeDropdown('categoryId')}
                      disabled={Boolean(bannerId)}
                    />
                  ) : (
                    <Dropdown
                      className="w-full"
                      placeholder="Select"
                      value={selectedCategory}
                      filter
                      filterBy="label,code"
                      options={optionsListCategories as []}
                      onChange={handleChangeDropdown('categoryId')}
                      disabled={Boolean(bannerId)}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          {Boolean(bannerType === 'SECTION_BACKGROUND') && (
            <div id="apply_to_street" className="flex items-center">
              <div id="apply_street_label" className="w-1/5">
                Apply to Section
              </div>
              <div className="!w-1/2" id="dropdown_field">
                <div className="card flex justify-content-center">
                  <MultiSelect
                    className="w-full"
                    placeholder="Select"
                    value={selectedSection}
                    filterBy="label,code"
                    options={optionsListSection as []}
                    onChange={handleChangeDropdown('sectionType')}
                    disabled={Boolean(bannerId)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
