import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import {
  Json, ProductTabulation, useCreateProductTabulation, useDeleteProductTabulationById, useDeleteSectionStreet,
  useGetChildLayout,
  useGetParentLayout, useGetProductTabulationByLayoutId, useUpdateChildLayout,
  useUpdateParentLayout
} from '@/services/rest/pageBuilder';
import { toTitleCase } from '@/utils';
import { storageUpload } from '@/utils/storage';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { ColorPickerHSBType, ColorPickerRGBType } from 'primereact/colorpicker';
import { Toast } from 'primereact/toast';
import { Nullable } from 'primereact/ts-helpers';
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';


interface IStreetItem {
  id: number;
  title: string
  isDisabled: boolean;
  sectionValue: string;
  attribute?: Json
  slot: string;
  images?: Json;
  isMobile: boolean
  color?: Nullable<string | ColorPickerRGBType | ColorPickerHSBType>
  marginTop?: number;
  marginBottom?: number
  parentId?: number;
  display?: string;
  newSection?: boolean
}

interface IAttribute {
  display: string;
  marginTop: number;
  marginBottom: number;
}

const initialStateAttributeStyle = {
  display: '',
  marginTop: 0,
  marginBottom: 0
};


export const useCustom = () => {
  const params = useParams();
  const navigation = useNavigate();
  const toast = useRef<Toast>(null);

  const [title, setTitle] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<string>('');
  const [iconName, setIconName] = useState<string>('');
  const [activeIndex, setActiveIndex] = useState<number>();
  const [sectionText, setSectionText] = useState<string>('');
  const [streetItems, setStreetItems] = useState<IStreetItem[]>([]);
  const [selectedSection, setSelectedSection] = useState<null | Record<string, string>>(null);
  const [color, setColor] = useState<Nullable<string | ColorPickerRGBType | ColorPickerHSBType>>(null);
  const [selectedTileType, setSelectedTileType] = useState<null | Record<string, string>>({ name: 'Icon', code: 'ICON' });
  const [attributeStyle, setAttributeStyle] = useState<IAttribute>(initialStateAttributeStyle);
  const [productTabulationItems, setProductTabulationItems] = useState<{ id: number; title: string, isSelected: boolean }[]>([]);

  // get detail parent layout
  const { data: dataDetailParentLayout, isLoading: isLoadingParentlayout, refetch: refetchParentLayout } = useGetParentLayout(Number(params?.id));

  // get product tabulation
  const { data: dataProductTabulation } = useGetProductTabulationByLayoutId(Number(params?.id));

  // delete product tabulation
  const { mutate: mutateDeleteTabulation } = useDeleteProductTabulationById();

  // create product tabulation
  const { mutate: createProductTabulation } = useCreateProductTabulation();


  // product tabulation exist
  const dataListProductTabulationIds = useMemo(() => {
    return Array.isArray(dataProductTabulation?.data) ?
      dataProductTabulation?.data.map((it) => it.id) :
      [];
  }, [dataProductTabulation?.data]);

  const detailParentLayout = useMemo(() => {
    if (dataDetailParentLayout?.data) {
      return {
        id: dataDetailParentLayout?.data?.id || 0
      };
    }
  }, [dataDetailParentLayout, refetchParentLayout, isLoadingParentlayout]);

  // get child layout
  const { data: dataDetailChildLayout, isLoading: isLoadingChildLayout, refetch: refetchChildLayout } = useGetChildLayout(Number(detailParentLayout?.id));


  // update parent layout
  const { mutate: updateParentLayout, isLoading: isLoadingUpdateParent } = useUpdateParentLayout(refetchParentLayout);

  // udpate child layout
  const { mutate: updateChildLayout, isLoading: isLoadingUpdateChild } = useUpdateChildLayout(refetchChildLayout);

  const refetchDetail = useCallback(() => {
    refetchParentLayout();
    refetchChildLayout();
  }, []);

  // delete section street
  const { mutate: deleteSectionStreet, isLoading: isLoadingDeleteSection } = useDeleteSectionStreet(refetchDetail);

  const sections = useMemo(() => {
    return [
      {
        name: 'Street',
        code: 'street',
        type: 'street'
      },
      {
        name: 'Banner',
        code: 'mainBanner',
        subSection: [
          { name: 'Main Banner', code: 'mainBanner', type: 'banner' },
          { name: 'Brand Banner', code: 'brandBanner', type: 'banner' },
          { name: 'Ads Banner Slider', code: 'adsBannerSlider', type: 'banner' },
          { name: 'Promo Banner Ads 1', code: 'promoBannerAds1', type: 'banner' }
        ]
      },
      {
        name: 'Product Tabulation',
        code: 'productTabulation',
        type: 'productTabulation'
      },
      {
        name: 'Product Pilihan',
        code: 'curatedProduct'
      },
      {
        name: 'Video',
        code: 'video'
      },
      {
        name: 'Article',
        code: 'article'
      },
      {
        name: 'Flash Sale',
        code: 'flashSale'
      },
      {
        name: 'Official Store',
        code: 'officialStore'
      },
      {
        name: 'Hot Deals',
        code: 'hotDeals'
      },
      {
        name: 'Popular Brand',
        code: 'popularBrand'
      }
    ];
  }, []);

  // reset select section
  const handleResetSelectSection = useCallback(() => {
    setSelectedSection(null);
  }, []);

  // reset attribute style
  const handleResetAttributeStyle = useCallback(() => {
    setAttributeStyle(initialStateAttributeStyle);
  }, []);

  // reset sectionText
  const handleResetSectionText = useCallback(() => {
    setSectionText('');
  }, []);


  const tileType = [
    { name: 'Icon', code: 'ICON' },
    { name: 'Text', code: 'TEXT' }
  ];

  // toast
  const showSuccess = (msg: string) => {
    if (toast.current != null) {
      toast?.current?.show({
        severity: 'success',
        summary: `${msg}`,
        detail: `Page Builder is ${msg}`,
        life: 1000
      });
      navigation('/frontstore-management/page-builder');
    }
  };

  const showFailed = (msg: string) => {
    if (toast.current != null) {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `Failed to Create because ${msg}`,
        life: 1500
      });
    }
  };


  const onChangeImage = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (event?.currentTarget?.files) {
        const dataImage = event?.currentTarget?.files[0];
        const fileName = dataImage.name;
        const extension = fileName.split('.').pop();
        if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
          // upload image to bucket
          const responsUploadPhoto = await storageUpload('web/', dataImage);

          if (responsUploadPhoto) {
            setImageUrl(responsUploadPhoto.src);
            setIconName(responsUploadPhoto.label);
          }
        }
      }
    },
    []
  );

  const addSection = useCallback(() => {
    if (selectedSection?.type === 'street') {
      setStreetItems(
        (prevState) => [
          ...prevState,
          {
            id: (prevState[prevState.length - 1]?.id || 0) + 1,
            slot: 'street',
            title: 'Street',
            isDisabled: false,
            isMobile: false,
            sectionValue: title,
            color: color,
            marginTop: Number(attributeStyle.marginTop),
            marginBottom: Number(attributeStyle.marginBottom),
            newSection: true
          }
        ]
      );
    } else {
      setStreetItems((prevState) => [
        ...prevState,
        {
          id: (prevState[prevState.length - 1]?.id || 0) + 1,
          title: sectionText,
          isDisabled: false,
          slot: selectedSection?.code || '',
          sectionValue: '',
          isMobile: false,
          images: '',
          marginTop: Number(attributeStyle.marginTop),
          marginBottom: Number(attributeStyle.marginBottom),
          parentId: streetItems[0].id,
          newSection: true
        }
      ]);
    }
    handleResetSectionText();
    handleResetSelectSection();
    handleResetAttributeStyle();
  }, [setStreetItems, sectionText, selectedSection, title, imageUrl, color]);

  const deleteSection = useCallback(
    (index: number, id:number) => () => {
      setProductTabulationItems([]);
      setSelectedSection(null);
      setStreetItems([]);
      deleteSectionStreet(id);
      setStreetItems((prevState) => prevState.filter((_, i) => i !== index));
    },
    [streetItems, setStreetItems]
  );


  const addProductTabulation = useCallback(() => {
    setProductTabulationItems(
      (prevState) => [
        ...prevState,
        {
          id: (prevState[prevState.length - 1]?.id || 0) + 1,
          title: '',
          isSelected: false
        }
      ]
    );
  }, [setProductTabulationItems]);

  const deleteProductTabulation = useCallback(
    (index: number) => (event: SyntheticEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setProductTabulationItems((prevState) => prevState.filter((_, i) => i !== index));
    },
    [productTabulationItems, setProductTabulationItems]
  );

  const selectProductTabulation = useCallback(
    (id: number) => (event: SyntheticEvent<HTMLDivElement>) => {
      event.stopPropagation();
      setProductTabulationItems(
        (prevState) =>
          prevState.map(
            (item) => (item.id === id) ? ({ ...item, isSelected: !item.isSelected }) : ({ ...item, isSelected: false })
          )
      );
    },
    [productTabulationItems, setProductTabulationItems]
  );

  const changeProductTabulation = useCallback(
    (id: number, value = '') => {
      setProductTabulationItems(
        (prevState) =>
          prevState.map(
            (item) => (item.id === id) ? ({ ...item, title: value }) : item
          )
      );
    },
    [productTabulationItems, setProductTabulationItems]
  );

  const dragItem = useRef<number | null>(null);
  const dragOverItem = useRef<number | null>(null);
  const handleSort = useCallback(() => {
    if (dragItem.current !== null && dragOverItem.current !== null) {
      const newItems = [...streetItems];
      const draggedItem = newItems[dragItem.current];
      newItems.splice(dragItem.current ?? -1, 1);
      newItems.splice(dragOverItem.current ?? -1, 0, draggedItem);
      setStreetItems(newItems);
    }
  }, [dragItem, dragOverItem, streetItems]);

  const handleDragStart = useCallback(
    (index: number) => {
      dragItem.current = index;
    },
    [dragItem]
  );

  const handleDragEnter = useCallback(
    (index: number) => {
      dragOverItem.current = index;
    },
    [dragOverItem]
  );

  const handleClick = useCallback(
    (index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const handleBack = useCallback(() => {
    navigation(-1);
  }, []);

  const getIndexSelectedSection = useCallback((slot: string) => {
    return streetItems.findIndex((item) => item.slot === slot);
  }, [streetItems]);


  // update page builder
  const handleSubmitPageBuilder = useCallback( async () => {
    // delete product tabulation id
    await mutateDeleteTabulation(dataListProductTabulationIds as []);

    // insert product tabulation
    if (productTabulationItems.length >= 1) {
      const payload = productTabulationItems.map((it) => {
        return {
          layout_id: params?.id,
          name: it.title
        };
      });

      createProductTabulation(payload as []);
    }

    const streetParent = streetItems?.find((item) => item.slot === 'street');
    const attributeParent = {
      color: streetParent?.color || '',
      margin_top: Number(streetParent?.marginTop) || Number(0),
      margin_bottom: Number(streetParent?.marginBottom) || Number(0),
      tab_name: productTabulationItems.map((item) => item.title) || null,
      image: imageUrl ?? null,
      display: streetParent?.display || ''
    };
    const payloadParent = {
      images: null,
      platform: streetParent?.isMobile ? 'MOBILE' : 'WEB',
      id: Number(streetParent?.id),
      attribute: attributeParent,
      slot: streetParent?.slot || '',
      sequence: getIndexSelectedSection(streetParent?.slot || ''),
      section_name: title ? title : streetParent?.sectionValue
    };

    // upddate parent layout
    await updateParentLayout(payloadParent);

    const filterStreet = streetItems?.filter((item) => item.slot !== 'street' && !item.newSection);
    const payloadLayout = filterStreet?.map((item) => {
      const attributeProductTabulation = {
        color: item?.color,
        margin_top: Number(item?.marginTop) || Number(0),
        margin_bottom: Number(item?.marginBottom) || Number(0),
        tab_name: productTabulationItems.map((item) => item.title),
        image: imageUrl ?? null,
        display: item.display || ''
      };
      return {
        id: item.id,
        slot: item.slot,
        attribute: attributeProductTabulation,
        parent_id: Number(item.parentId),
        section_name: item.sectionValue,
        platform: item.isMobile ? 'MOBILE' : 'WEB',
        sequence: getIndexSelectedSection(item.slot)
      };
    });
    updateChildLayout(payloadLayout);

    const filterNewSection = streetItems?.filter((item) => item.slot !== 'street' && item.newSection);
    if (filterNewSection.length > 0) {
      const payloadNewSection = filterNewSection?.map((item) => {
        const attributeProductTabulation = {
          color: streetParent?.color,
          margin_top: Number(item.marginTop),
          margin_bottom: Number(item.marginBottom),
          tab_name: productTabulationItems.map((item) => item.title),
          image: imageUrl ?? null,
          display: item.display
        };
        return {
          slot: item.slot,
          attribute: attributeProductTabulation,
          parent_id: item.parentId,
          section_name: item.sectionValue,
          platform: item.isMobile ? 'MOBILE' : 'WEB',
          sequence: getIndexSelectedSection(item.slot)
        };
      });

      // post new section
      const { error: errorUpdate } = await supabase
        .from('user.layouts')
        .insert(payloadNewSection);
      if (errorUpdate) {
        showFailed(errorUpdate.message);
      }
    }
    setProductTabulationItems([]);
    setSelectedSection(null);
    setStreetItems([]);
    showSuccess('Edited');
  }, [streetItems, imageUrl, productTabulationItems, params, dataListProductTabulationIds]);


  // handle set new section
  const handleSetMargin = useCallback((type: string, value: string) => {
    switch (type) {
    case 'marginTop':
      setAttributeStyle((prev) => ({
        ...prev,
        marginTop: Number(value)
      }));
      return;
    case 'marginBottom':
      setAttributeStyle((prev) => ({
        ...prev,
        marginBottom: Number(value)
      }));
      return;
    case 'display':
      setAttributeStyle((prev) => ({
        ...prev,
        display: value
      }));

    default:
    }
  }, []);

  // set attribute style
  const handleSetAttributeStyle = useCallback((type: string, index:number) => (e: ChangeEvent<HTMLInputElement>) => {
    if (index) {
      const tempStreet = streetItems;
      switch (type) {
      case 'marginTop':
        tempStreet[index].marginTop = Number(e.target.value);
        setStreetItems(tempStreet);
        setAttributeStyle((prev) => ({
          ...prev,
          marginTop: Number(e.target.value)
        }));
        return;
      case 'marginBottom':
        tempStreet[index].marginBottom = Number(e.target.value);
        setStreetItems(tempStreet);
        setAttributeStyle((prev) => ({
          ...prev,
          marginBottom: Number(e.target.value)
        }));
        return;
      case 'display':
        tempStreet[index].marginBottom = Number(e.target.value);
        setStreetItems(tempStreet);
        setAttributeStyle((prev) => ({
          ...prev,
          display: e.target.value
        }));
      default:
      }
    } else {
      handleSetMargin(type, e.target.value);
    }
  }, [streetItems]);

  // check platform
  const handleCheckPlatform = useCallback((id: number, value: boolean) => {
    const updateStreet = streetItems.map((item) => item.id === id ? {
      ...item,
      isMobile: value
    } : item);
    setStreetItems(updateStreet);
  }, [streetItems, setStreetItems]);

  // check title type
  const handleCheckTitleType = useCallback((item: IStreetItem) => {
    if (item.sectionValue) {
      setSelectedTileType({ name: 'Text', code: 'TEXT' });
    } else {
      setSelectedTileType({ name: 'Icon', code: 'ICON' });
    }
  }, []);

  // handle click edit
  const handleClickEditSection = useCallback((item: IStreetItem) => {
    const filteredSection = sections.find((i) => i.code == item.slot);
    setSelectedSection(filteredSection as {});
    setAttributeStyle({
      marginTop: Number(item.marginTop) || Number(0),
      marginBottom: Number(item.marginBottom) || Number(0),
      display: item.display || ''
    });
    setColor(item.color);
    setSectionText(item.title);
    if (item.slot == 'street') {
      setTitle(item.sectionValue);
    }
    handleCheckTitleType(item);
  }, [sections]);

  const handleSetStreetName = useCallback((value: string) => {
    const updateStreeName = streetItems.map((item) => item.slot === 'street' ? {
      ...item,
      sectionValue: value
    } : item);
    setStreetItems(updateStreeName);
    setTitle(value);
  }, [streetItems]);

  useEffect(() => {
    if (dataDetailParentLayout?.data && dataDetailChildLayout?.data) {
      const attr = JSON.parse(JSON.stringify(dataDetailParentLayout?.data?.attribute));
      setStreetItems(
        [
          {
            id: dataDetailParentLayout?.data?.id || 0,
            slot: dataDetailParentLayout?.data?.slot || '',
            title: dataDetailParentLayout.data.section_name || toTitleCase(dataDetailParentLayout?.data?.slot || '') || '',
            isDisabled: false,
            isMobile: dataDetailParentLayout.data.platform !== 'WEB',
            sectionValue: dataDetailParentLayout?.data?.section_name || '',
            color: attr?.color || '',
            marginTop: Number(attr?.margin_top),
            marginBottom: Number(attr?.margin_bottom),
            display: attr?.display
          }
        ]
      );
      setImageUrl(attr?.image);
    }
  }, [dataDetailChildLayout?.data]);

  useEffect(() => {
    if (dataDetailChildLayout?.data && dataDetailParentLayout?.data && Array.isArray(dataDetailChildLayout?.data)) {
      dataDetailChildLayout?.data?.forEach((item) => {
        const attr = JSON.parse(JSON.stringify(item.attribute));
        setStreetItems(
          (prevState) => [
            ...prevState,
            {
              id: item.id || 0,
              slot: item.slot || '',
              title: item.section_name || toTitleCase(item?.slot || '') || '',
              isDisabled: false,
              isMobile: item.platform !== 'WEB',
              sectionValue: item.section_name || '',
              color: '',
              marginTop: Number(attr?.margin_top),
              marginBottom: Number(attr?.margin_bottom),
              parentId: item.parent_id || 0,
              display: attr?.display
            }
          ]
        );
      });
    }
  }, [dataDetailChildLayout?.data]);

  // set product tabulation list
  const getProductTabulations = useCallback( async (id: number) => {
    const { data } = await supabase
      .from(TABLE.TABULATION)
      .select('*')
      .eq('layout_id', id) as PostgrestSingleResponse<ProductTabulation[]>;

    const listProducttabulations = data?.map((i, idx) => {
      return {
        id: idx + 1,
        title: i.name || '',
        isSelected: false
      };
    });

    setProductTabulationItems(listProducttabulations as []);
  }, []);

  useEffect(() => {
    if (params?.id) {
      getProductTabulations(Number(params?.id));
    }
  }, [params?.id]);


  return {
    data: {
      title,
      color,
      toast,
      imageUrl,
      tileType,
      iconName,
      sections,
      sectionText,
      streetItems,
      activeIndex,
      attributeStyle,
      selectedSection,
      selectedTileType,
      isLoadingChildLayout,
      isLoadingUpdateChild,
      isLoadingUpdateParent,
      isLoadingParentlayout,
      isLoadingDeleteSection,
      productTabulationItems
    },
    methods: {
      setTitle,
      setColor,
      handleSort,
      handleBack,
      addSection,
      handleClick,
      onChangeImage,
      deleteSection,
      setSectionText,
      handleDragEnter,
      handleDragStart,
      setSelectedSection,
      handleSetStreetName,
      handleCheckPlatform,
      setSelectedTileType,
      addProductTabulation,
      handleClickEditSection,
      handleResetSectionText,
      handleSetAttributeStyle,
      handleSubmitPageBuilder,
      selectProductTabulation,
      changeProductTabulation,
      deleteProductTabulation,
      handleResetSelectSection,
      handleResetAttributeStyle
    }
  };
};

export default useCustom;
