import LoadingOverlay from '@/components/base/LoadingOverlay';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { FC } from 'react';

type PropsModalEdit = {
    onCloseCreateRole: () => void;
    id?: number
    roleName?: string
    description?: string
    onChangeData: (e) => void;
    handleSubmit: () => void
    loading
}

export const CreateRoleSetting: FC<PropsModalEdit> = ({ onCloseCreateRole, id, roleName, description, onChangeData, handleSubmit, loading }) => {
  const handleClose = () => {
    onCloseCreateRole();
  };

  const handleChange = (e) => {
    onChangeData(e);
  };

  const onSubmitEditData = () => {
    handleSubmit();
  };
  return (
    <div id="user-popup-create-permission" className="bg-black bg-opacity-30 w-screen h-screen fixed top-0 left-0 z-50 flex justify-center items-center">
      <div className="bg-white relative p-6 z-50 w-[411px] rounded-lg h-[515px]">
        <h1 className="font-bold text-xl">Add Role Setting</h1>
        <div className="py-4">
          <p className="my-2 text-xs font-medium">Role Setting ID</p>
          <LoadingOverlay show={loading} />
          <InputText
            id="user-input-id"
            name="id" disabled
            type="number"
            value={String(id)}
            className="w-full"
            onChange={(e) => handleChange(e)} />
        </div>
        <div className="">
          <p className="py-2 text-xs font-medium">Role Setting Name*</p>
          <InputText
            id="user-input-name"
            name="name"
            className="w-full"
            value={roleName}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="py-4">
          <p className="my-2 text-xs font-medium">Description</p>
          <InputTextarea
            id="user-input-description"
            name="description" rows={4} cols={20}
            className="w-full"
            value={description}
            onChange={(e) => handleChange(e)} />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Button
            id="user-cancel-button"
            outlined
            label="Cancel"
            onClick={() => handleClose()}/>
          <Button
            id="user-create-button"
            outlined
            label="Add"
            onClick={() => onSubmitEditData()}/>
        </div>
      </div>
    </div>
  );
};

export default CreateRoleSetting;
