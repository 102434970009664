import dayjs from 'dayjs';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import ModalAddProduct from '@/section/FlashSaleManagement/AddProduct/ModalAddProduct';
import ProductTable from '@/section/FlashSaleManagement/Table';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import { useCreateFlashSale } from './hooks';
import { optionPeriode, optionStatus } from '../helpers';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

import type { ChangeEvent, KeyboardEvent } from 'react';
import type { ITableDataProducts } from '@/views/FlashSaleManagement/Create/hooks';
import type { InputNumberValueChangeEvent } from 'primereact/inputnumber';

import './index.css';

export type NullableNumber = number | null

export interface IMassUpdateField {
  priceBy: string
  priceValue: NullableNumber
  stock: NullableNumber
}

export const defaultValueFormMassUpdate = {
  priceBy: '',
  priceValue: null,
  stock: null
};

export const priceByOptions = ['Percentage', 'Amount'];

const CreateFlashSale = () => {
  const {
    data: {
      fileUploaded,
      fileUpdateUploaded,
      tableData,
      formCreateFlashSale,
      menuFlashSaleInformation,
      dataMenuFlashSale,
      optionSlot,
      time,
      toast,
      isOpenDropdownSlot,
      slotName,
      formik,
      isLoading,
      isSubmit
    },
    methods: {
      handleActiveMenu,
      handleActiveColorMenu,
      onChangeFormCreateFlashSale,
      addMultipleDate,
      addToMultipleDate,
      deleteMultipleDate,
      handleSelectFile,
      handleSelectFileUpdate,
      handleClickDropdownSlot,
      handleMouseEnter,
      handleClickDropdownType,
      handleDownloadTemplate,
      isFormFieldValid,
      changeInputText,
      setProductUpload
    }
  } = useCreateFlashSale();
  const navigate = useNavigate();

  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  const [products, setProducts] = useState<ITableDataProducts[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<ITableDataProducts[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<ITableDataProducts[] | null>(null);
  const [formMassUpdate, setFormMassUpdate] = useState<IMassUpdateField>(
    { ...defaultValueFormMassUpdate }
  );
  const [filter, setFilter] = useState('');
  const [isShowModalAddProduct, setIsShowModalAddProduct] = useState(false);
  const [isSubmitAddProduct, setIsSubmitAddProduct] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);

  const onRowEditComplete = useCallback((newData: ITableDataProducts, index: number, newSortValue?: string) => {
    setFilteredProducts((_products) => {
      if (_products) {
        const newProducts = [..._products as ITableDataProducts[]];
        newProducts[index] = newData;
        newProducts[index]['Sort Value'] = newSortValue ?? '';
        newProducts[index]['Periode'] = !!newData['Periode'] ?
          dayjs(newData['Periode']).format('MM-DD-YYYY') :
          '';
        return newProducts;
      }
      return _products;
    });
    setProducts((_products) => {
      const newProducts = [..._products];
      const rowIndex = newProducts.findIndex((product) => product.index === newData.index);

      if (rowIndex !== -1) {
        newProducts[rowIndex] = newData;
        newProducts[rowIndex]['Sort Value'] = newSortValue ?? '';
        newProducts[rowIndex]['Periode'] = !!newData['Periode'] ?
          dayjs(newData['Periode']).format('MM-DD-YYYY') :
          '';
      }

      return newProducts;
    });
  }, [setProducts, setFilteredProducts, filteredProducts]);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const onFilter = () => {
    setFilteredProducts(() => {
      return products.filter((product) => {
        const productVariantId = product['Product Variant ID'].toLowerCase();
        const productId = product['Product ID']?.toLowerCase();
        const productName = product['Product Name'].toLowerCase();
        const query = filter.toLowerCase();

        return productVariantId.includes(query) ||
          productId?.includes(query) ||
          productName.includes(query);
      });
    });
  };

  const disabledMassUpdateButton = useMemo(() => {
    const isEmptyData = selectedProducts.length === 0;
    const isEmptyForm = JSON.stringify(formMassUpdate) === JSON.stringify(defaultValueFormMassUpdate);

    return isEmptyData || isEmptyForm;
  }, [formMassUpdate, selectedProducts]);

  const resetFormMassUpdate = () => {
    setFormMassUpdate({ ...defaultValueFormMassUpdate });
  };

  const onInputPriceBy = useCallback((e: DropdownChangeEvent) => {
    setFormMassUpdate((c) => ({
      ...c,
      priceBy: e.value
    }));
  }, []);

  const onInputPriceValue = useCallback((e: InputNumberValueChangeEvent) => {
    setFormMassUpdate((c) => ({
      ...c,
      priceValue: e.value as NullableNumber
    }));
  }, []);

  const onInputStock = useCallback((e: InputNumberValueChangeEvent) => {
    setFormMassUpdate((c) => ({
      ...c,
      stock: e.value as NullableNumber
    }));
  }, []);

  const selectedProductsIndex = useMemo(() => {
    return selectedProducts.map((item) => {
      const index = products.findIndex(
        (product) => product?.['Product Variant ID'] === item?.['Product Variant ID']
      );
      return index;
    });
  }, [selectedProducts]);

  const onMassUpdate = useCallback(() => {
    const _products = products.slice();

    for (const index of selectedProductsIndex) {
      if (!!formMassUpdate.priceBy) {
        _products[index]['Flash Sale Price By'] = formMassUpdate.priceBy;
      }
      if (!!formMassUpdate.priceValue) {
        _products[index]['Flash Sale Price Value'] = `${formMassUpdate.priceValue}`;
      }
      if (!!formMassUpdate.stock) {
        _products[index]['Jumlah Stock Flash Sale'] = formMassUpdate.stock;
      }
    }

    setSelectedProducts([]);
    resetFormMassUpdate();
    setProducts(_products);
  }, [products, formMassUpdate, selectedProductsIndex]);

  const toggleModalProduct = useCallback(() => {
    setIsSubmitAddProduct(false);
    setIsShowModalAddProduct((c) => (!c));
  }, [isShowModalAddProduct]);

  const cancelAddProduct = useCallback(() => {
    toggleModalProduct();
  }, []);

  const addProduct = useCallback(() => {
    setIsSubmitAddProduct(true);
  }, []);

  const onFinishedAddProduct = (addedProducts: ITableDataProducts[]) => {
    toggleModalProduct();
    const _addedProducts = addedProducts.map((item) => {
      const elmt = item;
      const date = dayjs(item['Periode']).format('MM-DD-YYYY');
      elmt['Periode'] = date;
      return elmt;
    });
    setProducts((c) => {
      const newProducts = [...c, ..._addedProducts].map((item, index) => ({ ...item, index }));
      return newProducts;
    });
  };

  const onDelete = (index: number) => {
    setFilteredProducts((currentFilteredProducts) => {
      if (currentFilteredProducts && currentFilteredProducts.length > 0) {
        const newFilteredProducts = currentFilteredProducts.filter((item) => item.index !== index);
        return newFilteredProducts;
      }
      return currentFilteredProducts;
    });
    setProducts((currentProducts) => currentProducts.filter((item) => item.index !== index));
  };

  const minDate = useMemo(() => {
    if (formCreateFlashSale.periode.code === 'range' && formCreateFlashSale.valid_from) {
      return new Date(formCreateFlashSale.valid_from);
    }
    return undefined;
  }, [formCreateFlashSale.periode.code, formCreateFlashSale.valid_from]);

  const maxDate = useMemo(() => {
    if (formCreateFlashSale.periode.code === 'range' && formCreateFlashSale.valid_to) {
      return new Date(formCreateFlashSale.valid_to);
    }
    return undefined;
  }, [formCreateFlashSale.periode.code, formCreateFlashSale.valid_to]);

  const headerDialog = useMemo(() => (
    <div id="admin-create-header" className="font-bold">Tambah Produk</div>
  ), []);

  const footerDialog = useMemo(() => (
    <div id="admin-create-footer" className="-mt-6 -mr-10">
      <SubmitButton
        onCancel={cancelAddProduct}
        onSubmit={addProduct}
        labelBack="Cancel"
        labelSubmit="Submit"
        disabledSubmit={isDisabledButton}
      />
    </div>
  ), [isDisabledButton]);

  const onType = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') onFilter();
  };

  useEffect(() => {
    setProducts(tableData.slice().map((item, index) => ({ ...item, index })));
  }, [tableData]);

  useEffect(() => {
    setProductUpload(products.slice());
  }, [products]);

  useEffect(() => {
    if (!filter) setFilteredProducts(null);
  }, [filter]);

  return (
    <div className="px-5 h-screen">
      <LoadingOverlay show={isSubmit || isLoading} loadingText={isSubmit ? 'Processing...' : undefined}/>
      <Dialog
        header={headerDialog}
        footer={footerDialog}
        draggable={false}
        visible={isShowModalAddProduct}
        className="w-[95vw]"
        onHide={cancelAddProduct}
      >
        <ModalAddProduct
          isSubmit={isSubmitAddProduct}
          flashSaleSlot={time}
          onFinishedAddProduct={onFinishedAddProduct}
          onInvalid={setIsDisabledButton}
          currentProducts={products}
          onOverwriteSequence={onRowEditComplete}
          minDate={minDate}
          maxDate={maxDate}
        />
      </Dialog>
      <Toast ref={toast} />
      {/* Header */}
      <div className="flex justify-between items-center w-full h-[100px]">
        <MainTitle isWithButton={false} title="Create Flash Sale" />
      </div>
      {/* End Header */}
      {/* Content */}
      <div className="w-full flex flex-col border-black border p-3 box-border">
        <div className="flex h-full my-3">
          <div className="w-[260px]">
            {/* Flash Sale Menu */}
            <div className="">
              <h1 className="px-3 flex items-center font-bold text-base w-full h-[50px] border border-r-0 border-black bg-gray-300">
                {menuFlashSaleInformation.headerMenu}
              </h1>
              {menuFlashSaleInformation.listMenu.map((menu, idx) => {
                return (
                  <button
                    key={idx}
                    type="button"
                    className={`px-3 h-[50px] border border-r-0 border-t-0 border-black w-full flex-col flex justify-center focus:outline-none ${handleActiveColorMenu(
                      idx
                    )}`}
                    onClick={handleActiveMenu(idx, menu.label)}
                  >
                    {menu.label}
                  </button>
                );
              })}
            </div>
            {/* End Flash Sale Menu */}
          </div>
          <div className="w-[calc(100vw-16rem-5rem-1.5rem-260px)] min-h-[50vh] relative p-6 border border-black">
            {dataMenuFlashSale === 'FLASH SALE DETAIL' ? (
              // Flash Sale Detail
              <div>
                <div className="flex flex-col gap-y-3">
                  <div className="grid grid-cols-3">
                    <div>Flash Sale Name*</div>
                    <div className="flex flex-col">
                      <InputText
                        required
                        name="name"
                        value={formCreateFlashSale.name}
                        onChange={changeInputText('name')}
                      />
                      <div className="pt-1 pl-1">{getFormErrorMessage('name')}</div>
                    </div>
                  </div>
                  <div className="grid grid-cols-3">
                    <div>Description*</div>
                    <div className="flex flex-col">
                      <InputText
                        required
                        name="desription"
                        value={formCreateFlashSale.description}
                        onChange={changeInputText('description')}
                      />
                      <div className="pt-1 pl-1">{getFormErrorMessage('description')}</div>
                    </div>
                  </div>
                  <div className="grid grid-cols-3 relative">
                    <div>Select Slot Type*</div>
                    <div className="flex flex-col relative">
                      <button type="button" className="!w-full bg-white p-3 flex items-center justify-between border border-gray-300 rounded-md text-gray-500" onClick={handleClickDropdownSlot} >{formCreateFlashSale.master_slot_id.name || 'Select Type'} <ChevronDownIcon width={15} /></button>
                      {
                        isOpenDropdownSlot && (
                          <div className="absolute left-0 top-full z-10 flex rounded-md overflow-hidden">
                            <div className="bg-white border max-h-[360px] overflow-y-scroll no-scrollbar overflow-hidden" >
                              {optionSlot?.map((option, index) => {
                                return <option
                                  onClick={handleClickDropdownType(option.name, String(option.code))}
                                  className="p-4 hover:bg-slate-200 cursor-pointer"
                                  key={index}
                                  value={String(option.code)}
                                  onMouseEnter={handleMouseEnter(option.name)}>{option.name}</option>;
                              })}
                            </div>
                            <div className="flex-1 w-96 bg-gray-50 flex flex-col items-start px-6 py-4">
                              <div className="font-bold mb-4">
                                {slotName} Detail
                              </div>
                              {Array.isArray(time) ? time.map((i, idx) => {
                                return (
                                  <div key={idx}>Slot {idx + 1} : {i.started_time} : {i.ended_time}</div>
                                );
                              }) : null}
                            </div>
                          </div>
                        )
                      }
                      <div className="pt-1 pl-1">{getFormErrorMessage('slot_type')}</div>
                    </div>
                  </div>
                  <div className="grid grid-cols-3">
                    <div>Periode*</div>
                    <div className="flex flex-col">
                      <Dropdown
                        value={formCreateFlashSale.periode}
                        onChange={(e: DropdownChangeEvent) => {
                          formik.setFieldValue('periode', e.value?.code);
                          onChangeFormCreateFlashSale('periode', e.value);
                        }}
                        options={optionPeriode}
                        optionLabel="name"
                        placeholder="Select Periode"
                        className="w-full md:w-14rem"
                      />
                      <div className="pt-1 pl-1">{getFormErrorMessage('periode')}</div>
                    </div>
                  </div>

                  {/* display period input based on selected period */}
                  {formCreateFlashSale.periode.code === 'multiple' ? (
                    // Multiple Date
                    <div className="flex flex-col gap-y-3">
                      {formCreateFlashSale.multiple.map((_, index) => {
                        return (
                          <div key={index} className="grid grid-cols-3">
                            <div />
                            <div className="flex flex-col">
                              <div className="flex">
                                <Calendar
                                  value={String(formCreateFlashSale.multiple[index])}
                                  placeholder={String(formCreateFlashSale.multiple[index])}
                                  onChange={(e: CalendarChangeEvent) => {
                                    formik.setFieldValue('valid_from', e.value);
                                    formik.setFieldValue('valid_to', e.value);
                                    addToMultipleDate(index, e.target.value);
                                  }}
                                  dateFormat="yy-mm-dd"
                                  minDate={new Date()}
                                  maxDate={new Date(formCreateFlashSale.multiple[index+1])}
                                  hideOnDateTimeSelect
                                  readOnlyInput
                                />
                                <Button label="Delete" severity="danger" onClick={deleteMultipleDate(index)} />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="grid grid-cols-3">
                        <div />
                        <div className="flex flex-col">
                          <Button label="+ Add More" severity="danger" onClick={addMultipleDate} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    // End Multiple Date
                    // Range
                    <div className="flex flex-col gap-y-3">
                      <div className="grid grid-cols-3">
                        <div className="ml-2">Valid From*</div>
                        <div className="flex flex-col">
                          <Calendar
                            value={formCreateFlashSale.valid_from}
                            placeholder={formCreateFlashSale.valid_from}
                            onChange={(e: CalendarChangeEvent) => {
                              formik.setFieldValue('valid_from', e.value);
                              onChangeFormCreateFlashSale('valid_from', String(dayjs(e.value as Date).format('YYYY-MM-DD')));
                            }}
                            dateFormat="yy-mm-dd"
                            minDate={new Date()}
                            hideOnDateTimeSelect
                            maxDate={new Date(formCreateFlashSale.valid_to as string)}
                          />
                          <div className="pt-1 pl-1">{getFormErrorMessage('valid_from')}</div>
                        </div>
                      </div>
                      <div className="grid grid-cols-3">
                        <div className="ml-2">Valid To*</div>
                        <div className="flex flex-col">
                          <Calendar
                            value={formCreateFlashSale.valid_to}
                            placeholder={formCreateFlashSale.valid_to}
                            disabled={!Boolean(formCreateFlashSale.valid_from)}
                            onChange={(e: CalendarChangeEvent) => {
                              formik.setFieldValue('valid_to', e.value);
                              onChangeFormCreateFlashSale('valid_to', String(dayjs(e.value as Date).format('YYYY-MM-DD')));
                            }}
                            dateFormat="yy-mm-dd"
                            hideOnDateTimeSelect
                            minDate={formCreateFlashSale.valid_from == '' ? new Date() : new Date(String(dayjs(formCreateFlashSale.valid_from).format('YYYY-MM-DD')))}
                          />
                          <div className="pt-1 pl-1">{getFormErrorMessage('valid_to')}</div>
                        </div>
                      </div>
                    </div>
                    // End Range
                  )}

                  <div className="grid grid-cols-3">
                    <div>Status*</div>
                    <div className="flex flex-col">
                      <Dropdown
                        value={formCreateFlashSale.status}
                        onChange={(e: DropdownChangeEvent) => {
                          formik.setFieldValue('status', e.value?.name);
                          onChangeFormCreateFlashSale('status', e.value);
                        }}
                        options={optionStatus}
                        optionLabel="name"
                        placeholder="Select Status"
                        className="w-full md:w-14rem"
                      />
                      <div className="pt-1 pl-1">{getFormErrorMessage('status')}</div>
                    </div>
                  </div>
                </div>
              </div>
              // End Flash Sale Detail
            ) : (
            // Flash Sale Product
              <div>
                <div className="text-sm">
                  <div className="flex items-center p-4 rounded-lg bg-white">
                    <InputText
                      className="w-60"
                      placeholder="Product ID / Name"
                      type="search"
                      onInput={onSearch}
                      value={filter}
                      onKeyDown={onType}
                    />
                    <div className="ml-4">
                      <Button
                        id="filter-table"
                        label="Search"
                        onClick={onFilter}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="p-4 rounded-lg bg-white">
                    <div className="flex justify-between items-center h-20">
                      <div className="h-full flex flex-col justify-between">
                        <p className="font-bold">UPDATE MASAL</p>
                        <div className="h-[50px] flex items-center">
                          <p className="text-gray-400">{selectedProducts.length} produk dipilih</p>
                        </div>
                      </div>
                      <div className="h-full flex flex-col justify-between">
                        <p>FS Price By</p>
                        <Dropdown
                          className="w-full max-w-[140px]"
                          options={priceByOptions}
                          onChange={onInputPriceBy}
                          value={formMassUpdate.priceBy}
                        />
                      </div>
                      <div className="h-full flex flex-col justify-between">
                        <p>FS Price Value</p>
                        <InputNumber
                          className="w-full max-w-[140px] text-sm"
                          style={{ fontSize: '14px' }}
                          placeholder="Percentage/Amount"
                          onValueChange={onInputPriceValue}
                          value={formMassUpdate.priceValue}
                          locale="id-ID"
                        />
                      </div>
                      <div className="h-full flex flex-col justify-between">
                        <p>Flash Sale Stock</p>
                        <InputNumber
                          className="w-full max-w-[120px]"
                          onValueChange={onInputStock}
                          value={formMassUpdate.stock}
                          locale="id-ID"
                        />
                      </div>
                      <div className="h-full flex flex-col justify-end">
                        <Button
                          label="Update Masal"
                          outlined
                          disabled={disabledMassUpdateButton}
                          onClick={onMassUpdate}
                        />
                      </div>
                    </div>
                    <br />
                    <div className="flex items-center gap-4">
                      <Button
                        label="Tambah Produk"
                        outlined
                        icon="pi pi-fw pi-plus"
                        onClick={toggleModalProduct}
                      />
                      <FileUpload
                        mode="basic"
                        accept=".xlsx"
                        auto
                        customUpload
                        multiple
                        uploadHandler={handleSelectFile}
                        ref={fileUploaded}
                        disabled={isSubmit}
                        className="bg-transparent"
                        id="product-highlight-input-product"
                        chooseOptions={{
                          className: 'p-button-outlined',
                          label: 'Bulk Upload',
                          icon: 'empty'
                        }}
                      />
                      <FileUpload
                        mode="basic"
                        accept=".xlsx"
                        auto
                        customUpload
                        multiple
                        uploadHandler={handleSelectFileUpdate}
                        ref={fileUpdateUploaded}
                        disabled={isSubmit}
                        className="bg-transparent"
                        id="product-highlight-input-product"
                        chooseOptions={{
                          className: 'p-button-outlined',
                          label: 'Bulk Update',
                          icon: 'empty'
                        }}
                      />
                    </div>
                  </div>
                  <br />
                  {!!products && products.length > 0 && (
                    <ProductTable
                      data={filteredProducts ?? products}
                      selectedProduct={selectedProducts}
                      onSelectProduct={setSelectedProducts}
                      flashSaleSlot={time}
                      onRowEditComplete={onRowEditComplete}
                      disabledEditStatus
                      minDate={minDate}
                      maxDate={maxDate}
                      onDelete={onDelete}
                    />
                  )}
                </div>
                <div className="absolute bottom-0 right-0 m-6">
                  <Button label="Download Template" icon="pi pi-download" onClick={handleDownloadTemplate} />
                </div>
              </div>
              //End Flash Sale Product
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-3 mt-3 justify-end">
        <SubmitButton
          onSubmit={() => {
            if (!(Object.keys(formik.errors).length === 0)) {
              toast?.current?.show({
                severity: 'error',
                summary: 'Failed',
                detail: 'Field is Required',
                life: 2500
              });
            }
            formik.handleSubmit();
          }}
          onCancel={()=> navigate('/flash-sale')}
          labelSubmit="SUBMIT"
          disabledSubmit={isSubmit}
          labelBack="CANCEL"/>
      </div>
      {/* End Content */}
    </div>
  );
};

export default CreateFlashSale;
