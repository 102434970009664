import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type State = {
  visitedPage: IHistoryStoreVisitedPage;
  lastFilterPage: IHistoryStoreVisitedPage;
};

type Action = {
  setVisitedPage: (page: IHistoryStoreVisitedPage) => void;
  setLastFilterPage: (page: IHistoryStoreVisitedPage) => void;
};

interface IHistoryStoreVisitedPage {
  usergroup: number | string;
  accountType: number | string;
  userAdmin: number | string;
  pageBuilder: number | string;
  banner: number | string;
  productHighlight: number | string;
  customerManagement: number | string;
  category: number | string;
  specification: number | string;
  variant: number | string;
  attributeSet: number | string;
  products: number | string;
  promoTagging: number | string;
  voucher: number | string;
  promoOngkir: number | string;
  promoPage: number | string;
  flashSale: number | string;
  masterSlot: number | string;
  orderManagement: number | string;
  auditTrail: number | string;
  importHistory: number | string;
}

const initStateHistoryStore: State = {
  visitedPage: {
    usergroup: 1,
    accountType: 1,
    userAdmin: 1,
    pageBuilder: 1,
    banner: 1,
    productHighlight: 1,
    customerManagement: 1,
    category: 1,
    specification: 1,
    variant: 1,
    attributeSet: 1,
    products: 1,
    promoTagging: 1,
    voucher: 1,
    promoOngkir: 1,
    promoPage: 1,
    flashSale: 1,
    masterSlot: 1,
    orderManagement: 1,
    auditTrail: 1,
    importHistory: 1
  },
  lastFilterPage: {
    usergroup: '',
    accountType: '',
    userAdmin: '',
    pageBuilder: '',
    banner: '',
    productHighlight: '',
    customerManagement: '',
    category: '',
    specification: '',
    variant: '',
    attributeSet: '',
    products: '',
    promoTagging: '',
    voucher: '',
    promoOngkir: '',
    promoPage: '',
    flashSale: '',
    masterSlot: '',
    orderManagement: '',
    auditTrail: '',
    importHistory: ''
  }
};

export const useHistoryStore = create<State & Action>()(
  persist(
    (set) => ({
      ...initStateHistoryStore,
      setVisitedPage: (page) => {
        set({ visitedPage: page });
      },
      setLastFilterPage: (page) => {
        set({ lastFilterPage: page });
      }
    }),
    { name: 'history' }
  )
);
