import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import React from 'react';
import EditUserGroupInformation from './TabGroupInformation/EditUserGroupInformation';

const EditUserGroupManagement = () => {
  return (
    <div>
      <BaseContainer>
        <MainTitle title="Edit Group Information" isWithButton={false} />
        <EditUserGroupInformation />
      </BaseContainer>
    </div>
  );
};

export default EditUserGroupManagement;
