// import LoadingOverlay from '@/components/base/LoadingOverlay';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import useTabEditGroupInformation from './hooks';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';

export const status = [
  { code: true, label: 'Active' },
  { code: false, label: 'Inactive' }
];

const EditUserGroupInformation = () => {
  const { data, method } = useTabEditGroupInformation();
  const getFormErrorMessage = (name) => {
    return method.isFormFieldValid(name) && <small className="p-error">{data.formik.errors[name]}</small>;
  };
  return (
    <div>
      <Toast ref={data.toast} />
      <div className="border border-mainly-blue rounded-md min-h-full p-10">
        <div className="flex justify-end mt-2.5 mb-5">
          <Button onClick={method.handleClickCancel} label="Cancel" icon="pi pi-times" className="p-button-text" />
          <Button id="user-group-edit-nama" label="Save" onClick={()=>data.formik.handleSubmit()} />
        </div>
        {/* <LoadingOverlay show={isLoading} /> */}
        <div className="grid grid-cols-12 items-center gap-x-2 gap-y-2.5">
          <div className="col-span-2 flex justify-between">
            <span>Name *</span>
            <span>:</span>
          </div>
          <div className="col-span-10 w-full">
            <InputText id="user-group-name" value={data.formEdit.name || ''} onChange={(e) => method.handleFormEdit('name', e.target.value)} className="w-full" />
            <div className="pt-1 pl-1">{getFormErrorMessage('name')}</div>
          </div>
          <div className="col-span-2 flex justify-between">
            <span>Description</span>
            <span>:</span>
          </div>
          <div className="col-span-10">
            <InputText id="user-group-description" value={data.formEdit.description || ''} onChange={(e) => method.handleFormEdit('description', e.target.value)} className="w-full" />
          </div>
          <div className="col-span-2 flex justify-between">
            <span>Number of Members</span>
            <span>:</span>
          </div>
          <div className="col-span-10">
            <InputNumber id="user-group-count" value={data?.mergedData?.count || 0} className="w-full" disabled />
          </div>
          <div className="col-span-2 flex justify-between">
            <span>Created Date</span>
            <span>:</span>
          </div>
          <div className="col-span-10">
            <InputText id="user-group-created-at" value={String(data.mergedData?.created_at) || ''} className="w-full" disabled />
          </div>
          <div className="col-span-2 flex justify-between">
            <span>Group Status *</span>
            <span>:</span>
          </div>
          <div className="col-span-10">
            <Dropdown
              id="user-group-status"
              required
              className="w-full"
              optionLabel="label"
              optionValue="code"
              value={data.formEdit.status}
              options={status}
              onChange={(e) => method.handleFormEdit('status', e.target.value)}
              placeholder="Select status" />
            <div className="pt-1 pl-1">{getFormErrorMessage('status')}</div>
          </div>
          <LoadingOverlay show={data.isFetching} />
        </div>
      </div>
    </div>
  );
};

export default EditUserGroupInformation;
