import { Modal, NumberInput, SelectInput, TextInput } from 'akeneo-design-system';
import React, { useContext } from 'react';
import { CategoryContext } from '../hooks';

const ModalFilterProduct = ({ onFilter, onCancel }: { onFilter: () => void; onCancel: () => void }) => {
  const category = useContext(CategoryContext);

  const {
    data: { filterProducts },
    methods: {
      onChangeFilterIdFrom,
      onChangeFilterIdTo,
      onChangeFilterPriceFrom,
      onChangeFilterPriceTo,
      onChangeFilterSpecialPriceFrom,
      onChangeFilterSpecialPriceTo,
      onChangeFilterName,
      onChangeFilterSku,
      onChangeFilterStatus
    }
  } = category();
  return (
    <Modal id="modal-product-category" className="modal-filter-product" onClose={() => {}} closeTitle="close">
      <div className="py-4 px-4 flex flex-col gap-8 w-9/12 ml-80 font-sans">
        <div className="w-full text-left">
          <p className="text-black font-medium">Semua Filter</p>
        </div>

        {/* filter id */}
        <div className="flex flex-col gap-10 w-full">
          <div>
            <p className="font-medium text-black mb-1">ID</p>
            <div className="flex gap-6 items-center text-black">
              <p>Dari</p>
              <div className="w-1/4 input-filter">
                <NumberInput
                  id="input-id-from"
                  onChange={onChangeFilterIdFrom}
                  placeholder=""
                  value={String(filterProducts.productId.from)}
                />
              </div>
              <p className="ml-2">Sampai</p>
              <div className="w-1/4 input-filter">
                <NumberInput
                  id="input-id-to"
                  onChange={onChangeFilterIdTo}
                  placeholder=""
                  value={String(filterProducts.productId.to)}
                />
              </div>
            </div>
          </div>

          {/* filter price */}
          <div>
            <p className="font-medium text-black mb-1">Harga</p>
            <div className="flex gap-6 items-center text-black">
              <p>Dari</p>
              <div className="w-1/4 input-filter">
                <NumberInput
                  id="input-price-from"
                  onChange={onChangeFilterPriceFrom}
                  placeholder=""
                  value={String(filterProducts.price.from)}
                />
              </div>
              <p className="ml-2">Sampai</p>
              <div className="w-1/4 input-filter">
                <NumberInput
                  id="input-price-to"
                  onChange={onChangeFilterPriceTo}
                  placeholder=""
                  value={String(filterProducts.price.to)}
                />
              </div>
            </div>
          </div>

          {/* filter special price */}
          <div>
            <p className="font-medium text-black mb-1">Harga Spesial</p>
            <div className="flex gap-6 items-center text-black">
              <p>Dari</p>
              <div className="w-1/4 input-filter">
                <NumberInput
                  id="input-special-price-from"
                  onChange={onChangeFilterSpecialPriceFrom}
                  placeholder=""
                  value={String(filterProducts.specialPrice.from)}
                />
              </div>
              <p className="ml-2">Sampai</p>
              <div className="w-1/4 input-filter">
                <NumberInput
                  id="input-special-price-to"
                  onChange={onChangeFilterSpecialPriceTo}
                  placeholder=""
                  value={String(filterProducts.specialPrice.to)}
                />
              </div>
            </div>
          </div>

          <div className="flex gap-20 w-full">
            <div>
              <p className="font-medium text-black mb-2">Nama Produk</p>
              <div className="w-60 input-filter">
                <TextInput
                  id="input-product-name"
                  onChange={onChangeFilterName}
                  placeholder=""
                  value={filterProducts.productName}
                />
              </div>
            </div>
            <div>
              <p className="font-medium text-black mb-2">SKU</p>
              <div className="w-60 input-filter">
                <TextInput
                  id="input-product-sku"
                  onChange={onChangeFilterSku}
                  placeholder=""
                  value={filterProducts.sku}
                />
              </div>
            </div>
            <div>
              <p className="font-medium text-black mb-2">Status</p>
              <div className="w-60 select-filter">
                <SelectInput
                  id="select-product-status"
                  openLabel=""
                  emptyResultLabel="No result found"
                  onChange={onChangeFilterStatus}
                  placeholder="-- Semua Status --"
                  value={filterProducts.status}
                >
                  <SelectInput.Option title="" value="Aktif" id="option-product-status-active">
                    Aktif
                  </SelectInput.Option>
                  <SelectInput.Option title="" value="Tidak Aktif" id="option-product-status-inactive">
                    Tidak Aktif
                  </SelectInput.Option>
                </SelectInput>
              </div>
            </div>
          </div>

          <div id="action-filter-product" className="w-full flex justify-end gap-6">
            <button
              type="button"
              id="filter-product-cancel"
              className="px-10 py-3 bg-[#F1F2F4] rounded-md text-dark-electric-blue"
              onClick={onCancel}
            >
              <p className="font-sans">Batal</p>
            </button>
            <button type="button" id="filter-product-submit" className="px-10 py-3 bg-azure rounded-md text-white" onClick={onFilter}>
              <p className="font-sans">Terapkan</p>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalFilterProduct;
