import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { TABLE } from '@/constants';
import { getAlVariants, upsertPromoAppliedMerchants, upsertPromoAppliedProduct, upsertPromoAppliedPromo, useListPaymentProviders } from '@/services/rest/voucher';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { useFormik } from 'formik';
import { SchemaCreateVoucher } from './validation';
import { debounce, uniqBy } from 'lodash';
import { MultiSelectChangeEvent } from 'primereact/multiselect';

export interface ProductVariant {
    id: string;
    products: [{ name: string }]
  }

export interface Merchants {
    id: string;
    name: string;
  }

export interface PromoTags {
    id: number;
    name: string;
  }

interface IProductVariants {
  id: string
}

interface IOptionsProduct {
  id: number | string;
  name: string;
  product_variants: IProductVariants[]
}

interface IException {
  exclude_type: string;
  exclude: [],
  excludeProductVariantIds: string[]
}

const useCreateVoucher = () => {
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [foundProducts, setFoundProducts] = useState<IOptionsProduct[]>([]);
  const [foundMerchants, setFoundMerchants] = useState<{ id: string, name: string }[]>([]);
  const [foundPromoTags, setFoundPromoTags] = useState<{ id: string, name: string }[]>([]);
  const [formValue, setFormValue] = useState({
    name: '',
    platforms: [],
    customers: [],
    cooperation: '',
    merchants: [],
    borned_by_seller: 0,
    start_date: new Date(),
    end_date: new Date(),
    status: false,
    description: '',
    code: '',
    use_generator: false,
    no_minimal: false,
    quota: 0,
    user_quota: 0,
    is_user_quota_unlimited: false,
    min_payment_amount: 0,
    max_discount_amount: 0,
    amount_type: '',
    discount_amount: 0,
    discount_percentage: 0,
    payment_method: {
      id: 0,
      label: '',
      code: ''
    },
    discount_by: '',
    bin_code: [],
    voucher_type: '',
    applied_to: [],
    exclude_type: '',
    exclude: [],
    applied_promo_tags: [],
    accepted_payment_provider_ids: [],
    isAllPlatform: false,
    isRegisteredCustomer: false
  });
  const [counter, setCounter] = useState<number>(1);

  const collapsible = {
    quota: true,
    condition: true,
    codes: true
  };
  const [collapse, setCollapse] = useState(collapsible);

  const [addMoreException, setAddMoreException] = useState<IException[]>([
    {
      exclude_type: '',
      exclude: [],
      excludeProductVariantIds: []
    }
  ]);

  const optionNominal = [
    { name: 'Fixed Amount', code: 'fixed' },
    { name: 'Percentage', code: 'percentage' }
  ];

  const optionVoucherType = [
    { name: 'Auto', code: 'auto' },
    { name: 'Specific', code: 'specific' }
  ];

  const selectionDiscountOption = [
    { name: 'All Product in Checkout', code: 'ALL' },
    { name: 'Selected Product', code: 'PRODUCT' },
    { name: 'Selected Merchant', code: 'STORE' },
    { name: 'Selected Promo Tags', code: 'PROMO' }
  ];

  const initialValueFormik = {
    name: '',
    platforms: [],
    customers: [],
    cooperation: '',
    start_date: new Date(),
    end_date: new Date(),
    description: '',
    voucher_type: '',
    code: '',
    quota: '',
    user_quota: '',
    min_payment_amount: '',
    amount_type: '',
    discount_amount: '',
    discount_percentage: '',
    max_discount_amount: '',
    payment_method: '',
    bin_code: []
  };

  const excludedDiscountOption = [
    { name: 'Selected Product', code: 'PRODUCT' },
    { name: 'Selected Merchant', code: 'STORE' },
    { name: 'Selected Promo Tags', code: 'PROMO' }
  ];
  //toast
  const showSuccess = (msg: string) => {
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: `${msg}`,
      life: 2000
    });

    navigate('/voucher');
  };

  const showError = (msg: string) => {
    toast.current?.show({
      severity: 'error',
      summary: 'Error',
      detail: `${msg}`,
      life: 2000
    });
  };

  const findMerchantsDebounce = debounce(async (findName: string | null) => {
    if (!findName) {
      return;
    }

    const { data } = await supabase.from('catalogue.merchants').select('id, name').ilike('name', `*${findName}*`).limit(10);

    if (data) {
      const concatData = uniqBy([...foundMerchants || [], ...data], 'id');
      setFoundMerchants(concatData);
    }
  }, 250);

  const findMerchants = async (findName: string | null) => {
    findMerchantsDebounce(findName);
  };

  const findProductsDebounce = debounce(async (findName: string | null) => {
    if (!findName) {
      return;
    }

    const { data } = await supabase.from('catalogue.products').select('id, name, product_variants!inner(id)')
      .ilike('name', `*${findName}*`).eq('status', 'APPROVED').eq('is_active', true).limit(10);
    if (data) {
      const concatData = uniqBy([...foundProducts || [], ...data], 'id');
      setFoundProducts(concatData as []);
    }
  }, 250);

  const findProducts = async (findName: string | null) => {
    findProductsDebounce(findName);
  };

  const findPromoTagsDebounce = debounce(async (findName: string | null) => {
    if (!findName) {
      return;
    }

    const { data } = await supabase.from('catalogue.promo_tags').select('id, name').ilike('name', `*${findName}*`).limit(10);

    if (data) {
      const concatData = uniqBy([...foundPromoTags || [], ...data], 'id');
      setFoundPromoTags(concatData);
    }
  }, 250);

  const findPromoTags = async (findName: string | null) => {
    findPromoTagsDebounce(findName);
  };

  const formik = useFormik({
    initialValues: initialValueFormik,
    validationSchema: SchemaCreateVoucher,
    onSubmit: () => {
      onCreateVoucher();
    }
  });

  const onChangeFormValue = (key: string, value: string | Date | boolean | number | string[]) => {
    formik.setValues((prev) => {
      return {
        ...prev,
        [key]: value
      };
    });
    setFormValue((prevState) => {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  const handleOnChangeDropdown = useCallback(
    (key: string) => (event: DropdownChangeEvent) => {
      if (key !== 'payment_method') {
        formik.setValues((prev) => ({
          ...prev,
          [key]: event.value
        }));
        setFormValue((prev) => ({
          ...prev,
          [key]: event.value
        }));
      }
      if (key === 'payment_method') {
        setFormValue((prev) => ({
          ...prev,
          payment_method: {
            id: event.value?.id,
            code: event.value?.code,
            label: event.value?.label
          }
        }));
        formik.setValues((prev) => ({
          ...prev,
          payment_method: event.value?.code
        }));
      }
      if (key === 'discount_by') {
        setFormValue((prev)=>({
          ...prev,
          applied_to: []
        }));
      }
      if (key==='exclude_type') {
        setFormValue((prev)=>({
          ...prev,
          exclude: []
        }));
      }
    },
    []
  );

  const handleOnChangeCheckbox = useCallback(
    (key: string) => (event: CheckboxChangeEvent) => {
      if (key === 'use_generator') {
        onChangeFormValue('quota', 1);
        onChangeFormValue('code', '');
      }
      formik.setValues((prev) => ({
        ...prev,
        [key]: event.checked
      }));
      setFormValue((prev) => ({
        ...prev,
        [key]: event.checked
      }));
    },
    []
  );
  const handleAddException = () => {
    setAddMoreException((prev) => [
      ...prev,
      {
        exclude_type: '',
        exclude: [],
        excludeProductVariantIds: []
      }
    ]);
  };

  const onCollapse = (key: string, value: boolean) => {
    setCollapse((prevState)=> {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  const merchantOptions = useMemo(() => {
    return Array.isArray(foundMerchants) && foundMerchants;
  }, [foundMerchants]);

  const promoTagsOptions = useMemo(() => {
    return Array.isArray(foundPromoTags) && foundPromoTags;
  }, [foundPromoTags]);

  const variantOptions = useMemo(() => {
    return Array.isArray(foundProducts) ? foundProducts : [];
  }, [foundProducts]);

  const { data: dataPaymentProviders } = useListPaymentProviders();

  const listProvidersPayment = useMemo(() => {
    const paymentProviderActive = dataPaymentProviders?.data?.data?.filter((it) => it.active);
    return (
      (Array.isArray(paymentProviderActive) &&
      paymentProviderActive.map((pp) => {
        return {
          label: pp.name,
          code: pp.slug,
          id: pp.id
        };
      })) || []
    );
  }, [dataPaymentProviders]);

  const optionsPayments = useMemo(() => {
    if (listProvidersPayment) {
      const newOptions = [
        {
          label: 'Semua Metode Pembayaran',
          code: 'all-payment-method',
          id: 'all-payment'
        },
        ...listProvidersPayment
      ];
      return newOptions;
    }
  }, [listProvidersPayment]);


  const onCreateVoucher = useCallback(async () => {
    setIsLoading(true);
    const { data: variants } = await getAlVariants(formValue.applied_to);

    const variantIds = variants?.map((v)=>v.product_variants.map((pv)=>pv.id)).reduce((prev, current)=>prev.concat(current), []);

    const excludeProdIds:string[] = [];
    addMoreException.forEach((exception) => {
      exception.exclude.forEach((exclude: string) => {
        excludeProdIds.push(exclude);
      });
    });

    const excludeMerchantIds = addMoreException.find((it) => it.exclude_type == 'STORE')?.exclude as [];

    const excludePromoTagsIds = addMoreException.find((it) => it.exclude_type == 'PROMO')?.exclude as [];

    const excludeProductIds = addMoreException.find((it) => it.exclude_type == 'PRODUCT')?.excludeProductVariantIds as [];

    const excludePromoTagsIdsString = excludePromoTagsIds?.map(String);

    const query = supabase
      .from(TABLE.PROMOS)
      .insert([
        {
          type: 'product',
          name: formValue.name,
          start_date: formValue.start_date.toISOString(),
          end_date: formValue.end_date.toISOString(),
          description: formValue.description,
          code: formValue.code,
          is_quota_unlimited: false,
          is_user_quota_unlimited: formValue.is_user_quota_unlimited,
          quota: formValue.quota,
          user_quota: formValue.user_quota,
          status: formValue.status,
          amount: formValue.amount_type == 'fixed' ? formValue.discount_amount : formValue.discount_percentage,
          amount_type: formValue.amount_type,
          min_payment_amount: formValue.min_payment_amount,
          max_payment_amount: formValue.min_payment_amount * 2,
          min_discount_amount: formValue.amount_type === 'fixed'? formValue.discount_amount : formValue.min_payment_amount * (formValue.discount_percentage / 100),
          max_discount_amount: formValue.amount_type === 'fixed' ? formValue.discount_amount : formValue.max_discount_amount,
          accepted_payment_provider_ids: formValue.payment_method.code == 'all-payment-method' ? null : [formValue.payment_method],
          payment_bin_rule: formValue.bin_code,
          is_payment_cc: formValue.payment_method.code === 'credit_card',
          target_platform: formValue.isAllPlatform ? ['web', 'ios', 'android'] : null,
          is_public: !formValue.isRegisteredCustomer,
          merchant_cooperation: formValue.merchants,
          borne_by_seller: formValue.borned_by_seller,
          is_code_generator: formValue.use_generator
        }
      ]);

    const { data, error } = await query.select('*').single();

    if (data && !formValue.use_generator) {
      const { error: errorRule } = await supabase.functions.invoke('promo-engine', ({
        body: {
          'action': 'add',
          'promo': {
            'id': data.id,
            'code': data.code,
            'description': data.description,
            'image': data.image,
            'amount_type': data.amount_type,
            'amount': data.amount,
            'start_date': data.start_date,
            'end_date': data.end_date,
            'status': data.status,
            'type': 'product',
            'accepted_payment_provider_ids': data.accepted_payment_provider_ids,
            'payment_bin_rule': data.payment_bin_rule,
            'is_payment_cc': data.is_payment_cc,
            'quota': data.quota,
            'user_quota': data.user_quota,
            'is_quota_unlimited': data.is_quota_unlimited,
            'is_user_quota_unlimited': data.is_user_quota_unlimited,
            'min_discount_amount': data.min_discount_amount,
            'max_discount_amount': data.max_discount_amount,
            'min_payment_amount': data.min_payment_amount,
            'max_payment_amount': data.max_payment_amount,
            'target_platform': data.target_platform,
            'is_public': data.is_public,
            'promo_applied_merchant_variants': [
              {
                'merchant_ids': formValue.discount_by === 'STORE' ? formValue.applied_to : []
              }
            ],
            'promo_applied_product_variants': [
              {
                'product_variant_ids': variantIds
              }
            ],
            'promo_applied_shipping_providers': [
              {
                'shipping_provider_ids': [
                ]
              }
            ],
            'promo_applied_product_variant_promo_tags': [
              {
                'product_variant_promo_tag_ids': formValue.discount_by === 'PROMO' ? formValue.applied_to : []
              }
            ],
            'promo_excluded_product_variants': [
              {
                'product_variant_ids': excludeProductIds
              }
            ],
            'promo_excluded_merchant_variants': [
              {
                'merchant_ids': excludeMerchantIds
              }
            ],
            'promo_excluded_product_variant_promo_tags': [
              {
                'product_variant_promo_tag_ids': excludePromoTagsIdsString
              }
            ]
          }
        }
      }));

      if (excludeProductIds && excludeProductIds?.length > 0) {
        const payload = excludeProductIds.map((it) => {
          return {
            promo_id: data.id,
            product_variant_ids: it
          };
        });
        const { error: errExclude } = await supabase
          .from('trade.promo_excluded_product_variants')
          .insert(payload);

        if (errExclude) {
          setIsLoading(false);
          showError('Failed insert to promo excluded product variants');
          return;
        }
      }
      if (excludeMerchantIds && excludeMerchantIds.length > 0) {
        const payload = excludeMerchantIds.map((it) => {
          return {
            promo_id: data.id,
            merchant_ids: it
          };
        });
        const { error: errExclude } = await supabase
          .from('trade.promo_excluded_merchant_variants')
          .insert(payload);
        if (errExclude) {
          setIsLoading(false);
          showError('Failed insert to promo excluded product variants');
          return;
        }
      }
      if (excludePromoTagsIds && excludePromoTagsIds.length > 0) {
        const payload = excludePromoTagsIds.map((it) => {
          return {
            promo_id: data.id,
            product_variant_promo_tag_ids: it
          };
        });
        const { error: errExclude } = await supabase
          .from('trade.promo_excluded_product_variant_promo_tags')
          .insert(payload);
        if (errExclude) {
          setIsLoading(false);
          showError('Failed insert to promo excluded product variants');
          return;
        }
      }
      if (errorRule) {
        setIsLoading(false);
        showError('Failed Create Voucher');
        return;
      };
      if (formValue.discount_by === 'PROMO') {
        const { error: errPromo } = await upsertPromoAppliedPromo({
          promo_id: data.id,
          product_variant_promo_tag_ids: formValue.applied_to
        });
        if (errPromo) {
          setIsLoading(false);
          showError('Failed Create Voucher');
          return;
        }
      }
      if (formValue.discount_by === 'PRODUCT' && variantIds && variantIds.length > 0) {
        const { error: errPromo } = await upsertPromoAppliedProduct({
          promo_id: data.id,
          product_variant_ids: variantIds
        });
        if (errPromo) {
          setIsLoading(false);
          showError('Failed Create Voucher');
          return;
        }
      }
      if (formValue.discount_by === 'STORE') {
        const { error: errPromo } = await upsertPromoAppliedMerchants({
          promo_id: data.id,
          merchant_ids: formValue.applied_to
        });
        if (errPromo) {
          setIsLoading(false);
          showError('Failed Create Voucher');
          return;
        }
      }
      showSuccess('New voucher created successfully');
    }

    if (error) {
      showError('Failed Create Voucher');
    } else {
      showSuccess('New voucher created successfully');
    }
    setIsLoading(false);
  }, [
    formValue,
    addMoreException
  ]);

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  // handle delete rule exception
  const handleDeleteRuleExcepiton = useCallback((index: number) => {
    const tempExceptionArr = [...addMoreException];
    tempExceptionArr.splice(index, 1);

    setAddMoreException(tempExceptionArr);
  }, [addMoreException]);


  const handleInputChange = (index, event: MultiSelectChangeEvent, type = '') => {
    const values = [...addMoreException];
    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;

    if (updatedValue == 'exclude_type') {
      values[index]['exclude'] = [];
    }

    // to check when exclude type product adding product variant id
    if (type == 'PRODUCT' && event?.selectedOption?.product_variants) {
      // filter product variant it by product id
      const productVariantIds = variantOptions.filter((i) => event.value.includes(i.id)).map((v) => v.product_variants).flat().map((it) => it.id);
      values[index]['excludeProductVariantIds'] = productVariantIds;
    } else if (type == 'PRODUCT' && !event?.selectedOption?.product_variants) {
      // select all product variant
      const productVariantIds = variantOptions.map((i) => i.product_variants.map((v) => v.id)).flat();
      values[index]['excludeProductVariantIds'] = productVariantIds;
    }

    setAddMoreException(values);
  };


  // get default options product
  const getOptionsProduct = async () => {
    const { data } = await supabase.from('catalogue.products').select('id, name, product_variants!inner(id)').limit(10);

    if (data) {
      setFoundProducts(data as []);
    }
  };

  // get default options merchant
  const getOptionsMerchant = async () => {
    const { data } = await supabase.from('catalogue.merchants').select('id, name').limit(10);

    if (data) {
      setFoundMerchants(data);
    }
  };

  // get default options promo tags
  const getOptionsPromoTags = async () => {
    const { data } = await supabase.from('catalogue.promo_tags').select('id, name').limit(10);

    if (data) {
      setFoundPromoTags(data);
    }
  };

  const defaultValueUsageVoucher = useCallback(() => {
    if (formValue.is_user_quota_unlimited) {
      onChangeFormValue('user_quota', 0);
    } else if (formValue.use_generator) {
      onChangeFormValue('quota', 1);
      onChangeFormValue('user_quota', 1);
    }
  }, [formValue]);

  useEffect(() => {
    defaultValueUsageVoucher();
  }, [formValue.use_generator, formValue.is_user_quota_unlimited]);


  useEffect(() => {
    getOptionsProduct();
    getOptionsMerchant();
    getOptionsPromoTags();
  }, []);


  return {
    data: {
      formik,
      toast,
      collapse,
      formValue,
      optionNominal,
      optionVoucherType,
      merchantOptions,
      variantOptions,
      promoTagsOptions,
      selectionDiscountOption,
      excludedDiscountOption,
      addMoreException,
      counter,
      isLoading,
      optionsPayments
    },
    method: {
      isFormFieldValid,
      onChangeFormValue,
      onCreateVoucher,
      navigate,
      handleOnChangeDropdown,
      handleOnChangeCheckbox,
      handleAddException,
      handleInputChange,
      onCollapse,
      setCounter,
      handleDeleteRuleExcepiton,
      findMerchants,
      findProducts,
      findPromoTags
    }
  };
};


export default useCreateVoucher;
