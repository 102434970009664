import LoadingOverlay from '@/components/base/LoadingOverlay';
import ShopComponents from '../../component/TitleAndTable';
import useDetailCustomer from '../../DetailHooks';

const ShoppingCart = () => {
  const { data: { listCarts, isLoadingCarts, isInitialLoadingProductVariant, isInitialLoadingProduct } } = useDetailCustomer();
  const column = [
    { field: 'id', header: 'Id' },
    { field: 'product', header: 'Product' },
    { field: 'sku', header: 'SKU' },
    { field: 'quantity', header: 'Quantity' },
    { field: 'price', header: 'Price' },
    { field: 'total', header: 'Total' }
  ];

  return (
    <>
      <LoadingOverlay show={isLoadingCarts || isInitialLoadingProductVariant || isInitialLoadingProduct} />
      <ShopComponents titleLabel="Shopping Cart" column={column} valueTable={listCarts as []} />
    </>
  );
};

export default ShoppingCart;
