import { useNavigate } from 'react-router-dom';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import Table from '@/components/base/DataTable/DataTable';
import useEditVoucher from './hooks';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { exportTable } from '@/utils/exportTable';
import dayjs from 'dayjs';
import { usePermissionStore } from '@/store/usePermissions';
import { Promos } from '@/services/rest/promo';
import { DataTableRowClickEvent } from 'primereact/datatable';
import { IPagination } from '@/components/base/Pagination';

const ListVoucherDetailVoucher = () => {
  const {
    data: {
      voucherListData,
      isLoadingCoupon,
      toast,
      pagination,
      totalPages,
      perPage,
      search,
      formValue,
      params,
      voucherListCount
    },
    method: {
      handleChangeDropdownPage,
      handleClickNext,
      handleClickPrev,
      handleSearch,
      handleJumpToPage,
      handleChangeJumpTopage
    }
  } = useEditVoucher();
  const { canView, canEdit } = usePermissionStore();
  const navigate = useNavigate();

  const navigateRow = (e: DataTableRowClickEvent) => {
    if (params.detailId) {
      canView && navigate(`/voucher/${e.data.id}`);
    } else {
      canEdit && navigate(`/voucher/edit/${e.data.id}`);
    }
  };

  const dateTemplate = (data: Promos) => {
    return (
      <div id={`voucher-created-date-${data.id}`}>{dayjs(data.created_at).format('DD MMMM YYYY, HH:mm:ss')}</div>
    );
  };

  const usedTemplate = (data: Promos) => {
    return (
      <div id={`voucher-used-${data.id}`}>{data.is_used == true ? 'YES' : 'NO'}</div>
    );
  };

  const header = [
    { field: 'code', header: 'Coupon Code' },
    { field: 'created_at', header: 'Created', body: dateTemplate },
    { field: 'is_used', header: 'Uses', body: usedTemplate }
  ];

  const paginationOptions: IPagination = {
    perPage: perPage,
    totalPages: totalPages,
    currentPage: pagination.currentPage,
    disabledDropdown: voucherListCount === 0,
    onChangeDropdownPage: handleChangeDropdownPage,
    onClickPrev: handleClickPrev,
    onClickNext: handleClickNext,
    onJumptoPage: handleJumpToPage,
    onChangeJumptoPage: handleChangeJumpTopage
  };

  return (
    <>
      <ConfirmDialog />
      <Toast ref={toast} />
      <BaseContainer>
        <div className="flex gap-4 pt-6 justify-end">
          <Button label="Export" severity="success" icon="pi pi-download" disabled={!canView}
            onClick={() => exportTable(voucherListData as [], `Promo ${formValue.name} Coupon.xlsx`)}
          />
        </div>
        <Table
          isLoadingTable={isLoadingCoupon}
          headerTable={header}
          paginationOptions={paginationOptions}
          value={voucherListData as []}
          rows={perPage}
          isCustomAction
          filter={search}
          onSearch={handleSearch}
          onRowClick={navigateRow}
          handleClearFilter={() => {}}
          handleDeleteFilterHistory={() => {}}
        />
      </BaseContainer>
    </>
  );
};

export default ListVoucherDetailVoucher;
