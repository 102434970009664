import { CSSProperties, FC } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Carousel } from 'primereact/carousel';
import { Image } from 'primereact/image';
import { IProduct } from '@/services/rest/product';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';

type IGeneral = {
  product: IProduct;
  specifications: { name: string, value: string }[];
  isOpen: boolean;
  specStyle: CSSProperties;
  onShowMore: (style: CSSProperties, state:boolean)=>void;
};

const GeneralInfo: FC<IGeneral> = ({ product, specifications, isOpen, specStyle, onShowMore }) => {
  const imageTemplate = (img) => {
    return (
      <div className="w-full ">
        <Image src={img}
          zoomSrc={img}
          alt={`image-${img}`}
          width="90" height="70" preview />
      </div>
    );
  };
  return (
    <><div id="general_info" className="font-bold text-xl mb-2">General Information</div>
      <div className="flex w-full">
        <div id="form_general_info" className="py-3 px-4 mt-4 w-1/2">
          <div id="nama_produk" className="card flex justify-content-center mb-6">
            <span className="flex w-full flex-col">
              <label htmlFor="product_name">Nama Product</label>
              <InputText disabled id="product-name-input" value={product?.name || ''} />
            </span>
          </div>
          <div id="deskripsi" className="flex mb-6 items-center w-full">
            <span className="flex flex-col w-full">
              <label className="flex w-1/5" htmlFor="category">Deskripsi</label>
              <div className="card flex justify-content-center">
                <InputTextarea id="product-description-input" value={product?.description || ''} rows={5} cols={75} readOnly />
              </div>
            </span>
          </div>
        </div>
        <div id="form_photo" className="flex flex-col mt-6 w-full">
          <div className="flex mb-2">Upload Product</div>
          <div className="flex w-full">
            <Carousel id="product-carousel" value={product?.images} numScroll={1} numVisible={4} showIndicators={false} itemTemplate={imageTemplate} />
          </div>
          <div className="flex flex-col">
            <FileUpload id="product-file-upload" mode="basic" disabled chooseLabel="Add Photos Of Product"
              url="/api/upload" accept="image/*" maxFileSize={50000}
              chooseOptions={{ className: 'custom-choose-btn p-button-text p-button-success' }}
            />
            <div className="px-4 text-xs text-gray-500">Format gambar .jpg .jpeg .png dan ukuran minimum 300 x 300px &#40;Untuk gambar yang optimal gunakan ukuran minimum 700 x 700px&#41;</div>
          </div>
        </div>
      </div>
      <div id="produk-e-voucher" className="flex px-4 w-full mb-6">
        <div className="flex flex-col w-1/2">
          <div className="flex mb-2">Produk E-Voucher</div>
        </div>
        <div className="flex items-center">
          <InputSwitch checked={product?.type === 'EVOUCHER'} disabled />
        </div>
      </div>
      <div id="video_produk" className="flex px-4 w-full mb-6">
        <div className="flex flex-col w-1/2">
          <div className="flex mb-2">Video Produk</div>
          <span className="text-xs text-gray-500">Tambah video agar pembeli semakin tertarik dengan produkmu, *maksimal 3 mega*</span>
        </div>
        <span className="p-float-label self-center w-1/2">
          <InputText id="product-url-video-input" value={product?.video_url || ''} disabled className="h-10 w-48"/>
          <label htmlFor="url_video" className="font-semibold">&#43; Tambah URL Video</label>
        </span>
      </div>
      <div id="form_spesifikasi" className="flex w-full px-4">
        <div className="flex flex-col w-1/4">
          <div className="flex mb-1">Spesifikasi</div>
          <span className="text-xs text-gray-500">Tambah keterangan secara rinci supaya produkmu makin mudah dikenali pembeli</span>
        </div>
        <div className="w-1/2" >
          <div id="spec_item" className="grid grid-cols-2 w-full gap-x-8 gap-y-0" style={specStyle}>
            {specifications.length > 0 && specifications.map((item)=> {
              return (
                <div id={item?.name} key={item.name} className="flex flex-col">
                  <label htmlFor={item?.name}>{item?.name}</label>
                  <Dropdown id={`product-${item?.name}`} disabled showClear
                    placeholder={item?.value|| ''} className="w-full h-12 mb-4"
                  />
                </div>
              );
            })}
          </div>
          {specifications.length === 0 && (
            <div className="flex mx-4 mt-2 w-1/2 p-3">Produk tidak memiliki Spesifikasi</div>
          )}
          {isOpen &&
            <div className="col-span-2">
              <Button id="product-show-more-button" label="Tampilkan lebih banyak"
                severity="success"
                onClick={(e)=> onShowMore({ height: '85%', overflow: 'hidden' }, false)} text />
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default GeneralInfo;
