import { CSSProperties, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { supabase } from '@/lib/supabase';
import { STATUS, TABLE } from '@/constants';
import { DataTableExpandedRows, DataTableSortEvent, DataTableValueArray } from 'primereact/datatable';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import {
  getAllProduct,
  ICategories,
  ICategory,
  IFilterProduct,
  IProduct,
  IProductVariants,
  ISetSpecification,
  ISetVariants,
  PendingUpdate,
  useDetailProduct,
  usePendingUpdate,
  useProduct,
  useWholesalePrice
} from '@/services/rest/product';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { debounce, uniqBy } from 'lodash';
import { exportTable } from '@/utils/exportTable';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { confirmDialog } from 'primereact/confirmdialog';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { useHistoryStore } from '@/store/useHistoryStore';
import { IFilterHistoryItems } from '@/components/base/FilterHistory';
import { useDebounce } from 'primereact/hooks';

export const headerVariant = [
  { field: 'price', header: 'Price' },
  { field: 'special_price', header: 'Special Price' }
];

interface IOptions {
  label: string;
  items: Array<IItemsOption>;
}

interface IItemsOption {
  label: string;
  value: string;
  name: string;
}

interface ISpecValue {
  name: string,
  value: string
}

interface ITreeCategory extends ICategories {
  level: number;
}

export const useCustom = () => {
  const initialDetailProduct: IProduct = {
    id: 0,
    description: '',
    specification: {
      brand: '',
      color: '',
      size: '',
      weight: 0
    },
    sku: '',
    merchant_id: '',
    merchants: {
      name: '',
      id: ''
    },
    images: [],
    video_url: '',
    insurance_flag: false,
    minimum_purchase: 1,
    maximum_purchase: 5,
    is_digital: false,
    is_active: false,
    revision_note: '',
    status: '',
    information: '',
    name: '',
    type: '',
    main_price: 0,
    is_main_price: true,
    wholesale_price: [
      {
        id: 0,
        max_purchase: 0,
        min_purchase: 0,
        price: 0,
        created_at: '',
        product_id: 0
      }
    ],
    volume: {
      height: 0,
      width: 0,
      depth: 0,
      weight: 0
    },
    product_variants: [
      {
        saleable_stock: 0,
        inflight_stock: 0,
        price: 0,
        product_id: 0,
        has_price_diff: false,
        merchant_id: '',
        id: '',
        special_price: 0,
        is_primary_variant: false,
        sku_source: '',
        sku_id: '',
        image: '',
        is_active: false,
        warehouse_id: 0,
        voucher_stock: 0,
        omnichannel_account_id: '',
        attribute_set_variants: [],
        attribute_set_specifications: [],
        saleable_stock_product_digitals: [
          {
            stock: 0,
            product_variant_id: ''
          }
        ],
        stock_product_digitals: [],
        is_digital: false
      }
    ],
    category_id: 0,
    categories: {
      id: 0,
      name: '',
      parent_id: 0
    }
  };

  const initialFilter: IFilterProduct = {
    id_from: null,
    id_to: null,
    name: '',
    merchant_name: '',
    sku: '',
    price_from: null,
    price_to: null,
    special_price_from: null,
    special_price_to: null,
    stock_from: null,
    stock_to: null,
    saleable_from: null,
    saleable_to: null,
    status_product: '',
    status: null
  };

  const [categories, setCategories] = useState<ICategories[]>([]);
  const [specifications, setSpecifications] = useState<ISpecValue[]>([]);
  const [stock, setStock] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false);
  const [noteRevision, setNoteRevision] = useState<string>('');
  const [selectedParent, setSelectedParent] = useState<{ '1': ICategory, '2': ICategory, '3': ICategory }>({
    '1': initialDetailProduct.categories as ICategory,
    '2': initialDetailProduct.categories as ICategory,
    '3': initialDetailProduct.categories as ICategory
  });
  const [secondSelect, setSecondSelect] = useState<ICategories[]>([]);
  const [lastSelect, setLastSelect] = useState<ICategories[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isExportLoading, setIsExportLoading] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [foundMerchants, setFoundMerchants] = useState<{ id: string, name: string }[]|null>(null);
  const [params, setParams] = useSearchParams();
  const [idProdVariant, setIdProdVariant] = useState<string>('');
  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const currentPage = parseInt(visitedPage.products.toString()) ?? 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : perPage - 1;
  const [paginator, setPaginator] = useState({
    currentPage,
    range: {
      start,
      end
    }
  });
  const [jumpToPage, setJumpToPage] = useState<number>(1);
  const [itemFilters, setItemFilters] = useState(
    lastFilterPage.products != '' ? JSON.parse(String(lastFilterPage.products)) : initialFilter
  );
  const [filters, setFilters] = useState(
    lastFilterPage.products != '' ? JSON.parse(String(lastFilterPage.products)) : initialFilter
  );
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);
  const [search, debounceSearch, setSearch] = useDebounce('', 1500);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);

  const [sort, setSort] = useState({
    field: 'status',
    asc: true
  });

  const [tipe1, setTipe1] = useState([
    {
      name: '',
      value: ['']
    }
  ]);
  const [tipe2, setTipe2] = useState([
    {
      name: '',
      value: ['']
    }
  ]);

  const [specStyle, setSpecStyle] = useState<CSSProperties>({ overflow: 'hidden', maxHeight: '16rem' });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<IProductVariants[]>([]);

  const [dataVariants, setDataVariants] = useState<IProductVariants[]>([
    {
      saleable_stock: 0,
      inflight_stock: 0,
      price: 0,
      product_id: 0,
      has_price_diff: false,
      merchant_id: '',
      id: '',
      special_price: 0,
      is_primary_variant: false,
      sku_source: '',
      sku_id: '',
      image: '',
      is_active: false,
      warehouse_id: 0,
      omnichannel_account_id: '',
      attribute_set_variants: [],
      attribute_set_specifications: [],
      is_digital: false
    }
  ]);
  const [expanded, setExpanded] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);

  const toast = useRef<Toast>(null);

  const { id: idParams } = useParams();

  const navigate = useNavigate();

  //get all product
  const {
    data: dataProductResponse,
    refetch: refetchProduct,
    isFetching: isFetchingProduct
  } = useProduct(paginator.range, sort, filters, debounceSearch);

  const totalRecords = useMemo(() => {
    return dataProductResponse?.count ? dataProductResponse?.count : 0;
  }, [dataProductResponse]);

  const totalPages = useMemo(() => {
    return Math.ceil(totalRecords / perPage);
  }, [totalRecords, perPage]);

  const dataProduct = useMemo(() => {
    return Array.isArray(dataProductResponse?.data) && dataProductResponse?.data.map((item) => {
      const primaryVariant = item.product_variants?.find((pv) => pv.is_primary_variant) || item.product_variants?.[0];
      let stockFs = 0;
      let stockFn = 0;
      item.product_variants?.map((pv) => {
        pv.product_flashsale?.map((eq) => {
          if (eq.saleable_stock !== undefined) {
            if (eq.periode?.isEnable === true && eq.periode?.isExpired === false) {
              stockFs +=eq.saleable_stock;
            }
          }
          return stockFs;
        });
        stockFn =+ stockFs;
        return stockFn;
      });

      const totalStocks = item.product_variants?.map((pv) => pv.inflight_stock + pv.saleable_stock) || [];
      const saleStocks = item.product_variants?.map((pv) => pv.saleable_stock) || [];
      const mainPrice = primaryVariant?.price || 0;
      const specialPrice = primaryVariant?.special_price;
      //const listFlashsale = flashSaleVariant?.product_flashsale?.find((pv) => pv.periode.isEnable === true && pv.periode.isExpired === false && new Date(pv.periode.date?.[0]) > now && pv.periode.date?.[0] !== 'undefined' );

      return {
        ...item,
        ui_id: item.ref_id || item.id,
        variant_id: item.product_variants?.map((pv) => pv.id),
        total_stock: totalStocks?.length > 0 ? totalStocks.reduce((prev, curr) => prev + curr) : 0,
        saleable_stock: saleStocks?.length > 0 ? saleStocks.reduce((prev, curr) => prev + curr) : 0,
        main_price: mainPrice,
        special_price: specialPrice,
        stock_flashsale: stockFs || 0
      };
    });
  }, [dataProductResponse]);

  const onSHowMore = (style, opened) => {
    setSpecStyle(style);
    setIsOpen(opened);
  };

  //get detail product by id
  const { data: detailProductResponse, refetch: refetchDetail } = useDetailProduct(idParams);
  const { data: wholesalePrice } = useWholesalePrice(idParams);
  const { data: pendingUpdate } = usePendingUpdate(idParams as string);

  const latestPendingUpdate = useMemo(() => {
    if (pendingUpdate?.data) {
      return pendingUpdate.data[0];
    } return undefined;
  }, [pendingUpdate]);

  const detailProduct = useMemo(() => {
    let tempData:IProduct = initialDetailProduct;
    const isUpdated = detailProductResponse?.data?.status === STATUS.IN_REVIEW_UPDATE ||
      detailProductResponse?.data?.status === STATUS.NEED_REVISION_UPDATE;
    if (detailProductResponse?.data && wholesalePrice?.data) {
      detailProductResponse.data.wholesale_price = wholesalePrice?.data || initialDetailProduct.wholesale_price;
      tempData = detailProductResponse?.data || initialDetailProduct;
    }
    if (latestPendingUpdate && detailProductResponse?.data) {
      for (const key in latestPendingUpdate) {
        if (latestPendingUpdate[key] === null) {
          delete latestPendingUpdate[key];
        }
      }
      const dataUpdate = {
        ...latestPendingUpdate,
        id: latestPendingUpdate?.product_id as number
      };
      delete dataUpdate.product_id;
      if (isUpdated) tempData = { ...detailProductResponse?.data, ...dataUpdate };
      else tempData = { ...detailProductResponse?.data };
    }
    setNoteRevision(tempData.revision_note || '');
    return tempData || initialDetailProduct;
  }, [detailProductResponse, wholesalePrice, pendingUpdate, latestPendingUpdate]);

  const findMerchantsDebounce = debounce(async (findName: string | null) => {
    if (!findName) {
      return;
    }

    const { data } = await supabase.from('catalogue.merchants').select('id, name').ilike('name', `*${findName}*`).limit(10);

    if (data) {
      const concatData = uniqBy([...foundMerchants || [], ...data], 'id');
      setFoundMerchants(concatData);
    }
  }, 250);

  const findMerchants = async (findName: string | null) => {
    findMerchantsDebounce(findName);
  };

  const validateStatus: boolean = useMemo(()=> {
    const status = detailProduct.status;
    return status === STATUS.NEED_REVISION_UPDATE || status === STATUS.NEED_REVISION;
  }, [detailProduct.status]);

  //set data filter options
  const optionsFilter = useMemo((): IOptions[] => {
    const merchants = [...new Set(foundMerchants?.map((p) => p))];
    return [
      {
        label: 'Merchants',
        items: merchants
          .filter((m) => m.name !== undefined && m.name !== null)
          .map((item) => {
            return {
              label: item.name,
              name: 'merchants',
              value: item.id
            };
          })
      },
      {
        label: 'Status',
        items: ['ACTIVE', 'INACTIVE'].map((s) => ({ label: s, value: s, name: 'status' }))
      },
      {
        label: 'Status Product',
        items: ['APPROVED', 'IN_REVIEW', 'NEED_REVISION', 'IN_REVIEW_UPDATE', 'NEED_REVISION_UPDATE'].map((s) => ({ label: s, value: s, name: 'status_product' }))
      }
    ];
  }, [foundMerchants]);

  const productDetail = async (data: IProduct) => {
    setIsLoading(true);
    const productId = data.id;
    const { data: prodVariant, error: prodError } = await supabase
      .from(TABLE.PRODUCT_VARIANTS).select('*')
      .order('is_primary_variant', { ascending: false }).eq('product_id', data.id);
    if (prodError) {
      showError(prodError.message);
      setIsLoading(false);
    }
    if (prodVariant && prodVariant.length > 0) {
      setIdProdVariant(prodVariant[0].id);
      if (detailProduct.is_digital) {
        const ids = prodVariant.map((item)=> item.id);
        const { data } = await supabase.rpc('catalogue.get_vouchers_count', { variant_ids: ids });
        if (data) {
          const stocks = data.map((item)=> item.stock).reduce((a, b)=> a + b, 0);
          prodVariant.map((item)=> {
            const stockVariant = data.find((s)=> s.product_variant_id === item.id);
            item.voucher_stock = stockVariant?.stock || 0;
            return item;
          });
          getArrtibuteSpec(prodVariant as IProductVariants[]);
          getAttributeVariant(prodVariant as IProductVariants[], productId);
          setStock(stocks);
        }
      } else {
        getArrtibuteSpec(prodVariant as IProductVariants[]);
        getAttributeVariant(prodVariant as IProductVariants[], productId);
      }
    }
  };

  const getAttributeVariant = async (prodVariant: IProductVariants[], productId: number) => {
    const { data } = await supabase.from(TABLE.ATTRIBUTE_SET_VARIANT)
      .select('product_variant_id, value, variant_id, product_variants!inner(product_id)')
      .order('variant_id')
      .eq('product_variants.product_id', productId);
    if (data && data.length > 0) {
      const dataVars = prodVariant.map((item)=> {
        const attribute = data.filter((it)=> it.product_variant_id === item.id);
        return {
          ...item,
          attribute_set_variants: attribute
        };
      }).filter((item)=> item.attribute_set_variants.length > 0);
      setDataVariants(dataVars);
      setIsLoading(false);
      getVariant(data);
      if (dataVars.every((it) => !it.is_digital)) {
        const startStock = dataVars.map((item) => item.saleable_stock).reduce((a, b) => a + b, 0);
        setStock(startStock);
      }
    } else {
      /**
       * Count saleable_stock in is_primary_variant
      */
      const primaryProductVariant = prodVariant.filter((variant) => variant.is_primary_variant && !variant.is_digital);
      if (primaryProductVariant.length > 0) {
        const startStock = primaryProductVariant.map((item) => item.saleable_stock).reduce((a, b) => a + b, 0);
        setStock(startStock);
      }
    }
  };

  const getVariant = async (attributes: ISetVariants[]) => {
    const varId = [...new Set(attributes.map((item)=> item.variant_id))].filter((item)=> item != null);
    const { data } = await supabase.from(TABLE.VARIANTS).select('label, name, id').in('id', varId);
    if (data && varId.length > 0) {
      const var1 = data.filter((i)=> i.id === varId[0]);
      const value1 = [...new Set(attributes.filter((val)=> val.variant_id === varId[0]).map((v)=> v.value))];
      setTipe1([{ name: var1[0]?.label, value: value1 !== null ? value1 : [] }]);
      if (varId.length > 1) {
        const var2 = data.filter((i)=> i.id === varId[1]);
        const value2 = [...new Set(attributes.filter((val)=> val.variant_id === varId[1]).map((v)=> v.value))];
        setTipe2([{ name: var2[0]?.label, value: value2 !== null ? value2 : [] }]);
      }
    }
  };

  const getArrtibuteSpec = async (prodVariant:IProductVariants[]) => {
    const variantIds = prodVariant.map((item)=> item.id);
    const { data } = await supabase.from(TABLE.ATTRIBUTE_SET_SPECIFICATION).select('product_variant_id, value, specification_id').order('specification_id').in('product_variant_id', variantIds);
    if (data && data.length > 0) {
      getSpecification(data);
    }
  };

  const getSpecification =async (attributes: ISetSpecification[]) => {
    const specId = [...new Set(attributes.map((item)=> item.specification_id))];
    const { data } = await supabase.from(TABLE.SPESIFICATION).select('label, name, id').is('deleted_at', null).in('id', specId);
    if (data && specId.length > 0) {
      const spec = [...new Set(attributes.flatMap((it) => {
        const specVal = data.map((sp)=>{
          if (it.specification_id === sp.id) {
            return {
              name: sp.label,
              value: it.value
            };
          }
          return null;
        }
        ).filter((s)=> s != null).map((item)=> JSON.stringify(item));
        return specVal;
      }))];
      const prodSpec = spec.map((item)=> JSON.parse(item)).filter((item)=> item.value != null);
      if (prodSpec.length>6) {
        setIsOpen(true);
      }
      setSpecifications(prodSpec as ISpecValue[]);
    }
  };

  const showError = (msg: string) => {
    if (toast.current != null) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: `${msg}`,
        life: 2500
      });
    }
  };

  const showSuccess = (msg: string) => {
    if (toast.current != null) {
      toast.current.show({
        severity: 'success',
        summary: `${msg}`,
        detail: `Product ${msg}`,
        life: 2500
      });
      setTimeout(() => {
        navigate('/product');
      }, 3000);
    }
  };
  const showSuccessStatus = (msg: string) => {
    if (toast.current != null) {
      toast.current.show({
        severity: 'success',
        summary: `${msg}`,
        detail: `Product ${msg}`,
        life: 2500
      });
    }
  };
  const onRejected = useCallback(async (status: string) => {
    const statusUpdate = status == 'IN_REVIEW' ? 'NEED_REVISION' : 'NEED_REVISION_UPDATE';

    const { error: productError } = await supabase
      .from(TABLE.PRODUCT)
      .update({ status: statusUpdate, revision_note: noteRevision })
      .eq('id', idParams);
    if (productError) {
      showError(productError.message || 'Error Reject');
    }
    // insert reject notification
    const idProduct = idProdVariant;
    const idMerchant = detailProduct.merchant_id;
    const productName = detailProduct.name;
    const sku = detailProduct.sku;
    const { error: rejectError } = await supabase
      .from('notification.notifications')
      .insert({ type: 'Rejected', merchant_id: idMerchant, metadata: { status: statusUpdate, revision_note: noteRevision, name: productName, sku: sku, product_id: idProduct } } )
      .eq('merchant_id', idMerchant);
    if (rejectError) {
      showError(rejectError.message || 'Error Notification');
    }
    setVisible(false);
    setNoteRevision('');
    showSuccess('Rejected');
  }, [noteRevision, visible]);

  const onApproved = useCallback(async () => {
    const productVariantId = selectedProducts.map((item)=> item.id);
    const payloadApproved = {
      ...detailProduct,
      status: STATUS.APPROVED,
      revision_note: '',
      is_active: true
    };
    delete payloadApproved.wholesale_price;
    delete payloadApproved.merchants;
    delete payloadApproved.categories;
    const { error } = await supabase
      .from(TABLE.PRODUCT)
      .update(payloadApproved)
      .eq('id', idParams);
    const query = supabase
      .from(TABLE.PRODUCT_VARIANTS)
      .update({ is_active: true });
    if (productVariantId.length > 0) {
      query.in('id', productVariantId);
    } else {
      query.eq('product_id', idParams);
    }
    const { error: prodVars } = await query;
    if (error || prodVars) {
      return error ? showError(error.message) : showError(prodVars?.message || 'Failed Update Product Variant Status');
    }
    setVisible(false);
    showSuccess('Approved');
  }, [selectedProducts, detailProduct, pendingUpdate?.data, idParams]);

  const getCategory = useCallback(async (detail: IProduct, pendingUpdate?: PendingUpdate) => {
    setIsLoading(true);
    const { data } = await supabase.rpc('catalogue.get_list_categories');
    if (data) {
      const level1 = (data as ITreeCategory[]).flatMap((item) => {
        return {
          ...item,
          level: 1
        };
      });
      const level2 = level1.flatMap((it)=> it.child?.map((item) => {
        return {
          ...item,
          level: 2
        };
      }));
      const level3 = level2.flatMap((it)=> it?.child?.map((item)=> {
        return {
          ...item,
          level: 3
        };
      }));
      const categorySelected: { '1': ICategory, '2': ICategory, '3': ICategory } = {
        '1': initialDetailProduct.categories as ICategory,
        '2': initialDetailProduct.categories as ICategory,
        '3': initialDetailProduct.categories as ICategory
      };
      const findLevel1 = level1.find((item)=> pendingUpdate && pendingUpdate.category_id ? item?.id === pendingUpdate.category_id : item?.id === detail.categories?.id);
      const findLevel2 = level2.find((item)=> pendingUpdate && pendingUpdate.category_id ? item?.id === pendingUpdate.category_id : item?.id === detail.categories?.id);
      const findLevel3 = level3.find((item)=> pendingUpdate && pendingUpdate.category_id ? item?.id === pendingUpdate.category_id : item?.id === detail.categories?.id);
      // if product.categories in level 3
      if (findLevel3 != undefined) {
        const selected2 = level2.find((item)=> item?.id === findLevel3.parent_id);
        const selected1 = level1.find((item)=> item?.id === selected2?.parent_id);
        categorySelected['3'] = findLevel3;
        categorySelected['2'] = selected2 as ICategories;
        categorySelected['1'] = selected1 as ICategories;
        const siblings3 = level3.filter((item)=> item?.parent_id === findLevel3.parent_id) as ICategories[];
        const siblings2 = level2.filter((item) => item?.parent_id === selected2?.parent_id) as ICategories[];
        setSecondSelect(siblings2);
        setLastSelect(siblings3);
        setCategories(level1);
        setIsLoading(false);
      }
      // if product.categories in level 2
      if (findLevel2 != undefined) {
        categorySelected['2'] = findLevel2;
        const siblings2 = level2.filter((item) => item?.parent_id === findLevel2.parent_id) as ICategories[];
        setCategories(level1);
        setSecondSelect(siblings2);
        setIsLoading(false);
      }
      // if product.categories in level 1
      if (findLevel1) {
        categorySelected['1'] = findLevel1;
        setCategories(level1);
        setIsLoading(false);
      }
      setSelectedParent(categorySelected);
      setIsLoading(false);
    }
  }, []);

  // scroll to top

  const handleFilter = useCallback((e, field: string) => {
    setItemFilters((prev) => ({
      ...prev,
      [field]: e.target ? e.target.value : e.value
    }));
  }, []);

  const handleChangeInput = async ({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>) => {
    params.delete('page');
    params.delete('limit');
    const existingParams = Object.fromEntries(params);

    const updateParams = {
      ...existingParams,
      search: currentTarget.value
    };
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage-1
      }
    });
    setParams(updateParams);
    handleChangeInputDebounce(currentTarget.value);
  };

  const handleChangeInputDebounce = debounce(async (findName: string) => {
    if (findName.length === 0) {
      setSearchQuery('');
      return;
    }
    setSearchQuery(findName);
  }, 500);

  const handleListSwitch = async (value: boolean, id) => {
    const query = supabase.from(TABLE.PRODUCT).update({
      is_active: value
    }).eq('id', id);
    const { error } = await query;
    const { error: errorProdVars } = await supabase.from(TABLE.PRODUCT_VARIANTS).update({
      is_active: value
    }).eq('product_id', id);
    if (error) {
      showError('Failed Update Status');
      return;
    }
    if (errorProdVars) {
      return showError('Failed Update Status');
    }
    showSuccessStatus('Status Updated');
    refetchProduct();
  };
  const handleListVariantSwitch = async (value: boolean, id: string) => {
    const data = dataVariants;
    const lastActive = data?.filter((item)=> item.is_active);
    if (lastActive?.length === 1 && value === false) {
      return confirmDialog({
        message: 'This is the last active product variant, are you sure you want to deactive this product?',
        header: 'Deactive Confirmation',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept() {
          return productVariantStatus(value, id, data);
        }
      });
    }
    if (lastActive?.length === 0) {
      return confirmDialog({
        message: 'This is the first activating product variant, are you sure you want to activate this product?',
        header: 'Deactive Confirmation',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept() {
          return productVariantStatus(value, id, data);
        }
      });
    }
    const query = supabase.from(TABLE.PRODUCT_VARIANTS).update({
      is_active: value
    }).eq('id', id);
    const { error } = await query;
    if (error) {
      showError('Failed Update Status');
      return;
    }
    await refetchDetail();
    await productDetail(detailProduct);
    showSuccessStatus('Variant Status Updated');
  };
  const productVariantStatus = async (value: boolean, id: string, data) => {
    const productId = data?.map((item)=> item.product_id);
    const uniqueId = uniqBy(productId, 'product_id');
    const query = supabase.from(TABLE.PRODUCT_VARIANTS).update({
      is_active: value
    }).eq('id', id);
    const { error } = await query;
    const { error: productError } = await supabase.from(TABLE.PRODUCT).update({
      is_active: value
    }).in('id', uniqueId);
    if (productError) {
      showError('Failed Update Status');
      return;
    }
    if (error) {
      showError('Failed Update Status');
      return;
    }
    await refetchDetail();
    await productDetail(detailProduct);
    showSuccessStatus('Variant Status Updated');
  };

  const handleClearFilter = useCallback(() => {
    setLastFilterPage({
      ...lastFilterPage,
      products: ''
    });
    setItemFilters(initialFilter);
    setFilters(initialFilter);
    setFilterHistory([]);
    setSearch('');
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [lastFilterPage, perPage]);

  const handleDeleteFilterHistory = useCallback(
    (key: string, value: string[]) => {
      const items = value[0].split(',');
      items.forEach((i) => {
        setFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));

        setItemFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));
      });

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setSearch('');
    },
    []
  );

  const handleSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>)=>{
    setSearch(currentTarget.value);
    const newKeyword = {
      name: 'Keyword',
      items: [{
        label: currentTarget.value,
        value: currentTarget.value,
        name: currentTarget.value
      }]
    };

    if (currentTarget.value != '') {
      setFilterHistory((prev: IFilterHistoryItems[]) => {
        const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
        if (existingIndex !== -1) {
          prev[existingIndex] = newKeyword;
        } else {
          prev.push(newKeyword);
        }
        return [...prev];
      });

      setPaginator({
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      });
    } else {
      handleDeleteFilterHistory('Keyword', ['Keyword']);
    }
  }, [perPage]);

  // set query params
  const setUrlQueryPageParams = async (page: number, limit: number) => {
    params.delete('page');
    params.delete('limit');
    const existingParams = Object.fromEntries(params);
    setParams({
      page: String(page),
      limit: String(limit),
      ...existingParams
    });
  };

  const handleClickNext = useCallback(() => {
    paginator.currentPage <= totalPages &&
      setPaginator((prev) => {
        setUrlQueryPageParams(paginator.currentPage + 1, perPage);
        return {
          ...prev,
          currentPage: paginator.currentPage + 1,
          range: {
            start: paginator.range.start + perPage,
            end: paginator.range.end + perPage
          }
        };
      });
  }, [paginator, totalPages, params]);

  const handleClickPrev = useCallback(() => {
    paginator.range.start > 0 &&
    setPaginator((prev) => {
      setUrlQueryPageParams(prev.currentPage - 1, perPage);
      return {
        ...prev,
        currentPage: prev.currentPage - 1,
        range: {
          start: prev.range.start - perPage,
          end: prev.range.end - perPage
        }
      };
    });
  }, [paginator, params]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent) => {
    setPerPage(event.value);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleChangeSort = useCallback((e: DataTableSortEvent) => {
    setSort((prev) => ({
      ...prev,
      field: e.sortField === 'ui_id' ? 'id' : e.sortField,
      asc: !prev.asc
    }));
  }, []);

  const handleExportData = async () => {
    setIsExportLoading(true);
    const now = new Date();
    const dataProductResponse: PostgrestSingleResponse<IProduct[]> = { data: [] as IProduct[] } as PostgrestSingleResponse<IProduct[]>;
    const pages = (paginator.currentPage-1) * 100;
    let rangeStart = 0 + pages;
    let rangeEnd = 49 + pages;
    for (let i = 0; i< 2; i++) {
      const dataProduct = await getAllProduct({ start: rangeStart, end: rangeEnd }, sort, filters, searchQuery);
      dataProductResponse.data?.push(...dataProduct.data as IProduct[]);
      rangeStart += 50;
      rangeEnd += 50;
    }

    if (dataProductResponse.data?.length === 0) {
      const data = [
        {
          id: '',
          description: '',
          sku: '',
          updated_at: '',
          created_at: '',
          merchant_id: '',
          images: '',
          insurance_flag: '',
          information: '',
          name: '',
          main_price: '',
          is_main_price: '',
          minimum_purchase: '',
          volume: '',
          is_active: '',
          category_id: '',
          revision_note: '',
          street_id: '',
          video_url: '',
          ref_id: '',
          sku_source: '',
          maximum_purchase: '',
          status: '',
          whole_sale: '',
          type: '',
          vendor_product_id: '',
          meta: '',
          is_required_by_provider: '',
          is_text: '',
          is_digital: '',
          product_variants: '',
          merchants: '',
          categories: '',
          magento_id: '',
          variant_id: ''
        }
      ];
      setIsExportLoading(false);
      return exportTable(data, `aladinmall-produk-${now.toISOString()}.xlsx`);
    }

    const _dataProduct = Array.isArray(dataProductResponse?.data) && dataProductResponse?.data.map((item)=>({
      ...item,
      magento_id: item.ref_id || null,
      variant_id: item.product_variants?.map((pv)=>pv.id)
    }));


    if (_dataProduct) {
      const exportDataMapper = _dataProduct.map((__dataProduct) => {
        const productVariants: string[] = [];

        __dataProduct.product_variants?.forEach((productVariant) => {
          if (productVariant?.id) {
            productVariants.push(productVariant?.id);
          }
        });

        return {
          ...__dataProduct,
          id: __dataProduct.id,
          merchants: __dataProduct.merchants?.name,
          variant_id: __dataProduct?.variant_id ? __dataProduct?.variant_id.toString() : '',
          product_variants: productVariants.toString(),
          categories: __dataProduct.categories?.name
        };
      });

      setIsExportLoading(false);
      return exportTable(exportDataMapper as [], `aladinmall-produk-${now.toISOString()}.xlsx`);
    }

    setIsExportLoading(false);
  };

  useEffect(()=> {
    if (idParams && detailProduct) {
      productDetail(detailProduct as IProduct);
    }
  }, [idParams, detailProduct]);

  useEffect(() => {
    if (idParams && detailProduct) {
      const { NEED_REVISION, IN_REVIEW } = STATUS;
      const { status } = detailProduct;
      const pendingUpdateData = status === IN_REVIEW || status === NEED_REVISION ?
        undefined :
        pendingUpdate?.data?.[0] as PendingUpdate;
      getCategory(detailProduct as IProduct, pendingUpdateData);
    }
  }, [idParams, detailProduct, pendingUpdate]);

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, totalRecords - 1);

      setPaginator(() => ({
        currentPage: jumpToPage > totalPages ? totalPages : value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, totalRecords]);

  const handleClickSubmitFilter = useCallback(() => {
    setFilters(itemFilters);
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
    setIsOpenDialog(false);
  }, [itemFilters, perPage]);

  const storeFilterHistory = useCallback((filter: IFilterProduct) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter.id_from != null) {
      let filterID = filter.id_from.toString();
      if (filter.id_to != null) {
        filterID = `${filter.id_from}-${filter.id_to}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('ID', 'id_from,id_to', filterID));
    }

    if (filter.price_from != null) {
      let filterPrice = filter.price_from.toString();
      if (filter.price_to != null) {
        filterPrice = `${filter.price_from}-${filter.price_to}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Harga', 'price_from,price_to', filterPrice));
    }

    if (filter.special_price_from != null) {
      let filterSpecialPrice = filter.special_price_from.toString();
      if (filter.special_price_to != null) {
        filterSpecialPrice = `${filter.special_price_from}-${filter.special_price_to}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Harga Spesial', 'special_price_from,special_price_to', filterSpecialPrice));
    }

    if (filter.saleable_from != null) {
      let filterSaleable = filter.saleable_from.toString();
      if (filter.saleable_to != null) {
        filterSaleable = `${filter.saleable_from}-${filter.saleable_to}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Saleable', 'saleable_from,saleable_to', filterSaleable));
    }

    if (filter.name !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Name', 'name', filter.name));
    }

    if (filter.merchant_name !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Merchant Name', 'merchant_name', filter.merchant_name));
    }

    if (filter.sku !== '') {
      filterHistoryItems.push(createFilterHistoryItem('SKU', 'sku', filter.sku));
    }

    if (filter.status_product !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Status Product', 'status_product', filter.status_product));
    }

    if (filter.status !== null) {
      filterHistoryItems.push(createFilterHistoryItem('Status', 'status', (filter.status ? 'Active' : 'Inactive')));
    }

    setFilterHistory(filterHistoryItems);
  }, []);

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  useEffect(() => {
    // update store visitedPage with latest page
    setVisitedPage({
      ...visitedPage,
      products: paginator.currentPage
    });
  }, [paginator.currentPage]);

  // update store lastFilterPage with latest filter
  useEffect(() => {
    setLastFilterPage({
      ...lastFilterPage,
      products: JSON.stringify(filters)
    });
  }, [filters]);

  return {
    data: {
      dataProduct,
      detailProduct,
      dataVariants,
      expanded,
      categories,
      specifications,
      isOpen,
      specStyle,
      stock,
      tipe1,
      tipe2,
      selectedProducts,
      visible,
      noteRevision,
      selectedParent,
      secondSelect,
      lastSelect,
      toast,
      isLoading,
      optionsFilter,
      isFetchingProduct,
      filterHistory,
      perPage,
      totalRecords,
      paginator,
      totalPages,
      isExportLoading,
      validateStatus,
      itemFilters,
      search,
      isOpenDialog
    },
    method: {
      setExpanded,
      onSHowMore,
      setSelectedProducts,
      onRejected,
      setVisible,
      setNoteRevision,
      onApproved,
      handleChangeInput,
      handleDeleteFilterHistory,
      handleClearFilter,
      setPerPage,
      handleClickPrev,
      handleClickNext,
      handleChangeDropdownPage,
      handleChangeSort,
      handleExportData,
      findMerchants,
      handleListSwitch,
      handleListVariantSwitch,
      handleChangeJumpTopage,
      handleJumpToPage,
      handleFilter,
      handleClickSubmitFilter,
      handleSearch,
      setIsOpenDialog
    }
  };
};
